import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputBasicComponent } from './search-input-basic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrossButtonModule } from '../../buttons/cross-button/cross-button.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CrossButtonModule,
    ],
    exports: [
        SearchInputBasicComponent
    ],
    declarations: [
        SearchInputBasicComponent
    ],
})
export class SearchInputBasicModule {
}
