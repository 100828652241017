<div class="devices-list-view">
    <div class="devices-list-view__search-line">
        <search-input-embedded
                [textPlaceholder]="TEXTS.POSTS_AND_DEVICES.searchDevice"
                [(text)]="searchQuery"
                [debounceTime]="400"
        ></search-input-embedded>
    </div>
    <div [@inOutAnimation]
         *ngIf="!isShowSearchResultPlaceholder"
         class="devices-list-view__main_block scroll">
        <div>
            <div class="devices-list-view__organizing-buttons">
                <shared-sort-list-item
                        (click)="postsAndDevicesFacade.makeDeviceSorting(postsAndDevicesFacade.sortDeviceSerialNum, 'sortDeviceSerialNum')"
                        class="devices-list-view__organizing-buttons--item"
                        [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortDeviceSerialNum"
                        [text]="TEXTS.POSTS_AND_DEVICES.device"></shared-sort-list-item>
                <!--                <shared-sort-list-item-->
                <!--                        (click)="makeSorting(sortType, 'sortType')"-->
                <!--                        class="devices-list-view__organizing-buttons&#45;&#45;item"-->
                <!--                        [sortingDirection]="sortingDirection.sortType"-->
                <!--                        [text]="TEXTS.POSTS_AND_DEVICES.type"></shared-sort-list-item>-->
                <shared-sort-list-item
                        (click)="postsAndDevicesFacade.makeDeviceSorting(postsAndDevicesFacade.sortDeviceState, 'sortDeviceState')"
                        class="devices-list-view__organizing-buttons--item"
                        [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortDeviceState"
                        [text]="TEXTS.POSTS_AND_DEVICES.state"></shared-sort-list-item>
            </div>
        </div>
        <ng-container
                *ngIf="devicesList | multipleSearchfilter: ['arrSerialNumber', 'arrMoName'] : searchQuery as result">
            <ng-container
                    *ngFor="let device of devicesList | multipleSearchfilter: ['arrSerialNumber', 'arrMoName'] : searchQuery | sorting : postsAndDevicesFacade.sortingDevice : postsAndDevicesFacade.sortingDeviceDirectionMain;">
                <device-card
                        class="devices-list-view__monitoring-object-plate"
                        [serialNumber]="device.serialNumber"
                        [isOnline]="!device.offline"
                        [isNetworks]="device.v220"
                        [isBattery]="device.battery"
                        [tagsDevice]="device.childDevices"
                        [tagsMo]="device.linksToMo"
                        (openActionsPopup)="clickMenu($event, device.serialNumber)"
                        (cbClickForStaticTags)="showDetailsMo($event)"
                ></device-card>
            </ng-container>
            <search-result-placeholder
                    *ngIf="!isShowNotDate && isShowResultPlaceholder && result.length == 0"
                    class="screen-placeholders"
            ></search-result-placeholder>
            <non-data-screen
                    *ngIf="isShowNotDate"
                    class="screen-placeholders"
                    [text]="TEXTS.POSTS_AND_DEVICES.noDevices"
            ></non-data-screen>
        </ng-container>
    </div>
    <!--    <button-download-data-->
    <!--            (click)="openPage(null,path + pages.deviceReport + '/all')"-->
    <!--            class="devices-list-view__button-download-data"-->
    <!--    ></button-download-data>-->
    <div [@inOutAnimation]
         class="devices-list-view__search-result-placeholder"
         *ngIf="isShowSearchResultPlaceholder">
        <search-result-placeholder></search-result-placeholder>
    </div>
</div>

<ng-template #cardMenu>
    <card-actions-list class="devices-list-view__card-actions-list"
                       [@inOutAnimation]
                       *ngIf="isShowMenu"
                       [position]="menuPositionTop"
                       (clickOutside)="closePopup($event)"
    >
        <card-actions-item
                data-cy="action-rename"
                [text]="TEXTS.POSTS_AND_DEVICES.viewInformation"
                (click)="openPage($event, path + pages.details + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.loadPrimaryData"
                (click)="openPage($event,path + pages.deviceReport + '/' + idTargetCard)"
        ></card-actions-item>
    </card-actions-list>
</ng-template>