import { SharedCoreFacade } from '../../../../shared/core/SharedCoreFacade';
import { Marker_model } from '../../../../../src/namespace';
import { EventsSourceName, ModuleHandler } from '../../../../shared/utils/other-utils';

export class DefaultModuleHandler extends ModuleHandler {
    constructor(private readonly sharedCoreFacade: SharedCoreFacade) {
        super();

        const actions = {
            [EventsSourceName.onMarkerClick$]: (marker: Marker_model) => {
                this.sharedCoreFacade.actionObservers.mapMarkerClick.next(marker);
            }
        }

        this.onEvent$ = ({type, payload}) => {
            return actions[type](payload);
        }
    }
}
