// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/arrow_white_dropdown.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logout_cityscreen{position:relative;display:flex;align-items:center;justify-content:space-between;padding:0 13px;width:69px;height:42px;font-weight:500;font-size:14px;cursor:pointer;border-radius:8px;background:#6097f3;color:#fff;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;box-shadow:0 5px 21px rgba(0,0,0,.1);transition:all .2s linear}.logout_cityscreen:hover{box-shadow:0 5px 21px rgba(0,0,0,.3)}.logout_cityscreen__name{text-transform:uppercase}.logout_cityscreen__triangle{min-width:11px;width:13px;height:8px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;transform:rotate(0deg);background-size:contain;transition:all .2s linear}.logout_cityscreen__triangle-open{transform:rotate(180deg)}.logout_cityscreen__dropdown{position:absolute;display:flex;align-items:center;justify-content:center;top:50px;left:0;padding:0 10px;height:42px;width:69px;cursor:pointer;border-radius:8px;box-shadow:0 5px 21px rgba(0,0,0,.1);background:#fff;color:#4a4a4a;opacity:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;visibility:hidden;transition:all .2s linear}.logout_cityscreen__dropdown-open{opacity:1;visibility:visible}.logout_cityscreen__dropdown:hover{box-shadow:0 5px 21px rgba(0,0,0,.3)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
