import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import type { CheckboxItem } from 'src/namespace';

@Component({
    selector: 'selectbox-radio',
    templateUrl: 'selectbox-radio.component.html',
    styleUrls: ['selectbox-radio.component.less']
})
export class SelectboxRadioComponent implements OnInit {
    @Input() listItems: CheckboxItem[];

    @Output() listItemsChange = new EventEmitter<CheckboxItem[]>();

    form: FormGroup;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            selectedItem: [
                this.listItems.find(item => item.selected)?.id
            ]
        });

        this.form.controls.selectedItem.valueChanges
            .subscribe(value => {
                this.listItems.forEach(item => {
                    item.selected = item.id === value;
                });

                this.listItemsChange.emit(this.listItems);
            });
    }
}
