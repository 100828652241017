import {
    Component,
    Input,
    OnInit,
    OnDestroy
} from '@angular/core';

import { NgLocalization } from '@angular/common';

import { TEXTS } from 'src/texts/texts';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { PacketValueType } from 'harvester/UiAdminProject8/src/commonData/models';
import State from 'map/state';
import { postsListLabels } from 'projects/cityscreen/src/modules/notifications/notifications.utils';
import { MAX_INLINE_MOS } from 'projects/cityscreen/src/modules/notifications/notifications.settings';

import 'projects/cityscreen/src/modules/notifications/components/subscription-card-measurements/subscription-card-measurements.component.less';

@Component({
    templateUrl: './subscription-card-measurements.component.html',
    selector: 'subscription-card-measurements'
})
export class SubscriptionCardMeasurements implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    currentMos: string[];
    allMos: number[];
    allValues: number[];
    saving = false;
    maxInlineMos = MAX_INLINE_MOS;

    textsNotification = TEXTS.NOTIFICATIONS;

    stubTags = [this.postsListLabels().all()];

    constructor(
        public stateService: NotificationsStateService,
        private globalState: State,
        private ngLocalization: NgLocalization
    ) {
        this.allMos = globalState.adminPanel.monitoringObjects.map(v => v.id);

        const {
            PM2,
            PM10,
            NO2,
            SO2,
            O3,
            CO,
            H2S
        } = PacketValueType;

        this.allValues = [
            PM2,
            PM10,
            NO2,
            SO2,
            O3,
            CO,
            H2S
        ];
    }

    ngOnInit() {
        this.currentMos = this.initialMosList().map(mo => this.getMoName(mo)).filter(v => v);
    }

    ngOnDestroy() {
        this.globalState.map.notifiableMos$.next(null);
    }

    initialMosList() {
        return this.subscription.isForAllMos ? this.allMos : this.subscription.moItems;
    }

    removeMo(id: number) {
        this.subscription.moItems = this.subscription.moItems.filter(v => v !== id);
    }

    getValueName(value: number) {
        return this.stateService.getValueName(value);
    }

    getMoName(id: number) {
        return this.globalState.adminPanel.monitoringObjects.find(v => v.id === id)?.name;
    }

    getEmails() {
        return this.subscription.emailsList.join(', ');
    }

    editSubscription() {
        this.stateService.openSettings(this.subscription);
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    addSubscription() {
        this.saving = true;
        this.stateService.addSubscription(this.subscription)
        .finally(() => {
            this.saving = false;
        });
    }
}
