<div class="post-report-tza">
    <div class="post-report-tza__main-block scroll">
        <header-of-panel class="post-report-tza__header-of-panel"
                         [path]="navigationLink"
                         (outputPath)="backToPrevPage($event)"
                         [title]="TEXTS.POSTS_AND_DEVICES.tza4">
        </header-of-panel>
        <input-date-range class="post-report-tza__input-date-range"
                          [timeBegin]="objectForReportMo.timeBegin"
                          [timeEnd]="objectForReportMo.timeEnd"
                          (timeBeginChange)="getDateBeginRangeFromCalendar($event)"
                          (timeEndChange)="getDateEndRangeFromCalendar($event)"
        ></input-date-range>

        <input-dropdown class="post-report-tza__input-dropdown"
                        [inputText]="selectedCheckboxText(getTags().length)"
                        [label]="TEXTS.POSTS_AND_DEVICES.postsMonitoring"
                        [(showDropdown)]="isShowDropdownForStations"
                        (clickOutside)="isShowDropdownForStations = false"
        >
            <cs-selectbox2
                    [listItems]="listStations"
                    (itemChange)="selectItem($event)"
            ></cs-selectbox2>
        </input-dropdown>
        <tags-list2
                class="post-report-tza__tags-list"
                [tags]="getTags()"
                [limit]="4"
                [textFunctions]="postsListLabels"
                (removeTag)="removeFromList($event)">
        </tags-list2>
    </div>
    <div class="post-report-tza__btn">
                <btn-cancel (click)="backToPrevPage(navigationLink[navigationLink.length - 2])">
                    {{TEXTS.COMMON.cancel}}
                </btn-cancel>
        <btn-accept
                [disabled]="getTags().length == 0 || isActiveLoader"
                [isLoading]="isActiveLoader"
                (cbClick)="downloadReportMo()"
        >
            {{TEXTS.DOWNLOAD_POPUP.download}}
        </btn-accept>
    </div>
</div>
