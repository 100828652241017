import { NgModule, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragScrollModule } from 'ngx-drag-scroll';

import { networksRoutes } from './posts-and-devices.route';

import { LittleComponentsModule } from 'src/little-components/little-components.module';

import { PostsAndDevicesPageComponent } from './components/posts-and-devices-page/posts-and-devices-page.component';
import { PostsListComponent } from './components/posts-list/posts-list.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { PostDetailsComponent } from './components/post-details/post-details.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { PostCreateComponent } from './components/post-create/post-create.component';
import { PostReportComponent } from './components/post-report/post-report.component';
import { PostReportTzaComponent } from './components/post-report-tza/post-report-tza.component';
import { DeviceDetailsComponent } from './components/device-details/device-details.component';
import { PostsAndDevicesHeaderComponent } from './components/posts-and-devices-header/posts-and-devices-header.component';
import { DeviceReportComponent } from './components/device-report/device-report.component'
import { DetailsHeaderComponent } from './components/details-header/details-header.component';
import { HeaderOfPanelModule } from '../../components/admin-panel/components/header-of-panel/header-of-panel.module';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { ButtonAddModule } from '../../components/admin-panel/components/button-add/button-add.module';
import { SwitchV2Module } from '../../components/switch-v2/switch-v2.module';
import { MonitoringObjectPlateModule } from '../../components/monitoring-object-plate/monitoring-object-plate.module';
import { DeviceCardModule } from '../../components/device-card/device-card.module';
import { SortListItemModule } from 'projects/shared/components/sort-list-item/sort-list-item.module';
import { PageTabsModule } from 'projects/shared/components/page-tabs/page-tabs.module';
import { SearchInputModule } from 'projects/shared/modules/form-components/search-input/search-input.module';
import { SearchInputEmbeddedModule } from '../../components/admin-panel/components/search-input-embedded/search-input-embedded.module';
import { CardActionsModule } from 'projects/cityscreen/src/components/card-actions/card-actions.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { ButtonDownloadDataModule } from 'projects/cityscreen/src/components/button-download-data/button-download-data.module';
import { SearchResultPlaceholderModule } from 'projects/cityscreen/src/components/search-result-placeholder/search-result-placeholder.module';
import { NonDataScreenModule } from 'projects/cityscreen/src/components/non-data-screen/non-data-screen.module';
import { KebabMenuModule } from 'projects/shared/components/kebab-menu/kebab-menu.module';
import { InformationColumnModule } from 'projects/cityscreen/src/components/information-column/information-column.module';
import { SectionLineModule } from 'projects/cityscreen/src/components/section-line/section-line.module';
import { ArrowModule } from 'projects/shared/modules/icons/components/arrow/arrow.module';
import { StaticTagsModule } from 'projects/cityscreen/src/components/static-tags/static-tags.module';
import { InformationStringModule } from 'projects/cityscreen/src/components/information-string/information-string.module';
import { LinkOnDocumentModule } from 'projects/cityscreen/src/components/link-on-document/link-on-document.module';
import { AlertTagsModule } from 'projects/shared/components/alert-tags/alert-tags.module';
import { InputModule } from 'projects/cityscreen/src/components/input/input.module';
import { InputForCoordinateModule } from 'projects/cityscreen/src/components/input-for-coordinate/input-for-coordinate.module';
import { TextareaModule } from 'projects/cityscreen/src/components/textarea/textarea.module';
import { InputDropdownModule } from 'projects/cityscreen/src/components/input-dropdown/input-dropdown.module';
import { SelectboxModule } from 'projects/cityscreen/src/components/selectbox/selectbox.module';
import { SelectboxRadioModule } from 'projects/cityscreen/src/components/selectbox-radio/selectbox-radio.module';
import { InputDateRangeModule } from 'src/calendar/input-date-range/input-date-range.module';
import { GrayBackgroundModule } from 'projects/cityscreen/src/components/gray-background/gray-background.module';
import { BtnAcceptModule } from 'projects/cityscreen/src/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from 'projects/cityscreen/src/components/btn-cancel/btn-cancel.module';
import { BtnExtraModule } from 'projects/cityscreen/src/components/btn-extra/btn-extra.module';
import { TagsListModule } from 'projects/cityscreen/src/components/tags-list/tags-list.module';
import { SwitchItemModule } from 'projects/cityscreen/src/components/switch-item/switch-item.module';
import { CheckboxModule } from 'projects/cityscreen/src/components/checkbox/checkbox.module';
import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { PopupAskModule } from 'projects/cityscreen/src/components/popup-ask/popup-ask.module';
import { BtnAccordionModule } from 'projects/cityscreen/src/components/btn-accordion/btn-accordion.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SelectboxModule2 } from 'projects/cityscreen/src/components/selectbox2/selectbox.module';
import { TagsListModule2 } from 'projects/cityscreen/src/components/tags-list2/tags-list.module';

@NgModule({
    imports: [
        RouterModule.forRoot(networksRoutes),
        CommonModule,
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        LittleComponentsModule,
        HeaderOfPanelModule,
        SearchInputEmbeddedModule,
        SharedComponentsModule,
        SearchInputModule,
        ButtonAddModule,
        SwitchV2Module,
        MonitoringObjectPlateModule,
        SortListItemModule,
        DeviceCardModule,
        PageTabsModule,
        CardActionsModule,
        DirectivesModule,
        ButtonDownloadDataModule,
        SearchResultPlaceholderModule,
        NonDataScreenModule,
        KebabMenuModule,
        InformationColumnModule,
        SectionLineModule,
        ArrowModule,
        StaticTagsModule,
        InformationStringModule,
        LinkOnDocumentModule,
        AlertTagsModule,
        InputModule,
        TextareaModule,
        InputForCoordinateModule,
        InputDropdownModule,
        SelectboxModule,
        SelectboxRadioModule,
        InputDateRangeModule,
        GrayBackgroundModule,
        BtnAcceptModule,
        BtnCancelModule,
        BtnExtraModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        PipesModule,
        PopupAskModule,
        DragScrollModule,
        BtnAccordionModule,
        SelectboxModule2,
        TagsListModule2,
    ],
    exports: [
        PostsAndDevicesPageComponent,
        PostsListComponent,
        DevicesListComponent,
        PostDetailsComponent,
        PostEditComponent,
        PostCreateComponent,
        PostReportComponent,
        PostReportTzaComponent,
        DeviceDetailsComponent,
        DeviceReportComponent,
        PostsAndDevicesHeaderComponent,
    ],
    declarations: [
        PostsAndDevicesPageComponent,
        PostsListComponent,
        DevicesListComponent,
        PostDetailsComponent,
        PostEditComponent,
        PostCreateComponent,
        PostReportComponent,
        PostReportTzaComponent,
        DeviceDetailsComponent,
        DeviceReportComponent,
        PostsAndDevicesHeaderComponent,
        DetailsHeaderComponent,
    ],
})
export class PostsAndDevicesModule {
}
