import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StndChart } from './chart';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        StndChart,
    ],
    declarations: [
        StndChart,
    ]
})
export class ChartsModule {}
