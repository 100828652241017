import { Component, Input } from '@angular/core';

@Component({
    selector: 'aqi-circle',
    template: `
        <div class="{{getClassName()}}" [ngClass]="{
            'aqi_circle': true,
            'aqi_circle__plus': imgPlus
        }"></div>
    `,
    styleUrls: ['aqi-circle.component.less']
})
export class AqiCircleComponent {
    @Input() numberAqi = 0;
    @Input() imgPlus = false;
    // ширина задаётся родительским контейнером

    getClassName() {
        let { numberAqi, imgPlus } = this;

        const suffix = imgPlus ? 'img_plus_' : '';

        if (numberAqi % 1 !== 0 || numberAqi < 1 || numberAqi > 10) {
            numberAqi = 0;
        }

        return `aqi_circle-${suffix}${numberAqi}`;
    }
}
