import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit,
    ApplicationRef
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { Subject, merge } from 'rxjs';
import { pluck, takeUntil, switchMapTo } from 'rxjs/operators';

import { TabModel } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import {
    NotificationsStateService,
    SubscriptionTypeFilter,
    NotificationState,
    Pages
} from 'projects/cityscreen/src/modules/notifications/notifications-state.service';

import 'projects/cityscreen/src/modules/notifications/components/subscriptions/subscriptions.component.less';

@Component({
    templateUrl: 'subscriptions.component.html',
    selector: 'subscriptions'
})
export class Subscriptions implements OnInit, OnDestroy {
    @Input() subscriptions: NotificationSubscription[];

    @ViewChild('subs') subs: CdkVirtualScrollViewport;

    onDestroy$ = new Subject<void>();

    textsNotification = TEXTS.NOTIFICATIONS;
    textsAdminPanel = TEXTS.ADMIN_PANEL;
    textsConfig = TEXTS.CONFIG;

    filterTabs: TabModel[] = [
        {
            title: this.textsNotification.measures,
            type: SubscriptionTypeFilter.MEASUREMENTS
        },
        {
            title: this.textsNotification.service,
            type: SubscriptionTypeFilter.SERVICE
        }
    ];

    selectedTab: TabModel = this.filterTabs[0];

    filterTypes = SubscriptionTypeFilter;

    initialScrollPosition = true;
    selectedCount = 0;

    uiState: NotificationState;

    constructor(
        readonly stateService: NotificationsStateService,
        private appRef: ApplicationRef
    ) {
        this.resetFilter();
    }

    ngOnInit() {
        this.stateService.lists.selected$
        .pipe(
            takeUntil(this.onDestroy$),
            pluck('length')
        )
        .subscribe((len) => {
            this.selectedCount = len;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    resetFilter() {
        this.uiState = this.stateService.getState(Pages.SUBSCRIPTIONS);
        this.showSelectedTab(this.filterTabs.find(t => t.type === this.uiState.typeFilter));
    }

    applyFilter(filter: SubscriptionTypeFilter) {
        this.stateService.typeFilter = this.uiState.typeFilter = filter;
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
        this.applyFilter(tab.type);
    }

    scrollTop() {
        this.subs.scrollToIndex(0);
    }
}
