import {
    Component,
    Input,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy
} from '@angular/core';

import {
    FormGroup,
    Validators,
    FormBuilder
} from '@angular/forms';

import { merge } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';

import { TEXTS } from 'src/texts/texts';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';

import {
    deduplicate,
    haveDifferentValues,
    mapEmails,
    stringToList,
    updateFormControls,
    EmailsListData
} from 'projects/cityscreen/src/modules/notifications/components/settings/settings.utils';

import 'projects/cityscreen/src/modules/notifications/components/settings-service/settings-service.component.less';
import { MessageAPIResponseService } from 'src/little-components/message-api-response/message-api-response.service';

const VALUE_PATTERN = /^[-\+]?[1-9][0-9]*$/;

@Component({
    templateUrl: './settings-service.component.html',
    selector: 'settings-service'
})
export class SettingsService implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    workingSubscription: NotificationSubscription;

    textsNotification = TEXTS.NOTIFICATIONS;
    textsPopup = TEXTS.POPUP_THREE_QUESTIONS;
    TEXTS = TEXTS;

    settingsForm: FormGroup;

    emailsListData: EmailsListData;
    emailsListReportsData: EmailsListData;

    saving = false;
    formDataHasChanged = false;
    showConfirmationPopup = false;

    constructor(
        private stateService: NotificationsStateService,
        private fb: FormBuilder,
        private popupProvider: OffPanelPopupService,
        private msgService: MessageAPIResponseService
    ) {}

    ngOnInit() {
        this.workingSubscription = NotificationSubscription.clone(this.subscription);

        this.emailsListData = mapEmails(this.workingSubscription.emailsList);
        this.emailsListReportsData = mapEmails(this.workingSubscription.emailsListReports);

        this.settingsForm = this.fb.group({
            title: [
                this.workingSubscription.title,
                [
                    Validators.required,
                    Validators.minLength(1)
                ]
            ],
            serviceSettings: this.fb.group({
                nodata: [
                    this.workingSubscription.serviceNoData,
                    [
                        Validators.required,
                        Validators.pattern(VALUE_PATTERN),
                        Validators.min(1)
                    ]
                ],
                nopower: [
                    this.workingSubscription.serviceNoPower,
                    [
                        Validators.required,
                        Validators.pattern(VALUE_PATTERN),
                        Validators.min(1)
                    ]
                ],
                verification: [
                    this.workingSubscription.serviceVerification,
                    [
                        Validators.required,
                        Validators.pattern(VALUE_PATTERN),
                        Validators.min(1)
                    ]
                ]
            }),
            emailsList: [
                this.workingSubscription.emailsList,
                [
                    Validators.minLength(1)
                ]
            ],
            emailsListReports: [
                this.workingSubscription.emailsListReports,
                [
                    Validators.minLength(1)
                ]
            ],
            emailsListProxy: this.fb.group(
                this.emailsListData.reduce((acc, { name, value }) => ({
                    ...acc,
                    [name]: [value, [Validators.email]]
                }), {})
            ),
            emailsListReportsProxy: this.fb.group(
                this.emailsListReportsData.reduce((acc, { name, value }) => ({
                    ...acc,
                    [name]: [value, [Validators.email]]
                }), {})
            )
        });

        const title$ = this.title.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const serviceSettings$ = this.serviceSettings.valueChanges.pipe(debounceTime(200));
        const emailsList$ = this.emailsList.valueChanges.pipe(debounceTime(400), distinctUntilChanged());
        const emailsListReports$ = this.emailsListReports.valueChanges.pipe(debounceTime(400), distinctUntilChanged());

        title$
        .subscribe(value => {
            this.workingSubscription.title = value;
        });

        serviceSettings$
        .subscribe(value => {
            Object.assign(this.workingSubscription, {
                serviceNoData: value.nodata,
                serviceNoPower: value.nopower,
                serviceVerification: value.verification
            });
        });

        emailsList$
        .subscribe(value => {
            this.updateList(value.toString());
            const emails = Object.values(this.emailsListProxy.value as object);
            this.workingSubscription.emailsList = deduplicate(emails);
        });

        emailsListReports$
        .subscribe(value => {
            this.updateListReports(value.toString());
            const emails = Object.values(this.emailsListReportsProxy.value as object);
            this.workingSubscription.emailsListReports = deduplicate(emails);
        });

        merge(
            title$,
            serviceSettings$,
            emailsList$,
            emailsListReports$
        )
        .subscribe(() => {
            this.changeCheck();
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            const changed = this.settingsForm.invalid || this.formDataHasChanged;
            if (changed) this.openPopup();
            return changed;
        });
    }

    ngOnDestroy() {
        this.msgService.clearMsg();
        this.popupProvider.clear();
    }

    updateList(value: string) {
        this.emailsListData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListProxy as FormGroup, this.emailsListData);
    }

    updateListReports(value: string) {
        this.emailsListReportsData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListReportsProxy as FormGroup, this.emailsListReportsData);
    }

    changeCheck() {
        this.formDataHasChanged = haveDifferentValues(
            this.subscription,
            this.workingSubscription
        );
    }

    get title() {
        return this.settingsForm.get('title');
    }

    get serviceSettings() {
        return this.settingsForm.get('serviceSettings');
    }

    get nodata() {
        return this.settingsForm.get('serviceSettings').get('nodata');
    }

    get nopower() {
        return this.settingsForm.get('serviceSettings').get('nopower');
    }

    get verification() {
        return this.settingsForm.get('serviceSettings').get('verification');
    }

    get emailsList() {
        return this.settingsForm.get('emailsList');
    }

    get emailsListReports() {
        return this.settingsForm.get('emailsListReports');
    }

    get emailsListProxy() {
        return this.settingsForm.get('emailsListProxy') as FormGroup;
    }

    get emailsListReportsProxy() {
        return this.settingsForm.get('emailsListReportsProxy') as FormGroup;
    }

    cancel = () => {
        this.stateService.openSubscriptions();
    }

    save() {
        if (!this.settingsForm.disabled && !this.settingsForm.invalid && this.formDataHasChanged) {
            this.onSubmit();
        } else {
            this.onClosePopup();
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onSubmit() {
        this.settingsForm.disable({
            emitEvent: false
        });

        this.saving = true;

        const subscription = this.workingSubscription;

        this.stateService.addOrActivateSubscription(subscription)
        .then(() => {
            if (this.popupProvider.deferred()) {
                this.stateService.openSubscriptions();
            }
        })
        .finally(() => {
            this.saving = false;
            this.settingsForm.enable();
            this.onClosePopup();
        });
    }

    onCancelAndNavigation() {
        this.onClosePopup();
        this.popupProvider.deferred();
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    }

    onSavePopup = () => {
        this.save();
    }

    onCancelPopup = () => {
        this.cancel();
    }
}
