import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectboxComponent } from './selectbox.component';
import { SearchInputModule } from 'projects/shared/modules/form-components/search-input/search-input.module';
import { CheckboxModule } from 'projects/cityscreen/src/components/checkbox/checkbox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { TextButtonModule } from 'projects/cityscreen/src/components/text-button/text-button.module';

@NgModule({
    exports: [SelectboxComponent],
    declarations: [SelectboxComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchInputModule,
        CheckboxModule,
        PipesModule,
        TextButtonModule
    ]
})
export class SelectboxModule {
}
