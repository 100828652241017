import * as moment from 'moment';
import { LANGUAGE, TEXTS } from 'src/texts/texts';

function trimToLen(index: number, len: number) {
    return (str: string, i: number) => i === index ? str.slice(0, len) : str;
}

function formatMonthName(dateText: string) {
    return dateText.split(' ').map(trimToLen(1, 3)).join(' ');
}

// TODO: Should comply with ISO_8601
// Currently the same format is used in timeline.
export function shortDateFormat(dateTime: Date, locale: string = LANGUAGE) {
    return formatMonthName(
        moment(dateTime).format(
            {
                ru: 'D MMM YYYY, H:mm'
            }[locale] || 'MMM Do YYYY, H:mm'
        )
    );
}

export function shortDateFormatDay(dateTime: Date, locale: string = LANGUAGE) {
    return formatMonthName(
        moment(dateTime).format(
            {
                ru: 'D MMM YYYY'
            }[locale] || 'MMM D YYYY'
        )
    );
}

export function shortDateFormatWithClosest(dateTime: Date) {
    const { today, yesterday } = TEXTS.COMMON;

    return isToday(dateTime) ? today
            : isYesterday(dateTime) ? yesterday
            : shortDateFormatDay(dateTime);
}

export function shortDateFormatWithToday(todayDate: Date, dateTime: Date) {
    const formatted = moment(dateTime).isSame(todayDate, 'day')
        ? moment(dateTime).format(`[${TEXTS.COMMON.today}], H:mm`)
        : shortDateFormat(dateTime);

    return moment(dateTime).diff(moment(), 'days') ? formatMonthName(formatted) : formatted;
}

export function isToday(dateTime: Date) {
    return moment(dateTime).isSame(moment(), 'day');
}

export function isYesterday(dateTime: Date) {
    return isToday(moment(dateTime).add(1, 'day').toDate());
}

export function annotateWithDates<T>(
    getDate: (item: T) => Date,
    formatDate?: (date: Date) => string
) {
    formatDate = formatDate || shortDateFormatDay;

    let prevDateTime = new Date(0);
    let prevLabel = formatDate(prevDateTime);

    return (items: T[]) => items.map(item => {
        const dateTime = getDate(item);
        const label = formatDate(dateTime);
        const changed = label !== prevLabel;
        const annotation = changed ? label : '';

        if (changed) {
            prevDateTime = dateTime;
            prevLabel = label;
        }

        return {
            item,
            annotation
        };
    });
}

export function dateRangeText(begin: Date, end: Date) {
    const beginText = shortDateFormatDay(begin);
    const endText = shortDateFormatDay(end);

    const beginYear = moment(begin).format('YYYY');
    const sameYear = beginYear === moment(end).format('YYYY');
    const separator = '–';

    if (beginText === endText) {
        return beginText;
    } else if (sameYear) {
        return [beginText.split(beginYear)[0], separator, endText].join(' ');
    } else {
        return [beginText, separator, endText].join(' ');
    }
}

export function shortDateTime(dateTime: Date) {
    return moment(dateTime).format('HH:mm D MMM YYYY').split(' ').map(trimToLen(2, 3)).join(' ');
}

export function shortDateTimeWithoutYear(dateTime: Date) {
    return moment(dateTime).format('HH:mm D MMM').split(' ').map(trimToLen(2, 3)).join(' ');
}

export function formatDayMonthYear(dateString: string, locale: string) {
    return dateString ? shortDateFormatDay(moment(dateString).toDate(), locale) : '';
}

export function shortDateNoYear(dateTime: Date, locale: string = LANGUAGE) {
    return moment(dateTime).format(
        {
            ru: 'D MMM, HH:mm'
        }[locale] || 'MMM Do, HH:mm'
    ).replace('.', '');
}

export function nowDateTimeLong(dateTime: Date, utcOffset = moment().utcOffset(), locale = LANGUAGE) {
    return moment(dateTime).utcOffset(utcOffset).format({
        ru: 'D MMMM, HH:mm'
    }[locale] || 'MMMM D, HH:mm');
}
