import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTagsComponent } from './alert-tags.component';


@NgModule({
    exports: [AlertTagsComponent],
    declarations: [AlertTagsComponent],
    imports: [
        CommonModule
    ]
})
export class AlertTagsModule {
}
