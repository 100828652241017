import { createAction, props } from '@ngrx/store';
import { PostsMeasurementsResponse, PostWithAnalytics } from 'projects/cityscreen/src/modules/indoor/services/api';
import { NotificationEvent } from './props.type';

enum IndoorActions {
    IndoorUpdatePostsList = '[IndoorActions] IndoorUpdatePostsList',
    IndoorPostsListLoaded = '[IndoorActions] IndoorPostsListLoaded',
    IndoorUpdateTimeRange = '[IndoorActions] IndoorUpdateTimeRange',
    IndoorUpdatedAnalytic = '[IndoorActions] IndoorUpdatedAnalytic',
    IndoorHideAnalytics = '[IndoorActions] IndoorHideAnalytics',
    IndoorSelectPoint = '[IndoorActions] IndoorSelectPoint',
    IndoorDeselectPoint = '[IndoorActions] IndoorDeselectPoint',
    IndoorSetTime = '[IndoorActions] IndoorSetTime',
    IndoorSortPoints = '[IndoorActions] IndoorSortPoints',
    IndoorUpdateIntervalData = '[IndoorActions] IndoorUpdateIntervalData',
    IndoorIntervalDataLoaded = '[IndoorActions] IndoorIntervalDataLoaded',
    IndoorUpdateTimeIndex = '[IndoorActions] IndoorUpdateTimeIndex',
    IndoorAllowUpdate = '[IndoorActions] IndoorAllowUpdate',
    IndoorShowEventOnChart = '[IndoorActions] IndoorShowEventOnChart',
    IndoorSetTimeAfterDataLoaded = '[IndoorActions] IndoorSetTimeAfterDataLoaded',
}

export const indoorUpdatePostsList = createAction(
    IndoorActions.IndoorUpdatePostsList,
    props<{ timeBegin: number; timeEnd: number; groupId: number; }>()
);
export const indoorPostsListLoaded = createAction(
    IndoorActions.IndoorPostsListLoaded,
    props<PostsMeasurementsResponse>()
);
export const indoorUpdateIntervalData = createAction(
    IndoorActions.IndoorUpdateIntervalData,
    props<{ timeBegin: number; timeEnd: number; groupId: number }>()
);
export const indoorIntervalDataLoaded = createAction(
    IndoorActions.IndoorIntervalDataLoaded,
    props<PostsMeasurementsResponse>()
);
export const indoorUpdatedAnalytic = createAction(
    IndoorActions.IndoorUpdatedAnalytic,
    props<PostWithAnalytics>()
);
export const indoorHideAnalytics = createAction(
    IndoorActions.IndoorHideAnalytics,
);
export const indoorSetTime = createAction(
    IndoorActions.IndoorSetTime,
    props<{ time: string }>()
);
export const indoorSelectPoint = createAction(
    IndoorActions.IndoorSelectPoint,
    props<{ id: number }>()
);
export const indoorDeselectPoints = createAction(
    IndoorActions.IndoorDeselectPoint
);
export const indoorSortPoints = createAction(
    IndoorActions.IndoorSortPoints,
    props<{ direction: 1 | -1, field: string }>()
);
export const indoorUpdateTimeIndex = createAction(
    IndoorActions.IndoorUpdateTimeIndex,
    props<{ index: number }>()
);
export const indoorAllowUpdate = createAction(
    IndoorActions.IndoorAllowUpdate,
    props<{ isAllow: boolean }>()
);
export const indoorSetTimeAfterDataLoaded = createAction(
    IndoorActions.IndoorSetTimeAfterDataLoaded,
);
export const indoorShowEventOnChart = createAction(
    IndoorActions.IndoorShowEventOnChart,
    props<NotificationEvent>()
);
