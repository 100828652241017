import { Injectable } from '@angular/core';

import { RunPlume, Source } from './services/models/run-model';
import { ControlPoint } from './services/models/control-point-model';
import { RunConfig } from './services/models/run-config.model';

@Injectable({
    providedIn: 'root'
})
export class PlumesCollectionService {
    private _runs: Map<number, RunPlume> = new Map();

    updateAllRuns(runs: RunPlume[], measure: string) {
        this._runs = new Map(runs.map(run => [run.id, new RunPlume(run, measure)]));
    }

    getAllRuns() {
        return Array.from(this._runs.values())
    }

    updateOneRun(id: number, run: RunPlume, measure: string) {
        this._runs.set(id, new RunPlume(run, measure));
    }

    getOneRun(id: number) {
        return this._runs.get(id);
    }

    // POINTS -------------------------------------------------------------
    private _controlPoints: ControlPoint[] = [];

    updateAllPoints(points: ControlPoint[]) {
        this._controlPoints = points;
    }

    addPoint(point: ControlPoint) {
        this._controlPoints.push(point);
    }

    deletePoint(id: number) {
        this._controlPoints = this._controlPoints.filter(point => point.id !== id);
    }

    getAllPoints(): ControlPoint[] {
        return this._controlPoints;
    }

    patchPoint(body: ControlPoint) {
        const index = this._controlPoints.findIndex(point => point.id === body.id);
        this._controlPoints[index] = body;
    }


    // SOURCES------------------------------------------------------------------------
    private _sources: Source[] = [];

    getAllSources() {
        return this._sources;
    }

    updateAllSources(sources: Source[]) {
        this._sources = sources;
    }

    // RUN CONFIG
    private _runConfig: RunConfig[] = [];

    getRunConfig() {
        return this._runConfig;
    }

    updateRunConfig(runConfig: RunConfig[]) {
        this._runConfig = runConfig;
    }
}
