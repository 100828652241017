import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { AdminDevice, Marker_model } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'monitoring-object-plate',
    templateUrl: `monitoring-object-plate.component.html`,
    styleUrls: ['monitoring-object-plate.component.less']
})
export class MonitoringObjectPlateComponent {
    @Input() name = '';
    @Input() idMo = null;
    @Input() description?: string;
    @Input() city?: string;
    @Input() aqi: Marker_model = null;
    @Input() arrDeviceName: AdminDevice[] = [];
    @Input() isActive = false;
    @Input() isComplexMode = false;
    @Input() isShowKebabMenu = true;
    @Input() isNotConnectedDevices = true;
    @Input() isNoDataSources = true;

    @Output() cbClickForStaticTagsDevice = new EventEmitter<{type: string, id: string}>();
    @Output() cbClickForCard = new EventEmitter<void>();
    @Output() openActionsPopup = new EventEmitter<{ target: HTMLElement, positionTop: number }>();

    TEXTS = TEXTS;

    clickForStaticTagsPost(idMO) {
        this.cbClickForStaticTagsDevice.emit({type: 'MO', id: idMO})
    }

    clickForCard() {
        this.cbClickForCard.emit();
    }

    clickForStaticTagsDevice(serialNumber: string) {
        const typeDevice = this.arrDeviceName.find(adn => adn.serialNumber == serialNumber).sourceId
        if (typeDevice == 3) {
            this.cbClickForStaticTagsDevice.emit({type: 'device', id: serialNumber})
        }
    }

    openActions({target, positionTop}) {
        this.openActionsPopup.emit({
            target,
            positionTop
        });
    }

    getAqi(aqi) {
        return aqi == null ? null : aqi.aqi
    }

}
