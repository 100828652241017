import { Component, OnDestroy } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { NotificationsStateService, SubscriptionTypeFilter } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';

import 'projects/cityscreen/src/modules/notifications/components/settings/settings.component.less';

@Component({
    templateUrl: './settings.component.html',
    selector: 'settings'
})
export class Settings implements OnDestroy {
    textsNotification = TEXTS.NOTIFICATIONS;
    filterTypes = SubscriptionTypeFilter;

    constructor(public stateService: NotificationsStateService) {}

    ngOnDestroy() {
        this.stateService.resetData();
    }
}
