import { Component, Input } from '@angular/core';
import { measureZones } from '../../../../../../src/config';
import { TEXTS } from '../../../../../../src/texts/texts';

@Component({
    selector: 'timeline-footer-pdk',
    templateUrl: './timeline-footer-pdk.component.html',
    styleUrls: ['./timeline-footer-pdk.component.less']
})
export class TimelineFooterPdkComponent {
    @Input() measureType: string;
    @Input() unit: string;
    @Input() pdk: 'ss' | 'mr';

    TEXTS = TEXTS;

    value = () => this.pdk === 'ss' ? measureZones.getPDKcc(this.measureType) : measureZones.getPDKmr(this.measureType);
}
