import { ChartOptions } from 'chart.js';

export function createChartOptions(min: Date, max: Date, ticksColor: string): ChartOptions {
    const xAxisConfig = {
        type: 'time',
        ticks: {
            display: false,
            source: 'labels',
            min,
            max
        },
        gridLines: {
            display: true,
            drawBorder: false,
            color: ticksColor,
            zeroLineColor: ticksColor
        }
    };

    return {
        responsive: false,
        layout: {
            padding: {
                left: 0,
                right: 15,
                top: 0,
                bottom: 0
            }
        },
        scales: {
            xAxes: [
                xAxisConfig,
                {
                    ...xAxisConfig,
                    position: 'top'
                }
            ]  as Chart.ChartXAxe[],
            yAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    display: false
                }
            }]
        }
    };
};
