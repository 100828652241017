import type { DomainConfigType } from './domain-tiles-player/domain-config.type';
import { Substance } from './domain-tiles-player/substance.enum';

export const DOMAINS_FORECASTS: Record<string, DomainConfigType> = {
    moscow: {
        slug: 'ru_moscow_5km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [35.482452392578125, 56.42679977416992],
            [39.207977294921875, 56.42679977416992],
            [39.207977294921875, 54.11769485473633],
            [35.482452392578125, 54.11769485473633]
        ]
    },
    spb: {
        slug: 'ru_s-petersburg_5km',
        substances: [Substance.PM25, Substance.PM10, Substance.NO2, Substance.SO2],
        coordinates: [
            [25.987335205078125, 62.16099166870117],
            [34.646026611328125, 62.16099166870117],
            [34.646026611328125, 57.58010482788086],
            [25.987335205078125, 57.58010482788086]
        ]
    }
};
