import { Component, Input } from '@angular/core';

import { STATUS_TYPES } from '../device-status/device-status.types';

@Component({
    selector: 'device-status-tag',
    templateUrl: 'device-status-tag.component.html',
    styleUrls: ['device-status-tag.component.less']
})
export class DeviceStatusTagComponent {
    @Input() type: STATUS_TYPES;
    @Input() text: string;
}
