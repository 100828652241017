import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionLineComponent } from './section-line.component';


@NgModule({
    exports: [SectionLineComponent],
    declarations: [SectionLineComponent],
    imports: [
        CommonModule
    ]
})
export class SectionLineModule {
}
