import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { FeedbackComponent } from 'projects/cityscreen/src/components/feedback/feedback.component';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule
    ],
    exports: [
        FeedbackComponent
    ],
    declarations: [
        FeedbackComponent
    ]
})
export class FeedbackComponentModule {}
