<div class="track-panel-wrapper">
    <div class="track-data">
        <div class="block" *ngFor="let item of date; index as i"
            [style.backgroundColor]="hasDataByIndex[i] && backColors[AQI[i]]"
            [class.active]="i === index"
            (click)="setIndex(i)"
        >
            <ng-container *ngIf="item.label">
                <div class="label" [innerHTML]="item.label | safeHtml"></div>
                <div class="tick"></div>
            </ng-container>
        </div>
    </div>
</div>
