import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupChangeSelectorComponent } from 'projects/cityscreen/src/components/group-change-selector/group-change-selector.component';
import { GroupListItemModule } from 'projects/cityscreen/src/components/group-list-item/group-list-item.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        GroupListItemModule,
        DirectivesModule
    ],
    exports: [
        GroupChangeSelectorComponent
    ],
    declarations: [
        GroupChangeSelectorComponent
    ]
})
export class GroupChangeSelectorModule {}
