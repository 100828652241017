<div class="side-menu-button" (click)="onClick($event)">
    <div class="side-menu-button__icon side-menu-button__icon-{{name}}"
        [class.side-menu-button__icon--not-available]="!isAvailable"
    >
        <div class="side-menu-button__tooltip"
            *ngIf="!isTouchDevice"
            [attr.data-cy]="name"
            caTooltip
            caTooltipPlacement="right"
            [caTooltipTemplate]="menuTooltip"
        ></div>
        <ng-template #menuTooltip>
            <ca-tooltip
                [text]="isAvailable ? TEXTS.ADMIN_PANEL[name] : TEXTS.ADMIN_PANEL[name] + TEXTS.ADMIN_PANEL['not' + name]"
            ></ca-tooltip>
        </ng-template>
    </div>
</div>
