import AdminPanelState from './state';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import { isFalseNumber } from 'src/utils';
import { Marker_model, MonitoringObject, namePages, UserItems, WindowGlobalVars, } from 'src/namespace';
import { getShiftMapCityCard, MAX_PCF, MIN_PCF } from 'src/config';
import { DevicesApi } from 'src/api/DevicesApi/api';
import { ErrorTransformer_model } from 'src/api/dataProvider/DataProvider';
import { MessageAPIResponseService } from 'src/little-components/message-api-response/message-api-response.service';
import { CpUserItem } from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { CoreFacade } from '../../modules/core/core-facade';
import { Injectable } from '@angular/core';
import { GroupFeaturesService } from '../../modules/core/services/group-features/group-features.service';
import { detectMobile } from 'projects/shared/utils/other-utils';
import { selectCurrentMo } from '../../modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { RoutingService } from 'projects/cityscreen/src/modules/core/routing.service';

declare const window: WindowGlobalVars;

const LAST_VISITED_MENU = 'last_visited_menu';

@Injectable({
    providedIn: 'root'
})
export default class AdminPanelActions {
    constructor(
        private apState: AdminPanelState,
        public adminPanelApi: AdminPanelApi,
        private devicesApi: DevicesApi,
        private msgService: MessageAPIResponseService,
        readonly sharedCoreFacade: SharedCoreFacade,
        private groupFeaturesService: GroupFeaturesService,
        readonly coreFacade: CoreFacade,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store,
        readonly popupProvider: OffPanelPopupService,
        private routingService: RoutingService
    ) {
        this.adminPanelApi.setErrorCb((response: ErrorTransformer_model) => {
            const error = new Error();
            error.message = response.message;
            this.msgService.setMsg({
                ...error,
                type: 'error'
            });
        });

        this.sharedCoreFacade.actionObservables.openCityAnalytic.subscribe((x: Marker_model | string) => {
            this.openCity(x);
        });

        this.routingService.pageChange$.subscribe((page) => {
            if (page === namePages.plumes)
                this.openPlumesPage();
        })
    }

    _save = (promise: Promise<any>, promise2?: Promise<void>) => {
        this.apState.saving = true;
        promise
            .then(async () => {
                this.apState.success = true;

                setTimeout(() => this.apState.success = false, 2000);

                this.coreFacade.updateGroupInfo();

                if (promise2)
                    await promise2;

                this.apState.saving = false;
            })
            .catch((error) => {
                this.apState.saving = false;
            });
    };

    // openPage(newPage: namePages) {
    //     this.apState.pagesArr = [newPage];
    //
    //     this.setLastVisitedPage(newPage);
    //
    //     this.sharedCoreFacade.actionObservers.onOpenModule.next({newPage});
    // }

    closeToken = async (id: number) => await this.adminPanelApi.deleteUserToken(id);

    editToken = async (id: number, title: string) => await this.adminPanelApi.editUserToken(id, title);

    createToken = async (title: string) => this.adminPanelApi.createUserToken(this.apState.iAm.userId, title);

    updateTokens = async () => this.apState.tokens = await this.adminPanelApi.getUserPermission(this.apState.iAm.userId);

    getUserTokenValue = async (id: number) => await this.adminPanelApi.getUserTokenValue(id);

    selectDevice = (id: number, onlyOne = true) => {
        if (isFalseNumber(id)) {
            return;
        }

        const dev = this.apState.allDevices.find(d => d.id === id);

        if (dev && onlyOne)
            this.apState.selectedDevices = [dev];
        else if (dev)
            this.apState.selectedDevices.push(dev);
        else
            this.apState.selectedDevices = []; // Device не найден девайс!
    };

    onModuleSelect(page: namePages) {
        // if (this.popupProvider.confirm(() => this.onModuleSelect(page))) {
        //     return;
        // }
        //
        // this.store.dispatch(page ? openSidebar() : closeSidebar());
        //
        // switch (page) {
        //     case namePages.listDevices:
        //     case namePages.listOM:
        //     case namePages.listUsers:
        //     case namePages.configPanel:
        //     case namePages.networks:
        //     case namePages.dashboard:
        //     case namePages.notifications:
        //         this.setDefaultModule();
        //         // this.openPage(page);
        //         break;
        //     case namePages.indoor:
        //         this.sharedCoreFacade.setModuleConfig(indoorModuleConfig);
        //         // this.openPage(page);
        //         break;
        //     case namePages.plumes:
        //         this.openPlumesPage();
        //         break;
        //     case namePages.cityCard:
        //     default:
        //         this.setDefaultModule();
        //         break;
        // }
    }

    public isActiveRoute = this.routingService.isActiveRoute;

    public routeIsNotEmpty = () => !!this.router.url.substring(1);

    back = () => this.apState.pagesArr.splice(this.apState.pagesArr.length - 1, 1);


    getLastVisitedPage() {
        return localStorage.getItem(LAST_VISITED_MENU) as namePages;
    }

    setLastVisitedPage(page: namePages) {
        localStorage.setItem(LAST_VISITED_MENU, page);
    }

    clearLastVisitedPage() {
        localStorage.removeItem(LAST_VISITED_MENU);
    }

    saveChangeParamMo(selectedMo: MonitoringObject) {
        this.apState.invalidGeo = false;

        const isValidGeo = !isFalseNumber(selectedMo.geoLatitude) && !isFalseNumber(selectedMo.geoLongitude); // проверко на number

        if (!isValidGeo) {
            this.apState.invalidGeo = true;
            return;
        }

        this.apState.saving = true;

        this.adminPanelApi.editMo(selectedMo)
            .then(() => {
                this.coreFacade.updateGroupInfo();
                this.cancelEdit();
            })
            .catch(() => {
                this.apState.saving = false;
            });
    };

    deleteMo = async () => {
        const mo = await this.store.select(selectCurrentMo).pipe(take(1)).toPromise();

        await this.adminPanelApi.deleteMo(mo);

        this.back();
        this.coreFacade.updateGroupInfo();

        this.sharedCoreFacade.actionObservers.closeTimeline.next();
    };

    cancelEdit = () => {
        this.apState.saving = false;
        this.apState.menuEditMo = false;
    };

    /*-----------------------------------------------USERS------------------------------------------------------------*/

    applyUserUpdates = async (user: UserItems, isEditMode: boolean, isUpdateRole: boolean, isUpdateMos: boolean): Promise<boolean> => {
        try {
            if (!isEditMode && !user.userId) {
                const cpUserItem: CpUserItem = await this.adminPanelApi.createObserverUserItem(user);

                if (cpUserItem)
                    user.userId = cpUserItem.UserId;
                else
                    return !window.sentryCaptureException(new Error('createObserverUserItem не вернул юзера ' + user.email));

                await this.adminPanelApi.sendWelcomeMail(cpUserItem)
            }

            if (!isEditMode)
                await this.adminPanelApi.addUser(user.userId);

            if (isUpdateRole && user.roleId)
                await this.adminPanelApi.editUser(user.userId, user.roleId);

            if (isUpdateMos)
                await this.adminPanelApi.editUsersMO(user.userId, user.linksToMo.map(m => m.id))

            this.coreFacade.updateGroupInfo();
            return true;
        } catch (e) {
            return false;
        }
    }


    selectUserToDelete = (user: UserItems) => {
        this.apState.userToDelete = user.userId;
    };

    deleteUser = () => {
        this._save(this.adminPanelApi.removeUser(this.apState.userToDelete));
    };

    /*-----------------------------------------------/USERS------------------------------------------------------------*/

    createMo = (newMo: MonitoringObject) => {
        this._save(this.adminPanelApi.createMoItem(newMo, this.apState.selectedDevices))
    }

    clickFromApToMo = (moObjId) => this.coreFacade.clickFromApToMo(moObjId);

    // -------------------------------------------------------------city card --------------------------------------------------

    clickFromApToMarker = (marker: Marker_model) => this.coreFacade.clickFromApToMarker(marker);

    clickFromApToCity = (marker: Marker_model) => {
        this.sharedCoreFacade.actionObservers.clickOnCityMarker.next(marker);
        this.sharedCoreFacade.actionObservers.centringOnMarker.next({
            lat: marker.lat,
            lng: marker.lng,
            zooming: true,
            shift: getShiftMapCityCard()
        });
    };

    openCityCard = () => {
        if (detectMobile() || !this.coreFacade.getCities()?.length) {
            return;
        }

        if (!this.sharedCoreFacade.getCityCard().city && this.sharedCoreFacade.getIsCityMod()) {
            this.sharedCoreFacade.setCityCardCity(this.sharedCoreFacade.getCurrentCity());
        }

        // this.openPage(namePages.cityCard);
        this.routingService.goToPage(namePages.cityCard);
    };

    // setDefaultModule = () => {
    //     this.sharedCoreFacade.setModuleHandler(DefaultModuleHandler);
    //     this.sharedCoreFacade.setModuleConfig(defaultModuleConfig);
    // }

    openPlumesPage = () => {
        if (this.sharedCoreFacade.getComparedList().length > 1)
            this.sharedCoreFacade.clearCompareList();

        this.sharedCoreFacade.setCompareModFalse();
    }

    backToCityChange = () => this.sharedCoreFacade.setCityCardCity(null);

    openCity = (markerOrId: Marker_model | string) => {
        if (!markerOrId || detectMobile()) {
            return;
        }

        const city = this.coreFacade.getCities().find(c => c.id === (typeof markerOrId === 'object' ? markerOrId.cityId : markerOrId));

        if (this.sharedCoreFacade.getCityCard().city?.id !== city?.id) {
            this.sharedCoreFacade.setCityCardCity(city);

            // не открыта админ панель
            if (!this.routingService.getActivePage()) {
                this.openCityCard();
            }
        }
    };

    setCoefficient = (val: number) => {
        if (val < MIN_PCF || val > MAX_PCF) {
            return;
        }

        this.sharedCoreFacade.actionObservers.closeTimeline.next();

        this._save(this.adminPanelApi.setRegionCoefsItem(val));
    }
}
