import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, first, take, distinctUntilChanged, scan, switchMapTo } from 'rxjs/operators';
import * as moment from 'moment';

import { round20Min } from 'src/utils';
import { namePages, PM10 } from 'src/namespace';

import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { getStndTimeBegin, getStndTimeEnd } from 'projects/shared/utils/config';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import type { RunsQueryOptions } from './services/api/plumes-api';
import { PlumesApi } from './services/api/plumes-api';
import { CoreFacade } from '../core/core-facade';
import { RunPlume, Source } from './services/models/run-model';
import { PlumesCollectionService } from './plumes-collection.service';
import { FormError } from './services/api/form-error';
import { ControlPoint } from './services/models/control-point-model';
import { RunConfig } from './services/models/run-config.model';
import { PlumeUiState } from './ui-state';
import { GroupExtConfigName, GroupFeaturesService } from '../core/services/group-features/group-features.service';
import { selectGroupInfo, selectMapLoaded } from '../core/store/selectors';
import { RoutingService } from 'projects/cityscreen/src/modules/core/routing.service';

class PlumeState {
    currentRunId: number;
    lastRunId: number;

    setCurrentRunId(id: number) {
        this.currentRunId = id;
        this.lastRunId = id;
    }

    clearCurrentRunId() {
        this.currentRunId = null;
    }

    currentMeasure = PM10;

    readonly evaluationTime = {
        begin: moment().startOf('day').subtract(2, 'days').valueOf(),
        end: moment().endOf('day').valueOf()
    };
}

@Injectable({
    providedIn: 'root'
})
export class PlumesFacadeService {
    private plumeState = new PlumeState();

    private _isEnabledModule$ = new BehaviorSubject<boolean>(false);
    isEnabledModule$ = this._isEnabledModule$.asObservable();

    private _isEnabled$ = new BehaviorSubject<boolean>(false);
    isEnabled$ = this._isEnabled$.asObservable();

    private _allRuns$ = new BehaviorSubject<RunPlume[]>([]);
    allRuns$ = this._allRuns$.asObservable();

    private _allPoints$ = new BehaviorSubject<ControlPoint[]>([]);
    allPoints$ = this._allPoints$.asObservable();

    private _allSources$ = new BehaviorSubject<Source[]>([]);
    allSources$ = this._allSources$.asObservable();

    private _runConfig$ = new BehaviorSubject<RunConfig[]>([]);
    runConfig$ = this._runConfig$.asObservable();

    private _run$ = new BehaviorSubject<RunPlume>(null);
    run$ = this._run$.asObservable();

    getEvaluationTimeBegin() {
        return this.plumeState.evaluationTime.begin;
    }
    getEvaluationTimeEnd() {
        return this.plumeState.evaluationTime.end;
    }

    setEvaluationTimeBegin(time: number) {
        return this.plumeState.evaluationTime.begin = time;
    }
    setEvaluationTimeEnd(time: number) {
        return this.plumeState.evaluationTime.end = time;
    }

    getCurrentRunId() {
        return this.plumeState.currentRunId;
    }

    getCurrentMeasure() {
        return this.plumeState.currentMeasure;
    }

    isUpdating = false;
    isOpenPlums = false

    constructor(
        private coreFacade: CoreFacade,
        private sharedCoreFacade: SharedCoreFacade,
        private plumesApi: PlumesApi,
        private plumesCollectionService: PlumesCollectionService,
        public uiState: PlumeUiState,
        private groupFeaturesService: GroupFeaturesService,
        private store: Store,
        private time: Time,
        private routingService: RoutingService
    ) {
        this.store.select(selectGroupInfo).
        pipe(
            filter(groupInfo => !!groupInfo),
            take(1)
        )
        .subscribe((groupInfo) => {
            this.plumeState.currentMeasure = groupFeaturesService.getConfig(GroupExtConfigName.plumeMeasure);

            if (groupInfo?.allowPlumes) {
                this.isUpdating = true;
                this.updateAll().finally(() => {
                    this.isUpdating = false;
                });
            } else {
                this.resetAll();
            }
        });

        plumesApi.setErrorTokenCb(coreFacade.logOut);
        plumesApi.setErrorCb(coreFacade.showErrorPopup);

        time.timeUpdated.subscribe((val) => {
            this.updateTimeData(val.time);
        });

        routingService.pageChange$.subscribe(async (page) => {
            if (this.isOpenPlums == true) { // переход на другой модуль
                this.onDisableModule();
                this.isOpenPlums = false
            }
            if (page === namePages.plumes) {
                this.isOpenPlums = true
                this.onEnableModule();
            }
        });

        this.allPoints$.subscribe(async () => {
            if (this.getCurrentRunId()) {
                await this.updateRun(this.getCurrentRunId());
                // this.updateControlPointsValue(this.sharedCoreFacade.getCurrentTime());
            }
        });
    }

    updateTimeData(timestamp: number) {
        if (timestamp && this.getCurrentRunId()) {
            const time = round20Min(timestamp);
            this.updateControlPointsValue(time);
        }
    }

    enable() {
        this._isEnabled$.next(true);

        const runId = this.plumeState.lastRunId;
        this.plumeState.setCurrentRunId(runId);

        const run = this.plumesCollectionService.getOneRun(runId);
        this._run$.next(run);

        this.updateTimeData(this.time.getCurrent());
    }

    disable() {
        this.plumeState.clearCurrentRunId();
        this.uiState.clearCurrentPointValues();
        this.sharedCoreFacade.actionObservers.maybeCloseTimeline.next('plume');
        this._isEnabled$.next(false);
    }

    onEnableModule() {
        this._isEnabledModule$.next(true);

        const sub = this.allRuns$.pipe(
            first(runs => runs.length > 0),
            switchMapTo(this.store.select(selectMapLoaded)),
            filter(mapLoaded => mapLoaded),
            switchMapTo(this.allRuns$),
        ).subscribe(async (runs) => {
            await this.onRunSelected(runs[0].id);
            this.centringOnRun(runs[0].id);
        });

        this.isEnabledModule$.pipe(
            filter(isEnabled => !isEnabled)
        ).subscribe(() => {
            sub.unsubscribe();
        });
    }

    onDisableModule() {
        this.disable();

        this._isEnabledModule$.next(false);

        this.time.intervalUpdate.next({begin: getStndTimeBegin(), end: getStndTimeEnd(), allowUpdate: true});
    }

    private formSetError(error: FormError | HttpErrorResponse) {
        if (error instanceof FormError) {
            this.uiState.setFormError(error);
            return error
        } else
            this.coreFacade.showAdminPanelError(error);
    }

    private async updateAll() {
        this.updateRuns({
            evaluation_time__gte: moment(this.getEvaluationTimeBegin()).toJSON(),
            evaluation_time__lte: moment(this.getEvaluationTimeEnd()).toJSON()
        });

        const groupId = this.coreFacade.getGroupId();
        await this.plumesApi.getAllPoints(groupId);
        await this.plumesApi.getAllSources(groupId);
        await this.plumesApi.getRunConfig(groupId);

        this._allSources$.next(this.plumesCollectionService.getAllSources());
        this._allPoints$.next(this.plumesCollectionService.getAllPoints());
        this._runConfig$.next(this.plumesCollectionService.getRunConfig());
    }

    async updateRuns(options?: Partial<RunsQueryOptions>) {
        this.updateFilters(options);

        const groupId = this.coreFacade.getGroupId();
        await this.plumesApi.getAllRuns(groupId, this.getCurrentMeasure(), options);
        this._allRuns$.next(this.plumesCollectionService.getAllRuns());
    }

    updateFilters(options: Partial<RunsQueryOptions>) {
        if (options?.evaluation_time__gte) {
            this.setEvaluationTimeBegin(moment(options.evaluation_time__gte).valueOf())
        }

        if (options?.evaluation_time__lte) {
            this.setEvaluationTimeEnd(moment(options.evaluation_time__lte).valueOf())
        }
    }

    resetAll() {
        this.disable();
        this._allRuns$.next([]);
        this._allSources$.next([]);
        this._allPoints$.next([]);
        this._runConfig$.next([]);
    }

    loadRun(id: number) {
        this.plumeState.setCurrentRunId(id);
        const run = this.plumesCollectionService.getOneRun(id);
        this.sharedCoreFacade.setPlumeCurrentRun(run);
        this._run$.next(run);

        // TODO: force canvas to draw for the current timestamp
        this.time.timeUpdated
            .pipe(take(1))
            .subscribe((val) => {
                this.updateTimeData(val.time);
            });
    }

    async updateRun(id: number) {
        this.coreFacade.showMapLoader();
        await this.plumesApi.getRunDetail(id, this.getCurrentMeasure(), this.groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme));
        this.coreFacade.hideMapLoader();
        this.loadRun(id);
    }

    onRunSelected = async (runId: number) => {
        await this.updateRun(runId);

        const run = this.plumesCollectionService.getOneRun(runId);
        const begin = run.getFirstTimestamp();
        const end = run.getLastTimestamp();

        this.sharedCoreFacade.actionObservers.maybeCloseTimeline.next();

        this.time.intervalUpdate.next({begin, end, allowUpdate: false});

        this.enable();
    }

    centringOnRun(runId: number) {
        const run = this.plumesCollectionService.getOneRun(runId);
        const [lng, lat] = run.domain.centroid.coordinates;
        this.coreFacade.gotoMapLocation(lat, lng, run.domain.zoom);
    }

    // CONTROL POINTS -------------------------------------------------------------------------------
    createControlPoint = async (body: ControlPoint) => {
        body.group_id = this.coreFacade.getGroupId();
        const error = await this.plumesApi.createPoint(body);

        if (error) {
            this.formSetError(error);
            return false;
        }
        else {
            this.uiState.clearFormError();
            this._allPoints$.next(this.plumesCollectionService.getAllPoints());
            return true;
        }
    }

    editControlPoint = async (body: ControlPoint) => {
        body.group_id = this.coreFacade.getGroupId();
        const error = await this.plumesApi.editPoint(body);

        if (error) {
            this.formSetError(error);
            return false;
        }
        else {
            this.uiState.clearFormError();
            this._allPoints$.next(this.plumesCollectionService.getAllPoints());
            return true;
        }
    }

    deletePoint = async (pointId: number) => {
        await this.plumesApi.deletePoint(pointId);
        this._allPoints$.next(this.plumesCollectionService.getAllPoints());
    }

    getControlPointData(pointId: number) {
        const run = this.sharedCoreFacade.getPlumeCurrentRun();
        return run?.getPointValues(pointId, this.getCurrentMeasure());
    }

    getActiveControlPointId = (): number => {
        const compareStations = this.sharedCoreFacade.getComparedList();

        const station = compareStations?.find(s => s.type === 'geoPoint');
        return station ? station.id : null;
    }

    updateControlPointsValue = (time: number) => {
        const run = this.sharedCoreFacade.getPlumeCurrentRun();
        this.uiState.setCurrentPointValues(run.getCurrentPointsValues(time, this.getCurrentMeasure()));
    }

    // ---------------------------------------------------------------------------------------------

    loadSources() {
        const sources = this.plumesCollectionService.getAllSources();
        this._allSources$.next(sources);
    }

    loadRunConfig() {
        const config = this.plumesCollectionService.getRunConfig();
        this._runConfig$.next(config);
    }

    editRunConfig = async (props: {name?: string, schedule_period?: number, is_active?: boolean}) => {
        const config = {
            ...this.plumesCollectionService.getRunConfig()[0],
            ...props
        };

        const error = await this.plumesApi.editRunConfig(config);

        if (error) {
            this.formSetError(error);
            return false;
        }
        else {
            this.uiState.clearFormError();
            this.loadRunConfig();
            return true;
        }
    }
}
