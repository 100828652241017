<div class="search" [formGroup]="form">
    <input class="search__input ellipsis"
        formControlName="textInput"
        [attr.placeholder]="textPlaceholderInner"
        (focus)="onFocus()"
        (blur)="onBlur()"
    />
    <div class="search__loupe"
        [class.search__loupe--active]="focus"
    ></div>
    <cross-button class="search__clear"
                  [class.search__clear--active]="textInner"
                  (click)="clear()"
    ></cross-button>
</div>

