<div class="plumes-run-summary__label">{{TEXTS.PLUMES.nowOnMap}}</div>
<div *ngIf="!run" class="plumes-run-summary">
    {{TEXTS.PLUMES.noRunSelected}}
</div>
<div *ngIf="run" class="plumes-run-summary">
    <div class="plumes-run-summary__evaluated">
        <div class="plumes-run-summary__subtitle">
            {{TEXTS.PLUMES.runResults}}
        </div>
        <div class="plumes-run-summary__item"
            *ngIf="runFinished"
        >
            {{TEXTS.PLUMES.resultsAt}} {{formatDate(runFinished)}}
        </div>
    </div>
    <div class="plumes-run-summary__forecast">
        <div class="plumes-run-summary__subtitle">
            {{TEXTS.PLUMES.forecastOn}}
        </div>
        <div class="plumes-run-summary__item plumes-run-summary__item-bold"
            *ngIf="dateStart && dateEnd"
        >
            {{dateStart.isSame(dateEnd, 'day') ? formatTime(dateStart) : formatDate(dateStart)}} – {{formatDate(dateEnd)}}
        </div>
    </div>
</div>
