<div class="admin_panel">
    <div class="sidebar">
        <ca-menu>
            <div class="client-icon"
                *ngIf="assets.logo"
                [style.backgroundImage]="assets.logo"
                [style.backgroundSize]="assets.styleBackgroundSize"
            ></div>

            <group-change-selector *ngIf="(store.select(selectGroupList) | async).length > 1"></group-change-selector>

<!--            <ca-menu-button-->
<!--                name="listOM"-->
<!--                [isAvailable]="menuItemEnabled.posts()"-->
<!--                [isActive]="apActions.isActiveRoute(namePages.listOM)"-->
<!--                (activate)="openInPanel(namePages.listOM)"-->
<!--            ></ca-menu-button>-->

            <ca-menu-button
                name="postsAndDevices"
                [isAvailable]="menuItemEnabled.posts()"
                routerLinkActive="active"
                [routerLink]="namePages.networks"
            ></ca-menu-button>

            <ca-menu-button
                name="cityCard"
                [isAvailable]="menuItemEnabled.analytics()"
                routerLinkActive="active"
                [routerLink]="namePages.cityCard"
            ></ca-menu-button>

            <ca-menu-button
                name="plumes"
                [isAvailable]="menuItemEnabled.plumes()"
                routerLinkActive="active"
                [routerLink]="namePages.plumes"
            ></ca-menu-button>

            <ca-menu-button
                name="indoor"
                *ngIf="menuItemVisible.indoor | async"
                [isAvailable]="true"
                routerLinkActive="active"
                [routerLink]="namePages.indoor"
            ></ca-menu-button>

<!--            <ca-menu-button-->
<!--                name="listDevices"-->
<!--                [isAvailable]="menuItemEnabled.service()"-->
<!--                [isActive]="apActions.isActiveRoute(namePages.listDevices)"-->
<!--                (activate)="openInPanel(namePages.listDevices)"-->
<!--            ></ca-menu-button>-->

            <ca-menu-button
                name="notifications"
                [isAvailable]="menuItemEnabled.notifications()"
                routerLinkActive="active"
                [routerLink]="namePages.notifications"
            ></ca-menu-button>

            <ca-menu-button
                name="listUsers"
                [isAvailable]="menuItemEnabled.users()"
                routerLinkActive="active"
                [routerLink]="namePages.listUsers"
            ></ca-menu-button>

            <ca-menu-button
                name="configPanel"
                [isAvailable]="menuItemEnabled.settings()"
                routerLinkActive="active"
                [routerLink]="namePages.configPanel"
            ></ca-menu-button>

<!--             <ca-menu-button-->
<!--                name="dashboard"-->
<!--                [isAvailable]="!!apState.iAm"-->
<!--                [isActive]="apActions.isActiveRoute(namePages.dashboard)"-->
<!--                (activate)="openInPanel(namePages.dashboard)"-->
<!--            ></ca-menu-button>-->

            <cs-feedback></cs-feedback>
        </ca-menu>

        <div class="sidebar_container"
             [class.sidebar_container-active]="store.select(selectIsSidebarOpen) | async"
             [class.sidebar_container-full_width]="apActions.isActiveRoute(namePages.indoor)"
        >
            <ca-sidebar-toggle-button
                *ngIf="apActions.routeIsNotEmpty() && !apActions.isActiveRoute(namePages.indoor)"
                [isActive]="store.select(selectIsSidebarOpen) | async"
                (toggle)="store.dispatch(toggleSidebar())"
            ></ca-sidebar-toggle-button>

            <router-outlet></router-outlet>

            <message-api-response></message-api-response>
        </div>

        <dashboard-page
            *ngIf="apState.pagesArr[0] === namePages.dashboard"
            [devices]="apState.myDevices"
            (adminPanelCallback)="updateGroupInfo()"
        ></dashboard-page>
    </div>

    <div *ngIf="coreFacade.getIsAllLoaded()" class="admin_panel__notifications">
        <div class="admin_panel__error {{apState.error ? 'admin_panel__error-show' : ''}}">
            <div>{{TEXTS.COMMON.error}}</div>
            <div>{{apState.error}}</div>
        </div>
        <div class="admin_panel__success {{apState.success ? 'admin_panel__success-show' : ''}}">
            {{TEXTS.LIST_OM.changesSaved}}
        </div>
    </div>
</div>
