import * as moment from 'moment';
import * as Color from 'color';

import {
    AqiHistory_model,
    ChartControl_model,
    City_model,
    DATA_INTERVAL_TYPES,
    DataForCreateSeries,
    DAY_MS,
    IntervalType,
    IntervalV2Type,
    Marker_model,
    MeasureCoef_model,
    MeasuresInfoFormat,
    MonitoringObject,
    OriginChartData_model,
    StartOutsideParams,
    StationForMapPage_model,
    USER_KEY
} from './namespace';

import { TEXTS } from './texts/texts';

declare const window: any;

export function isOldBrowser() {
    if (!window.navigator)
        return true;

    const ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0)
        return true;

    return false;
}

export function detectTouchDevice() {
    const prefixes = ['-webkit-', '-moz-', '-o-', '-ms-'];

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof window.DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return window.matchMedia(query).matches;
}

function _findPoint(data, currentX) {
    let delta = 24 * 60 * 60 * 1000; // 1 day
    let originalIndex;

    for (let i = 0; i < data.length; i++) {
        let val = data[i];
        val = val ? (val.x ? val.x : val[0]) : 0;
        const abs = Math.abs(currentX - val);
        if (abs < delta) {
            delta = abs;
            originalIndex = i;
        }
    }

    return originalIndex;
}

function _findPointForCoordinates(data, coordinateX) {
    let delta = 5; // 1 dey
    let originalIndex;

    for (let i = 0; i < data.length; i++) {
        const abs = Math.abs(coordinateX - data[i].clientX);
        if (abs < delta) {
            delta = abs;
            originalIndex = i;
        }
    }

    return originalIndex;
}

export function findPointFor(data: {}[], currentX: number, name: string, delta: number = DAY_MS) {
    let originalIndex;

    for (let i = 0; i < data.length; i++) {
        const val = data[i][name] || 0;
        const abs = Math.abs(currentX - val);
        if (abs < delta) {
            delta = abs;
            originalIndex = i;
        }
    }

    return originalIndex;
}

export function findMuchPointInSeries(data, currentX) {
    return _findPoint(data, currentX);
}

export function findMuchPointInAllSeries(series, currentX) {
    let arrPoints = series.map(serie => {
        const data = serie.data.length ? serie.data : serie.groupedData;

        if (!data || !data.length)
            return null;

        const index = _findPoint(data, currentX);
        return data[index];
    });

    arrPoints = arrPoints.filter(a => !!a);

    if (!arrPoints.length)
        return null;

    const index = _findPoint(arrPoints, currentX);
    return arrPoints[index];
}

export function findPointInAllSeriesForCoordinates(series, coordinateX) {
    let arrPoints = series.map(serie => {
        const data = serie.points;

        if (!data.length)
            return null;

        const pointIndex = _findPointForCoordinates(data, coordinateX);

        if (isFalseNumber(pointIndex))
            return null;

        return data[pointIndex];
    });

    arrPoints = arrPoints.filter(a => a && a.y !== null);

    if (!arrPoints.length)
        return null;

    const index = _findPointForCoordinates(arrPoints, coordinateX);
    return arrPoints[index];
}

export function findAqiInMarker(marker: Marker_model, currentTime: number): { aqi: number, lagMinutes: number } {
    const m = moment(currentTime);
    if (m.minute() || m.second() || m.millisecond())
        currentTime = moment(currentTime).startOf('hour').add(1, 'hour').valueOf(); // round up of hour

    const minute = 60 * 1000; // 1 hour

    let lag = 999999 * minute;
    let nearestAqi = 0;

    const found = marker.aqis.find((el) => {
        if (!el.aqi) {
            return false;
        }

        const diff = Math.abs(el.timestamp - currentTime);

        if (lag >= diff) {
            lag = diff;
            nearestAqi = el.aqi;
        }

        if (el.timestamp === currentTime) {
            return true;
        }
    });

    if (found) {
        return {aqi: found.aqi, lagMinutes: 0};
    } else if (nearestAqi) {
        return {aqi: nearestAqi, lagMinutes: Math.round(lag / minute)};
    } else {
        return {aqi: 0, lagMinutes: 999999};
    }
};

export function findExactlyPoint(serie, point) {
    return serie.data.find(p => {
        if (!p)
            return false;

        const val = p.x ? p.x : p[0];
        return val === point.x || val === point[0];
    });
}

export function excludeNavigatorSerie(series: any[]): any[] {
    // TODO: test
    return series.filter(s => !(s.sharedClipKey && s.sharedClipKey.indexOf('navigator') > -1));
}

// ----------------------------------------------------------------------------------------------------------------------

export class ChartActions {
    chartControl: ChartControl_model;

    setChartControl(chartControl: ChartControl_model): void {
        this.chartControl = chartControl;
    }

    updateSeries(): void {
        throw new Error('updateSeries: Method not implemented ');
    }

    updateChart(deleteSelectLine?: boolean): void {
        throw new Error('updateChart: Method not implemented');
    }

    updateMeasures(fromDragFlag?: boolean): void {
        throw new Error('updateMeasures: Method not implemented');
    }

    highlight(timestamp: number): void {
        throw new Error('highlight: Method not implemented');
    }

    updateMeasuresFromCoordinates(timestamp: number): void {
        throw new Error('highlight: Method not implemented');
    }
}

export let isFalseNumber = (a: number) => isNaN(a) || a === null || a === undefined; // 0 - true

export let isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);

export let createSeriesID = (id: number, measure: string) => id + '-' + measure;

export let findIndexInForChart = (forChartMesure: [number, number][], timestamp: number) => {
    return forChartMesure.findIndex((old, i) => old[0] === timestamp);
};

export function declOfNum(num: number, titles: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        (num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]
        ];
    // declOfNum(count, ['найдена', 'найдено', 'найдены']);
}

export function findMaxMinInForChart(data: [number, number][]): { min: number, max: number } {
    let min;
    let max;

    for (let i = 0; i < data.length; i++) {
        const val = data[i][1];

        if (min === undefined && !isFalseNumber(val))
            min = val;
        if (max === undefined && !isFalseNumber(val))
            max = val;

        if (val !== null) {
            max = Math.max(val, max);
            min = Math.min(val, min);
        }
    }

    return {min, max};
}

export function averageChartData(mos: Readonly<DataForCreateSeries[]>): OriginChartData_model {
    const averageData = {};

    mos.forEach((mo) => {
        Object.keys(mo.originChartData).forEach(key => {
            if (!mo.originChartData[key] || !mo.originChartData[key].data) {
                return;
            }

            if (!averageData[key]) {
                averageData[key] = copyObj(mo.originChartData[key]);
            } else {
                averageData[key].data.forEach((d, i) => {
                    if (!mo.originChartData[key].data[i] || isFalseNumber(mo.originChartData[key].data[i][1])) {
                        return;
                    }

                    if (isFalseNumber(d[1]) && mo.originChartData[key].data[i][1]) {
                        d[1] = mo.originChartData[key].data[i][1];
                    } else {
                        d[1] += mo.originChartData[key].data[i][1];

                        // делитель
                        if (!d[2]) {
                            d[2] = 2;
                        } else {
                            d[2]++;
                        }
                    }
                })
            }
        });
    });

    Object.keys(averageData).forEach(key => {
        averageData[key].data.forEach((d) => {
            if (d[2]) {
                d[1] = d[1] / d[2];
                d.splice(2, 1);
            }
        });

        const {min, max} = findMaxMinInForChart(averageData[key].data);

        averageData[key].min = min;
        averageData[key].max = max;
    });

    return averageData;
}

export let applyCoef = (val, coef: { a: number, b: number } = {
    a: 1,
    b: 0
}): number => isFalseNumber(val) ? null : val * coef.a + coef.b; // проблема когда val = 0 || null
export let dissapplyCoef = (val, coef: { a: number, b: number }) => (val - coef.b) / coef.a;

export function calcCommonMeasuresCoef(comparedStations: Readonly<DataForCreateSeries[]>, selectMeasures: MeasuresInfoFormat[]): MeasureCoef_model {
    const commonMeasures: {
        [textMeasure: string]: { min: number, max: number }
    } = {}; // ppm, %....

    selectMeasures.forEach(m => {
        const cM = commonMeasures[m.unit];

        const filter = comparedStations.filter(s => s.originChartData && s.originChartData[m.name]);

        const min = Math.min(...filter.map(s => s.originChartData[m.name].min));
        const max = Math.max(...filter.map(s => s.originChartData[m.name].max));

        if (!cM) {
            commonMeasures[m.unit] = {min, max};
        } else {
            cM.min = Math.min(cM.min, min);
            cM.max = Math.max(cM.max, max);
        }
    });

    const coef: MeasureCoef_model = {};

    selectMeasures.forEach(m => {
        const measureName = m.type;

        const {min, max} = commonMeasures[m.unit];
        if (isFalseNumber(min) || isFalseNumber(max))
            return;

        const bottom = -100;
        const top = 200;
        const range = max - min;
        const a = top / (range || 1);
        const b = bottom - min * a;

        coef[measureName] = {a, b};
    });

    return coef;
}

export function filterEmptyMeasuresFromOriginal(originChartData: OriginChartData_model): OriginChartData_model {
    Object.keys(originChartData).forEach(key => {
        if (isFalseNumber(originChartData[key].min) && isFalseNumber(originChartData[key].max)) {
            delete originChartData[key];
        }
    });

    return originChartData;
}

export function copyObj(original) {
    if (!original)
        return original; // возвратит примитив, undefined, null
    // объект должен быть без функций, сложных объектов, вроде Date и циклических ссылок
    return JSON.parse(JSON.stringify(original));
}

export function isTimelineAqiLowDetail(timeBegin: number, timeEnd: number): boolean {
    return Math.abs(moment(timeBegin).diff(moment(timeEnd), 'days')) > 28;
}


export function getTimelineInterval(timeBegin: number, timeEnd: number): IntervalType {
    const days = Math.abs(moment(timeBegin).diff(moment(timeEnd), 'days'));

    if (days <= 5)
        return 2;

    if (days <= 28)
        return 3;

    return 4;
}

export function getApiV2Interval(timeBegin: number, timeEnd: number): IntervalV2Type {
    const days = Math.abs(moment(timeBegin).diff(moment(timeEnd), 'days'));

    if (days <= 5)
        return '20m';

    if (days <= 28)
        return '1h';

    return '1d';
}

export function createEmptyTimelineAqiHistory(timeBegin: number, timeEnd: number): AqiHistory_model[] {
    const intervalMin = DATA_INTERVAL_TYPES[getTimelineInterval(timeBegin, timeEnd)];

    const arrAqi = [];
    const time = moment(timeBegin);
    while (time <= moment(timeEnd)) {
        arrAqi.push({
            aqi: 0,
            time: time.valueOf()
        })
        time.add(intervalMin, 'minute');
    }

    return arrAqi;
}

export function differentObject(a: any, b: any, revert: boolean) {
    let ret = false;
    if ((!b && a) || (!a && b))
        return true;

    for (const key in a) {
        if (a.hasOwnProperty(key)) {
            if (typeof a[key] !== typeof b[key])
                return true;

            if (typeof a[key] === 'object')
                ret = differentObject(a[key], b[key], false);
            else if (a[key] !== b[key])
                return true;

            if (ret)
                return ret;
        }
    }

    if (revert) {
        return differentObject(b, a, false);
    }
}

export function diffMarkers(a: CopyMarkerObj[], b: Marker_model[]) {
    if (a.length !== b.length) {
        return true;
    }

    for (let i = 0; i < a.length; i++) {
        if (
            a[i].opacity !== b[i].opacity ||
            a[i].aqi !== b[i].aqi ||
            a[i].over !== b[i].over
        ) {
            return true;
        }
    }

    return false;
}

export class CopyMarkerObj {
    opacity: number;
    aqi: number;
    over: boolean;
}

export function copyDiffMarkers(markers: Marker_model[]): CopyMarkerObj[] {
    return markers.map(m => ({
        opacity: m.opacity,
        aqi: m.aqi,
        over: m.over
    }));
}


// -----------------------------------------------------------SWIPER------------------------------------------------

export class SwipeElementPropsBounds {
    /*максимално и минимально перетаскиваемая граница в пикселях,*/
    max: number;
    changeLine?: number; // граница после которой иидет автоматическая прокрутка, берётся по модулю и возвращается направление
    min: number;
}

export class SwipeElementProps {
    touchId: string; // за что захватываем
    dragId: string; // элемент котоый перемещаем
    type: 'vertical' | 'horizontal';
    stopPropagation?: boolean;
    bounds: SwipeElementPropsBounds;
    cbCssChange: (px: number) => { cssProp: string; val: string }; // какой именно параметр стиля меняем и на сколько, val без точки с запятой
    cbMoreChangeLine?: (direction: number | 1 | -1) => void; // срабатывает если мы свайпнули больше changeLine, возвращает 1 положительное или -1 отрицательное направление
    cbLessChangeLine?: (direction: number | 1 | -1) => void;  // срабатывает если мы свайпнули меньше changeLine
}

export class SwipeElement extends SwipeElementProps {
    touchEl: HTMLElement;
    dragEl: HTMLElement;

    shiftY: number;
    shiftX: number;

    startY: number;
    startX: number;

    constructor(props: SwipeElementProps) {
        super();

        Object.assign(this, props); // копирование всех пропсов в this

        this.touchEl = document.getElementById(this.touchId);
        this.dragEl = document.getElementById(this.dragId);

        this.touchEl.ondragstart = () => false;
        this.touchEl.ontouchstart = this.ontouchstart;
    }

    public changeBounds = (bounds: SwipeElementPropsBounds) => {
        this.bounds = bounds;
    };

    private ontouchstart = (e: TouchEvent) => {
        this.dragEl.style.transition = 'none';

        this.startY = e.touches[0].pageY;
        this.startX = e.touches[0].pageX;

        document.ontouchmove = this.ontouchmove;
        document.ontouchend = this.ontouchend;

        if (this.stopPropagation) {
            return false;
        }
    };

    private ontouchmove = (e: TouchEvent) => {
        if (this.type === 'vertical') {
            this.moveY(e.touches[0].pageY);
        }
        if (this.type === 'horizontal') {
            this.moveX(e.touches[0].pageX);
        }

        window.getSelection().removeAllRanges(); // убираем выделение текста
    };

    private ontouchend = () => {
        const shift = this.type === 'vertical' ? this.shiftY : this.shiftX;

        const changeLine = isFalseNumber(this.bounds.changeLine) ? 70 : this.bounds.changeLine;

        if (this.cbMoreChangeLine && Math.abs(shift) > changeLine)
            this.cbMoreChangeLine(Math.sign(shift));

        if (this.cbLessChangeLine && Math.abs(shift) < changeLine)
            this.cbLessChangeLine(Math.sign(shift));

        this.dragEl.style.transition = '';

        // зануляем инлайновые стили
        const css = this.cbCssChange(0);
        if (css) {
            this.dragEl.style[css.cssProp] = '';
        }

        document.ontouchmove = null;
        document.ontouchend = null;
    };

    private moveY = (pageY: number) => {
        this.shiftY = this.startY - pageY;

        this.changePosition(this.shiftY);
    };

    private moveX = (pageX: number) => {
        this.shiftX = pageX - this.startX;

        this.changePosition(this.shiftX);
    };

    changePosition(shift: number) {
        if (shift > this.bounds.max || shift < this.bounds.min)
            return;

        const css = this.cbCssChange(shift);
        if (css) {
            this.dragEl.style[css.cssProp] = css.val;
        }
    }
}

export function testLocalStorage(): boolean {
    try {
        return window.sessionStorage && window.localStorage;
    } catch (e) {
        return false;
    }
}

export function getUserSavedData() {
    if (!testLocalStorage())
        return;

    try {
        return JSON.parse(localStorage.getItem(USER_KEY));
    } catch (err) {
        // there is no saved user data
        return null;
    }
}

/// -----------------------------------------------------TEXTS----------------------------------------------------

export function getRandomText(arr: string[]) {
    return arr[Math.round(Math.random() * (arr.length - 1))];
}

function transformAqiGradation(aqi: number): 0 | 1 | 2 {
    if (aqi <= 3)
        return 0;
    if (aqi <= 6)
        return 1;
    if (aqi <= 10)
        return 2;
}

export function getQualityText(nowAqi: number): string {
    const i = transformAqiGradation(nowAqi);
    return getRandomText(TEXTS.QUALITY_CURRENT[i]);
}

export function getQualityForecastText(nowAqi: number, lastAqi: number): string {
    if (!nowAqi || !lastAqi) {
        return '';
    }

    const nowIndex = transformAqiGradation(nowAqi);

    let text: string[][];
    if (nowAqi < lastAqi)
        text = TEXTS.QUALITY_FORECAST['now<last'];
    if (nowAqi === lastAqi)
        text = TEXTS.QUALITY_FORECAST['now=last'];
    if (nowAqi > lastAqi)
        text = TEXTS.QUALITY_FORECAST['now>last'];

    return getRandomText(text[nowIndex]);
}

export function is24hDiapason(time: number): boolean {
    return Math.abs((time - new Date().getTime()) / (60 * 60 * 1000)) <= 24;
}

export let convertMonitoringObjectToStationForMapPage_model = (mo: MonitoringObject): StationForMapPage_model => {
    return {
        id: mo.id,
        name: mo.name,
        tzOffset: mo.tzOffset,
        isOurStation: null,
        originChartData: mo.originChartData,
        pubName: mo.pubName,
        measuresVal: mo.measuresVal,
        lastPacketID: mo.lastPacketId,
        lat: mo.geoLatitude,
        lng: mo.geoLongitude,
        type: null,
        loading: null
    }
};

export function getAqiForZones(zone: number[], val: number): number {
    let findIndex;
    zone.find((zVal, i) => {
        const prev = zone[i - 1];
        const current = zone[i];

        const _prev = isFalseNumber(prev) ? true : prev < val;
        const _current = isFalseNumber(current) ? true : val < current;

        if (_prev && _current) {
            findIndex = i;
            return true;
        }
        return false;
    });

    return findIndex + 1;
}

export function parseUrl(): StartOutsideParams {
    const retObj: StartOutsideParams = {};
    const url = window.location.search;

    if (url) {
        const urlParam /*: [string, string][]*/ = decodeURIComponent(url.substr(1)).split('&').map(str => str.split('='));

        urlParam.forEach(arr => {
            try {
                if (arr[0] && arr[1])
                    retObj[arr[0]] = ~arr[1].indexOf('[') ? JSON.parse(arr[1]) : arr[1];
            } catch (e) {
                console.error(e);
            }
        });
    }

    if (window.location.hash) {
        retObj.hash = window.location.hash.substring(1);
    }

    return retObj;
}

export function clearURL() {
    const position = window.location.href.indexOf('?');
    if (position > -1)
        history.replaceState(null, null, window.location.href.substring(0, position));
}

export function getCityIdForCoords(cities: City_model[] = [], lat: number, lng: number): string {
    const city = cities.find(c =>
        (c.bounds.west <= lng && lng <= c.bounds.east) &&
        (c.bounds.south <= lat && lat <= c.bounds.north)
    );

    return city?.id || null;
}

export let isFuture = (time) => new Date().getTime() < (time + 60 * 60 * 1000);

const min20 = 20 * 60 * 1000;
export const round20Min = (time: number) => Math.round(time / min20) * min20;

export const roundValue10 = (val: number) => {
    if (typeof val !== 'number')
        return val;

    return Math.round(val * 10) / 10;
}

export function toDataURL(base64: string, type: string) {
    return `data:image/${type};base64,${base64}`;
}

export function createBoundaries(bbox: number[]): [number, number][] {
    const [west, south, east, north] = bbox;

    return [
        [west, north],
        [east, north],
        [east, south],
        [west, south]
    ];
}

export function createBBoxFromRectangle(coordinates: number[][]): number[] {
    let bbox = [];

    if (coordinates.length === 4) {
        const [west, north] = coordinates[0];
        const [east, south] = coordinates[2];

        bbox = [west, south, east, north];
    }

    return bbox;
}

export function createTimeSequence(timeBegin: number, timeEnd: number): Set<number> {
    const timeSequence: Set<number> = new Set();

    const time = moment(timeBegin).startOf('hour');
    while (time < moment(timeEnd)) {
        timeSequence.add(time.valueOf());
        time.add(1, 'hour');
    }

    return timeSequence;
}

// TODO: unify with createTimeSequence
export function createTimeSequencePlumes(timeBegin: number, timeEnd: number): Set<number> {
    const timeSequence: Set<number> = new Set();

    const time = moment(timeBegin);
    while (time < moment(timeEnd)) {
        timeSequence.add(time.valueOf());
        time.add(20, 'minutes');
    }

    return timeSequence;
}

function base64toBlob(b64Data, contentType = 'application/octet-binary', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const saveDataToDisk = (data, filename) => {
    const blob = base64toBlob(data);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    downloadLink.setAttribute('download', filename);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
}

export const hideHtmlLoader = () => {
    const elemStyle = window.commonLoader?.style;

    if (!elemStyle) {
        return;
    }

    elemStyle.opacity = '0';
    setTimeout(() => {
        elemStyle.display = 'none';
    }, 200);
}

export function lightenColor(color: string) {
    return Color(color).alpha(0.8).rgb().string();
}
