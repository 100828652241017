// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../imgs/arrow_back_blue.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_page{display:flex;justify-content:space-between;align-items:flex-end}.header_page__name{font-weight:600;font-size:23px;letter-spacing:-.7705px;color:#21242b}.header_page__back{display:inline-block;height:16px;line-height:17px;padding-left:17px;margin-bottom:20px;font-size:16px;font-weight:400;letter-spacing:-.4px;color:#6097f3;white-space:nowrap;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 no-repeat;background-size:contain;cursor:pointer;transition:all .2s}.header_page__back:hover{opacity:.7}.header_page__btn_open_page{color:#6097f3;font-weight:400;font-size:14px;line-height:16px;letter-spacing:-.4px;cursor:pointer}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
