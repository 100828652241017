<div class="edit_user">
    <div class="edit_user__wrapper">
        <header-page
            [cbBack]="stateService.setDefaultState"
            [textObject]="{
                titlePage: isEditMode ? TEXTS.LIST_USERS.editUsers : TEXTS.LIST_USERS.addUsers,
                btnBack: TEXTS.ADMIN_PANEL.back
            }"
        ></header-page>
    </div>
    <div>
        <div class="edit_user__title_edit">{{TEXTS.LOGIN_WINDOWS.emailAddress}}</div>
        <input type="email" minlength="1" autocomplete="off"
            [ngClass]="{
                'edit_user__input_name': true,
                'edit_user__input_name-error': !usersControl.email.valid && usersControl.email.enabled
            }"
            [formControl]="usersControl.email"
        />
        <div *ngIf="usersControl.email.invalid && usersControl.email.enabled" class="edit_user__alert_error">
            <div *ngIf="usersControl.email.errors.minLength || usersControl.email.errors.email">
                {{TEXTS.LIST_USERS.incorrectEmail}}
            </div>
            <div *ngIf="usersControl.email.errors.emailInGroupValidator" [innerHTML]="TEXTS.LIST_USERS.emailInGroup"></div>
        </div>
        <div *ngIf="newUserProps" class="edit_user__title_edit">{{TEXTS.LIST_USERS.login}}</div>
        <input type="text" minlength="1" autocomplete="off"
            *ngIf="newUserProps"
            [ngClass]="{
                'edit_user__input_name': true,
                'edit_user__input_name-error': !usersControl.login.valid && usersControl.login.enabled
            }"
            [formControl]="usersControl.login"
        />
        <div *ngIf="usersControl.login.invalid && usersControl.login.enabled" class="edit_user__alert_error">
            {{TEXTS.LIST_USERS.invalidLogin}}
        </div>
    </div>

    <div *ngIf="newUserProps" class="edit_user__title_edit">{{TEXTS.LIST_USERS.accessSelect}}</div>
    <div *ngIf="newUserProps" class="edit_user__posts_filter-wrapper" (clickOutside)="hideUserRoles()">
        <div class="edit_user__posts_filter edit_user__input_name input_surrogate"
            [ngClass]="{
                'edit_user__posts_filter-disables': !newUserProps
            }"
            (click)="toggleUsersRole($event)"
        >
            {{newUserProps?.roleId ? (newUserProps?.roleId == 2 ? TEXTS.LIST_OM.operator : TEXTS.LIST_OM.observer) : TEXTS.LIST_USERS.noSelect}}
            <div [ngClass]="{
                'edit_user__input_name__arrow': true,
                'edit_user__input_name__arrow-active': showUsersRole
            }"></div>
        </div>
        <div class="edit_user__posts_filter-popup" *ngIf="showUsersRole">
            <div class="edit_user__posts_filter-input scroll">
                <div class="edit_user__posts_filter-select"
                    (click)="newUserProps.roleId = 2; showUsersRole = false; stopPropagation($event)"
                >
                    {{TEXTS.LIST_OM.operator}}
                </div>
                <div class="edit_user__posts_filter-select"
                    (click)="newUserProps.roleId = 3; showUsersRole = false; stopPropagation($event)"
                >
                    {{TEXTS.LIST_OM.observer}}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="newUserProps?.roleId == 2" class="edit_user__description" [innerHTML]="TEXTS.LIST_USERS.operator"></div>
    <div *ngIf="newUserProps?.roleId == 3" class="edit_user__description" [innerHTML]="TEXTS.LIST_USERS.observer"></div>

    <div *ngIf="newUserProps?.roleId == 3" class="edit_user__control_select_wrapper">
        <control-select
            format="items"
            keyValue="id"
            keyName="name"
            optionsWidth="100%"
            classButton=""
            [text]="newUserProps?.linksToMo?.length
                ? selectedPostsText(newUserProps.linksToMo.length)
                : TEXTS.NOTIFICATIONS.noneSelected"
            [title]="TEXTS.NOTIFICATIONS.selectPosts"
            [items]="apState.monitoringObjects"
            [model]="newUserProps.linksToMo"
            [buttonsSelectAll]="false"
            (modelChange)="updateUsersMO($event)"
        ></control-select>
    </div>

    <div class="action_button__wrapper edit_user__buttons_save_cancel">
        <ca-button type="cancel"
            (clickAction)="stateService.setDefaultState()"
        >
            {{TEXTS.COMMON.cancel}}
        </ca-button>
        <ca-button type="primary"
            (clickAction)="apply()"
            [disabled]="isDisabled()"
        >
            <ca-spinner *ngIf="saving; else showContent"></ca-spinner>
            <ng-template #showContent>
                {{isEditMode ? TEXTS.COMMON.save : isUserFound ? TEXTS.LIST_USERS.add : TEXTS.LIST_USERS.create}}
            </ng-template>
        </ca-button>
    </div>
</div>
