import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'plume-source-marker',
    templateUrl: 'plume-source-marker.component.html',
    styleUrls: ['plume-source-marker.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlumeSourceMarkerComponent {
    @Input() name: string;
}
