import { Component } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { TabModel } from 'src/namespace';
import { ControlPoint } from 'projects/cityscreen/src/modules/plumes/services/models/control-point-model';
import Actions from 'map/actions';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';

enum PlumesPages {
    SOURCES = 'sources',
    CONTROL_POINTS = 'control-points',
    CALCULATION_RESULTS = 'calculation-result'
}

enum PlumesEditPages {
    ADD_CHECKPIONT = 'add-checkpoint',
    EDIT_CALCULATION = 'edit-calculation'
}

@Component({
    selector: 'plumes-page',
    templateUrl: 'plumes-page.component.html',
    styleUrls: ['./plumes-page.component.less']
})
export class PlumesPage {
    texts = TEXTS;

    tabs: TabModel[] = [
        {
            title: TEXTS.PLUMES.runResults,
            type: PlumesPages.CALCULATION_RESULTS
        },
        {
            title: TEXTS.PLUMES.runConfiguration,
            type: PlumesPages.SOURCES
        },
        {
            title: TEXTS.PLUMES.controlPoints,
            type: PlumesPages.CONTROL_POINTS
        }
    ];

    editMode = false;
    selectedTab = this.tabs[0];
    titleEditPage: string;

    plumesEditPages = PlumesEditPages;
    plumesPages = PlumesPages;
    currentPage = PlumesPages.CALCULATION_RESULTS;
    currentEditPage = PlumesEditPages.ADD_CHECKPIONT;
    controlPointForEdit: ControlPoint;

    constructor(readonly actions: Actions, public sharedCoreFacade: SharedCoreFacade) {}

    showSelectedTab(tab: TabModel) {
        this.selectedTab = this.tabs.find(t => t === tab);
        this.currentPage = tab.type;
    }

    openAddCheckpoint = () => {
        this.controlPointForEdit = null;
        this.editMode = true;
        this.currentEditPage = this.plumesEditPages.ADD_CHECKPIONT;
        this.titleEditPage = this.texts.PLUMES.createControlPointTitle;
    }

    closeEditMode = () => {
        this.editMode = false;
    }

    openEditCheckpoint = () => {
        this.editMode = true;
        this.currentEditPage = this.plumesEditPages.ADD_CHECKPIONT;
        this.titleEditPage = this.texts.PLUMES.editControlPointTitle;
    }

    openCalculationResultsEdit = () => {
        this.editMode = true;
        this.currentEditPage = this.plumesEditPages.EDIT_CALCULATION;
    }
}
