<div class="chart-header">
    <div class="chart-title">
        {{ title }}
    </div>
    <div class="chart-tooltip">
        <ca-info-icon
                [active]="true"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipTemplate]="tooltipAllHistory"
        ></ca-info-icon>
        <ng-template #tooltipAllHistory>
            <ca-info-tooltip
                    [text]="tooltip1"
                    [description]="tooltip2"
            ></ca-info-tooltip>
        </ng-template>
    </div>
</div>
