export const NOTIFIACTIONS_CONFIG = {
    id: 0,
    title: '',
    isActive: false,
    type: 0,
    isForEventFeed: false,
    forUserIds: [],
    emailsList: [],
    isForAllMos: true,
    isForAllValues: true,
    moItems: [],
    valueItems: [],
    excessLevel: 1.5
};

export const MAX_INLINE_MOS = 3;
