import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TabModel } from 'src/namespace';

@Component({
    selector: 'switch-item',
    template: `
        <div class="ca_switch_item">
            <ng-container *ngFor="let tab of tabs">
                <div [ngClass]="{
                        'ca_switch_item__tab noselect': true,
                        'ca_switch_item__tab-active': tab === currentTab
                    }"
                    (click)="selectTab(tab)"
                    [innerHTML]="tab.title"
                    [attr.data-cy]="tab.type"
                ></div>
            </ng-container>
        </div>
    `,
    styleUrls: ['switch-item.component.less']
})
export class SwitchItemComponent{
    @Input() tabs: TabModel[];
    @Input() currentTab: TabModel;
    @Output() action = new EventEmitter<TabModel>();

    selectTab(tab: TabModel) {
        this.action.emit(tab);
    }
}
