import { Injectable } from '@angular/core';
import { LngLatBoundsLike, LngLatLike, RasterSource } from 'mapbox-gl';

import { STND_GLOBAL_MIN_ZOOM, STND_CITY_MAX_ZOOM } from 'src/config';
import { PM10 } from 'src/namespace';
import { Subject } from 'rxjs';

export enum GroupExtConfigName {
    startModule = 'startModule',
    locationWidget = 'locationWidget',
    numberedAqi = 'numberedAqi',
    defaultMo = 'defaultMo',
    mapSettings = 'mapSettings',
    featuresLayer = 'featuresLayer',
    plumeMeasure = 'plumeMeasure',
    chartMinMax = 'chartMinMax',
    chartSingleSelect = 'chartSingleSelect',
    tilePlayerSettings = 'tilePlayerSettings',
    MeasureScheme = 'MeasureScheme',
    showQRCode = 'showQRCode'
}

export interface GroupMapSettings {
    accessToken?: string;
    tiles?: string[];
    tileSize?: number;
    center?: LngLatLike;
    bounds?: LngLatBoundsLike;
    zoom?: number;
    minzoom?: number;
    maxzoom?: number;
}

export interface GroupTilePlayerSettings {
    datesRange: string[];
    timeStep: number;
    layerId?: string;
    opacity?: number;
    dateFormat?: string;
    layer: RasterSource;
}

export interface GroupChartConfig {
    [measureName: string]: {
        min: number;
        max: number;
        intervalMajor: number;
        intervalMinor: number;
    };
}

export interface FeaturesConfigType {
    [GroupExtConfigName.startModule]?: string; // example: plume
    [GroupExtConfigName.locationWidget]?: string; // cityId
    [GroupExtConfigName.numberedAqi]?: boolean;
    [GroupExtConfigName.defaultMo]?: number;
    [GroupExtConfigName.mapSettings]?: GroupMapSettings;
    [GroupExtConfigName.chartMinMax]?: GroupChartConfig;
    [GroupExtConfigName.chartSingleSelect]?: boolean;
    [GroupExtConfigName.tilePlayerSettings]?: GroupTilePlayerSettings;
    [GroupExtConfigName.MeasureScheme]?: string;
    [GroupExtConfigName.plumeMeasure]?: string;
    [GroupExtConfigName.showQRCode]?: boolean;
};

export const DEFAULT_CONFIG: FeaturesConfigType = {
    startModule: null,
    locationWidget: null,
    numberedAqi: null,
    defaultMo: null,
    plumeMeasure: PM10,
    mapSettings: {
        center: {
            lat: 57,
            lng: 57
        },
        bounds: [
            {
                lng: -360,
                lat: -84
            },
            {
                lng: 360,
                lat: 84
            }
        ],
        zoom: 2,
        minzoom: STND_GLOBAL_MIN_ZOOM,
        maxzoom: STND_CITY_MAX_ZOOM - 1.1
    }
};

@Injectable({
    providedIn: 'root'
})
export class GroupFeaturesService {
    private features: FeaturesConfigType = DEFAULT_CONFIG;

    private _ready$ = new Subject<void>();
    ready$ = this._ready$.asObservable();

    setFeatures(features: FeaturesConfigType) {
        try {
            features = JSON.parse(JSON.stringify(features));
        } catch (e) {}

        this.features = {
            ...DEFAULT_CONFIG,
            ...(features || {}),
            mapSettings: {
                ...DEFAULT_CONFIG.mapSettings,
                ...(features?.mapSettings || {})
            }
        };

        this._ready$.next();
    }

    getConfig(name: GroupExtConfigName) {
        return this.features[name] || DEFAULT_CONFIG[name];
    }
}
