import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ErrorResult, PacketsValueTypeItem } from 'harvester/UiAdminProject8/src/commonData/models';
import { measureScheme, ModelData, StationData, WindowGlobalVars } from 'src/namespace';
import { copyObj } from 'src/utils';
import { ApiModels } from '../api-models';
import { DataProvider3 } from '../dataProvider/DataProvider';
import { CityairMapProvider } from './cityairMapProvider';
import {
    GetMoPacketsApiRequest,
    HistogramAqi10Request,
    ICityairMapProvider,
} from './cityairMapModels';
import {
    CityairMapDataTransformerResponse,
    histogramTransformer,
} from './cityairMapDataTransformerResponse';
import { MoItem } from './cityairMapDtos';

declare const window: WindowGlobalVars;

export class CheckLongPending {
    start: number;
    interval;

    constructor() {
        this.start = new Date().getTime();

        this.interval = setInterval( () => {
            if (new Date().getTime() - this.start > 35000) {
                clearInterval(this.interval);

                window.sentryAddBreadcrumb?.({
                    message: 'GetTimelineInfo дольше 35 секунд'
                });
            }
        }, 100);
    }

    end = () => clearInterval(this.interval)
}

@Injectable({
    providedIn: 'root'
})
export class MapApiProvider extends ApiModels {
    cityairMapProvider: ICityairMapProvider;

    _packetsValueTypes: PacketsValueTypeItem[];
    _moItems: MoItem[];

    constructor(private http: HttpClient) {
        super();
        this.cityairMapProvider = new CityairMapProvider(new DataProvider3(this.http));
    }

    public savePacketsValueTypes = (packetsValueTypes: PacketsValueTypeItem[]) => {
        this._packetsValueTypes = copyObj(packetsValueTypes);
    };

    public saveMoItems = (MoItems: MoItem[]) => {
        this._moItems = copyObj(MoItems);
    };

    getHistogramAqi10 = (id: number, days?: number): Promise<any> => {
        return new Promise((resolve, reject) => {
            this.cityairMapProvider.GetHistogramAqi10(
                {
                    MonitoringObjectId: id,
                    PeriodInDays: days
                } as HistogramAqi10Request,
                (data) => resolve(histogramTransformer(data)),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    }

    getPlumes: (time: number, isHeatmap: boolean) => Promise<ModelData>;

    getMoPackets: (prop: GetMoPacketsApiRequest, MeasureScheme?: measureScheme) => Promise<StationData>
    getTimelineInfo: (timeBegin: number, timeEnd: number, cityUrl?: string, allMoItems?: boolean) => Promise<CityairMapDataTransformerResponse>
    getCSTimelineInfo: (timeBegin: number, timeEnd: number, GroupId: number) => Promise<CityairMapDataTransformerResponse>
}
