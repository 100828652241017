<div class="event_feed__header">
    <div class="event_feed__title_wrapper">
        <search-input
            [textPlaceholder]="textsNotification.eventsFilterPrompt"
            [text]="filterText"
            (textChange)="filterUpdate($event)"
            [debounceTime]="200"
        ></search-input>
    </div>
    <div class="event_feed__filter-tabs"
        [class.event_feed__filter-tabs--initial-position]="initialScrollPosition || !selectedCount"
    >
        <switch-item
            [currentTab]="selectedTab"
            [tabs]="filterTabs"
            (action)="showSelectedTab($event)"
        ></switch-item>
    </div>
</div>

<cdk-virtual-scroll-viewport class="event_feed__list scroll"
    [class.event_feed__list--within-page]="!disableNavigation"
    itemSize="190"
    minBufferPx="400"
    maxBufferPx="1600"
    #feed
    isScrollTop (isTop)="initialScrollPosition = $event"
>
    <ng-container *ngIf="selectedCount || loading">
        <ng-container *cdkVirtualFor="let notification of lists.annotated$; templateCacheSize: 0">
            <div class="event_feed__block" *ngIf="notification.annotation">
                <div class="event_feed__block_name event_feed__annotation">{{notification.annotation}}</div>
            </div>
            <event-feed-item
                [notification]="notification.item"
                [todayDate]="todayDate"
                [disableNavigation]="disableNavigation"
                [hasMarker]="notificationHasRelatedMarker(notification.item)"
                (gotoMonitoringObject)="gotoMonitoringObject($event)"
                (gotoDeviceInfo)="gotoDeviceInfo($event)"
                (gotoIndoorMonitoringObject)="gotoIndoorMonitoringObject($event)"
            ></event-feed-item>
        </ng-container>
    </ng-container>
</cdk-virtual-scroll-viewport>

<div *ngIf="loading" class="loader_for_notification_list">
    <loader_map></loader_map>
</div>

<ng-container *ngIf="!((filterText$ | async).length || loading || selectedCount)">
    <div class="event_feed__list notifications_tips"
        *ngIf="(stateService.lists.selected$ | async).length || (!(stateService.lists.selected$ | async).length && !stateService.canEdit())"
    >
        <div class="notifications_tips__not-available">
            {{textsNotification.noEventsAvailable}}
        </div>
    </div>
    <div *ngIf="!(stateService.lists.selected$ | async).length && stateService.canEdit()" class="event_feed__list notifications_tips">
        <div class="notifications_tips__img notifications_tips__img-{{uiState.typeFilter}}"></div>
        <div class="notifications_tips__text">
            <div class="notifications_tips__text-bold notifications_tips__text-padding_button">
                <ng-container [ngSwitch]="uiState.typeFilter">
                    <ng-container *ngSwitchCase="filterTypes.MEASUREMENTS">
                        {{textsNotification.eventsHelpMessageMeasurements}}
                    </ng-container>
                    <ng-container *ngSwitchCase="filterTypes.SERVICE">
                        {{textsNotification.eventsHelpMessageService}}
                    </ng-container>
                </ng-container>
            </div>
            <div class="button_link button_link-blue"
                (click)="openSubscriptions()"
            >
                {{textsNotification.subscribeSuggestion}}
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="(filterText$ | async).length && !loading && (lists.annotated$ | async).length === 0">
    <div class="event_feed__list">
        <search-result-not-found
            [text]="textsNotification.eventsNotFound"
            [description]="textsNotification.eventsNotFoundHint"
        ></search-result-not-found>
    </div>
</ng-container>

<ca-scroll-top *ngIf="!initialScrollPosition && selectedCount" (click)="scrollTop()"></ca-scroll-top>
