import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { ConfigPage } from '../config.types';

import './config-title.less';

@Component({
    selector: 'config-title',
    template: `
        <div class="config-title__wrapper">
            <div class="config-title">
                <div class="config-title__text" [ngSwitch]="currentPage">
                    <header-page *ngSwitchDefault
                        [textObject]="{titlePage: TEXTS.CONFIG.title}"
                    ></header-page>
                    <header-page *ngSwitchCase="configPage.API_TOKENS"
                        [cbBack]="select"
                        [textObject]="{
                            titlePage: TEXTS.CONFIG.token,
                            btnBack: TEXTS.ADMIN_PANEL.back
                        }"
                    ></header-page>
                    <header-page *ngSwitchCase="configPage.COEFFICIENT"
                        [cbBack]="select"
                        [textObject]="{
                            titlePage: TEXTS.CONFIG.coeff,
                            btnBack: TEXTS.ADMIN_PANEL.back
                        }"
                    ></header-page>
                    <header-page *ngSwitchCase="configPage.ACTIVITY_HISTORY"
                        [cbBack]="select"
                        [textObject]="{
                            titlePage: TEXTS.CONFIG.history,
                            btnBack: TEXTS.ADMIN_PANEL.back
                        }"
                    ></header-page>
                    <header-page *ngSwitchCase="configPage.LANGUAGE"
                        [cbBack]="select"
                        [textObject]="{
                            titlePage: TEXTS.LOGIN_WINDOWS.language,
                            btnBack: TEXTS.ADMIN_PANEL.back
                        }"
                    ></header-page>
                </div>
                <i *ngIf="![configPage.HOME, configPage.LANGUAGE].includes(currentPage)"
                    class="settings_subtitle__icon"
                    caTooltip
                    [caTooltipTemplate]="headerTooltip"
                ></i>
                <ng-template #headerTooltip>
                    <ca-info-tooltip [text]="tooltipTexts[currentPage]"></ca-info-tooltip>
                </ng-template>
            </div>
        </div>
    `
})
export class ConfigTitleComponent  {
    @Input() currentPage: ConfigPage;
    @Output() selectPage = new EventEmitter<ConfigPage>();

    TEXTS = TEXTS;
    configPage = ConfigPage;

    tooltipTexts: {
        [key in ConfigPage]: string
    } = {
        [ConfigPage.API_TOKENS]: this.TEXTS.CONFIG.tokenTooltip,
        [ConfigPage.COEFFICIENT]: this.TEXTS.CONFIG.coeffTooltip,
        [ConfigPage.ACTIVITY_HISTORY]: this.TEXTS.CONFIG.historyTooltip,
        [ConfigPage.HOME]: '',
        [ConfigPage.LANGUAGE]: ''
    };

    select = () => {
        this.selectPage.emit(ConfigPage.HOME);
    }
}
