import {
    Component,
    EventEmitter,
    Input,
    Output,
    Directive,
    OnInit,
    OnDestroy
} from '@angular/core';

import { StndAskPopupTexts } from '../namespace';
import { TEXTS } from '../texts/texts';

import './style.less';

@Directive()
class KeyEscape implements OnInit, OnDestroy {
    close: () => void;

    keydown = (e: KeyboardEvent) => {
        if (e.code === 'Escape' && this.close)
            this.close();
    }

    ngOnInit() {
        document.addEventListener('keydown', this.keydown);
    }

    ngOnDestroy() {
        document.removeEventListener('keydown', this.keydown);
    }
}

@Component({
    selector: 'stnd-popup',
    template: `
        <div [ngClass]="{stnd_popup__splash: type === 'splash'}" (click)="splashClick($event)">
            <div [ngClass]="[className || 'stnd_popup']">

                <div *ngIf="close" class="stnd_popup__close" (click)="close()"></div>

                <ng-content></ng-content>

            </div>
        </div>
    `,
})
export class StndPopup extends KeyEscape implements OnInit, OnDestroy {
    @Input() close: () => void;
    @Input() className: string;
    @Input() type: 'splash' | '';

    splashClick = (event) => {
        if (this.type === 'splash' && event.currentTarget == event.target) {
            this.close();

            document.body.classList.remove('splash-open');
        }
    }

    ngOnInit() {
        if (this.type === 'splash') {
            document.body.classList.add('splash-open');
        }

        document.addEventListener('keydown', this.keydown);
    }

    ngOnDestroy() {
        if (this.type === 'splash') {
            document.body.classList.remove('splash-open');
        }

        document.removeEventListener('keydown', this.keydown);
    }
}

@Component({
    selector: 'station-loading-splash',
    template: `
        <stnd-popup className="loading-wrapper" type="splash">
            <div class="loading">
                <div class="loader-spinner" style="position: static;"></div>
                <div  class="loading__img"></div>
            </div>
        </stnd-popup>
    `,
})
export class StationLoadingSplash {};

@Component({
    selector: 'stnd-error-popup',
    template: `
        <div class="stnd_popup__splash">
            <div class="stnd_popup">
                <div class="stnd_popup__close" (click)="close()"></div>
                <h2 style="color: #FF7344; text-align: center">{{TEXTS.COMMON.error}}:</h2>
                <div class="stnd_popup__error" style="text-align: center;">{{errorMsg}}</div>
                <div class="stnd_button__blue_style stnd_button__blue_style-blue_fill text-standard" style="width: 274px;margin: 50px auto 0 auto;" (click)="close()">OK</div>
            </div>
        </div>
    `
})
export class StndErrorPopup extends KeyEscape {
    @Input() close: () => void;
    @Input() errorMsg: string;

    TEXTS = TEXTS
}


@Component({
    selector: 'stnd-ask-popup',
    template: `
        <div class="stnd_popup__splash">
            <div class="stnd_popup">
                <div class="stnd_popup__close" (click)="close()"></div>
                <div class="stnd_popup__title">{{texts.title}}</div>
                <div class="stnd_popup__description">{{texts.body}}</div>

                <div class="stnd_popup__btn_block">
                    <div class="stnd_popup__btn stnd_popup__btn-cancel" (click)="close()">{{texts.cancel}}</div>
                    <div class="stnd_popup__btn stnd_popup__btn-save" (click)="accept(); close()">{{texts.accept}}</div>
                </div>
            </div>
        </div>
    `
})
export class StndAskPopup extends KeyEscape {
    @Input() texts: StndAskPopupTexts;

    @Input() accept: () => void;
    @Input() close: () => void;
}

@Component({
    selector: 'stnd-ask-popup-three-questions',
    template: `
        <div class="stnd_popup__splash">
            <div class="stnd_popup stnd_popup-threeQuestions">
                <div>
                    <div class="stnd_popup__close" (click)="close()"></div>
                    <div class="stnd_popup__title">{{texts.title}}</div>
                    <div *ngIf="texts.body" class="stnd_popup__description">{{texts.body}}</div>
                    <div class="stnd_popup__three_btn_block">
                        <div class="stnd_popup__btn_wrapper">
                            <ca-button type="redOutline"
                                (clickAction)="extraAction(); isViewLoader = true"
                                [disabled]="false"
                            >
                                <ca-spinner [color]="'#FF7344'" *ngIf="isViewLoader"></ca-spinner>
                                <div *ngIf="!isViewLoader">
                                    {{texts.extraAction}}
                                </div>
                            </ca-button>
                        </div>
                        <div class="stnd_popup__btn_wrapper-right">
                            <ca-button type="primary"
                                (clickAction)="close()"
                                [disabled]="false"
                                [isLoading]="false"
                            >
                                {{texts.cancel}}
                            </ca-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class StndAskPopupThreeQuestions extends KeyEscape {
    @Input() texts: StndAskPopupTexts;

    @Input() accept: () => void;
    @Input() close: () => void;
    @Input() extraAction: () => void;

    isViewLoader = false;
}

@Component({
    selector: 'stnd-ask-popup2',
    template: `
        <div class="stnd_popup__splash">
            <div class="stnd_popup">
                <div class="stnd_popup__close" (click)="cancel.emit()"></div>
                <div *ngIf="texts.title" class="stnd_popup__title">{{texts.title}}</div>
                <div *ngIf="texts.body" class="stnd_popup__description">{{texts.body}}</div>

                <ng-content></ng-content>

                <div class="stnd_popup__btn_block">
                    <div class="stnd_popup__btn stnd_popup__btn-cancel" (click)="cancel.emit()">{{texts.cancel || TEXTS.COMMON.cancel}}</div>
                    <div class="stnd_popup__btn stnd_popup__btn{{disableAccept ? '-disabled' : '-save'}}" (click)="accept.emit()">{{texts.accept || TEXTS.COMMON.apply}}</div>
                </div>
            </div>
        </div>
    `
})
export class StndAskPopup2 extends KeyEscape {
    @Input() texts: StndAskPopupTexts;
    @Input() disableAccept?: boolean;

    @Output() cancel = new EventEmitter<void>();
    @Output() accept = new EventEmitter<void>();

    TEXTS = TEXTS;

    close = () => this.cancel.emit();
}
