import { NgModule } from '@angular/core';
import { Metrika } from 'ng-yandex-metrika';

@NgModule({
    providers: [
        {
            provide: Metrika,
            useClass: class {
                fireEvent() {}
            },
        }
    ]
})
export class MetrikaMockModule {}
