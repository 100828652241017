import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { IconsModule } from 'projects/shared/modules/icons/icons.module';
import { AnalyticChartTitleComponent } from 'projects/cityscreen/src/components/analytic-charts/analytic-chart-title/analytic-chart-title.component';

@NgModule({
    imports: [
        CommonModule,
        IconsModule,
        TooltipsModule
    ],
    declarations: [
        AnalyticChartTitleComponent,
    ],
    exports: [
        AnalyticChartTitleComponent,
    ]
})
export class AnalyticChartTitleModule {
}
