import { Injectable } from '@angular/core';

import { WindowGlobalVars } from 'src/namespace';
import { FormError } from './services/api/form-error';
import { CurrentPointValues } from './services/models/run-model';

declare const window: WindowGlobalVars;

@Injectable({
    providedIn: 'root'
})
export class PlumeUiState {
    constructor() {
        // @ts-ignore
        window.plumeUiState = this;
    }

    currentPointValues: CurrentPointValues;

    getPointValue(pointId: number) {
        return this.currentPointValues ? this.currentPointValues[pointId] : null;
    }

    setCurrentPointValues(data: CurrentPointValues) {
        this.currentPointValues = data;
    }

    clearCurrentPointValues() {
        this.currentPointValues = null;
    }

    formError: FormError = {};

    setFormError(error: FormError) {
        this.formError = error;
    }

    clearFormError() {
        this.formError = {};
    }
}
