import { Injectable } from '@angular/core';
import { UserItems } from '../namespace';

export enum Pages {
    USERS_LIST = 'users-list',
    EDIT_USER = 'edit-user',
};

@Injectable({
    providedIn: 'root'
})
export class UsersStateService {
    currentPage: Pages;
    currentUser: UserItems = null;
    isShowDeleteUserPopup = false;

    openUsersList = () => {
        this.currentPage = Pages.USERS_LIST;
    }

    openEditUser = (user: UserItems) => {
        this.currentPage = Pages.EDIT_USER;
        this.currentUser = user;
    }

    openCreateUser = () => {
        this.currentUser = null;
        this.currentPage = Pages.EDIT_USER;
    }

    setDefaultState = () => {
        this.currentPage = Pages.USERS_LIST;
    }
}
