import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossButtonComponent } from './cross-button.component';



@NgModule({
  exports: [CrossButtonComponent],
  declarations: [CrossButtonComponent],
  imports: [
    CommonModule
  ]
})
export class CrossButtonModule { }
