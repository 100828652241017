import { Component, Input } from '@angular/core';

import { isFalseNumber } from 'src/utils';

@Component({
    selector: 'control-point-marker',
    templateUrl: 'control-point-marker.component.html',
    styleUrls: ['control-point-marker.component.less']
})
export class ControlPointMarkerComponent {
    @Input() isSelected: boolean;
    @Input() value: number;
    @Input() units: string;

    roundValue = (val: number) => Math.round(val);
    isFalseNumber = isFalseNumber;
}
