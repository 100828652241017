import { Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'dashboard-widget',
    templateUrl: 'dashboard-widget.component.html',
    styleUrls: ['dashboard-widget.component.less']
})
export class DashboardWidgetComponent {
    @Input() @HostBinding('attr.size') size: number;
}
