import { Component, OnInit } from '@angular/core';

import State from 'map/state';
import Actions from 'map/actions';
import { hideHtmlLoader, isOldBrowser } from 'src/utils';
import { isRU, TEXTS } from 'src/texts/texts';
import { detectMobile } from 'projects/shared/utils/other-utils';
import { ClientConfigService, ClientConfig } from './modules/core/services/client-config/client-config.service';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';

const templateNotSupported = `
    <div class="browser_not_support">{{TEXTS.COMMON.browserNotSupport}}</div>
`;

const templateDesktop = `
    <ca-tooltip-outlet></ca-tooltip-outlet>
    <ca-popup-outlet></ca-popup-outlet>
    <map *ngIf="sharedCoreFacade.getModuleConfig().enableMap" [globalState]="state" [globalActions]="actions"></map>
    <cs-top-right-elements *ngIf="sharedCoreFacade.getModuleConfig().enableTopElements" [state]="state" [actions]="actions"></cs-top-right-elements>
    <time-line *ngIf="sharedCoreFacade.getModuleConfig().enableTimeline" [config]="{showPdk: true}"></time-line>
    <admin-panel></admin-panel>
    <loader_map *showDirective="state.mapLoading"></loader_map>
    <stnd-error-popup *ngIf="state.errorMsg" [errorMsg]="state.errorMsg" [close]="actions.closeErrorPopup"></stnd-error-popup>
    <download-popups-wrapper></download-popups-wrapper>
    <cs-onboarding *ngIf="isRu"></cs-onboarding>
`;

const templateMobile = `
    <ca-tooltip-outlet></ca-tooltip-outlet>
    <ca-popup-outlet></ca-popup-outlet>
    <map *ngIf="sharedCoreFacade.getModuleConfig().enableMap" [globalState]="state" [globalActions]="actions"></map>
    <cs-top-right-elements-m *ngIf="sharedCoreFacade.getModuleConfig().enableTopElements" [state]="state" [actions]="actions"></cs-top-right-elements-m>

    <cityscreen-mobile-header></cityscreen-mobile-header>
    <time-line-mobile *ngIf="sharedCoreFacade.getModuleConfig().enableTimeline"></time-line-mobile>

    <loader_map *showDirective="state.mapLoading"></loader_map>
    <stnd-error-popup *ngIf="state.errorMsg" [errorMsg]="state.errorMsg" [close]="actions.closeErrorPopup"></stnd-error-popup>
    <download-popups-wrapper></download-popups-wrapper>
`;

enum Views {
    NOT_SUPPORTED = 'not-supported',
    MOBILE = 'mobile',
    CLOSED_MAP = 'closed-map'
}

@Component({
    selector: 'map-page',
    styleUrls: ['../../../map/mapPage.less'],
    template: `
        <ng-container [ngSwitch]="currentView">
            <ng-container *ngSwitchDefault>${templateDesktop}</ng-container>

            <ng-container *ngSwitchCase="views.MOBILE">${templateMobile}</ng-container>

            <ng-container *ngSwitchCase="views.NOT_SUPPORTED">${templateNotSupported}</ng-container>
        </ng-container>
    `
})
export class CityScreenPage implements OnInit {
    TEXTS = TEXTS;
    isRu = isRU;
    views = Views;
    currentView: string;

    clientConfig: ClientConfig;

    suggestionText = TEXTS.LOGIN_WINDOWS.suggestionForMobileClients;

    constructor(
        public state: State,
        public actions: Actions,
        public sharedCoreFacade: SharedCoreFacade,
        clientConfigService: ClientConfigService
    ) {
        this.currentView = isOldBrowser() ? Views.NOT_SUPPORTED
            : detectMobile() ? Views.MOBILE
            : Views.CLOSED_MAP;

        this.clientConfig = clientConfigService.getConfig();

        if (this.clientConfig.mobile.replaceAppNameWithDomainName) {
            this.suggestionText = this.suggestionText.replace('CityScreen', location.host);
        }
    }

    ngOnInit() {
        if (this.currentView !== Views.CLOSED_MAP) {
            hideHtmlLoader();
        }
    }
}
