import { Component, Input } from '@angular/core';

@Component({
    selector: 'information-column',
    templateUrl: './information-column.component.html',
    styleUrls: ['./information-column.component.less']
})
export class InformationColumnComponent {
    @Input() title = '';
    @Input() description = '';
}
