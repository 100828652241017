import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopupAskComponent } from './popup-ask.component';
import { BtnAcceptModule } from 'projects/cityscreen/src/components/btn-accept/btn-accept.module';
import { BtnCancelModule } from 'projects/cityscreen/src/components/btn-cancel/btn-cancel.module';
import { CrossButtonModule } from 'projects/shared/modules/buttons/cross-button/cross-button.module';

@NgModule({
    exports: [PopupAskComponent],
    declarations: [PopupAskComponent],
    imports: [
        CommonModule,
        CrossButtonModule,
        BtnAcceptModule,
        BtnCancelModule,
    ]
})
export class PopupAskModule {}
