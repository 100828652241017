import { Injectable } from '@angular/core';

import { MAP_PROVIDERS } from 'src/config';
import { WindowGlobalVars } from 'src/namespace';

declare const window: WindowGlobalVars;

@Injectable()
export class MapProvidersService {
    availableProviders = MAP_PROVIDERS;
    currentProvider: MAP_PROVIDERS;

    agmOverlayShift = {
        x: 10, // https://github.com/AckerApple/agm-overlays/blob/6e14e0dbf9f35a2c4e1df4af84bc0e400a0a51dc/src/AgmOverlay.component.ts#L217
        y: 20 // https://github.com/AckerApple/agm-overlays/blob/6e14e0dbf9f35a2c4e1df4af84bc0e400a0a51dc/src/AgmOverlay.component.ts#L218
    };

    defaultOverlayShift = {
        x: 0,
        y: 0
    };

    constructor() {
        this.currentProvider = MAP_PROVIDERS[window.CURRENT_MAP_PROVIDER];
    }

    getMarkerShift() {
        // return this.currentProvider === MAP_PROVIDERS.GoogleMaps
        //     ? this.agmOverlayShift
        //     : this.defaultOverlayShift;

        return this.defaultOverlayShift;
    }
}
