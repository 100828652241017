import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges
} from '@angular/core';

import { NgLocalization } from '@angular/common';
import * as moment from 'moment';
import { map, take } from 'rxjs/operators';

import { TEXTS, LANGUAGE } from 'src/texts/texts';
import { shortDateFormat, shortDateFormatWithToday } from 'src/utils/date-formats';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';

import {
    NotificationSubscribeType,
    NotificationFeedItem,
    ServiceExcessType,
    NotificationEventType
} from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';

const enum DurationMap {
    STARTED = 'begin',
    CONTINUING = 'continue'
};

const DurationsAbbr = {
    ru: {
        years: 'г.',
        months: 'мес.',
        days: {
            zero: 'дней',
            one: 'день',
            two: 'дня',
            few: 'дня',
            many: 'дней',
            other: 'дней'
        },
        hours: 'ч',
        minutes: 'мин'
    },
    en: {
        years: 'yr',
        months: 'mo',
        days: {
            zero: 'days',
            one: 'day',
            two: 'days',
            few: 'days',
            many: 'days',
            other: 'days'
        },
        hours: 'h',
        minutes: 'min'
    }
};

function dateRangeText(begin: Date, end: Date) {
    return [shortDateFormat(begin), '—', shortDateFormat(end)].join(' ');
}

@Component({
    selector: 'event-feed-item',
    templateUrl: 'event-feed-item.component.html',
    styleUrls: ['event-feed-item.component.less']
})
export class EventFeedItem implements OnChanges {
    @Input() notification: NotificationFeedItem;
    @Input() todayDate: Date;
    @Input() disableNavigation: boolean;
    @Input() hasMarker: boolean;

    @Output() gotoMonitoringObject = new EventEmitter<{
        moId: number;
        dateTime: string;
    }>();

    @Output() gotoDeviceInfo = new EventEmitter<string>();

    @Output() gotoIndoorMonitoringObject = new EventEmitter<{
        moId: number;
        dateTime: string;
        mmt?: string;
    }>();

    tooltipIconText: string;
    firstNotification: NotificationFeedItem;
    durationType: DurationMap;
    textsNotification = TEXTS.NOTIFICATIONS;
    eventTypes = NotificationEventType;
    serviceExcessTypes = ServiceExcessType;
    firstEventVisible = false;
    durationTooltipText = '';
    headerDateTimeText = '';

    constructor(
        public stateService: NotificationsStateService,
        private ngLocalization: NgLocalization
    ) {}

    ngOnChanges() {
        const { BeginTime, EndTime, SubscribeType } = this.notification;

        this.durationType = this.hasEnded ? DurationMap.CONTINUING : DurationMap.STARTED;

        this.tooltipIconText = {
            [NotificationSubscribeType.PdkExcess]: this.textsNotification.tooltipEventIcon.measurements,
            [NotificationSubscribeType.ServiceDevice]: this.textsNotification.tooltipEventIcon.service
        }[SubscribeType] || '';

        this.durationTooltipText = this.getDurationTooltipText();

        this.headerDateTimeText = this.hasEnded
            ? this.durationFormat(BeginTime, EndTime)
            : shortDateFormatWithToday(this.todayDate, BeginTime);

        this.firstNotification = this.hasEnded ? Object.assign(
            new NotificationFeedItem(),
            this.notification,
            {
                UpdateTime: null,
                EventType: this.eventTypes.Begin
            }
        ) : null;
    }

    getDurationTooltipText() {
        const { BeginTime, EndTime, UpdateTime } = this.notification;

        let result = '';

        if (this.hasEnded) {
            result = dateRangeText(BeginTime, EndTime);
        } else {
            const end = UpdateTime === BeginTime ? new Date() : UpdateTime;
            result = this.durationFormat(BeginTime, end);
        }

        return result;
    }

    get hasEnded() {
        return this.notification.EventType === this.eventTypes.End;
    }

    get isPdkType() {
        return this.notification.SubscribeType === NotificationSubscribeType.PdkExcess;
    }

    get isServiceType() {
        return this.notification.SubscribeType === NotificationSubscribeType.ServiceDevice;
    }

    openSettings() {
        const id = this.notification.NotificationId;

        this.stateService.lists.selected$
        .pipe(
            take(1),
            map(ss => ss.find(s => s.id === id))
        )
        .subscribe(subscription => {
            if (subscription) {
                this.stateService.openSettings(subscription);
            }
        });
    }

    toggleFirstEvent(e: Event) {
        e.stopPropagation();

        this.firstEventVisible = !this.firstEventVisible;
    }

    durationFormat(begin: Date, end: Date) {
        const abbr = DurationsAbbr[LANGUAGE] || DurationsAbbr.en;

        const duration = moment.duration(moment(end).diff(begin));

        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        const category = this.ngLocalization.getPluralCategory(days, LANGUAGE);

        const d = [];

        if (years) {
            d.push(`${years} ${abbr.years}`);
        }

        if (months) {
            d.push(`${months} ${abbr.months}`);
        }

        if (days) {
            d.push(`${days} ${abbr.days[category]}`);
        }

        if (hours) {
            d.push(`${hours} ${abbr.hours}`);
        }

        if (minutes) {
            d.push(`${minutes} ${abbr.minutes}`);
        }

        return d.join(' : ');
    }

    markAsVisited() {
        if (!this.notification.IsViewed) {
            this.stateService.markNotificationAsVisited(this.notification);
        }
    }
}
