import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-list-header',
    templateUrl: './list-header.component.html',
    styleUrls: ['./list-header.component.less']
})
export class ListHeader {
    @Input() text?: string;
    @Input() showCounter = true;
    @Input() itemsCount = 0;
}
