import { DataProvider3 } from '../dataProvider/DataProvider';
import * as Models from './cityairMapModels';

import { GetObserverPacketsResponce } from '../adminPanel/models';
import { WindowGlobalVars } from 'src/namespace';

import { ErrorResult } from 'harvester/UiAdminProject8/src/commonData/models';
import { CityScreenTimelineRequest, CityScreenTimelineResponse } from './cityairMapModels';

declare const window: WindowGlobalVars;

function generateRequestId() {
    return '?requestId=' + Math.random().toString().substr(2);
}

export class CityairMapProvider implements  Models.ICityairMapProvider {
    cpController = 'CityairMap';
    setToken = (request: any) => {
        request.Token = window.JS_CP_TOKEN;
        return request;
    };

    constructor(private readonly dataProvider: DataProvider3) {}

    GetTimelineInfo(request: Models.TimelineInfoRequest, execOk: (responce: Models.TimelineInfoResponce) => void, execFail: (errorResult: ErrorResult) => void) {
        this.dataProvider.execHttp(this.cpController, 'GetTimelineInfo' + generateRequestId(), this.setToken(request), execOk, execFail);
    }

    GetCityScreenTimeline(request: CityScreenTimelineRequest, execOk: (responce: CityScreenTimelineResponse) => void, execFail: (errorResult: ErrorResult) => void) {
        this.dataProvider.execHttp(this.cpController, 'GetCityScreenTimeline' + generateRequestId(), this.setToken(request), execOk, execFail);
    }

    GetMapPackets(request: Models.MoPacketsRequest , execOk: (responce: GetObserverPacketsResponce) => void, execFail: (errorResult: ErrorResult) => void) {
        this.dataProvider.execHttp(this.cpController, 'GetMapPackets' + generateRequestId(), this.setToken(request), execOk, execFail);
    }

    GetMapPacketsCityScreen(request: Models.MoPacketsRequest , execOk: (responce: GetObserverPacketsResponce) => void, execFail: (errorResult: ErrorResult) => void) {
        this.dataProvider.execHttp(this.cpController, 'GetMapPacketsCityScreen'  + generateRequestId(), this.setToken(request), execOk, execFail);
    }

    GetHistogramAqi10(request: Models.HistogramAqi10Request, execOk: (responce: Models.HistogramAqi10Response) => void, execFail: (errorResult: ErrorResult) => void) {
        this.dataProvider.execHttp(this.cpController, 'GetHistogramAqi10', this.setToken(request), execOk, execFail);
    }
}
