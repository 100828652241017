import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';

import { TEXTS } from 'src/texts/texts';
import { environment } from 'projects/cityscreen/src/environments/environment';
import { WebResponse, LoginApiRequest, LoginErrorCode } from './harvester.type';
import { LoginResponse } from 'harvester/UiAdminProject8/src/commonData/providers/authProvider/authModels';
import { UserItems } from 'src/namespace';

declare const window: any;

const APP_NAME = window.JS_CP_APP_TITLE || 'map-app';

const API_URL = environment.api_url;

const ENDPOINTS = {
    LOGIN: `${API_URL}/Auth/CreateTokenByLogin`,
    CLOSE_TOKEN: `${API_URL}/Auth/CloseToken`,
    REQUEST_RESET_CODE: `${API_URL}/Auth/SendPasswordResetCodeByEmail`,
    RESET_PASSWORD: `${API_URL}/Auth/SetNewUserPasswordWithResetCode`,
};

@Injectable({
    providedIn: 'root'
})
export class HarvesterApiService {
    private _errorMessage$ = new BehaviorSubject<string>(null);
    errorMessage$ = this._errorMessage$.asObservable();

    private _error$ = new BehaviorSubject<WebResponse<LoginResponse>>(null);
    error$ = this._error$.asObservable();

    formError$ = this.error$.pipe(
        filter(e => !!e),
        map(e => {
            if (isNaN(e.ErrorNumber)) {
                return TEXTS.LOGIN_WINDOWS.unrecognizedError;
            } else if (this.isServerException(e)) {
                return e.ErrorMessage;
            } else {
                // show default message instead
                return '';
            }
        })
    );

    constructor(private http: HttpClient) {}

    private clearErrors() {
        this._errorMessage$.next(null);
        this._error$.next(null);
    }

    private handleError(httpError: HttpErrorResponse) {
        const { status, error } = httpError;

        if (status === 0) {
            console.error('A client-side or network error occurred:', error);
        } else if (isNaN(error?.ErrorNumber)) {
            console.error('Unrecognized error:', error);
            this._errorMessage$.next('Unrecognized error. Please try again later.');
        } else {
            const message = this.isServerException(error) ? error.ErrorMessage : `[${LoginErrorCode[error.ErrorNumber]}] ${error.ErrorMessage}`;

            this._errorMessage$.next(message);

            this._error$.next(error);
        }

        return throwError(error);
    }

    private isServerException(error: WebResponse<LoginResponse>) {
        return [LoginErrorCode.NotSpecified, LoginErrorCode.InfoException].includes(error.ErrorNumber);
    }

    login(name: string, password: string) {
        this.clearErrors();

        const requestBody: LoginApiRequest = {
            AppTitle: APP_NAME,
            Login: name,
            Pwd: password,
            Token: ''
        };

        return this.http.post<WebResponse<LoginResponse>>(
            ENDPOINTS.LOGIN, requestBody
        ).pipe(
            catchError(this.handleError.bind(this)),
            filter(resp => !!resp.Result),
            map(resp => ({
                user: <UserItems>{
                    login: resp.Result.CpUser.Login,
                    email: resp.Result.CpUser.Email,
                    userId: resp.Result.CpUser.UserId
                },
                access: resp.Result.Token
            }))
        );
    }

    post(endpoint: string, requestBody: Record<string, any>) {
        this.clearErrors();

        return this.http.post<WebResponse<string>>(endpoint, requestBody).pipe(
            catchError(this.handleError.bind(this)),
            map(resp => ({ ok: resp.Result === 'OK' }))
        );
    }

    closeToken(token: string) {
        const requestBody = {
            Token: token
        };

        return this.post(ENDPOINTS.CLOSE_TOKEN, requestBody);
    }

    requestResetCode(email: string) {
        const requestBody = {
            Email: email
        };

        return this.post(ENDPOINTS.REQUEST_RESET_CODE, requestBody);
    }

    verifyResetCode(email: string, code: string) {
        const requestBody = {
            Email: email,
            ResetCode: code,
            CheckResetCodeOnly: true
        };

        return this.post(ENDPOINTS.RESET_PASSWORD, requestBody);
    }

    changePassword(email: string, code: string, password: string) {
        const requestBody = {
            Email: email,
            ResetCode: code,
            Tag: password,
            CheckResetCodeOnly: false
        };

        return this.post(ENDPOINTS.RESET_PASSWORD, requestBody);
    }
}
