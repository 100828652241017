import { Injectable } from '@angular/core';

import { DashboardModel, DashboardStatsModel, Device } from './dashboard.model';
import { DashboardFacadeService } from './dashboard.facade';

@Injectable({
    providedIn: 'root'
})
export class DashboardCollectionService {
    constructor(private dashboardFacadeService: DashboardFacadeService) {}

    private rateInterval: number;
    private devices: Device[];
    private stats: DashboardStatsModel;

    updateData(data: DashboardModel) {
        this.rateInterval = data.rateInterval;
        this.devices = data.devices;

        this.dashboardFacadeService.updateCollection$.next(data.devices);
        this.dashboardFacadeService.setRateInterval(data.rateInterval);
    }

    updateStats(data: DashboardStatsModel) {
        this.stats = data;

        this.dashboardFacadeService.updateStats$.next(data);
    }
}
