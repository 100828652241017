import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';

import Actions from '../../../../../../map/actions';
import State from '../../../../../../map/state';
import { TEXTS } from '../../../../../../src/texts/texts';
import { Token } from '../../../../../../src/namespace';

import './browser-tokens.less';
import AdminPanelActions from '../../admin-panel/actions';

@Component({
    selector: 'browser-tokens',
    templateUrl: 'browser-tokens.html',
})
export class BrowserTokensComponent implements OnInit {
    constructor(private actions: Actions, private state: State, public apActions: AdminPanelActions) {}

    apState = this.state.adminPanel;

    text = TEXTS.CONFIG;

    clickStream$ = new Subject<number>();
    clickStreamCloseAll$ = new Subject<number>();

    async close(id: number) {
        await this.apActions.closeToken(id);
        const index = this.apState.tokens.browserTokens.findIndex((t) => t.tokenId === id);
        this.apState.tokens.browserTokens.splice(index, 1);
    }

    ngOnInit() {
        this.clickStream$.pipe(distinctUntilChanged()).subscribe(async (id: number) => {
            this.close(id);
        });

        this.clickStreamCloseAll$.pipe(throttleTime(2000)).subscribe(() => {
            this.apState.tokens.browserTokens.forEach(async (t: Token) => {
                if (!t.isActive) {
                    this.close(t.tokenId);
                }
            });
        });
    }

    closeToken = (id: number) => this.clickStream$.next(id);

    closeAllTokens = () => this.clickStreamCloseAll$.next();

    nothingClose = () => {
        return !this.apState.tokens.browserTokens.filter((t) => !t.isActive).length;
    };
}
