import { Component, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';

import { TEXTS } from 'src/texts/texts';
import { RunPlume } from 'projects/cityscreen/src/modules/plumes/services/models/run-model';
import { formatDayMonth } from 'src/config';

@Component({
    selector: 'plumes-run-summary',
    templateUrl: 'plumes-run-summary.component.html',
    styleUrls: ['plumes-run-summary.component.less']
})
export class PlumesRunSummaryComponent implements OnChanges {
    @Input() run: RunPlume;

    runFinished: moment.Moment;
    dateStart: moment.Moment;
    dateEnd: moment.Moment;

    TEXTS = TEXTS;

    ngOnChanges() {
        if (this.run) {
            this.runFinished = moment(this.run.finished_at || 0);
            this.dateStart = moment(this.run.evaluation_time || 0);
            this.dateEnd = moment(this.dateStart).add(this.run.duration_minutes, 'minutes');
        }
    }

    formatTime(m: moment.Moment) {
        return m.format('HH:mm');
    }

    formatDate(m: moment.Moment) {
        return `${this.formatTime(m)} ${formatDayMonth(m)}`;
    }
}
