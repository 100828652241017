<div [@inOutAnimation] class="aqi_info_screen" (clickOutside)="showLegend = false;" (click)="toggleLegend()">
    <div  class="aqi_info_screen__time" [innerHTML]="formatterFlagTime(time.getCurrent(), sharedCoreFacade.getTzOffset())"></div>
    <div class="aqi_info_screen__aqi">
        <div class="aqi_info_screen__text aqi_info_screen__text-underline" >AQI</div>
        <div class="aqi_info_screen__num aqi_info_screen__num-{{avgAqi$ | async}}"></div>
    </div>
</div>
<!--TODO !!!!!!!!!!!!!!!!!Будет необходимо раскоментить после согласования дизайна!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
<!--<div class="aqi_legend_screen" *ngIf="showLegend" [@inOutAnimation]>-->
<!--    <div class="aqi_legend_screen__header">-->
<!--        {{TEXTS.TEXT_DESCRIPTION.airQualityTitle}}-->
<!--    </div>-->
<!--    <div class="aqi_legend_screen__content">-->
<!--        <div class="aqi_legend_screen__name">AQI</div>-->
<!--        <div class="aqi_legend_screen__description">-->
<!--            <div class="aqi_legend_screen__legend_text">-->
<!--                <div *ngFor="let t of TEXTS.AQI_THREE_TEXTS; let i = index; ">{{TEXTS.AQI_THREE_TEXTS[i]}}</div>-->
<!--            </div>-->
<!--            <div class="aqi_legend_screen__legend_img"></div>-->
<!--            <div class="aqi_legend_screen__legend_num">-->
<!--                <div><span class="aqi_legend_screen__num">1</span> {{TEXTS.AQI_DECL[0]}}</div>-->
<!--                <div><span class="aqi_legend_screen__num">3</span> {{TEXTS.AQI_DECL[1]}}</div>-->
<!--                <div><span class="aqi_legend_screen__num">7</span> {{TEXTS.AQI_DECL[2]}}</div>-->
<!--                <div><span class="aqi_legend_screen__num">10</span></div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="aqi_legend" *ngIf="showLegend && sharedCoreFacade.getIsCityMod()" [@inOutAnimation]>
    <div class="scroll aqi_legend__wrapper">
        <div class="aqi_legend__header">
            <div class="aqi_legend__title">Air Quality Index</div>
            <div class="aqi_legend__description" [innerHtml]="TEXTS.TEXT_DESCRIPTION.airQuality"></div>
        </div>
        <div class="aqi_legend__content">
            <div class="aqi_legend__measure_aqi">
                <div *ngFor="let i of arr;" class="aqi_legend__tr">
                    <div class="aqi_legend__color_circle aqi_legend__color_circle-{{i}}"
                         [ngClass]="{'aqi_legend__color_circle-select': i === findSelectAqi(avgAqi$ | async) }"
                    ></div>
                    <div [ngStyle]="{fontWeight: i === findSelectAqi(avgAqi$ | async) ? 'bold' : ''}">
                        {{TEXTS.AQI_THREE_TEXTS2[i]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>