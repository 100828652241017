import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsList2 } from './tags-list.component';
import { CrossButtonModule } from 'projects/shared/modules/buttons/cross-button/cross-button.module';

@NgModule({
    exports: [TagsList2],
    declarations: [TagsList2],
    imports: [
        CommonModule,
        CrossButtonModule,
    ]
})
export class TagsListModule2 {
}
