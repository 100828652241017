import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { environment } from 'projects/cityscreen/src/environments/environment';
import { ApiModels2 } from 'projects/cityscreen/src/modules/core/services/api/error-handler';
import { ProviderApiV2 } from 'projects/cityscreen/src/modules/core/services/api/provider_v2';
import { SeasonsName } from'src/namespace';
import { getApiV2Interval } from 'src/utils';

const API_URL = environment.api_url + '/harvester/v2/posts';

export interface DistributionAqi {
    aqi: number;
    percent: number;
}

export interface DistributionDayHour {
    hourOfDay: number;
    aqi: number;
}

export interface DistributionWeekDay {
    dayOfWeek: number;
    aqi: number;
}

export interface Season {
    firstPacketDate: Date;
    distributionAqi: DistributionAqi[];
    distributionDayHour: DistributionDayHour[];
    distributionWeekDay: DistributionWeekDay[];
}

export interface DistributionSummary {
    [SeasonsName.Year]: Season;
    [SeasonsName.Winter]: Season;
    [SeasonsName.Spring]: Season;
    [SeasonsName.Summer]: Season;
    [SeasonsName.Autumn]: Season;
}

export interface PostWithAnalytics {
    id: number;
    name: string;
    ownerId?: any;
    description?: any;
    isOnline: boolean;
    geo: Geo;
    distributionSummary: DistributionSummary;
}

interface PostsMeasurementsProps {
    timeBegin: number;
    timeEnd: number;
    groupId: number;
}

export interface Units {
    temperature: string;
    pressure: string;
    humidity: string;
    tsp: string;
    pm2: string;
    pm10: string;
    co: string;
    co2: string;
    no: string;
    no2: string;
    so2: string;
    o3: string;
    h2s: string;
    ch2o: string;
    pws_wda: string;
    pws_wva: string;
    pws_prc: string;
}

export interface Meta {
    Units: Units;
}

export interface CityairAqi {
    value: number;
}

export interface AqiObject {
    dominant: string;
    valueScaled10: number;
    value: number;
}

export interface Aqi {
    cityairAqi: CityairAqi;
    epaAqi: AqiObject;
    indoorAqi: AqiObject;
}

export interface Measurement {
    postId: number;
    date: string;
    version: number;
    temperature: number;
    pressure: number;
    humidity: number;
    pm2: number;
    pm10: number;
    co2: number;
    aqi: Aqi;
}

export interface Geo {
    latitude: number;
    longitude: number;
    gmtOffsetSeconds: number;
    timeZoneIana: string;
}

export interface Post {
    id: number;
    name: string;
    ownerId: string;
    description: string;
    isOnline: boolean;
    geo: Geo;
}

export interface PostsMeasurementsResponse {
    meta: Meta;
    data: Measurement[];
    posts: Post[];
}

const transformProps = (props: PostsMeasurementsProps) => ({
    ids: '',
    interval: getApiV2Interval(props.timeBegin, props.timeEnd),
    date__gt: moment(props.timeBegin).toISOString(),
    date__lt: moment(props.timeEnd).toISOString(),
    with_posts: 'true',
    device_source_type: 'CityairIndoor',
    measure_value_types: 'pm2,pm10,temperature,pressure,humidity,co2',
    fill_holes: 'true',
    with_iaq: 'true',
    limit: '1000000',
    group_id: props.groupId.toString()
});

@Injectable({
    providedIn: 'root'
})
export class IndoorApi extends ApiModels2 {
    constructor(
        private providerApiV2: ProviderApiV2
    ) {
        super();
    }

    async getPostsMeasurements(props: PostsMeasurementsProps) {
        return await this.providerApiV2.get(
            `${API_URL}/measurements`,
            transformProps(props),
            this.errorHandler
        ) as PostsMeasurementsResponse;
    }

    async getAnalytic(id: number) {
        return await this.providerApiV2.get(
            `${API_URL}/${id}`,
            {indoor_stat: 'true'},
            this.errorHandler
        ) as PostWithAnalytics;
    }
}
