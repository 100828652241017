import { Component, Input } from '@angular/core';

import { TooltipsService } from 'projects/shared/components/tooltips/tooltips.service';
import { ANIMATION_OPACITY } from '../../../../utils/config';

@Component({
    selector: 'ca-tooltip',
    templateUrl: 'tooltip.component.html',
    styleUrls: ['tooltip.component.less'],
    animations: ANIMATION_OPACITY
})
export class TooltipComponent {
    @Input() text: string;

    constructor(readonly tooltipsService: TooltipsService) {}
}
