export interface ApiRequest {
    Token: string;
}

export interface LoginApiRequest extends ApiRequest {
    AppTitle: string;
    Login: string;
    Pwd: string;
};

export interface PasswordResetCodeRequest extends ApiRequest {
    CheckResetCodeOnly: boolean;
    Email: string;
    ResetCode: string;
    Tag: string;
}

export type WebResponse<T> = {
    Result: T;
    Message: string;
    IsError: boolean;
    ErrorNumber: LoginErrorCode;
    ErrorMessage: string;
    ErrorMessageDetails: string;
};

export enum LoginErrorCode {
    NotSpecified = 0,
    LoginFailed = 101,
    TokenFailed = 102,
    ResetCodeFailed = 109,
    EmailValidation = 110,
    InfoException = 199
}
