import { Component, OnInit } from '@angular/core';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';

@Component({
    selector: 'analytics-page-wrapper',
    templateUrl: './analytics-page-wrapper.component.html'
})
export class AnalyticsPageWrapperComponent implements OnInit {

    constructor(public sharedCoreFacade: SharedCoreFacade) {
    }

    ngOnInit(): void {
    }

}
