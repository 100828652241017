import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { detectTouchDevice } from 'src/utils';

@Component({
    selector: 'ca-menu-button',
    templateUrl: 'menu-button.component.html',
    styleUrls: ['menu-button.component.less']
})
export class MenuButtonComponent {
    @Input() name: string;
    @Input() isAvailable: boolean;

    TEXTS = TEXTS;

    isTouchDevice = detectTouchDevice();

    onClick($event) {
        if (!this.isAvailable) {
            $event.stopPropagation();
        }
    }
}
