import { IDataProvider, ErrorResult } from "../../models";
import { IMoApiProvider, MoApiItemsRequest, MoApiItemsResponse, MoApiItemRequest, MoApiGroupsResponse, MoApiPacketsRequest, MoApiPacketsResponse, MoApiExportPacketsRequest } from "./moApiModels";

export class MoApiProvider implements IMoApiProvider {
    cpController: string = "MoApi2";

    constructor(private readonly dataProvider: IDataProvider) {
    }

    getGroupsItems = (request: MoApiItemsRequest, execOk: (response: MoApiGroupsResponse) => void, execFail: (errRes: ErrorResult) => void): void => {
        this.dataProvider.execHttp(this.cpController, "GetGroupsItems", request, execOk, execFail);
    }

    getMoItems = (request: MoApiItemsRequest, execOk: (response: MoApiItemsResponse) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttp(this.cpController, "GetMoItems", request, execOk, execFail);
    }

    addMoItem = (request: MoApiItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttp(this.cpController, "AddMoItem", request, execOk, execFail);
    }

    editMoItem = (request: MoApiItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttp(this.cpController, "EditMoItem", request, execOk, execFail);
    }

    deleteMoItem = (request: MoApiItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttp(this.cpController, "DeleteMoItem", request, execOk, execFail);
    }

    resetMoData = (request: MoApiItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttp(this.cpController, "ResetMoData", request, execOk, execFail);
    }

    getMoPackets = (request: MoApiPacketsRequest, execOk: (responce: MoApiPacketsResponse) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttpEx(this.cpController, "GetMoPackets", request, false, true, execOk, execFail);
    }

    exportMoPacketsToExcel = (request: MoApiExportPacketsRequest, execOk: (responce: string) => void, execFail: (errRes: ErrorResult) => void = null): void => {
        this.dataProvider.execHttp(this.cpController, 'ExportMoPacketsToExcel', request, execOk, execFail);
    }
}
