import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'switch-v2',
  templateUrl: './switch-v2.component.html',
  styleUrls: ['./switch-v2.component.less']
})
export class SwitchV2Component {
    @Input() text = '';
    @Input() isSwitchOn = false
    @Input() isSwitchDisabled = false
    @Output() switchOnChange = new EventEmitter<boolean>()

    switchOn () {
        if (this.isSwitchDisabled) {
            return;
        }
        this.isSwitchOn = !this.isSwitchOn
        this.switchOnChange.emit(this.isSwitchOn)
    }

}
