<div class="play-button">
    <div class="play-button__loader"
        [class.play-button__loader--hidden]="!loading"
    >
        <div class="play-button__loader-ring">
            <div class="play-button__state play-button__state--{{state}}"></div>
        </div>
        <svg width="100%" height="100%" viewBox="0 0 34 34" class="play-button__loader-segment-holder">
            <circle class="play-button__loader-segment"
                cx="17" cy="17" r="15.91549430918954" stroke-dashoffset="25" fill="transparent" stroke-width="3.6"
                [attr.stroke-dasharray]="progressPair"
            ></circle>
        </svg>
    </div>
</div>
