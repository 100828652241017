import { CHART_INDIVIDUAL_OPTIONS, CHART_PADDING_LR } from 'src/config';
import { CHART_TYPE_TIMELINE } from 'src/namespace';

let flagEl: HTMLElement;
let flagWidthEl: HTMLElement;

export function flagSetPosition(left: number, shiftWidth: number) {
    if (!flagEl) {
        return;
    }

    flagEl.style.left = left + 'px';

    const chartWidth = CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE].chart.width + shiftWidth;

    if (chartWidth + 30 < flagWidthEl.clientWidth /*должны быть фиксирована ширина*/ + left) {
        flagEl.classList.add('flag_right_out_of');
    } else if (flagEl.classList.contains('flag_right_out_of')) {
        flagEl.classList.remove('flag_right_out_of');
    }
}

export function dragFlag(callBack, getShiftWidth: () => number = () => 0) {
    flagEl = document.getElementById('flag_drag');
    flagWidthEl = document.getElementById('flag_width');

    const ev = (pageX) => {
        const shiftX: number = pageX - flagEl.getBoundingClientRect().left + pageXOffset;

        const moveAt = (targetX: number) => {
            const left = Math.trunc(targetX - shiftX + getShiftWidth());
            const width = document.body.clientWidth;

            if (left >= width - CHART_PADDING_LR[0] + getShiftWidth() || left <= CHART_PADDING_LR[0])
                return;

            flagSetPosition(left, getShiftWidth());

            window.getSelection().removeAllRanges(); // убираем выделение текста

            callBack(left);
        };

        flagEl.style.transition = 'none';
        document.onmousemove = e => moveAt(e.pageX);
        document.ontouchmove = e => moveAt(e.touches[0].pageX);

        document.onmouseup = document.ontouchend = () => {
            flagEl.style.transition = '';
            document.onmousemove = document.ontouchmove = null;
            document.onmouseup = document.ontouchend = null;
        };
    };

    flagEl.onmousedown = e => ev(e.pageX);

    flagEl.ontouchstart = e => {
        // @ts-ignore
        if (!e.target.classList?.contains('timeline_flag__to_right')) {
            e.preventDefault(); // костыль для ios, что бы шторка вместе с флагом не поднималась
            e.stopPropagation();
        }
        ev(e.touches[0].pageX);
    }

    flagEl.ondragstart = () => false;
}
