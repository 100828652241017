import * as moment from 'moment';

import {
    AQI,
    AqiHistory_model,
    DATA_INTERVAL_TYPES,
    DataForCreateSeries,
    DAY_MS,
    HOUR_MS,
    Interval,
    MeasureCoef_model,
    MeasuresForTime,
    MeasuresInfo_model,
    MeasuresInfoFormat,
    OriginChartData_model,
    OriginChartDataMeasure_model,
    Series_model,
    SeriesElement_model
} from '../namespace';

import { TEXTS } from '../texts/texts';

import {
    ALERT_COLORS,
    CHART_STATIC_COLORS, MEASURE_SORT_ELEMENTS,
    measureZones,
    TIMELINE_COLOR
} from '../config';

import {
    applyCoef,
    calcCommonMeasuresCoef,
    ChartActions,
    createEmptyTimelineAqiHistory,
    createSeriesID,
    dissapplyCoef,
    findIndexInForChart,
    findMaxMinInForChart,
    findPointFor, getTimelineInterval,
    isFalseNumber
} from '../utils';
import { LOAD_STATION_SMALL_INTERVAL } from '../../projects/shared/utils/config';

export class DataForUpdateStationData {
    data: MeasuresForTime[];
    station: DataForCreateSeries;
    comparedStations?: DataForCreateSeries[];
    chart?: ChartActions;
    selectedMeasures: MeasuresInfoFormat[];
    tzOffset?: number;
    interval?: Interval;
    measuresInfo?: MeasuresInfo_model;
}

export const getZoneColors = {
    [AQI]: (aqiInfoColor: string[], coef = { a: 1, b: 0 }) => [
        ...aqiInfoColor.slice(0, 9).map((color, i) => ({
            value: applyCoef(i + 1, coef),
            color
        })),
        {
            color: aqiInfoColor[10]
        }
    ]
};

MEASURE_SORT_ELEMENTS.forEach(name => {
    getZoneColors[name] = (aqiInfoColor: string[], coef = { a: 1, b: 0 }) => [
        ...measureZones.get(name).map((val: number, ind: number) => ({
            value: applyCoef(val, coef),
            color: aqiInfoColor[ind + 1]
        })),
        {
            color: aqiInfoColor[10]
        }
    ];
});

export function createPdkOption(value, color, text, dashStyle?: string) {
    return {
        value,
        color,
        dashStyle: dashStyle || 'shortdash',
        width: 1,
        label: {
            text,
            useHTML: true
        }
    };
}

class CreateChartNull_model {
    time: number;
    [name: string]: any;
}

function createNullWithoutEmpty (data: CreateChartNull_model[], timeBegin, timeEnd, interval, emptyObj: {name: string, val: any}): CreateChartNull_model[] {
    if (!timeBegin || !timeEnd) {
        return data;
    }

    const newData: CreateChartNull_model[] = [];
    let lastPoint = timeBegin;

    for (let i = 0; i < data.length; i++) { // для начала и серидины
        const currentPoit = data[i].time;

        if (currentPoit - lastPoint > interval * 1.1 /*погрешность*/) {
            newData.push({
                time: lastPoint,
                [emptyObj.name]: emptyObj.val
            });
            i--;
            lastPoint += interval;
        } else {
            newData.push(data[i]);
            lastPoint = data[i].time;
        }
    }

    // для конца
    while (timeEnd - lastPoint > interval * 1.1) { // коефициент если большой то не создасться точки до конца дня
        newData.push({
            time: lastPoint + interval,
            [emptyObj.name]: emptyObj.val
        });
        lastPoint += interval;
    }

    return newData;
}

export function createOriginChartData(
    _data: MeasuresForTime[],
    tzOffset = 0 /*min*/,
    timeBegin = 0,
    timeEnd = 0,
    _interval: Interval = LOAD_STATION_SMALL_INTERVAL,
    measuresInfo?: MeasuresInfo_model
): OriginChartData_model {
    const toMilliseconds = 60000;

    const interval = DATA_INTERVAL_TYPES[_interval] * toMilliseconds; // minutes

    const measures = Array.from(new Set(
        _data
        .map(d => Object.keys(d.values))
        .reduce((a, v) => [...a, ...v], [])
    ));

    _data.sort((a, b) => a.time - b.time);

    const data = createNullWithoutEmpty(_data, timeBegin, timeEnd, interval, {
        name: 'values',
        val: {}
    });

    const createItemWithTime = (time: number) => (val: number) : [number, number] => [
        time + tzOffset * toMilliseconds,
        isFalseNumber(val) ? null : Math.round(val * 10) / 10
    ];

    const origin: OriginChartData_model = {};

    data.forEach(arrEl => {
        const createItem = createItemWithTime(arrEl.time);

        measures.forEach(name => {
            if (!origin[name]) {
                origin[name] = new OriginChartDataMeasure_model();
            }

            origin[name].data.push(createItem(arrEl.values[name]));
        });
    });

    measures.forEach(name => {
        const chartData = origin[name];
        const { min, max } = findMaxMinInForChart(chartData.data);

        chartData.min = min;
        chartData.max = max;

        const measureData = measuresInfo?.[name];

        if (measureData) {
            chartData.type = measureData.type;
            chartData.name = measureData.name;
            chartData.unit = measureData.unit;

            if (chartData.type === AQI) {
                chartData.min = 0;
                chartData.max = 10;
            }
        } else {
            chartData.type = name;
            chartData.name = name;
            if (measuresInfo) {
                console.error('measuresInfo не содержит описания этой меры, Id:', name);
            }
        }
    });

    return  Object.values(origin).reduce(
        (a, v) => ({ ...a, [v.name]: v }),
        new OriginChartData_model()
    );
}

function _combine(oldData, newData) {
    newData.forEach(a => {
        const index = findIndexInForChart(oldData, a[0]);

        if (index !== -1) {
            oldData[index] = a;
        } else {
            oldData.push(a);
            oldData.shift();
        }
    });
}

export function combineOriginChartData(old: OriginChartData_model, neW: OriginChartData_model, measures: MeasuresInfoFormat[] = []): boolean {
    let changeMinMax = false;

    function change (name: string) {
        if (measures.find(a => a.name === name))
            changeMinMax = true;
    }

    // важно что forEach по old. Иначе в standalone нужно делать фильтр по пустым мерам
    Object.keys(old).forEach( (name: string) => {
        const _old = old[name];
        const _new = neW[name];

        if (!_old || !_new) {
            return;
        }

        _combine(_old.data, _new.data);

        const {min, max} = findMaxMinInForChart(_old.data);

        if (_old.type !== AQI /*AQIspecially*/
            && (min !== _old.min || max !== _old.max)) {
            _old.max = max;
            _old.min = min;
            change(name);
        }
    });

    return changeMinMax;
}

export function updateStationData(props: DataForUpdateStationData) {
    if (!props.data.length)
        return;

    const packets = createOriginChartData(
        props.data,
        props.tzOffset,
        undefined,
        undefined,
        props.interval,
        props.measuresInfo
    );

    const needRerender = combineOriginChartData(props.station.originChartData, packets, props.selectedMeasures);

    if (!props.chart) {
        return;
    }

    if (needRerender) {
        props.chart.updateSeries();
        props.chart.updateChart();
    } else {
        const coefs = calcCommonMeasuresCoef(props.comparedStations, props.selectedMeasures);
        let stationIndex: number;
        props.comparedStations.find((s, index) => {
            if (s.id === props.station.id) {
                stationIndex = index;
                return true;
            }
        });

        props.selectedMeasures.forEach(obj => {
            const m =  packets[obj.name];
            if (m)
                m.data.forEach(a => {

                    const isSingle = props.comparedStations.length === 1 && props.selectedMeasures.length === 1;

                    const coef = isSingle ? {a: 1, b: 0} : coefs[obj.type];

                    if (!coef)
                        console.error('нет коэфициента для нового пакета');

                    props.chart.chartControl.addPoint(
                        a,
                        createSeriesID(stationIndex, obj.name),
                        coef
                    );
                });
        });
    }
}

type  FunctionCreateStndSerie = (measure: MeasuresInfoFormat, origin: OriginChartDataMeasure_model, stationName: string, id: number, single: boolean, coef: {a: number, b: number}) => SeriesElement_model;

export function createStndSerie(measure: MeasuresInfoFormat, origin: OriginChartDataMeasure_model, stationName: string, indexInCompared: number, single: boolean, coef: {a: number, b: number} = {a: 1, b: 0}): SeriesElement_model {
    const dashs = [
        'Solid',
        'ShortDot',
        'LongDash',
        'LongDashDotDot',
        'DashDot',
    ];

    const data = origin ? origin.data.map((d): [number, number] => [<number>d[0], applyCoef(d[1], coef)] ) : [];

    // AQIspecially
    const isColumn = measure.type === AQI;

    const preOption = isColumn ? {
        crisp: false,
        threshold: -100,
        dataGrouping: {
            approximation: 'high',
            groupPixelWidth: 30,
            enabled: true
        }
    } : {
        dataGrouping: {
            approximation: 'high',
        }
    };

    return {
        ...preOption,
        id: createSeriesID(indexInCompared, measure.name),
        type: isColumn ? 'column' : 'line',
        name: `${measure.name} ${single ? '' : stationName}`,
        tooltip: {
            pointFormatter: function () {
                const val = Math.round( dissapplyCoef(this.y, coef) * 10) / 10;
                return `<span style="color:${this.color}">\u25CF</span> <b>${val}</b> ${this.series.name}<br/>`;
            }
        },
        data: data ? [...data] : [],
        color: CHART_STATIC_COLORS[measure.type],
        zones: getZoneColors[measure.type] ? getZoneColors[measure.type](ALERT_COLORS, coef) : [],
        dashStyle: dashs[indexInCompared],
    }
}

export function createSingleSerie(measure: MeasuresInfoFormat, origin: OriginChartDataMeasure_model, stationName: string, indexInCompared: number, single: boolean, coef: {a: number, b: number} = {a: 1, b: 0}): SeriesElement_model {
    const dashs = [
        'Solid',
        'ShortDot',
        'LongDash',
        'LongDashDotDot',
        'DashDot',
    ];

    const data = origin ? origin.data.map((d): [number, number] => [<number>d[0], d[1]] ) : [];

    // AQIspecially
    const isColumn = measure.type === AQI;

    const preOption = isColumn ? {
        crisp: false,
        threshold: -100,
        dataGrouping: {
            approximation: 'high',
            groupPixelWidth: 30,
            enabled: true
        }
    } : {
        dataGrouping: {
            approximation: 'high',
        }
    };

    return {
        ...preOption,
        id: createSeriesID(indexInCompared, measure.name),
        type: isColumn ? 'column' : 'line',
        name: `${measure.name} ${single ? '' : stationName}`,
        data: data || [],
        color: CHART_STATIC_COLORS[measure.type],
        zones: getZoneColors[measure.type] ? getZoneColors[measure.type](ALERT_COLORS, {a: 1, b: 0}) : [],
        dashStyle: dashs[indexInCompared],
    }
}

export function getSeriesForChart(comparedStations: Readonly<DataForCreateSeries[]>, selectMeasures: MeasuresInfoFormat[], createFn: FunctionCreateStndSerie = createStndSerie): Series_model {
    const series = [];
    const pdk = [];
    let yTitle = null;

    const measureCoef: MeasureCoef_model = calcCommonMeasuresCoef(comparedStations, selectMeasures);

    selectMeasures.forEach(measure => {
        comparedStations.forEach((s, ind) => {
            series.push(createFn(measure, s.originChartData[measure.name], s.pubName || s.name, ind, comparedStations.length === 1, measureCoef[measure.type]));
        });
    });

    if (selectMeasures.length === 1)
        yTitle = TEXTS.NAMES[selectMeasures[0].type] + ` (${selectMeasures[0].unit})`;

    return {series, pdk, yTitle};
}

export function createSeriesForTimeline(aqiHistory: AqiHistory_model[], aqiInfoColor = TIMELINE_COLOR, msOffset = 0, timeBegin = 0, timeEnd = 0): Series_model {
    const interval = getTimelineInterval(timeBegin, timeEnd);
    const delta = interval === 4 ? DAY_MS : HOUR_MS;

    const intervalMin = DATA_INTERVAL_TYPES[interval];

    const arrTimes = [];
    const time = moment(timeBegin);

    while (time <= moment(timeEnd)) {
        arrTimes.push(time.valueOf());
        time.add(intervalMin, 'minute');
    }

    const data = arrTimes.reduce( (arr: any[], _time) => {
        const index = findPointFor(aqiHistory, _time, 'time', delta)

        return arr.concat({
            x: _time + msOffset,
            y: 1,
            color: aqiInfoColor[isFalseNumber(index) ? 0 : aqiHistory[index].aqi],
            name: moment(_time + msOffset).format('DD.MM.YYYY HH:mm'),
            grouping: false
        });
    }, []);

    return {
        series: [{
            name: `${TEXTS.NAMES[AQI]} ${TEXTS.COMMON.byTheCity}`,
            tooltip: {
                pointFormat: '{point.y} {point.name}'
            },
            data,
            turboThreshold: 10000 // maximum
        }]
    };
}

export function createWorldTimelineAqiHistory(timeBegin: number, timeEnd: number): AqiHistory_model[] {
    return createEmptyTimelineAqiHistory(timeBegin, timeEnd);
}

