<div class="new-password-form">
    <ng-container *ngIf="isInvite; else defaultTitle">
        <div class="login-title new-password-form-title" [innerHTML]="TEXTS.LOGIN_WINDOWS.welcome"></div>
        <div class="login-subtitle new-password-form-subtitle">{{TEXTS.LOGIN_WINDOWS.toolsFor}}</div>
    </ng-container>
    <ng-template #defaultTitle>
        <div class="login-title new-password-form-title">{{TEXTS.LOGIN_WINDOWS.resetPassTitle}}</div>
    </ng-template>

    <div class="login-description new-password-form-description">{{TEXTS.LOGIN_WINDOWS.enterNewPass}}</div>

    <form class="login__fieldset" (ngSubmit)="onSubmit()" [formGroup]="newPasswordForm" novalidate>
        <input type="submit" [style.display]="'none'" />

        <div class="login-input-group new-password-form-input-group">
            <input class="login-input"
                type="{{showPassword ? 'text' : 'password'}}"
                autocomplete="new-password"
                formControlName="newPassword"
                [class.login-input--danger]="hasErrors"
                [attr.placeholder]="TEXTS.LOGIN_WINDOWS.newPass"
            />

            <ca-eye-icon class="new-password-form-input__eye"
                [isOpened]="showPassword"
                (click)="showPassword = !showPassword"
            ></ca-eye-icon>

            <div class="new-password-form__pwd-strength" *ngIf="!hasErrors">
                <div class="new-password-form__pwd-strength-check new-password-form__pwd-strength-check--{{passwordStrength}}">
                    <div class="new-password-form__pwd-strength-fill-wrapper">
                        <div class="new-password-form__pwd-strength-fill new-password-form__pwd-strength-fill--{{passwordStrength}}"></div>
                    </div>
                </div>
                <div class="new-password-form__pwd-strength-description" *ngIf="passwordStrength > 0">
                    {{TEXTS.LOGIN_WINDOWS.passLevel[passwordStrength]}}
                </div>
            </div>

            <div class="login__info login-form__info">
                <div class="login-errors login-form__errors scroll">
                    <ng-container *ngIf="hasErrors">
                        {{(harvester.formError$ | async) || localError}}
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="login-input-group new-password-form-input-group">
            <div class="new-password-form__hint" [innerHTML]="TEXTS.LOGIN_WINDOWS.warningNewPass"></div>
        </div>

        <div class="login-input-group new-password-form-input-group">
            <input class="login-input"
                type="{{showPassword ? 'text' : 'password'}}"
                autocomplete="new-password"
                formControlName="newPasswordConfirm"
                [class.login-input--danger]="hasErrors"
                [attr.placeholder]="TEXTS.LOGIN_WINDOWS.confNewPass"
            />

            <ca-eye-icon class="new-password-form-input__eye"
                [isOpened]="showPassword"
                (click)="showPassword = !showPassword"
            ></ca-eye-icon>
        </div>

        <button type="submit" class="login-submit-button new-password-form__submit-button" data-cy="submit">
            <ca-login-button>
                <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                <ng-template #showContent>{{TEXTS.LOGIN_WINDOWS.resetPass}}</ng-template>
            </ca-login-button>
        </button>

        <a class="login-nav-link new-password-form__back-btn" [routerLink]="['/', loginPage.Login]">
            {{TEXTS.LOGIN_WINDOWS.backToSign}}
        </a>
    </form>
</div>
