import { APP_INITIALIZER, enableProdMode, ErrorHandler, Injectable, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as Sentry from '@sentry/browser';
import { MetrikaModule } from 'ng-yandex-metrika';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { MapModule } from 'projects/shared/modules/map/map.module';
import { CalendarModule } from 'src/calendar/calendar.module';
import { CityscreenComponentsModule } from './components/cityscreen-components.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { ChartsModule } from 'src/chart/charts.module';
import { AdminPanelModule } from 'projects/cityscreen/src/components/admin-panel/admin-panel.module';

import { CityScreenPage } from './cityScreenPage';

import Actions from 'map/actions';
import State from 'map/state';

import { WindowGlobalVars } from 'src/namespace';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import { DevicesApi } from 'src/api/DevicesApi/api';

import { LoginModule } from './modules/login/login.module';
import { ClientConfigService } from './modules/core/services/client-config/client-config.service';

import { environment } from './environments/environment';

import 'src/common-style/main.less';
import { CityscreenMapApi } from 'src/api/mapProvider/cityscreenMapApi';

import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { PopupsModule } from 'projects/cityscreen/src/components/popups/popups.module';
import { TimelineModule } from 'projects/shared/modules/timeline/timeline.module';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { SharedCoreModule } from 'projects/shared/modules/core/core.module';
import { MetrikaMockModule } from 'projects/shared/modules/metrika/metrika-mock.module';
import { IS_PUBLIC } from '../../shared/utils/other-utils';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { commonReducers } from './modules/core/store/reducers';
import { StoreModule } from '@ngrx/store';
import { CommonEffects } from './modules/core/store/effects';
import { EffectsModule } from '@ngrx/effects';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';

import { CityscreenRoutingModule } from './cityscreen-routing.module';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ClientLoginPageComponent } from './pages/client-login-page/client-login-page.component';
import { IndoorModule } from 'projects/cityscreen/src/modules/indoor/indoor.module';

declare const window: WindowGlobalVars;

if (environment.production) {
    enableProdMode();
}

if (environment.sentry) {
    Sentry.init({
        dsn: 'https://20cf356d4cdb465daba339a2de5db292@o204789.ingest.sentry.io/5522834'
    });

    window.sentryCaptureException = Sentry.captureException;
    window.sentryAddBreadcrumb = Sentry.addBreadcrumb;
}

registerLocaleData(localeRu);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error: any) {
        Sentry.captureException(error.originalError || error);
        console.error(error);
    }
}

function clientConfigFactory(clientConfigService: ClientConfigService) {
    return () => clientConfigService.loadFromGlobalsLegacy();
}

@NgModule({
    imports: [
        environment.yandex_metrika
            ? MetrikaModule.forRoot(environment.yandex_metrika)
            : MetrikaMockModule
        ,
        BrowserModule,
        CommonModule,
        FormsModule,
        CityscreenRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        SharedCoreModule,
        MapModule,
        CalendarModule,
        LittleComponentsModule,
        CityscreenComponentsModule,
        ChartsModule,
        AdminPanelModule,
        TooltipsModule,
        PopupsModule,
        TimelineModule,
        SharedComponentsModule,
        LoadersModule,
        LoginModule,
        SharedComponentsModule,
        StoreModule.forRoot({core: commonReducers}),
        EffectsModule.forRoot([CommonEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
        }),
        IndoorModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: clientConfigFactory,
            deps: [ClientConfigService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        Actions,
        State,
        CityscreenMapApi,
        AdminPanelApi,
        DevicesApi,
        {
            provide: IS_PUBLIC,
            useValue: false
        }
    ],
    declarations: [
        CityScreenPage,
        ClientLoginPageComponent,
        MainPageComponent,
    ],
    bootstrap: [
        MainPageComponent,
    ],
})
export class CityScreenModule {
}
