import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as moment from 'moment';

import { formatDayMonth } from 'src/config';
import { createChartOptions } from '../../chartjs.config';

function formatDateTime(dateTime: Date, isStartOfDay: boolean) {
    const m = moment(dateTime);

    if (isStartOfDay) {
        return formatDayMonth(m);
    }

    return m.format('HH:mm');
}

const TICK_LINE_COLOR = '#E6ECF2'; // @grey3

@Component({
    selector: 'packets-monitor-timeline-scale',
    templateUrl: 'packets-monitor-timeline-scale.component.html',
    styleUrls: ['packets-monitor-timeline-scale.component.less']
})
export class PacketsMonitorTimelineScaleComponent implements OnChanges {
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() intervalWidth: number;
    @Input() ticks: number;
    @Input() datesList: Date[];

    lineChartData: ChartDataSets[] = [];
    lineChartLabels: Label[];
    lineChartOptions: ChartOptions;

    formattedDatesList: {
        date: string;
        isMajor: boolean;
    }[];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.datesList && (changes.datesList.firstChange || changes.datesList.currentValue !== changes.datesList.previousValue)) {
            this.formattedDatesList = this.datesList.map(dateTime => {
                const isMajor = moment(dateTime).isSame(moment(dateTime).startOf('day'))

                return {
                    date: formatDateTime(dateTime, isMajor),
                    isMajor
                };
            });

            this.lineChartData = [{
                data: this.datesList.map(_ => ({ x: null, y: 0 })),
                fill: false,
                showLine: false
            }];

            const begin = this.beginDate.getTime();
            const step = (this.endDate.getTime() - begin) / (this.ticks - 1);

            this.lineChartLabels = Array(this.ticks).fill('').map((_, i) =>
                i && new Date(begin + i * step).toISOString());

            this.lineChartOptions = createChartOptions(this.beginDate, this.endDate, TICK_LINE_COLOR);
        }
    }
}
