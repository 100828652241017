<ng-template #popupDownload>
    <ca-popup-confirm
            *ngIf="downloadType == 2"
            [isSaveBtnDisabled]="isSaveBtnDisabled"
            [title]="title"
            [isActiveLoader]="isActiveLoader"
            [btnAccept]="TEXTS.DOWNLOAD_POPUP.download"
            (cbCloseBtn)="closeDownloadPopup()"
            (cbCancelBtn)="closeDownloadPopup()"
            (cbAcceptBtn)="downloadReportMo()"
    >
        <download-popup-mo
                [selectedIds]="ids"
                [downloadPopupObj]="downloadPopupObj"
                [monitoringObjects]="mos"
                [errorDownloadMsg]="errorDownloadMsg"
                (objectForReport)="getObjectForReportMO($event)"
                (getSaveBtnDisabled)="getSaveBtnDisabled($event)"
        ></download-popup-mo>

    </ca-popup-confirm>
    <ca-popup-confirm
            *ngIf="downloadType == 1"
            [isSaveBtnDisabled]="isSaveBtnDisabled"
            [title]="title"
            [btnAccept]="TEXTS.DOWNLOAD_POPUP.download"
            [isActiveLoader]="isActiveLoader"
            (cbCloseBtn)="closeDownloadPopup()"
            (cbCancelBtn)="closeDownloadPopup()"
            (cbAcceptBtn)="downloadReportMo()"
    >
        <download-popup-mo
                [selectedIds]="ids"
                [downloadPopupObj]="downloadPopupObj"
                [errorDownloadMsg]="errorDownloadMsg"
                [monitoringObjects]="mos"
                [currentTabInterval]="currentTabInterval"
                (objectForReport)="getObjectForReportMO($event)"
                (getSaveBtnDisabled)="getSaveBtnDisabled($event)"
        ></download-popup-mo>
    </ca-popup-confirm>
    <ca-popup-confirm
            *ngIf="downloadType == 3"
            [isSaveBtnDisabled]="isSaveBtnDisabled"
            [title]="title"
            [btnAccept]="TEXTS.DOWNLOAD_POPUP.download"
            [isActiveLoader]="isActiveLoader"
            (cbCloseBtn)="closeDownloadPopup()"
            (cbCancelBtn)="closeDownloadPopup()"
            (cbAcceptBtn)="downloadReportStation()"
    >
        <download-popup-station
                [selectedId]="ids[0]"
                [downloadPopupObj]="downloadPopupObj"
                [errorDownloadMsg]="errorDownloadMsg"
                [myDevices]="devices"
                (objectForReportStation)="getObjectForReportStation($event)"
                (getSaveBtnDisabled)="getSaveBtnDisabled($event)"
        ></download-popup-station>
    </ca-popup-confirm>
</ng-template>