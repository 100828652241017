<div class="packets-monitor-list-item">
    <div class="packets-monitor-list-item__device-info">
        <div class="packets-monitor-list-item__line">
            <div class="packets-monitor-list-item__device-info__sn">
                {{data.SerialNumber}}
            </div>
        </div>
        <div class="packets-monitor-list-item__line">
            <div class="packets-monitor-list-item__device-info__location">
                {{data.LocationName || TEXTS.LIST_DEVICES.notConnected}}
            </div>
        </div>
    </div>
    <div class="packets-monitor-list-item__aside">
        <div class="packets-monitor-list-item__status">
            <div class="packets-monitor-list-item__line"
                *ngFor="let status of statusList | sorting : getStatusType"
            >
                <div class="packets-monitor-list-item__status-item">
                    <device-status-tag [type]="status.type" [text]="status.text"></device-status-tag>
                </div>
            </div>
        </div>
        <div class="packets-monitor-list-item__chart">
            <ng-content select="dashboard-chart"></ng-content>
        </div>
    </div>
</div>
