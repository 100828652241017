<cs-analytic-chart-title
        [title]="TEXTS.CITY_CARD.charts[0]"
        [tooltip1]="tooltip"
        [tooltip2]="tooltipDescription"
></cs-analytic-chart-title>

<div class="doughnut__wrapper">
    <div>
        <canvas baseChart
                height="135px"
                width="135px"
                [datasets]="datasets"
                [labels]="labels"
                [chartType]="'doughnut'"
                [options]="options"
        >
        </canvas>
    </div>

    <div class="doughnut__items_wrapper">
        <div *ngFor="let val of data; index as i;">
            <div *ngIf="val" class="doughnut__item">
                <span class="doughnut__circle" [style]="{background: ALERT_COLORS[i+1]}"></span>
                <span class="doughnut__point">{{labels[i]}}</span>
                <span class="doughnut__percent">{{val}}%</span>
            </div>
        </div>
    </div>
</div>
