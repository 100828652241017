import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Post } from 'projects/cityscreen/src/modules/indoor/services/api';
import { Store } from '@ngrx/store';
import { TEXTS } from 'src/texts/texts';
import { orderOfMeasures } from 'projects/cityscreen/src/modules/indoor/config';
import { take } from 'rxjs/operators';
import { IAQ, ROOM } from 'src/namespace';
import { indoorSortPoints } from 'projects/cityscreen/src/modules/indoor/services/store/actions';
import { indoorSelectors } from 'projects/cityscreen/src/modules/indoor/services/store/selectors';
import { IndoorState } from 'projects/cityscreen/src/modules/indoor/services/store/reducers';

@Component({
    selector: 'point-list',
    templateUrl: './point-list.component.html',
    styleUrls: ['./point-list.component.less']
})
export class PointListComponent {
    @Input() points: Post[];
    @Output() clickOnPoint = new EventEmitter<number>();

    @ViewChild('list') feed: ElementRef<HTMLInputElement>;

    IAQ = IAQ;
    ROOM = ROOM;

    orderOfMeasures = orderOfMeasures;
    TEXTS = TEXTS;
    searchQuery = '';

    isScrollTop = true;

    searchFilters = [
        (post: Post) => post.name,
        (post: Post) => post.id + '',
        (post: Post) => post.description
    ];

    indoorSelectors = indoorSelectors;

    constructor(public store: Store<IndoorState>) {
    }

    setSort = (field: string) => {
        this.store.select(indoorSelectors.sort)
            .pipe(take(1)).subscribe((sort) => {
            this.store.dispatch(indoorSortPoints(
                {
                    direction: sort.field === field ? -sort.direction as -1 | 1 : sort.direction,
                    field
                }
            ))
        })
    }

    scrollTop() {
        this.feed.nativeElement.scrollTop = 0;
    }
}
