import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnExtraComponent } from './btn-extra.component';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';


@NgModule({
    declarations: [BtnExtraComponent],
    exports: [BtnExtraComponent],
    imports: [
        CommonModule,
        LoadersModule,
    ]
})
export class BtnExtraModule {
}
