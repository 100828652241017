import {
    Component,
    ElementRef,
    ViewChild,
    HostListener,
    OnDestroy,
    Input,
    SimpleChanges,
    OnChanges,
    AfterViewInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import * as moment from 'moment';

import { TimelineState } from './store/index';
import {
    onChangeTimeDataIndex,
    onDataRangeChanged,
    onDatesReloaded,
    onGetLastPointTimeseries
} from './store/core.actions';
import {
    selectChartEnabled,
    selectCurrentIndex,
    getAppConfig,
    getDisplayPointTimeseriesWithDates,
} from './store/selectors/core.selectors';

import { AppConfig, DateRange, Feature, TimeTrackView, FlagDirection } from './models/core';
import { PlayButtonState } from './button-play/button-play.component';
import { DAY_OR_HOUR_BOUND, STEP_DURATION } from './constants';
import { TimeRunnerComponent } from './time-runner/time-runner.component';
import { DragAndDrop } from './dnd';

const MAX_LABEL_WIDTH = 75; // max width + padding in px

@Component({
    selector: 'timeline-panel',
    templateUrl: './timeline-panel.component.html',
    styleUrls: ['./timeline-panel.component.less'],
})
export class TimelinePanelComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() sidebarIsOpened: boolean;
    @Input() dates: string[];
    @Input() features: Feature[];
    @Input() dateTime: string;
    @Input() initSelectMeasurement?: string;

    public dateRange: DateRange;
    public city: Feature;
    public chartEnabled: boolean;
    public dateArray: string[] = [];
    public timeLineDate: TimeTrackView[] = [];
    public timeLineAQI: number[] = [];
    hasDataByIndex: boolean[] = [];
    public pointSeriesData: Feature[];
    playerState: {
        state: PlayButtonState;
        isBuffering: boolean;
        progress: number;
    } = {
        state: 'play',
        isBuffering: false,
        progress: 0,
    };
    flagDirection: FlagDirection = 'left';
    positionPercent = 0;
    currentTime = '';
    timeIndex: number;
    private tm: NodeJS.Timeout;
    changeTimeGenerator: Subject<number>;
    changeTimeGeneratorSubscription: Subscription;
    subscriptions: Subscription[] = [];
    public currentWidthTimeline: number;
    public configApp: AppConfig;

    @ViewChild(TimeRunnerComponent) timelineRunnerComponent: TimeRunnerComponent;
    @ViewChild('timelineRunner', { read: ElementRef }) timelineRunner: ElementRef<HTMLElement>;
    @ViewChild('timelineTrack', { read: ElementRef }) timelineTrack: ElementRef<HTMLElement>;

    constructor(private store: Store<TimelineState>, private el: ElementRef<HTMLElement>) {
        this.changeTimeGenerator = new Subject<number>();

        this.changeTimeGeneratorSubscription = this.changeTimeGenerator.pipe(
            distinctUntilChanged(),
            // TODO: Increase timer for low-perfomance cpus.
            debounceTime(3)
        ).subscribe((index) => {
            this.store.dispatch(onChangeTimeDataIndex({ index }));
        });

        this.subscriptions.push(this.changeTimeGeneratorSubscription);
        this.setInit();
    }

    ngAfterViewInit() {
        this.updateTimelineTrack();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sidebarIsOpened) {
            this.updateTimelineTrack();
        }

        if (changes.dates) {
            const { dates } = this;

            this.store.dispatch(onDataRangeChanged({
                startDate: dates[0],
                finishDate: dates[dates.length - 1]
            }));

            this.store.dispatch(onDatesReloaded({
                dates,
                currentDate: changes?.dateTime?.currentValue
            }));
        }

        if (changes.features) {
            this.store.dispatch(onGetLastPointTimeseries({ payload: this.features }));
        }
    }

    ngOnDestroy() {
       this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private setInit() {
        const timeDataSub = this.store.select(getDisplayPointTimeseriesWithDates).subscribe(({ data, dates }) => {
            this.pointSeriesData = [...data];
            this.dateArray = dates;
            this.timeLineDate = this.prepareTimelineData(dates);

            if (data.length === 1) {
                this.timeLineAQI = data[0].properties.timeseries.AQI.map((item) => Math.round(item));

                const featureHasDataByIndex = data.map(f => {
                    const { timeseries } = f.properties;
                    const keys = Object.keys(timeseries).filter(k => k !== 'date');
                    const data = keys.map(k => timeseries[k]) as number[][];

                    return this.indicateData(dates.length, data);
                });

                this.hasDataByIndex = this.indicateData(dates.length, featureHasDataByIndex).map(v => !!v);
            } else {
                this.clearDataAQI();
            }
        });

        const indexSub = this.store.select(selectCurrentIndex).subscribe((index) => {
            this.setTime(index);
        });

        const configSub = this.store.select(getAppConfig).subscribe((config) => {
            this.configApp = config;
        });

        const chartEnabledSub = this.store.select(selectChartEnabled).subscribe((data) => {
            this.chartEnabled = data;
            // clear AQI data
            if (!this.chartEnabled) {
                this.clearDataAQI();
            }
        });

        const resizeSub = fromEvent(window, 'resize').pipe(
            throttleTime(500),
            debounceTime(500)
        ).subscribe(() => {
            this.updateTimelineTrack();
        });

        this.subscriptions.push(timeDataSub, indexSub, configSub, chartEnabledSub, resizeSub);
    }

    private updateTimelineTrack() {
        this.currentWidthTimeline = this.el.nativeElement.getBoundingClientRect().width;
        this.timeLineDate = this.prepareTimelineData(this.dateArray);
    }

    private indicateData(len: number, data: number[][]): number[] {
        const init: number[] = Array(len).fill(null);
        return data.reduce((acc, v) => acc.map((a, i) => a || v[i] !== null ? 1 : null), init);
    }

    onTrackClick(index: number) {
        this.stopPlaying();
        this.setTime(index);
        this.onRunnerChange(index);
    }

    onRunnerChange(x: number): void {
        if (this.timelineTrack && this.timelineRunnerComponent) {
            const { width } = this.timelineTrack.nativeElement.getBoundingClientRect();
            const flagWidth = this.timelineRunnerComponent.getWidth();
            const currentWidth = (width / this.dateArray.length) * x;
            this.flagDirection = currentWidth + flagWidth > width ? 'left' : 'right';
        }
    }

    dragAndDrop: DragAndDrop;

    dragStart(e: MouseEvent | TouchEvent) {
        this.stopPlaying();
        this.dragAndDrop = new DragAndDrop(this.timelineRunner.nativeElement, (x: number) => {
            const position = this.timelineTrack.nativeElement.getBoundingClientRect();
            const relPos = Math.round(
                (x - position.left) / (position.width / this.dateArray.length)
            );
            if (relPos >= 0 && relPos < this.dateArray.length) {
                this.setTime(relPos);
            }
        });

        const pageX = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX;
        this.dragAndDrop.dragStart(pageX);
    }

    @HostListener('window:mouseup')
    @HostListener('window:touchend')
    dragEnd() {
        if (this.dragAndDrop) {
            this.dragAndDrop = null;
        }
    }

    setRunnerPosition(index: number) {
        const len = this.dateArray.length;
        let halfInterval = 100 * 0.5 / len;
        if (index === 0) {
            halfInterval = 0;
        }
        this.positionPercent = index === len - 1 ? 100 : 100 * index / len + halfInterval;
        this.onRunnerChange(index);
    }

    playPause() {
        if (this.playerState.state === 'pause') {
            this.stopPlaying();
        } else {
            this.playerState.state = 'pause';
            this.startPlaying();
        }
    }

    public goToEndTime() {
        this.setTime(this.dateArray.length - 1);
    }

    private startPlaying() {
        let index = this.timeIndex;
        const date = this.dateArray;

        if (index === -1 || index === date.length - 1) {
            index = 0;
        }

        this.tm = setInterval(() => {
            if (++index > date.length - 1) {
                this.stopPlaying();
            } else {
                this.setTime(index);
            }
        }, STEP_DURATION);
    }

    private stopPlaying() {
        if (this.tm) {
            clearInterval(this.tm);
        }

        this.playerState.state = 'play';
    }

    public setTime(index: number, noUpdate?: boolean) {
        if (!noUpdate && index >= 0) {
            if (this.changeTimeGenerator) {
                this.changeTimeGenerator.next(index);
            }
        } else {
            this.stopPlaying();
        }

        this.timeIndex = index;
        this.setRunnerPosition(index);
        this.currentTime = this.formatCurrentTime(this.dateArray[index]);
    }

    private prepareTimelineData(data: string[]): TimeTrackView[] {
        const result: TimeTrackView[] = [];
        const numberLabel = Math.round(this.currentWidthTimeline / MAX_LABEL_WIDTH);

        if (data.length && numberLabel) {
            const hours = this.getHourByRange(data[0], data[data.length - 1]);
            const hoursInterval = this.getStepIntervalHours(data[0], data[1]);
            const stepDataInterval = this.getStepInterval(data[0], data[1]);

            const items = stepDataInterval === DAY_OR_HOUR_BOUND ? data.length : hours;
            const roundInterval = this.getRoundInterval(Math.ceil(items / numberLabel));
            const coefficientTime = stepDataInterval === 20 ? 3 : 1;
            const isShowDayFormat = this.showDayFormat(hoursInterval);

            let currentDisplayIndex = 0;

            const nextDay = moment(data[currentDisplayIndex])
                .add(1, 'day')
                .startOf('day')
                .milliseconds(0)
                .toISOString()
                .replace('.000', '');

            const ndIndex = data.findIndex(d => d === nextDay);
            const stepSize = roundInterval * coefficientTime;

            if (ndIndex !== -1) {
                currentDisplayIndex = ndIndex % stepSize;
            }

            data.forEach((value, index) => {
                const hasLabel = index === currentDisplayIndex;
                const label = hasLabel ? this.getDisplayValue(value, isShowDayFormat) : '';

                result.push({ index, value, label });

                if (hasLabel) {
                    currentDisplayIndex = stepSize + currentDisplayIndex;
                }
            });
        }

        return result;
    }

    private getDisplayValue(value: string, isShowDayFormat: boolean): string {
        const m = moment.utc(value).local();
        const startDay = moment.utc(value).local().startOf('day');

        if (m.valueOf() === startDay.valueOf() || isShowDayFormat) {
            const month = m.format('MMM').slice(0, 3);
            return `<span class="time-line-track-date">${m.format('D')} ${month}</span>`;
        } else {
            return `<span class="time-line-track-time">${m.format('HH:mm')}</span>`;
        }
    }

    private getStepIntervalHours(start: string, end: string): number {
        const startTime = moment(start);
        const endTime = moment(end);
        const duration = moment.duration(endTime.diff(startTime));
        return duration.asHours();
    }

    private showDayFormat(hours: number) {
        return hours >= 24;
    }

    private formatCurrentTime(value: string): string {
        const hoursInterval = this.getStepIntervalHours(this.dateArray[0], this.dateArray[1]);
        const isShowDayFormat = this.showDayFormat(hoursInterval);
        const localTime = moment.utc(value).local();
        const month = localTime.format('MMM').slice(0, 3);
        const year = localTime.format('YYYY');
        return isShowDayFormat
            ? `${localTime.format('D')} ${month} ${year}, 00:00`
            : `${localTime.format('D')} ${month} ${year}, ${localTime.format('HH:mm')}`;
    }

    private getHourByRange(startDate: string, finishDate: string): number {
        const finish = moment(finishDate);
        const start = moment(startDate);
        return Math.ceil(finish.diff(start, 'hour'));
    }

    private getStepInterval(first: string, second: string): number {
        const init = moment(first);
        const next = moment(second);
        return next.diff(init, 'minutes');
    }

    private getRoundInterval(interval: number) {
        if (interval <= 2) {
            return interval;
        } else if (interval <= 4) {
            return 4;
        } else if (interval <= 6) {
            return 6;
        } else if (interval <= 12) {
            return 12;
        } else if (interval <= 24) {
            return 24;
        } else if (interval <= 48) {
            return 48;
        } else if (interval <= 72) {
            return 72;
        } else {
            return 96;
        }
    }

    private clearDataAQI(): void {
        const { length } = this.dateArray;
        this.timeLineAQI = Array(length).fill(0);
        this.hasDataByIndex = Array(length).fill(true);
    }
}
