<ng-template #popupOutlet>
    <ca-popup-container
        *ngIf="showConfirmationPopup"
        [title]="textsPopup.title"
        (closeAction)="onClosePopup()"
    >
        <confirm-unsaved-popup
            [text]="TEXTS.POPUP_THREE_QUESTIONS.body"
            [continueText]="TEXTS.COMMON.doNotSave"
            [cancelText]="TEXTS.POPUP_THREE_QUESTIONS.cancel"
            (continueAction)="onCancelAndNavigation()"
            (cancelAction)="onClosePopup()"
        ></confirm-unsaved-popup>
    </ca-popup-container>
</ng-template>

<div class="settings_block scroll">

<form (ngSubmit)="onSubmit()" [formGroup]="settingsForm" novalidate>
    <div class="settings_input_forms scroll" (scroll)="tooltipsService.clear()">
        <div class="settings_header">
            <div class="settings_header-wrapper">
                <header-page
                    [cbBack]="cancel"
                    [textObject]="{
                        btnBack: textsNotification.back,
                        titlePage: subscription.id === 0 ? textsNotification.newSubscription : textsNotification.editSubscription
                    }"
                ></header-page>
            </div>
        </div>

        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.titleSubscription}}
                </div>
                <input class="input_name" formControlName="title" type="text" minlength="1" maxlength="255" autocomplete="off" required
                    [class.alert-error]="title.touched && title.invalid"
                />
                <div class="alert_error"
                    [class.alert_error-active]="title.touched && title.invalid"
                >
                    <ng-container *ngIf="title.touched && title.invalid">
                        <div *ngIf="title.errors.required || title.errors.minlength">
                            {{textsNotification.errMsgTitleRequired}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="settings_subtitle">
            <div class="settings_subtitle__name">
                {{textsNotification.parameterForNotif}}
            </div>
        </div>
        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.selectPosts}}
                </div>
                <div class="posts_filter"
                    (clickOutside)="showMosSelection && toggleMosSelectionWithinContainer($event)"
                >
                    <div class="posts_filter__input input_name input_surrogate"
                        [class.alert-error]="!isForAllMos.value && !workingSubscription.moItems.length"
                        (click)="toggleMosSelection()"
                    >
                        {{
                            isForAllMos.value
                                ? textsNotification.allMonitoringPostsSelected
                                : workingSubscription.moItems.length
                                    ? selectedPostsText(workingSubscription.moItems.length)
                                    : textsNotification.noneSelected
                        }}
                        <div class="input_name__arrow"
                            [class.input_name__arrow-active]="showMosSelection"
                        ></div>
                    </div>
                    <cs-selectbox
                        class="posts_filter__popup"
                        *ngIf="showMosSelection"
                        (click)="stopPropagation($event)"
                        [selectAll]="isForAllMos.value"
                        (selectAllChange)="selectAllMos($event)"
                        [listItems]="moCheckboxes"
                        (listItemsChange)="updateMoCheckboxes($event)"
                        [searchPlaceholderText]="TEXTS.NOTIFICATIONS.searchMoPlaceholderText"
                        [selectAllText]="TEXTS.NOTIFICATIONS.selectAll"
                    ></cs-selectbox>
                </div>
            </div>

            <div class="settings__input-wrapper settings__input-wrapper--text"
                *ngIf="workingSubscription.moItems.length !== 0"
            >
                <tags-list
                    [tags]="workingSubscription.moItems"
                    [tagsMapper]="getMoNameHandler"
                    [truncateTo]="maxInlineMos"
                    [editable]="true"
                    [textNodesFns]="postsListLabels()"
                    (removeTag)="removePostFromList($event)"
                ></tags-list>
            </div>

            <div class="settings__input-wrapper">
                <div class="settings__input-label">
                    {{textsNotification.controlledSubstances}}
                </div>
                <div class="list_substances">
                    <div class="list_substances__item"
                        [class.list_substances__item-active]="workingSubscription.isForAllValues"
                        (click)="selectAllValues()"
                    >
                        {{textsNotification.all}}
                    </div>
                    <div class="list_substances__item"
                        [class.list_substances__item-active]="isValueItemSelected(value)"
                        *ngFor="let value of allSubstance"
                        [innerHTML]="getValueName(value)"
                        (click)="toggleValueItem(value)"
                    >
                    </div>
                </div>
            </div>

            <div class="settings__input-wrapper">
                <div class="settings__input-label-wrapper">
                    <div class="settings__input-label">
                        {{textsNotification.excessLevel}}
                    </div>
                    <i class="settings_subtitle__icon settings__input-label__icon"
                        *ngIf="showPdkTooltip"
                        caTooltip
                        [caTooltipTemplate]="pdkTooltip"
                    ></i>
                    <ng-template #pdkTooltip>
                        <ca-detailed-tooltip
                            [title]="textsNotification.subscriptionsPdkTooltip.title"
                            [description]="textsNotification.subscriptionsPdkTooltip.description"
                            [text]="textsNotification.subscriptionsPdkTooltip.text"
                        ></ca-detailed-tooltip>
                    </ng-template>
                </div>
                <div class="gradient_slider">
                    <div class="gradient_slider__value">
                        {{workingSubscription.excessLevel}}&nbsp;{{textsNotification.excessLevelUnits}}
                    </div>
                    <ca-gradient-slider
                        [min]="0.5"
                        [max]="10"
                        [step]="0.5"
                        [sliderModel]="workingSubscription.excessLevel"
                        (sliderModelChange)="updateSliderValue($event)"
                    >
                    </ca-gradient-slider>
                </div>
            </div>
        </div>

        <div class="settings_subtitle">
            <div class="settings_subtitle__name">
                {{textsNotification.recipients}}
            </div>
        </div>
        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.emailListInputLabel}}
                </div>
                <input class="input_name" type="text" minlength="1" autocomplete="email"
                    formControlName="emailsList"
                    [class.alert-error]="emailsListProxy.invalid"
                />
                <div [formGroup]="emailsListProxy" style="display: none;">
                    <input type="email" email
                        *ngFor="let item of emailsListData"
                        [formControlName]="item.name"
                        [value]="item.value"
                    />
                </div>
                <div class="alert_error"
                    [class.alert_error-active]="emailsListProxy.invalid"
                >
                    <div *ngIf="emailsListProxy.invalid">
                        {{textsNotification.errMsgIncorrectEmails}}
                    </div>
                </div>
            </div>

            <div class="settings__input-wrapper">
                <div class="settings_radio">
                    <label class="settings_radio__str">
                        <div>
                            <input class="settings_radio__btn settings_radio__btn-input" type="radio"
                                formControlName="isPeriodic"
                                [value]="true"
                            >
                            <div class="settings_radio__btn settings_radio__btn-img"></div>
                        </div>
                        <div class="settings_radio__text">{{textsNotification.notifyPeriodically}}</div>
                    </label>
                    <label class="settings_radio__str">
                        <div>
                            <input class="settings_radio__btn settings_radio__btn-input" type="radio"
                                formControlName="isPeriodic"
                                [value]="false"
                            >
                            <div class="settings_radio__btn settings_radio__btn-img"></div>
                        </div>
                        <div class="settings_radio__text">{{textsNotification.notifyEvent}}</div>
                    </label>
                </div>    
            </div>
        </div>

        <div class="settings_subtitle">
            <div class="settings_subtitle__name">
                {{textsNotification.dailyReport}}
            </div>
            <div class="settings_subtitle__icon"
                caTooltip
                [caTooltipTemplate]="emailTooltip"
            ></div>
            <ng-template #emailTooltip>
                <ca-info-tooltip
                    [text]="textsNotification.tooltipSettingsHintDailyReport"
                ></ca-info-tooltip>
            </ng-template>
        </div>
        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.emailListInputLabel}}
                </div>
                <input class="input_name" type="text" minlength="1" autocomplete="email"
                    formControlName="emailsListReports"
                    [class.alert-error]="emailsListReportsProxy.invalid"
                />
                <div [formGroup]="emailsListReportsProxy" style="display: none;">
                    <input type="email" email
                        *ngFor="let item of emailsListReportsData"
                        [formControlName]="item.name"
                        [value]="item.value"
                    />
                </div>
                <div class="alert_error"
                    [class.alert_error-active]="emailsListReportsProxy.invalid"
                >
                    <div *ngIf="emailsListReportsProxy.invalid">
                        {{textsNotification.errMsgIncorrectEmails}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="settings_footer">
        <div class="action_button__wrapper">
            <ca-button type="cancel"
                (clickAction)="cancel()"
            >
                {{textsNotification.cancel}}
            </ca-button>
            <ca-button type="primary"
                (clickAction)="save()"
                [disabled]="settingsForm.disabled || settingsForm.invalid || !formDataHasChanged"
            >
                <ca-spinner *ngIf="saving; else showContent"></ca-spinner>
                <ng-template #showContent>
                    {{textsNotification.save}}
                </ng-template>
            </ca-button>
        </div>
    </div>
</form>

</div>

