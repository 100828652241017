import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { TEXTS } from 'src/texts/texts';
import type { CheckboxItem } from 'src/namespace';
import { STATUS_TYPES } from '../device-status/device-status.types';

export type DeviceStatusCheckboxItem = CheckboxItem & {
    id: STATUS_TYPES;
};

export const STATUS_TEXTS: {
    [key in STATUS_TYPES]: string;
} = {
    ok: '',
    lowBattery: TEXTS.DASHBOARD.lowBattery,
    lowSignal: TEXTS.DASHBOARD.lowSignal,
    noPackets: TEXTS.DASHBOARD.noPackets
};

@Component({
    selector: 'device-status-filter',
    templateUrl: 'device-status-filter.component.html',
    styleUrls: ['device-status-filter.component.less']
})
export class DeviceStatusFilterComponent implements OnInit {
    @Input() listItems: DeviceStatusCheckboxItem[];
    @Input() resetText?: string;

    @Output() listItemsChange = new EventEmitter<DeviceStatusCheckboxItem[]>();

    searchText = '';
    form: FormGroup;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            allItems: [false],
            items: this.fb.group(
                this.getItemsCheckboxMap(this.listItems)
            )
        });

        this.allItems.valueChanges
            .subscribe(value => {
                this.items.patchValue(
                    this.getItemsCheckboxMap(
                        Object.keys(this.items.value).map(id => ({ id })) as DeviceStatusCheckboxItem[],
                        value
                    )
                );
            });

        this.items.valueChanges
            .subscribe(value => {
                this.listItems.forEach((item) => {
                    item.selected = value[item.id];
                });

                this.listItemsChange.emit(this.listItems);
            });
    }

    resetFilter() {
        this.allItems.patchValue(false);
    }

    private getItemsCheckboxMap(items: DeviceStatusCheckboxItem[], resetValue?: boolean) {
        return items.reduce((acc, { id }) => ({
            ...acc,
            [id]: resetValue ?? items.find(it => it.id === id).selected
        }), {});
    }

    get allItems() {
        return this.form.get('allItems');
    }

    get items() {
        return this.form.get('items') as FormGroup;
    }
}
