import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { UsersPageWrapperComponent } from 'src/users/components/users-page-wrapper/users-page-wrapper.component';
import { AnalyticsPageWrapperComponent } from 'src/analytics/components/analytics-page-wrapper/analytics-page-wrapper.component';
import { PlumesPage } from 'projects/cityscreen/src/modules/plumes/components/plumes-page/plumes-page.component';
import { namePages } from 'src/namespace';
import { ConfigPanelComponent } from 'projects/cityscreen/src/components/config-menu/config.component';
import { Notifications } from 'projects/cityscreen/src/modules/notifications/components/notifications/notifications.component';
import { PostsAndDevicesPageComponent } from 'projects/cityscreen/src/modules/posts-and-devices/components/posts-and-devices-page/posts-and-devices-page.component';
import { networksRoutes } from 'projects/cityscreen/src/modules/posts-and-devices/posts-and-devices.route';
import { IndoorMainComponent } from 'projects/cityscreen/src/modules/indoor/components/indoor-main/indoor-main.component';

export const adminPanelRoutes: Routes = [
    {
        path: namePages.listUsers,
        component: UsersPageWrapperComponent,
    },
    {
        path: namePages.cityCard,
        component: AnalyticsPageWrapperComponent,
    },
    {
        path: namePages.plumes,
        component: PlumesPage,
    },
    {
        path: namePages.indoor,
        component: IndoorMainComponent,
    },
    {
        path: namePages.notifications,
        component: Notifications,
    },
    {
        path: namePages.configPanel,
        component: ConfigPanelComponent,
    },
    {
        path: namePages.networks,
        component: PostsAndDevicesPageComponent,
        children: networksRoutes
    },
]

@NgModule({
    imports: [
        RouterModule.forChild(adminPanelRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class AdminPanelRoutingModule {
}
