import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Marker_model } from '../../../src/namespace';

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform  {
    constructor(private sanitized: DomSanitizer) {}

    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({
    name: 'multipleSearchfilter'
})
export class MultipleSearchfilterPipe implements PipeTransform {
    transform(items: any[], fields: string[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter(it => {
            const itsStr = fields.map(f => it[f]) + '';
            return itsStr.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
    }
}

@Pipe({
    name: 'multipleSearchFns'
})
export class MultipleSearchFnsPipe implements PipeTransform {
    transform(items: any[], fields: ((item: any) => string)[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter(it => fields
            .map(fn => fn(it))
            .toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) !== -1
        );
    }
}

@Pipe({
    name: 'filterData'
})
export class FilterData implements PipeTransform {
    transform(items: any[], fields: string[], value: any[]): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null) {
            console.error('FilterData value == undefined || value == null');
            return items;
        }
        if (fields.length !== value.length) {
            console.error('FilterData fields.length !== value.length');
            return items;
        }
        return items.filter(item => {
            return fields.every((field, i) => item[field] === value[i]);
        });
    }
}

@Pipe({
    name: 'markerFilter'
})
export class MarkerFilter implements PipeTransform {
    transform(items: Marker_model[], city: boolean): Marker_model[] {
        if (!items) {
            return [];
        }
        return items.filter((item) => city ? !!item.cityId : !item.cityId);
    }
}

@Pipe({name: 'markerFilterOpacity0'})
export class MarkerFilterOpacity0 implements PipeTransform {
    transform(items: Marker_model[]): Marker_model[] {
        if (!items) {
            return [];
        }
        return items.filter(item => item.opacity !== 0);
    }
}

@Pipe({
    name: 'sorting'
})
export class SortingPipe implements PipeTransform {
    transform(items: any[], column: (item: any) => string | number | boolean, direction = 1): any[] {
        if (!items) {
            return [];
        }

        if (!column) {
            return items;
        }

        return [...items].sort((a, b) => {
            const aValue = column(a);
            const bValue = column(b);

            const p1 = typeof aValue === 'number' ? aValue : aValue?.toString().toLowerCase();
            const p2 = typeof bValue === 'number' ? bValue : bValue?.toString().toLowerCase();

            if ((!p1 && p1 !== 0 && p1 !== '') || p1 > p2) {
                return Math.sign(direction);
            }

            if ((!p2 && p2 !== 0 && p1 !== '') || p1 < p2) {
                return -Math.sign(direction);
            }

            return 0;
        });
    }
}

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe<T> implements PipeTransform {
    transform(items: T[], predicate: (item: T) => boolean): T[] {
        return items?.length ? items.filter(predicate) : items;
    }
}
