import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import {
    selectGroupInfo,
    selectMos,
    selectMyDevices
} from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AdminDevice } from 'src/namespace';
import { CardMo } from 'projects/cityscreen/src/modules/posts-and-devices/components/posts-list/posts-list.component';

@Injectable({
    providedIn: 'root'
})
export class PostsAndDevicesFacade implements OnDestroy {
    isPermissionViewDevices = false;
    isAvailabilityPosts = false;
    isInnerRouting = false;

    isSwitchDataSources = false;

    sortingDevice;
    sortingPost;
    sortingDirection = {
        sortDeviceSerialNum: 0,
        sortDeviceType: 0,
        sortDeviceState: 0,
        sortPostName: 0,
        sortPostLocation: 0,
        sortPostAqi: 0
    }

    sortingDeviceDirectionMain = 1;
    sortingPostDirectionMain = 1;

    sortDeviceSerialNum = (stateInfoCards: AdminDevice) => stateInfoCards.serialNumber;
    sortDeviceState = (stateInfoCards: AdminDevice) => stateInfoCards.offline;
    sortPostName = (stateInfoCards: CardMo) => stateInfoCards.name;
    sortPostAqi = (stateInfoCards: CardMo) => stateInfoCards.aqi?.aqi;

    subscription1;
    subscription2;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public store: Store<any>,
    ) {
    }

    showDataSources(isSwitchDataSrc: boolean) {
        this.isSwitchDataSources = isSwitchDataSrc;
    }

    makeDeviceSorting = (sort, direction) => {
        this.sortingDirection.sortDeviceSerialNum = 0
        this.sortingDirection.sortDeviceType = 0
        this.sortingDirection.sortDeviceState = 0
        if (this.sortingDevice === sort) {
            this.sortingDeviceDirectionMain *= -1;
        } else {
            this.sortingDevice = sort;
        }
        this.sortingDirection[direction] = this.sortingDeviceDirectionMain;
    }

    makePostSorting = (sort, direction) => {
        this.sortingDirection.sortPostLocation = 0
        this.sortingDirection.sortPostName = 0
        this.sortingDirection.sortPostAqi = 0
        if (this.sortingPost === sort) {
            this.sortingPostDirectionMain *= -1;
        } else {
            this.sortingPost = sort;
        }
        this.sortingDirection[direction] = this.sortingPostDirectionMain;
    }

    openPage(routerLink: string = null) {
        return this.router.navigate([routerLink], {
            relativeTo: this.activatedRoute,
        });
    }

    checkExistsPage(id) {
        let resultMo = false
        let resultDevice = false
        this.subscription1 = this.store.select(selectMos)
            .pipe(filter(mos => !!mos.length))
            .subscribe(mos => {
                this.isAvailabilityPosts = true;
                resultMo = !!mos.find(m => m.id == id);
            })
        this.subscription2 = this.store.select(selectMyDevices)
            .pipe(filter(smd => !!smd?.length))
            .subscribe(smd => {
                resultDevice = !!smd.find(m => m.serialNumber == id);
            })
        return resultMo || resultDevice;
    }

    checkAvailabilityPosts() {
        return this.isAvailabilityPosts;
    }

    ngOnDestroy() {
        this.subscription1?.unsubscribe();
        this.subscription2?.unsubscribe();
    }
}
