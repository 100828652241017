import { Component, Input } from '@angular/core';

import { TooltipsService } from 'projects/shared/components/tooltips/tooltips.service';

@Component({
    selector: 'ca-tooltip-pointer',
    templateUrl: 'tooltip-pointer.component.html',
    styleUrls: ['tooltip-pointer.component.less']
})
export class TooltipPointerComponent {
    @Input() fill = 'transparent';

    constructor(readonly tooltipsService: TooltipsService) {}
}
