// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/copy.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".api_tokens{height:calc(100vh - 116px)}.api_tokens__item{display:block;margin:0 14px 12px}.api_tokens__item .browser_tokens__item{border:none;margin:0 -10px}.api_tokens__token_place{display:flex;align-items:center;justify-content:space-between;height:40px;padding:0 14px;background:#f7f7f7;border:1px solid #e9e9e9;box-sizing:border-box;border-radius:4px;font-size:14px;line-height:16px;letter-spacing:-.4px;color:#bfbfbf}.api_tokens__copy_token{height:24px;width:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;cursor:pointer;opacity:.7;transition:opacity .2s linear}.api_tokens__copy_token:hover{opacity:1}.api_tokens__copy_token:active{opacity:.5}.api_tokens__new_title{margin-top:44px;font-size:12px;line-height:14px;letter-spacing:-.2px;color:#bfbfbf}.api_tokens__new_title_input{display:block;width:100%;margin-top:8px;margin-bottom:70px;background:#fff;border:1px solid #e9e9e9;box-sizing:border-box;border-radius:4px;padding:0 25px;font-size:14px;line-height:38px;letter-spacing:-.4px;color:#4a4a4a}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
