import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from '../../../../../src/texts/texts';
import { WindowGlobalVars } from '../../../../../src/namespace';

import './logout.component.less';
declare const window: WindowGlobalVars;

@Component({
    selector: 'logout',
    templateUrl: 'logout.component.html',
})
export class Logout {
    @Output() handleLogout = new EventEmitter<void>();

    showLogout = window.CLOSED_MAP;

    getUser = () => window.JS_CP_LOGIN ? window.JS_CP_LOGIN.substr(0, 2) : '';

    TEXTS = TEXTS;
    isOpenSelectLang = false;

    logout() {
        this.handleLogout.emit();
    }

}
