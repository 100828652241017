import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';

import { TEXTS } from 'src/texts/texts';
import { Feature } from '../models/core';
import {
    CO2,
    HUM,
    PM2,
    PM10,
    PRES,
    TEMP,
    SO2,
    CO,
    O3,
    NO2,
    H2S,
    NH3,
} from '../constants';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public unitsMeasures = TEXTS.MEASURES;

    constructor(@Inject(LOCALE_ID) public locale: string) {}

    public translate(obj: Feature): string {
        const langPropName = this.getCityNameProp();
        return obj.properties[langPropName];
    }

    public getCurrentCityName(obj: Feature) {
        if (!obj) {
            return '';
        }
        return this.translate(obj);
    }

    public units = {
        T: this.unitsMeasures[TEMP],
        P: this.unitsMeasures[PRES],
        RH: this.unitsMeasures[HUM],
        'PM2.5': this.unitsMeasures[PM2],
        PM10: this.unitsMeasures[PM10],
        CO: this.unitsMeasures[CO],
        CO2: this.unitsMeasures[CO2],
        NO2: this.unitsMeasures[NO2],
        SO2: this.unitsMeasures[SO2],
        O3: this.unitsMeasures[O3],
        H2S: this.unitsMeasures[H2S],
        NH3: this.unitsMeasures[NH3],
    };

    getCityNameProp() {
        if (this.locale == 'en') {
            return 'name'
        } else {
            return 'name_' + this.locale;
        }
    }

    getWeekShort() {
        const result: string[] = [];

        for (let i = 1; i <= 7; i++) {
            const value = moment.weekdaysMin(i);
            if (this.locale === 'ru') {
                result.push(value.charAt(0).toUpperCase() + value.slice(1));
            } else {
                result.push(value);
            }
        }

        return result;
    }
}
