import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import {
    ChartDataSets,
    ChartOptions,
    ChartPoint,
    ChartTooltipItem, ChartTooltipLabelColor,
    ChartType,
    ChartYAxe
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as moment from 'moment';

import { TEXTS } from 'src/texts/texts';
import { lightenColor } from 'src/utils';
import { TimelineState } from '../store/index';
import { getSelectedMeasures, selectChartEnabled } from '../store/selectors/core.selectors';
import { onEnabledChart, onMeasuresChanges, onSelectGeoObj } from '../store/core.actions';
import { Feature } from '../models/core';
import {
    MEASUREMENTS_ORDER,
    SELECT_MEASUREMENTS_INIT,
    DEFAULT_METEO_VALUES_COLOR,
    METEO_VALUES_COLORS,
    CHART_LINE_COLORS,
    LINE_DASH_STYLES,
    BAR_CHARTS,
    CITY_OBJ_TYPE,
    AQI,
    AUTO_SCALED_MEASUREMENTS,
} from '../constants';
import { HelperService } from '../services/helper.service';
import { RenameAQIPipe } from '../pipes/rename-aqi.pipe';
import { replaceBarWithRoundedBar } from './rounded-bars';
import { replaceTooltipUpdate } from './plugin-tooltip';

export const ANIMATION_CHART_HEIGHT = [
    trigger('inOutAnimation', [
        transition(':leave', [
            style({ height: 180 }),
            animate('0.2s ease-in', style({ height: 0 })),
        ]),
    ]),
];

function sortMeasurements(a: string, b: string) {
    const index = MEASUREMENTS_ORDER.indexOf(a);

    if (index === -1) {
        return 1;
    }

    return MEASUREMENTS_ORDER.indexOf(a) - MEASUREMENTS_ORDER.indexOf(b);
}

function createLineChartData(
    data: ChartPoint[],
    seriesName: string,
    key: string,
    axisId: string,
    i: number
): ChartDataSets {
    return {
        label: keyToLabel(seriesName, key),
        type: 'line',
        data,
        borderColor: METEO_VALUES_COLORS[key] || DEFAULT_METEO_VALUES_COLOR,
        borderDash: LINE_DASH_STYLES[i],
        order: 2,
        yAxisID: axisId,
    };
}

function createBarChartData(
    data: ChartPoint[],
    seriesName: string,
    key: string
): ChartDataSets {
    const backgroundColor = data.map(p => CHART_LINE_COLORS[p.y as number]);

    return {
        label: keyToLabel(seriesName, key),
        type: 'bar',
        barThickness: 'flex',
        data,
        backgroundColor,
        hoverBackgroundColor: backgroundColor.map(c => lightenColor(c)),
        order: 1,
        yAxisID: key
    };
}

function withDataPaddings(data: {x: string; y: number}[]) {
    if (!data?.length) {
        return [];
    }

    const first = moment(data[0].x);
    const last = moment(data[data.length - 1].x);

    const timeStep = (last.valueOf() - first.valueOf()) / (data.length - 1);

    const addDelta = (date: moment.Moment, d: number) =>
        date.add(d, 'milliseconds').toISOString();

    return [
        {
            x: addDelta(first, -timeStep),
            y: null
        },
        ...data,
        {
            x: addDelta(last, timeStep),
            y: null
        },
    ];
}

function labelToKey(label: string) {
    return label.split('$$')[1];
}

function keyToLabel(seriesName: string, key: string) {
    return `${seriesName}$$${key}`;
}

replaceBarWithRoundedBar();
replaceTooltipUpdate();

@Component({
    selector: 'airvoice2-chart-timeline',
    templateUrl: './chart-timeline.component.html',
    styleUrls: ['./chart-timeline.component.less'],
    animations: [ANIMATION_CHART_HEIGHT],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTimelineComponent implements OnInit, OnChanges {
    @Input() timeIndex: number;
    @Input() data: Feature[];
    @Input() isCompare: boolean;
    @Input() showCompare: boolean;
    @Input() aqiName?: string;
    @Input() showGridLines?: boolean;
    @Input() initSelectMeasurement?: string;

    @Output() setPosition = new EventEmitter<number>();
    @Output() setCompare = new EventEmitter<boolean>();

    TEXTS = TEXTS;

    public chartEnabled: boolean;
    public units = this.helperService.units;
    public availableMeasurements: string[] = MEASUREMENTS_ORDER;
    public selectedMeasurements: string[] = [...SELECT_MEASUREMENTS_INIT];
    private initMeasurements: string[];
    public cityNameField: string;
    public isEmptyData = false;
    public isEmptyDataByMmt = {};

    showStationSubtitle = false;

    constructor(
        private store: Store<TimelineState>,
        private helperService: HelperService,
        private _changeDetectorRef: ChangeDetectorRef,
        private renameAQIPipe: RenameAQIPipe,
    ) {
        this.initState();
        this.cityNameField = this.helperService.getCityNameProp();
    }

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    hoverLinePosition = 0;
    hoverLineVisible = false;

    private initState() {
        this.store.select(selectChartEnabled).subscribe((data) => {
            this.chartEnabled = data;
            this._changeDetectorRef.markForCheck();
        });
        this.store.select(getSelectedMeasures).pipe(take(1)).subscribe((data) => {
            this.initMeasurements = data;
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnInit() {
        this.aqiName = this.aqiName || AQI;
        this.initMeasurements = [this.initSelectMeasurement] || this.initMeasurements;
        this.selectedMeasurements = this.initMeasurements ? this.initMeasurements : [...SELECT_MEASUREMENTS_INIT];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.chartData = [];
            this.availableMeasurements = this.getMeasurements(this.data);
            this.isEmptyDataByMmt = this.updateEmptyDataMmt(this.data);
            this.isEmptyData = this.hasNoTimeseries(this.data) || !this.hasNonEmptyDataSeries();
            this.createYAxisConfig();
            this.createChartData(this.data);
        }
    }

    private createYAxisConfig() {
        const yAxes = this.availableMeasurements.reduce((acc, mmt) => {
            const isAQI = mmt === 'AQI';

            const ticksMinMax = isAQI ? {
                min: 0,
                max: 10
            } : !AUTO_SCALED_MEASUREMENTS.includes(mmt) ? {
                min: 0
            } : {};

            const id = this.getAxisId(mmt);

            return !acc.find(scale => scale.id === id) ? [
                ...acc,
                {
                    id,
                    display: isAQI,
                    position: 'right',
                    ticks: {
                        mirror: true,
                        display: false,
                        ...ticksMinMax
                    },
                    gridLines: {
                        drawBorder: false,
                        tickMarkLength: 0,
                        display: false
                    }
                }
            ] : acc;
        }, []);

        this.updateYAxes(yAxes);
    }

    toggleMeasurement(mmt: string) {
        const selectedOne = this.selectedMeasurements.length === 1 && this.selectedMeasurements.indexOf(mmt) === 0;

        if (!selectedOne) {
            const toDisable = this.selectedMeasurements.includes(mmt);

            if (toDisable) {
                this.selectedMeasurements = this.selectedMeasurements.filter((m) => m !== mmt);
            } else {
                this.selectedMeasurements = this.selectedMeasurements
                    .concat(mmt)
                    .sort(sortMeasurements);
            }

            this.store.dispatch(onMeasuresChanges({ payload: this.selectedMeasurements }));
            this.updateChart(mmt, toDisable);
        }

        this.isEmptyData = !this.hasNonEmptyDataSeries();
    }

    toggleMeasurementOne(mmt: string) {
        this.isEmptyData = this.isEmptyDataByMmt[mmt];

        const datasets = this.chartData.filter(d => this.selectedMeasurements.indexOf(labelToKey(d.label)) >= 0);

        datasets.forEach((dataset) => {
            dataset.hidden = true;
        });

        this.selectedMeasurements = [mmt];
        this.store.dispatch(onMeasuresChanges({payload: this.selectedMeasurements}));
        this.updateChart(mmt, false);
    }

    closeChart() {
        this.store.dispatch(onEnabledChart({ obj: false }));
    }

    selectFeature(city: Feature) {
        this.store.dispatch(onSelectGeoObj({ obj: city }));
    }

    onCompare() {
        this.setCompare.emit();
    }

    getCurrentCityName(city: Feature): string {
        return this.helperService.getCurrentCityName(city);
    }

    featureIdentify(_: number, feature: Feature) {
        return feature.properties.uuid;
    }

    measurementIdentify(index: number) {
        return index;
    }

    getValue(city: Feature, mmt: string) {
        const { timeseries } = city.properties;
        const data = timeseries?.[mmt] || null;
        const index = this.timeIndex;

        if (!data) {
            return '-';
        } else {
            return data[index] !== null ? this.prepareMmt(data[index], mmt) : '-';
        }
    }

    prepareMmt(value: number, mmt: string) {
        return Math.round(value);
    }

    getAqiValue(city: Feature, mmt: string) {
        const { timeseries } = city.properties;
        const data = timeseries?.[mmt] || null;

        return data?.[this.timeIndex] ? Math.round(data[this.timeIndex]) : 0;
    }

    private updateChart(mmt: string, toDisable: boolean) {
        const datasets = this.chartData.filter(d => d.label.endsWith(mmt));

        datasets.forEach((dataset) => {
            dataset.hidden = toDisable;
        });

        this.updateYAxes(this.chart.options.scales.yAxes);

        this.chart.update();
    }

    private getAxisId(mmt: string) {
        return this.helperService.units[mmt] ?? mmt;
    }

    private updateYAxes(yAxes: ChartYAxe[]) {
        yAxes.forEach(axis => {
            axis.display = false;
            axis.ticks.display = false;
            axis.gridLines.display = false;
        });

        if (this.selectedMeasurementsOfSameScale()) {
            // display axis with ticks if selected datasets have the same axis
            const mmt = this.selectedMeasurements[0];

            yAxes.filter(axis => axis.id === this.getAxisId(mmt)).forEach(axis => {
                if (this.showGridLines) {
                    axis.display = true;
                    axis.ticks.display = true;
                    axis.gridLines.display = true;
                }
            });
        } else {
            // otherwise display y-grid for the AQI but without ticks
            const axis = yAxes.find((axis) => axis.id === 'AQI');
            if (axis && this.showGridLines) {
                axis.display = true;
                axis.gridLines.display = true;
            }
        }

        // push updates to the chart component
        this.chartOptions = {
            ...this.chartOptions,
            scales: {
                ...this.chartOptions.scales,
                yAxes
            }
        };
    }

    private selectedMeasurementsOfSameScale() {
        const ids = new Set(this.selectedMeasurements.map(mmt => this.getAxisId(mmt)));
        return ids.size === 1;
    }

    chartClick(e: { active?: any; event?: MouseEvent }) {
        const activeElements: any[] = this.chart.chart.getElementsAtXAxis(e.event);
        const element = activeElements[0];

        if (element) {
            this.moveRunnerToChartElement(element);
        }
    }

    private moveRunnerToChartElement(element: any) {
        const { index } = this.getDataForElement(element);
        const len = this.chartData[0].data.length;

        if (index > 0 && index < len - 1) {
            this.setPosition.emit(index - 1);
        }
    }

    private getDataForElement(chartElement: any) {
        return {
            index: chartElement._index,
            data: this.chartData.find(d => d.label === chartElement._yScale.id)?.data as ChartPoint[]
        };
    }

    private getMeasurements(data: Feature[]) {
        const result = [];

        data.forEach((item) => {
            const keys = Object.keys(item.properties.timeseries);
            keys.forEach((key) => {
                if (key !== 'date' && result.indexOf(key) === -1) {
                    result.push(key);
                }
            });
        });

        result.sort(sortMeasurements);
        // check selected items
        if (result.length) {
            const selectedMeasurements = result.filter((item) => this.selectedMeasurements.indexOf(item) >= 0);
            if (!selectedMeasurements.length) {
                this.selectedMeasurements = [...SELECT_MEASUREMENTS_INIT];
                this.store.dispatch(onMeasuresChanges({ payload: this.selectedMeasurements }));
            }
        }

        return result;
    }

    public getCityName(city: Feature) {
        const ancestor = city.properties.ancestors?.filter((item) => item.obj === CITY_OBJ_TYPE);
        return ancestor?.[0]?.[this.cityNameField] || '';
    }

    chartType: ChartType = 'line';

    chartData: ChartDataSets[] = [];

    chartPlugins: Chart.PluginServiceRegistrationOptions[] = [
        {
            beforeDraw: (chart) => {
                const {
                    ctx,
                    chartArea: { left, right, bottom }
                } = chart;

                ctx.save();
                ctx.strokeStyle = '#E6ECF2'; // @grey3
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(left, bottom);
                ctx.lineTo(right, bottom);
                ctx.closePath();
                ctx.stroke();
                ctx.restore();
            }
        }
    ];

    chartOptions: ChartOptions & {
        barBorderRadius?: number;
        customAlignTooltip?: boolean;
        tooltips?: ChartOptions['tooltips'] & {
            xAlign?: string;
            yAlign?: string;
        }
    } = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0
        },
        barBorderRadius: 2,
        customAlignTooltip: true,
        scales: {
            xAxes: [
                {
                    type: 'time',
                    display: false,
                    time: {
                        tooltipFormat: 'DD MMM, HH:mm',
                        parser: (value) => moment.utc(value).local()
                    }
                },
            ],
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        onHover: (event: MouseEvent, _) => {
            const activeElements = this.chart.chart.getElementsAtXAxis(event);
            const element = activeElements[0] as any;

            if (element) {
                this.hoverLinePosition = element._view.x;
                this.hoverLineVisible = true;
                this._changeDetectorRef.detectChanges();
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            filter: ({ datasetIndex }, { datasets }) => {
                const key = labelToKey(datasets[datasetIndex].label);
                return this.selectedMeasurements.indexOf(key) <= 3;
            },
            yAlign: 'center',
            callbacks: {
                label: (tti, data) => {
                    const parser = data.datasets[tti.datasetIndex].label.split('$$');
                    let label = parser.length > 1 ? parser[1] : parser[0];
                    const cityName = parser.length > 1 ? parser[0] : '';
                    label = this.renameAQIPipe.transform(label, this.aqiName);
                    const l = `${label}: ${tti.yLabel}`;
                    return cityName ? `${l} (${cityName})` : l;
                },
                labelColor: (tooltipItem: ChartTooltipItem, chart: Chart): ChartTooltipLabelColor => {
                    const { datasetIndex, value } = tooltipItem;
                    const { datasets } = chart.config.data;

                    const isAQI = datasets[datasetIndex].yAxisID === AQI;
                    const aqiColor = CHART_LINE_COLORS[value] ? CHART_LINE_COLORS[value] : CHART_LINE_COLORS[1];
                    const borderColor = isAQI ? aqiColor : datasets[datasetIndex].borderColor as string;

                    return {
                        borderColor,
                        backgroundColor: borderColor
                    };
                }
            },
        },
    };

    dashLinesMap: {
        [key: string]: number;
    } = {};

    private createChartData(data: Feature[]) {
        const chartData = [];

        this.dashLinesMap = data.reduce((acc, v, i) => ({
            ...acc,
            [v.properties.uuid]: LINE_DASH_STYLES[i].join(' ')
        }), {});

        // show feature name if comparison
        const showName = data.length > 1;
        this.availableMeasurements.forEach((key) => {
            const chartsForKey = data.map(f => {
                const { timeseries } = f.properties;

                return (timeseries[key] as number[])?.map((y, i) => ({
                    // TODO: will be rounded on backend
                    y: y !== null ? Math.round(y) : null,
                    x: timeseries.date[i]
                })) || [];
            });

            chartsForKey.forEach((chartForKey, i) => {
                const seriesName = showName ? data[i].properties[this.cityNameField] : '';
                const dataset = BAR_CHARTS.includes(key)
                    ? createBarChartData(withDataPaddings(chartForKey), seriesName, key)
                    : createLineChartData(withDataPaddings(chartForKey), seriesName, key, this.getAxisId(key), i);

                chartData.push(dataset);

                if (!this.selectedMeasurements.includes(key)) {
                    dataset.hidden = true;
                }
            });
        });

        this.chartData = chartData;
    }

    private hasNoTimeseries(data: Feature[]): boolean {
        return data.filter(d => d.properties.has_any_timeseries).length === 0;
    }

    private updateEmptyDataMmt(data: Feature[]) {
        return data.reduce((acc, item) => {
            const { timeseries } = item.properties;

            for (const key in timeseries) {
                if (key !== 'date') {
                    if (!acc.hasOwnProperty(key) || acc[key] !== false) {
                        const isEmpty = !timeseries[key].some((v: number) => v !== null);
                        acc[key] = isEmpty;
                    }
                }
            }

            return acc;
        }, {});
    }

    private hasNonEmptyDataSeries(): boolean {
        return this.selectedMeasurements.some(key => !this.isEmptyDataByMmt[key]);
    }
}
