import {
    animate,
    style,
    transition,
    trigger,
    state
} from '@angular/animations';
import * as moment from 'moment';

export const TIMELINE_STEP = 600;

export const ANIMATION_TIMEOUT = 200;

export const ANIMATION_OPACITY = [
    trigger('inOutAnimation', [
        transition(':enter', [
            style({opacity: 0}),
            animate('0.2s linear', style({opacity: 1}))
        ]),
        transition(':leave', [
            style({opacity: 1}),
            animate('0.2s linear', style({opacity: 0}))
        ])
    ])
];

export const ANIMATION_MAX_HEIGHT = [
    trigger('maxHeightAnimation', [
        state('true', style({
            maxHeight: '{{numberMaxHeight}}px',
            overflow: 'hidden'
        }), {params: {numberMaxHeight: 1}}),
        state('false', style({
            maxHeight: '0px',
            overflow: 'hidden',
            opacity: 0
        })),
        transition('true => false', animate('0.3s linear')),
        transition('false => true', animate('0.3s linear'))
    ])
];

export const LOAD_HISTORY_DEFAULT = 3; // days
export const LOAD_STATION_SMALL_INTERVAL = 2; // type
export const LOAD_STATION_DETAIL_INTERVAL = 1; // type

export const getStndTimeBegin: () => number = () => moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();
export const getStndTimeEnd: () => number = () => {
    const m = moment();
    m.startOf('minutes')
    m.subtract(m.get('minutes') % 20, 'minutes') // floor of 20 minutes
    return m.valueOf();
}
export const WIDTH_LEFT_MENU = 58;
export const MAPBOX_STYLES = {
    landuse: {
        'fill-color': '#dfe5dc'
    },
    landcover: {
        'fill-color': '#dfe5dc'
    },
    water: {
        'fill-color': '#A8CDFF'
    },
    land: {
        'background-color': '#f0f0f0'
    }
};
export const MAPBOX_LABELS_COLOR = '#999999';
export const MAPBOX_BOUNDARY_LAYERS = [
    'admin-0-boundary-disputed',
    'admin-0-boundary',
    'admin-0-boundary-bg',
    'admin-1-boundary',
    'admin-1-boundary-bg'
];
export const MAPBOX_LABEL_LAYERS = [
    'road-label',
    'waterway-label',
    'natural-line-label',
    'natural-point-label',
    'water-line-label',
    'water-point-label',
    'poi-label',
    'airport-label',
    'settlement-subdivision-label',
    'settlement-label',
    'state-label',
    'country-label'
];
