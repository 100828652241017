import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';

import { UserItems } from '../../../namespace';
import { TEXTS } from '../../../texts/texts';
import { OffPanelPopupService } from '../../../../projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { UsersStateService } from '../../users-state.service';
import AdminPanelActions from '../../../../projects/cityscreen/src/components/admin-panel/actions';

import './user-record.component.less';

@Component({
    selector: 'user-record',
    templateUrl: './user-record.component.html'
})
export class UserRecord {
    @Input() objUser: UserItems;

    popupActive = false;
    popupPositionTop = '';

    TEXTS = TEXTS;

    @ViewChild('popupOpener', { static: true }) popupOpener: ElementRef<HTMLDivElement>;
    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    constructor(public popupProvider: OffPanelPopupService, public stateService: UsersStateService, public apActions: AdminPanelActions) {
    }

    showDeleteUserPopup = () => this.stateService.isShowDeleteUserPopup = true;
    hideDeleteUserPopup = () => this.stateService.isShowDeleteUserPopup = false;

    openPopup = () => {
        const { nativeElement } = this.popupOpener;
        this.popupProvider.setTemplate(this.popupOutlet);
        this.popupPositionTop = `${nativeElement.getBoundingClientRect().top - nativeElement.offsetTop}px`;
        this.popupActive = true;
    }

    closePopup(e: Event) {
        if (this.popupActive && this.popupOpener.nativeElement !== e.target) {
            this.popupActive = false;
        }
    }
}
