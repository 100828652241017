import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceCardComponent } from './device-card.component';
import { AqiCircleModule } from 'projects/shared/components/aqi-circle/aqi-circle.module';
import { KebabMenuModule } from 'projects/shared/components/kebab-menu/kebab-menu.module';
import { StaticTagsModule } from '../static-tags/static-tags.module';
import { AlertTagsModule } from 'projects/shared/components/alert-tags/alert-tags.module';

@NgModule({
    imports: [
        CommonModule,
        AqiCircleModule,
        KebabMenuModule,
        StaticTagsModule,
        AlertTagsModule,
    ],
    exports: [
        DeviceCardComponent,
    ],
    declarations: [
        DeviceCardComponent,
    ]
})
export class DeviceCardModule {
}
