import { NgZone } from '@angular/core';

import MapState from '../../mapState';
import { MapActionsInterface } from '../../mapActionsInterface';
import { MapActionsWrapper, MapStateWrapper } from '../../wrapper';

import { calculateGeometryForMarker, findClosestCity, MapObject } from './mapAPI';

import { GLOBAL_ZOOM_LEVEL, STND_CITY_ZOOM, STND_GLOBAL_MIN_ZOOM, } from 'src/config';

import { DATA_INTERVAL_TYPES, Marker_model } from 'src/namespace';

import { findAqiInMarker, getTimelineInterval, isFalseNumber, isFuture } from 'src/utils';

import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import {
    GroupExtConfigName,
    GroupFeaturesService,
    GroupMapSettings
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';
import { EventsSourceName } from 'projects/shared/utils/other-utils';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

export default class MapboxActions implements MapActionsInterface {
    mapState: MapState;
    globalActions: MapActionsWrapper;
    globalState: MapStateWrapper;

    private mapObject: MapObject;

    preventZooming = false; // чтобы в момент центрования не отлавливался зум

    constructor(
        state: MapStateWrapper,
        actions: MapActionsWrapper,
        private zone: NgZone,
        readonly sharedCoreFacade: SharedCoreFacade,
        private groupFeaturesService: GroupFeaturesService,
        private time: Time
    ) {
        this.mapState = state.map;
        this.globalActions = actions;
        this.globalState = state;

        this.sharedCoreFacade.actionObservables.centringOnMarker.subscribe((props) => {
            this.centringOnMarker(props.lat, props.lng, props.zooming, props.shift);
        });
    }

    public mapLoaded = (map: any) => {
        this.mapObject = new MapObject(map, this.zone);
    }

    stopLoadingOnZoom = () => {
        this.preventZooming = true;
        setTimeout(() => {
            if (this.sharedCoreFacade.getIsCityMod()) {
                this.preventZooming = false;
            } else {
                this.stopLoadingOnZoom();
            }
        }, 1000);
    };

    zoomChanges = (zoom: number) => {
        if (this.preventZooming) {
            return;
        }

        this.mapState.currentZoom = zoom;

        // не <= чтоб плавность зума сохранялась
        if (this.sharedCoreFacade.getIsCityMod() && zoom < GLOBAL_ZOOM_LEVEL - 1) {
            this.globalActions.cityModOff(false);
        }

        if (!this.sharedCoreFacade.getIsCityMod() && zoom >= GLOBAL_ZOOM_LEVEL - 1) {
            this.loadNearestLocation();
        }
    };

    private loadNearestLocation() {
        const city = findClosestCity(this.globalState.cities, this.mapObject.getCenter());

        if (city) {
            this.stopLoadingOnZoom();
            this.globalActions.loadCity(city.id, false);
        }
    }

    centerMapChanges = () => {
        const city = findClosestCity(this.globalState.cities, this.mapObject.getCenter());

        if (city && city.id !== this.sharedCoreFacade.getCurrentCityId()) {
            this.globalActions.loadCity(city.id, false);
        }
    };

    mapOnDragEnd = () => {
        if (!this.globalState.mapLoading) {
            if (this.sharedCoreFacade.getIsCityMod() || this.mapState.currentZoom >= GLOBAL_ZOOM_LEVEL - 1) {
                this.centerMapChanges();
            }
        }
    };

    private updateMarker = (marker: Marker_model) => {
        const data = findAqiInMarker(marker, this.time.getCurrent());

        if (!data) {
            return;
        }

        marker.aqi = data.aqi;

        let interval = getTimelineInterval(this.time.getBegin(), this.time.getEnd());

        if (interval < 3)
            interval = 3;

        // Override opacity and description for the CityScreen (private) markers.
        const intervalMin = DATA_INTERVAL_TYPES[interval]
        marker.opacity = 1;
        marker.description = '';
        if (data.lagMinutes > intervalMin * 2) {
            marker.aqi = 0;
        }

        marker.areaPolygon = calculateGeometryForMarker(marker);
        return marker;
    };

    markerOver = (marker: Marker_model, over: boolean) => {
        marker.over = over;
        this.updateMarker(marker);

        const { tooltip } = this.mapState;
        if (over && (marker.description || marker.type !== 'city')) {
            tooltip.text = marker.name;
            tooltip.show = true;

            if (!isFuture(this.time.getCurrent())) {
                tooltip.lastData = marker.description || '';
            }
        } else {
            tooltip.text = '';
            tooltip.lastData = '';
            tooltip.show = false;
        }
    };

    checkTooltipOver = (el: Element) => {
        if (el.nodeName.toLocaleUpperCase() !== 'IMG' && !('ownerSVGElement' in el)) {
            this.mapState.tooltip.show = false;
        }
    };

    clickToTheCity = (marker: Marker_model) => {
        this.zone.run(() => {
            // TODO: check
            this.globalActions.clickOnCityMarker(marker);
        });
    };

    clickToMarker = (marker: Marker_model) => {
        this.sharedCoreFacade.eventsSource.onEvent$.next({type: EventsSourceName.onMarkerClick$, payload: marker})
    }

    isModelVisible() {
        const isCityHaveModel = this.sharedCoreFacade.getAllowForecast()

        return (this.mapState.showContours || this.mapState.showOverlay)
            && this.mapState.showingModelMeasure
            && this.sharedCoreFacade.getIsCityMod()
            && isCityHaveModel;
    }

    isPlumeVisible() {
        return this.sharedCoreFacade.getIsShowPlume();
    }

    centringMap() {
        const city = this.sharedCoreFacade.getCurrentCity();

        this.preventZooming = true;
        setTimeout(() => this.preventZooming = false, 1000);

        const mapSettings = this.groupFeaturesService.getConfig(GroupExtConfigName.mapSettings) as GroupMapSettings;
        const minZoom = (mapSettings?.zoom ?? STND_GLOBAL_MIN_ZOOM - 1) + 1; // probably need to exclude global zoom

        const zoom = this.sharedCoreFacade.getIsCityMod() ? STND_CITY_ZOOM : minZoom;

        const newZoom = zoom - 1;

        this.mapState.currentZoom = newZoom;

        if (!isFalseNumber(city?.lat) && !isFalseNumber(city?.lng)) {
            const { lat, lng } = city;
            this.mapObject.centerTo({ lat, lng }, newZoom);
        } else {
            this.mapObject.zoomTo(newZoom);
        }
    }

    centringOnMarker = (lat: number, lng: number, zooming: boolean, shiftMap?: [number, number]) => {
        const zoom = this.sharedCoreFacade.getIsCityMod() ? STND_CITY_ZOOM : GLOBAL_ZOOM_LEVEL - 1;
        let newZoom: number;

        if (zooming && this.mapState.currentZoom < zoom) {
            newZoom = zoom - 1;
        }

        this.gotoLocation(lat, lng, zooming, newZoom, shiftMap);
    }

    gotoLocation(lat: number, lng: number, zooming: boolean, zoomTo?: number, panTo?: [number, number]) {
        if (zooming && this.mapState.currentZoom < zoomTo) {
            this.preventZooming = true;
            setTimeout(() => this.preventZooming = false, 1000);
        }

        this.mapObject.moveTo({ lat, lng }, zoomTo, panTo?.map(v => -v) as [number, number]);
    }

    updateAllMarkersIcon() {
        this.mapState.moMarkers.forEach(this.updateMarker);
        this.mapState.myMarkers.forEach(this.updateMarker);
        this.mapState.citiesMarkers.forEach(this.updateMarker);
    }
}
