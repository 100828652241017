import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CityairGlobalQrComponent } from './cityair-global-qr.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        CityairGlobalQrComponent,
    ],
    declarations: [
        CityairGlobalQrComponent,
    ],
})
export class CityairGlobalQrModule {}
