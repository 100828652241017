import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { CalendarModule } from 'src/calendar/calendar.module';

import { PopupConfirmComponent } from 'projects/cityscreen/src/components/popups/components/popup-confirm/popup-confirm.component';
import { PopupContainerComponent } from 'projects/cityscreen/src/components/popups/components/popup-container/popup-container.component';
import { DownloadPopupMoComponent } from 'projects/cityscreen/src/components/popups/components/download-popup-mo/download-popup-mo.component';
import { DownloadPopupStationComponent } from 'projects/cityscreen/src/components/popups/components/download-popup-station/download-popup-station.component';
import { ConfirmUnsavedPopupComponent } from 'projects/cityscreen/src/components/popups/components/confirm-unsaved-popup/confirm-unsaved-popup.component';
import { PopupOutletComponent } from 'projects/cityscreen/src/components/popups/components/popup-outlet/popup-outlet.component';
import { InputDropdownModule } from 'projects/cityscreen/src/components/input-dropdown/input-dropdown.module';
import { SelectboxModule } from 'projects/cityscreen/src/components/selectbox/selectbox.module';
import { SelectboxRadioModule } from 'projects/cityscreen/src/components/selectbox-radio/selectbox-radio.module';
import { InputDateRangeModule } from 'src/calendar/input-date-range/input-date-range.module';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';
import { TagsListModule } from 'projects/cityscreen/src/components/tags-list/tags-list.module';
import { SwitchItemModule } from 'projects/cityscreen/src/components/switch-item/switch-item.module';
import { CheckboxModule } from 'projects/cityscreen/src/components/checkbox/checkbox.module';

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        DirectivesModule,
        LittleComponentsModule,
        InputDropdownModule,
        SelectboxModule,
        SelectboxRadioModule,
        InputDateRangeModule,
        LoadersModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
    ],
    exports: [
        PopupOutletComponent,
        PopupConfirmComponent,
        PopupContainerComponent,
        DownloadPopupMoComponent,
        DownloadPopupStationComponent,
        ConfirmUnsavedPopupComponent,
    ],
    declarations: [
        PopupOutletComponent,
        PopupConfirmComponent,
        PopupContainerComponent,
        DownloadPopupMoComponent,
        DownloadPopupStationComponent,
        ConfirmUnsavedPopupComponent,
    ]
})
export class PopupsModule {}
