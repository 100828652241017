import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UserItems } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { ADMIN_ID, OBSERVER_ID, OPERATOR_ID } from 'src/config';

const admin = require('./img/admin.png').default;
const operator = require('./img/operator.png').default;
const observer = require('./img/observer.png').default;

@Component({
    selector: 'cityscreen-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.less']
})
export class UserCardComponent implements OnChanges {
    @Input() user: UserItems;
    @Output() logout = new EventEmitter<void>();

    icon: string;
    TEXTS = TEXTS;

    constructor() {
    }

    ngOnChanges() {
        if (this.user.roleId === ADMIN_ID)
            this.icon = admin;
        if (this.user.roleId === OPERATOR_ID)
            this.icon = operator;
        if (this.user.roleId === OBSERVER_ID)
            this.icon = observer;
    }

}
