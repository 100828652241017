<div (click)="clickForCard()" [ngClass]="{'mo_plate': true, 'mo_plate-active': isActive}">
    <div class="mo_plate__main_info">
        <div class="mo_plate__text_block">
            <div *ngIf="name"
                 [ngClass]="{
                    mo_plate__name: true,
                    'mo_plate__name-disable': !isShowKebabMenu
                 }"
                 (click)="clickForStaticTagsPost(idMo)"
                 >
                {{name ? name : "-"}}
            </div>
            <div *ngIf="city" class="mo_plate__description">{{city}}</div>
            <div *ngIf="description"
                 class="mo_plate__description mo_plate__description-city">{{description}}</div>
        </div>
        <div class="mo_plate__aqi_circle_wrapper">
            <aqi-circle [numberAqi]="getAqi(aqi)"
                        [imgPlus]="false"></aqi-circle>
        </div>
        <kebab-menu *ngIf="isShowKebabMenu" (clickActions)="openActions($event)"></kebab-menu>
    </div>
    <div class="mo_plate__separation {{isComplexMode ? 'mo_plate__separation--active' : ''}}"></div>
    <div class="mo_plate__data_sources {{isComplexMode ? 'mo_plate__data_sources--active' : ''}}">
        <div class="mo_plate__data_sources--static-tags scroll">
            <div class="mo_plate__title">
                {{TEXTS.CITY_CARD.dataSources}}
            </div>
            <div *ngIf="!isNoDataSources && !isNotConnectedDevices" class="mo_plate__tag_devices_block">
                    <static-tags
                            *ngFor="let atd of arrDeviceName; let i = index;"
                            class="mo_plate__tag_device"
                            (click)="clickForStaticTagsDevice(atd.serialNumber)"
                            [isActiveElement]="atd.sourceId == 3"
                            [text]="atd.serialNumber"
                            [description]="atd.model"
                            [isOffline]="atd.offline"
                            [typeTag]="'device'"
                    ></static-tags>
            </div>
            <div *ngIf="isNoDataSources && isNotConnectedDevices" class="mo_plate__not_devices">
                {{TEXTS.CITY_CARD.noDataSources}}
            </div>
            <div *ngIf="!isNoDataSources && isNotConnectedDevices" class="mo_plate__not_devices mo_plate__not_devices--connect">
                <static-tags
                    [description]="TEXTS.STATION_BLOCK.type.mo"
                    [typeTag]="'device'"
                ></static-tags>
            </div>
        </div>
        <div *ngIf="aqi?.aqi == 0" class="mo_plate__not_date">
            {{TEXTS.CITY_CARD.noData}}
        </div>
    </div>
</div>
