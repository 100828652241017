import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationColumnComponent } from './information-column.component';


@NgModule({
    exports: [InformationColumnComponent],
    declarations: [InformationColumnComponent],
    imports: [
        CommonModule
    ]
})
export class InformationColumnModule {
}
