import { Injectable } from '@angular/core';

import { MAP_PROVIDERS } from '../src/config';

import { City_model, WindowGlobalVars } from '../src/namespace';

import MapState from 'projects/shared/modules/map/mapState';
import AdminPanelState from 'projects/cityscreen/src/components/admin-panel/state';

declare const window: WindowGlobalVars;

@Injectable()
export default class State {
    constructor(public adminPanel: AdminPanelState) {}

    readonly map = new MapState();

    get isLogged(): boolean {
        return window.JS_CP_TOKEN && window.CLOSED_MAP;
    };

    startPopupShow = true;

    mapProvider: string = MAP_PROVIDERS[window.CURRENT_MAP_PROVIDER];

    inviteProps: {
        email: string,
        code: string
    };

    cities: City_model[] = null;
    isOpenSelectCity = false;

    allLoaded = false;
    mapLoading = true;

    errorMsg: string;

    sharingPopup = {
        show: false,
        url: '',
        private: false
    };
}
