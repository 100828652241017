import { MeasuresInfoFormat, OriginChartData_model, } from '../../namespace';
import { findMuchPointInSeries, isFalseNumber, roundValue10 } from '../../utils';

export function calcUsesMeasures(compared: {originChartData: OriginChartData_model}[]) {
    const arr: MeasuresInfoFormat[] = [];

    compared.forEach(s => {
        Object.keys(s.originChartData).forEach(key => {
            if (!arr.find(a => a.name === key))
                arr.push({
                    name: s.originChartData[key].name,
                    type: s.originChartData[key].type,
                    unit: s.originChartData[key].unit
                })
        });
    });

    return arr;
}

export function popupInfoUpdateFromChart(origin: OriginChartData_model, selectedMeasures: MeasuresInfoFormat[], currentX: number): { [measure: string]: number } {
    let data: any = origin[Object.keys(origin)[0]]; // будет искать совподение времени только по первой выделенной мере

    if (!data || !data.data)// TODO лк
        return {};

    data = data.data;

    const originalIndex = findMuchPointInSeries(data, currentX);

    return Object.keys(origin).reduce((obj, name) => {
        if (Object.keys(origin).indexOf(name) > -1) {
            const val = origin[name].data[originalIndex];
            obj[name] = val && !isFalseNumber(val[1]) ? roundValue10(val[1]) : null;
        }
        return obj;
    }, {});
}
