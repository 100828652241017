<div class="device-report">
    <div class="device-report__main-block scroll">
        <header-of-panel class="device-report__header-of-panel"
                         [path]="navigationLink"
                         (outputPath)="backToPrevPage($event)"
                         [title]=" TEXTS.POSTS_AND_DEVICES.dataDevice">
        </header-of-panel>
        <input-date-range class="device-report__input-date-range"
                          [timeBegin]="objectForReportStation.timeBegin"
                          [timeEnd]="objectForReportStation.timeEnd"
                          (timeBeginChange)="getDateBeginRangeFromCalendar($event)"
                          (timeEndChange)="getDateEndRangeFromCalendar($event)"
        ></input-date-range>

        <input-dropdown class="device-report__input-dropdown"
                        [inputText]="selectedCheckboxText(tags.length)"
                        [isDisable]="disabledDevices"
                        [label]="TEXTS.LIST_DEVICES.selectDevices"
                        [(showDropdown)]="isShowDropdownForStations"
                        (clickOutside)="isShowDropdownForStations = false"
        >
            <cs-selectbox
                    [selectAll]="isSelectAll"
                    (selectAllChange)="selectAll($event)"
                    [listItems]="listDevices"
                    (listItemsChange)="formationTagList()"
                    [searchPlaceholderText]="TEXTS.DASHBOARD.searchDevicePlaceholderText"
                    [selectAllText]="TEXTS.LIST_OM.checkAll"
            ></cs-selectbox>
        </input-dropdown>
        <tags-list
                class="device-report__tags-list"
                [tags]="tags"
                [collapseAll]="collapseAll"
                [truncateTo]="maxLength"
                [textNodesFns]="postsListLabels"
                [editable]="editable"
                (removeTag)="removeFromList($event)">
        </tags-list>
        <div class="device-report__title">{{TEXTS.POSTS_AND_DEVICES.format}}</div>
        <switch-item
                class="device-report__switch-item"
                [currentTab]="selectedListReportType"
                [tabs]="listReportType"
                (action)="showSelectedTab($event)"
        ></switch-item>
    </div>
    <div class="device-report__btn">
                <btn-cancel (click)="backToPrevPage(navigationLink[navigationLink.length - 2])">
                    {{TEXTS.COMMON.cancel}}
                </btn-cancel>
        <btn-accept
                [disabled]="tags.length == 0 || isActiveLoader"
                [isLoading]="isActiveLoader"
                (cbClick)="downloadReportStation()"
        >{{TEXTS.DOWNLOAD_POPUP.download}}</btn-accept>
    </div>
</div>