import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { CityscreenMapApi } from 'src/api/mapProvider/cityscreenMapApi';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import { DevicesApi } from 'src/api/DevicesApi/api';

import {
    deviceInfoLoad,
    deviceInfoLoaded,
    groupInfoLoad,
    groupInfoLoaded,
    groupListLoad,
    groupListLoaded,
    timelineInfoLoad,
    timelineInfoLoaded
} from './actions';

@Injectable()
export class CommonEffects {
    constructor(
        private actions$: Actions,
        private cityscreenMapApi: CityscreenMapApi,
        private adminPanelApi: AdminPanelApi,
        private devicesApi: DevicesApi,
    ) {}

    @Effect()
    loadGroupList$ = this.actions$.pipe(
        ofType(groupListLoad),
        switchMap(() => this.adminPanelApi.getGroupsList()),
        map(data => groupListLoaded({groups: data.groups, packetValueTypes: data.packetValueTypes}))
    );

    @Effect()
    loadGroupInfo$ = this.actions$.pipe(
        ofType(groupInfoLoad),
        switchMap(props => this.adminPanelApi.getGroupInfo(props.groupId)),
        map(data => groupInfoLoaded({groupInfo: data}))
    );

    @Effect()
    loadTimelineInfo$ = this.actions$.pipe(
        ofType(timelineInfoLoad),
        switchMap(props => this.cityscreenMapApi.getCSTimelineInfo(
            props.timeBegin,
            props.timeEnd,
            props.groupId,
            props.measureScheme
        )),
        map(data => timelineInfoLoaded(data))
    );

    @Effect()
    loadDeviceInfo$ = this.actions$.pipe(
        ofType(deviceInfoLoad),
        switchMap(props => this.devicesApi.getDevicesList(props.serialNumber)),
        map(data => deviceInfoLoaded(data))
    );
}
