import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TEXTS } from 'src/texts/texts';
import { OffPanelPopupService } from '../admin-panel/off-panel-popup.service';
import * as moment from 'moment';
import { saveDataToDisk } from 'src/utils';
import { ErrorTransformer_model } from 'src/api/dataProvider/DataProvider';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import {
    DownloadPopupData,
    ModelDownloadPopup,
    MoItemsDataToExcelRequestProps,
    StationDataToExcelRequestProps
} from 'src/namespace';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { CoreFacade } from '../../modules/core/core-facade';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

@Component({
    selector: 'download-popups-wrapper',
    templateUrl: './download-popups-wrapper.component.html',
})
export class DownloadPopupsWrapperComponent {
    @ViewChild('popupDownload', {static: true}) popupDownload: TemplateRef<HTMLDivElement>;

    downloadType: number;
    title: string;
    ids: number[];
    mos: {id: number, name: string}[];
    devices: {id: number, serialNumber: string}[];
    currentTabInterval?: number;

    TEXTS = TEXTS;

    isSaveBtnDisabled = false;
    isActiveLoader = false;

    objectForReportMo: MoItemsDataToExcelRequestProps;
    objectForReportStation: StationDataToExcelRequestProps;
    downloadPopupObj: ModelDownloadPopup;

    errorDownloadMsg: string;


    constructor(
        private popupProvider: OffPanelPopupService,
        public adminPanelApi: AdminPanelApi,
        private coreFacade: CoreFacade,
        private sharedCoreFacade: SharedCoreFacade,
        private time: Time
    ) {
        coreFacade.openDownloadPopup$.asObservable().subscribe(data => {
            this.showDownloadPopup(data);
        })
    }

    closeDownloadPopup = () => {
        this.popupProvider.clear();
    };

    getSaveBtnDisabled = (event: boolean) => {
        this.isSaveBtnDisabled = event;
    };

    getObjectForReportMO = (event) => {
        this.objectForReportMo = event;
    }

    getObjectForReportStation = (event) => {
        this.objectForReportStation = event;
    }

    showDownloadPopup = (data: DownloadPopupData) => {
        this.isSaveBtnDisabled = false;
        this.downloadType = data.type;
        this.title = data.title;
        this.ids = data.ids;
        this.devices = data.devices;
        this.mos = data.mos;
        this.currentTabInterval = data.currentTabInterval;

        this.errorDownloadMsg = '';
        this.downloadPopupObj = {
            timeBegin: this.time.getBegin(),
            timeEnd: this.time.getEnd(),
            downloadType: data.type,
            downloadTitle: data.title
        };

        if (data.type != 3) {
            this.objectForReportMo = {
                timeBegin: this.time.getBegin(),
                timeEnd: this.time.getEnd(),
                moIds: this.ids,
                interval: 2,
                type: data.type,
                unitsType: 1,
                insertPdk: false
            }
        } else {
            this.isSaveBtnDisabled = true;
        }

        this.popupProvider.setTemplate(this.popupDownload);
    };

    downloadReportMo = () => {
        this.isActiveLoader = true;

        const filename = this.downloadPopupObj.downloadTitle + moment().format('_YYYYMMDD_HHmm') + '.xlsx';
        this.adminPanelApi.SaveMoDataToExcel(this.objectForReportMo)
            .then((data: string) => {
                saveDataToDisk(data, filename);
                this.closeDownloadPopup();
                this.isActiveLoader = false;
            })
            .catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.isActiveLoader = false;
            })
    };

    downloadReportStation = () => {
        const fileExtension = this.objectForReportStation.type == 1 ? '.xlsx' : '.csv';
        const filename = TEXTS.DOWNLOAD_POPUP.filenameDataDevice + moment().format('_YYYYMMDD_HHmm') + fileExtension;
        this.isActiveLoader = true;
        this.adminPanelApi.SaveDeviceDataToExcel(this.objectForReportStation).then((data: string) => {
            saveDataToDisk(data, filename);
            this.closeDownloadPopup();
            this.isActiveLoader = false;
        }).catch((errorDownloadMsg: ErrorTransformer_model) => {
            this.errorDownloadMsg = errorDownloadMsg.message;
            this.isActiveLoader = false;
        })
    };
}
