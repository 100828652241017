<div class="page-content__wrapper">
    <div class="admin_panel__fixed_block"
        *ngIf="!cityCard.city"
    >
        <div class="admin_panel__title">
            <header-page [textObject]="{titlePage: textsAdminPanel.cityCard}"></header-page>
        </div>
        <div class="search_list_wrapper">
            <search-input
                [textPlaceholder]="textsAnalytics.searchPlaceholder"
                [(text)]="searchQuery"
            ></search-input>
        </div>
    </div>

    <div class="page-content">
        <div class="city_title__wrapper" *ngIf="cityCard.city">
            <header-page
                [cbBack]="cityCard.city ? apActions.backToCityChange : null"
                [textObject]="{
                    titlePage: cityCard.city.name,
                    btnBack: textsCityCard.backBtnCityScreen
                }"
            ></header-page>
        </div>

        <div *ngIf="!cityCard.city" class="admin_panel__relative_block-listPosts">
            <station-city-list
                [markersData]="globalState.map.citiesMarkers"
                [cities]="globalState.cities"
                [compareMod]="sharedCoreFacade.getIsCompareMod()"
                [comparedStations]="sharedCoreFacade.getComparedList()"
                [cbClickForName]="apActions.clickFromApToCity"
                [filter]="searchQuery"
                [sortListStation]="sortListStation"
            ></station-city-list>
        </div>

        <div class="admin_panel__relative_block-cityCard"
            *ngIf="cityCard.city"
        >
            <city-info
                [cityCard]="cityCard"
                [comparedStations]="sharedCoreFacade.getComparedList()"
                [markersData]="globalState.map.commonMarkers"
                [isComparing]="sharedCoreFacade.getIsCompareMod()"
                [isCityMode]="sharedCoreFacade.getIsCityMod()"
            ></city-info>
        </div>
    </div>
</div>
