<ng-template #popupOutlet>
    <stnd-ask-popup-three-questions
        *ngIf="showConfirmationPopup"
        [texts]="texts.POPUP_THREE_QUESTIONS"
        [close]="onClosePopup"
        [extraAction]="openCheckpoint"
    ></stnd-ask-popup-three-questions>
</ng-template>

<div class="edit_control_point">
    <div class="edit_control_point__title_wrapper">
        <header-page [textObject]="{titlePage: title}"></header-page>
    </div>
    <div class="edit_control_point__body">
        <form (ngSubmit)="onSubmit()" [formGroup]="editControlPoint" novalidate>
            <div class="edit_control_point__wrapper_input_name">
                <ca-input
                    type="text"
                    controlName="name"
                    [form]="editControlPoint"
                    [valueForm]="name.value"
                    [label]="texts.PLUMES.title"
                    [textError]="errorObj.name"
                ></ca-input>
            </div>
            <div class="edit_control_point__subtitle">{{texts.EDIT_STATION.coordinates}}</div>
            <div class="edit_control_point__input_coordinates">
                <div class="edit_control_point__wrapper_coordinate">
                    <ca-input
                        type="number"
                        controlName="lat"
                        [form]="editControlPoint"
                        [valueForm]="lat.value"
                        [label]="texts.PLUMES.latitude"
                        [textError]="errorObj.lat"
                    ></ca-input>
                </div>
                <div class="edit_control_point__wrapper_coordinate">
                    <ca-input
                        type="number"
                        controlName="lon"
                        [form]="editControlPoint"
                        [valueForm]="lon.value"
                        [label]="texts.PLUMES.longitude"
                        [textError]="errorObj.lon"
                    ></ca-input>
                </div>
            </div>
        </form>
    </div>
    <div class="edit_control_point__action_btn_wrapper">
        <ca-button type="cancel" (clickAction)="openCheckpoint()">
            {{texts.EDIT_STATION.cancel}}
        </ca-button>
        <ca-button type="primary" (clickAction)="save()">
            <ca-spinner *ngIf="isLoading; else showContentSave"></ca-spinner>
            <ng-template #showContentSave>{{texts.EDIT_STATION.save}}</ng-template>
        </ca-button>
    </div>
</div>
