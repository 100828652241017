import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'buttons-save-cancel',
    templateUrl: 'buttons-save-cancel.html',
    styleUrls: ['buttons-save-cancel.less']
})
export class ButtonsSaveCancel {
    @Input() saving: boolean;
    @Input() disabled?: boolean;

    @Input() textCancel?: string;
    @Input() textSave?: string;

    @Output() save = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    _save() {
        this.save.emit();
    }

    _cancel() {
        this.cancel.emit();
    }

    TEXTS = TEXTS;
}
