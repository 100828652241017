import { RESIZE_TIMEOUT } from 'src/config';
import { WindowGlobalVars } from 'src/namespace';
import { environment } from 'projects/cityscreen/src/environments/environment';

declare let window: WindowGlobalVars;

function calcFullHeight() {
    document.documentElement.style.setProperty('--full-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--height-mobile-chart-measures', `${window.innerHeight - 111}px`);

    if (environment.is_mobile_app && window.innerHeight > window.innerWidth)
        calcMobileAppSafePadding();
    else
        calcMobileEmptyPadding();
}

function calcMobileAppSafePadding() {
    document.documentElement.style.setProperty('--mobile-app-padding-top', `35px`);
}

function calcMobileEmptyPadding() {
    document.documentElement.style.setProperty('--mobile-app-padding-top', `0px`); // need for correct css calc
}

export function createCssVars() {
    calcFullHeight();
    setTimeout(calcFullHeight, RESIZE_TIMEOUT * 2);

    (window.visualViewport || window).addEventListener('resize', calcFullHeight);

    window.addEventListener('orientationchange', calcFullHeight);
}
