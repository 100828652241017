<div class="station_city_list__wrapper">
    <div class="station_city_list__sort_list_wrapper">
        <sort-list
            [properties]="sortListStation"
            [cbSortFun]="sortingOfStation"
            [sortingProps]="sortingStation"
            [sortingDirection]="sortingDirection"
        ></sort-list>
    </div>
    <div class="station_city_list__body scroll">
        <div [ngClass]="{
                'station_city_list station_city_list__container': true,
                'station_city_list__container-big': showCity
            }"
            *ngFor="let marker of markersData | multipleSearchFns : searchFilters : filter | sorting : sortingStation : sortingDirection"
            (click)="cbClickForName(marker)"
        >
            <div class="station_city_list__block">
                <div class="station_city_list__title">{{marker.name}}</div>
                <div class="station_city_list__sub_description">{{getText(marker)}}</div>
                <div *ngIf="showCity" class="station_city_list__description">{{marker.city != null ? marker.city.name : ''}}</div>
            </div>
            <div class="station_city_list__block station_city_list__block-flex">
                <div class="air_aqi__num {{getClassName(marker)}}"></div>
                <div class="station_city_list__aqi_description" [innerHTML]="declOfNum(marker.aqi) | safeHtml"></div>
            </div>
        </div>
    </div>
</div>
