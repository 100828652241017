import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemRadioComponent } from './item-radio.component';

@NgModule({
    exports: [ItemRadioComponent],
    declarations: [ItemRadioComponent ],
    imports: [
        CommonModule,
    ]
})
export class ItemRadioModule {
}
