import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ControlPoint } from 'projects/cityscreen/src/modules/plumes/services/models/control-point-model';
import { TEXTS } from 'src/texts/texts';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { isFalseNumber } from 'src/utils';

@Component({
    selector: 'plumes-control-points-card',
    templateUrl: 'plumes-control-points-card.component.html',
    styleUrls: ['plumes-control-points-card.component.less']
})
export class PlumesControlPointsCard {
    @Input() currentPointValues: number;
    @Input() measureUnit: string;
    @Input() point: ControlPoint;
    @Input() activePointId: number;

    @Output() openActionsPopup = new EventEmitter<{target: HTMLElement, positionTop: number}>();

    popupActive = false;
    popupPositionTop = 0;
    isDeletePopupOpen: boolean;
    texts = TEXTS;
    popupDeleteTexts = Object.assign({}, TEXTS.PLUMES.popupDelete);

    isFalseNumber = isFalseNumber;
    roundValue = (val: number) => Math.round(val);

    @ViewChild('popupOpener', { static: true }) popupOpener: ElementRef<HTMLDivElement>;

    constructor(public popupProvider: OffPanelPopupService) {}

    openActions() {
        const { nativeElement } = this.popupOpener;
        const positionTop = nativeElement.getBoundingClientRect().top - nativeElement.offsetTop;

        this.openActionsPopup.emit({
            target: nativeElement,
            positionTop
        });
    }
}
