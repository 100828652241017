<div class="plumes_calc_card"
    [class.plumes_calc_card-active]="isActive"
>
    <div class="plumes_calc_card__is_now"
        *ngIf="isActive"
    >
        {{TEXTS.PLUMES.nowOnMap}}
    </div>
    <ng-content></ng-content>
</div>
