import { Component, EventEmitter, Output, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import Actions from '../../../../../../map/actions'
import State from '../../../../../../map/state'
import { TEXTS } from '../../../../../../src/texts/texts'

import './coefficientView.less';
import { MAX_PCF, MIN_PCF } from '../../../../../../src/config';
import { OffPanelPopupService } from '../../admin-panel/off-panel-popup.service';
import { ConfigPage } from '../config.types';
import AdminPanelActions from '../../admin-panel/actions';

@Component({
    selector: 'coefficient-view',
    templateUrl: 'coefficientView.html'
})
export class CoefficientComponent implements OnInit, OnDestroy {
    @ViewChild('coeffPopup', { static: true }) coeffPopup: TemplateRef<HTMLDivElement>;
    @Output() selectPage = new EventEmitter<ConfigPage>();

    constructor(
        private actions: Actions,
        private state: State,
        private popupProvider: OffPanelPopupService,
        public apActions: AdminPanelActions
    ) {}

    apState = this.state.adminPanel;

    TEXTS = TEXTS;

    min = MIN_PCF;
    max = MAX_PCF;
    showPopup = false;
    newCoef: number = null;

    clearCoef = () => {
        this.newCoef = null;
    };

    isVrongCoeff = (): boolean => {
        if (this.newCoef === null) {
            return false;
        }

        if (typeof this.newCoef !== 'number') {
            return true;
        }

        if (this.newCoef < MIN_PCF || this.newCoef > MAX_PCF) {
            return true;
        }

        return false;
    }

    ngOnInit() {
        this.popupProvider.setTemplate(this.coeffPopup);
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    back = () => this.selectPage.emit(ConfigPage.HOME);
}
