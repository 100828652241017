import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgLocalization } from '@angular/common';

import type { CheckboxItem, ModelDownloadPopup, StationDataToExcelRequestProps } from 'src/namespace';
import { LANGUAGE, TEXTS } from 'src/texts/texts';

@Component({
    selector: 'download-popup-station',
    templateUrl: 'download-popup-station.component.html',
    styleUrls: ['download-popup-station.component.less']
})
export class DownloadPopupStationComponent implements OnInit{
    @Input() selectedId: number;
    @Input() downloadPopupObj: ModelDownloadPopup;
    @Input() myDevices: {id: number, serialNumber: string}[] = [];
    @Input() errorDownloadMsg = '';

    @Output() getSaveBtnDisabled = new EventEmitter<boolean>();
    @Output() objectForReportStation = new EventEmitter<StationDataToExcelRequestProps>();

    texts = TEXTS;
    objDropdownListStation: CheckboxItem[] = [];
    maxInlineStation = 3;
    currentStation: string[] = [];
    modelType = null;
    objectForDownload: StationDataToExcelRequestProps = {
        timeBegin: null,
        timeEnd: null,
        ids: [],
        type: null
    };

    listReportType: CheckboxItem[] = [
        {
            id: 1,
            label: 'Excel',
            selected: false
        },
        {
            id: 2,
            label: 'CSV',
            selected: false
        }
    ];

    constructor(private ngLocalization: NgLocalization) {}

    ngOnInit() {
        this.createObjDropdownList();
    }

    createObjDropdownList() {
        this.currentStation = [];
        this.myDevices.forEach(md => {
            const isChecked = this.selectedId === md.id;

            if (isChecked) {
                this.currentStation.push(md.serialNumber);
            }

            this.objDropdownListStation.push({
                id: md.id,
                label: md.serialNumber,
                selected: isChecked
            });
        });

        this.objectForDownload.ids.push(this.selectedId);
        this.objectForDownload.timeBegin = this.downloadPopupObj.timeBegin;
        this.objectForDownload.timeEnd = this.downloadPopupObj.timeEnd;
    }

    getObjectForReport = (objForFilter: CheckboxItem[]) => {
        this.currentStation = [];
        const arrMoOd: number[] = [];

        this.myDevices.forEach(md => {
            objForFilter.forEach(off => {
                if (off.selected && md.id === off.id) {
                    arrMoOd.push(md.id);
                    this.currentStation.push(md.serialNumber);
                }
            });
        });

        this.objectForDownload = {
            timeBegin: this.downloadPopupObj.timeBegin,
            timeEnd: this.downloadPopupObj.timeEnd,
            ids: arrMoOd,
            type: this.modelType
        };

        this.getSaveBtnDisabled.emit(arrMoOd.length === 0 && this.modelType === 1);
        this.objectForReportStation.emit(this.objectForDownload);
    };

    getTypeReport = (items: CheckboxItem[]) => {
        items.forEach(item => {
            if (item.selected) {
                this.modelType = item.id;
                this.selectedFiletype = item.label;
            }
        });

        if (this.modelType === 2) {
            this.getSaveBtnDisabled.emit(false);
            this.objectForDownload.type = this.modelType;
            this.objectForReportStation.emit(this.objectForDownload);
        } else {
            this.getSaveBtnDisabled.emit(true);
        }

        this.getObjectForReport(this.objDropdownListStation);
        this.getSaveBtnDisabled.emit(!this.currentStation.length && !this.modelType);
    };

    removePostFromList(index: number) {
        const idStation = this.currentStation[index];
        this.currentStation = this.currentStation.filter((_, i) => i !== index);

        this.objDropdownListStation = this.objDropdownListStation.map(off => {
            if (off.label === idStation) {
                off.selected = false;
            }
            return off;
        });

        this.getObjectForReport(this.objDropdownListStation);
        this.postsListLabels();
    }

    postsListLabels() {
        return this.getPostsListLabels(true, this.ngLocalization);
    }

    getPostsListLabels(showMore?: boolean, ngLocalization?: NgLocalization) {
        const { allStation, collapse } = TEXTS.NOTIFICATIONS;
        const empty = () => '...';
        return {
            all: () => allStation,
            expand: showMore && ngLocalization ? this.showMoreText.bind(null, ngLocalization) : empty,
            collapse: () => collapse
        };
    }

    showMoreText(ngLocalization: NgLocalization, num: number = 0) {
        const { showMore, station } = TEXTS.NOTIFICATIONS;
        const category = ngLocalization.getPluralCategory(num, LANGUAGE);
        const words = {
            ru: [showMore, num]
        }[LANGUAGE] || [num, showMore];

        words.push(station[category]);
        return words.join(' ');
    }

    devicesDropdownOpened = false;
    filetypeDropdownOpened = false;
    selectedFiletype = '';

    selectedCheckboxText(num: number = 0) {
        const { selectedStation, station } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [
            selectedStation[category],
            num,
            station[category]
        ].join(' ');
    }
}
