import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataProvider3 } from '../dataProvider/DataProvider';
// виталино--------------------------------------------------------------------------------------------------------------------------------
import { ErrorResult, PacketsValueTypeItem } from '../../../harvester/UiAdminProject8/src/commonData/models';
import { DataApiDevicesResponse, } from '../../../harvester/UiAdminProject8/src/commonData/providers/devicesApiProvider/devicesApiModels';
import { DevicesApiProvider } from '../../../harvester/UiAdminProject8/src/commonData/providers/devicesApiProvider/devicesApiProvider';
import { MoApiProvider } from '../../../harvester/UiAdminProject8/src/commonData/providers/moApiProvider/moApiProvider';
// виталино--------------------------------------------------------------------------------------------------------------------------------
import { copyObj } from '../../utils';
import { WindowGlobalVars } from '../../namespace';

import { devicesDataTransformer, DevicesDataTransformer_model, } from './dataTransformer';
import { ApiModels } from '../api-models';

declare const window: WindowGlobalVars;

@Injectable()
export class DevicesApi extends ApiModels {
    private provider: DevicesApiProvider;
    private moProvider: MoApiProvider;

    private _token: string;
    private _devicesResponse: DataApiDevicesResponse; // для хранения сырых данных
    private _packetsValueTypes: PacketsValueTypeItem[]; // для хранения id мер

    constructor(private http: HttpClient) {
        super();
        this.provider = new DevicesApiProvider(new DataProvider3(this.http));
        this.moProvider = new MoApiProvider(new DataProvider3(this.http));
    }

    public saveToken = (token: string) => {
        this._token = token;
        window.JS_CP_TOKEN = token;
    };

    private token = () => ({
        Token: this._token,
        User: undefined,
        Pwd: undefined,
    });

    public savePacketsValueTypes = (packetsValueTypes: PacketsValueTypeItem[]) => {
        this._packetsValueTypes = copyObj(packetsValueTypes);
    };
    public saveDevicesResponse = (devicesResponse: DataApiDevicesResponse) => {
        this._devicesResponse = copyObj(devicesResponse);
    };

    getDevicesList(SerialNumber: string = ''): Promise<DevicesDataTransformer_model> {
        return new Promise<DevicesDataTransformer_model>((resolve, reject) => {
            this.provider.getDevices(
                {
                    ...this.token(),
                    Filter: {
                        SerialNumber
                    }
                },
                (data: DataApiDevicesResponse) => {
                    this.saveDevicesResponse(data);
                    resolve( devicesDataTransformer(data) );
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        })
    }
}
