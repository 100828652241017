import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringObjectPlateComponent } from './monitoring-object-plate.component';
import { AqiCircleModule } from 'projects/shared/components/aqi-circle/aqi-circle.module';
import { KebabMenuModule } from 'projects/shared/components/kebab-menu/kebab-menu.module';
import { StaticTagsModule } from '../static-tags/static-tags.module';


@NgModule({
    exports: [MonitoringObjectPlateComponent],
    declarations: [MonitoringObjectPlateComponent],
    imports: [
        CommonModule,
        AqiCircleModule,
        KebabMenuModule,
        StaticTagsModule,
    ]
})
export class MonitoringObjectPlateModule {
}
