import { HttpClient } from '@angular/common/http';
import {
    CityScreenTimelineRequest,
    CityScreenTimelineResponse,
    GetMoPacketsApiRequest,
    MoPacketsRequest
} from './cityairMapModels';
import { measureScheme, StationData, WindowGlobalVars } from '../../namespace';
import { CheckLongPending, MapApiProvider } from './MapApiProvider';
import { Injectable } from '@angular/core';
import { CityairMapDataTransformerResponse, objectMonitorDataTransformer } from './cityairMapDataTransformerResponse';
import { csMapDataTransformer } from './cityscreenDataTransformer';
import { isTimelineAqiLowDetail } from '../../utils';
import * as moment from 'moment';
import { ErrorResult } from '../../../harvester/UiAdminProject8/src/commonData/models';
import { GetObserverPacketsResponce } from '../adminPanel/models';

declare const window: WindowGlobalVars;

@Injectable()
export class CityscreenMapApi extends MapApiProvider {
    constructor(http: HttpClient) {
        super(http);
    }

    getMoPackets = (prop: GetMoPacketsApiRequest, MeasureScheme?: measureScheme): Promise<StationData> => {
        return new Promise((resolve, reject) => {
            this.cityairMapProvider.GetMapPacketsCityScreen(
                <MoPacketsRequest>{
                    MoId: prop.id,
                    TimeBegin: prop.timeBegin ? moment(prop.timeBegin) : undefined,
                    TimeEnd: prop.timeEnd ? moment(prop.timeEnd) : undefined,
                    IntervalType: prop.interval,
                    LastTimestamp: prop.lastPacketId || null,
                    MeasureScheme
                },
                (data: GetObserverPacketsResponce) => {
                    resolve(objectMonitorDataTransformer(data, this._packetsValueTypes, prop.calcAqi));
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };


    getCSTimelineInfo = (timeBegin: number, timeEnd: number, GroupId: number, MeasureScheme?: measureScheme): Promise<CityairMapDataTransformerResponse> => {
        const checkLongPending = new CheckLongPending();

        return new Promise((resolve, reject) => {
            this.cityairMapProvider.GetCityScreenTimeline(<CityScreenTimelineRequest>{
                    TimeInterval: isTimelineAqiLowDetail(timeBegin, timeEnd) ? 2 : 1,
                    // TODO убрать subtract когда сервак будет правильно время отдавать
                    TimeBegin: moment(timeBegin).subtract(1, 'hour'),
                    TimeEnd: moment(timeEnd).startOf('hour'),
                    GroupId,
                    MeasureScheme
                }, (data: CityScreenTimelineResponse) => {
                    this.saveMoItems(data.MoTimelines);
                    this.savePacketsValueTypes(data.PacketValueTypes);

                    checkLongPending.end();
                    resolve(csMapDataTransformer(data, timeBegin, timeEnd));
                },
                (errorResult: ErrorResult) => {
                    checkLongPending.end();
                    reject(this.errorHandler(errorResult))
                });
        });
    };
}
