import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './modules/core/services/route-guards/auth.guard';
import { loginRoutes } from './modules/login/login-routing.module';
import { CityScreenPage } from './cityScreenPage'
import { ClientLoginPageComponent } from './pages/client-login-page/client-login-page.component';
import { adminPanelRoutes } from 'projects/cityscreen/src/components/admin-panel/admin-panel.routing.module';

export const cityscreenRoutes: Routes = [
    {
        path: '',
        component: CityScreenPage,
        children: adminPanelRoutes,
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: ClientLoginPageComponent,
        children: loginRoutes,
        canActivate: [AuthGuard],
    },
    // {
    //     path: PAGES.mobile,
    //     component: MobileContainerComponent,
    //     canActivate: [MobileGuard],
    // },
    {
        path: '**',
        redirectTo: ''
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(cityscreenRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class CityscreenRoutingModule {}
