import { Component, Input } from '@angular/core';

import { Source } from 'projects/cityscreen/src/modules/plumes/services/models/run-model';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'plumes-source',
    templateUrl: 'plumes-source.component.html',
    styleUrls: ['plumes-source.component.less']
})
export class PlumesSource {
    @Input() source: Source;
    @Input() valueTypes: string[];
    @Input() isActive = true;

    TEXTS = TEXTS;
}
