import { Injectable } from '@angular/core';

import { AdminDevice, CreateOM, MonitoringObject, namePages, UserItems } from 'src/namespace';

import { Group } from 'src/api/adminPanel/models';

import {
    CurrentRegionalCoefs,
    GetUserPermissionModel,
    GroupInfo,
    NotificationSubscription,
    Permissions,
    Roles
} from 'src/api/adminPanel/dataTransformer';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from '../../modules/core/store/selectors';
import { copyObj } from '../../../../../src/utils';

@Injectable({
    providedIn: 'root'
})
export default class AdminPanelState {
    constructor(private store: Store) {
        this.defaultState();
        this.store.select(selectGroupInfo).subscribe(data => Object.assign(this, data))
    }

    monitoringObjects: MonitoringObject[] = [];
    myDevices: AdminDevice[] = [];
    allDevices: AdminDevice[] = [];
    users: UserItems[] = null;
    usersWithoutAdmin: UserItems[] = null;
    iAm: UserItems = null;
    roles: Roles = null;
    myRole: Permissions = null;
    regionalCoefs: CurrentRegionalCoefs;

    userToDelete: number;
    selectedDevices: AdminDevice[] = [];
    menuEditMo = false;

    pagesArr: namePages[] = [];

    get namePageOpen(): namePages {
        return this.pagesArr[this.pagesArr.length - 1];
    }

    set namePageOpen(name: namePages) {
        if (name) {
            this.pagesArr.push(name);
        }
    }

    invalidGeo = false;
    editSaveError: string;

    saving = false;
    error = '';
    success = false;

    tokens: GetUserPermissionModel;

    private groups: Group[] = [];
    private currentGroup: Group;

    setGroupsList(groups: Group[]) {
        this.groups = groups || [];
    }

    getGroupsList() {
        return this.groups;
    }

    setCurrentGroup(currentGroup: Group) {
        this.currentGroup = currentGroup;
    }
    setFirstGroup() {
        this.currentGroup = this.groups[0];
    }
    getCurrentGroup() {
        return this.currentGroup;
    }

    defaultState() {
        this.monitoringObjects = [];
        this.myDevices = [];
        this.allDevices = [];
        this.users = [];
        this.myRole = null;
    }
}
