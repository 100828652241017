import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-sort-list-item',
  template: `
    <div class="{{sortingDirection == 0 ? 'sort_list_item sort_list_item-inactive' : 'sort_list_item' }}">
      <div class="sort_list_item__text" [innerHTML]="text"></div>
      <div [ngClass]="{
          'sort_list_item__img': true,
          'sort_list_item__img-default': sortingDirection == 0,
          'sort_list_item__img-sorting_up': sortingDirection > 0,
          'sort_list_item__img-sorting_down': sortingDirection < 0
        }"
      ></div>
    </div>
  `,
  styleUrls: ['./sort-list-item.component.less']
})
export class SortListItemComponent {
    @Input() sortingDirection = 0
    @Input() text = ''
}
