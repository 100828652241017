<div class="plumes-calculation">
    <div class="plumes-calculation__interval">
        <div class="plumes-calculation__interval__title">
            {{TEXTS.PLUMES.interval}}
        </div>
        <div class="plumes-calculation__interval__units">
            {{valText}}
        </div>
    </div>
    <div class="plumes-calculation__substances">
        <ca-substances-list [items]="valueTypes"></ca-substances-list>
    </div>
</div>
