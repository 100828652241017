import { Component, AfterContentInit } from '@angular/core';

import { CHART_TYPE_FULL_WIDTH_M, CHART_TYPE_TIMELINE_M, TimeEventFrom } from 'src/namespace';
import { SwipeElement } from 'src/utils';

import '../../style.less';
import { Timeline } from '../timeline/timeline.component';
import { dragFlag } from '../../dragFlag';
import { formatterFlagTimeHour, formatterFlagTimeDateWithYear } from 'src/config';

@Component({
    selector: 'time-line-mobile',
    template: `
        <div class="timeline__wrapper">
            <div id="timeline_drag" [ngClass]="{
                        timeline__top: true,
                        'timeline__top-m': true,
                        'timeline__top-minimize': timelineState.mobileMinimize || sharedCoreFacade.isComparedListEmpty(),
                        'timeline__top-hide_m': sharedCoreFacade.isComparedListEmpty()
                    }">
                <div id="timeline_touch" class="timeline__drag">
                    <div [ngClass]="{
                        timeline__drag_bar: true,
                        hide_show: true,
                        'hide_show-show': sharedCoreFacade.isComparedListNotEmpty(),
                        'timeline__drag_bar-top': timelineState.mobileMinimize
                     }"></div>
                </div>

                <div [ngClass]="{
                hide_show: true,
                'hide_show-show': !timelineState.mobileMinimize && sharedCoreFacade.isComparedListNotEmpty()
            }">
<!--                    <div class="timeline__compare_btn button_link button_link-blue"-->
<!--                         (click)="timelineActions.toggleCompareMod()">-->
<!--                        {{sharedCoreFacade.getIsCompareMod() ? TEXTS.STATION_BLOCK.endCompare : TEXTS.STATION_BLOCK.compare}}-->
<!--                    </div>-->
                    <div class="timeline__close-m" (click)="timelineActions.onCloseTimeline()"></div>
                </div>

                <div class="timeline__mobile_scrolling">
                    <!-- stationMainBlock -->
                    <div class="timeline__stations_wrapper timeline__stations_wrapper-m">
                        <div class="timeline__center">
                            <timeline-selected-item-mobile
                                    [items]="sharedCoreFacade.getComparedList()"
                                    (titleOnClick)="timelineActions.clickForTitleTimeline($event)"
                            ></timeline-selected-item-mobile>
                            <div *ngIf="sharedCoreFacade.getMeasuresList().length; else emptyChart" class="timeline__measures-m">
                                <select-measures-m class="timeline__header-gray"
                                                   *ngIf="sharedCoreFacade.isComparedListNotEmpty()"
                                                   [className]="'timeline__measure_block timeline__measure_block-m'"
                                                   [measuresSelected]="sharedCoreFacade.getMeasuresSelected()"
                                                   [measuresList]="sharedCoreFacade.getMeasuresList()"
                                                   [select]="select"
                                ></select-measures-m>
                                <chart-compare-measure-indicator-new-m class="timeline__tr timeline__tr-m"
                                                                       *ngFor="let station of sharedCoreFacade.getComparedList(); let i = index;"
                                                                       [className]="'timeline__measure_block timeline__measure_block-m'"
                                                                       [station]="station"
                                                                       [measuresList]="sharedCoreFacade.getMeasuresList()"
                                                                       [color]="CHART_COLORS[i]"
                                                                       [select]="select"
                                ></chart-compare-measure-indicator-new-m>
                            </div>
                            <ng-template #emptyChart>
                                <div class="timeline__station_no_data">{{TEXTS.CITY_CARD.noData}}</div>
                            </ng-template>
                        </div>
                    </div>
                    <!-- /stationMainBlock -->

                    <loader [show]="sharedCoreFacade.getMainChartIsLoading()"
                            [className]="'loader-spinner-m loader-spinner-chart'"></loader>

                    <div class="timeline__chart timeline__chart-m" [ngStyle]="{visibility: sharedCoreFacade.getMeasuresList().length ? 'visible' : 'hidden'}">
                        <div [ngClass]="{
                            'anim_showing': true,
                            'anim_showing-show': !sharedCoreFacade.getMainChartIsLoading()
                        }"
                        >
                            <stnd-chart [type]="CHART_TYPE_FULL_WIDTH_M"
                                        class="chart_mobile_margin_left"
                                        [onClick]="clickOnChart"
                                        [onCreated]="onCreated"
                                        [onHover]="hover"
                                        [onZoomed]="timelineActions.onChartZoom"
                            ></stnd-chart>
                            <div *ngIf="timelineState.isZooming" class="chart-reset-zoom"
                                 (click)="timelineActions.resetChartZoom()">{{TEXTS.STATION_BLOCK.returnScale}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="timeline timeline-m">
                <!-- flag -->
                <div id="flag_drag"
                     [ngClass]="{
                    'timeline_flag': true,
                    'timeline_flag-m': true,
                    'timeline_flag-have_data': !!time.getCurrent(),
                    'timeline_flag-tall': !timelineState.mobileMinimize && sharedCoreFacade.isComparedListNotEmpty(),
                    'timeline_flag-zoom': timelineState.isZooming
                }"
                >
                    <div class="timeline_flag__wrapper">
                        <div id="flag_width" class="timeline_flag__text timeline_flag__text-m no_highlight_touch">
                            <b>{{formatterFlagTimeHour(time.getCurrent(), sharedCoreFacade.getTzOffset())}}</b>
                            {{formatterFlagTimeDateWithYear(time.getCurrent(), sharedCoreFacade.getTzOffset())}}
                        </div>
                        <div class="timeline_flag__to_right timeline_flag__to_right-m no_highlight_touch"
                            (click)="timelineActions.goToEndTime()"
                        ></div>
                    </div>
                    <div [ngClass]="{
                        'timeline_flag__line': true,
                        'timeline_flag__line-m': true,
                        'timeline_flag__line-tall': !timelineState.mobileMinimize && sharedCoreFacade.isComparedListNotEmpty(),
                        'timeline_flag__line-zoom': timelineState.isZooming
                    }"
                    ></div>
                </div>
                <!-- /flag -->

                <play-button
                    *ngIf="!config?.disablePlay"
                    class="timeline__play_button-m no_highlight_touch"
                    [state]="sharedCoreFacade.getIsPlaying() ? 'pause' : 'play'"
                    [loading]="sharedCoreFacade.getForecastIsLoading()"
                    [loadingProgress]="sharedCoreFacade.getPercentLoadForecast()/100"
                    (click)="timelineActions.togglePlay()"
                >
                </play-button>

                <stnd-chart #timelineChart
                            class="chart_mobile_margin_left"
                            [type]="CHART_TYPE_TIMELINE_M"
                            [onClick]="timelineClick"
                            [onCreated]="onCreatedTimeline"
                            [onHover]="hover">
                </stnd-chart>
            </div>

        </div>
    `,
    providers: []
})
export class TimelineMobile extends Timeline implements AfterContentInit {
    CHART_TYPE_TIMELINE_M = CHART_TYPE_TIMELINE_M;
    CHART_TYPE_FULL_WIDTH_M = CHART_TYPE_FULL_WIDTH_M;

    formatterFlagTimeHour = formatterFlagTimeHour;
    formatterFlagTimeDateWithYear = formatterFlagTimeDateWithYear;


    onCreated =  chartControl => this.timelineActions.chartObjCreated(chartControl, this.cityair, CHART_TYPE_FULL_WIDTH_M);
    onCreatedTimeline = chartControl => this.timelineActions.chartObjCreated(chartControl, this.timeline, CHART_TYPE_TIMELINE_M);

    ngAfterContentInit() {
        this.timelineChart.element.nativeElement.onclick = () =>
            this.time.timeUpdate.next({
                time: this.sharedCoreFacade.getLastHoverTime() - this.sharedCoreFacade.getMsOffset(),
                eventFrom: TimeEventFrom.clickOnChart
            });

        dragFlag(this.timelineActions.updateCurrentTimeForFlagCoordinates);

        const headerDragElem = 90; // @headerDragElem в less
        const self = this;

        const _temp = new SwipeElement({
            touchId: 'timeline_touch',
            dragId: 'timeline_drag',
            type: 'vertical',
            stopPropagation: true,
            bounds: {
                get max() {
                    return self.timelineState.mobileMinimize ? document.getElementById('timeline_drag').offsetHeight - headerDragElem : 0
                },
                get min() {
                    return self.timelineState.mobileMinimize ? 0 : -document.getElementById('timeline_drag').offsetHeight + headerDragElem
                }
            },
            cbCssChange: (px: number) => {
                if (this.sharedCoreFacade.isComparedListEmpty()) {
                    return null;
                }

                return {
                    cssProp: 'transform',
                    val: this.timelineState.mobileMinimize ? `translateY(calc(-${headerDragElem}px - ${px}px))` : `translateY(calc(-100% - ${px}px))`
                }
            },
            cbMoreChangeLine: (direction: -1 | 1) => {
                if (this.sharedCoreFacade.isComparedListEmpty()) {
                    return;
                }

                if (this.timelineState.mobileMinimize && direction === 1) {
                    this.sharedCoreFacade.actionObservers.timelineMobileDragState.next(false);
                }

                if (!this.timelineState.mobileMinimize && direction === -1) {
                    this.sharedCoreFacade.actionObservers.timelineMobileDragState.next(true);
                }
            }
        });
    }
}
