import {
    Component,
    Input,
    ViewChild,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import type { CheckboxItem } from 'src/namespace';

@Component({
    selector: 'cs-selectbox',
    templateUrl: 'selectbox.component.html',
    styleUrls: ['selectbox.component.less']
})
export class SelectboxComponent implements OnInit, OnChanges {
    @Input() listItems: CheckboxItem[];
    @Input() searchPlaceholderText?: string;
    @Input() selectAllText?: string;
    @Input() resetText?: string;
    @Input() selectAll?: boolean;

    @Output() listItemsChange = new EventEmitter<CheckboxItem[]>();
    @Output() selectAllChange = new EventEmitter<boolean>();


    searchText = '';
    form: FormGroup;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            allItems: [this.selectAll ?? false],
            items: this.fb.group(
                this.getItemsCheckboxMap(this.listItems, this.selectAll || undefined)
            )
        });

        this.allItems.valueChanges
            .subscribe(value => {
                this.items.patchValue(
                    this.getItemsCheckboxMap(
                        Object.keys(this.items.value).map(id => ({ id })),
                        value
                    )
                );

                this.selectAllChange.emit(value);
            });

        this.items.valueChanges
            .subscribe(value => {
                const selected = this.listItems.filter((item) => {
                    return item.selected = value[item.id];
                });

                if (selected.length < this.listItems.length && this.allItems.value) {
                    this.allItems.patchValue(false, { emitEvent: false });
                    this.selectAllChange.emit(false);
                }

                this.listItemsChange.emit(this.listItems);
            });
    }

    ngOnChanges({ listItems }: SimpleChanges) {
        if (listItems) {
            const { firstChange, currentValue, previousValue } = listItems;

            if (!firstChange && currentValue !== previousValue) {
                this.items.patchValue(
                    this.getItemsCheckboxMap(currentValue, this.selectAll || undefined),
                    { emitEvent: false }
                );
            }
        }
    }

    resetFilter() {
        this.searchText = '';
    }

    private getItemsCheckboxMap(items: CheckboxItem[], resetValue?: boolean) {
        return items.reduce((acc, { id }) => ({
            ...acc,
            [id]: resetValue ?? items.find(it => it.id === id).selected
        }), {});
    }

    get allItems() {
        return this.form.get('allItems');
    }

    get items() {
        return this.form.get('items') as FormGroup;
    }
}
