import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

export type PlayButtonState = 'play' | 'pause';

@Component({
    selector: 'play-button',
    templateUrl: 'play-button.component.html',
    styleUrls: ['play-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayButtonComponent implements OnChanges {
    @Input() state: PlayButtonState = 'play';

    @Input() loading = false;

    @Input() loadingProgress: number;

    progressPair = '0 100';

    ngOnChanges(changes: SimpleChanges) {
        if (changes.loadingProgress) {
            const progress = Math.round(this.loadingProgress * 100);
            this.progressPair = `${progress} ${100 - progress}`;
        }
    }
}
