import { Component, Input } from '@angular/core';

import { TooltipsService } from 'projects/shared/components/tooltips/tooltips.service';
import { STATUS_TYPES } from '../device-status/device-status.types';
import { ANIMATION_OPACITY } from '../../../../../../shared/utils/config';

@Component({
    selector: 'packets-tooltip',
    templateUrl: 'packets-tooltip.component.html',
    styleUrls: ['packets-tooltip.component.less'],
    animations: ANIMATION_OPACITY
})
export class PacketsTooltipComponent {
    @Input() data: {
        title: string;
        chart: Chart;
        labels: {
            status: STATUS_TYPES;
            text: string;
        }[];
    };

    deviceStatusTypes = STATUS_TYPES;

    constructor(readonly tooltipsService: TooltipsService) {}
}
