export enum STATUS_TYPES {
    OK = 'ok',
    NO_PACKETS = 'noPackets',
    LOW_BATTERY = 'lowBattery',
    LOW_SIGNAL = 'lowSignal'
}

export type DeviceStatusLabel = {
    type: STATUS_TYPES;
    text: string;
};
