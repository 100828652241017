import { Component, Input } from '@angular/core';

@Component({
    selector: 'card-actions-item',
    templateUrl: 'card-actions-item.component.html',
    styleUrls: ['card-actions-item.component.less']
})
export class CardActionsItemComponent {
    @Input() text: string;
    @Input() type: 'default' | 'danger' = 'default';
}
