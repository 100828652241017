import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import {
    InitiatorToCloseTimeline,
    Marker_model,
    namePages,
    StationData,
    StationForMapPage_model,
    UpdatesCommand,
    UpdatesProps
} from '../../../../../src/namespace';
import { Observable } from 'rxjs/internal/Observable';

export class Observables {
    locationId: Observable<number>;
    models: Observable<void>;
    comparedList: Observable<void>;
    comparedChart: Observable<string>;
    loadCity: Observable<{ cityId: string, centringMap?: boolean }>;

    updateCompareStations: Observable<void>;
    updateStationDataOnePocket: Observable<{ data: StationData, station: StationForMapPage_model }>;
    loadBeforePlay: Observable<void>;
    canStartPlay: Observable<void>;
    closeTimeline: Observable<void>;
    maybeCloseTimeline: Observable<InitiatorToCloseTimeline>;
    changeTimeline: Observable<string>;
    timelineMobileDragState: Observable<boolean>;
    deleteCompareStation: Observable<number>;
    centringOnMarker: Observable<{ lat: number, lng: number, zooming: boolean, shift?: [number, number] }>;
    mapMarkerClick: Observable<Marker_model>;
    clickOnCityMarker: Observable<Marker_model>;
    openCityAnalytic: Observable<Marker_model | string>;
    disableCityMod: Observable<boolean>;
    updateData: Observable<{command: UpdatesCommand, props: UpdatesProps}>;
}

export class Observers {
    locationId = new BehaviorSubject(0);
    models = new ReplaySubject<void>();
    comparedList = new Subject<void>();
    comparedChart = new Subject<string>();
    loadCity = new Subject<{ cityId: string, centringMap?: boolean }>();

    // TODO убрать после переделги главного Action
    updateCompareStations = new Subject<void>();
    updateStationDataOnePocket = new Subject<{ data: StationData, station: StationForMapPage_model }>();
    loadBeforePlay = new Subject<void>();
    canStartPlay = new Subject<void>();
    closeTimeline = new Subject<void>();
    maybeCloseTimeline = new Subject<InitiatorToCloseTimeline>();
    changeTimeline = new Subject<string>();
    timelineMobileDragState = new Subject<boolean>();
    deleteCompareStation = new Subject<number>();
    centringOnMarker = new Subject<{ lat: number, lng: number, zooming: boolean, shift?: [number, number] }>();
    mapMarkerClick = new Subject<Marker_model>();
    clickOnCityMarker = new Subject<Marker_model>();
    openCityAnalytic = new Subject<Marker_model | string>();
    disableCityMod = new Subject<boolean>();
    updateData = new Subject<{command: UpdatesCommand, props: UpdatesProps}>();

    asObservable = (): Observables => ({
        locationId: this.locationId.asObservable(),
        models: this.models.asObservable(),
        comparedList: this.comparedList.asObservable(),
        comparedChart: this.comparedChart.asObservable(),
        loadCity: this.loadCity.asObservable(),

        updateCompareStations: this.updateCompareStations.asObservable(),
        updateStationDataOnePocket: this.updateStationDataOnePocket.asObservable(),
        loadBeforePlay: this.loadBeforePlay.asObservable(),
        canStartPlay: this.canStartPlay.asObservable(),
        closeTimeline: this.closeTimeline.asObservable(),
        maybeCloseTimeline: this.maybeCloseTimeline.asObservable(),
        changeTimeline: this.changeTimeline.asObservable(),
        timelineMobileDragState: this.timelineMobileDragState.asObservable(),
        deleteCompareStation: this.deleteCompareStation.asObservable(),
        centringOnMarker: this.centringOnMarker.asObservable(),
        mapMarkerClick: this.mapMarkerClick.asObservable(),
        clickOnCityMarker: this.clickOnCityMarker.asObservable(),
        openCityAnalytic: this.openCityAnalytic.asObservable(),
        disableCityMod: this.disableCityMod.asObservable(),
        updateData: this.updateData.asObservable(),
    });
}
