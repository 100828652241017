import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

import { TEXTS } from 'src/texts/texts';
import { LoginNavigationService } from '../../services/login-navigation/login-navigation.service';
import { HarvesterApiService } from '../../services/harvester-api/harvester-api.service';
import { LoginUpdateService } from '../../services/login-update/login-update.service';
import { LoginPage } from '../../login.settings';
import { MIN_PASSWORD_LENGTH } from 'src/config';

@Component({
    selector: 'ca-new-password-form',
    templateUrl: 'new-password-form.component.html',
    styleUrls: ['new-password-form.component.less'],
})
export class NewPasswordFormComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();

    TEXTS = TEXTS;

    showPassword = false;

    hasErrors = false;

    isLoading = false;

    newPasswordForm: FormGroup;

    passwordStrength = 0;

    containsValidPasswords = false;

    isInvite = false;

    localError = TEXTS.LOGIN_WINDOWS.wrongNewPass;

    loginPage = LoginPage;

    constructor(
        readonly harvester: HarvesterApiService,
        private fb: FormBuilder,
        private navigation: LoginNavigationService,
        private router: Router,
        private loginUpdateService: LoginUpdateService,
    ) {}

    ngOnInit() {
        this.isInvite = this.loginUpdateService.isInvite();

        this.newPasswordForm = this.fb.group({
            newPassword: ['', [
                Validators.required,
                Validators.minLength(MIN_PASSWORD_LENGTH),
            ]],
            newPasswordConfirm: ['', [
                Validators.required,
                Validators.minLength(MIN_PASSWORD_LENGTH),
            ]]
        });

        this.newPasswordForm.controls.newPassword.valueChanges.pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged()
        ).subscribe((value) => {
            this.measurePasswordStrength(value);
        });

        this.newPasswordForm.valueChanges.pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged()
        ).subscribe(({ newPassword, newPasswordConfirm }) => {
            this.checkPassword(newPassword, newPasswordConfirm);
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    async onSubmit() {
        if (this.isLoading) {
            return;
        }

        this.hasErrors = false;
        this.localError = TEXTS.LOGIN_WINDOWS.wrongNewPass;

        if (this.newPasswordForm.invalid) {
            this.hasErrors = true;
            return;
        }

        if (!this.containsValidPasswords) {
            this.localError = TEXTS.LOGIN_WINDOWS.passwordsDontMatch;
            this.hasErrors = true;
            return;
        }

        const { newPassword } = this.newPasswordForm.value;

        this.isLoading = true;

        const { email, verificationCode } = this.loginUpdateService.getParams();

        this.harvester.changePassword(email, verificationCode, newPassword).subscribe(() => {
            this.isLoading = false;
            // this.loginUpdateService.clear();
            this.navigation.openPasswordUpdatedPopup();
            this.router.navigate([LoginPage.Login]);
        }, () => {
            this.isLoading = false;
            this.hasErrors = true;
        });
    }

    checkPassword(password: string, passwordConfirm: string) {
        this.containsValidPasswords = password.length >= MIN_PASSWORD_LENGTH && password === passwordConfirm;
    }

    measurePasswordStrength(password: string) {
        this.passwordStrength = 0;

        this.hasErrors = false;

        if (!password) {
            return;
        }

        if (password.search(/[a-zA-Z0-9_!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) === -1) {
            this.hasErrors = true;
            return;
        }

        this.passwordStrength = 1;

        if (password.length < MIN_PASSWORD_LENGTH) {
            return;
        }

        if (password.search(/\d/) !== -1) {
            this.passwordStrength++;
        }

        if (password.search(/[A-Z]/) !== -1) {
            this.passwordStrength++;
        }

        if (password.search(/[a-z]/) !== -1) {
            this.passwordStrength++;
        }

        if (password.search(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) !== -1) {
            this.passwordStrength++;
        }
    }
}
