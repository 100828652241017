import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { TEXTS } from 'src/texts/texts';
import { indoorSelectPoint } from 'projects/cityscreen/src/modules/indoor/services/store/actions';
import { indoorSelectors } from 'projects/cityscreen/src/modules/indoor/services/store/selectors';
import { IIndoorState } from 'projects/cityscreen/src/modules/indoor/services/store/reducers';
import { IndoorFacade } from 'projects/cityscreen/src/modules/indoor/services/facade';

@Component({
    selector: 'indoor-main',
    templateUrl: './indoor-main.component.html',
    styleUrls: ['./indoor-main.component.less']
})
export class IndoorMainComponent implements OnDestroy {
    indoorSelectors = indoorSelectors;
    indoorSelectPoint = indoorSelectPoint;
    TEXTS = TEXTS;
    analyticIsOpen = false;

    constructor(
        public store: Store<IIndoorState>,
        public indoorFacade: IndoorFacade
    ) {
        indoorFacade.mainComponentMounted();
    }

    updateTimeRange(data: { begin: number, end: number, allowUpdate: boolean }) {
        this.indoorFacade.updateTimeRange(data.begin, data.end, data.allowUpdate);
    }

    ngOnDestroy() {
        this.indoorFacade.mainComponentUnmounted();
    }
}
