<div class="selectbox">
    <search-input
        [textPlaceholder]="searchPlaceholderText"
        [(text)]="searchText"
        [debounceTime]="400"
    ></search-input>

    <div class="selectbox__list scroll">
        <div *ngIf="!searchText"
             class="selectbox__list-item selectbox__list-item--all"
             (click)="isSelectAll() ? deselectAll() : selectAll()"
        >
            <cs-checkbox [checked]="isSelectAll()"></cs-checkbox>
            <span class="selectbox__list-item__label">{{selectAllText}}</span>
        </div>

        <div *ngFor="let item of listItems | multipleSearchfilter : ['label'] : searchText"
             class="selectbox__list-item"
             (click)="onItemClick(item)"
        >
            <cs-checkbox [checked]="item.selected"></cs-checkbox>
            <span class="selectbox__list-item__label">{{item.label}}</span>
        </div>
    </div>

    <div class="selectbox-actions" *ngIf="searchText">
        <text-button size="small"
            (clickAction)="searchText = ''"
        ></text-button>
    </div>
</div>
