<div class="plumes_control_points">
    <ca-list-header [showCounter]="false">
        <button-add
            [text]="texts.PLUMES.addControlPoint"
            (action)="openAddCheckpoint()"
        ></button-add>
    </ca-list-header>
    <calculation-result-selector
        class="plumes_control_points__dropdown_control_point_wrapper"
        [run]="sharedCoreFacade.getIsShowPlume() ? sharedCoreFacade.getPlumeCurrentRun() : null"
    ></calculation-result-selector>
    <div class="plumes_control_points__sort_wrapper">
        <sort-list
            [properties]="[
                {
                    props: sortControlPointName,
                    title: texts.PLUMES.controlPoints
                },
                {
                    props: sortControlPointValue,
                    title: texts.PLUMES.value
                }
            ]"
            [cbSortFun]="sortingOfControlPoint"
            [sortingProps]="sortingControlPoint"
            [sortingDirection]="sortingDirection"
        ></sort-list>
    </div>
    <div (scroll)="onScroll($event)" class="plumes_control_points scroll">
        <plumes-control-points-card
            *ngFor="let point of allPoints | sorting : sortingControlPoint : sortingDirection"
            [currentPointValues]="plumeFacade.uiState.getPointValue(point.id)"
            [measureUnit]="groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme) === measureScheme.c_mmhg_mg ? texts.mgM : texts.ugM"
            [point]="point"
            [activePointId]="plumeFacade.getActiveControlPointId()"
            (click)="openControlPointChart(point)"
            (openActionsPopup)="openActionsPopup($event, point)"
        ></plumes-control-points-card>

        <ng-template #popupDeleteOutlet>
            <stnd-ask-popup
                *ngIf="isDeletePopupOpen"
                [accept]="deleteControlPointAccept"
                [close]="closeDeleteControlPointDialog"
                [texts]="popupDeleteTexts"
            ></stnd-ask-popup>
        </ng-template>

        <ng-template #actionsOutlet>
            <card-actions-list
                [position]="popupPositionTop"
                (clickOutside)="closeActionsPopup($event)"
            >
                <card-actions-item
                    [text]="texts.EDIT_STATION.edit"
                    (click)="editControlPoint()"
                ></card-actions-item>

                <card-actions-separator></card-actions-separator>

                <card-actions-item
                    type="danger"
                    [text]="texts.EDIT_STATION.delete"
                    (click)="deleteControlPointDialog($event, selectedPoint)"
                ></card-actions-item>
            </card-actions-list>
        </ng-template>
    </div>
</div>
