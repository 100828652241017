import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const TIMEOUT = 200;

@Injectable({
    providedIn: 'root'
})
export class CommonLoaderService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    hideHtmlLoader() {
        const style = this.getLoaderStyle();

        if (style) {
            style.opacity = '0';

            setTimeout(() => {
                style.display = 'none';
            }, TIMEOUT);
        }
    }

    showHtmlLoader() {
        const style = this.getLoaderStyle();

        if (style) {
            style.opacity = '1';

            setTimeout(() => {
                style.display = 'flex';
            }, TIMEOUT);
        }
    }

    private getLoaderStyle() {
        const loader = this.document.getElementById('commonLoader');

        return loader?.style;
    }
}
