import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputEmbeddedComponent } from './search-input-embedded.component';
import { SearchInputBasicModule } from 'projects/shared/modules/form-components/search-input-basic/search-input-basic.module';



@NgModule({
    exports: [SearchInputEmbeddedComponent],
    declarations: [SearchInputEmbeddedComponent],
    imports: [
        CommonModule,
        SearchInputBasicModule,
    ]
})
export class SearchInputEmbeddedModule {
}
