import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { CardModule } from 'projects/cityscreen/src/components/card/card.module';
import { ButtonAddModule } from '../../../components/admin-panel/components/button-add/button-add.module';

import { PlumesPage } from './plumes-page/plumes-page.component'
import { PlumesSourcesList } from './plumes-sources-list/plumes-sources-list.component';
import { PlumesControlPoints } from './plumes-control-points/plumes-control-points.component';
import { PlumesCalculationResults } from './plumes-calculation-results/plumes-calculation-results.component';
import { PlumesCalculationResultsCard } from './plumes-calculation-results-card/plumes-calculation-results-card.component';
import { PlumesControlPointsCard } from './plumes-control-points-card/plumes-control-points-card.component';
import { EditControlPointComponent } from './edit-control-point/edit-control-point.component';

import { PlumesCalculation } from './plumes-calculation/plumes-calculation.component';
import { PlumesSource } from './plumes-source/plumes-source.component';
import { CalculationResultSelectorComponent } from './calculation-result-selector/calculation-result-selector.component';
import { PlumesRunSummaryComponent } from './plumes-run-summary/plumes-run-summary.component';
import { PlumesCalculationResultsEditComponent } from './plumes-calculation-results-edit/plumes-calculation-results-edit.component';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { CalendarModule } from 'src/calendar/calendar.module';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { PageTabsModule } from 'projects/shared/components/page-tabs/page-tabs.module';
import { CardActionsModule } from 'projects/cityscreen/src/components/card-actions/card-actions.module';
import { InputModule } from 'projects/cityscreen/src/components/input/input.module';
import { CalendarMonthsModule } from 'src/calendar/calendar-months/calendar-months.module';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        SharedComponentsModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        CalendarMonthsModule,
        LoadersModule,
    ],
    exports: [
        PlumesPage,
        PlumesRunSummaryComponent,
        PlumesCalculationResultsCard,
    ],
    declarations: [
        PlumesPage,
        PlumesSourcesList,
        PlumesCalculationResults,
        PlumesCalculationResultsCard,
        PlumesControlPoints,
        PlumesControlPointsCard,
        EditControlPointComponent,
        PlumesCalculation,
        PlumesSource,
        CalculationResultSelectorComponent,
        PlumesCalculationResultsEditComponent,
        PlumesRunSummaryComponent,
    ]
})
export class PlumesModule {}
