import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

import State from '../../map/state';
import { formatDayMonthYear } from '../config'

import './calendar.less';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

@Component({
    selector: 'calendar',
    template: `
        <div class="calendar" (clickOutside)="close()">
            <div class="calendar__date" (click)="toggleShow()">{{getIntervalText()}}</div>

            <calendar-months
                [show]="showCalendar"
                [timeBegin]="time.getBegin()"
                [timeEnd]="time.getEnd()"
                [tzOffset]="sharedCoreFacade.getTzOffset()"
                [applyCb]="updateTime"
                [updateDays]="updateDays"
                [monthsVisible]="monthsVisible"
                [columns]="columns >= 4 ? 4 : columns"
            ></calendar-months>
        </div>
    `,
})
export class Calendar {
    @Input() monthsVisible: number;
    @Input() columns: number;
    showCalendar = false;

    @Output() updateDateRange = new EventEmitter<{
        begin: number;
        end: number;
        allowUpdate: boolean;
    }>();

    constructor(public state: State, readonly sharedCoreFacade: SharedCoreFacade, public time: Time) {}

    getIntervalText() {
        const tzOffset = this.sharedCoreFacade.getTzOffset();
        const begin = moment(this.time.getBegin()).utcOffset(tzOffset);
        const end = moment(this.time.getEnd()).utcOffset(tzOffset);

        const textStart = formatDayMonthYear(begin);
        const textEnd = formatDayMonthYear(end);

        return  `${textStart} - ${textEnd}`;
    }

    updateTime = (begin: number, end: number, allowUpdate: boolean) => {
        this.updateDateRange.emit({ begin, end, allowUpdate });
        this.showCalendar = false;
    };

    toggleShow = () => {
        this.showCalendar = !this.showCalendar;

        if (this.showCalendar) {
            this.handleUpdateDays(this.sharedCoreFacade.getTzOffset());
        }
    };

    handleUpdateDays: (tzOffset: number) => void;

    updateDays = (fn: (tzOffset: number) => void) => this.handleUpdateDays = fn;

    close = () => {
        this.showCalendar = false;
    };
}
