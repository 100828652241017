<div class="device-status-filter">
    <div class="device-status-filter-list" [formGroup]="form">
        <div class="device-status-filter-list-item" [formGroup]="items"
            *ngFor="let item of listItems"
        >
            <label>
                <cs-checkbox [checked]="items.value[item.id]"></cs-checkbox>
                <input type="checkbox" [formControlName]="item.id" />
                <span class="device-status-filter-list-item__label">
                    <device-status-tag [type]="item.id" [text]="item.label"></device-status-tag>
                </span>
            </label>
        </div>
    </div>
    <div class="device-status-filter-actions">
        <text-button size="small"
            [text]="resetText"
            (clickAction)="resetFilter()"
        ></text-button>
    </div>
</div>
