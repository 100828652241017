import { Component, Input } from '@angular/core';

import { measureZones } from 'src/config';
import { AQI, MeasuresInfoFormat, StationForMapPage_model, WDA } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { declOfNum, isFalseNumber } from 'src/utils';

import './style.less';

@Component({
    selector: 'chart-compare-measure-indicator-new',
    template: `
        <div *ngFor="let measure of measuresList" class="measure_indicator {{className || ''}}"
            [class.measure_indicator--multiline]="hasMultilineTitle(measure.name)"
        >
            <div class="measure_indicator__text"
                [class.measure_indicator__text--aqi]="measure.name === AQI"
            >
                <ng-container *ngIf="!isFalseNumber(getValue(measure)); else notAvailable">
                    <ng-container *ngIf="measure.name === AQI; else notAqi">
                        <div class="measure_indicator__circle measure_indicator__circle-{{getValue(measure)}}"></div>
                    </ng-container>
                    <ng-template #notAqi>{{getValue(measure)}}</ng-template>

                    <div class="measure_indicator__v_name"
                        [innerHtml]="(measure.name === AQI ? declOfNum(getValue(measure), TEXTS.AQI_DECL) : TEXTS.UNITS[measure.unit] || '') | safeHtml"
                    ></div>

                    <div class="measure_indicator__v_note"
                         *ngIf="measure.type === WDA"
                         [ngStyle]="{transform: getRotation(measure)}"
                    ></div>
                </ng-container>
                <ng-template #notAvailable>-</ng-template>
            </div>
            <div *ngIf="showIndicator(measure)" class="measure_indicator__circle_exec"></div>
        </div>
    `
})
export class ChartCompareMeasureIndicator {
    @Input() className: string;
    @Input() station: StationForMapPage_model;
    @Input() measuresList: MeasuresInfoFormat[];
    @Input() color: string;
    @Input() pdkTypeIndicator?: 'ss' | 'mr';

    AQI = AQI;
    WDA = WDA;
    TEXTS = TEXTS;

    declOfNum = declOfNum;

    isFalseNumber = isFalseNumber;

    getRotation(measure: MeasuresInfoFormat) {
        const val = +this.getValue(measure);
        return `rotate(${val}deg)`;
    }

    getValue(measure: MeasuresInfoFormat) {
        return this.station.measuresVal ? this.station.measuresVal[measure.name] : null;
    }

    showIndicator(measure: MeasuresInfoFormat) {
        if (!this.pdkTypeIndicator || !this.station.measuresVal) {
            return false;
        }

        const val = this.getValue(measure);

        const maxVal = this.pdkTypeIndicator === 'ss'
            ? measureZones.getPDKcc(measure.type)
            : measureZones.getPDKmr(measure.type);

        if (isFalseNumber(val) || isFalseNumber(maxVal)) {
            return false;
        }

        return val >= maxVal;
    }

    hasMultilineTitle(name: string) {
        return (TEXTS.NAMES[name] || name).split(' ').length > 1;
    }
}

@Component({
    selector: 'chart-compare-measure-indicator-new-m',
    template: `
        <div *ngFor="let measure of measuresList"
            class="measure_indicator {{className || ''}}"
            (click)="select(measure, false)"
        >
            <div class="measure_indicator__text"
                [class.measure_indicator__text--aqi]="measure.name === AQI"
            >
                <ng-container *ngIf="!isFalseNumber(getValue(measure)); else notAvailable">
                    <ng-container *ngIf="measure.name === AQI; else notAqi">
                        <div class="measure_indicator__circle measure_indicator__circle-{{getValue(measure)}}"></div>
                    </ng-container>
                    <ng-template #notAqi>{{getValue(measure)}}</ng-template>

                    <div class="measure_indicator__v_note"
                         *ngIf="measure.type === WDA"
                         [ngStyle]="{transform: getRotation(measure)}"
                    ></div>
                </ng-container>
                <ng-template #notAvailable>-</ng-template>
            </div>
        </div>
    `
})
export class ChartCompareMeasureIndicator_m {
    @Input() className: string;
    @Input() station: StationForMapPage_model;
    @Input() measuresList: MeasuresInfoFormat[];
    @Input() color: string;
    @Input() select: (measure: MeasuresInfoFormat, add: boolean) => void;

    TEXTS = TEXTS;
    AQI = AQI;
    WDA = WDA;
    isFalseNumber = isFalseNumber;

    getRotation(measure: MeasuresInfoFormat) {
        const val = +this.getValue(measure);
        return `rotate(${val}deg)`;
    }

    getValue(measure: MeasuresInfoFormat) {
        return this.station.measuresVal ? this.station.measuresVal[measure.name] : null;
    }

    getHtml(measure: MeasuresInfoFormat) {
        const val = this.station.measuresVal[measure.name];

        let measureText = TEXTS.UNITS[measure.unit] || '';

        if (isFalseNumber(val)) {
            return  '-';
        }

        if (measure.name === AQI) {
            measureText = declOfNum(val,  TEXTS.AQI_DECL);
        }

        return`${val}<div class="measure_indicator__v_name measure_indicator__v_name-m">${measureText}</div>`;
    }
}
