<div class="dashboard">
    <div class="dashboard-panel__wrapper scroll">
    <div class="dashboard-panel dashboard-main">
        <div class="dashboard__header">
            <div class="dashboard__header-title">{{TEXTS.DASHBOARD.dashboardTitle}}</div>
            <div class="dashboard__header__aside">
                <div class="dashboard__header__button" (click)="refreshData()">{{TEXTS.DASHBOARD.refreshData}}</div>
            </div>
        </div>
        <div class="dashboard-section">
            <dashboard-widget [size]="2">
                <dashboard-widget-header>
                    <dashboard-widget-title>
                        {{TEXTS.DASHBOARD.availabilityStatsTitle}}
                        <ca-info-icon
                            [active]="true"
                            caTooltip
                            caTooltipPlacement="top-left"
                            [caTooltipTemplate]="availabilityStatsTooltip"
                        ></ca-info-icon>
                        <ng-template #availabilityStatsTooltip>
                            <ca-tooltip [text]="[TEXTS.DASHBOARD.availabilityTooltipText, lastPacketReceivedDate].join(' ')"></ca-tooltip>
                        </ng-template>
                    </dashboard-widget-title>
                </dashboard-widget-header>
                <dashboard-widget-body>
                    <div class="dashboard-stats__wrapper">
                        <div class="dashboard-stats">
                            <div class="dashboard-stats__title">{{dataTransmissionStats.title}}</div>
                            <div class="dashboard-stats__body">
                                <dashboard-doughnut [chart]="dataTransmissionStats.chart"></dashboard-doughnut>
                                <div class="dashboard-stats__device-list">
                                    <tags-list
                                        [tags]="dataTransmissionStats.devices"
                                        [truncateTo]="2"
                                        [textNodesFns]="postsListLabels"
                                    ></tags-list>
                                </div>
                                <text-button size="small"
                                    [text]="TEXTS.DASHBOARD.showInMonitor"
                                    (clickAction)="noop()"
                                ></text-button>
                            </div>
                        </div>
                        <div class="dashboard-stats">
                            <div class="dashboard-stats__title">{{powerSupplyStats.title}}</div>
                            <div class="dashboard-stats__body">
                                <dashboard-doughnut [chart]="powerSupplyStats.chart"></dashboard-doughnut>
                                <div class="dashboard-stats__device-list">
                                    <tags-list
                                        [tags]="powerSupplyStats.devices"
                                        [truncateTo]="2"
                                        [textNodesFns]="postsListLabels"
                                    ></tags-list>
                                </div>
                                <text-button size="small"
                                    [text]="TEXTS.DASHBOARD.showInMonitor"
                                    (clickAction)="noop()"
                                ></text-button>
                            </div>
                        </div>
                    </div>
                </dashboard-widget-body>
            </dashboard-widget>
            <dashboard-widget>
                <dashboard-widget-header>
                    <dashboard-widget-title>
                        {{TEXTS.DASHBOARD.monthlyStatsTitle}} {{currentMonth}}
                        <ca-info-icon
                            [active]="true"
                            caTooltip
                            caTooltipPlacement="top-left"
                            [caTooltipTemplate]="monthlyStatsTooltip"
                        ></ca-info-icon>
                        <ng-template #monthlyStatsTooltip>
                            <ca-tooltip [text]="TEXTS.DASHBOARD.monthlyStatsTooltipText"></ca-tooltip>
                        </ng-template>
                    </dashboard-widget-title>
                </dashboard-widget-header>
                <dashboard-widget-body>
                    <div class="dashboard-stats">
                        <div class="dashboard-stats__title">{{monthlyStats.title}}</div>
                        <div class="dashboard-stats__body dashboard-stats__body--center">
                            <dashboard-doughnut
                                [chart]="monthlyStats.chart"
                                [showLegend]="false"
                                [showMainValue]="true"
                            ></dashboard-doughnut>
                            <div class="dashboard-stats__chart-legend-label">{{TEXTS.DASHBOARD.dataReceived}}</div>
                        </div>
                    </div>
                </dashboard-widget-body>
            </dashboard-widget>
        </div>
        <div class="dashboard-section">
            <dashboard-widget>
                <dashboard-widget-header>
                    <dashboard-widget-title>{{TEXTS.DASHBOARD.packetsMonitorTitle}}</dashboard-widget-title>
                    <div class="dashboard__device-monitor-legend">
                        <device-status
                            [status]="deviceStatusTypes.OK"
                            [text]="TEXTS.DASHBOARD.deviceMonitorLegend.dataReceived"
                        ></device-status>
                        <device-status
                            [status]="deviceStatusTypes.NO_PACKETS"
                            [text]="TEXTS.DASHBOARD.deviceMonitorLegend.hasMissingData"
                        ></device-status>
                        <device-status
                            [status]="deviceStatusTypes.LOW_BATTERY"
                            [text]="TEXTS.DASHBOARD.deviceMonitorLegend.poweredByBattery"
                        ></device-status>
                        <device-status
                            [status]="deviceStatusTypes.LOW_SIGNAL"
                            [text]="TEXTS.DASHBOARD.deviceMonitorLegend.lowGMSSignal"
                        ></device-status>
                    </div>
                </dashboard-widget-header>
                <dashboard-widget-body>
                    <div class="dashboard-controls">
                        <div class="dashboard-controls__item__wrapper">
                            <div class="dashboard-controls__item dashboard-controls__time-interval">
                                <div class="dashboard-controls__label">{{TEXTS.DASHBOARD.selectedTimeIntervalLabel}}</div>
                                <ca-arrow direction="left" type="action" (click)="prevTimeInterval()"></ca-arrow>
                                <div class="dashboard-controls__text">{{dateRangeFilterText}}</div>
                                <ca-arrow direction="right" type="action" (click)="nextTimeInterval()" [disabled]="todayIsIncluded()"></ca-arrow>
                            </div>
                        </div>
                        <div class="dashboard-controls__item__wrapper"
                            (clickOutside)="deviceFilter.isActive = false"
                        >
                            <div class="dashboard-controls__item dashboard-controls__devices"
                                (click)="deviceFilter.isActive = !deviceFilter.isActive"
                            >
                                <div class="dashboard-controls__label">{{TEXTS.DASHBOARD.selectedDevicesLabel}}</div>
                                <div class="dashboard-controls__text">{{deviceFilter.data.length}} {{deviceFilter.data.length | i18nPlural : TEXTS.NOTIFICATIONS.station}}</div>
                                <ca-arrow [direction]="deviceFilter.isActive ? 'up' : 'down'"></ca-arrow>
                            </div>
                            <cs-selectbox
                                class="dashboard-controls__select"
                                *ngIf="deviceFilter.isActive"
                                [listItems]="deviceFilter.data"
                                (listItemsChange)="filterCharts(dataForChart, $event, statusFilter.data)"
                                [searchPlaceholderText]="TEXTS.DASHBOARD.searchDevicePlaceholderText"
                                [selectAllText]="TEXTS.DASHBOARD.selectAllDevicesFilter"
                                [resetText]="TEXTS.DASHBOARD.resetDevicesFilter"
                            ></cs-selectbox>
                        </div>
                        <div class="dashboard-controls__item__wrapper"
                            (clickOutside)="statusFilter.isActive = false"
                        >
                            <div class="dashboard-controls__item dashboard-controls__devices"
                                (click)="statusFilter.isActive = !statusFilter.isActive"
                            >
                                <div class="dashboard-controls__label">{{TEXTS.DASHBOARD.columnLabels.status}}</div>
                                <div class="dashboard-controls__text">
                                    <ng-container *ngIf="filterHasSelectedItems(statusFilter.data); else statusNotSelected">
                                        <ng-container *ngFor="let tag of statusFilter.data">
                                            <device-status-tag
                                                class="dashboard-controls__text__tag"
                                                *ngIf="tag.selected"
                                                [type]="tag.id"
                                                [text]="tag.label"
                                            ></device-status-tag>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #statusNotSelected>
                                        {{TEXTS.DASHBOARD.notSelectedStatusFilter}}
                                    </ng-template>
                                </div>
                                <ca-arrow [direction]="statusFilter.isActive ? 'up' : 'down'"></ca-arrow>
                            </div>
                            <device-status-filter
                                class="dashboard-controls__select"
                                *ngIf="statusFilter.isActive"
                                [listItems]="statusFilter.data"
                                (listItemsChange)="filterCharts(dataForChart, deviceFilter.data, $event)"
                                [resetText]="TEXTS.DASHBOARD.resetDevicesFilter"
                            ></device-status-filter>
                        </div>
                    </div>

                    <div class="dashboard__packets-monitor">
                        <div class="dashboard__packets-monitor__device-info">
                            <sort-list
                                [properties]="sortDevicesProps"
                                [cbSortFun]="onChartsSorting"
                                [sortingProps]="sortingPredicate"
                                [sortingDirection]="sortingDirection"
                            ></sort-list>
                        </div>
                        <div class="dashboard__packets-monitor__device-aside">
                            <div class="dashboard__packets-monitor__device-status">
                                <div class="dashboard__packets-monitor__device-status__content">
                                    {{TEXTS.DASHBOARD.columnLabels.status}}
                                </div>
                            </div>
                            <div class="dashboard__packets-monitor__device-chart">
                                {{TEXTS.DASHBOARD.columnLabels.packetsRate}}
                            </div>
                        </div>
                    </div>
                    <packets-monitor-list-item *ngFor="let chartData of dataForChartFiltered | sorting : sortingPredicate : sortingDirection"
                        [data]="chartData.device.deviceInfo"
                        [chart]="chartData.device.chart"
                        [statusList]="chartData.statusList"
                        [beginDate]="beginDate"
                        [endDate]="endDate"
                    >
                        <dashboard-chart
                            [data]="chartData.device"
                            [beginDate]="beginDate"
                            [endDate]="endDate"
                            [intervalWidth]="intervalWidth"
                            [ticks]="ticks"
                        ></dashboard-chart>
                    </packets-monitor-list-item>
                    <packets-monitor-timeline-scale
                        [beginDate]="beginDate"
                        [endDate]="endDate"
                        [intervalWidth]="intervalWidth"
                        [ticks]="ticks"
                        [datesList]="scaleDates"
                    ></packets-monitor-timeline-scale>
                </dashboard-widget-body>
            </dashboard-widget>
        </div>
    </div>
    </div>
    <div class="dashboard-panel dashboard-panel--fixed dashboard-aside">
        <event-feed [disableNavigation]="true"></event-feed>
    </div>
</div>
