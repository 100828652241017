import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { PopupsModule } from 'projects/cityscreen/src/components/popups/popups.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { CardModule } from 'projects/cityscreen/src/components/card/card.module';

import { GradientSlider } from 'projects/cityscreen/src/modules/notifications/components/gradient-slider/gradient-slider.component';
import { Notifications } from 'projects/cityscreen/src/modules/notifications/components/notifications/notifications.component';
import { EventFeed } from 'projects/cityscreen/src/modules/notifications/components/event-feed/event-feed.component';
import { Subscriptions } from 'projects/cityscreen/src/modules/notifications/components/subscriptions/subscriptions.component';
import { SubscriptionsMeasurements } from 'projects/cityscreen/src/modules/notifications/components/subscriptions-measurements/subscriptions-measurements.component';
import { SubscriptionsService } from 'projects/cityscreen/src/modules/notifications/components/subscriptions-service/subscriptions-service.component';
import { SubscriptionCard } from 'projects/cityscreen/src/modules/notifications/components/subscription-card/subscription-card.component';
import { SubscriptionCardMeasurements } from 'projects/cityscreen/src/modules/notifications/components/subscription-card-measurements/subscription-card-measurements.component';
import { SubscriptionCardService } from 'projects/cityscreen/src/modules/notifications/components/subscription-card-service/subscription-card-service.component';
import { Settings } from 'projects/cityscreen/src/modules/notifications/components/settings/settings.component';
import { SettingsMeasurements } from 'projects/cityscreen/src/modules/notifications/components/settings-measurements/settings-measurements.component';
import { SettingsService } from 'projects/cityscreen/src/modules/notifications/components/settings-service/settings-service.component';
import { EventFeedItem } from 'projects/cityscreen/src/modules/notifications/components/event-feed-item/event-feed-item.component';
import { EventBody } from 'projects/cityscreen/src/modules/notifications/components/event-body/event-body.component';

import Actions from 'map/actions';
import State from 'map/state';
import { MultipleSearchFnsPipe } from 'projects/shared/pipes/pipes';
import { CalendarModule } from 'src/calendar/calendar.module';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { ButtonAddModule } from 'projects/cityscreen/src/components/admin-panel/components/button-add/button-add.module';
import { PageTabsModule } from 'projects/shared/components/page-tabs/page-tabs.module';
import { SearchInputModule } from 'projects/shared/modules/form-components/search-input/search-input.module';
import { CardActionsModule } from 'projects/cityscreen/src/components/card-actions/card-actions.module';
import { SelectboxModule } from 'projects/cityscreen/src/components/selectbox/selectbox.module';
import { InputDateRangeModule } from 'src/calendar/input-date-range/input-date-range.module';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';
import { TagsListModule } from 'projects/cityscreen/src/components/tags-list/tags-list.module';
import { SwitchItemModule } from 'projects/cityscreen/src/components/switch-item/switch-item.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        ScrollingModule,
        LittleComponentsModule,
        PopupsModule,
        TooltipsModule,
        CardModule,
        CalendarModule,
        SharedComponentsModule,
        SearchInputModule,
        ButtonAddModule,
        PageTabsModule,
        CardActionsModule,
        SelectboxModule,
        InputDateRangeModule,
        LoadersModule,
        TagsListModule,
        SwitchItemModule,
    ],
    exports: [
        Notifications,
        Subscriptions,
        EventFeed,
        EventFeedItem,
        GradientSlider,
    ],
    providers: [
        Actions,
        State,
        MultipleSearchFnsPipe,
    ],
    declarations: [
        Notifications,
        EventFeed,
        EventFeedItem,
        EventBody,
        Subscriptions,
        SubscriptionsMeasurements,
        SubscriptionsService,
        SubscriptionCard,
        SubscriptionCardMeasurements,
        SubscriptionCardService,
        Settings,
        SettingsMeasurements,
        SettingsService,
        GradientSlider,
    ]
})
export class NotificationsModule {}
