import {
    Component, ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { ANIMATION_OPACITY } from '../../utils/config';

@Component({
    selector: 'kebab-menu',
    template: `<div>
        <div #popupOpener (click)="actions()" class="kebab_menu"
            [class.kebab_menu-disabled]="disabled"
        >
            <div class="kebab_menu__circles">
                <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="2" class="kebab_menu-icon-dot"/>
                    <circle cx="2" cy="9" r="2" class="kebab_menu-icon-dot"/>
                    <circle cx="2" cy="16" r="2" class="kebab_menu-icon-dot"/>
                </svg>
            </div>
            <div class="kebab_menu__object"
                [class.kebab_menu__object-left]="positionOpenedMenu === 'left'"
                *ngIf="isOpenMenu"
                [@inOutAnimation]
            >
                <ng-content></ng-content>
            </div>
        </div>
    </div>`,
    styleUrls: ['kebab-menu.component.less'],
    animations: ANIMATION_OPACITY
})
export class KebabMenuComponent {
    @Input() disabled = false;
    @Input() positionOpenedMenu: 'right' | 'left' = 'right';

    @Output() clickActions = new EventEmitter<{target: HTMLElement, positionTop: number}>();

    isOpenMenu = false;

    @ViewChild('popupOpener') popupOpener: ElementRef<HTMLDivElement>;

    actions() {
        this.isOpenMenu = !this.isOpenMenu;
        const { nativeElement: target } = this.popupOpener;
        const positionTop = target.getBoundingClientRect().top - target.offsetTop + window.scrollY;
        this.clickActions.emit({
            target,
            positionTop
        });
    }
}
