import {
    Component,
    TemplateRef,
    ViewChild,
    OnInit,
    OnDestroy,
    OnChanges
} from '@angular/core';

import Actions from 'map/actions';
import State from 'map/state';
import { LANGUAGE, TEXTS } from 'src/texts/texts';
import { Token } from 'src/namespace';

import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { MessageAPIResponseService } from 'src/little-components/message-api-response/message-api-response.service';

import './api-tokens.less';
import AdminPanelActions from '../../admin-panel/actions';

type PopupType = 'create' | 'rename' | 'close' | '';

@Component({
    selector: 'api-tokens',
    templateUrl: 'api-tokens.html'
})
export class ApiTokensComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('tokenMenu', { static: true }) tokenMenu: TemplateRef<HTMLDivElement>;
    @ViewChild('popupMenu', { static: true }) popupMenu: TemplateRef<HTMLDivElement>;

    isShowMenu = false;
    menuPositionTop = 0;
    countTokens: number;
    popup: PopupType;

    newTitle: string;
    showTokenValue: {
        [id: number]: string
    } = {};
    selectedToken: Token;
    hoveredToken: Token;

    TEXTS = TEXTS;
    language = LANGUAGE;
    quotes = this.language == 'en' ? ['"', '"'] : ['«', '»'];
    tokenPlaceholder = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';

    private popupOpenerElement: HTMLElement;

    constructor(
        private actions: Actions,
        private state: State,
        public popupProvider: OffPanelPopupService,
        private msgService: MessageAPIResponseService,
        public apActions: AdminPanelActions
    ) {}

    apState = this.state.adminPanel;

    configPopup = {
        create: {
            title: TEXTS.CONFIG.titlePopupCreate,
            apply: TEXTS.CONFIG.applyCreate
        },
        close: {
            title: TEXTS.CONFIG.titlePopupClose,
            apply: TEXTS.CONFIG.applyClose
        },
        rename: {
            title: TEXTS.CONFIG.titlePopupRename,
            apply: TEXTS.CONFIG.applyRename
        }
    }

    copyToken = async (tokenId: number) => {
        const text = await this.apActions.getUserTokenValue(tokenId);

        const showTokenPlanB = () => this.showTokenValue[tokenId] = text;

        // safari
        if (!navigator.permissions || !navigator.permissions.query) {
            return showTokenPlanB();
        }

        // @ts-ignore
        navigator.permissions.query({name: 'clipboard-write'}).then(result => {
            if (result.state == 'granted' || result.state == 'prompt') {
                navigator.clipboard.writeText(text).then(() => {
                    this.msgService.setMsg({
                        message: TEXTS.CONFIG.copySuccess,
                        type: 'success'
                    });
                }, () => {
                    showTokenPlanB();
                });
            }
            else
                showTokenPlanB();
        }).catch(() => showTokenPlanB());
    }

    getDescription = () => {
        return TEXTS.CONFIG.closeDescription + this.quotes[0] +
            this.selectedToken.title + this.quotes[1];
    }

    applyPopup = async () => {
        const type = this.popup;
        this.popup = '';

        if (type === 'rename') {
            await this.apActions.editToken(this.selectedToken.tokenId, this.newTitle);
        }

        if (type === 'close') {
            await this.apActions.closeToken(this.selectedToken.tokenId);
        }

        if (type === 'create') {
            await this.apActions.createToken(this.newTitle);
        }

        await this.apActions.updateTokens();
        this.updateCount();
    }

    showPopup(e: Event, name: PopupType) {
        this.popup = name;

        if (e) {
            this.closeMenu(e);
        }

        this.popupProvider.setTemplate(this.popupMenu);

        if (name === 'rename') {
            this.newTitle = this.selectedToken?.title;
        } else {
            this.newTitle = '';
        }
    }

    clickMenu({ target, positionTop }, token: Token) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.tokenMenu, () => this.isShowMenu = false);
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop;
        this.isShowMenu = true;
        this.selectedToken = token;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    updateCount() {
        this.countTokens = this.state.adminPanel.tokens?.apiTokens.length;
    }

    ngOnInit() {
        this.updateCount();
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    ngOnChanges() {
        this.updateCount();
    }

    onScroll() {
        this.isShowMenu = false;
    }
}
