import { Component, Input } from '@angular/core';

import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'plumes-calculation-results-card',
    templateUrl: 'plumes-calculation-results-card.component.html',
    styleUrls: ['plumes-calculation-results-card.component.less']
})
export class PlumesCalculationResultsCard {
    @Input() isActive: boolean;
    TEXTS = TEXTS;
}
