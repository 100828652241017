import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsList } from './tags-list.component';
import { CrossButtonModule } from 'projects/shared/modules/buttons/cross-button/cross-button.module';

@NgModule({
    exports: [TagsList],
    declarations: [TagsList],
    imports: [
        CommonModule,
        CrossButtonModule,
    ]
})
export class TagsListModule {
}
