import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchV2Component } from './switch-v2.component';

@NgModule({
  exports: [SwitchV2Component],
  declarations: [SwitchV2Component],
  imports: [
    CommonModule
  ]
})
export class SwitchV2Module { }
