import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import State from 'map/state';
import { ADMIN_ID, OPERATOR_ID } from 'src/config';
import { TEXTS } from 'src/texts/texts';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { NotificationSubscribeType } from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';
import { NOTIFIACTIONS_CONFIG } from 'projects/cityscreen/src/modules/notifications/notifications.settings';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';
import { ClosePopupsHelper } from 'projects/cityscreen/src/modules/notifications/components/subscription-card/subscription-card.component';

const DEFAULT_CONFIG = Object.assign({}, NOTIFIACTIONS_CONFIG, {
    type: NotificationSubscribeType.ServiceDevice
});

const SUGGESTED_CONFIG = Object.assign({}, DEFAULT_CONFIG, {
    title: TEXTS.NOTIFICATIONS.serviceNotifications,
    serviceNoData: 3,
    serviceNoPower: 3,
    serviceVerification: 14
});

@Component({
    selector: 'subscriptions-service',
    templateUrl: 'subscriptions-service.component.html'
})
export class SubscriptionsService extends ClosePopupsHelper implements OnInit {
    @Input() scrolled: Observable<Event>;

    suggestedSubscription: NotificationSubscription;

    textsNotification = TEXTS.NOTIFICATIONS;
    emailsList: string[] = [];

    popupPositionTop = 0;
    popupActive = false;

    constructor(
        public stateService: NotificationsStateService,
        private globalState: State
    ) {
        super();
    }

    ngOnInit() {
        const { email, userId } = this.globalState.adminPanel.iAm;
        const adminsAndOperators = this.globalState.adminPanel.users
            .filter(u => u.roleId === ADMIN_ID || u.roleId === OPERATOR_ID)
            .map(u => u.userId);

        const userDefaults = {
            groupId: this.globalState.adminPanel.getCurrentGroup().id,
            emailsList: [email],
            forUserIds: [userId, ...adminsAndOperators]
        };

        Object.assign(DEFAULT_CONFIG, userDefaults);
        Object.assign(SUGGESTED_CONFIG, userDefaults);

        this.stateService.lists.selected$.subscribe(ss => {
            if (!ss.length) {
                this.makeSuggestion();
            }
        });

        this.scrolled.subscribe((e) => this.onScroll(e));
    }

    makeSuggestion() {
        this.suggestedSubscription = new NotificationSubscription();
        Object.assign(this.suggestedSubscription, SUGGESTED_CONFIG);
        this.suggestedSubscription.setSuggested();
    }

    addSubscription() {
        const subscription = new NotificationSubscription();
        Object.assign(subscription, DEFAULT_CONFIG);
        this.editSubscription(subscription);
    }

    editSubscription(subscription: NotificationSubscription) {
        this.stateService.openSettings(subscription);
    }
}
