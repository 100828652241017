import { Component, OnInit } from '@angular/core';
import { PostsAndDevicesFacade } from 'projects/cityscreen/src/modules/posts-and-devices/posts-and-devices.facade';
import { TEXTS } from 'src/texts/texts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminDevice, CheckboxItem } from 'src/namespace';
import { CreateOM, CreateDevice, PAGES } from './../../namespace';
import * as moment from 'moment';
import {
    ANIMATION_MAX_HEIGHT,
    LOAD_HISTORY_DEFAULT
} from 'projects/shared/utils/config';
import {
    selectDevices,
    selectGroupCities,
    selectMyDevices
} from 'projects/cityscreen/src/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { CITYAIR_DEVICE_ID } from 'src/config';
import AdminPanelActions
    from 'projects/cityscreen/src/components/admin-panel/actions';
import AdminPanelState
    from 'projects/cityscreen/src/components/admin-panel/state';

export class PATH {
    name: string;
    path: string;
}

export class DateRange {
    timeBegin: number;
    timeEnd: number;
}

export interface ErrorModel {
    name?: string;
    schedule_period?: string;
}


@Component({
    selector: 'post-create',
    templateUrl: './post-create.component.html',
    styleUrls: ['./post-create.component.less'],
    animations: ANIMATION_MAX_HEIGHT
})
export class PostCreateComponent implements OnInit {

    TEXTS = TEXTS;
    pages = PAGES;
    cityAirDeviceId = CITYAIR_DEVICE_ID;
    PostCreate: FormGroup;
    errorObj: ErrorModel = {};
    devicesList: AdminDevice[] = null;

    navigationLink: PATH[] = [{
        name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
        path: this.pages.networks + '/' + this.pages.postsList
    }, {
        name: TEXTS.POSTS_AND_DEVICES.newMO,
        path: null
    }];

    newMo = new CreateOM();
    lastTime$;
    isLoader = false;
    getCities: CheckboxItem[] = []
    selectedCity: CheckboxItem = {
        id: null,
        label: null
    };
    isShowDropdownForStations = false;
    isShowDropdownForLocation = false;
    selectedStation: AdminDevice = new CreateDevice();
    dateRange: DateRange = {
        timeBegin: null,
        timeEnd: null
    };

    devicesListForCheckbox: CheckboxItem[] = [];

    constructor(
        private fb: FormBuilder,
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        public store: Store<any>,
        public apActions: AdminPanelActions,
        public apState: AdminPanelState
    ) {
        this.dateRange.timeBegin = this.getStndTimeBegin();
        this.dateRange.timeEnd = this.getStndTimeEnd();
        this.apState.selectedDevices = [];
        this.store.select(selectMyDevices)
            .pipe(filter(smd => !!smd?.length))
            .subscribe(smd => {
                this.devicesList = smd;
                smd.forEach(s => {
                    if (s.sourceId == this.cityAirDeviceId) {
                        this.devicesListForCheckbox.push({
                            id: s.id,
                            label: s.serialNumber,
                            selected: false
                        })
                    }
                })
            })

        this.store.select(selectGroupCities)
            .pipe(filter(sgc => !!sgc.length))
            .subscribe(sgc => {
                this.getCities = sgc.map(s => ({
                        id: s.locationId,
                        idNumber: s.locationId,
                        label: s.name,
                        selected: false
                    }))
            })
        this.getCities.sort(this.byField('label'));
    }

    ngOnInit() {
        this.PostCreate = this.fb.group({
            name: [null, [Validators.required]],
            description: [null, [Validators.required]],
            geoLatitude: [this.selectedStation.geoLatitude, [Validators.required]],
            geoLongitude: [this.selectedStation.geoLongitude, [Validators.required]]
        });
    }

    byField(field) {
        return (a, b) => a[field] > b[field] ? 1 : -1;
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    get name() {
        return this.PostCreate.get('name');
    }

    get description() {
        return this.PostCreate.get('description');
    }

    get geoLatitude() {
        return this.PostCreate.get('geoLatitude');
    }

    get geoLongitude() {
        return this.PostCreate.get('geoLongitude');
    }

    onSubmit = async () => {
        this.isLoader = true
        this.createObject();
        this.apActions.createMo(this.newMo);
        setTimeout(() => {
            this.openPage(this.navigationLink[0].path);
            this.isLoader = false
        }, 3000)
    }

    createObject() {
        this.newMo.name = this.name.value;
        this.newMo.locationId = this.selectedCity.idNumber;
        this.newMo.description = this.description.value;
        this.newMo.geoLatitude = this.geoLatitude.value;
        this.newMo.geoLongitude = this.geoLongitude.value;
    }

    validationCreateMo() {
        return this.name.value != null && this.selectedCity.idNumber != null && this.selectedStation.id != null;
    }

    getSelectedStation(stations: CheckboxItem[]) {
        this.selectedStation = this.devicesList.find(dl => dl.id === stations.find(s => s.selected == true).id);
        this.lastTime$ = this.store.select(selectDevices).pipe(
            filter(d => !!d[this.selectedStation.serialNumber]),
            map(d => d[this.selectedStation.serialNumber].lastTime)
        )
        this.PostCreate.patchValue({
            geoLatitude: this.selectedStation.geoLatitude,
            geoLongitude: this.selectedStation.geoLongitude
        });
        this.apState.selectedDevices.push(this.selectedStation)
    }

    getSelectedCity(city: CheckboxItem[]) {
        this.selectedCity = city.find(c => c.selected == true)
    }

    getDateBeginRangeFromCalendar(time) {
        console.log(time)
    }

    getDateEndRangeFromCalendar(time) {
        console.log(time)
    }

    getStndTimeBegin: () => number = () => moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

    getStndTimeEnd: () => number = () => {
        const m = moment();
        m.startOf('minutes')
        m.subtract(m.get('minutes') % 20, 'minutes') // floor of 20 minutes
        return m.valueOf();
    }

    openPage(pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }
}
