<div>
    <ng-container *ngIf="sharedCoreFacade.getAllowForecast() || sharedCoreFacade.getModuleConfig().enablePlumeButton">
        <ca-layer-button
            class="layer_button"
            *ngIf="state.adminPanel.namePageOpen === 'plumes'; else forecastButton"
            [type]="LayerButtonIconType.PLUME"
            [active]="sharedCoreFacade.getIsShowPlume()"
            (clickOnIcon)="sharedCoreFacade.getIsShowPlume() ? offPlume() : onPlume()"
        >
            <ca-layer-button-item
                [label]="TEXTS.NAMES[currentMeasure] || currentMeasure"
                [active]="true"
            ></ca-layer-button-item>
        </ca-layer-button>

        <ng-template #forecastButton>
            <ca-layer-button
                class="layer_button"
                *ngIf="sharedCoreFacade.getIsCityMod()"
                [type]="LayerButtonIconType.FORECAST"
                [active]="sharedCoreFacade.getIsActiveModelling()"
                (clickOnIcon)="sharedCoreFacade.getIsActiveModelling() ? disableModelling() : enableModelling()"
            >
                <ca-layer-button-item
                    *ngFor="let opt of MEASURES_FOR_FORECAST"
                    [label]="TEXTS.NAMES[opt]"
                    [active]="state.map.showingModelMeasure === opt"
                    (click)="forecastControlService.updateProps({
                        measure: opt,
                        contours: false,
                        overlay: true
                    })"
                ></ca-layer-button-item>
            </ca-layer-button>
        </ng-template>
    </ng-container>
</div>
