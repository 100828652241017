import MapState from './mapState';
import { MapActionsInterface } from './mapActionsInterface';
import { MapActionsWrapper, MapStateWrapper } from './wrapper';

export default class NullMapActions implements MapActionsInterface {
    mapState: MapState;
    globalState: MapStateWrapper;
    globalActions: MapActionsWrapper;

    constructor(state: MapStateWrapper, actions: MapActionsWrapper) {
        this.globalState = state;
        this.globalActions = actions;
        this.mapState = state.map;
    }

    isModelVisible() {
        return false;
    }

    isPlumeVisible() {
        return false;
    }

    updateAllMarkersIcon() {}
    centringMap() {}
    centringOnMarker() {}
    gotoLocation() {}
    mapLoaded() {}
    mapOnDragEnd() {}
    zoomChanges() {}
    checkTooltipOver() {}
    markerOver() {}
    clickToTheCity() {}
    clickToMarker() {}
    clickForMap() {}
}
