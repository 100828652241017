import { IDataProvider, ErrorResult } from "../../models";
import * as Models from "./devicesApiModels";

export class DevicesApiProvider implements Models.IDevicesApiProvider {
    cpController: string = "DevicesApi2";

    constructor(private readonly dataProvider: IDataProvider) {
    }

    getDevices(request: Models.DataApiDevicesRequest, execOk: (response: Models.DataApiDevicesResponse) => void, execFail: (errRes: ErrorResult) => void = null) {
        this.dataProvider.execHttp(this.cpController, "GetDevices", request, execOk, execFail);
    }

    updateDeviceItem = (request: Models.UpdateDeviceRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void) => {
        this.dataProvider.execHttp(this.cpController, "UpdateDeviceItem", request, execOk, execFail);
    }

    getPackets(request: Models.DataApiPacketsRequest, execOk: (response: Models.DataApiPacketsResponse) => void, execFail: (errRes: ErrorResult) => void) {
        this.dataProvider.execHttpEx(this.cpController, "GetPackets", request, false, true, execOk, execFail);
    }

    exportDataToBase64 = (request: Models.ExportDeviceDataRequest, execOk: (base64: string) => void, execFail: (errRes: ErrorResult) => void) => {
        this.dataProvider.execHttp(this.cpController, "ExportDataToBase64", request, execOk, execFail);
    }

    updateOnServiceFlagOnDevicePackets = (request: Models.UpdateOnServiceFlagRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void) => {
        this.dataProvider.execHttp(this.cpController, "UpdateOnServiceFlagOnDevicePackets", request, execOk, execFail);
    }
}
