<ng-template #markerTooltip>
    <ca-info-tooltip
        [text]="globalActions.mapActions.mapState.tooltip.text"
        [description]="globalActions.mapActions.mapState.tooltip.lastData"
    ></ca-info-tooltip>
</ng-template>

<mgl-map
    class="mapbox-map"
    movingMethod="jumpTo"
    [style.visibility]="showMap ? 'visible' : 'hidden'"
    [style]="style"
    [zoom]="[mapSettings.zoom]"
    [minZoom]="mapSettings.minzoom"
    [maxZoom]="mapSettings.maxzoom"
    [center]="mapSettings.center"
    [maxBounds]="mapSettings.bounds"
    [transformRequest]="authorizeTileRequest"
    [pitchWithRotate]="false"
    [dragRotate]="false"
    [renderWorldCopies]="true"
    [attributionControl]="false"
    (load)="mapboxLoad($event)"
    (zoomEvt)="onZoom($event.target.getZoom())"
    (dragEnd)="gmActions.mapOnDragEnd()"
>
    <mgl-control mglNavigation [showZoom]="true" [showCompass]="false"></mgl-control>

    <mgl-control mglAttribution
        [compact]="true"
        [customAttribution]="'© CityAir'"
    ></mgl-control>

    <!-- Forecast -->
    <ng-container *ngIf="isForecastLayerVisible() && domainTilesPlayer.imageUrl$ | async as url">
        <ng-container *ngIf="domainTilesPlayer.config as cfg">
            <mgl-image-source
                [id]="cfg.domain.slug"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                [id]="cfg.domain.slug"
                [source]="cfg.domain.slug"
                [minzoom]="cfg.minzoom"
                [maxzoom]="cfg.maxzoom"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>

    <!-- Custom layers -->
    <ng-container *ngFor="let layer of sharedCoreFacade.enabledExtraLayers">
        <mgl-raster-source
            [id]="layer.id"
            [tiles]="layer.source.tiles"
            [tileSize]="layer.source.tileSize"
            [minzoom]="mapSettings.minzoom"
            [maxzoom]="mapSettings.maxzoom"
        ></mgl-raster-source>
        <mgl-layer
            [id]="layer.id"
            [type]="layer.source.type"
            [source]="layer.id"
        ></mgl-layer>
    </ng-container>

    <ng-container *ngFor="let layer of tilePlayers.default?.layersWindow?.window; index as i">
        <ng-container *ngIf="isGreaterThanMinZoom(layer.source.minzoom)">
            <mgl-raster-source
                [id]="layer.id"
                [tiles]="layer.source.tiles"
                [tileSize]="layer.source.tileSize"
                [bounds]="layer.source.bounds"
                [minzoom]="layer.source.minzoom"
                [maxzoom]="layer.source.maxzoom"
            ></mgl-raster-source>
            <mgl-layer
                [id]="layer.id"
                [type]="layer.source.type"
                [source]="layer.id"
                [paint]="{
                    'raster-opacity': layer.opacity,
                    'raster-fade-duration': timeStep
                }"
            ></mgl-layer>

            <domain-border
                *ngIf="i === 0 && (tilePlayers.default.layers | slice : -1)[0] as lastLayer"
                [bbox]="lastLayer?.source.bounds"
            ></domain-border>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let layerId of sharedCoreFacade.enabledAnimatedExtraLayers">
        <ng-container *ngFor="let layer of tilePlayers[layerId]?.layersWindow?.window">
            <mgl-raster-source
                [id]="layer.id"
                [tiles]="layer.source.tiles"
                [tileSize]="layer.source.tileSize"
                [bounds]="layer.source.bounds"
                [minzoom]="layer.source.minzoom"
                [maxzoom]="layer.source.maxzoom"
            ></mgl-raster-source>
            <mgl-layer
                [id]="layer.id"
                [type]="layer.source.type"
                [source]="layer.id"
                [paint]="{
                    'raster-opacity': layer.opacity,
                    'raster-fade-duration': 0
                }"
                before="settlement-subdivision-label"
            ></mgl-layer>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!mapState.notifiableMos; else notifications">

    <!-- Group layers -->
    <ng-container *ngIf="groupFeaturesLayer">
        <mgl-geojson-source
            id="group-features-json"
            [data]="groupFeaturesLayer"
        ></mgl-geojson-source>
        <mgl-layer
            id="group-features-layer-line"
            [type]="'line'"
            source="group-features-json"
            [paint]="{
                'line-color': ['get', 'color'],
                'line-opacity': ['get', 'opacity'],
                'line-width': 2
            }"
        ></mgl-layer>
    </ng-container>

    <!-- Marker area -->
    <ng-container *ngIf="showMarkersArea">
        <!-- Maximum buffer size fixes overlapping artifacts at the maximum zoom level (mostly). -->
        <mgl-geojson-source
            id="markers-area-data"
            [data]="markersData"
            [buffer]="512"
        >
        </mgl-geojson-source>
        <mgl-layer
            id="markers-area"
            type="fill"
            source="markers-area-data"
            [paint]="{
                'fill-color': ['get', 'color'],
                'fill-opacity': ['get', 'opacity'],
                'fill-outline-color': 'transparent'
            }"
        >
        </mgl-layer>
    </ng-container>

    <!-- Markers inside the city -->
    <ng-container *ngIf="sharedCoreFacade.getIsCityMod()">
        <mgl-marker
            *ngFor="let marker of mapState.myMarkers | markerFilter: true"
            [className]="[
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global',
                markerIsSelected(marker.id) ? 'map__mapbox-marker--global-selected' : ''
            ].join(' ')"
            [lngLat]="[marker.lng, marker.lat]"
        >
            <div class="map__mapbox-marker__pin"
                [ngClass]="{
                    'map__mapbox-marker__pin--active': markerIsSelected(marker.id)
                }"
                *ngIf="marker.opacity !== 0"
                (click)="gmActions.clickToMarker(marker)"
            >
                <marker-icon
                    [marker]="marker"
                    [selected]="markerIsSelected(marker.id) || isInSubscription(marker.id)"
                    [canAdd]="sharedCoreFacade.getIsCompareMod() && !sharedCoreFacade.isComparedListLimited() || !!mapState.notifiableMos"
                    [canNotify]="isInSubscription(marker.id)"
                    (mouseenter)="gmActions.markerOver(marker, !isTouchDevice)"
                    (mouseleave)="gmActions.markerOver(marker, false)"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="markerTooltip"
                >
                </marker-icon>
            </div>
        </mgl-marker>
        <mgl-marker
            *ngFor="let marker of mapState.moMarkers"
            [className]="[
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global',
                markerIsSelected(marker.id) ? 'map__mapbox-marker--global-selected' : ''
            ].join(' ')"
            [lngLat]="[marker.lng, marker.lat]"
        >
            <div class="map__mapbox-marker__pin"
                [ngClass]="{
                    'map__mapbox-marker__pin--active': markerIsSelected(marker.id)
                }"
                *ngIf="marker.opacity !== 0"
            >
                <marker-icon
                    [marker]="marker"
                    [selected]="markerIsSelected(marker.id) || isInSubscription(marker.id)"
                    [canAdd]="sharedCoreFacade.getIsCompareMod() && !sharedCoreFacade.isComparedListLimited() || !!mapState.notifiableMos"
                    [canNotify]="isInSubscription(marker.id)"
                    (mouseenter)="gmActions.markerOver(marker, !isTouchDevice)"
                    (mouseleave)="gmActions.markerOver(marker, false)"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="markerTooltip"
                    (click)="gmActions.clickToMarker(marker)"
                >
                </marker-icon>
            </div>
        </mgl-marker>
    </ng-container>

    <!-- My markers in the countryside -->
    <mgl-marker
        *ngFor="let marker of mapState.myMarkers | markerFilter: false"
        [className]="[
            'mapboxgl-marker',
            'mapboxgl-marker-anchor-center',
            'map__mapbox-marker--global',
            markerIsSelected(marker.id) ? 'map__mapbox-marker--global-selected' : ''
        ].join(' ')"
        [lngLat]="[marker.lng, marker.lat]"
    >
        <div class="map__mapbox-marker__pin"
            [ngClass]="{
                'map__mapbox-marker__pin--active': markerIsSelected(marker.id)
            }"
            *ngIf="marker.opacity !== 0"
        >
            <marker-icon
                [marker]="marker"
                [selected]="markerIsSelected(marker.id) || isInSubscription(marker.id)"
                [canAdd]="sharedCoreFacade.getIsCompareMod() && !sharedCoreFacade.isComparedListLimited() || !!mapState.notifiableMos"
                [canNotify]="isInSubscription(marker.id)"
                (mouseenter)="gmActions.markerOver(marker, !isTouchDevice)"
                (mouseleave)="gmActions.markerOver(marker, false)"
                caTooltip
                caTooltipPlacement="left"
                [caTooltipFollowPointer]="true"
                [caTooltipTemplate]="markerTooltip"
                (click)="gmActions.clickToMarker(marker)"
            >
            </marker-icon>
        </div>
    </mgl-marker>

    <!-- City markers -->
    <ng-container *ngIf="!sharedCoreFacade.getIsCityMod()">
        <mgl-marker #cityMarkers
            *ngFor="let marker of mapState.citiesMarkers"
            [className]="[
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global',
                'map__mapbox-marker--global-city',
                markerIsSelected(marker.id) ? 'map__mapbox-marker--global-selected' : ''
            ].join(' ')"
            [lngLat]="[marker.lng, marker.lat]"
        >
            <city-marker
                [marker]="marker"
                [isSelected]="markerIsSelected(marker.id)"
                [canSelected]="!markerIsSelected(marker.id) && sharedCoreFacade.getIsCompareMod()"
                (mouseenter)="gmActions.markerOver(marker, !isTouchDevice)"
                (mouseleave)="gmActions.markerOver(marker, false)"
                (click)="gmActions.clickToTheCity(marker)"
            >
            </city-marker>
        </mgl-marker>
    </ng-container>
    </ng-container>

    <!-- Markers selectable in notifications -->
    <ng-template #notifications>
        <mgl-marker
            *ngFor="let marker of notifiableMarkers"
            [className]="[
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global',
                markerIsSelected(marker.id) ? 'map__mapbox-marker--global-selected' : ''
            ].join(' ')"
            [lngLat]="[marker.lng, marker.lat]"
        >
            <div class="map__mapbox-marker__pin"
                [ngClass]="{
                    'map__mapbox-marker__pin--active': markerIsSelected(marker.id)
                }"
                *ngIf="marker.opacity !== 0"
            >
                <marker-icon
                    [marker]="marker"
                    [selected]="markerIsSelected(marker.id) || isInSubscription(marker.id)"
                    [canAdd]="true"
                    [canNotify]="isInSubscription(marker.id)"
                    (mouseenter)="gmActions.markerOver(marker, !isTouchDevice)"
                    (mouseleave)="gmActions.markerOver(marker, false)"
                    caTooltip
                    caTooltipPlacement="left"
                    [caTooltipFollowPointer]="true"
                    [caTooltipTemplate]="markerTooltip"
                    (click)="gmActions.clickToMarker(marker)"
                >
                </marker-icon>
            </div>
        </mgl-marker>
    </ng-template>

    <!-- Run Sources -->
    <ng-container *ngIf="plumesFacadeService.isEnabled$ | async">
        <mgl-marker
            *ngFor="let source of runSources$ | async"
            [className]="[
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global'
            ].join(' ')"
            [lngLat]="[source.centroid.coordinates[0], source.centroid.coordinates[1]]"
        >
            <plume-source-marker [name]="source.name"></plume-source-marker>
        </mgl-marker>

        <!-- Control points -->
        <mgl-marker
            *ngFor="let point of plumesFacadeService.allPoints$ | async"
            [className]="[
                'mapboxgl-marker',
                'mapboxgl-marker-anchor-center',
                'map__mapbox-marker--global'
            ].join(' ')"
            [lngLat]="[point.lon, point.lat]"
        >
            <control-point-marker
                [value]="plumesFacadeService.uiState.getPointValue(point.id)"
                [units]="TEXTS.measures.get(plumesFacadeService.getCurrentMeasure())"
                [isSelected]="point.id === plumesFacadeService.getActiveControlPointId()"
                (click)="openControlPointChart(point)"
            ></control-point-marker>
        </mgl-marker>
    </ng-container>

    <!-- Plumes -->
    <ng-container *ngIf="isPlumesLayerVisible() && domainTilesPlumesPlayer.imageUrl$ | async as url">
        <ng-container *ngIf="domainTilesPlumesPlayer.config as cfg">
            <mgl-image-source
                id="plumes-data"
                [url]="url"
                [coordinates]="cfg.domain.coordinates"
            ></mgl-image-source>
            <mgl-layer
                type="raster"
                id="plumes-layer"
                source="plumes-data"
                [paint]="{
                    'raster-opacity': cfg.opacity,
                    'raster-fade-duration': 0
                }"
            ></mgl-layer>
            <domain-border *ngIf="url" [coordinates]="cfg.domain.coordinates"></domain-border>
        </ng-container>
    </ng-container>
</mgl-map>
