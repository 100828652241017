/**
 * This method is obsolete in Chart.js v3
 * TODO: Replace with scriptable align options after update.
 */

import { Chart } from 'chart.js';
import {
    getBaseModel,
    createTooltipItem,
    getTooltipSize,
    determineAlignment,
    getBackgroundPoint,
} from './plugin-chart-utils';

// @ts-ignore
const originalTooltipUpdate = Chart.Tooltip.prototype.update;

export function replaceTooltipUpdate() {
    function customTooltipUpdate(changed: boolean) {
        const me = this;
        const opts = me._options;

        // Need to regenerate the model because its faster than using extend and it is necessary due to the optimization in Chart.Element.transition
        // that does _view = _model if ease === 1. This causes the 2nd tooltip update to set properties in both the view and model at the same time
        // which breaks any animations.
        const existingModel = me._model;
        const model = me._model = getBaseModel(opts) as any;
        const active = me._active;

        const data = me._data;

        // In the case where active.length === 0 we need to keep these at existing values for good animations
        let alignment = {
            xAlign: existingModel.xAlign,
            yAlign: existingModel.yAlign
        };
        let backgroundPoint = {
            x: existingModel.x,
            y: existingModel.y
        };
        let tooltipSize = {
            width: existingModel.width,
            height: existingModel.height
        };
        let tooltipPosition = {
            x: existingModel.caretX,
            y: existingModel.caretY
        };

        let i; let len;

        if (active.length) {
            model.opacity = 1;

            const labelColors = [];
            const labelTextColors = [];
            tooltipPosition = Chart.Tooltip.positioners[opts.position].call(me, active, me._eventPosition);

            let tooltipItems = [];
            for (i = 0, len = active.length; i < len; ++i) {
                tooltipItems.push(createTooltipItem(active[i]));
            }

            // If the user provided a filter function, use it to modify the tooltip items
            if (opts.filter) {
                tooltipItems = tooltipItems.filter((a) => opts.filter(a, data));
            }

            // If the user provided a sorting function, use it to modify the tooltip items
            if (opts.itemSort) {
                tooltipItems = tooltipItems.sort((a, b) => opts.itemSort(a, b, data));
            }

            // Determine colors for boxes
            Chart.helpers.each(tooltipItems, (tooltipItem) => {
                labelColors.push(opts.callbacks.labelColor.call(me, tooltipItem, me._chart));
                labelTextColors.push(opts.callbacks.labelTextColor.call(me, tooltipItem, me._chart));
            });

            // Build the Text Lines
            model.title = me.getTitle(tooltipItems, data);
            model.beforeBody = me.getBeforeBody(tooltipItems, data);
            model.body = me.getBody(tooltipItems, data);
            model.afterBody = me.getAfterBody(tooltipItems, data);
            model.footer = me.getFooter(tooltipItems, data);

            // Initial positioning and colors
            model.x = tooltipPosition.x;
            model.y = tooltipPosition.y;
            model.caretPadding = opts.caretPadding;
            model.labelColors = labelColors;
            model.labelTextColors = labelTextColors;

            // data points
            model.dataPoints = tooltipItems;

            // We need to determine alignment of the tooltip
            tooltipSize = getTooltipSize(this, model);
            alignment = determineAlignment(this, tooltipSize);
            // PATCH: restirct xAlign to be either right or left
            alignment.xAlign = model.x > this._chart.width / 2 ? 'right' : 'left';
            // Final Size and Position
            backgroundPoint = getBackgroundPoint(model, tooltipSize, alignment, me._chart);
        } else {
            model.opacity = 0;
        }

        model.xAlign = alignment.xAlign;
        model.yAlign = alignment.yAlign;
        model.x = backgroundPoint.x;
        model.y = backgroundPoint.y;
        model.width = tooltipSize.width;
        model.height = tooltipSize.height;

        // Point where the caret on the tooltip points to
        model.caretX = tooltipPosition.x;
        model.caretY = tooltipPosition.y;

        me._model = model;

        if (changed && opts.custom) {
            opts.custom.call(me, model);
        }

        return me;
    }

    function update(changed: boolean) {
        const { customAlignTooltip } = this._chart.config.options;

        if (customAlignTooltip) {
            customTooltipUpdate.bind(this)(changed);
        } else {
            originalTooltipUpdate.bind(this)(changed);
        }
    }

    // @ts-ignore
    Chart.Tooltip.prototype.update = update;
}
