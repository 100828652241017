import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingComponent } from './onboarding.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ButtonSimpleModule } from '../button-simple/button-simple.module';
import { SlideIndicatorModule } from '../slide-indicator/slide-indicator.module';

@NgModule({
    imports: [
        CommonModule,
        CheckboxModule,
        ButtonSimpleModule,
        SlideIndicatorModule,
    ],
    exports: [
        OnboardingComponent,
    ],
    declarations: [
        OnboardingComponent,
    ],
})
export class OnboardingModule {}
