<div class="dashboard-chart">
    <canvas [attr.width]="width" height="96"
        baseChart
        chartType="line"
        [datasets]="lineChartData"
        [options]="lineChartOptions"
        [legend]="lineChartLegend"
        [labels]="lineChartLabels"
        caTooltip
        [caTooltipTemplate]="pointTooltip"
        [caTooltipFollowPointer]="true"
        [style.cursor]="chartTooltip.model ? 'pointer' : 'auto'"
    ></canvas>
</div>
<ng-template #pointTooltip>
    <packets-tooltip
        *ngIf="chartTooltip.model"
        [data]="chartTooltip.model"
    ></packets-tooltip>
</ng-template>
