import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';

const DEFAULT_OPTIONS: ChartOptions = {
    responsive: false,
    tooltips: {
        enabled: false
    },
    legend: {
        display: false
    },
    cutoutPercentage: 66,
    elements: {
        arc: {
            borderWidth: 0
        }
    },
    hover: {
        animationDuration: 0
    },
    animation: {
        animateScale: false
    }
};

const WITH_VALUE_SHOWN_OPTIONS = {
    ...DEFAULT_OPTIONS,
    cutoutPercentage: 76
};

@Component({
    selector: 'dashboard-doughnut',
    templateUrl: 'dashboard-doughnut.component.html',
    styleUrls: ['dashboard-doughnut.component.less']
})
export class DashboardDoughnutComponent implements OnInit {
    @Input() chart: {
        size: number;
        data: number[];
        labels: string[];
        labelsBg: string[];
        plugins: Chart.PluginServiceRegistrationOptions[];
    };

    @Input() showLegend = true;
    @Input() showMainValue = false;

    options = DEFAULT_OPTIONS;

    ngOnInit() {
        this.options = WITH_VALUE_SHOWN_OPTIONS;
    }
}
