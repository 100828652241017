import { CalendarState } from './CalendarState';
import { createCalendarDays } from './dataModel';
import * as moment from 'moment';

export class CalendarActions {
    calendar: CalendarState;
    updateTime: (begin: number, end: number, allowUpdate: boolean) => void;

    constructor(calendar, updateTime) {
        this.calendar = calendar;
        this.updateTime = updateTime;

        this.calendar.months = createCalendarDays(0);
    }

    private _reset() {
        this.calendar.begin = this.calendar.end = null;
        this.calendar.finish = false;
    }

    dayClick = (timestamp: number, allowUpdate: boolean, tzOffset: number) => {
        const c = this.calendar;
        c.isMainTimePriority = false;
        if (c.finish)
            this._reset();

        if (!c.begin) { // первый клик
            c.begin = c.end = timestamp;
            return;
        }

        c.end = timestamp;
        c.finish = true;

        if (c.begin > c.end) {
            c.end = c.begin;
            c.begin = timestamp;
        }

        c.end = moment(c.end).utcOffset(tzOffset || 0).endOf('day').valueOf();
        c.begin = moment(c.begin).utcOffset(tzOffset || 0).startOf('day').valueOf();

        this.apply(allowUpdate);
    };

    dayOver = (timestamp: number) => {
        const c = this.calendar;
        if (c.finish)
            return;
        c.end = timestamp;
    };

    apply = (allowUpdate: boolean) => {
        if (!this.calendar.begin || !this.calendar.end) {
            return;
        }

        const begin = this.calendar.begin;
        const end = this.calendar.end;

        this.updateTime(begin, end, allowUpdate);

        this.calendar.showCalendar = false;
        this.calendar.finish = true;
        this.calendar.isMainTimePriority = true;

        this.calendar.begin = this.calendar.end = null;
    };

    updateDays = (tzOffset: number = 0) => {
        this.calendar.months = createCalendarDays(tzOffset);
        this.calendar.monthsMobile = this.calendar.months.slice().reverse();
    }
}
