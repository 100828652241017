import { NgModule } from '@angular/core';

import { InfoIconModule } from './components/info-icon/info-icon.module';
import { ArrowModule } from './components/arrow/arrow.module';

@NgModule({
    exports: [
        InfoIconModule,
        ArrowModule,
    ]
})
export class IconsModule {}
