import { Component } from '@angular/core';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';

@Component({
    selector: 'cityscreen-mobile-header',
    template: `

        <cityscreen-mobile-profile></cityscreen-mobile-profile>

        <calendar-m (updateDateRange)="updateDateRange($event)"></calendar-m>
        <cityscreen-aqi-legend-mobile *ngIf="sharedCoreFacade.getIsCityMod()"></cityscreen-aqi-legend-mobile>
    `,
    styleUrls: ['./mobile-header.component.less']
})
export class MobileHeaderComponent {
    constructor(public sharedCoreFacade: SharedCoreFacade, private coreFacade: CoreFacade, private time: Time) {
    }

    updateDateRange(data: {begin: number, end: number, allowUpdate: boolean}) {
        this.time.intervalUpdate.next(data);
    }
}
