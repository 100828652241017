<div class="analytic scroll">
    <div class="analytic__title">{{TEXTS.CITY_CARD.cityProfile}}</div>
    <div class="analytic__name">{{analyticData?.name}}</div>
    <div *ngIf="analyticData" class="analytic__charts_wrapper">
        <cs-analytic [distributionSummary]="analyticData.distributionSummary" [measure]="IAQ"></cs-analytic>
    </div>
    <div *ngIf="!analyticData" class="analytic__placeholder">
        <div class="analytic__placeholder-text">{{TEXTS.INDOOR.selectRoom}}</div>
        <div class="analytic__placeholder-img"></div>
    </div>

    <analytic-loader *showDirective="loading"></analytic-loader>
</div>

