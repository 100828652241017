import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgLocalization } from '@angular/common';
import { debounceTime } from 'rxjs/operators';

import { UsersStateService } from '../../users-state.service';
import { TEXTS } from 'src/texts/texts';
import { MonitoringObject, UserItems } from 'src/namespace';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import AdminPanelState from 'projects/cityscreen/src/components/admin-panel/state';
import AdminPanelActions from 'projects/cityscreen/src/components/admin-panel/actions';
import State from 'map/state';
import { copyObj, differentObject } from 'src/utils';
import { selectedPostsText } from 'projects/cityscreen/src/modules/notifications/notifications.utils';
import {
    MessageAPIResponseService
} from 'src/little-components/message-api-response/message-api-response.service';

import './edit-user.component.less';

@Component({
    selector: 'edit-user',
    templateUrl: 'edit-user.component.html'
})
export class EditUser implements OnInit {
    TEXTS = TEXTS;
    usersControl: {
        login: FormControl,
        email: FormControl
    };

    notShowTooltip = true;
    showUsersRole = false;
    saving = false;

    isEditMode = false;
    isUserFound = null;
    newUserProps: UserItems;

    apState: AdminPanelState;

    constructor(
        public apActions: AdminPanelActions,
        globalState: State,
        private adminPanelApi: AdminPanelApi,
        private ngLocalization: NgLocalization,
        public stateService: UsersStateService,
        private msgService: MessageAPIResponseService
    ) {
        this.apState = globalState.adminPanel;
    }

    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);

    ngOnInit() {
        if (this.stateService.currentUser) {
            this.newUserProps = copyObj(this.stateService.currentUser);
            this.isEditMode = true;
        }

        this.usersControl = {
            login: new FormControl(
                {
                    value: this.stateService.currentUser?.login,
                    disabled: true
                },
                [
                    this.usernameValidator,
                    Validators.minLength(1)
                ]
            ),
            email: new FormControl(
                {
                    value: this.stateService.currentUser?.email,
                    disabled: !!this.stateService.currentUser
                },
                [
                    this.emailInGroupValidator,
                    Validators.minLength(5),
                    Validators.email
                ]
            )
        };

        this.usersControl.email.valueChanges
        .pipe(
            debounceTime(300)
        )
        .subscribe(async (value) => {
            if (value && this.usersControl.email.valid) {
                const user = await this.adminPanelApi.haveTheUser(value);

                if (user) {
                    this.newUserProps = {
                        ...new UserItems(),
                        ...user,
                        roleId: 2
                    };
                    this.usersControl.login.setValue(user.login);
                    this.usersControl.login.disable();

                    this.isUserFound = true;
                } else {
                    this.newUserProps = new UserItems();
                    this.newUserProps.email = value;
                    this.newUserProps.login = value.substring(0, value.indexOf('@'));
                    this.newUserProps.roleId = 2;
                    this.usersControl.login.setValue(this.newUserProps.login);
                    this.usersControl.login.enable();

                    this.isUserFound = false;
                }
            }
        })

        this.usersControl.login.valueChanges.subscribe(value => {
            if (value && this.usersControl.login.valid) {
                this.newUserProps.login = value;
            }
        })
    }

    emailInGroupValidator = (formControl: FormControl) => {
        return this.apState.users.find(u => u.email === formControl.value) ?
            {emailInGroupValidator: {message: 'user in group'}} : null;
    }

    usernameValidator = (formControl: FormControl) => {
        return formControl.value.match(/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~\.-]*$/) ?
            null : {usernameValidator: {message: 'error usernameValidator'}};
    }

    updateUsersMO = (mos: MonitoringObject[]) => {
        this.newUserProps.linksToMo = mos;
    }

    toggleUsersRole(e: MouseEvent) {
        e.stopPropagation();
        this.showUsersRole = !this.showUsersRole;
    }

    hideUserRoles() {
        this.showUsersRole = false;
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    checkChangeRole = () => this.newUserProps?.roleId !== this.stateService.currentUser?.roleId;

    checkChangeMos = () => differentObject(this.newUserProps?.linksToMo, this.stateService.currentUser?.linksToMo, true);

    apply = async () => {
        this.saving = true;

        const respSuccess = await this.apActions.applyUserUpdates(
            this.newUserProps,
            this.isEditMode,
            this.checkChangeRole(),
            this.checkChangeMos()
        );

        this.saving = false;

        if (respSuccess) {
            this.stateService.setDefaultState();

            this.msgService.setMsg({
                type: 'success',
                message: this.isEditMode
                    ? TEXTS.LIST_USERS.whenSaving
                    : (this.isUserFound ? TEXTS.LIST_USERS.whenAdd : TEXTS.LIST_USERS.whenCreate)
            });
        }
    }

    isDisabled = () => {
        if (this.saving) {
            return true;
        }

        if (this.isEditMode) {
            return !(this.checkChangeRole() || this.checkChangeMos());
        } else {
            const { login, email } = this.usersControl;
            return !(
                ((login.valid && login.value) || login.disabled)
                && email.valid && email.value
            );
        }
    }
}
