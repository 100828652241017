import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrayBackgroundComponent } from './gray-background.component';



@NgModule({
  exports: [GrayBackgroundComponent],
  declarations: [GrayBackgroundComponent],
  imports: [
    CommonModule
  ]
})
export class GrayBackgroundModule {}
