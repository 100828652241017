<div class="input-for-coordinate" [formGroup]="form">
    <div class="input-for-coordinate__input_wrapper">
        <input #inputText class="input-for-coordinate__input--{{positioning}}" [ngClass]="{
                    'input-for-coordinate__input': true,
                    'input-for-coordinate__input-disabled': disabled,
                    'input-for-coordinate__input-error': textError
                    }" formControlName="{{controlName}}" type="number" autocomplete="off" value="{{valueForm}}"/>
        <div [ngClass]="{
                'input-for-coordinate__label': true,
                'input-for-coordinate__label-active': valueForm || textError
                }">
            {{label}}
        </div>
    </div>
    <div [ngClass]="{'input-for-coordinate__description': true, 'input-for-coordinate__description-info': !textError && textInfo}">
        <ng-container *ngIf="!textError && textInfo">
            {{textInfo}}
        </ng-container>
    </div>
    <div [ngClass]="{'input-for-coordinate__description': true, 'input-for-coordinate__description-error': textError}">
        <ng-container *ngIf="textError">
            {{textError}}
        </ng-container>
    </div>
</div>

