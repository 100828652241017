import { Component, Input } from '@angular/core'

import { MAP_PROVIDERS } from 'src/config';
import { MapStateWrapper, MapActionsWrapper } from '../../wrapper';

@Component({
    selector: 'map',
    templateUrl: 'map-container.component.html',
    styleUrls: ['map-container.component.less']
})
export class MapContainerComponent {
    @Input() globalState: Readonly<MapStateWrapper>;
    @Input() globalActions: Readonly<MapActionsWrapper>;
    @Input() className: string;

    mapProviders = MAP_PROVIDERS;
}
