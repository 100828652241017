<div *ngIf="marker.opacity"
    [ngClass]="{
        'city_marker': true,
        'city_marker-selected': isSelected,
        'city_marker-small': marker.city.isSmallCity
    }"
    [ngStyle]="{
        zIndex: marker.city.isSmallCity ? -1 : marker.city.zIndex
    }"
>
    <div class="city_marker__small_city"
        [ngStyle]="{
            backgroundColor: alertColors[marker.aqi],
            opacity: marker.opacity
        }"
    >
    </div>
    <div [ngClass]="{
            'city_marker__content': true,
            'city_marker__content-selected': isSelected,
            'city_marker__content-hide': marker.city.isSmallCity
        }"
        [ngStyle]="{
            opacity: marker.opacity
        }"
    >
        <div class="city_marker__aqi city_marker__aqi-{{marker.aqi}} {{canSelected ? 'city_marker__aqi-plus' + marker.aqi : ''}}"></div>
        <div class="city_marker__name">{{marker.name}}</div>
        <div class="city_marker__tail"></div>
    </div>
    <div *ngIf="marker.description" class="city_marker__description">
        <div class="city_marker__description__second_text">{{marker.description}}</div>
    </div>
</div>
