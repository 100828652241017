import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { TimeEventFrom } from 'src/namespace';
import { isFalseNumber } from 'src/utils';
import { getStndTimeBegin, getStndTimeEnd } from 'projects/shared/utils/config';

type TimeEvent = {
    time: number;
    eventFrom?: TimeEventFrom;
};

@Injectable({
    providedIn: 'root'
})
export class Time {
    private current: number;
    private begin: number = getStndTimeBegin();
    private end: number = getStndTimeEnd();
    private isFixed = false;
    private allowUpdate = true;

    public timeUpdate = new Subject<TimeEvent>();
    private _timeUpdated = new BehaviorSubject<TimeEvent>({ time: this.current });
    public timeUpdated = this._timeUpdated.asObservable();

    public intervalUpdate = new Subject<{ begin: number; end: number; allowUpdate: boolean }>();
    private _intervalUpdated = new Subject<{ begin: number; end: number }>();
    public intervalUpdated = this._intervalUpdated.asObservable();

    constructor() {
        this.timeUpdate.subscribe(({ time, eventFrom }) => {
            if (eventFrom === TimeEventFrom.clickOnChart || eventFrom === TimeEventFrom.dragFlag) {
                this.isFixed = true;
            }

            if (eventFrom === TimeEventFrom.timeToEndBtn) {
                this.isFixed = false;
            }

            this.current = time;
            this._timeUpdated.next({ time, eventFrom });
        });

        this.intervalUpdate.subscribe(({ begin, end, allowUpdate }) => {
            this.begin = begin;
            this.end = end;
            this.allowUpdate = allowUpdate;

            this._intervalUpdated.next({ begin, end });
        });
    }

    // getters

    public getCurrent = () => this.current;
    public getBegin = () => this.begin;
    public getEnd = () => this.end;
    public getAllowUpdate = () => this.allowUpdate;

    // public special updates

    public updateAfterLoadTimeline() {
        this.timeUpdate.next({ time: this.findNearestTime() });
    }

    public updateAfterLoadNewChartPoint() {
        this.timeUpdate.next({ time: this.canUpdateToEnd() ? new Date().getTime() : this.current });
    }

    // private helpers

    private canUpdateToEnd() {
        return !this.isFixed && this.isTimeInsideDiapason();
    }

    private findNearestTime() {
        if (isFalseNumber(this.current)) {
            return this.end;
        }

        if (this.current < this.begin) {
            return this.begin;
        }

        if (this.current > this.end) {
            return this.end;
        }

        return this.current;
    }

    private isTimeInsideDiapason() {
        if (isFalseNumber(this.current)) {
            return false;
        }

        return this.current >= this.begin && this.current <= this.end;
    }
}
