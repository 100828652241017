import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputForDropdownComponent } from './input-for-dropdown.component';
import { ArrowModule } from 'projects/shared/modules/icons/components/arrow/arrow.module';

@NgModule({
    exports: [InputForDropdownComponent],
    declarations: [InputForDropdownComponent],
    imports: [
        CommonModule,
        ArrowModule
    ]
})
export class InputForDropdownModule {
}
