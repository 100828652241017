import { Component, Inject, Input, OnChanges } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { AQI, CityCard, Marker_model, StationForMapPage_model } from 'src/namespace';
import AdminPanelActions from 'projects/cityscreen/src/components/admin-panel/actions';

@Component({
    selector: 'city-info',
    templateUrl: './city-info.component.html',
    styleUrls: ['./city-info.component.less']
})
export class CityInfo implements OnChanges {
    @Input() cityCard: CityCard;
    @Input() comparedStations: StationForMapPage_model[];
    @Input() markersData: Marker_model[];
    @Input() isComparing: boolean;
    @Input() isCityMode: boolean;

    textsCityCard = TEXTS.CITY_CARD;
    AQI = AQI;

    cityMarkersData: Marker_model[] = [];

    sortListStation = [
        {
            props: (marker: Marker_model) => marker.name,
            title: TEXTS.CITY_CARD.sorting[1]
        },
        {
            props: (marker: Marker_model) => marker.aqi,
            title: 'AQI'
        }
    ];

    constructor(
        public apActions: AdminPanelActions
    ) {}

    ngOnChanges() {
        if (this.isCityMode) {
            this.cityMarkersData = this.markersData.filter(v => v.cityId === this.cityCard.city.id);
        }
    }
}
