<div class="dashboard-doughnut">
    <div class="dashboard-doughnut__chart"
        [class.dashboard-doughnut__chart--big]="showMainValue"
        *ngIf="chart"
    >
        <canvas baseChart
            chartType="doughnut"
            [options]="options"
            [width]="chart.size"
            [height]="chart.size"
            [data]="chart.data"
            [labels]="chart.labels"
            [plugins]="chart.plugins"
        ></canvas>
        <div class="dashboard-doughnut__chart__main-value"
            *ngIf="showMainValue"
        >
            {{chart.data[1]}}%
        </div>
    </div>

    <div class="dashboard-doughnut__chart-legend"
        *ngIf="chart && showLegend"
    >
        <div class="dashboard-doughnut__chart-legend-item"
            *ngFor="let _ of chart.data; index as i; count as len"
        >
            <div class="dashboard-doughnut__chart-legend-value"
                [style.background]="chart.labelsBg[len - i - 1]"
            >
                {{chart.data[len - i - 1]}}
            </div>
            <div class="dashboard-doughnut__chart-legend-label">
                {{chart.labels[len - i - 1]}}
            </div>
        </div>
    </div>
</div>
