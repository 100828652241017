import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'renameAqi'
})
export class RenameAQIPipe implements PipeTransform {
    transform(value: string, toValue: string) {
        return value === 'AQI' ? toValue : value;
    }
}
