import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchItemComponent } from './switch-item.component';

@NgModule({
  exports: [SwitchItemComponent],
  declarations: [SwitchItemComponent],
  imports: [
    CommonModule
  ]
})
export class SwitchItemModule { }
