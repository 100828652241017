import { Component, Input, Output, EventEmitter } from '@angular/core';

export type ButtonType = 'primary' | 'secondary';

@Component({
    selector: 'cs-button-simple',
    templateUrl: 'button-simple.component.html',
    styleUrls: ['button-simple.component.less'],
})
export class ButtonSimpleComponent {
    @Input() type: ButtonType = 'secondary';
    @Output() clickAction = new EventEmitter<Event>();
}
