import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';

type Tag = string | number;
type TagMapper = (tag: Tag) => Tag;

@Component({
    selector: 'tags-list',
    templateUrl: 'tags-list.component.html',
    styleUrls: ['tags-list.component.less']
})
export class TagsList implements OnInit, OnChanges {
    @Input() tags: Tag[];

    @Input() tagsMapper: TagMapper = tag => tag;

    @Input() textNodesFns?: {
        all: () => string,
        expand: (count: number) => string,
        collapse: () => string
    };

    @Input() truncateTo?: number;

    @Input() collapseAll?: boolean;

    @Input() editable?: boolean;

    @Output() removeTag = new EventEmitter<number>();

    maxVisibleTags: number;

    ngOnInit() {
        this.setVisibleLength();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.collapseAll || changes.truncateTo || changes.tags) {
            this.setVisibleLength();
        }
    }

    expandList() {
        this.maxVisibleTags = undefined;
    }

    collapseList() {
        this.setVisibleLength();
    }

    get isExpanded() {
        return isNaN(this.maxVisibleTags);
    }

    setVisibleLength(keepExpanded?: boolean) {
        let { truncateTo } = this;
        const { length } = this.tags;

        truncateTo = Math.min(Math.max(truncateTo, 0), length) || length;

        if (length - truncateTo === 1) {
            truncateTo += 1;
        }

        this.maxVisibleTags = this.collapseAll ? 0 : truncateTo;

        // set maxVisibleTags to undefined when it's NaN to slice all tags
        if (this.isExpanded || keepExpanded) {
            this.expandList();
        }
    }

    remove(index: number) {
        this.removeTag.emit(index);
        this.setVisibleLength(this.isExpanded);
    }
}
