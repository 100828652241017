import { Injectable } from '@angular/core';

class EmptyStorage {
    readonly length = 0;

    clear() {}

    setItem() {}

    removeItem() {}

    getItem() {
        return null;
    }

    key() {
        return null;
    }
}

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storage: Storage;

    constructor() {
        this.storage = this.hasLocalStorage() ? localStorage : new EmptyStorage();
    }

    private hasLocalStorage() {
        if (typeof localStorage !== 'undefined') {
            try {
                const testKey = 'test_localStorage';

                localStorage.setItem(testKey, 'ok');

                if (localStorage.getItem(testKey) === 'ok') {
                    localStorage.removeItem(testKey);
                    return true;
                } else {
                    // disabled
                    return false;
                }
            } catch (e) {
                // not available
                return false;
            }
        } else {
            // not supported
            return false;
        }
    }

    get(key: string) {
        return this.storage.getItem(key);
    }

    set(key: string, value: string) {
        return this.storage.setItem(key, value);
    }

    remove(key: string) {
        return this.storage.removeItem(key);
    }

    clear() {
        return this.storage.clear();
    }
}
