import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { PlumesFacadeService } from 'projects/cityscreen/src/modules/plumes/plumes-facade.service';
import { RunConfig } from 'projects/cityscreen/src/modules/plumes/services/models/run-config.model';

@Component({
    selector: 'plumes-sources-list',
    templateUrl: 'plumes-sources-list.component.html',
    styleUrls: ['plumes-sources-list.component.less']
})
export class PlumesSourcesList {
    @Output() cbOpenCalculationResultsEdit = new EventEmitter<void>();

    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;

    TEXTS = TEXTS;

    popupActive = false;
    popupPositionTop = 0;

    selectedCard: RunConfig = null;

    popupOpenerElement: HTMLElement;

    valueTypes = [];

    constructor(
        readonly popupProvider: OffPanelPopupService,
        readonly plumesFacadeService: PlumesFacadeService,
    ) {
        plumesFacadeService.loadSources();
        plumesFacadeService.loadRunConfig();

        const currentMeasure = plumesFacadeService.getCurrentMeasure();

        this.valueTypes = [
            TEXTS.NAMES[currentMeasure] || currentMeasure
        ];
    }

    openActionsPopup({ target, positionTop }, card: RunConfig) {
        this.popupOpenerElement = target;
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.actionsOutlet, () => this.popupActive = false);
        this.popupActive = true;
        this.selectedCard = card;
        this.popupPositionTop = positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedCard = null;
        }
    }

    editRunConfiguration(e: Event, card: RunConfig) {
        this.closeActionsPopup(e);
        this.cbOpenCalculationResultsEdit.emit();
    }

    stopRuns(e: Event, card: RunConfig) {
        this.plumesFacadeService.editRunConfig({is_active: !card.is_active});
        this.closeActionsPopup(e);
    }
}
