import { Component } from '@angular/core';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'search-result-placeholder',
    template: `
        <div class="search-result-placeholder">
            <div class="search-result-placeholder__text">{{TEXTS.POSTS_AND_DEVICES.unfortunatelyNothing}}</div>
            <div class="search-result-placeholder__img"></div>
        </div>`,
    styleUrls: ['./search-result-placeholder.component.less']
})
export class SearchResultPlaceholderComponent {

    TEXTS = TEXTS;

}
