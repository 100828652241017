import { CityScreenTimelineResponse } from './cityairMapModels';
import { City_model, Marker_model } from '../../namespace';
import {
    CityairMapDataTransformerResponse,
    convertLocation,
    createAqiInCity,
    createStndMarker
} from './cityairMapDataTransformerResponse';

export function csMapDataTransformer(data: CityScreenTimelineResponse, timeBegin: number, timeEnd: number): CityairMapDataTransformerResponse {
    let citiesMarkers: Marker_model[] = [];
    let cities: City_model[] = [];
    const citiesForAdminPanel: City_model[] = [];

    data.Locations
        .slice()
        .sort((a, b) => a.SortRank - b.SortRank)
        .forEach((loc) => {
            const {cityMarker, city} = convertLocation(loc, data.Countries);

            citiesForAdminPanel.push(city);

            const _timeline = data.LocationTimelines.find(lt => lt.LocationId === loc.LocationId);

            if (!_timeline || !_timeline.Timeline || !_timeline.Timeline.length)
                return;

            createAqiInCity(city, cityMarker, _timeline.Timeline, loc, data.PacketValueTypes, timeBegin, timeEnd, _timeline.LocationSummary?.DistributionSummaryItems);

            cities.push(city);

            cityMarker.city = city;
            citiesMarkers.push(cityMarker);
        });


    const myMarkers: Marker_model[] = data.MoTimelines.map(mo => {
        mo.AccessGranted = true; // TODO убрать. Используется для определения приватного МО
        return createStndMarker(mo.Timeline, mo, cities)
    });

    cities.sort((a, b) => {
        if (a.name > b.name)
            return 1;
        if (a.name < b.name)
            return -1;
        return 0;
    });

    const cityLocations = myMarkers.filter(my => my.city).map(my => my.city.locationId);
    const uniqIds = new Set(cityLocations);
    cities = cities.filter(c => uniqIds.has(c.locationId));
    citiesMarkers = citiesMarkers.filter(cm => uniqIds.has(cm.id));

    return {
        cities,
        citiesMarkers,
        moMarkers: null,
        myMarkers,
        citiesForAdminPanel
    }
}
