import { ActionReducerMap } from '@ngrx/store';
import { coreReducers, ITimelineState } from './core.reducer';

export interface TimelineState {
    core: ITimelineState;
}

export const reducers: ActionReducerMap<TimelineState> = {
    core: coreReducers,
};

export const effects = [
];
