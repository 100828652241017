// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../../src/little-components/imgs/flag_ru.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../../../../src/little-components/imgs/flag_en.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../../../../../src/little-components/imgs/ok.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".setting_lang_selector__flag{position:relative;margin-left:30px;width:calc(100% - 30px);height:65px;border-bottom:1px solid #e6ecf2;padding:15px 0 0 45px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;transition:all .2s linear}.setting_lang_selector__flag-ru{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:30px 30px;background-position:0 15px}.setting_lang_selector__flag-en{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:30px 30px;background-position:0 15px}.setting_lang_selector__flag-active{position:absolute;right:45px;width:16px;top:27px;height:11px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:16px 11px}.setting_lang_selector__flag:hover{opacity:.7}.setting_lang_selector__title_main{font-weight:600;font-size:16px;line-height:21px;letter-spacing:-.4px;color:#404655}.setting_lang_selector__title_second{font-weight:500;font-size:12px;line-height:14px;color:#b8bfcc}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
