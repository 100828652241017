<div class="subscription_card_content">
    <div class="subscription_card_content__title subscription_card_content__title-no_margin">
        {{textsNotification.accommodationObjects}}
    </div>
    <div class="subscription_card_content__container subscription_card_content__container-mo">
        <tags-list
            *ngIf="currentMos.length !== 0; else showStubTag"
            [tags]="currentMos"
            [collapseAll]="subscription.isForAllMos"
            [truncateTo]="maxInlineMos"
            [textNodesFns]="postsListLabels()"
        ></tags-list>
        <ng-template #showStubTag>
            <tags-list
                *ngIf="subscription.isForAllMos"
                [tags]="stubTags"
            ></tags-list>
        </ng-template>
    </div>

    <div class="subscription_card_content__title">{{textsNotification.controlledSubstances}}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item"
            *ngIf="subscription.isForAllValues; else listValues"
        >
            {{textsNotification.all}}
        </div>
        <ng-template #listValues>
            <div class="subscription_card_content__item"
                *ngFor="let value of subscription.valueItems"
                [innerHTML]="getValueName(value)"
            >
            </div>
        </ng-template>
    </div>

    <div class="subscription_card_content__title">{{textsNotification.levelExcess}}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item">
            {{subscription.excessLevel}}&nbsp;{{textsNotification.excessLevelUnits}}
        </div>
    </div>
    <div class="subscription_card_content__title">{{textsNotification.recipients}}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item">
            {{getEmails() || textsNotification.emailsNotSpecified}}
        </div>
    </div>
</div>
