import { Component, Input } from '@angular/core';

import { Marker_model } from 'src/namespace';
import { ALERT_COLORS } from 'src/config';

@Component({
    selector: 'city-marker',
    templateUrl: 'city-marker.component.html',
    styleUrls: ['city-marker.component.less']
})
export class CityMarkerComponent {
    @Input() marker: Marker_model;
    @Input() isSelected: boolean;
    @Input() canSelected: boolean;

    alertColors = ALERT_COLORS;
}
