import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Subscription, Observable } from 'rxjs';
import { filter, take, map, switchMapTo, pluck, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';

import { MINUTE20_MS, MoExportType } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';
import { getStndTimeEnd } from 'projects/shared/utils/config';
import { selectGroupInfo } from 'projects/cityscreen/src/modules/core/store/selectors';
import {
    indoorAllowUpdate,
    indoorUpdateIntervalData,
    indoorUpdatePostsList
} from './store/actions';
import { IIndoorState } from './store/reducers';
import { indoorSelectors } from './store/selectors';

@Injectable({
    providedIn: 'root'
})
export class IndoorFacade {
    subscriptions: Subscription[] = [];

    private groupId$: Observable<number>;

    constructor(
        public store: Store<IIndoorState>,
        private coreStore: Store,
        private coreFacade: CoreFacade,
    ) {
        this.groupId$ = this.coreStore.select(selectGroupInfo).pipe(
            filter(g => !!g?.groupId),
            pluck('groupId'),
        );
    }

    updateTimeRange = (timeBegin: number, timeEnd: number, isAllow: boolean) => {
        this.groupId$.pipe(take(1)).subscribe((groupId) => {
            this.store.dispatch(indoorUpdatePostsList({ timeBegin, timeEnd, groupId }));
            this.store.dispatch(indoorAllowUpdate({ isAllow }));
        });
    }

    public mainComponentMounted() {
        this.store.select(indoorSelectors.dateRange).pipe(
            take(1),
        ).subscribe(({ dateBegin, dateEnd }) => {
            const begin = dateBegin.getTime();
            const end = dateEnd.getTime();
            const isInitialized = begin !== end;

            let from = moment().subtract(1, 'days').valueOf(); // getStndTimeBegin()
            let to = getStndTimeEnd();

            if (isInitialized) {
                from = begin;
                to = end;
            }

            this.updateTimeRange(from, to, !isInitialized);
        });

        const updateStartingWithDate$ = combineLatest([
            this.store.select(indoorSelectors.dateEnd),
            this.store.select(indoorSelectors.isAllowUpdate)
        ]).pipe(
            take(1),
            filter(([_, isAllowUpdate]) => isAllowUpdate),
            map(([date]) => date)
        );

        const intervalSub = interval(MINUTE20_MS).pipe(
            switchMapTo(updateStartingWithDate$),
            withLatestFrom(this.groupId$)
        ).subscribe(([date, groupId]) => {
            this.store.dispatch(indoorUpdateIntervalData({
                timeBegin: date.getTime(),
                timeEnd: getStndTimeEnd(),
                groupId
            }));
        });

        this.subscriptions.push(intervalSub);
    }

    public mainComponentUnmounted() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    public clickDownload() {
        combineLatest([
            this.store.select(indoorSelectors.activePointId),
            this.store.select(indoorSelectors.listPoints)
        ]).pipe(
            take(1)
        ).subscribe(([activeId, posts]) => {
            const _posts = posts.map(({id, name}) => ({id, name}));

            _posts.sort((a, b) => {
                if (a.name > b.name)
                    return 1;
                if (a.name < b.name)
                    return -1;
                return 0;
            });

            this.coreFacade.openDownloadPopup$.next({
                type: MoExportType.mo,
                title: TEXTS.EDIT_STATION.saveData,
                ids: activeId ? [activeId] : [],
                mos: _posts,
            });
        });
    }
}
