import { Component, Input, OnInit } from '@angular/core';

import { TooltipsService, ITooltipComponent, TooltipPlacement } from '../../tooltips.service';
import { ANIMATION_OPACITY } from '../../../../utils/config';

const ARROW_COLORS: {
    [key in TooltipPlacement]: string;
} = {
    right: '#9364E9',
    left: '#5A83E8',
    top: '#7870E8',
    'top-right': '#5E7EE6',
    'top-left': '#9663E9',
    'right-top': '#9364E9',
    'left-top': '#5A83E8',
};

@Component({
    selector: 'ca-info-tooltip',
    templateUrl: 'info-tooltip.component.html',
    styleUrls: ['info-tooltip.component.less'],
    animations: ANIMATION_OPACITY
})
export class InfoTooltipComponent implements ITooltipComponent, OnInit {
    @Input() text: string;
    @Input() description?: string;

    arrowColor: string;

    constructor(readonly tooltipsService: TooltipsService) {}

    get hasContent() {
        return this.text || this.description;
    }

    ngOnInit() {
        this.arrowColor = ARROW_COLORS[this.tooltipsService.placement];
    }
}
