import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { StationForMapPage_model } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'timeline-selected-item-mobile',
    templateUrl: 'timeline-selected-item-mobile.component.html',
    styleUrls: ['timeline-selected-item-mobile.component.less']
})
export class TimelineSelectedItemMobileComponent {
    @Input() items: StationForMapPage_model[];
    @Output() titleOnClick = new EventEmitter<StationForMapPage_model>();

    TEXTS = TEXTS;

    titleClick($event: StationForMapPage_model) {
        this.titleOnClick.emit($event);
    }
}
