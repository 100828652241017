<div class="timeline_selected_item_m"
    [ngStyle]="{
        'width': items.length > 1 ? '50%' : '100%'
    }"
    *ngFor="let item of items; let i = index;"
>
    <div [ngClass]="{
            'timeline_selected_item_m__title-text_compare': items.length > 1,
            'button_link-underscore': item.type === 'city'
        }"
        class="ellipsis timeline_selected_item_m__title"
        (click)="titleClick(item)"
    >
        {{item.name}}
    </div>
</div>
