import { PM10 } from 'src/namespace';

export interface ControlPoint {
    name: string;
    lat: number;
    lon: number;
    id: number;
    group_id: number;
}

export interface Timeline {
    dates: Date[];
    [PM10]: number[];
    obj: string;
    id: number;
}

export interface ControlPointsReport {
    id: number;
    name: string;
    lat: number;
    lon: number;
    group_id: number;
    obj: string;
    timeline: Timeline;
}

export class ControlPointTimeline {
    [measure: string]: {
        [time: number]: number;
    };

    constructor(point: ControlPointsReport, measure: string) {
        this[measure] = {};

        point.timeline.dates.forEach((time, index) => {
            // TODO: PM10 hardcoded in the vanga response, but the actual measure is received from the harvester
            this[measure][new Date(time).getTime()] = point.timeline[PM10][index];
        });
    }
}
