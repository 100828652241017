import { Injectable } from '@angular/core';
import { RasterSource } from 'mapbox-gl';
import * as moment from 'moment';

import {
    AqiHistory_model,
    City_model,
    CityCard,
    IntervalType,
    MeasuresInfoFormat,
    ModulePageConfig,
    StationForMapPage_model,
    PM2,
} from 'src/namespace';

import { RunPlume } from 'projects/cityscreen/src/modules/plumes/services/models/run-model';
import {
    GroupExtConfigName,
    GroupFeaturesService,
    GroupChartConfig
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';
import { Observables, Observers } from 'projects/cityscreen/src/modules/core/global-observers';
import {
    COMPARE_LIMIT,
    EventsSource,
    ModuleHandler,
    ModuleHandlerInterface
} from '../utils/other-utils';
import { DefaultModuleHandler } from 'projects/cityscreen/src/components/admin-panel/default-module-handler';
import { defaultModuleConfig } from 'projects/cityscreen/src/components/admin-panel/default-module-config';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { ForecastControlService } from '../modules/map/services/forecast-control.service';
import { ModelProps } from 'src/config';
import { getStndTimeBegin, getStndTimeEnd } from '../utils/config';
import { Store } from '@ngrx/store';

export type ExtraLayer = {
    id: string;
    source: RasterSource;
    accessToken?: string;
};

export interface ModuleHandlerConstructor {
    new (sharedCoreFacade: SharedCoreFacade, time: Time): ModuleHandlerInterface;
}


class SharedCoreState {
    cityAqiHistory: AqiHistory_model[];

    // TODO всегда для времени юзать этот но со здвигом по часовому поясу
    worldAqiHistory: AqiHistory_model[];

    currentCityId: string;
    currentLocationId: number;
    currentTzOffset = moment().utcOffset(); // minutes
    currentMsOffset = moment().utcOffset() * 60000;

    lastHoverTime: number = new Date().getTime();

    isCityMod = false;
    isCompareMod = false;
    comparedList: StationForMapPage_model[] = [];

    plumeCurrentRun: RunPlume;
    isShowPlume: boolean;

    // TIMELINE -------------------
    // TODO move to timeline module
    measuresSelected: MeasuresInfoFormat[] = [];
    measuresList: MeasuresInfoFormat[] = [];

    mainChartIsLoading = false;

    showingChartName: string;
    chartDataIntervalType: IntervalType = 2;

    isPlaying = false;
    percentLoadForecast = 0;
    forecastIsLoading: boolean;
    // /TIMELINE -------------------

    // Available locations
    locations: City_model[];

    cityCard: CityCard = {
        city: null,
    };

    allowForecast = false;

    isActiveModelling = false;
}

@Injectable({
    providedIn: 'root'
})
export class SharedCoreFacade {
    private sharedCoreState = new SharedCoreState();

    public eventsSource: EventsSource;
    private activeModuleHandler: ModuleHandler;

    private moduleConfig: ModulePageConfig;

    availableExtraLayers: ExtraLayer[] = [];
    enabledExtraLayers: ExtraLayer[] = [];

    availableAnimatedExtraLayers: string[] = [];
    enabledAnimatedExtraLayers: string[] = [];

    constructor(
        private groupFeaturesService: GroupFeaturesService,
        private time: Time,
        private forecastControlService: ForecastControlService,
        public store: Store
    ) {
        this.setModuleHandler(DefaultModuleHandler);
        this.setModuleConfig(defaultModuleConfig);

        this.eventsSource = new EventsSource();

        this.actionObservers = new Observers();
        this.actionObservables = this.actionObservers.asObservable();

        this.eventsSource.onEvent$.asObservable().subscribe(props => {
            this.activeModuleHandler.onEvent$(props);
        });
    }

    // TODO remove old dependencies
    actionObservers: Observers;
    actionObservables: Observables;

    setModuleHandler(Handler: ModuleHandlerConstructor) {
        this.activeModuleHandler = new Handler(this, this.time);
    }

    setModuleConfig(config: ModulePageConfig) {
        this.moduleConfig = config;
    }

    getModuleConfig() {
        return this.moduleConfig;
    }

    // PLUMES -------------------------------------------

    getPlumeCurrentRun() {
        return this.sharedCoreState.plumeCurrentRun;
    }
    setPlumeCurrentRun(run: RunPlume) {
        this.sharedCoreState.plumeCurrentRun = run;
    }

    getIsShowPlume() {
        return this.sharedCoreState.isShowPlume;
    }
    setIsShowPlume(isShowPlume: boolean) {
        this.sharedCoreState.isShowPlume = isShowPlume;
    }

    // COMPARED AND SELECTED STATIONS ------------------

    getIsCompareMod() {
        return this.sharedCoreState.isCompareMod;
    }
    setCompareModTrue() {
        this.sharedCoreState.isCompareMod = true;
    }
    setCompareModFalse() {
        this.sharedCoreState.isCompareMod = false;
    }

    getComparedList() {
        return this.sharedCoreState.comparedList;
    }
    isComparedListEmpty() {
        return !this.sharedCoreState.comparedList.length;
    }
    isComparedListNotEmpty() {
        return this.sharedCoreState.comparedList.length;
    }
    isComparedListLimited() {
        return this.sharedCoreState.comparedList.length >= COMPARE_LIMIT;
    }
    getComparedListObject(id: number) {
        return this.sharedCoreState.comparedList.find(s => s.id === id);
    }
    findIndexInComparedList(id: number) {
        return this.sharedCoreState.comparedList.findIndex(s => s.id === id);
    }

    compareListAdd(object: StationForMapPage_model) {
        this.sharedCoreState.comparedList.push(object);
        this.actionObservers.comparedList.next();
    }
    compareListRemove(id: number) {
        const index = this.sharedCoreState.comparedList.findIndex(s => s.id === id);
        this.sharedCoreState.comparedList.splice(index, 1);
        this.actionObservers.comparedList.next();
    }
    compareListRemoveForIndex(index: number) {
        this.sharedCoreState.comparedList.splice(index, 1);
        this.actionObservers.comparedList.next();
    }
    clearCompareList() {
        this.sharedCoreState.comparedList = [];
        this.actionObservers.comparedList.next();
    }
    clearCompareListWithoutFirst() {
        this.sharedCoreState.comparedList = [this.sharedCoreState.comparedList[0]];
        this.actionObservers.comparedList.next();
    }

    // AQI HISTORY -------------------

    getAqiHistory() {
        return this.sharedCoreState.isCityMod ? this.getCityAqiHistory() : this.getWorldAqiHistory();
    }

    getCityAqiHistory() {
        return this.sharedCoreState.cityAqiHistory;
    }
    setCityAqiHistory(data: AqiHistory_model[]) {
        this.sharedCoreState.cityAqiHistory = data;
    }

    getWorldAqiHistory() {
        return this.sharedCoreState.worldAqiHistory;
    }
    setWorldAqiHistory(data: AqiHistory_model[]) {
        this.sharedCoreState.worldAqiHistory = data;
    }

    // CITY CARD ------------------------------

    getCityCard() {
        return this.sharedCoreState.cityCard;
    }

    setCityCardCity(city: City_model) {
        this.sharedCoreState.cityCard.city = city;
    }

    // CURRENT LOCATION -----------------------

    getIsCityMod() {
        return this.sharedCoreState.isCityMod;
    }
    setIsCityMod(isCityMod : boolean) {
        this.sharedCoreState.isCityMod = isCityMod;
    }

    setLocations(locations: City_model[]) {
        this.sharedCoreState.locations = locations;
    }

    getLocations() {
        return this.sharedCoreState.locations;
    }

    getCurrentCityId() {
        return this.sharedCoreState.currentCityId;
    }
    getCurrentLocationId() {
        return this.sharedCoreState.currentLocationId;
    }
    setCurrentCity(city: City_model) {
        this.sharedCoreState.currentCityId = city.id;
        this.sharedCoreState.currentLocationId = city.locationId;
        this.sharedCoreState.currentTzOffset = city.tzOffset;
        this.sharedCoreState.currentMsOffset = city.msOffset;
    }
    clearCurrentCity() {
        this.sharedCoreState.currentCityId = null;
        this.sharedCoreState.currentLocationId = null;

        this.sharedCoreState.currentTzOffset = moment().utcOffset();
        this.sharedCoreState.currentMsOffset = moment().utcOffset() * 60000;
    }

    getCityById(cityId: string) {
        return this.sharedCoreState.locations?.find(c => c.id === cityId) || null;
    }

    getCurrentCity(): City_model {
        return this.getCityById(this.sharedCoreState.currentCityId);
    }

    getTzOffset() {
        return this.sharedCoreState.currentTzOffset;
    }
    getMsOffset() {
        return this.sharedCoreState.currentMsOffset;
    }

    // TIME --------------------------------
    setLastHoverTime(time: number) {
        this.sharedCoreState.lastHoverTime = time;
    }
    getLastHoverTime() {
        return this.sharedCoreState.lastHoverTime;
    }

    // MEASURES -----------------------------
    getMeasuresSelected() {
        return this.sharedCoreState.measuresSelected;
    }
    setMeasuresSelected(measures: MeasuresInfoFormat[]) {
        this.sharedCoreState.measuresSelected = measures;
    }
    clearMeasuresSelected() {
        this.sharedCoreState.measuresSelected = [];
    }

    getMeasuresList() {
        return this.sharedCoreState.measuresList;
    }
    setMeasuresList(measures: MeasuresInfoFormat[]) {
        this.sharedCoreState.measuresList = measures;
    }

    // CHART -----------

    getMainChartIsLoading() {
        return this.sharedCoreState.mainChartIsLoading;
    }
    setMainChartLoadingTrue() {
        this.sharedCoreState.mainChartIsLoading = true;
    }
    setMainChartLoadingFalse() {
        this.sharedCoreState.mainChartIsLoading = false;
    }

    getChartDataIntervalType() {
        return this.sharedCoreState.chartDataIntervalType;
    }
    setChartDataIntervalType(interval: IntervalType) {
        this.sharedCoreState.chartDataIntervalType = interval;
    }

    getShowingChartName() {
        return this.sharedCoreState.showingChartName;
    }
    setShowingChartName(str: string) {
        this.sharedCoreState.showingChartName = str;
    }

    // PLAY --------------------------

    getIsPlaying() {
        return this.sharedCoreState.isPlaying;
    }
    setIsPlayingTrue() {
        this.sharedCoreState.isPlaying = true;
    }
    setIsPlayingFalse() {
        this.sharedCoreState.isPlaying = false;
    }

    getForecastIsLoading() {
        return this.sharedCoreState.forecastIsLoading;
    }
    setForecastIsLoadingTrue() {
        this.sharedCoreState.forecastIsLoading = true;
    }
    setForecastIsLoadingFalse() {
        this.sharedCoreState.forecastIsLoading = false;
    }

    getPercentLoadForecast() {
        return this.sharedCoreState.percentLoadForecast;
    }
    setPercentLoadForecast(a: number) {
        this.sharedCoreState.percentLoadForecast = a;
    }

    // options for extra client

    canShowNumbersAqiChart() {
        return this.groupFeaturesService.getConfig(GroupExtConfigName.numberedAqi) && this.getComparedList().length === 1;
    }

    getGroupChartConfig() {
        return this.groupFeaturesService.getConfig(GroupExtConfigName.chartMinMax) as GroupChartConfig;
    }

    // map layers
    toggleMapLayer(layerId: string, isEnabled: boolean) {
        const layer = isEnabled ? this.availableExtraLayers.find(layer => layer.id === layerId) : null;
        this.enabledExtraLayers = layer ? [layer] : [];
    }

    addMapLayer(layer: ExtraLayer) {
        if (!this.availableExtraLayers.find(l => l.id === layer.id)) {
            this.availableExtraLayers.push(layer);
        }
    }

    toggleAnimatedMapLayer(layerIds: string[], isEnabled: boolean) {
        const layers = this.availableAnimatedExtraLayers.filter(id => layerIds.includes(id));
        this.enabledAnimatedExtraLayers = isEnabled ? layers : [];
    }

    addAnimatedMapLayer(layerId: string) {
        if (!this.availableAnimatedExtraLayers.find(id => id === layerId)) {
            this.availableAnimatedExtraLayers.push(layerId);
        }
    }

    clearMapLayers() {
        this.enabledExtraLayers = [];
        this.availableExtraLayers = [];

        this.enabledAnimatedExtraLayers = [];
        this.availableAnimatedExtraLayers = [];
    }

    getAllowForecast = () => this.sharedCoreState.allowForecast;

    setAllowForecast = (val: boolean) => this.sharedCoreState.allowForecast = val;

    setIsActiveModelling(val: boolean) {
        return this.sharedCoreState.isActiveModelling = val;
    }

    getIsActiveModelling() {
        return this.sharedCoreState.isActiveModelling;
    }

    updateModelProps(props?: ModelProps) {
        this.setIsPlayingFalse();

        if (props) {
            this.forecastControlService.updateProps(props);
        }
    }

    enableModelling() {
        const begin = moment().startOf('hour').subtract(24, 'hour').valueOf();
        const end = moment().add(36, 'hour').endOf('hour').valueOf();

        this.time.intervalUpdate.next({
            begin,
            end,
            allowUpdate: true
        });

        this.setIsActiveModelling(true);

        this.forecastControlService.enable();

        this.updateModelProps({
            measure: PM2,
            overlay: true,
            contours: false
        });
    }

    disableModelling() {
        this.setIsActiveModelling(false);

        this.forecastControlService.disable();

        this.time.intervalUpdate.next({
            begin: getStndTimeBegin(),
            end: getStndTimeEnd(),
            allowUpdate: true
        });
    }
}
