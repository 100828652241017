import { CHART_INDIVIDUAL_OPTIONS, RESIZE_TIMEOUT } from '../config';
import {
    applyCoef, excludeNavigatorSerie,
    findExactlyPoint,
    findMuchPointInAllSeries,
    findPointInAllSeriesForCoordinates,
    isFalseNumber
} from '../utils';
import { CHART_TYPE_BIG, ParamResizeChart_model, Series_model } from '../namespace';
import { isNumber } from '../utils';

export function  updateChart(data: Series_model, deleteSelectLine: boolean) {// сначала должен произойти биндинг в chart.ts, this = chart
    if (!this.series) {
        return new Error('updateChart this.series  undef');
    }

    if (this.series.length) {
        while (this.series.length > 0) // надо через while
            this.series[0].remove(false);
    }

    data.series.forEach(s => this.addSeries(s, false));

    const y = this.yAxis[0];
    y.removePlotLine();

    if (data.pdk && data.pdk.length) {
        data.pdk.forEach(pdk => {
            y.addPlotLine(pdk.CC);
            y.addPlotLine(pdk.MR);
        });
    }

    y.setTitle({ text: data.yTitle });

    if (deleteSelectLine) {
        this.xAxis.forEach(x => x.removePlotLine('vertLine'));
    }

    this.redraw();
}

export function  addPoint(
    data: /*[number, number] |*/ {x: number, y: number},
    _serie: number | string,
    coef = {a: 1, b: 0},
    isShift = true
    ) {// сначала должен произойти биндинг в chart.ts, this = chart
    const serie = isNumber(_serie) ? this.series[_serie] : this.get(_serie);

    if (!serie)
        return console.error('chart addPoint не нашел serie');

    const y = applyCoef(isFalseNumber(data[1]) ? data.y : data[1], coef);

    const point = findExactlyPoint(serie, data);

    if (point)
        point.update(y);
    else
        serie.addPoint([data[0], y], true, isShift);
}

export function findPoint(coordinateX) {// по координатам
    return findPointInAllSeriesForCoordinates(excludeNavigatorSerie(this.series), coordinateX);
}

export function  findPointXCoordinate(timestamp: number) {// по времени
    return findMuchPointInAllSeries(excludeNavigatorSerie(this.series), timestamp);
}

export function highlight(timestamp): number {
    const chart = this;

    const series = excludeNavigatorSerie(chart.series);

    const point = findMuchPointInAllSeries(series, timestamp);

    if (point && !isFalseNumber(point.y)/*иначе будет постоянно вылезать ошибки в разных местах графика*/) {
        this.lastHoverPoint = point;
        try {
            point.onMouseOver(); // Show the hover marker
            point.series.chart.xAxis[0].drawCrosshair(undefined, point); // Show the crosshair
        } catch (e) {
            // generates clutter after window resize
            // console.error(e);
        }

        return point.x; // last hover time
    } else if (this.lastHoverPoint && this.lastHoverPoint.series) {
        // this.lastHoverPoint.onMouseOut();
        this.lastHoverPoint.series.chart.xAxis[0].hideCrosshair();
        return null;
    }
}

export function onPointSelect(currentX, removeOnly) {
    if (!this.xAxis)
        return;

    this.xAxis.forEach(x => {
        x.removePlotLine('vertLine');

        if (removeOnly) {
            return;
        }

        x.addPlotLine({
            value: currentX,
            color: '#00b0f0',
            width: 2,
            id: 'vertLine',
            zIndex: 2,
        });
    });
}

export function setSize(chartType, params: ParamResizeChart_model = {shiftWidth: 0}, animation = false) {
    // TODO сделать так чтоб не вызывалось при окне логина
    try {
        this.setSize(CHART_INDIVIDUAL_OPTIONS[chartType].chart.width + params.shiftWidth, CHART_INDIVIDUAL_OPTIONS[chartType].chart.height, true)
    } catch (e) {
        console.log('chart resize error');
    }
}

export function resize(chartType, getParams: () => ParamResizeChart_model = () => null) {
    window.addEventListener('resize', () => {
        setTimeout(() => setSize.call(this, chartType, getParams()), RESIZE_TIMEOUT);
    });
}

export function chartFullscreen() {
    const heightTopElements = 500; // document.querySelector('#chart_fs > *').scrollHeight - document.querySelector('.popup_info__chart').scrollHeight;
    const blank = window.document.querySelector('#chart_fs');
    if (blank.classList.contains('on')) {
        const config = CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_BIG].chart;
        blank.classList.remove('on');
        this.setSize(config.width, config.height, true);
    }
    else {
        blank.classList.add('on');
        this.setSize(window.innerWidth - 88, window.innerHeight - heightTopElements, true);
    }
}

export function changeOptions(option: any) {
    setTimeout(() => this.update(option), 0); // чтобы обновлялось после внутренних изменений чарта
}

export function reloadExtremes() {
    if (!this.xAxis[0]) {
        return;
    }

    this.xAxis[0].setExtremes(null, null);
}
