import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TEXTS } from 'src/texts/texts';
import { HarvesterApiService } from '../../services/harvester-api/harvester-api.service';
import { LoginUpdateService } from '../../services/login-update/login-update.service';
import { LoginPage } from '../../login.settings';

@Component({
    selector: 'ca-password-reset-form',
    templateUrl: 'password-reset-form.component.html',
    styleUrls: ['password-reset-form.component.less'],
})
export class PasswordResetFormComponent implements OnInit {
    TEXTS = TEXTS;

    hasErrors = false;

    isLoading = false;

    resetForm: FormGroup;

    loginPage = LoginPage;

    constructor(
        readonly harvester: HarvesterApiService,
        private fb: FormBuilder,
        private router: Router,
        private loginUpdateService: LoginUpdateService,
    ) {}

    ngOnInit() {
        this.resetForm = this.fb.group({
            email: ['', [
                Validators.required,
                Validators.minLength(3),
            ]]
        });
    }

    async onSubmit() {
        if (this.isLoading || this.resetForm.invalid) {
            return;
        }

        this.hasErrors = false;

        const { email } = this.resetForm.value;

        this.loginUpdateService.setEmail(email);

        this.isLoading = true;

        this.harvester.requestResetCode(email).subscribe(() => {
            this.isLoading = false;
            this.router.navigate([LoginPage.Login, LoginPage.ResetCode]);
        }, () => {
            this.isLoading = false;
            this.hasErrors = true;
        });
    }
}
