import { NgModule } from '@angular/core';
import { InfoIconComponent } from './info-icon.component';

@NgModule({
    declarations: [
        InfoIconComponent,
    ],
    exports: [
        InfoIconComponent,
    ],
})
export class InfoIconModule {}
