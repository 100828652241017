import { Component, Inject, Input } from '@angular/core';
import { City_model, Marker_model, MonitoringObject, SortingColumn, StationForMapPage_model } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { declOfNum } from 'src/utils';

import './station-city-list.component.less';
import { COMPARE_LIMIT, IS_PUBLIC } from 'projects/shared/utils/other-utils';
import { selectMos } from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
    selector: 'station-city-list',
    templateUrl: './station-city-list.component.html'
})
export class StationCityListComponent {
    @Input() compareMod: boolean;
    @Input() comparedStations: Readonly<StationForMapPage_model[]>;
    @Input() markersData: Marker_model[];
    @Input() cities?: City_model[];
    @Input() cbClickForName: (marker: Marker_model) => void;
    @Input() monitoringObjects?: MonitoringObject[];
    @Input() filter?: string;
    @Input() sortListStation: SortingColumn[];
    @Input() showCity?: boolean;

    sortingStation;
    sortingDirection = 1;

    constructor(
        @Inject(IS_PUBLIC) readonly isPublic: boolean,
        public store: Store<any>
    ) {
    }

    searchFilters = [
        (marker: Marker_model) => marker.name,
        (marker: Marker_model) => marker.cityId,
        (marker: Marker_model) => marker.city?.name
    ];

    TEXTS = TEXTS;

    declOfNum = (val: number) => declOfNum(val, TEXTS.AQI_DECL);

    getClassName = (marker: Marker_model) => {
        const isSelect = this.comparedStations.find(s => s.id === marker.id);
        let str = ' air_aqi__num' + (isSelect ? '-big' : '-small');
        str += ' air_aqi__num' + (isSelect ? '-' : '-small-');
        str += (this.compareMod && !isSelect && this.comparedStations.length < COMPARE_LIMIT ? 'plus' : '');
        str += marker.aqi;
        return str;
    }

    sortingOfStation = (sortItem) => {
        if (this.sortingStation === sortItem) this.sortingDirection *= -1;
        else this.sortingStation = sortItem;
    };

    getText(marker: Marker_model): string {
        let text = ''
        this.store.select(selectMos)
            .pipe(filter(mos => !!mos.length))
            .subscribe(mos => {
                if (this.cities) {
                    const city = this.cities.find(city => city.id === marker.cityId);
                    if (city) {
                        text = city.countryName;
                    } else {
                        text = '';
                    }
                } else {
                    const mo = mos.find(m => m.id == marker.id)
                    mo.devicesObj.forEach(d => {
                        if (d.sourceId == 3) {
                            text = TEXTS.STATION_BLOCK.type[marker.type]
                        } else {
                            text = ''
                        }
                    })
                }
            })
        return text;
    }
}
