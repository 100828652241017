import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AqiCircleComponent } from './aqi-circle.component';



@NgModule({
  exports: [AqiCircleComponent],
  declarations: [AqiCircleComponent],
  imports: [
    CommonModule
  ]
})
export class AqiCircleModule { }
