import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewChild,
    TemplateRef,
    ViewChildren,
    QueryList,
    Directive
} from '@angular/core';

import * as moment from 'moment';

import { TEXTS } from 'src/texts/texts';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import State from 'map/state';

import { NotificationSubscribeType } from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';

import 'projects/cityscreen/src/modules/notifications/components/subscription-card/subscription-card.component.less';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { saveDataToDisk } from 'src/utils';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

@Component({
    templateUrl: 'subscription-card.component.html',
    selector: 'subscription-card'
})
export class SubscriptionCard implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    private popupOpenerElement: HTMLElement;

    isDeletePopupOpen = false;
    isDownloadPopupOpen = false;
    TEXTS = TEXTS;
    textsNotification = TEXTS.NOTIFICATIONS;
    textsDownloadPopup = TEXTS.DOWNLOAD_POPUP;

    subscriptionStatus: string;
    popupActive = false;
    popupPositionTop = 0;
    subscriptionTypes = NotificationSubscribeType;

    timeBegin: number;
    timeEnd: number;

    isShownInEventFeed = false;

    constructor(
        public stateService: NotificationsStateService,
        public popupProvider: OffPanelPopupService,
        private globalState: State,
        private sharedCoreFacade: SharedCoreFacade,
        private time: Time
    ) {}

    ngOnInit() {
        this.initCalendar();
        this.isShownInEventFeed = this.subscription.forUserIds.includes(this.globalState.adminPanel.iAm.userId);
    }

    includeInEventFeed(e: Event, include: boolean) {
        this.closePopup(e);

        const userId = this.globalState.adminPanel.iAm.userId;
        const { forUserIds } = this.subscription;

        const newUserIds = include
            ? forUserIds.concat(userId)
            : forUserIds.filter(id => id !== userId);

        this.stateService
        .updateEventFeedUserIds(this.subscription, newUserIds)
        .then(() => {
            this.isShownInEventFeed = include;
        })
    }

    editSubscription() {
        this.stateService.openSettings(this.subscription);
    }

    activateSubscription(e: Event) {
        this.closePopup(e);

        this.subscription.isNew() || this.subscription.isSuggested()
            ? this.stateService.addSubscription(this.subscription)
            : this.stateService.activateSubscription(this.subscription);
    }

    deactivateSubscription(e: Event) {
        this.closePopup(e);
        this.stateService
        .deactivateSubscription(this.subscription)
    }

    deleteSubscriptionDialog(e: Event) {
        this.closePopup(e);
        this.isDeletePopupOpen = true;
    }

    deleteSubscription() {
        this.stateService.currentSubscription = null;
        this.stateService.removeSubscription(this.subscription);
    }

    downloadFeedHistoryDialog(e: Event) {
        this.closePopup(e);
        this.isDownloadPopupOpen = true;
    }

    deleteSubscriptionAccept = () => {
        this.isDeletePopupOpen = false;
        this.deleteSubscription();
    }

    deleteSubscriptionCancel = () => {
        this.isDeletePopupOpen = false;
    }

    downloadFeedHistoryAccept = () =>  {
        this.isDownloadPopupOpen = false;
        this.downloadFeedHistory();
    }

    downloadFeedHistoryCancel = () => {
        this.initCalendar();
        this.isDownloadPopupOpen = false;
    }

    initCalendar() {
        this.updateTime(this.time.getBegin(), this.time.getEnd());
    }

    openPopup({ target, positionTop }) {
        this.popupProvider.confirm(() => {});
        this.popupProvider.setTemplate(this.popupOutlet, () => this.popupActive = false);
        this.popupOpenerElement = target;
        this.popupPositionTop = positionTop;
        this.popupActive = true;
    }

    closePopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupActive = false;
        }
    }

    updateTime = (timeBegin: number, timeEnd: number) => {
        this.timeBegin = timeBegin;
        this.timeEnd = timeEnd;
    }

    downloadFeedHistory() {
        const filename = 'feed-data.xlsx';
        const dateFormat = 'YYYY-MM-DD';
        const dateBegin = moment(this.timeBegin).format(dateFormat);
        const dateEnd = moment(this.timeEnd).format(dateFormat);

        this.stateService.downloadFeedHistory(this.subscription, dateBegin, dateEnd)
        .then((response) => {
            saveDataToDisk(response, filename);
        });
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }
}

@Directive()
export class ClosePopupsHelper {
    @ViewChildren(SubscriptionCard)
    popupHolders: QueryList<SubscriptionCard>;

    onScroll(e: Event) {
        this.popupHolders.find(c => c.popupActive)?.closePopup(e);
    }
}
