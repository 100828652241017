import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnCancelComponent } from './btn-cancel.component';


@NgModule({
    declarations: [BtnCancelComponent],
    exports: [BtnCancelComponent],
    imports: [
        CommonModule
    ]
})
export class BtnCancelModule {
}
