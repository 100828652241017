import { BehaviorSubject } from 'rxjs';

import { Marker_model } from 'src/namespace';
import { STND_CITY_ZOOM } from 'src/config';

export default class MapState {
    myMarkers: Marker_model[] = []; // маркеры в конкретный разрез времени
    moMarkers: Marker_model[] = [];
    commonMarkers: Marker_model[] = [];
    citiesMarkers: Marker_model[] = [];

    userLocation = {
        lat: 0,
        lng: 0,
        inCurrentCity: false
    };

    currentZoom: number = STND_CITY_ZOOM;
    contoursData: GeoJSON.FeatureCollection<GeoJSON.Polygon>;

    showingModelMeasure: string;
    showOverlay: boolean;
    showContours: boolean;

    tooltip = {
        show: false,
        text: '',
        lastData: ''
    };

    notifiableMos: number[] = null;
    notifiableMos$ = new BehaviorSubject<number[]>(null);
}
