import { Component, EventEmitter, OnInit, OnDestroy, Output, Input, TemplateRef, ViewChild } from '@angular/core';
import { TEXTS } from '../../../../../../../src/texts/texts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlumesFacadeService } from '../../plumes-facade.service';
import { ControlPoint } from '../../services/models/control-point-model';
import { OffPanelPopupService } from '../../../../components/admin-panel/off-panel-popup.service';

export interface ErrorModel {
    name?: string;
    lat?: string;
    lon?: string;
}

@Component({
    selector: 'edit-control-point',
    templateUrl: 'edit-control-point.component.html',
    styleUrls: ['./edit-control-point.component.less']
})
export class EditControlPointComponent implements OnInit, OnDestroy {
    @Output() cbCloseEditMode = new EventEmitter<void>();
    @Input() controlPointForEdit: ControlPoint;
    @Input() title: string;

    texts = TEXTS;

    editControlPoint: FormGroup;
    errorObj: ErrorModel = {};
    showConfirmationPopup = false;
    isLoading = false;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    constructor(
        private fb: FormBuilder,
        private plumeFacade: PlumesFacadeService,
        private popupProvider: OffPanelPopupService
    ) {}

    ngOnInit() {
        this.editControlPoint = this.fb.group({
            name: [
                this.controlPointForEdit ? this.controlPointForEdit.name : '',
                [
                    Validators.required,
                    Validators.minLength(1)
                ]
            ],
            lat: [
                this.controlPointForEdit ? this.controlPointForEdit.lat : 0,
                [
                    Validators.required,
                    Validators.minLength(1)
                ]
            ],
            lon: [
                this.controlPointForEdit ? this.controlPointForEdit.lon : 0,
                [
                    Validators.required,
                    Validators.minLength(1)
                ]
            ],
            group_id: [
                this.controlPointForEdit ? this.controlPointForEdit.group_id : '',
            ],
            id: [
                this.controlPointForEdit ? this.controlPointForEdit.id : null
            ]
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            this.openPopup();
            return true;
        });
    }

    ngOnDestroy () {
        this.popupProvider.clear();
    }

    get name() {
        return this.editControlPoint.get('name');
    }

    get lat() {
        return this.editControlPoint.get('lat');
    }

    get lon() {
        return this.editControlPoint.get('lon');
    }

    save() {
        this.onSubmit();
    }

    onSubmit() {
        this.isLoading = true;
        if (this.controlPointForEdit) {
            this.plumeFacade.editControlPoint(this.editControlPoint.value).then((result: boolean) => {
                if (!result) {
                    this.errorObj = this.plumeFacade.uiState.formError;
                } else {
                    this.openCheckpoint();
                }
                this.isLoading = false;
            });
        }
        else {
            this.plumeFacade.createControlPoint(this.editControlPoint.value).then((result: boolean) => {
                if (!result) {
                    this.errorObj = this.plumeFacade.uiState.formError;
                } else {
                    this.openCheckpoint();
                }
                this.isLoading = false;
            });
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    }

    onSavePopup = () => {
        this.save();
        this.showConfirmationPopup = false;
    }

    openCheckpoint = () => {
        this.cbCloseEditMode.emit();
    }

}
