import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { NotificationsModule } from 'projects/cityscreen/src/modules/notifications/notifications.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { IconsModule } from 'projects/shared/modules/icons/icons.module';

import { FilterByPipe } from 'projects/shared/pipes/pipes';

import { DashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { DashboardWidgetComponent } from './components/dashboard-widget/dashboard-widget.component';
import { DashboardWidgetHeaderComponent } from './components/dashboard-widget-header/dashboard-widget-header.component';
import { DashboardWidgetTitleComponent } from './components/dashboard-widget-title/dashboard-widget-title.component';
import { DashboardWidgetBodyComponent } from './components/dashboard-widget-body/dashboard-widget-body.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { PacketsMonitorListItemComponent } from './components/packets-monitor-list-item/packets-monitor-list-item.component';
import { PacketsMonitorTimelineScaleComponent } from './components/packets-monitor-timeline-scale/packets-monitor-timeline-scale.component';
import { DashboardDoughnutComponent } from './components/dashboard-doughnut/dashboard-doughnut.component';
import { PacketsTooltipComponent } from './components/packets-tooltip/packets-tooltip.component';
import { DeviceStatusComponent } from './components/device-status/device-status.component';
import { DeviceStatusFilterComponent } from './components/device-status-filter/device-status-filter.component';
import { DeviceStatusTagComponent } from './components/device-status-tag/device-status-tag.component';
import { SelectboxModule } from 'projects/cityscreen/src/components/selectbox/selectbox.module';
import { TextButtonModule } from 'projects/cityscreen/src/components/text-button/text-button.module';
import { TagsListModule } from 'projects/cityscreen/src/components/tags-list/tags-list.module';
import { CheckboxModule } from 'projects/cityscreen/src/components/checkbox/checkbox.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        TooltipsModule,
        ChartsModule,
        NotificationsModule,
        LittleComponentsModule,
        IconsModule,
        SelectboxModule,
        TextButtonModule,
        TagsListModule,
        CheckboxModule,
    ],
    providers: [
        FilterByPipe,
    ],
    exports: [
        DashboardPageComponent,
        DeviceStatusTagComponent,
    ],
    declarations: [
        DashboardPageComponent,
        DashboardWidgetComponent,
        DashboardWidgetHeaderComponent,
        DashboardWidgetTitleComponent,
        DashboardWidgetBodyComponent,
        DashboardChartComponent,
        PacketsMonitorListItemComponent,
        PacketsMonitorTimelineScaleComponent,
        DashboardDoughnutComponent,
        PacketsTooltipComponent,
        DeviceStatusComponent,
        DeviceStatusFilterComponent,
        DeviceStatusTagComponent,
    ]
})
export class DashboardModule {}
