import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'string-choice',
    template: `
        <div class="string_choice {{addClass}}">
            <cs-checkbox *ngIf="format != 'simple'" [checked]="checked" class="string_choice__checkbox"></cs-checkbox>
            <div class="string_choice__text {{format == 'simple' && checked ? 'string_choice__text-selected' : ''}}" [innerHTML]="text | safeHtml"></div>
            <item-radio *ngIf="format == 'simple'" [check]="checked" class="string_choice__radio"></item-radio>
        </div>
    `,
    styleUrls: ['string-choice.component.less'],
    providers: []
})
export class StringChoiceComponent implements OnInit {
    @Input() format?: 'simple' | 'array' | 'items' = 'simple';
    @Input() checked: boolean;
    @Input() text = '';
    @Input() type: 'online' | 'offline' | 'stnd' = 'online';

    addClass: string;

    ngOnInit() {
        switch (this.type) {
            case 'online': this.addClass = 'string_choice-online'; break;
            case 'offline': this.addClass = 'string_choice-offline'; break;
        }
    }
}
