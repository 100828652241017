<div class="timeline-player">
    <div class="timeline-container__wrapper">
        <div class="timeline-container"
            [class.timeline-container--with-panel]="sidebarIsOpened && configApp.sidebar"
        >
            <airvoice2-chart-timeline
                aqiName="IAQ"
                [timeIndex]="timeIndex"
                [data]="pointSeriesData"
                [showCompare]="false"
                [showGridLines]="true"
                [initSelectMeasurement]="initSelectMeasurement"
                (setPosition)="onTrackClick($event)"
            ></airvoice2-chart-timeline>

            <div class="panel-wrapper">
                <airvoice2-track-panel
                    #timelineTrack
                    [date]="timeLineDate"
                    [AQI]="timeLineAQI"
                    [index]="timeIndex"
                    [hasDataByIndex]="hasDataByIndex"
                    (setPosition)="onTrackClick($event)"
                ></airvoice2-track-panel>

                <airvoice2-time-runner
                    class="panel-wrapper__runner"
                    #timelineRunner
                    [time]="currentTime"
                    [isLastTime]="false"
                    [class.panel-wrapper__runner--extended]="chartEnabled"
                    [style.visibility]="positionPercent >= 0 ? 'visible' : 'hidden'"
                    [style.left.%]="positionPercent"
                    [flagDirection]="flagDirection"
                    [chartEnabled]="chartEnabled"
                    (mousedown)="dragStart($event)"
                    (touchstart)="dragStart($event)"
                ></airvoice2-time-runner>
            </div>
        </div>
    </div>
</div>
