import { Substance } from './substance.enum';

export type DomainConfigType = {
    slug: string;
    substances: Substance[];
    coordinates: number[][];
};

export enum DataType {
    Raster = 'raster',
    Vector = 'vector',
}

export enum TileType {
    DomainTiles = 'custom',
    Tiles = 'xyz',
}

export interface IAuthorizeHelper {
    getAuthHeader: () => Record<string, string>;
    refreshToken: () => Promise<void>;
}
