import { Component, Input } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { Device } from '../../services/dashboard.model';
import { STATUS_TYPES, DeviceStatusLabel } from '../device-status/device-status.types';

@Component({
    selector: 'packets-monitor-list-item',
    templateUrl: 'packets-monitor-list-item.component.html',
    styleUrls: ['packets-monitor-list-item.component.less']
})
export class PacketsMonitorListItemComponent {
    @Input() data: Device['deviceInfo'];
    @Input() chart: Device['chart'];
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() statusList: DeviceStatusLabel[];

    statusTypes = STATUS_TYPES;

    TEXTS = TEXTS;

    getStatusType(item: DeviceStatusLabel) {
        return item.type;
    }
}
