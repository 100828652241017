import { Component } from '@angular/core';

import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'ca-legal-notice',
    templateUrl: 'legal-notice.component.html',
    styleUrls: ['legal-notice.component.less'],
})
export class LegalNoticeComponent {
    TEXTS = TEXTS;
}
