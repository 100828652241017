import { Component, EventEmitter, Input, Output, } from '@angular/core';

import type { CheckboxItem } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'cs-selectbox2',
    templateUrl: 'selectbox.component.html',
    styleUrls: ['selectbox.component.less']
})
export class SelectboxComponent2 {
    @Input() listItems: CheckboxItem[];
    @Input() searchPlaceholderText = TEXTS.NOTIFICATIONS.searchMoPlaceholderText;
    @Input() selectAllText = TEXTS.NOTIFICATIONS.selectAll;

    @Output() itemChange = new EventEmitter<{ item: CheckboxItem, isSelect: boolean }>();


    searchText = '';

    isSelectAll() {
        return !this.listItems.find(item => item.selected === false);
    }

    selectAll() {
        this.listItems.forEach(item => {
            this.itemChange.emit({item, isSelect: true})
        });
    }

    deselectAll() {
        this.listItems.forEach(item => {
            this.itemChange.emit({item, isSelect: false})
        });
    }

    onItemClick(item: CheckboxItem) {
        this.itemChange.emit({item, isSelect: !item.selected})
    }
}
