import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipsModule } from '../../../../components/tooltips/tooltips.module';
import { SharedComponentsModule } from '../../../../components/shared-components.module';

import { LocationMeteoCardComponent } from './location-meteo-card.component';
import { InfoIconModule } from 'projects/shared/modules/icons/components/info-icon/info-icon.module';
import { AqiCircleModule } from '../../../../components/aqi-circle/aqi-circle.module';

@NgModule({
    imports: [
        CommonModule,
        SharedComponentsModule,
        InfoIconModule,
        TooltipsModule,
        AqiCircleModule,
    ],
    exports: [
        LocationMeteoCardComponent,
    ],
    declarations: [
        LocationMeteoCardComponent,
    ],
})
export class LocationMeteoCardModule {}
