import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    FilterData,
    MarkerFilter,
    MarkerFilterOpacity0,
    MultipleSearchfilterPipe,
    MultipleSearchFnsPipe,
    SafeHtmlPipe,
    SortingPipe,
    FilterByPipe
} from './pipes';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        FilterData,
        MarkerFilter,
        MarkerFilterOpacity0,
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        FilterByPipe,
    ],
    declarations: [
        FilterData,
        MarkerFilter,
        MarkerFilterOpacity0,
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        FilterByPipe,
    ]
})
export class PipesModule {}
