import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnAcceptComponent } from './btn-accept.component';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';


@NgModule({
    exports: [BtnAcceptComponent],
    declarations: [BtnAcceptComponent],
    imports: [
        CommonModule,
        LoadersModule,
    ]
})
export class BtnAcceptModule {
}
