import { Component, Input } from '@angular/core';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'link-on-document',
    templateUrl: './link-on-document.component.html',
    styleUrls: ['./link-on-document.component.less']
})
export class LinkOnDocumentComponent {
    @Input() name = ''
    @Input() link = ''

    TEXTS = TEXTS
}
