import {
    Component,
    Input,
    ElementRef,
    TemplateRef,
    ViewChild, OnInit
} from '@angular/core';

import AdminPanelState from '../../../../projects/cityscreen/src/components/admin-panel/state';
import AdminPanelActions from '../../../../projects/cityscreen/src/components/admin-panel/actions';

import { UsersStateService, Pages } from '../../users-state.service';
import { TEXTS } from '../../../texts/texts';
import { ADMIN_ID, OBSERVER_ID, OPERATOR_ID } from '../../../config';
import Actions from '../../../../map/actions';
import { UserItems } from '../../../namespace';

@Component({
    selector: 'users-page',
    templateUrl: 'users-page.component.html'
})
export class UsersPage {
    @Input() users: UserItems[];
    @Input() deleteUser: () => void;

    apActions: AdminPanelActions;

    TEXTS = TEXTS;

    OBSERVER_ID = OBSERVER_ID;
    OPERATOR_ID = OPERATOR_ID;

    notShowTooltip = false;

    pages = Pages;

    @ViewChild('popupOpener', { static: true }) popupOpener: ElementRef<HTMLDivElement>;
    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    constructor(public stateService: UsersStateService) {
    }


    hideDeleteUserPopup = () => this.stateService.isShowDeleteUserPopup = false;
}
