import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { ChartsModule } from 'src/chart/charts.module';
import { CalendarModule } from 'src/calendar/calendar.module';

import { AnalyticsModule } from 'src/analytics/analytics.module';
import { NotificationsModule } from 'projects/cityscreen/src/modules/notifications/notifications.module';
import { ConfigModule } from '../config-menu/config.module';
import { UsersModule } from 'src/users/users.module';

import { AdminPanelComponent } from './admin-panel.component';
import { PlumesModule } from 'projects/cityscreen/src/modules/plumes/components/plumes.module';

import { SidebarModule } from 'projects/shared/modules/sidebar/sidebar.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { PopupsModule } from 'projects/cityscreen/src/components/popups/popups.module';
import { DashboardModule } from '../../modules/dashboard/dashboard.module';
import { RouterModule } from '@angular/router';
import { PostsAndDevicesModule } from '../../modules/posts-and-devices/posts-and-devices.module';
import { GroupChangeSelectorModule } from 'projects/cityscreen/src/components/group-change-selector/group-change-selector.module';
import { FeedbackComponentModule } from 'projects/cityscreen/src/components/feedback/feedback.component.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        LittleComponentsModule,
        ChartsModule,
        CalendarModule,
        AnalyticsModule,
        NotificationsModule,
        ConfigModule,
        UsersModule,
        PlumesModule,
        PostsAndDevicesModule,
        SidebarModule,
        TooltipsModule,
        PopupsModule,
        DashboardModule,
        RouterModule,
        GroupChangeSelectorModule,
        FeedbackComponentModule,
    ],
    exports: [
        AdminPanelComponent,
    ],
    declarations: [
        AdminPanelComponent,
    ]
})
export class AdminPanelModule {}
