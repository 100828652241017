import * as moment from 'moment';

import {
    Country,
    DistributionSummaryItems,
    HistogramAqi10Response,
    Location,
    TimelineInfoResponce
} from './cityairMapModels';
import {
    AQI,
    City_model,
    HUM,
    Marker_model,
    MeasuresForTime,
    MeasuresInfo_model,
    MeasuresInfoFormat,
    PRES,
    SeasonsName,
    StationData,
    TEMP,
    WindowGlobalVars,
} from 'src/namespace';
import { ALERT_COLORS, getStationType, MEASURE_SORT_ALL, PACKET_VALUE_TYPE_ID } from 'src/config';
import {
    declOfNum,
    getAqiForZones,
    getCityIdForCoords,
    getQualityForecastText,
    getQualityText,
    isFalseNumber,
    isTimelineAqiLowDetail
} from 'src/utils';
import { nowDateTimeLong } from 'src/utils/date-formats';
import { isRU, TEXTS } from 'src/texts/texts';
import { MapMoTimeline, MoItem } from './cityairMapDtos';
import { createOriginChartData } from 'src/chart/model';
import { GetObserverPacketsResponce } from '../adminPanel/models';
import { PacketsValueTypeItem } from 'harvester/UiAdminProject8/src/commonData/models';
import { DistributionSummary } from 'projects/cityscreen/src/modules/indoor/services/api';

declare let window: WindowGlobalVars;

export class CityairMapDataTransformerResponse {
    cities: City_model[];
    citiesForAdminPanel?: City_model[];
    citiesMarkers: Marker_model[];
    moMarkers: Marker_model[];
    myMarkers: Marker_model[];
}

export function createStatisticSeason(data: DistributionSummaryItems): DistributionSummary {
    const summary = {};

    Object.keys(data).forEach((key: SeasonsName) => {
        const _d = data[key];

        summary[key] = {
            firstPacketDate: _d.FirstPacketDate,
            distributionAqi: _d.DistributionAqi.map(a => ({aqi: a.Aqi, percent: a.Percent})),
            distributionDayHour: _d.DistributionDayHour.map(a => ({aqi: a.Aqi, hourOfDay: a.HourOfDay})),
            distributionWeekDay: _d.DistributionWeekDay.map(a => ({aqi: a.Aqi, dayOfWeek: a.DayOfWeek})),
        }
    })

    return summary as DistributionSummary;
}

export function getLastCityData(city: City_model, measureInfo: MeasuresInfoFormat): [number, string] {
    if (!measureInfo) {
        return [null, ''];
    }

    const origin = city.originChartData[measureInfo.name];

    if (!origin || !origin.data || !origin.data.length) {
        return [null, ''];
    }

    const val = origin.data[origin.data.length - 1][1];
    let measure = '';

    if (measureInfo.type !== AQI) {
        measure = measureInfo.unit;
    } else {
        measure = declOfNum(val, TEXTS.AQI_DECL);
    }

    return [Math.round(val), measure];
}

export function calcMeasures(measuresIds: number[], packetValueType: PacketsValueTypeItem[], deleteArray): MeasuresInfo_model {
    const measuresInfo: MeasuresInfo_model = {
        0: {
            name: AQI,
            type: AQI,
            isHidden: false,
            order: 0,
            unit: TEXTS.measures.get(AQI)
        }
    };

    measuresIds.forEach(id => {
        let name: string;
        let vType: PacketsValueTypeItem;

        vType = packetValueType.find(t => t.ValueType === id);

        if (!vType) {
            return window.sentryCaptureException ? window.sentryCaptureException(new Error(`не нашел ValueType = ${id}`)) : null;
        }

        name = isRU ? vType.TypeNameRu || vType.TypeName : vType.TypeName || vType.TypeNameRu;

        if (vType.IsHidden) {
            deleteArray.forEach(d => delete d.values[id]);
            return;
        }

        const _type = PACKET_VALUE_TYPE_ID[vType.ValueType] || vType.TypeName;

        if (TEXTS.TOOLTIPS[_type] === name) {
            // для сокращенного названия T P RH
            name = TEXTS.NAMES[_type];
        }

        measuresInfo[id] = {
            type: _type,
            name,
            isHidden: vType.IsHidden,
            order: vType.TypeOrder,
            unit: vType.Measurement
        }
    });

    return measuresInfo;
}


export function createStndMarker(timeline: MapMoTimeline[], mo: MoItem, cities: City_model[]) {
    const cityByLocationId = mo.LocationId ? cities.find(c => c.locationId === mo.LocationId) : null;

    const cityId = cityByLocationId?.id || getCityIdForCoords(cities, mo.DotItem.Latitude, mo.DotItem.Longitude);
    const city = cityByLocationId || cities.find(c => c.id === cityId);
    // provide any available name prioritized by language (used in public map)
    const pubName = isRU ? mo.PublishNameRu || mo.PublishName : mo.PublishName || mo.PublishNameRu;

    return <Marker_model>{
        type: getStationType(mo.DataProviderId, mo.AccessGranted),
        id: mo.MoId,
        aqis: timeline.map((a) =>
            ({
                aqi: a.VtAqi?.CityairAqi,
                timestamp: new Date(a.Time).getTime()
            })),
        lat: mo.DotItem.Latitude,
        lng: mo.DotItem.Longitude,
        name: pubName,
        isOurStation: mo.AccessGranted,
        tzOffset: mo.GmtOffsetSeconds / 60,
        city,
        cityId
    };
}

export function convertLocation(loc: Location, Countries: Country[]): { cityMarker: Marker_model, city: City_model } {
    const id = loc.LocationUrl;
    const lat = loc.Latitude;
    const lng = loc.Longitude;
    const tzOffset = loc.GmtOffsetSeconds / 60;
    const msOffset = loc.GmtOffsetSeconds * 1000;
    const name = isRU ? loc.NameRu : loc.Name;
    const countryName: string = (() => {
        if (!Countries)
            return '';

        const find = Countries.find(country => loc.CountryId && loc.CountryId === country.CountryId);

        if (!find)
            return '';

        return isRU ? find.NameRu : find.Name;
    })();

    const cityMarker = new Marker_model({
        type: 'city',
        id: loc.LocationId,
        cityId: id,
        name,
        tzOffset,
        lat,
        lng
    });

    const city = new City_model({
        id: loc.LocationUrl,
        locationId: loc.LocationId,
        name,
        countryName,
        bounds: {
            east: loc.BounceEast,
            north: loc.BounceNorth,
            south: loc.BounceSouth,
            west: loc.BounceWest,
        },
        tzOffset,
        msOffset,
        lat,
        lng,

        zIndex: loc.SortRank,
        isSmallCity: loc.IsSmall,
    });

    return {cityMarker, city};
}

export function createAqiInCity(city: City_model,
                                cityMarker: Marker_model,
                                timeline: MapMoTimeline[],
                                loc: Location,
                                PacketValueTypes: PacketsValueTypeItem[],
                                timeBegin: number,
                                timeEnd: number,
                                DistributionSummaryItems?: DistributionSummaryItems
) {
    const lastTimeline = timeline[timeline.length - 1];
    // if (!lastTimeline.VtAqi) {
    //     lastTimeline.VtAqi = timeline[timeline.length - 2]?.VtAqi;
    // }

    cityMarker.aqis = timeline.map((a) => ({
        aqi: a.VtAqi?.CityairAqi,
        timestamp: new Date(a.Time).getTime()
    }));

    const measuresIds: number[] = [];
    const valuesChart = timeline.map(t => {
        const val = {};
        if (!t.Data) {
            return {
                time: new Date(t.Time).getTime(),
                values: {0: t.VtAqi?.CityairAqi}
            };
        }

        t.Data.forEach(d => {
            val[d.VT] = d.V;

            if (!measuresIds.includes(d.VT)) {
                measuresIds.push(d.VT);
            }
        });

        return {
            time: new Date(t.Time).getTime(),
            values: {
                0: t.VtAqi?.CityairAqi,
                ...val
            }
        };
    });

    const measuresInfo: MeasuresInfo_model = calcMeasures(measuresIds, PacketValueTypes, valuesChart);

    city.aqiHistory = timeline.map(t => {
        return {
            aqi: t.VtAqi ? t.VtAqi.CityairAqi : 1,
            time: new Date(t.Time).getTime()
        }
    });

    city.originChartData = createOriginChartData(
        valuesChart,
        moment().utcOffset(),
        timeBegin,
        timeEnd,
        isTimelineAqiLowDetail(timeBegin, timeEnd) ? 4 : 3,
        measuresInfo
    );

    city.distributionSummary = DistributionSummaryItems ? createStatisticSeason(DistributionSummaryItems) : null,

    [AQI, TEMP, HUM, PRES].forEach(type => {
        const index = Object.keys(measuresInfo).find(key => measuresInfo[key].type === type);
        city.lastData[type] = getLastCityData(city, measuresInfo[index]);
    });

    city.lastData.time = nowDateTimeLong(moment(timeline[timeline.length - 1].Time).toDate(), loc.GmtOffsetSeconds / 3600);

    if (timeline[timeline.length - 2]) {
        city.lastData.textForecast = getQualityForecastText(timeline[timeline.length - 1].Aqi, timeline[timeline.length - 2].Aqi);
    }
    city.lastData.textQuality = getQualityText(city.lastData[AQI][0]);
}

export function cityairMapDataTransformer(data: TimelineInfoResponce, timeBegin: number, timeEnd: number): CityairMapDataTransformerResponse {
    const citiesMarkers: Marker_model[] = [];
    const cities: City_model[] = [];

    data.Locations
        .slice()
        .sort((a, b) =>  a.Location.SortRank - b.Location.SortRank)
        .forEach((loc) => {
            if (!loc.Timeline || !loc.Timeline.length)
                return;

            const {cityMarker, city} = convertLocation(loc.Location, data.Countries);

            createAqiInCity(city, cityMarker, loc.Timeline, loc.Location, data.PacketValueTypes, timeBegin, timeEnd, loc.Location.DistributionSummary?.DistributionSummaryItems);

            cities.push(city);

            cityMarker.city = city;
            citiesMarkers.push(cityMarker);
        });


    const myMarkers: Marker_model[] = [];
    const moMarkers: Marker_model[] = [];
    data.MoItems.forEach(mo => {
        if (mo.Timeline) {
            const markers = mo.AccessGranted ? myMarkers : moMarkers;
            markers.push(createStndMarker(mo.Timeline, mo, cities));
        }
    });


    cities.sort((a, b) => {
        if (a.name > b.name)
            return 1;
        if (a.name < b.name)
            return -1;
        return 0;
    });

    return {
        cities,
        citiesMarkers,
        moMarkers,
        myMarkers
    }
}

export function histogramTransformer(data: HistogramAqi10Response) {
    const _data = data.HistogramMap;
    delete _data['0'];

    const count = Object.keys(_data).reduce( (y, key) => y + _data[key], 0);

    return Object.keys(_data).map( key => {return{
        x: parseInt(key, 10),
        y: _data[key] / count * 100,
        color: ALERT_COLORS[key]
    }})
}


export function transformMeasures(data: {[measure: string]: number}): {[measure: string]: number} {
    const resp = {};

    MEASURE_SORT_ALL.forEach(m => {
        if (!isFalseNumber(data[m])) {
            resp[m] = data[m];
        }
    });

    return resp;
}


export function objectMonitorDataTransformer (
    _data: GetObserverPacketsResponce,
    packetValueType: PacketsValueTypeItem[],
    calcAqi?: {
        vt: number,
        zones: number[]
    }
): StationData {
    const measuresIds: number[] = [];
    let lastPacket = 0;
    let lastPacketTime = 0;

    const data: MeasuresForTime[] = _data.Packets.map((item, index) => {
        if (item.Timestamp > lastPacket) {
            lastPacket = item.Timestamp;
            lastPacketTime = new Date(item.SendDate).getTime()
        }

        const mObj = {
            time: new Date(item.SendDate).getTime(),
            values: {}
        };

        item.Data.forEach(a => {
            mObj.values[a.VT] = a.V;

            if (!measuresIds.includes(a.VT)) {
                measuresIds.push(a.VT);
            }
        });

        if (calcAqi) {
            const V = item.Data.find(d => d.VT === calcAqi.vt)?.V;
            mObj.values[0] = isFalseNumber(V) ? null : getAqiForZones(calcAqi.zones, V);
        } else {

            const aqi = item.VtAqi?.CityairAqi;
            // if (isFalseNumber(aqi))
            //     aqi = _data.Packets[index - 1]?.VtAqi?.CityairAqi;
            // if (isFalseNumber(aqi))
            //     aqi = _data.Packets[index - 2]?.VtAqi?.CityairAqi;

            mObj.values[0] = aqi;
        }

        return mObj;
    });

    const measuresInfo = calcMeasures(measuresIds, packetValueType, data);

    return {
        lastPacketID: lastPacket,
        packets: data,
        measuresInfo,
        lastPacketTime
    };
}
