import { Component } from '@angular/core';

import { ALERT_COLORS, formatterFlagTime, measureZones } from 'src/config';
import { PM10, PM2 } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { ANIMATION_OPACITY } from '../../../../shared/utils/config';
import { findPointFor } from 'src/utils';
import { map } from 'rxjs/operators';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

@Component({
    selector: 'aqi-legend',
    templateUrl: 'aqi-legend.component.html',
    styleUrls: ['aqi-legend.component.less'],
    animations: ANIMATION_OPACITY
})
export class AqiLegend {
    TEXTS = TEXTS;
    ALERT_COLORS = ALERT_COLORS;

    arr = [0, 1, 2];
    pm2 = [0, ...measureZones.get(PM2)];
    pm10 = [0, ...measureZones.get(PM10)];

    showLegend = false;

    constructor(public readonly sharedCoreFacade: SharedCoreFacade, public time: Time) {}

    avgAqi$ = this.time.timeUpdated.pipe(map(data => {
        const aqiHistory = this.sharedCoreFacade.getAqiHistory();
        const originalIndex = findPointFor(aqiHistory, data.time, 'time');

        const hour = aqiHistory[originalIndex];
        return hour ? hour.aqi || 1 : 1;
    }));

    toggleLegend = () => this.showLegend = !this.showLegend;

    close = () => this.showLegend = false;

    formatterFlagTime = formatterFlagTime;

    findSelectAqi = (i: number) => {
        if (i >= 1 && i <= 3)
            return this.arr[0];
        if (i >= 4 && i <= 7)
            return this.arr[1];
        if (i >= 8 && i <= 10)
            return this.arr[2];
    }
}
