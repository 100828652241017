import { ApiRequest, DeviceSourceItem, DeviceItem, MoItem, MoLocationItem, RegionCoefsItem, PacketValueType, PacketsValueTypeItem, ErrorResult } from "../../models";
import { MoIntervalType } from "../moApiProvider/moApiModels";
import { DeviceExportDesc } from '../devicesApiProvider/devicesApiModels';
import { TsFuncs } from "../../../utils/tsFuncs";

// ------------------------------

export enum NotificationSubscribeType {
    None = 0,
    PdkExcess, 
    ServiceDevice
};

export enum NotificationEventType {
    None = 0,
    Begin,
    Continue,
    End
}

// ------------------------------

export class GroupsRolesItem {
    GroupRoleId: number;
    Name: string;
    NameRu: string;

    EditGroup: boolean;
    EditStation: boolean;
    EditUser: boolean;
    EditDeviceProperties: boolean;
    DownloadDeviceData: boolean;

    ViewDeviceData: boolean;
    ViewStationData: boolean;

    AllowUiAdminApp: boolean;
}

export class GroupsModuleItem {
    GroupModuleId: number;
    Name: string;
    NameRu: string;
}

export class GroupsRolesMemberItem {
    UserId: number;
    GroupId: number;
    GroupRoleId: number; // if GroupRoleId == 0 remove group role from Login
}

export class GroupsMosUsersItem {
    UserId: number;
    GroupId: number;
    MonitoringObjectsIds: number[];
}

export class PdkMrMoCheckSetting {
    IsForAllMoItems: boolean = true;
    SelectedMoItems: number[] = [];

    IsForAnyValueType: boolean = true;
    SelectedValueTypes: PacketValueType[] = [];

    IntervalType: MoIntervalType = MoIntervalType.Interval20M;

    // Умножение этого параметра на PdkMr даёт величину выше которой будет срабатывать оповещение
    PdkMrMultiplier: number = 1.5;

    // Уведомлять о продолжении превышения (Мыло о Continue или поднимать плашку в Feed)
    IsContinueNotify: boolean = false;
}

export class ServiceDeviceCheckSetting {
    NoDataFromDevicesWithMoDurationInHours: number = 3;
    NoPs220InDevicesWithMoDurationInHours: number = 3;
    NotifyCertificateExpirationInDays: number = 14;
}

export class NotifyMethodSetting {
    // Уведомлять всех членов группы (кроме наблюдателей),
    // NotificationFeedUserIds - не действует
    NotifyAllGroupMembers: boolean;

    // Иды юзеров которые будут видеть оповещения от данного NotificationId
    NotificationFeedUserIds: number[];

    // Email'ы по которым будут рассылаться уведомления почтой
    NotificationEmails: string[] = [];

    // Список Emails для отсылки "отчета за день"
    DailyReportEmails: string[] = [];
    DailyReportStartHour: number = 0;
    DailyReportTimeZone: number = TsFuncs.getTimeZone();
}

export class GroupNotificationSetting {
    NotificationId: number;
    NotificationTitle: string;
    GroupId: number;
    IsActive: boolean;
    SubscribeType: NotificationSubscribeType;

    NotifySetting: NotifyMethodSetting;
    PdkCheckSetting: PdkMrMoCheckSetting;
    ServiceCheckSetting: ServiceDeviceCheckSetting;
}

export enum GroupNotificationChangeType
{
    Add = 0, Edit, Delete
}

export class GroupNotificationChange {
    ChangeType: GroupNotificationChangeType;
    Item: GroupNotificationSetting;
}

//------------------------------------------

export class GroupItem {
    GroupId: number;
    Name: string;
    Description: string;
    MoIds: number[];
    DeviceIds: number[];
    LocationIds: number[];
    RoleMembers: GroupsRolesMemberItem[];
    MosUsers: GroupsMosUsersItem[];
    ModulesIds: number[];
    RegionCoefsId?: number;
    ExtConfig: { [key: string]: string };
    GroupIconUrl: string;
    GroupWhiteLabelUrl: string;

    // front only
    StationsCount: number;
    UserStationsCount: number;
    IsChecked: boolean;

    constructor(groupId: number, name: string) {
        this.GroupId = groupId;
        this.Name = name;
        this.Description = "";
        this.MoIds = [];
        this.DeviceIds = [];
        this.LocationIds = [];
        this.RoleMembers = [];
        this.MosUsers = [];
        this.ExtConfig = {};
    }
}

// ------------------------------

export class UserPortalPageItem {
    Title: string;
    IsChecked: boolean;

    constructor(title: string, isChecked: boolean) {
        this.Title = title;
        this.IsChecked = isChecked;
    }
}

export class CpUserItem {
    UserId: number;
    Login: string;
    Email: string;
    BioInfo: string;
    SiteLang: string;
    Tag: string;
    IsAdmin: boolean;
    MeasureScheme: string;

    // Используется внутри moLinksView
    GroupRoleId: number;
}

export class CpUserItemPermission {
    UserId: number;
    Login: string;

    MoIds: number[];
    DeviceIds: number[];
    LocationIds: number[];
    GroupMembers: GroupsRolesMemberItem[];
}

export class UserTokenRequest extends ApiRequest {
    Item: CpUserToken;

    constructor(tokenId: number, userId: number, title: string) {
        super();
        this.Item = {
            TokenId: tokenId,
            UserId: userId,
            Title: title,
            CreateDate: new Date(),
            LastDate: new Date(),
            ExecCount: 0,
            IsApiKey: false,
            RequestIp: null,
            UserAgent: null
        };
    }
}

//-------------------------------

export class AdminApiRequest extends ApiRequest {
}

export class GroupsListResponse {
    Groups: GroupItem[];
    GroupsRoles: GroupsRolesItem[];
    GroupsModules: GroupsModuleItem[];
    CityairLocations: MoLocationItem[];
    PacketValueTypes: PacketsValueTypeItem[];
}

//--------------

export class GroupRequest extends AdminApiRequest {
    Item: GroupItem;

    constructor(item: GroupItem) {
        super();
        this.Item = item;
    }
}

export class GroupInfoResponse {
    Group: GroupItem;
    GroupsRoles: GroupsRolesItem[];
    RegionCoefs: RegionCoefsItem[];
    DeviceExportTypes: DeviceExportDesc[];
    GroupNotificationSettings: GroupNotificationSetting[]; 
    DeviceSources: DeviceSourceItem[];
    Devices: DeviceItem[];
    MonitoringObjects: MoItem[];
    Users: CpUserItem[];
}

// --------------

export class GroupNotificationChangeRequest extends AdminApiRequest {
    ChangeType: GroupNotificationChangeType;
    Item: GroupNotificationSetting;

    constructor(changeType: GroupNotificationChangeType, item: GroupNotificationSetting) {
        super();
        this.ChangeType = changeType;
        this.Item = item;
    }
}

// --------------

export class GroupEditPermissionRequest extends AdminApiRequest {
    GroupId: number;
    ItemId: number;
    Permit: boolean;

    constructor(groupId: number, itemId: number) {
        super();
        this.GroupId = groupId;
        this.ItemId = itemId;
        this.Permit = false;
    }
}

// --------------

export class GroupRoleMemberRequest extends AdminApiRequest {
    Item: GroupsRolesMemberItem;

    constructor(item: GroupsRolesMemberItem) {
        super();
        this.Item = item;
    }
}

//--------------

export class UserItemRequest extends AdminApiRequest {
    Item: CpUserItem;

    constructor(item: CpUserItem) {
        super();
        this.Item = item;
    }
}

export enum EmailTypeEnum {
    Welcome = 0,
    ResetPwd
}

export class UserItemGroupRequest extends UserItemRequest {
    GroupId: number;
    EmailType: EmailTypeEnum;

    constructor(item: CpUserItem, groupId: number, emailType: EmailTypeEnum) {
        super(item);
        this.GroupId = groupId;
        this.EmailType = emailType;
    }
}

export class UsersListResponse {
    MonitoringObjects: MoItem[];
    DeviceItems: DeviceItem[];
    Groups: GroupItem[];
    GroupsRoles: GroupsRolesItem[];
    MeasureSchemes: string[];
    Users: CpUserItem[];
}

export  class UserPermissionResponse {
    Permission: CpUserItemPermission;
    Tokens: CpUserToken[];
    ActiveTokenId?: number;
}

export class CpUserToken {
    TokenId: number;
    UserId: number;
    Title: string;
    IsApiKey: boolean;
    CreateDate: Date;
    LastDate: Date;
    ExecCount: number;
    RequestIp: string;
    UserAgent: string;
}

//----------------------------------------------

export class MoExcessInfo {
    MoId: number;
    Name: string;
    TimeZoneIana: string;
    GmtOffsetSeconds: number;
}

export class PdkMoExcessContent {
    MoInfo: MoExcessInfo;
    MaxValue: number;
    PdkMrValue: number;
    PdkMrMultiplier: number;
}

export enum ServiceExcessType {
    Unknown = 0,
    DeviceNoPs220 = 1,
    DeviceNoData = 2
}

export class DeviceExcessInfo {
    DeviceId: number;
    SerialNumber: string;
    DeviceSource: string;
}

export class ServiceExcessContent {
    MoInfo: MoExcessInfo;
    DeviceInfo: DeviceExcessInfo;
}

export class NotificationFeedItem {
    FeedItemId: number;
    NotificationId: number;
    NotificationTitle: string;
    SubscribeType: NotificationSubscribeType;
    EventType: NotificationEventType;

    BeginTime: Date;
    EndTime?: Date;

    // Pdk
    PdkMoId: number;
    PdkValueType: PacketValueType;
    PdkMoContent: PdkMoExcessContent;

    // Service
    ServiceDeviceId: number;
    ServiceExcessType: ServiceExcessType;
    ServiceContent: ServiceExcessContent;

    UpdateTime: Date;
    IsViewed?: boolean;
}

export class UserFeedItemRequest extends AdminApiRequest {
    FeedUserId: number;
    Item: NotificationFeedItem;

    constructor(feedUserId: number, item: NotificationFeedItem) {
        super();
        this.FeedUserId = feedUserId;
        this.Item = item;
    }
}

export class UserFeedResponse {
    PacketValueTypes: PacketsValueTypeItem[];
    FeedItems: NotificationFeedItem[];
}

export class FeedItemsExportRequest extends AdminApiRequest {
    NotificationId: number;
    TimeBegin: Date;
    TimeEnd: Date;
    TimeZone: number;
    Lang: string;
}

//--------------

export class SetRegionCoefsItemRequest extends AdminApiRequest {
    Item: RegionCoefsItem;

    constructor(item: RegionCoefsItem) {
        super();
        this.Item = item;
    }
}

//--------------

export class CityairLicense {
    Name: string;
    ExpirationDate: Date;
    CityScreenModules: string[];
    Stations: string[];
    GModules: string[];
}

export class CityairLicenseRequest extends AdminApiRequest {
    Item: CityairLicense;

    constructor(item: CityairLicense) {
        super();
        this.Item = item;
    }
}

export class CityairLicenseImportRequest extends AdminApiRequest {
    LicenseBase64: string;

    constructor(licenseBase64: string) {
        super();
        this.LicenseBase64 = licenseBase64;
    }
}

//--------------

export interface IAdminApiProvider {
    getGroupsList(request: AdminApiRequest, execOk: (response: GroupsListResponse) => void, execFail?: (errRes: ErrorResult) => void): void;
    getGroupInfo(request: GroupRequest, execOk: (response: GroupInfoResponse) => void, execFail?: (errRes: ErrorResult) => void): void;

    createGroup(request: GroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editGroup(request: GroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    deleteGroup(request: GroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    changeGroupNotificationSetting(request: GroupNotificationChangeRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editGroupAddDevicePermission(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editGroupRemoveDevicePermission(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    editGroupAddMoPermission(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editGroupRemoveMoPermission(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    editGroupAddLocPermission(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editGroupRemoveLocPermission(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    editGroupSetModuleAccess(request: GroupEditPermissionRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    //editGroupPermissions(request: GroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editGroupUserMoPermissions(request: GroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    editGroupSetExtConfig(request: GroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    addNewMemberToGroup(request: GroupRoleMemberRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editMemberRoleInGroup(request: GroupRoleMemberRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    removeMemberFromGroup(request: GroupRoleMemberRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    generateCityairLicense(request: CityairLicenseRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    importCityairLicense(request: CityairLicenseImportRequest, execOk: (response: CityairLicense) => void, execFail?: (errRes: ErrorResult) => void): void;
    getActiveCityairLicense(request: AdminApiRequest, execOk: (response: CityairLicense) => void, execFail?: (errRes: ErrorResult) => void): void;

    getUsersList(request: AdminApiRequest, execOk: (response: UsersListResponse) => void, execFail?: (errRes: ErrorResult) => void): void;
    getUserPermission(request: UserItemRequest, execOk: (response: UserPermissionResponse) => void, execFail?: (errRes: ErrorResult) => void): void;
    upsertUserToken(request: UserTokenRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    deleteUserToken(request: UserTokenRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    getUserTokenValue(request: UserTokenRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    getUserNotificationFeeds(request: UserFeedItemRequest, execOk: (response: UserFeedResponse) => void, execFail?: (errRes: ErrorResult) => void): void;
    markNotificationFeedViewed(request: UserFeedItemRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    exportFeedItemsToExcel(request: FeedItemsExportRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    userItemCreate(request: UserItemRequest, execOk: (response: CpUserItem) => void, execFail?: (errRes: ErrorResult) => void): void;
    userItemEdit(request: UserItemRequest, execOk: (response: CpUserItem) => void, execFail?: (errRes: ErrorResult) => void): void;
    userItemDelete(request: UserItemRequest, execOk: (response: CpUserItem) => void, execFail?: (errRes: ErrorResult) => void): void;
    userItemSetPassword(request: UserItemRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    userItemSendWelcome(request: UserItemGroupRequest, execOk: (response: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    setRegionCoefsItem(request: SetRegionCoefsItemRequest, execOk: (response: RegionCoefsItem) => void, execFail?: (errRes: ErrorResult) => void): void;
}
