type ColorStop = {
    percent: number;
    color: string;
};

export type GradientConfig = {
    colorStops: ColorStop[];
    angleDeg: number;
};

const COLOR_STOP_ADJUSTMENT = 0.2;

// use on: beforeInit
export function createBackgroundGradientsPlugin(size: number, gradients: GradientConfig[]) {
    return (chart: Chart) => {
        const backgrounds = gradients.map(({ colorStops, angleDeg }) => {
            const t = Math.tan(angleDeg * Math.PI / 180);
            const offset = angleDeg < 45 ? size * t : size / t;
            const dStart = (size - offset) / 2;
            const dEnd = (size + offset) / 2;

            const gradient = angleDeg < 45
                ? chart.ctx.createLinearGradient(dStart, 0, dEnd, size)
                : chart.ctx.createLinearGradient(0, dStart, size, dEnd);

            colorStops.forEach((colorStop, i) => {
                let percent = colorStop.percent;

                if (colorStops.length > 1) {
                    if (i === 0) {
                        const adjusted = percent + COLOR_STOP_ADJUSTMENT;

                        if (adjusted < 0.5) {
                            percent = adjusted;
                        }
                    }

                    if (i === colorStops.length - 1) {
                        const adjusted = percent - COLOR_STOP_ADJUSTMENT;

                        if (adjusted > 0.5) {
                            percent = adjusted;
                        }
                    }
                }

                gradient.addColorStop(percent, colorStop.color);
            });

            return gradient;
        });

        chart.config.data.datasets[0].backgroundColor = backgrounds;
    };
}
