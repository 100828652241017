import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
    selector: 'btn-accept',
    templateUrl: './btn-accept.component.html',
    styleUrls: ['./btn-accept.component.less']
})
export class BtnAcceptComponent {
    @Input() disabled = false;
    @Input() isLoading = false;
    @Output() cbClick = new EventEmitter<void>();

    click () {
        this.cbClick.emit();
    }
}
