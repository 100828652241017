import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectGroupInfo, selectGroupList } from 'projects/cityscreen/src/modules/core/store/selectors';
import { TEXTS } from 'src/texts/texts';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';

@Component({
    selector: 'cityscreen-mobile-profile',
    templateUrl: './mobile-profile.component.html',
    styleUrls: ['./mobile-profile.component.less']
})
export class MobileProfileComponent implements OnInit {
    TEXTS = TEXTS;
    selectGroupList = selectGroupList;
    selectGroupInfo = selectGroupInfo;
    show = false;

    textC: string;
    currentGroupId: number;

    constructor(public store: Store, public coreFacade: CoreFacade) {
    }

    ngOnInit(): void {
        this.store.select(selectGroupInfo).pipe(filter(g => !!g?.groupId), take(1)).subscribe(g => {
            this.currentGroupId = g.groupId;
        });

        this.textC = `© ${moment().get('year')} `
    }

    setLang(lang: string) {
        localStorage.setItem('lang', lang);
        location.reload();
    }

}
