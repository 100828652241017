<div class="notifications_panel">
    <div class="notifications_subscriptions__main"
        *ngIf="stateService.currentPage !== pages.SETTINGS"
    >
        <div class="notifications_subscriptions__main_wrapper">
            <header-page
                [textObject]="{
                    titlePage: TEXTS.ADMIN_PANEL.notifications,
                    btnBack: TEXTS.ADMIN_PANEL.back
                }"
            ></header-page>
        </div>
        <div class="notifications_subscriptions__tabs-wrapper">
            <ca-page-tabs
                *ngIf="stateService.canEdit()"
                [tabs]="pageTabs"
                [selectedTab]="selectedTab"
                [isFullWidth]="true"
                (showTab)="showSelectedTab($event)"
            ></ca-page-tabs>
        </div>
    </div>
    <ng-container [ngSwitch]="stateService.currentPage">
        <event-feed *ngSwitchDefault></event-feed>
        <subscriptions *ngSwitchCase="pages.SUBSCRIPTIONS"></subscriptions>
        <settings *ngSwitchCase="pages.SETTINGS"></settings>
    </ng-container>
</div>
