import {
    Component,
    Input
} from '@angular/core';

import { SortingColumn } from 'src/namespace';

@Component({
    selector: 'sort-list',
    templateUrl: 'sort-list.component.html',
    styleUrls: ['sort-list.component.less']
})
export class SortList {
    @Input() cbSortFun: (prop: any) => void;
    @Input() properties: SortingColumn[] = [];
    @Input() sortingProps: (prop: any) => string | number | boolean;
    @Input() sortingDirection = 1;
}
