import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'src/calendar/calendar.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';

import { AqiLegend } from './aqi-legend/aqi-legend.component';
import { Logout } from './logout/logout.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { ShowModelBtn } from './model-panel/model-panel.component';
import { LayersButtonModelsComponent } from './layers-button-models/layers-button-models.component';
import { LayersButtonPlumesComponent } from './layers-button-plumes/layers-button-plumes.component';
import { CsTopRightElements } from './top-right-elements/top-right-elements.component';
import { RunSummaryComponent } from './run-summary/run-summary.component';
import { ActiveInactiveTagComponent } from './active-inactive-tag/active-inactive-tag.component';
import { SubstanceTagComponent } from './substance-tag/substance-tag.component';
import { DownloadPopupsWrapperComponent } from './download-popups-wrapper/download-popups-wrapper.component';
import { PopupsModule } from 'projects/cityscreen/src/components/popups/popups.module';
import { WidgetsModule } from 'projects/shared/modules/widgets/widgets.module';
import { MobileHeaderModule } from './mobile-header/mobile-header.module';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { KebabMenuModule } from '../../../shared/components/kebab-menu/kebab-menu.module';
import { StaticTagsModule } from './static-tags/static-tags.module';
import { InfoDevice } from 'projects/cityscreen/src/components/info-device/info-device.component';
import { GroupListItemModule } from 'projects/cityscreen/src/components/group-list-item/group-list-item.module';
import { TopRightElementsMobile } from 'projects/cityscreen/src/components/top-right-elements-m/top-right-elements-m.component';
import { OverlayModule } from 'projects/shared/modules/overlay/overlay.module';

@NgModule({
    imports: [
        DirectivesModule,
        LittleComponentsModule,
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        CalendarModule,
        SharedComponentsModule,
        PopupsModule,
        WidgetsModule,
        MobileHeaderModule,
        KebabMenuModule,
        StaticTagsModule,
        GroupListItemModule,
        OverlayModule,
        OnboardingModule,
    ],
    exports: [
        AqiLegend,
        Logout,
        CsTopRightElements,
        TopRightElementsMobile,
        LayersButtonModelsComponent,
        LayersButtonPlumesComponent,
        ShowModelBtn,
        RunSummaryComponent,
        ActiveInactiveTagComponent,
        SubstanceTagComponent,
        DownloadPopupsWrapperComponent,
        MobileHeaderComponent,
        InfoDevice,
        OnboardingModule,
    ],
    declarations: [
        AqiLegend,
        Logout,
        CsTopRightElements,
        TopRightElementsMobile,
        LayersButtonModelsComponent,
        LayersButtonPlumesComponent,
        ShowModelBtn,
        RunSummaryComponent,
        ActiveInactiveTagComponent,
        SubstanceTagComponent,
        DownloadPopupsWrapperComponent,
        InfoDevice,
    ]
})
export class CityscreenComponentsModule {
}
