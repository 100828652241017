<div class="textarea" [formGroup]="form">
    <div class="textarea__input_wrapper">
        <textarea #textareaText [ngClass]="{
                    'textarea__input scroll': true,
                    'textarea__input-disabled': disabled,
                    'textarea__input-error': textError
                    }" formControlName="{{controlName}}" autocomplete="off" value="{{valueForm}}"></textarea>
        <div [ngClass]="{
                'textarea__label': true,
                'textarea__label-active': valueForm || textError
                }">
            {{label}}
        </div>
    </div>
    <div [ngClass]="{'textarea__description': true, 'textarea__description-info': !textError && textInfo}">
        <ng-container *ngIf="!textError && textInfo">
            {{textInfo}}
        </ng-container>
    </div>
    <div [ngClass]="{'textarea__description': true, 'textarea__description-error': textError}">
        <ng-container *ngIf="textError">
            {{textError}}
        </ng-container>
    </div>
</div>

