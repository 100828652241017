import type { DomainConfigType, DataType, TileType } from './domain-config.type';
import { Substance } from './substance.enum';

export class DomainConfig {
    dateFormat = 'YYYYMMDD_HHmmss';

    timeStep = 3600 * 1000; // 1 hour

    opacity = 0.5;

    url?: string;

    minzoom?: number;

    maxzoom?: number;

    domain: DomainConfigType;

    constructor(options: Partial<DomainConfig> & { domain: DomainConfigType }) {
        Object.assign(this, options);
    }

    getImagePath(substance: Substance, dataType: DataType, tileType: TileType, altitudeIndex: number) {
        return [this.url, this.domain.slug, dataType, tileType, altitudeIndex, substance].join('/');
    }
}
