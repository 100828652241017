import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TimelineState } from '../index';
import { ITimelineState } from '../core.reducer';
import { Feature } from '../../models/core';

export const getTimelineState = createFeatureSelector<TimelineState>('timeline');

export const selectCore = createSelector(
    getTimelineState,
    (state: TimelineState): ITimelineState => state.core,
);

export const selectDates = createSelector(
    selectCore,
    (state: ITimelineState) => state.dates
);

export const selectCurrentIndex = createSelector(
    selectCore,
    (state: ITimelineState) => state.currentIndex
);

export const selectChartEnabled = createSelector(
    selectCore,
    (state: ITimelineState) => state.chartEnabled
);

export const getDisplayPointTimeseries = createSelector(
    selectCore,
    (state: ITimelineState) => state.lastPointTimeseries
);

export const getAppConfig = createSelector(
    selectCore,
    (state: ITimelineState) => state.config
);

export const getDisplayPointTimeseriesWithDates = createSelector(
    getDisplayPointTimeseries,
    selectDates,
    (data: Feature[], dates: string[]) => ({ data, dates })
);

export const getSelectedMeasures = createSelector(
    selectCore,
    (state: ITimelineState) => state.measures
);
