import { Component, Input } from '@angular/core';

import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'location-meteo-card',
    templateUrl: 'location-meteo-card.component.html',
    styleUrls: ['location-meteo-card.component.less']
})
export class LocationMeteoCardComponent {
    @Input() aqi: number;

    @Input() dateTime: string;

    @Input() textQuality: string;

    @Input() textForecast: string;

    @Input() measurementItems: {
        name: string;
        value: number;
        units: string;
    }[];

    TEXTS = TEXTS;
}
