import { Injectable } from '@angular/core';
import { Subject, merge } from 'rxjs';
import { take, filter, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { ChartActions, getTimelineInterval, isFalseNumber } from 'src/utils';
import { createSeriesForTimeline, createSingleSerie, getSeriesForChart, updateStationData } from 'src/chart/model';

import {
    CHART_NAME_CITYAIR,
    CHART_NAME_TIMELINE,
    CHART_TYPE_FULL_WIDTH,
    CHART_TYPE_TIMELINE,
    ChartControl_model,
    DATA_INTERVAL_TYPES,
    Interval,
    IntervalType,
    MeasuresInfoFormat,
    ParamResizeChart_model,
    Series_model,
    StationData,
    StationForMapPage_model, TimeEventFrom
} from 'src/namespace';

import {
    CHART_INDIVIDUAL_OPTIONS,
    CHART_LINE_COLORS,
    CHART_PADDING_LR,
    CHART_TIMEOUT_BEFORE_RENDER,
    DATA_MAX_INTERVAL,
    DEFAULT_SELECT_MEASURE,
    getDataMinimumInterval, RESIZE_TIMEOUT, WIDTH_ADMIN_PANEL
} from 'src/config';

import { TimelineState } from './TimelineState';
import { flagSetPosition } from './dragFlag';

import { calcUsesMeasures, popupInfoUpdateFromChart } from 'src/little-components/measures-indicators-new/dataModel';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { TIMELINE_STEP, WIDTH_LEFT_MENU } from '../../utils/config';

import { Metrika } from 'ng-yandex-metrika';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { detectMobile, EventsSourceName } from 'projects/shared/utils/other-utils';
import { selectIsSidebarOpen } from 'projects/cityscreen/src/modules/core/store/selectors';

export function getChartShiftWidth (isOpenPanel: boolean) {
    if (detectMobile())
        return 0;

    return isOpenPanel ? -WIDTH_ADMIN_PANEL.fat - WIDTH_LEFT_MENU : -WIDTH_LEFT_MENU
}

class DefaultConfig {
    yAxis = {
        labels: {
            enabled: false
        },
        tickInterval: undefined,
        minorTicks: false,
        minorTickInterval: undefined,
        min: -100,
        max: 100
    }
}

class DefaultChart extends ChartActions {
    series: Series_model;
    isSidebarOpen = false;

    constructor(public sharedCoreFacade: SharedCoreFacade, public time: Time) {
        super();
        sharedCoreFacade.store.select(selectIsSidebarOpen).subscribe(val => this.isSidebarOpen = val)
    }

    updateChart(deleteSelectLine = false): void {
        if (!this.chartControl) {
            return;
        }

        this.sharedCoreFacade.setMainChartLoadingTrue();
        setTimeout(() => { // что бы прогрузился спиннер, отработала анимация иначе всё тормозит
            this.chartControl.updateChart(this.series, deleteSelectLine);
            this.updateMeasures(); // из-за setTimeout
            this.sharedCoreFacade.setMainChartLoadingFalse();
        }, CHART_TIMEOUT_BEFORE_RENDER);
    }

    updateMeasures = (notShiftFlag?: boolean) => {
        let timestamp = this.time.getCurrent();

        if (!timestamp) {
            return;
        }

        timestamp += this.sharedCoreFacade.getMsOffset(); // series у нас в таймстэмпе с прибавленым здвигом таймзоны

        this.sharedCoreFacade.getComparedList().forEach(s => s.measuresVal = popupInfoUpdateFromChart(s.originChartData, this.sharedCoreFacade.getMeasuresSelected(), timestamp));

        if (notShiftFlag || !this.chartControl) {
            return;
        }

        const point = this.chartControl.findPointXCoordinate(timestamp); // series у нас в таймстэмпе с прибавленым здвигом таймзоны

        if (point !== null) {
            flagSetPosition(point.clientX + CHART_PADDING_LR[0],  getChartShiftWidth(this.isSidebarOpen));
        } else {
            console.log('updateMeasureAndFlag not find point for timestamp');
        }
    }

    highlight(clientX: number): void {
        if (this.chartControl) {
            this.chartControl.highlight(clientX);
        }
    }

    updateMeasuresFromCoordinates(coordinatesLeft: number) {
        return this.chartControl.findPoint(coordinatesLeft - CHART_PADDING_LR[0]);
    }
}

class ChartCityair extends DefaultChart {
    getConfigDefault() {
        return new DefaultConfig();
    }

    getConfigSingle() {
        const selected = this.sharedCoreFacade.getMeasuresSelected();
        const stations = this.sharedCoreFacade.getComparedList();

        const origin = stations[0].originChartData[selected[0].name];

        return {
            yAxis: {
                ...new DefaultConfig().yAxis,
                labels: {
                    enabled: true
                },
                min: origin ? origin.min : null,
                max: origin  ? origin.max : null
            }
        }

    }

    getExtraChartConfig({ min, max, intervalMajor, intervalMinor }) {
        return {
            yAxis: {
                labels: {
                    enabled: true
                },
                tickInterval: intervalMajor,
                minorTicks: !isNaN(intervalMinor),
                minorTickInterval: intervalMinor,
                min,
                max
            }
        };
    }

    patchSeriesForCODD (isSingle: boolean) {
        const _serie = this.series.series.find(s => s.type === 'column');

        if (!_serie)
            return;

        _serie.dataLabels = {
            enabled: true,
                verticalAlign: 'bottom',
                useHTML: true,
                inside: true,
                formatter: function () {
                    const aqi = isSingle ? this.y : ( this.y + 100 ) / 20;
                    const color = CHART_LINE_COLORS[aqi];

                    return `<div style="position: absolute;
                                    bottom: -30px;
                                    left: -4px;
                                    font-size: 13px;
                                    font-family: Inter, sans-serif;
                                    color: ${color};
                                ">
                                ${aqi}
                            </div>`
                }
        }
    }

    updateSeries(): void {
        const stations = this.sharedCoreFacade.getComparedList();

        this.sharedCoreFacade.setMeasuresList(calcUsesMeasures(stations))

        const list = this.sharedCoreFacade.getMeasuresList();

        const haveInList = this.sharedCoreFacade.getMeasuresSelected().filter(m => list.find(m2 => m2.name === m.name));

        if (haveInList.length === 0) {
            const standardMeasure = list.find(m => m.type === DEFAULT_SELECT_MEASURE);

            if (standardMeasure) {
                this.sharedCoreFacade.setMeasuresSelected([standardMeasure])
            } else if (list[0]) {
                this.sharedCoreFacade.setMeasuresSelected([list[0]])
            }
        }

        const selected = this.sharedCoreFacade.getMeasuresSelected();
        const isSingle = stations.length === 1 && selected.length === 1;

        const measurementType = selected[0]?.type;
        const chartMinMax = this.sharedCoreFacade.getGroupChartConfig();

        if (isSingle && chartMinMax && chartMinMax[measurementType]) {
            this.chartControl.changeOptions(this.getExtraChartConfig(chartMinMax[measurementType]))
            this.series = getSeriesForChart(stations, selected, createSingleSerie);
        }
        else if (isSingle) {
            this.chartControl.changeOptions(this.getConfigSingle());
            this.series = getSeriesForChart(stations, selected, createSingleSerie);
        }
        else {
            this.chartControl.changeOptions(this.getConfigDefault());
            this.series = getSeriesForChart(stations, selected);
        }

        // TODO ЦОДД
        if (this.sharedCoreFacade.canShowNumbersAqiChart())
            this.patchSeriesForCODD(isSingle);
    }
}

class ChartTimeline extends DefaultChart {
    updateSeries() {}

    updateChart(deleteSelectLine = false): void {
        if (!this.chartControl)
            return;

        const series = createSeriesForTimeline(
            this.sharedCoreFacade.getAqiHistory(),
            undefined,
            this.sharedCoreFacade.getMsOffset(),
            this.time.getBegin(),
            this.time.getEnd()
        );

        this.chartControl.updateChart(series, deleteSelectLine);
    }

    highlight(clientX): void {
        const lastTime = this.chartControl.highlight(clientX);
        if (lastTime)
            this.sharedCoreFacade.setLastHoverTime(lastTime);
    }

}

@Injectable({
    providedIn: 'root'
})
export class TimelineActions {
    charts: {[name: string]: ChartActions};

    isSidebarOpen = false;

    constructor(
        private timelineState: TimelineState,
        private sharedCoreFacade: SharedCoreFacade,
        private metrika: Metrika,
        private time: Time
    ) {
        this.charts = {
            [CHART_NAME_CITYAIR]: new ChartCityair(sharedCoreFacade, time),
            [CHART_NAME_TIMELINE]: new ChartTimeline(sharedCoreFacade, time),
        };

        window.addEventListener('resize', () => {
            setTimeout(this._getUsesChart().updateMeasures, RESIZE_TIMEOUT * 2); // *2 дя того чтобы запустилась после перерисовки графика
        });

        time.timeUpdated.subscribe((data) => {
            this._getUsesChart().updateMeasures(data.eventFrom === TimeEventFrom.dragFlag)
        });

        sharedCoreFacade.actionObservables.comparedList.subscribe(() => {
            this.calcCanDetailChart();
        });

        sharedCoreFacade.actionObservables.changeTimeline.subscribe((chartName: string) => {
            this.changeTimeline(chartName);
        })

        sharedCoreFacade.actionObservables.comparedChart.subscribe((name) => {
            this.updateCharts(name);
        });

        sharedCoreFacade.actionObservables.updateStationDataOnePocket.subscribe((props) => {
            this.updateStationDataOnePocket(props.data, props.station);
        });

        sharedCoreFacade.actionObservables.timelineMobileDragState.subscribe((val: boolean) => {
            this.timelineState.mobileMinimize = val;
        });

        sharedCoreFacade.actionObservables.closeTimeline.subscribe(() => {
            this.onCloseTimeline();
        });

        sharedCoreFacade.actionObservables.deleteCompareStation.subscribe((index) => {
            this.deleteCompareStation(index);
        });

        sharedCoreFacade.store.select(selectIsSidebarOpen).subscribe(val => {
            this.isSidebarOpen = val;
            this.chartsSetSizes({shiftWidth: getChartShiftWidth(this.isSidebarOpen)});
        });

        merge(
            sharedCoreFacade.eventsSource.onEvent$.pipe(filter(v => v.type === EventsSourceName.onMarkerClick$)),
            sharedCoreFacade.actionObservers.comparedList
        ).subscribe(() => {
            this.notifyControls();
        });
    }

    updateCharts(chartName: string) {
        const chart = this.charts[chartName];
        chart.updateSeries();
        chart.updateChart();
    }

    resetChartZoom = () => {
        const { chartControl } = this.charts[CHART_NAME_CITYAIR];

        if (chartControl) {
            chartControl.reloadExtremes();
            chartControl.onPointSelect(null, true);
        }
    };

    clickForTitleTimeline = (station: StationForMapPage_model) => {
        if (station.type === 'city' && station.cityId) {
            this.sharedCoreFacade.actionObservers.loadCity.next({cityId: station.cityId});
        }
    };

    toggleCompareMod = () => {
        this.metrika.fireEvent('enable_comparison_mode');
        this.sharedCoreFacade.getIsCompareMod() ? this.compareModOff() : this.sharedCoreFacade.setCompareModTrue();
    };

    compareModOff = () =>  {
        this.sharedCoreFacade.setCompareModFalse();

        if (this.sharedCoreFacade.getComparedList().length > 1)
            this.onCloseTimeline();
    };

    deleteCompareStation = (index: number) => {
        this.sharedCoreFacade.compareListRemoveForIndex(index);
        this.updateCharts(CHART_NAME_CITYAIR);

        if (this.sharedCoreFacade.isComparedListEmpty()) {
            this.onCloseTimeline();
        }
    };

    onCloseTimeline = () =>  {
        this.sharedCoreFacade.clearCompareList();
        this.changeTimeline(null);
        this.notifyControls();
    };

    changeTimeline(showedChart = this.sharedCoreFacade.getShowingChartName()) {
        // TODO нужна только чтоб при обновлении график ошибку не выдавал из-за зума
        this.resetChartZoom();

        this.sharedCoreFacade.setShowingChartName(showedChart);
        this.sharedCoreFacade.setMainChartLoadingTrue(); // когда график не загрузися но уже вылазиет должен быть лоадер
    }

    chartObjCreated = (chartControl: ChartControl_model, name: string, type: string) => {
        this.charts[name].setChartControl(chartControl);

        const getChartConf = () => ({
            shiftWidth: getChartShiftWidth(this.isSidebarOpen)
        });

        this.charts[name].chartControl.setSize(type, getChartConf());
        this.charts[name].chartControl.resize(type, getChartConf);
    };

    chartsSetSizes(param: ParamResizeChart_model) {
        if (!this.charts[CHART_NAME_CITYAIR].chartControl)
            return;

        this.charts[CHART_NAME_CITYAIR].chartControl.setSize(CHART_TYPE_FULL_WIDTH, param, {duration: 350, easing: 'linear'});
        this.charts[CHART_NAME_TIMELINE].chartControl.setSize(CHART_TYPE_TIMELINE, param, {duration: 350, easing: 'linear'});
        this.charts[CHART_NAME_CITYAIR].updateMeasures();
        this.charts[CHART_NAME_TIMELINE].updateMeasures();
    }

    chartOnHover = (timestamp: number) => {
        this.charts[CHART_NAME_CITYAIR].highlight(timestamp);
        this.charts[CHART_NAME_TIMELINE].highlight(timestamp);
    };

    selectMeasure = (measure: MeasuresInfoFormat, chartName, multiple = false) => {
        let measures: MeasuresInfoFormat[] = this.sharedCoreFacade.getMeasuresSelected();

        if (measures.length === 1 && measures[0].name === measure.name) {
            return;
        }

        if (multiple) {
            if (
                !measures.find((m, index) => {
                    if (m.name === measure.name) {
                        measures.splice(index, 1);
                        return true;
                    }
                })
            ) {
                measures.push(measure);
            }
        } else {
            measures = [measure];
        }

        this.sharedCoreFacade.setMeasuresSelected(measures);

        this.updateCharts(chartName);
    };

    goToEndTime = () => {
        this.time.timeUpdate.next({
            time: this.time.getEnd(),
            eventFrom: TimeEventFrom.timeToEndBtn
        });
    };

    private _getUsesChart() {
        let chart: ChartActions;
        if (this.sharedCoreFacade.isComparedListEmpty())
            chart = this.charts[CHART_NAME_TIMELINE];
        else if (this.timelineState.chartType === 'full' && !this.timelineState.isZooming)
            chart = this.charts[CHART_NAME_CITYAIR];
        else
            chart = this.charts[CHART_NAME_TIMELINE];

        return chart;
    }

    checkControlsLayout$ = new Subject<void>();

    private notifyControls() {
        // compensate for animation (.2s)
        setTimeout(() => this.checkControlsLayout$.next(), 300);
    }

    changeChartType = (type: 'simple' | 'full') => {
        this.timelineState.chartType = type;
        this.notifyControls();
    };

    onChartZoom = (zoomEvent: {max: number, min: number}) => {
        const isZooming = !isFalseNumber(zoomEvent.min) || !isFalseNumber(zoomEvent.max);

        if (this.timelineState.isZooming !== isZooming) {
            this.charts[CHART_NAME_CITYAIR].chartControl.changeOptions({
                chart: {marginBottom: CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_FULL_WIDTH].chart.marginBottom},
                xAxis: {labels: {enabled: isZooming}}
            });

            this.timelineState.isZooming = isZooming;
        }
    };

    updateCurrentTimeForFlagCoordinates = (coord: number) => {
        const point: any = this._getUsesChart().updateMeasuresFromCoordinates(coord);

        if (point) {
            this.time.timeUpdate.next(
                {
                    time: point.x - this.sharedCoreFacade.getMsOffset(),
                    eventFrom: TimeEventFrom.dragFlag
                }
            );
        }
    };

    setDataInterval = (val: Interval) => {
        if (!this.timelineState.IntervalButtons.includes(val)) {
            return;
        }

        this.sharedCoreFacade.setChartDataIntervalType(val)
        this.sharedCoreFacade.actionObservers.updateCompareStations.next();
    };

    calcCanDetailChart = () => {
        const arrMinimalsIntervals: IntervalType[] = [];

        this.sharedCoreFacade.getComparedList().forEach(compared => {
            arrMinimalsIntervals.push(getDataMinimumInterval(compared.type, this.time.getBegin(), this.time.getEnd()));
        });

        if (!arrMinimalsIntervals.length) {
            return;
        }

        const minAvailable = <IntervalType>Math.max(...arrMinimalsIntervals);
        const arr: IntervalType[] = [];

        for (let i = minAvailable; i <= DATA_MAX_INTERVAL; i++) {
            arr.push(<IntervalType>i);
        }

        this.timelineState.IntervalButtons = arr;

        if (!arr.includes(this.sharedCoreFacade.getChartDataIntervalType())) {
            this.sharedCoreFacade.setChartDataIntervalType(minAvailable)
        }
    };

    updateStationDataOnePocket = (data: StationData, station: StationForMapPage_model) => {
        if (!data.packets || !data.packets.length) {
            return;
        }

        station.lastPacketID = data.lastPacketID;

        updateStationData({
            data: data.packets,
            station,
            comparedStations: this.sharedCoreFacade.getComparedList(),
            chart: this.charts[CHART_NAME_CITYAIR],
            selectedMeasures: this.sharedCoreFacade.getMeasuresSelected(),
            tzOffset: station.tzOffset && station.isOurStation ? station.tzOffset : this.sharedCoreFacade.getTzOffset(),
            interval: this.sharedCoreFacade.getChartDataIntervalType(),
            measuresInfo: data.measuresInfo,
        });
    };

    togglePlay = async () => {
        if (this.sharedCoreFacade.getIsPlaying()) {
            this.sharedCoreFacade.setIsPlayingFalse();
        } else {
            this.sharedCoreFacade.setIsPlayingTrue();

            this.sharedCoreFacade.actionObservables.canStartPlay.pipe(take(1)).subscribe(() => this.startPlay());

            this.sharedCoreFacade.actionObservers.loadBeforePlay.next();
        }
    }

    private playInterval: any;
    stopPlay() {
        this.sharedCoreFacade.setIsPlayingFalse();
        clearInterval(this.playInterval);
    }

    startPlay = () => {
        const aqiHistory = this.sharedCoreFacade.getAqiHistory();

        let i = aqiHistory.findIndex(h => h.time >= this.time.getCurrent());

        if (i == -1 || i === aqiHistory.length - 1) {
            // в начало коретку
            i = 0;
        }

        const interval = getTimelineInterval(this.time.getBegin(), this.time.getEnd());
        const intervalMin = DATA_INTERVAL_TYPES[interval];
        const time = moment(aqiHistory[i].time);

        this.playInterval = setInterval(() => {
            time.add(intervalMin, 'minutes');

            if (time.valueOf() <= this.time.getEnd() && this.sharedCoreFacade.getIsPlaying()) {
                this.time.timeUpdate.next(
                     {time: time.valueOf()}
                 );
                this.sharedCoreFacade.setIsPlayingTrue();
            }
            else
                this.stopPlay();

        }, TIMELINE_STEP);
    };
}
