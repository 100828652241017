<div class="tags-list">
    <div class="tags-list__item"
        *ngFor="let tag of tags | slice : 0 : getLimit(); index as i"
    >
        {{tag.label}}
        <cross-button class="tags-list__delete"
                      size="small"
                      *ngIf="isEditable"
                      (click)="remove(tag)"
        ></cross-button>
    </div>

    <div class="tags-list__item tags-list__item--expand"
        *ngIf="!isExpand && tags.length > limit"
        (click)="isExpand = true"
    >
        {{textFunctions?.expand ? textFunctions?.expand(tags.length - limit) : TEXTS.NOTIFICATIONS.showMore}}
    </div>

    <div class="tags-list__item tags-list__item--expand"
        *ngIf="isExpand && tags.length > limit"
        (click)="isExpand = false"
    >
        {{textFunctions?.collapse ? textFunctions?.collapse() : TEXTS.NOTIFICATIONS.collapse}}
    </div>
</div>
