import { Component, Input } from '@angular/core';
import { isFalseNumber } from 'src/utils';
import { TEXTS } from 'src/texts/texts';
import { Measurement, Post } from 'projects/cityscreen/src/modules/indoor/services/api';
import { orderOfMeasures } from 'projects/cityscreen/src/modules/indoor/config';

@Component({
    selector: 'point-item',
    templateUrl: './point-item.component.html',
    styleUrls: ['./point-item.component.less']
})
export class PointItemComponent {
    @Input() point: Post;
    @Input() measurement: Measurement;
    @Input() isActive: boolean;

    orderOfMeasures = orderOfMeasures;

    getUnit = TEXTS.measures.get;

    isFalseNumber = isFalseNumber;
}
