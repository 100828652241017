import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    Input
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, take, switchMapTo, pluck, takeUntil, filter } from 'rxjs/operators';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import State from 'map/state';
import { TEXTS } from 'src/texts/texts';
import { TabModel } from 'src/namespace';

import { PACKET_VALUE_TYPE_ID } from 'src/config';
import { MultipleSearchFnsPipe } from 'projects/shared/pipes/pipes';
import { shortDateFormatWithClosest, annotateWithDates } from 'src/utils/date-formats';

import {
    NotificationFeedItem,
    NotificationEventType,
    NotificationSubscribeType
} from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';

import { PacketsValueTypeItem } from 'harvester/UiAdminProject8/src/commonData/models';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';

import {
    NotificationsStateService,
    SubscriptionTypeFilter,
    NotificationState,
    Pages
} from 'projects/cityscreen/src/modules/notifications/notifications-state.service';
import AdminPanelActions from 'projects/cityscreen/src/components/admin-panel/actions';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { selectGroupInfo } from 'projects/cityscreen/src/modules/core/store/selectors';
import { indoorShowEventOnChart, indoorSelectPoint } from 'projects/cityscreen/src/modules/indoor/services/store/actions';

function pastDateOrNow(dateTime: number) {
    return Math.min(dateTime, Date.now());
}

@Component({
    selector: 'event-feed',
    templateUrl: 'event-feed.component.html',
    styleUrls: ['event-feed.component.less']
})
export class EventFeed implements OnInit, OnDestroy {
    @Input() disableNavigation: boolean;

    @ViewChild('feed') feed: CdkVirtualScrollViewport;

    textsNotification = TEXTS.NOTIFICATIONS;
    todayDate = new Date();
    settingsForm: FormGroup;
    filterText = '';

    onDestroy$ = new Subject<void>();

    filterText$ = new BehaviorSubject<string>(this.filterText);

    feedItems$ = new BehaviorSubject<NotificationFeedItem[]>([]);

    lists: {
        all$?: Observable<NotificationFeedItem[]>;
        selected$?: Observable<NotificationFeedItem[]>;
        filtered$?: Observable<NotificationFeedItem[]>;
        annotated$?: Observable<{
            item: NotificationFeedItem;
            annotation: string;
        }[]>;
    } = {};

    packetValueTypes: PacketsValueTypeItem[];

    filterTypes = SubscriptionTypeFilter;

    filterTabs = [
        {
            title: this.textsNotification.measures,
            type: SubscriptionTypeFilter.MEASUREMENTS
        },
        {
            title: this.textsNotification.service,
            type: SubscriptionTypeFilter.SERVICE
        }
    ];

    selectedTab: TabModel = this.filterTabs[0];

    loading = true;
    initialScrollPosition = true;
    selectedCount = 0;
    uiState: NotificationState;

    private dateAnnotator = annotateWithDates<NotificationFeedItem>(
        (item) => this.getRelevantTime(item),
        shortDateFormatWithClosest
    );

    constructor(
        private globalState: State,
        private fb: FormBuilder,
        readonly stateService: NotificationsStateService,
        private sharedCoreFacade: SharedCoreFacade,
        private msfPipe: MultipleSearchFnsPipe,
        private apActions: AdminPanelActions,
        private time: Time,
        private router: Router,
        private store: Store
    ) {}

    ngOnInit() {
        this.resetFilter();

        const { filterText$, feedItems$, lists, searchFilters } = this;

        feedItems$.next([]);

        lists.all$ = feedItems$.asObservable();

        lists.selected$ = lists.all$.pipe(
            switchMapTo(
                this.stateService.lists.selected$,
                (es, ss) => es.filter(e =>
                    ss.find(s => s.id === e.NotificationId) &&
                    this.stateService.hasFilterType(e.SubscribeType)
                )
            )
        );

        lists.filtered$ = lists.selected$.pipe(
            switchMapTo(filterText$, (es, text) => this.msfPipe.transform(es, searchFilters, text))
        );

        lists.annotated$ = lists.filtered$.pipe(
            map(this.dateAnnotator)
        );

        this.settingsForm = this.fb.group({
            text: ['']
        });

        this.loading = true;

        this.store.select(selectGroupInfo).pipe(filter(g => !!g), take(1))
            .subscribe(groupInfo => {
                this.stateService
                    .getUsersEventFeed(groupInfo.iAm.userId)
                    .then((response) => {
                        this.packetValueTypes = response.PacketValueTypes;
                        this.updateLists(() => response.FeedItems.sort((a, b) => {
                            let cmp = 0;
                            if (a.UpdateTime && b.UpdateTime) {
                                cmp = new Date(a.UpdateTime).getTime() - new Date(b.UpdateTime).getTime();
                            } else if (a.UpdateTime) {
                                cmp = -1;
                            } else if (b.UpdateTime) {
                                cmp = 1;
                            } else {
                                cmp = a.FeedItemId - b.FeedItemId;
                            }
                            return cmp;
                        }).reverse());
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });

        this.lists.selected$
        .pipe(
            takeUntil(this.onDestroy$),
            pluck('length')
        )
        .subscribe((len) => {
            this.selectedCount = len;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    scrollTop() {
        this.feed.scrollToIndex(0);
    }

    filterUpdate(text: string) {
        this.filterText$.next(text);
    }

    resetFilter() {
        this.uiState = this.stateService.getState(Pages.EVENT_FEED);
        this.showSelectedTab(this.filterTabs.find(t => t.type === this.uiState.typeFilter));
    }

    updateLists(transform: (ss: NotificationFeedItem[]) => NotificationFeedItem[]) {
        this.feedItems$
        .pipe(take(1))
        .subscribe(list => {
            this.feedItems$.next(transform(list));
        });
    }

    getRelevantTime(event: NotificationFeedItem) {
        switch (event.EventType) {
            case NotificationEventType.End: return event.EndTime;
            case NotificationEventType.Continue: return event.UpdateTime;
            default: return event.BeginTime;
        }
    }

    openSubscriptions = () => {
        this.stateService.openSubscriptions(this.uiState.typeFilter);
    }

    isCurrentFilter(filter: SubscriptionTypeFilter) {
        return filter === this.stateService.typeFilter;
    }

    applyFilter(filter: SubscriptionTypeFilter) {
        if (!this.isCurrentFilter(filter)) {
            this.stateService.typeFilter = this.uiState.typeFilter = filter;
            this.stateService.updateLists(v => v);
            this.updateLists(v => v);
        }
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
        this.applyFilter(tab.type);
    }

    notificationHasRelatedMarker(notification: NotificationFeedItem) {
        const moId = {
            [NotificationSubscribeType.PdkExcess]: notification.PdkMoContent,
            [NotificationSubscribeType.ServiceDevice]: notification.ServiceContent
        }[notification.SubscribeType].MoInfo?.MoId;

        return this.globalState.map.commonMarkers.findIndex(m => m.id === moId) !== -1;
    }

    gotoDeviceInfo(deviceSerial: string) {
        // TODO routing
        this.router.navigate(['/networks/devices/details/' + deviceSerial]);
    }

    gotoMonitoringObject({ moId, dateTime }: { moId: number; dateTime: string }) {
        this.extendTimeline(dateTime);
        this.sharedCoreFacade.actionObservers.closeTimeline.next();
        this.apActions.clickFromApToMo(moId);
    }

    gotoIndoorMonitoringObject({ moId, dateTime, mmt }: { moId: number; dateTime: string; mmt?: string }) {
        const eventWindow = this.getEventWindow(dateTime);

        this.store.dispatch(indoorShowEventOnChart({
            eventDate: dateTime.toString(),
            beginDate: new Date(eventWindow.begin).toISOString(),
            endDate: new Date(eventWindow.end).toISOString(),
            selectMeasurement: mmt
        }));

        this.store.dispatch(indoorSelectPoint({ id: moId }));

        this.router.navigate(['/indoor']);
    }

    private getEventWindow(dateTime: string) {
        // use +/- 1 day, since there is TZ-shift in timeline after calling updateTimeDiapason
        const timePaddingInMs = 24 * 60 * 60 * 1000;
        const eventTime = moment(dateTime).valueOf();

        return {
            begin: eventTime - timePaddingInMs,
            end: pastDateOrNow(eventTime + timePaddingInMs)
        };
    }

    private extendTimeline(dateTime: string) {
        this.time.intervalUpdate.next({
            ...this.getEventWindow(dateTime),
            allowUpdate: false
        });
    }

    searchFilters = [
        (item: NotificationFeedItem) => item.NotificationTitle,
        (item: NotificationFeedItem) => item.ServiceContent?.DeviceInfo?.SerialNumber,
        (item: NotificationFeedItem) => item.ServiceContent?.MoInfo?.Name,
        (item: NotificationFeedItem) => item.PdkMoContent?.MoInfo?.Name,
        (item: NotificationFeedItem) => PACKET_VALUE_TYPE_ID[item.PdkValueType]
    ];
}
