import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest, Subject } from 'rxjs';
import * as Sentry from '@sentry/browser';

import State from 'map/state';
import { getShiftMapCityCard, measureZones } from 'src/config';
import { DownloadPopupData, GetCSTimelineInfoRequest, Marker_model } from 'src/namespace';
import { hideHtmlLoader, } from 'src/utils';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { MessageAPIResponseService } from 'src/little-components/message-api-response/message-api-response.service';
import { MapboxFacadeService } from '../mapbox/mapbox-facade.service';
import { CityscreenMapApi } from 'src/api/mapProvider/cityscreenMapApi';
import { GroupExtConfigName, GroupFeaturesService } from './services/group-features/group-features.service';
import { TEXTS } from '../../../../../src/texts/texts';
import { Store } from '@ngrx/store';
import { groupInfoLoad, groupListLoad, timelineInfoLoad } from './store/actions';
import { selectGroupInfo, selectGroupList, selectLoadingTimeline } from './store/selectors';
import { Group } from '../../../../../src/api/adminPanel/models';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { AuthService } from 'projects/cityscreen/src/modules/login/services/auth/auth.service';
import { StorageService } from 'projects/cityscreen/src/modules/login/services/auth/storage.service';
import { createCssVars } from 'projects/cityscreen/src/modules/core/css-vars';
import { IndoorApi } from '../indoor/services/api';
import { RoutingService } from 'projects/cityscreen/src/modules/core/routing.service';

export const GROUP_ID = 'groupId'

@Injectable({
    providedIn: 'root'
})
export class CoreFacade {
    constructor(
        private adminPanelApi: AdminPanelApi,
        public cityscreenMapApi: CityscreenMapApi,
        private state: State,
        private messageAPIResponseService: MessageAPIResponseService,
        private mapboxFacade: MapboxFacadeService,
        private sharedCoreFacade: SharedCoreFacade,
        private groupFeaturesService: GroupFeaturesService,
        private store: Store,
        private time: Time,
        private authService: AuthService,
        private storageService: StorageService,
        private indoorApi: IndoorApi,
        private routingService: RoutingService
    ) {
        createCssVars();

        this.adminPanelApi.setErrorTokenCb(() => this.logOut(false, true)); // TODO придумать общее решение для всех таких ошибок
        this.indoorApi.setErrorTokenCb(this.logOut);
        this.indoorApi.setErrorCb(this.showErrorPopup);


        this.firstLoad$.subscribe(([groupInfo, b]) => {
            if (!groupInfo.myRole)
                return this.showErrorPopup({message: TEXTS.COMMON.noGroupPermission});

            this.sharedCoreFacade.setAllowForecast(groupInfo.allowForecast);
            this.setAllowPlumes(groupInfo.allowPlumes);

            this.checkGroupExtraConfig();

            this.hideMapLoader();
        });

        this.firstLoadData();
    }

    private loadedGroupInfo$ = this.store.select(selectGroupInfo).pipe(filter(groupInfo => !!groupInfo?.groupId), take(1));
    private loadedTimelineInfo$ = this.store.select(selectLoadingTimeline).pipe(distinctUntilChanged(), filter(val => val == false), take(1));

    public firstLoad$ = combineLatest([
        this.loadedGroupInfo$,
        this.loadedTimelineInfo$
    ]).pipe(take(1));

    openDownloadPopup$ = new Subject<DownloadPopupData>();

    private firstLoadData() {
        hideHtmlLoader();
        this.showMapLoader();

        this.store.dispatch(groupListLoad());

        this.store.select(selectGroupList).pipe(
            filter(groups => !!groups.length),
            take(1)
        ).subscribe((groups) => {
            this.selectDefaultGroup(groups);
            this.updateGroupInfo();

            // TODO need write test 'load timeline info must be run after group info'
            this.loadedGroupInfo$.pipe(take(1)).subscribe(() => {
                this.getCSTimelineInfo(['firstOpen'], {});
            });
        });
    }

    showErrorPopup = (error: HttpErrorResponse | {message: string}) => {
        this.hideMapLoader();
        this.state.errorMsg = error.message;
    }

    showAdminPanelError = (error: HttpErrorResponse) => {
        this.messageAPIResponseService.setMsg({
            message: error.message,
            type: 'error'
        });
    }

    showAdminPanelSuccess = (message?: string) => {
        this.messageAPIResponseService.setMsg({
            message,
            type: 'success'
        });
    }

    showMapLoader = () => this.state.mapLoading = true;

    hideMapLoader = () => this.state.mapLoading = false;

    getGroupId = () => this.state.adminPanel.getCurrentGroup()?.id;

    async getCSTimelineInfo(commands, props) {
        this.store.dispatch(timelineInfoLoad({
            timeBegin: this.time.getBegin(),
            timeEnd: this.time.getEnd(),
            groupId: this.state.adminPanel.getCurrentGroup()?.id,
            measureScheme: this.groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme)
        } as GetCSTimelineInfoRequest));

        this.loadedTimelineInfo$.subscribe((data) => {
            this.sharedCoreFacade.actionObservers.updateData.next({command: commands, props});
        });

    }

    checkGroupExtraConfig() {
        const measureScheme = this.groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme);
        if (measureScheme) {
            TEXTS.measures.setScheme(measureScheme);
            measureZones.setScheme(measureScheme);
        }
    }

    // ---------------------------------

    public logOut = async (isCloseToken = true, isReload = true) => {
        this.authService.removeUser();
        this.authService.removeAccessToken();

        if (isCloseToken)
            await this.authService.closeToken();

        if (isReload)
            location.reload();
    };

    public changeGroup = async (groupId: number) => {
        const list = await this.store.select(selectGroupList).pipe(take(1)).toPromise();
        const groupInfo = await this.store.select(selectGroupInfo).pipe(take(1)).toPromise();

        const found = list.find(g => g.id === groupId);

        if (found && found.id !== groupInfo.groupId) {
            this.storageService.set(GROUP_ID, groupId.toString());
            location.href = location.origin; // закрываем модули
        }
    };

    gotoMapLocation(lat: number, lng: number, zoomTo?: number) {
        this.mapboxFacade.gotoLocation(lat, lng, zoomTo);
    }

    selectDefaultGroup = (groupList: Group[]) => {
        Sentry.addBreadcrumb({
            category: 'selectDefaultGroup',
            message: `[GroupId = ${this.state.adminPanel.getCurrentGroup()?.id}][GroupsList = ${this.state.adminPanel.getGroupsList().map(g => g.name)}]`,
            level: Sentry.Severity.Info,
        });

        if (!this.state.adminPanel.getCurrentGroup()?.id) {
            this.state.adminPanel.setGroupsList(groupList);

            const groups = this.state.adminPanel.getGroupsList();

            if (groups.length) {
                const saved = +this.storageService.get(GROUP_ID);
                const savedGroup = this.state.adminPanel.getGroupsList()?.find(g => g.id === saved);

                if (savedGroup) {
                    this.state.adminPanel.setCurrentGroup(savedGroup);
                } else {
                    this.state.adminPanel.setFirstGroup();
                }
            }
        }
    }

    updateGroupInfo = () => {
        const groupId = this.state.adminPanel.getCurrentGroup()?.id;

        this.store.dispatch(groupInfoLoad({groupId}));
    };

    getMarkers() {
        return this.state.map.myMarkers;
    }

    getIsAllLoaded() {
        return this.state.allLoaded;
    }

    getMOs() {
        return this.state.adminPanel.monitoringObjects;
    }

    getCities() {
        return this.state.cities;
    }

    clickFromApToMo = (moObjId) => {
        const marker = this.state.map.myMarkers.find(m => m.id === moObjId)

        if (marker) {
            this.clickFromApToMarker(marker);
        }
    };

    clickFromApToMarker = (marker: Marker_model) => {
        if (!this.sharedCoreFacade.getIsCityMod())
            this.sharedCoreFacade.actionObservers.loadCity.next({cityId: marker.cityId, centringMap: true})

        this.sharedCoreFacade.actionObservers.mapMarkerClick.next(marker);
        this.sharedCoreFacade.actionObservers.centringOnMarker.next({
            lat: marker.lat,
            lng: marker.lng,
            zooming: true,
            shift: getShiftMapCityCard()
        });
    };


    private allowPlumes = false;

    getAllowPlumes = () => this.allowPlumes;

    setAllowPlumes = (val: boolean) => this.allowPlumes = val;
}
