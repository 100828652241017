import * as moment from 'moment';
import 'moment/locale/ru';

import * as text_ru from './texts_ru';
import * as text_en from './texts_en';

declare const window: any;

const lang = localStorage.getItem('lang');
export let isRU: boolean = !!~(lang || navigator.language).indexOf('ru');

export const setLangRu = () => isRU = true;
export const setLangEn = () => isRU = false;

window.JS_CP_SITE_LANG = isRU ? 'RU' : 'EN';

export const TEXTS = isRU ? text_ru : text_en;

export const LANGUAGE = isRU ? 'ru' : 'en';

moment.locale(LANGUAGE);
