<div class="login-page login-page--{{clientConfig.name || 'unknown'}}"
    [style.backgroundImage]="assets.background && 'url(' + assets.background + ')'"
>
    <div class="login-page__header">
        <div class="login-page__logo">
            <ca-cityair-logo *ngIf="!clientConfig.disableBranding?.all"></ca-cityair-logo>
        </div>
        <div class="login-page__language">
            <ca-language-selector (selectLang)="setLang($event)"></ca-language-selector>
        </div>
    </div>

    <div class="login-page__body">
        <ng-container [ngSwitch]="navigation.activePage">
            <ca-login-form class="login-page__form"
                *ngSwitchDefault
                [subtitle]="TEXTS.LOGIN_WINDOWS.toolsFor_sakhalin"
            ></ca-login-form>

            <ca-password-reset-form class="login-page__form"
                *ngSwitchCase="loginPage.PasswordReset"
            ></ca-password-reset-form>

            <ca-reset-code-form class="login-page__form"
                *ngSwitchCase="loginPage.ResetCode"
            ></ca-reset-code-form>

            <ca-new-password-form class="login-page__form"
                *ngSwitchCase="loginPage.NewPassword"
            ></ca-new-password-form>
        </ng-container>
    </div>

    <div class="login-page__footer" *ngIf="!clientConfig.disableBranding?.all">
        <ca-contacts-info></ca-contacts-info>
        <ca-legal-notice *ngIf="currentLanguage === 'ru'"></ca-legal-notice>
    </div>

    <ng-container [ngSwitch]="navigation.activePopup">
        <ca-password-updated-popup *ngSwitchCase="loginPopup.PasswordUpdated"></ca-password-updated-popup>
        <ca-link-expired-popup *ngSwitchCase="loginPopup.InviteLinkExpired"></ca-link-expired-popup>
    </ng-container>
</div>
