import * as moment from 'moment';
import { PacketValueType } from "../commonData/models";
import { CpUserItem } from "../commonData/providers/adminApiProvider/adminApiModels";

//declare const JS_CP_SITE_LANG: string;

export class TsFuncs {

    static base64ToBlob(b64Data: string): Blob {
        const binary = atob(b64Data);
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const byteArray = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            byteArray[i] = binary.charCodeAt(i);
        }
        return new Blob([byteArray], { type: 'application/octet-binary' });
    }

    static blobToBase64 = (blob: Blob, onLoad: (b64Data: string) => void): void => {
        const reader: FileReader = new FileReader();
        reader.onloadend = () => {
            const base64 = (reader.result as string).split(',')[1];
            onLoad(base64);
        };
        reader.readAsDataURL(blob);
    }


    static saveDataToDisk(blob: Blob, filename: string): void {
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(blob));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static saveTextDataToDisk(content: string, filename: string): void {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static isValueContains = (value: string, filter: string): boolean => {
        if (!filter)
            return true;
        if (!value)
            return false;
        return value.toLowerCase().includes(filter.toLowerCase());
    }

    static isValue12Contains = (value1: string, value2: string, filter: string): boolean => {
        if (!filter)
            return true;
        if (!value1)
            return false;
        if (!value2)
            return false;
        const v1 = value1.toLowerCase().includes(filter.toLowerCase());
        const v2 = value2.toLowerCase().includes(filter.toLowerCase());
        return v1 || v2;
    }

    static copyObjectSimpleProperties = (objFrom: any, objTo: any) => {
        let key: any;
        for (key in objFrom) {
            if (!Array.isArray(objFrom[key]))
                objTo[key] = objFrom[key];
        }
    }

    static copyObjectAllProperties = (objFrom: any, objTo: any) => {
        let key: any;
        for (key in objFrom) {
            objTo[key] = objFrom[key];
        }
    }

    static cloneObject = (obj: any): any => {
        return Object.assign({}, obj);
    }

    static deepCloneObject = (obj: any): any => {
        const strObj = JSON.stringify(obj);
        return JSON.parse(strObj);
    }

    static getDateAsString = (date: moment.Moment): string => {
        return date.format('YYYY-MM-DD');
    }

    static getAsDateString = (dateTime: Date): string => {
        return moment(dateTime).format('YYYY-MM-DD');
    }

    static siteLangValue: string = 'EN';

    static getSiteLang = (): string => {
        return TsFuncs.siteLangValue ? TsFuncs.siteLangValue : 'EN';
        //return JS_CP_SITE_LANG;
    }

    static isSiteLangRu = (): boolean => {
        return TsFuncs.getSiteLang() === 'RU';
    }

    static getSiteLangValue = (enValue: string, ruValue: string) => {
        if (!ruValue)
            return enValue;

        return TsFuncs.isSiteLangRu()
            ? ruValue
            : enValue;
    }

    static getTimeZone = (): number => {
        const timeZone = -1 * (new Date()).getTimezoneOffset() / 60;
        return timeZone;
    }

    static getTimezoneOffsetSeconds = (): number => {
        return (new Date()).getTimezoneOffset() * 60 * (-1);
    }

    static getTimeZoneName = (): string => {
        const dateOpt = Intl.DateTimeFormat().resolvedOptions();
        return dateOpt.timeZone;
    }

    static getGmtOffsetToReadable = (gmtOffsetSeconds: number): string => {
        var sign = gmtOffsetSeconds  >= 0 ? "+" : "";
        const z = moment.duration(gmtOffsetSeconds * 1000);
        const h = Math.trunc(z.asHours());
        const m = z.minutes();
        //const zeroPad = (num, places) => String(num).padStart(places, '0')
        return sign + String(h).padStart(2, '0') + ":" + String(m).padStart(2, '0');
    }

    static jsonPrettyObject = (jsonObj: any): string => {
        return JSON.stringify(jsonObj, null, 4);
    }

    static jsonPretty = (jsonString: string): string => {
        try {
            const jsonObj = JSON.parse(jsonString);
            return JSON.stringify(jsonObj, null, 4);
        }
        catch (e) {
            return jsonString;
        }
    }

    static formatPacketValue = (measureScheme: string, valueType: PacketValueType, value: number): string => {
        if (valueType === PacketValueType.Temperature)
            return value.toFixed(1);

        if (valueType === PacketValueType.Pressure || valueType === PacketValueType.Humidity)
            return value.toFixed(0);

        if (measureScheme === "default")
            return (valueType === PacketValueType.PM2 || valueType === PacketValueType.PM10 || valueType === PacketValueType.TSP)
                ? value.toFixed(0)
                : value.toFixed(2);

        return value.toFixed(3);
    }
}
