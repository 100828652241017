        <div *ngIf="show" [@inOutAnimation] class="calendar__months"
             [ngClass]="{'calendar__months-dynamic_width': dynamicWidth}"
        >
            <div class="calendar__months_block scroll {{monthsVisible < 4 ? 'calendar__months_block-col-'+monthsVisible : 'calendar__months_block-col-'+columns}}">
                <div *ngFor="let indexMonth of showMonth | slice : 0 : monthsVisible; let i = index" class="calendar__month">
                    <div *ngIf="i == 0" class="calendar__to_left" (click)="toLeft($event)"></div>
                    <div class="calendar__month_name"><span>{{calendarState.months[indexMonth].nameWithoutYear}} </span><span class="calendar__month_name-normal"> {{calendarState.months[indexMonth].year}}</span></div>
                    <div *ngIf="i == (monthsVisible - 1)" class="calendar__to_right {{indexMonth !== 0 ? '' : 'calendar__to_right-non_active' }}" (click)="toRight($event)"></div>

                    <div class="calendar__days_name">
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.mo}}</div>
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.tu}}</div>
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.we}}</div>
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.th}}</div>
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.fr}}</div>
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.sa}}</div>
                        <div class="calendar__day_name">{{TEXTS.WEEKDAY.su}}</div>
                    </div>
                    <div *ngFor="let week of calendarState.months[indexMonth].daysArr" class="calendar__week">
                        <div *ngFor="let day of week; let i = index;"
                            [ngClass]="[
                                isHover(day,i),
                                (day && !day.disabled) ? 'calendar__day' : 'calendar__day calendar__day-disabled'
                            ]"
                            (mouseenter)="over(day)"
                            (click)="dayClick(day)"
                        >{{day ? day.text : ''}}</div>
                    </div>
                </div>
            </div>
            <div class="calendar__bottom">
                <div [innerHTML]="TEXTS.TIME_NAMES.watches24 " class="calendar__time_button {{calendarState.activeBtn == '1' ? 'calendar__time_button-active' : ''}}" (click)="timeButtonClick('1')"></div>
                <div [innerHTML]="TEXTS.TIME_NAMES.days3" class="calendar__time_button {{calendarState.activeBtn == '3' ? 'calendar__time_button-active' : ''}}" (click)="timeButtonClick('3')"></div>
                <div [innerHTML]="TEXTS.TIME_NAMES.week" class="calendar__time_button {{calendarState.activeBtn == 'week' ? 'calendar__time_button-active' : ''}}" (click)="timeButtonClick('week')"></div>
                <div [innerHTML]="TEXTS.TIME_NAMES.month" class="calendar__time_button {{calendarState.activeBtn == 'month' ? 'calendar__time_button-active' : ''}}" (click)="timeButtonClick('month')"></div>
            </div>
        </div>