<div class="user_list page-content__wrapper">
    <div class="admin_panel__fixed_block">
        <div class="admin_panel__title">
            <header-page [textObject]="{titlePage: TEXTS.LIST_USERS.listUsers}"></header-page>
        </div>
        <div class="search_list_wrapper">
            <search-input [textPlaceholder]="TEXTS.LIST_OM.filterForName" [(text)]="searchUsers"></search-input>
        </div>
    </div>
    <div class="button_add_wrapper">
        <button-add (action)="stateService.openCreateUser()" [text]="TEXTS.LIST_USERS.addUsers"></button-add>
    </div>
    <div class="sort_list_wrapper">
        <sort-list
            [properties]="[
                {props: sortUsersLogin, title: TEXTS.LIST_USERS.user},
                {props: sortUsersId, title: TEXTS.LIST_USERS.access}
            ]"
            [cbSortFun]="sortingOfUesr"
            [sortingProps] = "sortingUser"
            [sortingDirection] = "sortingDirection"
        ></sort-list>
    </div>
    <cdk-virtual-scroll-viewport class="user_list__body scroll"
                                 itemSize="190"
                                 minBufferPx="400"
                                 maxBufferPx="1600"
                                 #feed>
        <div *ngFor="let user of stateUsers | multipleSearchfilter: ['email','login'] : searchUsers | sorting : sortingUser : sortingDirection"
                class="user_list__user_record">
            <user-record [objUser]="user"></user-record>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
