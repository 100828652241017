<div class="post-description scroll">
    <div>
        <div class="post-description__information-column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.nameOnMap"
                                [description]="MO.name"></information-column>
            <kebab-menu (clickActions)="clickMenu($event)"></kebab-menu>
        </div>
        <div class="post-description__information-column">
            <information-column
                    class="post-description__information-column--coordinate"
                    [title]="TEXTS.POSTS_AND_DEVICES.coordinate"
                    [description]="MO.geoLatitude + ' ' + MO.geoLongitude"></information-column>
            <information-column
                    class="post-description__information-column--coordinate"
                    [title]="TEXTS.POSTS_AND_DEVICES.location"
                    [description]="location"></information-column>
        </div>

        <div class="post-description__information-column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.timeZone"
                                [description]="wrapperGMT"></information-column>
        </div>
    </div>
    <section-line [text]="TEXTS.POSTS_AND_DEVICES.dataSources"></section-line>
    <div *ngIf="devicesList.length != 0" class="post-description__data-sources">
        <ng-container *ngFor="let device of this.devicesList">
            <static-tags
                    (click)="openPage($event,pages.networks + '/' + pages.devicesList + '/' + pages.details + '/' + device.serialNumber)"
                    class="post-description__static-tags"
                    [isActiveElement]="true"
                    [text]="device.serialNumber"
                    [description]="device.model"
                    [isOffline]="device.model == CITYAIR_STND_DEVICE ? device.offline : false"
                    [typeTag]="'device'"
            ></static-tags>
        </ng-container>
    </div>

    <div *ngIf="isNoConnectedDevices"
        class="post-description__not-data-sources"
        [class.post-description__not-data-sources--gray]="!isNoDataSources"
    >
        <ng-container *ngIf="isNoDataSources; else defaultTag">
            {{TEXTS.CITY_CARD.noDataSources}}
        </ng-container>
        <ng-template #defaultTag>
            <static-tags
                typeTag="device"
                [description]="TEXTS.STATION_BLOCK.type.mo"
            ></static-tags>
        </ng-template>
    </div>

    <!--    <section-line [text]="TEXTS.POSTS_AND_DEVICES.actionHistory"></section-line>-->
    <!--    <div class="post-description__action-history scroll">-->
    <!--        <div *ngFor="let ah of actionHistory; let i = index;">-->
    <!--            <information-string-->
    <!--                    class="post-description__information-string {{i > 4 && !isShowActionHistory ? 'post-description__information-string&#45;&#45;hide' : ''}}"-->
    <!--                    [title]="ah.date"-->
    <!--                    [description]="ah.text"></information-string>-->
    <!--        </div>-->
    <!--        <btn-accordion-->
    <!--                (click)="isShowActionHistory = !isShowActionHistory"-->
    <!--                [text]="isShowActionHistory ? TEXTS.POSTS_AND_DEVICES.showLess : TEXTS.POSTS_AND_DEVICES.showMore"-->
    <!--                [isActiveArrow]="isShowActionHistory"></btn-accordion>-->
    <!--    </div>-->
    <section-line [text]="TEXTS.POSTS_AND_DEVICES.accessToData"></section-line>
    <div class="post-description__users">
        <div class="post-description__users-text">
            <span [innerHTML]="TEXTS.POSTS_AND_DEVICES.allAdministratorsAndOperators"></span>&nbsp;<span *ngIf="isCheckTheresObserver" [innerHTML]="TEXTS.POSTS_AND_DEVICES.asWellAsTheFollowing"></span>
        </div>
        <ng-container *ngFor="let user of MO.users;">
            <div *ngIf="(user.name == 'Observer' || user.name == 'Наблюдатель') && user.logins.length > 0">
                <div class="post-description__users-static-tags">
                    <ng-container *ngFor="let log of user.logins">
                        <static-tags
                                class="post-description__static-tags"
                                [text]="log"
                                [typeTag]="'user'"
                        ></static-tags>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <section-line
            [text]="TEXTS.POSTS_AND_DEVICES.documentation"></section-line>
    <div class="post-description__documentation">
        <link-on-document class="post-description__link-on-document"
                          [name]="TEXTS.EDIT_STATION.guideOperationName"
                          [link]="TEXTS.EDIT_STATION.guideOperationLink"></link-on-document>
        <link-on-document class="post-description__link-on-document"
                          [name]="TEXTS.EDIT_STATION.stationScheme"
                          [link]="TEXTS.EDIT_STATION.linkSpecification"></link-on-document>
    </div>
</div>
    <button-download-data
            (click)="openPage($event,path + pages.postReport + '/' + idTargetCard)"
            [text]="TEXTS.POSTS_AND_DEVICES.downloadDataFromPost"
            class="post-description__button-download-data"></button-download-data>

<ng-template #cardMenu>
    <popup-ask class="page__popup-ask"
               *ngIf="isShowDeletePopup"
               [@inOutAnimation]
               (cbAccept)="deleteAccept()"
               (cbClose)="deleteCancel()"
               [title]="TEXTS.POPUP_DELETE.title"
               [description]="TEXTS.POPUP_DELETE.body"
               [textAccept]="TEXTS.POPUP_DELETE.accept"
               [textCancel]="TEXTS.POPUP_DELETE.cancelNew"
    >
        <static-tags class="page__static-tags" [typeTag]="'mo'"
                     [text]="nameTargetCard"></static-tags>
    </popup-ask>
    <card-actions-list
            [@inOutAnimation]
            *ngIf="isShowMenu"
            [position]="menuPositionTop"
            (clickOutside)="closePopup($event)"
    >
        <card-actions-item
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.downloadDataFromPost"
                (click)="openPage($event,path + pages.postReport + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item *ngIf="isRU"
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.downloadTZA4Report"
                (click)="openPage($event,path + pages.postReportTza + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.edit"
                (click)="openPage($event, path + pages.postEdit + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item
                data-cy="action-close"
                type="danger"
                [text]="TEXTS.POSTS_AND_DEVICES.deletePost"
                (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>