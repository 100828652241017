import { createAction, props } from '@ngrx/store';

import { GetCSTimelineInfoRequest } from 'src/namespace';
import { CityairMapDataTransformerResponse } from 'src/api/mapProvider/cityairMapDataTransformerResponse';
import { Group } from 'src/api/adminPanel/models';
import { GroupInfo, NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { DevicesDataTransformer_model } from 'src/api/DevicesApi/dataTransformer';
import { PacketsValueTypeItem } from 'harvester/UiAdminProject8/src/commonData/models';

export enum CommonActions {
    DeviceInfoLoad = '[CommonActions] DeviceInfoLoad',
    DeviceInfoLoaded = '[CommonActions] DeviceInfoLoaded',
    GroupInfoLoad = '[CommonActions] GroupInfoLoad',
    GroupInfoLoaded = '[CommonActions] GroupInfoLoaded',
    GroupListLoad = '[CommonActions] GroupListLoad',
    GroupListLoaded = '[CommonActions] GroupListLoaded',
    ModifyNotification = '[CommonActions] ModifyNotification',
    TimelineInfoLoad = '[CommonActions] TimelineInfoLoad',
    SelectDevice = '[CommonActions] SelectDevice',
    SelectMo = '[CommonActions] SelectMo',
    TimelineInfoLoaded = '[CommonActions] TimelineInfoLoaded',
    ToggleSidebar = '[CommonActions] toggleSidebar',
    OpenSidebar = '[CommonActions] openSidebar',
    CloseSidebar = '[CommonActions] CloseSidebar',
    MapLoaded = '[CommonActions] MapLoaded',
}

export const groupListLoad = createAction(CommonActions.GroupListLoad);
export const groupListLoaded = createAction(
    CommonActions.GroupListLoaded,
    props<{groups: Group[], packetValueTypes: PacketsValueTypeItem[]}>()
);

export const groupInfoLoad = createAction(
    CommonActions.GroupInfoLoad,
    props<{ groupId: number }>()
);
export const groupInfoLoaded = createAction(
    CommonActions.GroupInfoLoaded,
    props<{ groupInfo: GroupInfo }>()
);

export const timelineInfoLoad = createAction(
    CommonActions.TimelineInfoLoad,
    props<GetCSTimelineInfoRequest>()
);
export const timelineInfoLoaded = createAction(
    CommonActions.TimelineInfoLoaded,
    props<CityairMapDataTransformerResponse>()
);

export const deviceInfoLoad = createAction(
    CommonActions.DeviceInfoLoad,
    props<{ serialNumber: string }>()
);
export const deviceInfoLoaded = createAction(
    CommonActions.DeviceInfoLoaded,
    props<DevicesDataTransformer_model>()
);
export const selectDevice = createAction(
    CommonActions.SelectDevice,
    props<{ serialNumber: string }>()
);

export const selectMo = createAction(CommonActions.SelectMo, props<{ id: number }>());
export const modifyNotification = createAction(
    CommonActions.ModifyNotification,
    props<{ id: number; props: Partial<NotificationSubscription> }>()
);

export const openSidebar = createAction(CommonActions.OpenSidebar);
export const closeSidebar = createAction(CommonActions.CloseSidebar);
export const toggleSidebar = createAction(CommonActions.ToggleSidebar);

export const mapLoaded = createAction(CommonActions.MapLoaded);
