// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/close.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".browser_tokens{height:calc(100vh - 116px);padding-bottom:156px}.browser_tokens__item{display:flex;flex-direction:row;padding:14px 0;justify-content:space-between;margin-left:23px;border-bottom:1px solid #e9e9e9}.browser_tokens__part{padding:0 10px}.browser_tokens__hr{width:1px;background-color:#e9e9e9}.browser_tokens__name{font-weight:300;letter-spacing:-.35px;color:#979797}.browser_tokens__name,.browser_tokens__value{font-size:12px;line-height:14px;white-space:nowrap}.browser_tokens__value{padding-top:4px;letter-spacing:-.2px;color:#262626}.browser_tokens__close{height:18px;width:18px;border-radius:18px;margin-right:20px;margin-top:-1px;flex-shrink:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#fff;background-size:10px 10px;background-repeat:no-repeat;background-position:50%;opacity:.7;transition:all .2s linear;cursor:pointer}.browser_tokens__close:hover{background-color:#e9e9e9;opacity:1}.browser_tokens__close:active{opacity:.5}.browser_tokens__active{height:10px;width:10px;border-radius:10px;margin-right:24px;margin-top:2px;flex-shrink:0;background-color:#bec617}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
