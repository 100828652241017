/**
 * TODO: Remove after update to Chart.js v3
 */
import { Chart } from 'chart.js';

/**
 * Helper to get the reset model for the tooltip
 * @param tooltipOpts {object} the tooltip options
 */
export function getBaseModel(tooltipOpts) {
    const globalDefaults = Chart.defaults.global;
    const valueOrDefault = Chart.helpers.valueOrDefault;

    return {
        // Positioning
        xPadding: tooltipOpts.xPadding,
        yPadding: tooltipOpts.yPadding,
        xAlign: tooltipOpts.xAlign,
        yAlign: tooltipOpts.yAlign,

        // Drawing direction and text direction
        rtl: tooltipOpts.rtl,
        textDirection: tooltipOpts.textDirection,

        // Body
        bodyFontColor: tooltipOpts.bodyFontColor,
        _bodyFontFamily: valueOrDefault(tooltipOpts.bodyFontFamily, globalDefaults.defaultFontFamily),
        _bodyFontStyle: valueOrDefault(tooltipOpts.bodyFontStyle, globalDefaults.defaultFontStyle),
        _bodyAlign: tooltipOpts.bodyAlign,
        bodyFontSize: valueOrDefault(tooltipOpts.bodyFontSize, globalDefaults.defaultFontSize),
        bodySpacing: tooltipOpts.bodySpacing,

        // Title
        titleFontColor: tooltipOpts.titleFontColor,
        _titleFontFamily: valueOrDefault(tooltipOpts.titleFontFamily, globalDefaults.defaultFontFamily),
        _titleFontStyle: valueOrDefault(tooltipOpts.titleFontStyle, globalDefaults.defaultFontStyle),
        titleFontSize: valueOrDefault(tooltipOpts.titleFontSize, globalDefaults.defaultFontSize),
        _titleAlign: tooltipOpts.titleAlign,
        titleSpacing: tooltipOpts.titleSpacing,
        titleMarginBottom: tooltipOpts.titleMarginBottom,

        // Footer
        footerFontColor: tooltipOpts.footerFontColor,
        _footerFontFamily: valueOrDefault(tooltipOpts.footerFontFamily, globalDefaults.defaultFontFamily),
        _footerFontStyle: valueOrDefault(tooltipOpts.footerFontStyle, globalDefaults.defaultFontStyle),
        footerFontSize: valueOrDefault(tooltipOpts.footerFontSize, globalDefaults.defaultFontSize),
        _footerAlign: tooltipOpts.footerAlign,
        footerSpacing: tooltipOpts.footerSpacing,
        footerMarginTop: tooltipOpts.footerMarginTop,

        // Appearance
        caretSize: tooltipOpts.caretSize,
        cornerRadius: tooltipOpts.cornerRadius,
        backgroundColor: tooltipOpts.backgroundColor,
        opacity: 0,
        legendColorBackground: tooltipOpts.multiKeyBackground,
        displayColors: tooltipOpts.displayColors,
        borderColor: tooltipOpts.borderColor,
        borderWidth: tooltipOpts.borderWidth
    };
}

/**
 * Private helper to create a tooltip item model
 * @param element - the chart element (point, arc, bar) to create the tooltip item for
 * @return new tooltip item
 */
export function createTooltipItem(element) {
    const xScale = element._xScale;
    const yScale = element._yScale || element._scale; // handle radar || polarArea charts
    const index = element._index;
    const datasetIndex = element._datasetIndex;
    const controller = element._chart.getDatasetMeta(datasetIndex).controller;
    const indexScale = controller._getIndexScale();
    const valueScale = controller._getValueScale();

    return {
        xLabel: xScale ? xScale.getLabelForIndex(index, datasetIndex) : '',
        yLabel: yScale ? yScale.getLabelForIndex(index, datasetIndex) : '',
        label: indexScale ? '' + indexScale.getLabelForIndex(index, datasetIndex) : '',
        value: valueScale ? '' + valueScale.getLabelForIndex(index, datasetIndex) : '',
        index: index,
        datasetIndex: datasetIndex,
        x: element._model.x,
        y: element._model.y
    };
}

/**
 * Get the size of the tooltip
 */
export function getTooltipSize(tooltip, model) {
    const ctx = tooltip._chart.ctx;

    let height = model.yPadding * 2; // Tooltip Padding
    let width = 0;

    // Count of all lines in the body
    const body = model.body;
    let combinedBodyLength = body.reduce((count, bodyItem) => {
        return count + bodyItem.before.length + bodyItem.lines.length + bodyItem.after.length;
    }, 0);
    combinedBodyLength += model.beforeBody.length + model.afterBody.length;

    const titleLineCount = model.title.length;
    const footerLineCount = model.footer.length;
    const titleFontSize = model.titleFontSize;
    const bodyFontSize = model.bodyFontSize;
    const footerFontSize = model.footerFontSize;

    height += titleLineCount * titleFontSize; // Title Lines
    height += titleLineCount ? (titleLineCount - 1) * model.titleSpacing : 0; // Title Line Spacing
    height += titleLineCount ? model.titleMarginBottom : 0; // Title's bottom Margin
    height += combinedBodyLength * bodyFontSize; // Body Lines
    height += combinedBodyLength ? (combinedBodyLength - 1) * model.bodySpacing : 0; // Body Line Spacing
    height += footerLineCount ? model.footerMarginTop : 0; // Footer Margin
    height += footerLineCount * (footerFontSize); // Footer Lines
    height += footerLineCount ? (footerLineCount - 1) * model.footerSpacing : 0; // Footer Line Spacing

    // Title width
    let widthPadding = 0;
    const maxLineWidth = (line) => {
        width = Math.max(width, ctx.measureText(line).width + widthPadding);
    };

    const helpers = Chart.helpers;

    ctx.font = helpers.fontString(titleFontSize, model._titleFontStyle, model._titleFontFamily);
    helpers.each(model.title, maxLineWidth);

    // Body width
    ctx.font = helpers.fontString(bodyFontSize, model._bodyFontStyle, model._bodyFontFamily);
    helpers.each(model.beforeBody.concat(model.afterBody), maxLineWidth);

    // Body lines may include some extra width due to the color box
    widthPadding = model.displayColors ? (bodyFontSize + 2) : 0;
    helpers.each(body, (bodyItem) => {
        helpers.each(bodyItem.before, maxLineWidth);
        helpers.each(bodyItem.lines, maxLineWidth);
        helpers.each(bodyItem.after, maxLineWidth);
    });

    // Reset back to 0
    widthPadding = 0;

    // Footer width
    ctx.font = helpers.fontString(footerFontSize, model._footerFontStyle, model._footerFontFamily);
    helpers.each(model.footer, maxLineWidth);

    // Add padding
    width += 2 * model.xPadding;

    return {
        width: width,
        height: height
    };
}

/**
 * Helper to get the location a tooltip needs to be placed at given the initial position (via the vm) and the size and alignment
 */
export function getBackgroundPoint(vm, size, alignment, chart) {
    // Background Position
    let x = vm.x;
    let y = vm.y;

    const caretSize = vm.caretSize;
    const caretPadding = vm.caretPadding;
    const cornerRadius = vm.cornerRadius;
    const xAlign = alignment.xAlign;
    const yAlign = alignment.yAlign;
    const paddingAndSize = caretSize + caretPadding;
    const radiusAndPadding = cornerRadius + caretPadding;

    if (xAlign === 'right') {
        x -= size.width;
    } else if (xAlign === 'center') {
        x -= (size.width / 2);
        if (x + size.width > chart.width) {
            x = chart.width - size.width;
        }
        if (x < 0) {
            x = 0;
        }
    }

    if (yAlign === 'top') {
        y += paddingAndSize;
    } else if (yAlign === 'bottom') {
        y -= size.height + paddingAndSize;
    } else {
        // PATCH: fix Y-position
        // y -= (size.height / 2);
        y = (chart.height - size.height) / 2;
    }

    if (yAlign === 'center') {
        if (xAlign === 'left') {
            x += paddingAndSize;
        } else if (xAlign === 'right') {
            x -= paddingAndSize;
        }
    } else if (xAlign === 'left') {
        x -= radiusAndPadding;
    } else if (xAlign === 'right') {
        x += radiusAndPadding;
    }

    return {
        x: x,
        y: y
    };
}

/**
 * Helper to get the alignment of a tooltip given the size
 */
export function determineAlignment(tooltip, size) {
    const model = tooltip._model;
    const chart = tooltip._chart;
    const chartArea = tooltip._chart.chartArea;
    let xAlign = 'center';
    let yAlign = 'center';

    if (model.y < size.height) {
        yAlign = 'top';
    } else if (model.y > (chart.height - size.height)) {
        yAlign = 'bottom';
    }

    let lf; let rf; // functions to determine left, right alignment
    let olf; let orf; // functions to determine if left/right alignment causes tooltip to go outside chart
    let yf; // function to get the y alignment if the tooltip goes outside of the left or right edges
    const midX = (chartArea.left + chartArea.right) / 2;
    const midY = (chartArea.top + chartArea.bottom) / 2;

    if (yAlign === 'center') {
        lf = (x) => x <= midX;
        rf = (x) => x > midX;
    } else {
        lf = (x) => x <= (size.width / 2);
        rf = (x) => x >= (chart.width - (size.width / 2));
    }

    olf = (x) => x + size.width + model.caretSize + model.caretPadding > chart.width;
    orf = (x) => x - size.width - model.caretSize - model.caretPadding < 0;
    yf = (y) => y <= midY ? 'top' : 'bottom';

    if (lf(model.x)) {
        xAlign = 'left';

        // Is tooltip too wide and goes over the right side of the chart.?
        if (olf(model.x)) {
            xAlign = 'center';
            yAlign = yf(model.y);
        }
    } else if (rf(model.x)) {
        xAlign = 'right';

        // Is tooltip too wide and goes outside left edge of canvas?
        if (orf(model.x)) {
            xAlign = 'center';
            yAlign = yf(model.y);
        }
    }

    const opts = tooltip._options;

    return {
        xAlign: opts.xAlign ? opts.xAlign : xAlign,
        yAlign: opts.yAlign ? opts.yAlign : yAlign
    };
}
