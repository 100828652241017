import { Component, OnInit } from '@angular/core';
import { TEXTS } from 'src/texts/texts';
import { CoreFacade } from '../../../../../cityscreen/src/modules/core/core-facade';
import { SharedCoreFacade } from '../../../../core/SharedCoreFacade';

@Component({
    selector: 'download-btn',
    templateUrl: './download-btn.component.html',
    styleUrls: ['./download-btn.component.less']
})
export class DownloadBtnComponent {

    TEXTS = TEXTS;

    constructor(private coreFacade: CoreFacade, private sharedCoreFacade: SharedCoreFacade) {
    }

    show = () => {
        const ids = this.sharedCoreFacade.getComparedList().map(mo => mo.id);

        this.coreFacade.openDownloadPopup$.next({
            type: 1,
            title: TEXTS.EDIT_STATION.downloadXLSData,
            ids,
            mos: this.coreFacade.getMOs(),
            devices: [],
            currentTabInterval: this.sharedCoreFacade.getChartDataIntervalType()
        })
    };
}
