<div class="setting_lang_selector">
    <div (click)="actions.setLang('ru')" class='setting_lang_selector__flag setting_lang_selector__flag-ru'>
        <div class="setting_lang_selector__title_main">Русский</div>
        <div class="setting_lang_selector__title_second">Russian</div>
        <div [ngClass]="{
        'setting_lang_selector__flag-active': lang == 'RU'
        }"></div>
    </div>
    <div (click)="actions.setLang('en')" class='setting_lang_selector__flag setting_lang_selector__flag-en'>
        <div class="setting_lang_selector__title_main">English</div>
        <div class="setting_lang_selector__title_second">Английский</div>
        <div [ngClass]="{
        'setting_lang_selector__flag-active': lang == 'EN'
        }"></div>
    </div>
</div>
