<div class="open_profile_btn" (click)="show = true"></div>

<div *showDirective="show" class="user_profile">
    <div class="user_profile__close" (click)="show = false"></div>

    <ca-language-selector-m class="user_profile__lang" (selectLang)="setLang($event)"></ca-language-selector-m>

    <cityscreen-user-card
            [user]="(store.select(selectGroupInfo) | async).iAm"
            (logout)="coreFacade.logOut()"
    ></cityscreen-user-card>

    <div>
        <div class="user_profile__group_text">{{TEXTS.PROFILE.network}}</div>

        <div class="user_profile__groups">
            <group-list-item-m *ngFor="let item of (store.select(selectGroupList) | async)"
                                        class="user_profile__group"
                                        [selected]="item.id === currentGroupId"
                                        [iconUrl]="item.icon"
                                        [name]="item.name"
                                        [postsCount]="item.moCount"
                                        (click)="coreFacade.changeGroup(item.id)"
            >
            </group-list-item-m>
        </div>
    </div>

    <div class="user_profile__footer">
        <div class="user_profile__text_support">{{TEXTS.PROFILE.support}}:</div>
        <div class="user_profile__text_info">
            <a href="tel:{{TEXTS.PROFILE.phone[0]}}" [innerHTML]="TEXTS.PROFILE.phone[1]"></a>
            <a style="padding-left: 20px" href="mailto:{{TEXTS.PROFILE.email}}">{{TEXTS.PROFILE.email}}</a>
        </div>
        <div class="user_profile__text_c">{{textC + TEXTS.PROFILE.c}}</div>
    </div>
</div>
