import { Component, Inject, Input } from '@angular/core';

import { isRU, TEXTS } from '../../../texts/texts';

import State from '../../../../map/state';

import AdminPanelActions from '../../../../projects/cityscreen/src/components/admin-panel/actions';

import './analytics-page.component.less';
import { CityCard, Marker_model } from '../../../namespace';
import { SharedCoreFacade } from '../../../../projects/shared/core/SharedCoreFacade';
import { IS_PUBLIC } from '../../../../projects/shared/utils/other-utils';

@Component({
    selector: 'analytics-page',
    templateUrl: './analytics-page.component.html'
})
export class AnalyticsPage {
    @Input() cityCard: CityCard;

    textsAdminPanel = TEXTS.ADMIN_PANEL;
    textsAnalytics = TEXTS.ANALYTICS_PAGE;
    textsCityCard = TEXTS.CITY_CARD;
    textsCommon = TEXTS.COMMON;
    isRU = isRU;

    sortListStation = [
        {props: (marker: Marker_model) => marker.name, title: TEXTS.COMMON.location},
        {props: (marker: Marker_model) => marker.aqi, title: 'AQI'}
    ];

    searchQuery = '';

    constructor(
        @Inject(IS_PUBLIC) readonly isPublic: boolean,
        readonly globalState: State,
        public sharedCoreFacade: SharedCoreFacade,
        public apActions: AdminPanelActions
    ) {}
}
