<div class="popup__wrapper_popup">
    <div class="popup__wrapper_input">
        <input-date-range
            [(timeBegin)]="downloadPopupObj.timeBegin"
            [(timeEnd)]="downloadPopupObj.timeEnd"
        ></input-date-range>
    </div>
    <div class="popup__wrapper_input">
        <input-dropdown
            [inputText]="selectedFiletype"
            [label]="texts.DOWNLOAD_POPUP.select_type"
            [(showDropdown)]="filetypeDropdownOpened"
            (clickOutside)="filetypeDropdownOpened = false"
        >
            <selectbox-radio
                [listItems]="listReportType"
                (listItemsChange)="getTypeReport($event); filetypeDropdownOpened = false"
            ></selectbox-radio>
        </input-dropdown>
    </div>
    <ng-container *ngIf="modelType == 1">
        <div class="popup__wrapper_input">
            <input-dropdown
                [inputText]="selectedCheckboxText(currentStation.length)"
                [label]="texts.LIST_DEVICES.selectDevices"
                [(showDropdown)]="devicesDropdownOpened"
                (clickOutside)="devicesDropdownOpened = false"
            >
                <cs-selectbox
                    [listItems]="objDropdownListStation"
                    (listItemsChange)="getObjectForReport($event)"
                    [searchPlaceholderText]="texts.DASHBOARD.searchDevicePlaceholderText"
                    [selectAllText]="texts.LIST_OM.checkAll"
                ></cs-selectbox>
            </input-dropdown>
        </div>
        <div class="popup__wrapper_tags_list scroll"
            [class.popup__wrapper_tags_list-active]="currentStation.length !== 0"
        >
            <tags-list
                *ngIf="currentStation.length !== 0"
                [tags]="currentStation"
                [truncateTo]=" maxInlineStation"
                [textNodesFns]="postsListLabels()"
                [editable]="true"
                (removeTag)="removePostFromList($event)"
            ></tags-list>
        </div>
    </ng-container>
</div>
<div class="popup__error scroll"
    [class.popup__error-active]="errorDownloadMsg"
>
    {{errorDownloadMsg}}
</div>
