import { Component, EventEmitter, Output } from '@angular/core';

import { TEXTS, LANGUAGE } from '../../../../../../src/texts/texts';
import { ConfigPage } from '../config.types';
import State from '../../../../../../map/state';

import './config-menu.components.less';

@Component({
    selector: 'config-menu',
    template: `
        <div class="config_menu">
            <div *ngIf="state.adminPanel.regionalCoefs?.currentPcf && state.adminPanel.myRole?.editGroup"
                class="config_menu__item"
                (click)="select(configPage.COEFFICIENT)"
                data-cy="settings-coefficient"
            >
                {{TEXTS.CONFIG.coeff}}
            </div>

            <div class="config_menu__item"
                (click)="select(configPage.API_TOKENS)"
                data-cy="settings-api-tokens"
            >
                {{TEXTS.CONFIG.token}}
            </div>

            <div class="config_menu__item"
                (click)="select(configPage.ACTIVITY_HISTORY)"
                data-cy="settings-activity-history"
            >
                {{TEXTS.CONFIG.history}}
            </div>

            <div class="config_menu__item"
                (click)="select(configPage.LANGUAGE)"
                data-cy="settings-langauge"
            >
                <div>{{TEXTS.LOGIN_WINDOWS.language}}</div>
                <div [ngClass]="{
                    'config_menu__flag': true,
                    'config_menu__flag-ru': lang == 'RU',
                    'config_menu__flag-en': lang == 'EN'
                }"></div>
                <div class="config_menu__description">
                    {{lang == 'RU' ? TEXTS.LOGIN_WINDOWS.ru : TEXTS.LOGIN_WINDOWS.en}}
                </div>
            </div>
        </div>
    `
})
export class ConfigMenuComponent {
    @Output() selectPage = new EventEmitter<ConfigPage>();

    configText = TEXTS.CONFIG;
    configPage = ConfigPage;
    TEXTS = TEXTS;
    lang: string = LANGUAGE.toLocaleUpperCase();

    constructor(public readonly state: State) {}

    select = (page: ConfigPage) => {
        this.selectPage.emit(page);
    }
}
