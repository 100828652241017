<ng-container [ngSwitch]="stateService.currentPage">
    <users-list *ngSwitchDefault [stateUsers]="users"></users-list>
    <edit-user *ngSwitchCase="pages.EDIT_USER"></edit-user>
</ng-container>

<ng-template #popupOutlet>
    <stnd-ask-popup
        *ngIf="stateService.isShowDeleteUserPopup"
        [accept]="deleteUser"
        [close]="hideDeleteUserPopup"
        [texts]="TEXTS.POPUP_DELETE_USER"
    >
    </stnd-ask-popup>
</ng-template>
