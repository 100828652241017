import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CityairGlobalQrModule } from 'projects/cityscreen/src/components/cityair-global-qr/cityair-global-qr.module';
import { PopupsModule } from 'projects/cityscreen/src/components/popups/popups.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { ChartsModule } from 'src/chart/charts.module';

import { TimelineSelectedItemComponent } from './components/timeline-selected-item/timeline-selected-item.component';
import { TimelineSelectedItemMobileComponent } from './components/timeline-selected-item-mobile/timeline-selected-item-mobile.component';

import { Timeline } from './components/timeline/timeline.component';
import { TimelineMobile } from './components/timeline-mobile/timeline-mobile.component';
import { TimelineFooterPdkComponent } from './components/timeline-footer-pdk/timeline-footer-pdk.component';
import { MapModule } from '../map/map.module';
import { DownloadBtnComponent } from './components/download-btn/download-btn.component';
import { SwitchItemModule } from 'projects/cityscreen/src/components/switch-item/switch-item.module';
import { PlayButtonComponent } from 'projects/shared/modules/timeline/components/play-button/play-button.component';

@NgModule({
    imports: [
        CommonModule,
        PopupsModule,
        LittleComponentsModule,
        ChartsModule,
        MapModule,
        SwitchItemModule,
        CityairGlobalQrModule,
    ],
    exports: [
        Timeline,
        TimelineMobile,
        TimelineSelectedItemComponent,
        TimelineSelectedItemMobileComponent,
    ],
    declarations: [
        Timeline,
        TimelineMobile,
        TimelineSelectedItemComponent,
        TimelineSelectedItemMobileComponent,
        TimelineFooterPdkComponent,
        DownloadBtnComponent,
        PlayButtonComponent,
    ],
})
export class TimelineModule {}
