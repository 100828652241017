import { CommonModule } from '@angular/common';
import { MobileHeaderComponent } from './mobile-header.component';
import { NgModule } from '@angular/core';
import { AqiLegendMobileComponent } from 'projects/cityscreen/src/components/aqi-legend-mobile/aqi-legend-mobile.component';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { CalendarModule } from 'src/calendar/calendar.module';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { MobileProfileComponent } from 'projects/cityscreen/src/components/mobile-profile/mobile-profile.component';
import { UserCardComponent } from 'projects/cityscreen/src/components/user-card/user-card.component';
import { LoginModule } from 'projects/cityscreen/src/modules/login/login.module';
import { GroupListItemModule } from 'projects/cityscreen/src/components/group-list-item/group-list-item.module';

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        SharedComponentsModule,
        DirectivesModule,
        LoginModule,
        GroupListItemModule,
    ],
    exports: [
        MobileHeaderComponent
    ],
    declarations: [
        MobileHeaderComponent,
        AqiLegendMobileComponent,
        MobileProfileComponent,
        UserCardComponent
    ]
})
export class MobileHeaderModule {}
