import { ChartDataSets } from 'chart.js';

type CustomChartData = {
    cutoutPercentage: number[];
    cutoutPercentageOuter: number[];
};

type CustomModel = {
    initialOuterRadius: number;
};

// use on: beforeDatasetsDraw
export function differentWidthArcsPlugin(
    chart: Chart & {
        data: {
            datasets: (ChartDataSets & CustomChartData)[]
        }
    }
) {
    chart.data.datasets.forEach((dataset: ChartDataSets & CustomChartData, di: number) => {
        const cutoutPercentage = dataset.cutoutPercentage;
        if (!cutoutPercentage || !cutoutPercentage.length) return;

        const cutoutPercentageOuter = dataset.cutoutPercentageOuter;
        if (!cutoutPercentageOuter || !cutoutPercentageOuter.length) return;

        const datasetMeta = chart.getDatasetMeta(di);

        datasetMeta.data.forEach(({ _model }, i: number) => {
            const model = _model as typeof _model & CustomModel;

            if (!model.initialOuterRadius) {
                model.initialOuterRadius = model.outerRadius;
            }

            const outerRadius = model.initialOuterRadius;
            const percentage = cutoutPercentage[i] / 100;
            const percentageOuter = cutoutPercentageOuter[i] / 100;

            model.innerRadius = outerRadius * percentage;
            model.outerRadius = outerRadius * percentageOuter;
        });
    });
};
