import {
    Component,
    Output,
    EventEmitter, Input
} from '@angular/core';

@Component({
    selector: 'ca-sidebar-toggle-button',
    templateUrl: 'sidebar-toggle-button.component.html',
    styleUrls: ['sidebar-toggle-button.component.less']
})
export class SidebarToggleButtonComponent {
    @Input() isActive: boolean;
    @Output() toggle = new EventEmitter<boolean>();

    togglePanel() {
        this.toggle.emit(!this.isActive);
    }
}
