import { ApiRequest, DeviceSourceItem, DeviceItem, PacketsValueTypeItem, MoCountryItem, MoLocationItem, MoItem, MoPacket, RegionCoefsItem, ErrorResult } from "../../models";
import { GroupItem, GroupsRolesItem } from "../adminApiProvider/adminApiModels";

//-----------------------------------------------------------------------

export class MoApiItemsRequest extends ApiRequest {
    constructor() {
        super();
    }
}

export class MoApiGroupsResponse {
    Groups: GroupItem[];
    GroupsRoles: GroupsRolesItem[];
}

export class MoApiItemsResponse {
    //Tags: TagItem[];
    //DataProviders: MoDataProviderItem[];
    Countries: MoCountryItem[];
    Locations: MoLocationItem[];
    RegionCoefs: RegionCoefsItem[];
    //PacketValueTypes: PacketsValueTypeItem[];

    DeviceSources: DeviceSourceItem[];
    Devices: DeviceItem[];

    MoItems: MoItem[];
}

//-----------------------------------------------------------------------

export class MoApiItemRequest extends ApiRequest
{
    GroupId: number;
    Item: MoItem;

    constructor(groupId: number, item: MoItem) {
        super();
        this.GroupId = groupId;
        this.Item = item;
    }
}

//-----------------------------------------------------------------------

export enum MoPacketFilterType {
    ByDate = 1, ByTimestamp, BySkipFromLast
}

export enum MoIntervalType {
    Interval5M = 1,
    Interval20M = 2,
    Interval1H = 3,
    Interval1D = 4,
    Source = 100
}

export class MoPacketFilter {
    FilterType: MoPacketFilterType;
    IntervalType: MoIntervalType;
    MoId: number;

    BeginTime?: Date;
    EndTime?: Date;

    Timestamp?: number;
    SkipFromLast?: number;

    TakeCount?: number;
}

export class MoApiPacketsRequest extends ApiRequest {
    MeasureScheme: string;
    Filter: MoPacketFilter;

    constructor(measureScheme: string, filter: MoPacketFilter) {
        super();
        this.MeasureScheme = measureScheme;
        this.Filter = filter;
    }
}

export class MoApiPacketsResponse {
    MeasureSchemeItems: PacketsValueTypeItem[];
    Packets: MoPacket[]; 
    LastTimestamp: number;
}

//-----------------------------------------------------------------------

export enum MoDataExportType {
    Unknown = 0, ExcelReport = 1, Tza4ExcelReport = 2
}

export enum MoUnitsType {
    Unknown = 0, Micrograms, Milligrams
}

export class MoApiExportPacketsRequest extends ApiRequest {
    ExportType: MoDataExportType;
    UnitsType: MoUnitsType;
    MoIds: number[];
    IntervalType: MoIntervalType;
    InsertPdk: boolean;
    TimeBegin: Date;
    TimeEnd: Date;
    TimeZone: number;
    Lang: string;
}

//-----------------------------------------------------------------------

export interface IMoApiProvider {
    getGroupsItems(request: MoApiItemsRequest, execOk: (response: MoApiGroupsResponse) => void, execFail?: (errRes: ErrorResult) => void): void
    getMoItems(request: MoApiItemsRequest, execOk: (responce: MoApiItemsResponse) => void, execFail?: (errRes: ErrorResult) => void): void;
    addMoItem(request: MoApiItemRequest, execOk: (responce: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    editMoItem(request: MoApiItemRequest, execOk: (responce: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    resetMoData(request: MoApiItemRequest, execOk: (responce: string) => void, execFail?: (errRes: ErrorResult) => void): void;
    deleteMoItem(request: MoApiItemRequest, execOk: (responce: string) => void, execFail?: (errRes: ErrorResult) => void): void;

    getMoPackets(request: MoApiPacketsRequest, execOk: (responce: MoApiPacketsResponse, execFail?: (errRes: ErrorResult) => void) => void): void;

    exportMoPacketsToExcel(request: MoApiExportPacketsRequest, execOk: (responce: string, execFail?: (errRes: ErrorResult) => void) => void): void;
}


