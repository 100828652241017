import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LANGUAGE } from 'src/texts/texts';
import { WindowGlobalVars } from 'src/namespace';

declare const window: WindowGlobalVars;

class Options {
    headers: HttpHeaders;

    params?: HttpParams | {
        [param: string]: string | string[];
    };

    constructor(params?: {[param: string]: string | string[]}) {
        this.headers = new HttpHeaders({
            'Authorization': 'Token ' + window.JS_CP_TOKEN,
            'Accept-Language': LANGUAGE
        });

        this.params = params;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ProviderApi {
    constructor(private http: HttpClient) {}

    get (url: string, params: {[param: string]: string | string[]}, errorHandler: (error: HttpErrorResponse) => ObservableInput<any>): Promise<any> {
        return this.http.get(url, new Options(params))
            .pipe(catchError(errorHandler))
            .toPromise()
    }

    post (url: string, body: any, errorHandler: (error: HttpErrorResponse) => ObservableInput<any>): Promise<any> {
        return this.http.post(url, body, new Options())
            .pipe(catchError(errorHandler))
            .toPromise();
    }

    delete (url: string, errorHandler: (error: HttpErrorResponse) => ObservableInput<any>): Promise<any> {
        return this.http.delete(url, new Options())
            .pipe(catchError(errorHandler))
            .toPromise();
    }

    patch (url: string, body: any, errorHandler: (error: HttpErrorResponse) => ObservableInput<any>): Promise<any> {
        return this.http.patch(url, body, new Options())
            .pipe(catchError(errorHandler))
            .toPromise();
    }
}
