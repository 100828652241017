import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';
import { selectGroupList, selectGroupInfo } from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { Group } from 'src/api/adminPanel/models';

@Component({
    selector: 'group-change-selector',
    templateUrl: './group-change-selector.component.html',
    styleUrls: ['./group-change-selector.component.less']
})
export class GroupChangeSelectorComponent implements OnInit {
    selectGroupList = selectGroupList;

    currentGroupId: number;
    group: Group;
    show = false;

    constructor(public store: Store, public coreFacade: CoreFacade) {
    }

    ngOnInit(): void {
        this.store.select(selectGroupInfo).pipe(filter(g => !!g?.groupId), take(1)).subscribe(g => {
            this.currentGroupId = g.groupId;

            this.store.select(selectGroupList).pipe(take(1)).subscribe(list => {
                this.group = list.find(group => group.id === this.currentGroupId);
            });
        });
    }

}
