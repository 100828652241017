// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/options--inline.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../img/options-hover--inline.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subscription_card{background:#fff;box-sizing:border-box;border-radius:8px;margin:0 14px 12px}.subscription_card--disabled{background:#f7f9fc}.subscription_card__header{position:relative;display:flex;justify-content:space-between;align-items:center;padding:0 11px 0 18px;height:44px;border-radius:8px 8px 0 0;background:#f7f9fc}.subscription_card__title{flex-basis:100%;font-weight:500;font-size:18px;letter-spacing:-.015em;color:#21242b}.subscription_card__title--disabled{color:#95a0b3}.subscription_card__status{display:flex;align-items:center;min-width:76px;justify-content:center;height:18px;font-weight:400;font-size:12px;letter-spacing:-.2px;color:#b8bfcc;background:#e6ecf2;border-radius:2px;overflow:hidden;transition:all .2s linear}.subscription_card__status-active{min-width:62px;color:#a2c618;background:#e3eeba}.subscription_card__more{flex-basis:18px;flex-shrink:0;margin-left:8px;height:20px;cursor:pointer;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:4px 18px;background-position:50%;transition:all .2s linear}.subscription_card__more:hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.subscription_card__popup_wrapper_input{padding-bottom:100px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
