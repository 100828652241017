import { Component, Input, OnChanges } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { ALERT_COLORS } from 'src/config';
import { options } from 'projects/cityscreen/src/components/analytic-charts/doughnut-chart/doughnut-chart.config';
import { declOfNum } from 'src/utils';
import { TEXTS } from 'src/texts/texts';
import { AQI } from 'src/namespace';

@Component({
    selector: 'cs-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.less']
})
export class DoughnutChartComponent implements OnChanges {
    @Input() data: number[];
    @Input() tooltipDescription: string;
    @Input() measure?: string = TEXTS.NAMES[AQI];

    TEXTS = TEXTS;
    ALERT_COLORS = ALERT_COLORS;
    options = options;

    public tooltip: string;
    public labels: string[];
    public datasets: ChartDataSets[];

    ngOnChanges() {
        this.tooltip = TEXTS.ANALYTICS_COMPONENT.tooltip(0, this.measure);

        this.labels = this.data.map((_, i) => i + 1 + ' ' + declOfNum(i + 1, TEXTS.AQI_DECL2));

        this.datasets = [{
            data: this.data,
            backgroundColor: ALERT_COLORS.slice(1),
            // borderWidth: 0,
        }];
    }
}
