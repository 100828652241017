<div class="post-report">
    <div class="post-report__main-block scroll">
        <header-of-panel class="post-report__header-of-panel"
                         [path]="navigationLink"
                         (outputPath)="backToPrevPage($event)"
                         [title]="TEXTS.POSTS_AND_DEVICES.dataFromPost">
        </header-of-panel>
        <input-date-range class="post-report__input-date-range"
                          [timeBegin]="objectForReportMo.timeBegin"
                          [timeEnd]="objectForReportMo.timeEnd"
                          (timeBeginChange)="getDateBeginRangeFromCalendar($event)"
                          (timeEndChange)="getDateEndRangeFromCalendar($event)"
        ></input-date-range>

        <input-dropdown class="post-report__input-dropdown"
                        [inputText]="selectedCheckboxText(getTags().length)"
                        [label]="TEXTS.POSTS_AND_DEVICES.postsMonitoring"
                        [(showDropdown)]="isShowDropdownForStations"
                        (clickOutside)="isShowDropdownForStations = false"
        >
            <cs-selectbox2
                    [listItems]="listStations"
                    (itemChange)="itemSelect($event)"
            ></cs-selectbox2>
        </input-dropdown>

        <tags-list2
                class="post-report__tags-list"
                [tags]="getTags()"
                [limit]="4"
                [textFunctions]="postsListLabels"
                (removeTag)="removeFromList($event)">
        </tags-list2>

        <div class="post-report__title">{{TEXTS.POSTS_AND_DEVICES.inthevalData}}</div>
        <switch-item
                class="post-report__switch-item"
                [currentTab]="currentFilterTabsInterval"
                [tabs]="filterTabsInterval"
                (action)="setTabsInterval($event)"
        ></switch-item>
        <div class="post-report__title">{{TEXTS.POSTS_AND_DEVICES.units}}</div>
        <div class="post-report__end-block">
            <switch-item
                    [currentTab]="currentUnitsMeasurements"
                    [tabs]="unitsMeasurementsList"
                    (action)="getUnitMeasurement($event)"
            ></switch-item>
            <div *ngIf="isRU" [ngClass]="{
                    'post-report__cs-checkbox': true,
                    'post-report__cs-checkbox-disabled': isDisabledPdk
                 }">
                <cs-checkbox
                        (click)="toggleInsertPdk()"
                        [checked]="objectForReportMo.insertPdk"
                        [disabled]="isDisabledPdk"></cs-checkbox>
                <div (click)="toggleInsertPdk()">{{TEXTS.POSTS_AND_DEVICES.pightsOfPDK}}</div>
            </div>
        </div>
    </div>
    <div class="post-report__btn">
        <btn-cancel (click)="backToPrevPage(navigationLink[navigationLink.length - 2])">
            {{TEXTS.COMMON.cancel}}
        </btn-cancel>
        <btn-accept
                [disabled]="getTags().length === 0 || isActiveLoader"
                [isLoading]="isActiveLoader"
                (cbClick)="downloadReportMo()"
        >
            {{TEXTS.DOWNLOAD_POPUP.download}}
        </btn-accept>
    </div>
</div>
