import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { ControlPoint } from 'projects/cityscreen/src/modules/plumes/services/models/control-point-model';
import { PlumesFacadeService } from 'projects/cityscreen/src/modules/plumes/plumes-facade.service';
import { TEXTS } from 'src/texts/texts';
import { measureScheme } from 'src/namespace';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { SharedCoreFacade } from '../../../../../../shared/core/SharedCoreFacade';
import { GroupExtConfigName, GroupFeaturesService } from '../../../core/services/group-features/group-features.service';

@Component({
    selector: 'plumes-control-points',
    templateUrl: 'plumes-control-points.component.html',
    styleUrls: ['plumes-control-points.component.less']
})
export class PlumesControlPoints {
    @Output() cbOpenAddCheckpoint = new EventEmitter<void>();
    @Output() cbOpenEditCheckpoint = new EventEmitter<void>();
    @Output() controlPointForEditChange = new EventEmitter<ControlPoint>();
    @Input() controlPointForEdit: ControlPoint;
    @Input() openChartControlPoint: (pointId: number, name: string, lat: number, lng: number, measures: string) => void;
    @Input() centringOnMarker: (lat: number, lng: number, zooming: boolean, shiftMap?: [number, number]) => void;

    @ViewChild('actionsOutlet', { static: true }) actionsOutlet: TemplateRef<HTMLDivElement>;
    @ViewChild('popupDeleteOutlet', { static: true }) popupDeleteOutlet: TemplateRef<HTMLDivElement>;

    texts = TEXTS;

    GroupExtConfigName = GroupExtConfigName;
    measureScheme = measureScheme;

    sortingControlPoint;
    sortingDirection = 1;
    allPoints: ControlPoint[];

    popupActive = false;
    popupPositionTop = 0;

    selectedPoint: ControlPoint = null;

    popupOpenerElement: HTMLElement;

    isDeletePopupOpen: boolean;
    popupDeleteTexts = Object.assign({}, TEXTS.PLUMES.popupDelete);

    constructor (
        readonly popupProvider: OffPanelPopupService,
        public plumeFacade: PlumesFacadeService,
        public sharedCoreFacade: SharedCoreFacade,
        public groupFeaturesService: GroupFeaturesService
    ) {
        this.plumeFacade.allPoints$.subscribe(data => this.allPoints = data);
    }

    sortControlPointName = (cp: ControlPoint) => cp.name;
    sortControlPointValue = (cp: ControlPoint) => this.plumeFacade.uiState.getPointValue(cp.id);

    sortingOfControlPoint = (sortItem) => {
        if (this.sortingControlPoint === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingControlPoint = sortItem;
        }
    };

    openAddCheckpoint = () => {
        this.cbOpenAddCheckpoint.emit();
    }

    deleteControlPoint = (id: number) => {
        this.plumeFacade.deletePoint(id);
    }

    editControlPoint = () => {
        this.controlPointForEditChange.emit(this.selectedPoint);
        this.cbOpenEditCheckpoint.emit();
    }

    openControlPointChart(point: ControlPoint) {
        if (!this.plumeFacade.getCurrentRunId())
            return;

        this.centringOnMarker(point.lat, point.lon, false);

        this.openChartControlPoint(point.id, point.name, point.lat, point.lon, this.plumeFacade.getCurrentMeasure());
    }

    openActionsPopup({ target, positionTop }, point: ControlPoint) {
        this.popupOpenerElement = target;
        this.popupProvider.setTemplate(this.actionsOutlet);
        this.popupActive = true;
        this.selectedPoint = point;
        this.popupPositionTop = positionTop;
    }

    closeActionsPopup(e: Event) {
        if (this.popupActive && this.popupOpenerElement !== e.target) {
            this.popupProvider.setTemplate(null);
            this.popupActive = false;
            this.selectedPoint = null;
        }
    }

    deleteControlPointAccept = () => {
        this.closeDeleteControlPointDialog();
        this.deleteControlPoint(this.selectedPoint.id);
    }

    closeDeleteControlPointDialog = () => {
        this.isDeletePopupOpen = false;
        this.popupProvider.setTemplate(null);
    }

    deleteControlPointDialog(e: Event, point: ControlPoint) {
        this.closeActionsPopup(e);
        const { body } = this.texts.PLUMES.popupDelete;
        this.popupDeleteTexts.body = body.replace('%s', point.name);
        this.isDeletePopupOpen = true;
        this.popupProvider.setTemplate(this.popupDeleteOutlet);
        this.selectedPoint = point;
    }

    onScroll(e: Event) {
        this.closeActionsPopup(e);
    }
}
