import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import State from './state';
import { getShiftMapCityCard, getShiftMapMobile, MAP_PROVIDERS } from 'src/config';

import { createOriginChartData, createWorldTimelineAqiHistory } from 'src/chart/model';

import {
    CHART_NAME_CITYAIR,
    CHART_NAME_TIMELINE,
    City_model,
    CompareActions,
    InitiatorToCloseTimeline,
    Marker_model,
    measureScheme,
    MeasuresForTime,
    namePages,
    StationData,
    StationForMapPage_model,
    TimeEventFrom,
    UpdatesCommand,
    UpdatesProps,
    WindowGlobalVars,
} from 'src/namespace';

import { copyObj, testLocalStorage } from 'src/utils';

import { AdminPanelApi } from 'src/api/adminPanel/api';
import { GetMoPacketsApiRequest } from 'src/api/mapProvider/cityairMapModels';
import { DevicesApi } from 'src/api/DevicesApi/api';
import { ErrorTransformer_model } from 'src/api/dataProvider/DataProvider';
import { CityscreenMapApi } from 'src/api/mapProvider/cityscreenMapApi';
import { MapApiProvider } from 'src/api/mapProvider/MapApiProvider';
import { MessageAPIResponseService } from 'src/little-components/message-api-response/message-api-response.service';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { MapActionsInterface } from 'projects/shared/modules/map/mapActionsInterface';
import { MapActionsWrapper, MapStateWrapper } from 'projects/shared/modules/map/wrapper';
import MapboxActions from 'projects/shared/modules/map/components/mapbox/mapboxActions';
import NullMapActions from 'projects/shared/modules/map/nullMapActions';
import { LOAD_STATION_SMALL_INTERVAL } from 'projects/shared/utils/config';
import { detectMobile } from 'projects/shared/utils/other-utils';
import { environment } from 'projects/cityscreen/src/environments/environment';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';
import {
    GroupExtConfigName,
    GroupFeaturesService
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';
import { PlumesFacadeService } from 'projects/cityscreen/src/modules/plumes/plumes-facade.service';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { coreSelector } from 'projects/cityscreen/src/modules/core/store/selectors';

import { IntervalAction } from './intervalActions';
import { TEXTS } from 'src/texts/texts';
import { RoutingService } from 'projects/cityscreen/src/modules/core/routing.service';

declare let window: WindowGlobalVars;

@Injectable()
export default class Actions {
    mapActions: MapActionsInterface;

    intervalActions: IntervalAction;

    cityairMapApi: MapApiProvider;

    constructor(
        private coreFacade: CoreFacade,
        public state: State,
        public sharedCoreFacade: SharedCoreFacade,
        public cityscreenMapApi: CityscreenMapApi,
        public adminPanelApi: AdminPanelApi,
        public devicesApi: DevicesApi,
        public zone: NgZone,
        public msgService: MessageAPIResponseService,
        private plumesFacadeService: PlumesFacadeService,
        private groupFeaturesService: GroupFeaturesService,
        private store: Store,
        private time: Time,
        private routingService: RoutingService
    ) {

        const MapActionsCtor = {
            [MAP_PROVIDERS.MapBox]: MapboxActions
        }[this.state.mapProvider] || NullMapActions;

        this.cityairMapApi = cityscreenMapApi;

        // OBSERVABLE ----------------------------------------------------------
        // TODO move its to facade

        this.time.timeUpdated
            .subscribe(props => {
                if (
                    props.eventFrom === TimeEventFrom.clickOnChart ||
                    props.eventFrom === TimeEventFrom.dragFlag ||
                    props.eventFrom === TimeEventFrom.timeToEndBtn
                ) {
                    this.sharedCoreFacade.setIsPlayingFalse();
                }
            });

        this.sharedCoreFacade.actionObservables.updateCompareStations.subscribe(() => {
            this.updateCompareStations();
        });

        this.sharedCoreFacade.actionObservables.maybeCloseTimeline.subscribe((initiator: InitiatorToCloseTimeline) => {
            this.maybeCloseTimeline(initiator);
        });

        this.sharedCoreFacade.actionObservables.loadCity.subscribe((props) => {
            this.loadCity(props.cityId, props.centringMap);
        });

        this.sharedCoreFacade.actionObservables.mapMarkerClick.subscribe((marker) => {
            this.mapMarkerClick(marker);
        });

        this.sharedCoreFacade.actionObservables.clickOnCityMarker.subscribe((marker) => {
            this.clickOnCityMarker(marker);
        });

        this.sharedCoreFacade.actionObservables.disableCityMod.subscribe((centringWorld: boolean) => {
            this.cityModOff(centringWorld);
        });

        this.sharedCoreFacade.actionObservables.updateData.subscribe((params) => {
            this.updateData(params.command, params.props);
        });

        this.time.intervalUpdated.subscribe(() => {
            this.updateTimeDiapason();
        });

        this.store.select(coreSelector).subscribe(store => {
            if (!store.collections.groups.length) {
                return;
            }

            store = copyObj(store); // иначе ошибка при изменении в этих данных

            this.state.cities = store.collections.cities.filter(c => c.aqiHistory && c.aqiHistory.length);
            this.state.map.myMarkers = store.map.markers;
            this.state.map.moMarkers = [];
            this.state.map.commonMarkers = store.map.markers
            this.state.map.citiesMarkers = store.map.citiesMarkers;
            this.sharedCoreFacade.setWorldAqiHistory(createWorldTimelineAqiHistory(this.time.getBegin(), this.time.getEnd()));
            this.sharedCoreFacade.setLocations(store.collections.cities);

            // TODO костыль а то перетирается aqi у маркеров созданный в mapActions
            this.mapActions.updateAllMarkersIcon();

            setTimeout(() => this.state.allLoaded = true, 200); // зелёная нотификация в админ панеле проглядывает
        })

        // /OBSERVABLE ----------------------------------------------------------

        this.mapActions = new MapActionsCtor(
            <MapStateWrapper>this.state,
            <MapActionsWrapper>this,
            zone,
            this.sharedCoreFacade,
            this.groupFeaturesService,
            this.time
        );

        this.cityairMapApi.setErrorTokenCb(this.errorToken);
        this.cityairMapApi.setErrorCb(this.errorCb);
        this.devicesApi.setErrorTokenCb(this.errorToken);
        this.devicesApi.setErrorCb(this.errorCb);

        if (environment.is_mobile_app) {
            const interval = setInterval(() => {
                if (document.getElementById('no_connect')) {
                    document.getElementById('no_connect').style.display = 'none';
                    clearInterval(interval);
                }
            }, 100)
        }

        this.intervalActions = new IntervalAction(state, this, sharedCoreFacade, groupFeaturesService, coreFacade, time);

        window.state = this.state;
    }

    errorCb = (error: ErrorTransformer_model) => this.state.errorMsg = error.message;

    errorToken = () => {
        if (window.CLOSED_MAP) {
            this.logOut(false);
        }
    };

    public logOut = async (isCloseToken = true, isReload = true) => {
        this.coreFacade.logOut(isCloseToken, isReload);
    };

    private modifyFeaturesStartConfig(command: UpdatesCommand, props: UpdatesProps) {
        const defaultMo = this.groupFeaturesService.getConfig(GroupExtConfigName.defaultMo);
        const locationWidget = this.groupFeaturesService.getConfig(GroupExtConfigName.locationWidget);

        if (locationWidget && !props.cityId) {
            props.cityId = locationWidget as string;
            command.push('selectCity');
        }

        if (defaultMo && (!props.mos || !props.mos.length)) {
            props.mos = [defaultMo as number];
            command.push('loadMos');
        }
    }

    updateData = async (command: UpdatesCommand, props: UpdatesProps) => {
        this._fixPrivateNames();

        if (command.includes('firstOpen')) {
            this.modifyFeaturesStartConfig(command, props);
        }

        if (command.includes('selectCity')) {
            this.selectCity(props.cityId, props.centringMap);
        }

        this._updateAqiHistory(props.cityId);

        this.time.updateAfterLoadTimeline();

        if (command.includes('loadMos')) {
            props.mos.length > 1 ?
                this.sharedCoreFacade.setCompareModTrue() :
                this.sharedCoreFacade.setCompareModFalse();

            props.mos.forEach(id => {
                // city
                if (id < 0) {
                    const marker = this.state.map.citiesMarkers.find(c => c.id === -id);

                    if (marker) {
                        this.clickOnCityMarker(marker);
                    }
                } else {
                    this.loadObjMonitoringData(id);
                }
            });

            // передвигаем карту
            const marker = this.sharedCoreFacade.getComparedList()[0];

            if (marker) {
                setTimeout(() => {
                    this.mapActions.centringOnMarker(
                        marker.lat,
                        marker.lng,
                        true,
                        detectMobile() ? getShiftMapMobile() : getShiftMapCityCard()
                    )
                });
            }
        }

        if (command.includes('updateCityChart')) {
            this.updateCitiesChart();
        }
    };

    private _fixPrivateNames() {
        const monitoringObjects = this.coreFacade.getMOs();

        if (monitoringObjects.length) {
            this.state.map.commonMarkers
                .forEach(marker => {
                    const found = monitoringObjects.find(mo => mo.id === marker.id);
                    if (found) {
                        marker.name = found.name;
                    }
                });

            this.sharedCoreFacade.getComparedList()
                .forEach(station => {
                    const found = monitoringObjects.find(mo => mo.id === station.id);
                    if (found) {
                        station.pubName = '';
                        station.name = found.name;
                    }
                });
        }
    }

    public setLang = (str: 'ru' | 'en') => {
        localStorage.setItem('lang', str);
        location.reload();
    }

    private _findMarker = (id: number): Marker_model => {
        return this.state.map.moMarkers.find(m => m.id === id)
            || this.state.map.myMarkers.find(m => m.id === id);
    };

    public actionsBeforeCompare(stationToAdd: StationForMapPage_model | Marker_model): CompareActions {
        const length = this.sharedCoreFacade.getComparedList().length;
        const haveControlPoint = !!this.sharedCoreFacade.getComparedList().find(s => s.type === 'geoPoint')
            || stationToAdd.type === 'geoPoint';
        const isLimit = this.sharedCoreFacade.isComparedListLimited();
        const stationIsInCompared = this.sharedCoreFacade.getComparedList().findIndex(s => {
            return s.id === stationToAdd.id && s.type === stationToAdd.type
        });

        return {
            notAllow: (this.sharedCoreFacade.getIsCompareMod() && isLimit && stationIsInCompared < 0) ||
                (length === 1 && stationIsInCompared === 0),
            clearBefore: haveControlPoint || !this.sharedCoreFacade.getIsCompareMod(),
            add: !isLimit && stationIsInCompared === -1,
            deleteCurrentIndex: length === 1 ? -1 : stationIsInCompared
        }
    }

    private _loadStation(id: number, isPrivate: boolean) {
        return this.cityairMapApi.getMoPackets(
            <GetMoPacketsApiRequest>{
                id,
                timeBegin: this.time.getBegin(),
                timeEnd: this.time.getEnd(),
                interval: this.sharedCoreFacade.getChartDataIntervalType(),
                isPrivate
            },
            this.groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme)
        );
    }

    closeErrorPopup = () => this.state.errorMsg = null;

    startPopupClose(checkbox: boolean) {
        this.state.startPopupShow = false;

        if (testLocalStorage()) {
            sessionStorage.setItem('hideStartPopup', 'true');

            if (checkbox) {
                localStorage.setItem('hideStartPopup', 'true');
            }
        }
    }

    _updateAqiHistory(cityId?: string) {
        const city = this.state.cities.find(c => c.id === cityId);

        this.sharedCoreFacade.setCityAqiHistory(city ? city.aqiHistory : this.sharedCoreFacade.getWorldAqiHistory());

        this.sharedCoreFacade.actionObservers.comparedChart.next(CHART_NAME_TIMELINE);
    }

    selectCity = (cityId: string, centringMap: boolean) => {
        const city = this.state.cities.find(c => c.id === cityId); // в том числе если cityId = null

        if (!city || !city.aqiHistory.length)
            this.cityModOff(true);
        else {
            this.cityModOn(city, centringMap);
            this.sharedCoreFacade.actionObservers.openCityAnalytic.next(cityId)
        }
    };

    getStorageStartPopupOptions() {
        let a = false;

        if (testLocalStorage()) {
            a = !(localStorage.getItem('hideStartPopup') || sessionStorage.getItem('hideStartPopup'));
        }

        this.state.startPopupShow = a;
    }

    cityModOn = (city: City_model, centringMap = true) => {
        this.sharedCoreFacade.setIsCityMod(true);
        this.sharedCoreFacade.setCurrentCity(city);

        if (centringMap) {
            this.mapActions.centringMap();
        }

        this.sharedCoreFacade.setIsPlayingFalse();

        this.sharedCoreFacade.actionObservers.locationId.next(city.locationId);
    };
    cityModOff = (centringWorld: boolean) => {
        this.sharedCoreFacade.setIsCityMod(false);
        this.sharedCoreFacade.clearCurrentCity();

        if (centringWorld) {
            this.mapActions.centringMap();
        }

        this.sharedCoreFacade.clearCompareList();
        this.sharedCoreFacade.setCompareModFalse();

        this.sharedCoreFacade.setIsPlayingFalse();

        this.updateData([], {});
    };

    updateStationData(data: StationData, stationId) {
        const station = this.sharedCoreFacade.getComparedListObject(stationId);

        if (!station) {
            return;
        }

        station.originChartData = createOriginChartData(
            data.packets,
            station.tzOffset && station.isOurStation ? station.tzOffset : this.sharedCoreFacade.getTzOffset(),
            this.time.getBegin(),
            this.time.getEnd(),
            this.sharedCoreFacade.getChartDataIntervalType(),
            data.measuresInfo
        );

        station.lastPacketID = data.lastPacketID;

        this.sharedCoreFacade.actionObservers.comparedChart.next(CHART_NAME_CITYAIR);
    }

    updateCitiesChart = () => {
        let notHaveCity = false;

        this.sharedCoreFacade.getComparedList().forEach(comparedStation => {
            if (comparedStation.type !== 'city') {
                return;
            }

            const find = this.state.cities.find(c => c.locationId === comparedStation.id);
            if (find) {
                comparedStation.originChartData = find.originChartData;
            } else {
                notHaveCity = true;
            }
        });

        if (notHaveCity) {
            this.sharedCoreFacade.actionObservers.closeTimeline.next();
        } else {
            this.sharedCoreFacade.actionObservers.changeTimeline.next(CHART_NAME_CITYAIR);
            this.sharedCoreFacade.actionObservers.comparedChart.next(CHART_NAME_CITYAIR);
        }

        this.sharedCoreFacade.setMainChartLoadingFalse();
    };

    mapMarkerClick = (marker: Marker_model) => {
        const st = this.state;

        // TODO: implement in mapService
        if (this.routingService.getActivePage() === namePages.notifications && st.map.notifiableMos) {
            const {notifiableMos, notifiableMos$} = st.map;
            if (notifiableMos) {
                notifiableMos$.pipe(take(1)).subscribe(value => {
                    notifiableMos$.next(
                        notifiableMos.indexOf(marker.id) !== -1
                            ? value.filter(v => v !== marker.id)
                            : value.concat(marker.id)
                    );
                });
            }
            return;
        }

        const compareActions = this.actionsBeforeCompare(marker);

        if (compareActions.notAllow) {
            return;
        }

        if (~compareActions.deleteCurrentIndex) {
            return this.sharedCoreFacade.actionObservers.deleteCompareStation.next(compareActions.deleteCurrentIndex);
        }

        if (compareActions.clearBefore) {
            this.sharedCoreFacade.clearCompareList();
        }

        if (!compareActions.add) {
            return;
        }

        this.sharedCoreFacade.actionObservers.changeTimeline.next(CHART_NAME_CITYAIR);

        if (marker.type === 'myMo') {
            this.loadObjMonitoringData(marker.id);
        } else {
            this.mapAddStation(marker);
        }

        // если маркер вне города
        if (!marker.cityId) {
            this.centringMapOnMO(marker.id);
        }

        if (detectMobile()) {
            this.mapActions.centringOnMarker(marker.lat, marker.lng, true, getShiftMapMobile());
            this.sharedCoreFacade.actionObservers.timelineMobileDragState.next(false);
        }
    };

    clickOnCityMarker = (cityMarker: Marker_model) => {
        const city = this.state.cities.find(c => c.id === cityMarker.cityId);

        if (!city) {
            return;
        }

        const station = new StationForMapPage_model(cityMarker, city.originChartData);

        const compareActions = this.actionsBeforeCompare(station);

        if (compareActions.notAllow) {
            return;
        }

        if (~compareActions.deleteCurrentIndex) {
            return this.sharedCoreFacade.actionObservers.deleteCompareStation.next(compareActions.deleteCurrentIndex);
        }

        if (compareActions.clearBefore) {
            this.sharedCoreFacade.clearCompareList();
        }

        if (compareActions.add) {
            this.sharedCoreFacade.compareListAdd(station);
        }


        if (compareActions.clearBefore && compareActions.add) {
            this.sharedCoreFacade.actionObservers.openCityAnalytic.next(cityMarker);
        }

        if (compareActions.add && detectMobile()) {
            this.mapActions.centringOnMarker(cityMarker.lat, cityMarker.lng, true, getShiftMapMobile());
        }

        this.updateCitiesChart();

        this.sharedCoreFacade.actionObservers.timelineMobileDragState.next(false);
    };

    loadCity = async (cityId: string, centringMap: boolean) => {
        this.sharedCoreFacade.actionObservers.closeTimeline.next();

        if (cityId) {
            const arr: UpdatesCommand = ['selectCity'];

            this.updateData(
                arr,
                {
                    cityId,
                    setTime: this.time.getCurrent(),
                    centringMap
                }
            );
        } else {
            this.cityModOff(true);
        }

        this.closeSelectCity();
    };

    mapAddStation = async (marker) => {
        this.sharedCoreFacade.compareListAdd(new StationForMapPage_model(marker));

        const data = await this._loadStation(marker.id, marker.type === 'myMo');

        this.sharedCoreFacade.setMainChartLoadingFalse();
        this.updateStationData(data, marker.id);
    };

    maybeCloseTimeline = (initiator: InitiatorToCloseTimeline) => {
        if (this.sharedCoreFacade.isComparedListEmpty()) {
            return;
        }

        let needToClose = false

        if (initiator === 'plume') {
            needToClose = needToClose || !!this.sharedCoreFacade.getComparedList().find(s => s.id < 0); // control point
        }

        if (needToClose) {
            this.sharedCoreFacade.actionObservers.closeTimeline.next();
        }
    }

    updateTimeDiapason = () => {
        this.sharedCoreFacade.setIsPlayingFalse();

        const commands: UpdatesCommand = [];

        const station = this.sharedCoreFacade.getComparedList()[0];

        if (station?.type === 'geoPoint') {
            // TODO костыль, только одна контрольная точка может быть
            this.openChartControlPoint(station.id, station.name, station.lat, station.lng);
        } else {
            if (this.sharedCoreFacade.getComparedList().find(s => s.type !== 'city')) {
                this.updateCompareStations();
            }

            if (this.sharedCoreFacade.getComparedList().find(s => s.type === 'city')) {
                commands.push('updateCityChart');
            }
        }

        this.coreFacade.getCSTimelineInfo(
            commands,
            {
                cityId: this.sharedCoreFacade.getIsCityMod() ? this.sharedCoreFacade.getCurrentCityId() : null
            }
        );
    };

    updateCompareStations() {
        this.sharedCoreFacade.actionObservers.changeTimeline.next(CHART_NAME_CITYAIR);

        this.sharedCoreFacade.getComparedList().forEach(async s => {
            if (s.type === 'city') {
                return;
            }

            const data = await this._loadStation(s.id, s.isOurStation);

            this.sharedCoreFacade.setMainChartLoadingFalse();

            if (data.packets && data.packets.length) {
                this.updateStationData(data, s.id);
            }
        });
    }

    loadObjMonitoringData = async (moObjID: number) => {
        let marker: Marker_model = this._findMarker(moObjID);

        if (!marker) {
            const mo = this.coreFacade.getMOs().find(mo => mo.id === moObjID);

            if (!mo) {
                return;
            }

            marker = {
                type: 'myMo',
                aqis: [],
                id: moObjID,
                lat: +mo.geoLatitude,
                lng: +mo.geoLongitude,
                name: mo.name,
                tzOffset: mo.tzOffset
            };
        }

        this.sharedCoreFacade.actionObservers.changeTimeline.next(CHART_NAME_CITYAIR);

        const index = this.sharedCoreFacade.findIndexInComparedList(moObjID);

        if (this.sharedCoreFacade.getComparedList().length > 1 && index !== -1) {
            return this.sharedCoreFacade.actionObservers.deleteCompareStation.next(index);
        }

        if (!this.sharedCoreFacade.getIsCompareMod()) {
            this.sharedCoreFacade.clearCompareList();
        }

        this.sharedCoreFacade.compareListAdd(new StationForMapPage_model(marker));

        const data = await this._loadStation(marker.id, marker.type === 'myMo');

        this.sharedCoreFacade.setMainChartLoadingFalse();
        this.updateStationData(data, marker.id);
    };

    centringMapOnMO = (moObjID: number) => {
        const marker = this._findMarker(moObjID);

        if (!marker) {
            return;
        }

        if (!marker.cityId || marker.cityId === this.sharedCoreFacade.getCurrentCityId()) {
            this.mapActions.centringOnMarker(marker.lat, marker.lng, true);
        } else {
            this.coreFacade.getCSTimelineInfo(
                ['selectCity'],
                {
                    cityId: marker.cityId,
                    setTime: this.time.getCurrent()
                }
            );
        }
    };

    openSelectCity = () => this.state.isOpenSelectCity = true;
    closeSelectCity = () => this.state.isOpenSelectCity = false;

    openChartControlPoint = (pointId: number, name: string, lat: number, lng: number) => {
        const mmt = this.groupFeaturesService.getConfig(GroupExtConfigName.plumeMeasure);
        const cfgMeasureScheme = this.groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme);

        const measuresInfo = {
            0: {
                type: mmt,
                name: TEXTS.NAMES[mmt] || mmt,
                unit: cfgMeasureScheme === measureScheme.c_mmhg_mg ? 'mg/m³' : 'µg/m³'
            }
        };

        const data = this.plumesFacadeService.getControlPointData(pointId);
        const timeBegin = this.time.getBegin();
        const timeEnd = this.time.getEnd();

        if (!data) {
            return;
        }

        const timestamps = Object.keys(data).map(Number).filter(time => time >= timeBegin && time <= timeEnd);

        const valuesChart: MeasuresForTime[] = timestamps.map(time => ({
            time,
            values: {
                0: data[time]
            }
        }));

        const originChartData = createOriginChartData(
            valuesChart,
            this.sharedCoreFacade.getTzOffset(),
            timeBegin,
            timeEnd,
            LOAD_STATION_SMALL_INTERVAL,
            measuresInfo
        );

        const fakeStation: StationForMapPage_model = {
            id: pointId,
            type: 'geoPoint',
            pubName: '',
            name,
            isOurStation: false,
            lat,
            lng,
            tzOffset: this.sharedCoreFacade.getTzOffset(),
            originChartData,
            measuresVal: {},
            lastPacketID: null,
        };

        this.sharedCoreFacade.clearCompareList();
        this.sharedCoreFacade.compareListAdd(fakeStation);

        this.sharedCoreFacade.actionObservers.comparedChart.next(CHART_NAME_CITYAIR);
    }
}
