import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginFormComponent } from './components/login-form/login-form.component';
import { PasswordResetFormComponent } from './components/password-reset-form/password-reset-form.component';
import { ResetCodeFormComponent } from './components/reset-code-form/reset-code-form.component';
import { NewPasswordFormComponent } from './components/new-password-form/new-password-form.component';

import { ResetCodeGuard } from './services/route-guards/reset-code.guard';
import { NewPasswordGuard } from './services/route-guards/new-password.guard';

export enum LoginPage {
    Login = 'login',
    PasswordReset = 'password_reset',
    ResetCode = 'reset_code',
    NewPassword = 'new_password',
}

export const loginRoutes: Routes = [
    {
        path: '',
        component: LoginFormComponent,
    },
    {
        path: LoginPage.PasswordReset,
        component: PasswordResetFormComponent,
    },
    {
        path: LoginPage.ResetCode,
        component: ResetCodeFormComponent,
        canActivate: [ResetCodeGuard],
    },
    {
        path: LoginPage.NewPassword,
        component: NewPasswordFormComponent,
        canActivate: [NewPasswordGuard],
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(loginRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class LoginRoutingModule {}
