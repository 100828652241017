<div class="subscription_card_content">
    <div class="subscription_card_content__title">{{textsNotification.notificationsFromEquipment}}</div>
    <div class="subscription_card_content__container subscription_card_content__container-service_notification">
        <div class="subscription_card_content__item subscription_card_content__item-service_notification">
            {{textsNotification.lackOfData}}
            {{subscription.serviceNoData}}
            {{subscription.serviceNoData | i18nPlural : textsNotification.timeIntervalHours}}
        </div>
        <div class="subscription_card_content__item subscription_card_content__item-service_notification">
            {{textsNotification.lackOfPower}}
            {{subscription.serviceNoPower}}
            {{subscription.serviceNoPower | i18nPlural : textsNotification.timeIntervalHours}}
        </div>
        <div class="subscription_card_content__item subscription_card_content__item-service_notification">
            {{textsNotification.verificationExpires}}
            {{subscription.serviceVerification}}
            {{subscription.serviceVerification | i18nPlural : textsNotification.timeIntervalDays}}
        </div>
    </div>

    <div class="subscription_card_content__title">{{textsNotification.recipients}}</div>
    <div class="subscription_card_content__container">
        <div class="subscription_card_content__item">
            {{getEmails() || textsNotification.emailsNotSpecified}}
        </div>
    </div>
</div>
