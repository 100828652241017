import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { namePages } from 'src/namespace';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { closeSidebar, openSidebar } from 'projects/cityscreen/src/modules/core/store/actions';
import { indoorModuleConfig } from 'projects/cityscreen/src/modules/indoor/module-config';
import { DefaultModuleHandler } from 'projects/cityscreen/src/components/admin-panel/default-module-handler';
import { defaultModuleConfig } from 'projects/cityscreen/src/components/admin-panel/default-module-config';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { PlumeHandler } from 'projects/cityscreen/src/modules/plumes/module-handler';
import { plumeModuleConfig } from 'projects/cityscreen/src/modules/plumes/module-config';
import {
    GroupExtConfigName,
    GroupFeaturesService
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {
    firstStart = true;

    private _pageChange$ = new BehaviorSubject<string>('');
    public pageChange$ = this._pageChange$.asObservable();

    constructor(
        private sharedCoreFacade: SharedCoreFacade,
        private groupFeaturesService: GroupFeaturesService,
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        router.events.pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(() => {
                if (this.firstStart) {
                    this.checkDefaultModule();
                    this.firstStart = false;
                }

                this.setModuleConfig();

                this.store.dispatch(this.getActivePage() ? openSidebar() : closeSidebar());

                this._pageChange$.next(this.getActivePage())
            });
    }

    private checkDefaultModule() {
        this.groupFeaturesService.ready$.pipe(take(1)).subscribe(() => {
            const startModule = this.groupFeaturesService.getConfig(GroupExtConfigName.startModule);

            if (startModule && !this.getActivePage())
                this.goToPage(startModule);
        })
    }

    // TODO возможно ModuleConfig нужно перенести в Store
    private setModuleConfig() {
        const page = this.getActivePage();

        switch (page) {
            case namePages.indoor:
                this.sharedCoreFacade.setModuleConfig(indoorModuleConfig);
                break;

            case namePages.plumes:
                this.setPlumeModuleConfig();
                break;

            case namePages.cityCard:
            case namePages.listUsers:
            case namePages.configPanel:
            case namePages.networks:
            case namePages.dashboard:
            case namePages.notifications:
            default:
                this.setDefaultModuleConfig();
                break;
        }
    }

    private setDefaultModuleConfig = () => {
        this.sharedCoreFacade.setModuleHandler(DefaultModuleHandler);
        this.sharedCoreFacade.setModuleConfig(defaultModuleConfig);
    }

    private setPlumeModuleConfig = () => {
        this.sharedCoreFacade.setModuleHandler(PlumeHandler);
        this.sharedCoreFacade.setModuleConfig(plumeModuleConfig);
    }


    public isActiveRoute(page: namePages) {
        return this.router.url.includes(page);
    }

    public getActivePage() {
        return this.router.url.split('/')[1]
    }

    public goToPage(page: namePages) {
        this.router.navigate([page], {
            relativeTo: this.activatedRoute
        });
    }
}
