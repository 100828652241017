import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { LanguageService, Languages, LangItem } from 'projects/shared/modules/core/services/language.service';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ca-language-selector',
    templateUrl: 'language-selector.component.html',
    styleUrls: ['language-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit {
    @Input() showFlags = true;

    @Output() selectLang = new EventEmitter<string>();

    isDropdownOpened = false;

    langItems$: Observable<LangItem[]>;

    lang: LangItem;

    constructor(private languageService: LanguageService) {}

    ngOnInit() {
        const langCode = this.languageService.getLanguage();
        this.lang = Languages.find(lang => lang.code === langCode);

        this.langItems$ = of(Languages).pipe(
            map(items => items.filter(it => it.code !== this.lang.code))
        );
    }

    setLang(langItem: LangItem) {
        if (langItem !== this.lang) {
            this.lang = langItem;
            this.selectLang.emit(langItem.code);
        }
    }
}
