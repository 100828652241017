<div *ngIf="cbBack" (click)="cbBack()" class="header_page__back">
    <div class="header_page__back--inner">{{textObject.btnBack}}</div>
</div>
<div class="header_page">
    <div class="header_page__name">{{textObject.titlePage}}</div>
    <div class="header_page__btn_open_page"
         *ngIf="cbOpenPage"
         (click)="cbOpenPage()"
         [innerHTML]="textObject.btnOpenPage"
    ></div>
</div>