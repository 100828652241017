import {
    Component,
    OnInit,
    Input
} from '@angular/core';

import { Marker_model } from 'src/namespace';
import { ALERT_COLORS, MARKER_HOVER_COLORS } from 'src/config';

import { MapProvidersService } from '../../services/map-providers.service';

const MARKER_HEIGHT = 52.3;

@Component({
    selector: 'marker-icon',
    templateUrl: 'marker-icon.component.html',
    styleUrls: ['marker-icon.component.less']
})
export class MarkerIconComponent implements OnInit {
    @Input() marker: Marker_model;
    @Input() selected: boolean;
    @Input() canAdd: boolean;
    @Input() canNotify: boolean;

    markerHeight = MARKER_HEIGHT;

    isTypeOpenAQ = false;

    iconShift: {
        x: number;
        y: number;
    };

    constructor(private mapProvidersService: MapProvidersService) {}

    ngOnInit() {
        const { type } = this.marker;
        this.isTypeOpenAQ = type === 'OpenAQ';
        this.iconShift = this.mapProvidersService.getMarkerShift();
    }

    getWidth() {
        let width = 40.9;

        if (this.selected) {
            width *= 1.2;
        }

        if (this.isTypeOpenAQ) {
            width *= 0.88;
        }

        return width;
    }

    getHeight() {
        let height = this.markerHeight;

        if (this.selected) {
            height *= 1.2;
        }

        if (this.isTypeOpenAQ) {
            height *= 0.88;
        }

        return height;
    }

    getTextValue() {
        let value = `${this.marker.aqi}` || '?';

        if (this.canAdd && !this.selected) {
            value = '+';
        }

        return value;
    }

    getTextTransform() {
        const coords = this.isTypeOpenAQ
            ? ['22 35', '15 35']
            : ['15.8591 23.048', '11.5 23.048'];

        const c = this.canAdd && !this.selected;
        const position = (c || this.marker.aqi !== 10) ? coords[0] : coords[1];
        return `matrix(1 0 0 1 ${position})`;
    }

    getMainColor() {
        const { aqi } = this.marker;
        return this.marker.over ? MARKER_HOVER_COLORS[aqi] : ALERT_COLORS[aqi];
    }

    getTextColor() {
        const { aqi } = this.marker;
        return this.selected ? '#fff' : ALERT_COLORS[aqi];
    }

    getTextSize() {
        return this.isTypeOpenAQ ? 21 : 14;
    }

    getCircleColor() {
        return this.selected ? this.getMainColor() : '#fff';
    }

    getMarkerShiftTop() {
        return this.iconShift.y - this.getHeight();
    }

    getMarkerShiftLeft() {
        return this.iconShift.x - this.getWidth() / 2;
    }
}
