import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input.component';
import { CrossButtonModule } from '../../buttons/cross-button/cross-button.module';
import { SearchInputBasicModule } from '../search-input-basic/search-input-basic.module';

@NgModule({
    exports: [SearchInputComponent],
    declarations: [SearchInputComponent],
    imports: [
        CommonModule,
        CrossButtonModule,
        SearchInputBasicModule,
    ]
})
export class SearchInputModule {
}
