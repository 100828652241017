import { Component, Input, OnChanges } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { RunPlume } from 'projects/cityscreen/src/modules/plumes/services/models/run-model';

@Component({
    selector: 'calculation-result-selector',
    templateUrl: 'calculation-result-selector.component.html',
    styleUrls: ['calculation-result-selector.component.less']
})
export class CalculationResultSelectorComponent {
    @Input() run: RunPlume;

    TEXTS = TEXTS;
}
