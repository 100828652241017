<div class="input-dropdown">
    <input-for-dropdown
        [isOpenDropdown]="showDropdown"
        (isOpenDropdownChange)="toggleDropdown()"
        [inputValue]="inputText"
        [label]="label"
        [isDisable]="isDisable"
        [isError]="textError != ''"
    ></input-for-dropdown>

    <div [ngClass]="{
                'input-dropdown__description': true,
                'input-dropdown__description-error': textError}"
    >{{textError}}</div>
    <div [ngClass]="{
                'input-dropdown__description': true,
                'input-dropdown__description-info': textDescription &&  !textError}">{{textDescription}}</div>

    <div class="input-dropdown__dropdown"
        *ngIf="showDropdown"
        [@inOutAnimation]
    >
        <ng-content></ng-content>
    </div>
</div>
