import { Component, Input } from '@angular/core';

export type DropdownState = 'opened' | 'closed';

@Component({
    selector: 'ca-dropdown-button',
    templateUrl: 'dropdown-button.component.html',
    styleUrls: ['dropdown-button.component.less']
})
export class DropdownButtonComponent {
    @Input() text: string;
    @Input() type: DropdownState = 'closed';
    @Input() disabled?: boolean;
}
