import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { AdminDevice, City_model, IntervalType, MeasuresInfoFormat } from 'src/namespace';

import { TEXTS } from 'src/texts/texts';

import './style.less';

@Component({
    selector: 'select-measures',
    template: `
        <div *ngFor="let measure of measuresList"
            class="select_measures {{className || ''}}"
            [class.select_measures--multiline]="isMultiline(measure.name)"
        >
            <span
                *ngIf="measuresList.length > 1"
                [ngClass]="{
                    'select_measures__input': true,
                    'select_measures__input-select': isUsed(measure.name)
                }"
                title="{{TEXTS.STATION_BLOCK.add}}"
                (click)="select(measure, true)">
            </span>
            <span [ngClass]="{
                    'select_measures__text ellipsis': true,
                    'select_measures__text-select': isUsed(measure.name)
                }"
                [innerHTML]="NAMES[measure.name] || measure.name"
                title="{{TEXTS.STATION_BLOCK.select}}"
                (click)="select(measure, false)"
            >
            </span>
        </div>
    `
})
export class SelectMeasures {
    @Input() className: string;
    @Input() measuresSelected: MeasuresInfoFormat[];
    @Input() measuresList: MeasuresInfoFormat[];
    @Input() select: (measure: MeasuresInfoFormat, add: boolean) => void;

    TEXTS = TEXTS;
    NAMES = TEXTS.NAMES;

    isUsed(name: string) {
        return this.measuresSelected.find(a => a.name === name);
    }

    isMultiline(name: string) {
        return (TEXTS.NAMES[name] || name).split(' ').length > 1;
    }
}

@Component({
    selector: 'select-measures-radio',
    template: `
        <div *ngFor="let measure of measuresList" class="select_measures_radio"
            [class.select_measures_radio--multiline]="isMultiline(measure.name)"
        >
            <div class="select_measures_radio_item"
                [class.select_measures_radio_item-select]="isUsed(measure.name)"
                [innerHTML]="NAMES[measure.name] || measure.name"
                (click)="select(measure)"
            ></div>
        </div>
    `
})
export class SelectMeasuresRadio {
    @Input() measuresSelected: MeasuresInfoFormat[];
    @Input() measuresList: MeasuresInfoFormat[];
    @Input() select: (measure: MeasuresInfoFormat) => void;

    TEXTS = TEXTS;
    NAMES = TEXTS.NAMES;

    isUsed(name: string) {
        return this.measuresSelected.find(a => a.name === name);
    }

    isMultiline(name: string) {
        return (TEXTS.NAMES[name] || name).split(' ').length > 1;
    }
}


@Component({
    selector: 'select-measures-m',
    template: `
        <div *ngFor="let measure of measuresList"
             class="select_measures {{className || ''}}"
        >
            <div (click)="select(measure, false)"
                 class="select_measures select_measures-m {{isUsed(measure.name) ? 'select_measures-select' : ''}}">
                <div
                    class="select_measures__text-m ellipsis {{isUsed(measure.name) ? 'select_measures__text-m-select' : ''}}"
                    [innerHTML]="NAMES[measure.name] || measure.name">
                </div>
                <div class="select_measures__type {{isUsed(measure.name) ? 'select_measures__type-select' : ''}}"
                     [innerHTML]="TEXTS.UNITS[measure.unit] || measure.unit"></div>
            </div>
        </div>
    `
})
export class SelectMeasures_m extends SelectMeasures {}

@Component({
    selector: 'online-circle',
    template: `
        <div class="online_circle__wrapper">
            <div [ngClass]="{
                    'online_circle': true,
                    'online_circle-offline': !isOnline,
                    'online_circle-online':  isOnline
                }"
            >
            </div>
            <div class="online_circle__text">{{isOnline ? TEXTS.LIST_DEVICES.online : TEXTS.LIST_DEVICES.offline}}</div>
        </div>
    `
})
export class OnlineCircle {
    @Input() isOnline: boolean;

    TEXTS = TEXTS;
}

@Component({
    selector: 'links-mo',
    template: `
        <div class="list_devices__block_title-mo">
            <div *ngIf="!station.linksToMo.length" class="list_devices__block_title-mo_block">
                <div class="list_devices__block_title-not_conect">{{text.notConnected}}</div>
            </div>
            <div *ngFor="let mo of station.linksToMo"
                 class="list_devices__block_title-mo_block"
                 (click)="click(mo.id)"
            >
                <div  class="list_devices__block_title-mo_name ellipsis">{{mo.name}}</div>
            </div>
        </div>
    `
})
export class LinksMo {

    @Input() station: AdminDevice;
    @Input() partOf: string;
    @Output() clickForMo: EventEmitter<any> = new EventEmitter();

    text = TEXTS.LIST_DEVICES;

    click(id: number) {
        this.clickForMo.emit(id);
    }
}
