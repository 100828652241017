import { UpdatesProps } from '../../../../../src/namespace';
import { parseUrl } from '../../../../../src/utils';

export function checkInviteLink(): { email: string, inviteCode: string } | null {
    const saved = parseUrl();

    const props: UpdatesProps = {};

    if (saved.action === 'invite') {
        props.email = saved.email;
        props.inviteCode = saved.hash;

        return {
            email: saved.email,
            inviteCode: saved.hash
        };
    }

    return null;
}
