<div class="timeline_selected_item">
    <div class="timeline_selected_item__title_wrapper">
        <div class="ellipsis timeline_selected_item__title">
            {{name}}
        </div>
        <ng-container *ngIf="item.type !== 'city'; else cityDescription">
            <div class="timeline_selected_item__description ellipsis">
                {{description}}
            </div>
        </ng-container>
        <ng-template #cityDescription>
            <div class="timeline_selected_item__description ellipsis timeline_selected_item__description-link button_link-underscore"
                (click)="titleClick()"
            >
                {{TEXTS.CITY_CARD.goOver}}
            </div>
        </ng-template>
    </div>
    <div *ngIf="lineNumber !== null"
        class="timeline_selected_item__line timeline_selected_item__line-{{lineNumber}}"
    ></div>
</div>
