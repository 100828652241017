<div class="chart-wrapper" *ngIf="chartOptions">
    <cs-analytic-chart-title
        [title]="chartOptions.title"
        [tooltip1]="chartOptions.tooltip"
        [tooltip2]="chartOptions.description"
    ></cs-analytic-chart-title>

    <div class="chart-content" *ngIf="chartOptions">
        <canvas
            baseChart
            [colors]="chartOptions.chart.colors"
            [datasets]="chartOptions.chart.data"
            [labels]="chartOptions.chart.labels"
            [options]="chartOptions.chart.options"
            [plugins]="chartOptions.chart.plugins"
            [chartType]="'bar'"
        >
        </canvas>
    </div>
</div>
