import {
    Component,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    AfterViewInit,
    ViewChildren,
    ElementRef,
    QueryList
} from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

import { TabModel } from 'src/namespace';

@Component({
    selector: 'ca-page-tabs',
    templateUrl: 'page-tabs.component.html',
    styleUrls: ['page-tabs.component.less']
})
export class PageTabs implements AfterViewInit {
    @Input() tabs: TabModel[];
    @Input() selectedTab: TabModel;
    @Input() isFullWidth = false;
    @Input() indexSelectElement = 1;
    @Output() showTab = new EventEmitter<TabModel>();

    @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
    @ViewChildren('pageTabsTab', {read: ElementRef}) pageTabs: QueryList<ElementRef>;

    isShowBtnArrows = false;
    startSelectedElement = 0;

    selectTab(tab: TabModel) {
        this.showTab.emit(tab);
    }

    moveToTabs(indexEl: number) {
        setTimeout(() => {
            this.ds.moveTo(indexEl);
        }, 0);
    }

    getTabIndex(pre: number) {
        this.startSelectedElement += pre
        if (this.startSelectedElement <= 0) {
            this.startSelectedElement = 0;
        } else if (this.startSelectedElement >= (this.tabs.length - 1)) {
            this.startSelectedElement = (this.tabs.length - 1);
        }
        this.selectTab(this.tabs[this.startSelectedElement])
        this.moveToTabs(this.startSelectedElement)
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const width = this.pageTabs.reduce((sum, pt) => {
                return sum + pt.nativeElement.offsetWidth
            }, 0)
            if (width > 456) {
                this.isShowBtnArrows = true;
                this.ds.wrapper.style.width = '376px';
            }
            this.startSelectedElement = this.indexSelectElement;
            this.moveToTabs(this.indexSelectElement)
        }, 0);
    }
}
