import { CO2, HUM, PM10, PM2, PRES, TEMP } from 'src/namespace';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

export const orderOfMeasures = [CO2, TEMP, HUM, PRES, PM2, PM10];

export interface BarChartData {
    title: string;
    tooltip: string;
    description: string;
    chart?: AllChartOptions;
}

export interface AllChartOptions {
    options?: ChartOptions;
    labels?: Label[];
    type?: ChartType;
    legend?: boolean;
    colors?: Color[];
    data?: ChartDataSets[];
    plugins?: any[];
}

export enum CHART_BAR_NAME {
    allHistory = 'AllHistory',
    dayHour = 'DayHour',
    weekDay = 'WeekDay'
}
