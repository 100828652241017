import { createReducer, on } from '@ngrx/store';
import * as commonActions from './actions';
import { AdminDeviceStandalone, City_model, Marker_model, StationForMapPage_model } from 'src/namespace';
import { CityairMapDataTransformerResponse } from 'src/api/mapProvider/cityairMapDataTransformerResponse';
import { Group } from 'src/api/adminPanel/models';
import { GroupInfo, NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { DevicesDataTransformer_model } from 'src/api/DevicesApi/dataTransformer';
import { PacketsValueTypeItem } from 'harvester/UiAdminProject8/src/commonData/models';

export interface MapState {
    loaded: boolean;
    markers: Marker_model[],
    citiesMarkers: Marker_model[],
}

export interface IUserInteractions {
    selectedItems: StationForMapPage_model[],
}

export interface ICoreState {
    map: MapState,
    // time: Time,
    isTimelineLoading: boolean,
    isSidebarOpen: boolean;
    selectedMoId: number;
    selectedDeviceSerialNumber: string
    // interactions: IUserInteractions,
    collections: ICollections,
}

export interface ICollections {
    cities: City_model[],
    groups: Group[],
    groupInfo: GroupInfo,
    devices: { [key in string]: AdminDeviceStandalone },
    packetsValueTypes: PacketsValueTypeItem[]
}

// interface Time {
//     currentTime: number;
//     begin: number;
//     end: number;
//     isFixed: boolean;
// };

export const initialState: ICoreState = {
    isTimelineLoading: false,
    map: {
        loaded: false,
        markers: [],
        citiesMarkers: [],
    },

    isSidebarOpen: false,
    selectedMoId: null,
    selectedDeviceSerialNumber: null,

    collections: {
        cities: [],
        groups: [],
        groupInfo: null,
        // groupInfo: {
        //     monitoringObjects: [],
        //     myDevices: [],
        //     allDevices: [],
        //     notificationSubscriptions: [],
        //     users: [],
        //     usersWithoutAdmin: [],
        //     iAm: null,
        //     roles: null,
        //     myRole: null,
        //     regionalCoefs: null,
        //     groupLocationsIds: [],
        //     allowForecast: null,
        //     allowPlumes: null,
        //     allowIndoor: null,
        //     extConfig: null,
        //     groupId: null
        // },
        devices: {},
        packetsValueTypes: null
    },
};

const _commonReducer = createReducer(
    initialState,

    on(
        commonActions.groupListLoaded, (state: ICoreState, data) => {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    groups: [...data.groups].sort((a, b) => {
                        return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
                    }),
                    packetsValueTypes: data.packetValueTypes
                }
            };
        }
    ),

    on(
        commonActions.groupInfoLoaded, (state: ICoreState, data: { groupInfo: GroupInfo }) => {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    groupInfo: data.groupInfo
                }
            };
        }
    ),

    on(
        commonActions.timelineInfoLoad, (state: ICoreState) => {
            return {
                ...state,
                isTimelineLoading: true,
            };
        }
    ),

    on(
        commonActions.timelineInfoLoaded, (state: ICoreState, {
            citiesForAdminPanel,
            citiesMarkers,
            myMarkers
        }: CityairMapDataTransformerResponse) => {
            return {
                ...state,
                isTimelineLoading: false,
                collections: {
                    ...state.collections,
                    cities: citiesForAdminPanel
                },
                map: {
                    ...state.map,
                    markers: myMarkers,
                    citiesMarkers
                }
            };
        }
    ),

    on(
        commonActions.deviceInfoLoaded, (state: ICoreState, data: DevicesDataTransformer_model) => {
            const devices = {};
            data.devices.forEach(d => devices[d.serialNumber] = d);

            return {
                ...state,
                collections: {
                    ...state.collections,
                    devices: {
                        ...state.collections.devices,
                        ...devices
                    }
                }
            };
        }
    ),

    on(
        commonActions.selectMo, (state: ICoreState, data: { id: number }) => {
            return {
                ...state,
                selectedMoId: data.id,
            };
        }
    ),

    on(
        commonActions.selectDevice, (state: ICoreState, data: { serialNumber: string }) => {
            return {
                ...state,
                selectedDeviceSerialNumber: data.serialNumber,
            };
        }
    ),

    on(
        commonActions.modifyNotification, (state: ICoreState, data: { id: number, props: Partial<NotificationSubscription> }) => {
            const newSubscriptions = state.collections.groupInfo.notificationSubscriptions.map(sub => {
                if (sub.id === data.id) {
                    // copy class instance. Object.create needed for disable protected props in sub
                    return Object.assign(Object.create(Object.getPrototypeOf(sub)), sub, data.props)
                } else {
                    return sub;
                }
            });

            return {
                ...state,
                collections: {
                    ...state.collections,
                    groupInfo: {
                        ...state.collections.groupInfo,
                        notificationSubscriptions: newSubscriptions
                    }
                }
            };
        }
    ),

    on(commonActions.mapLoaded, (state: ICoreState) => ({
        ...state,
        map: {
            ...state.map,
            loaded: true
        }
    })),


    on(
        commonActions.openSidebar, (state: ICoreState) => {
            return {
                ...state,
                isSidebarOpen: true,
            };
        }
    ),

    on(
        commonActions.closeSidebar, (state: ICoreState) => {
            console.log('closeSidebar');
            return {
                ...state,
                isSidebarOpen: false,
            };
        }
    ),

    on(
        commonActions.toggleSidebar, (state: ICoreState) => {
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            };
        }
    ),
);

export function commonReducers(state, action) {
    return _commonReducer(state, action);
}
