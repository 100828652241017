import { Component } from '@angular/core';
import { measureZones, ALERT_COLORS } from 'src/config';
import { PM10, PM2 } from 'src/namespace';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { map } from 'rxjs/operators';
import { findPointFor } from 'src/utils';
import { TEXTS } from 'src/texts/texts';

@Component({
  selector: 'cityscreen-aqi-legend-mobile',
  templateUrl: './aqi-legend-mobile.component.html',
  styleUrls: ['./aqi-legend-mobile.component.less']
})
export class AqiLegendMobileComponent {
    TEXTS = TEXTS;
    ALERT_COLORS = ALERT_COLORS;
    TEXT = TEXTS.COMMON;

    arr = [0, 1, 2];
    pm2 = [0, ...measureZones.get(PM2)];
    pm10 = [0, ...measureZones.get(PM10)];

    showLegend = false;

    constructor(private time: Time, public readonly sharedCoreFacade: SharedCoreFacade) {}

    avgAqi$ = this.time.timeUpdated.pipe(map(data => {
        const aqiHistory = this.sharedCoreFacade.getAqiHistory();
        const originalIndex = findPointFor(aqiHistory, data.time, 'time');

        const hour = aqiHistory[originalIndex];
        return hour ? hour.aqi || 1 : 1;
    }));


    close = () => this.showLegend = false;

    open = () => this.showLegend = true;

    findSelectAqi = (i: number) => {
        if (i >= 1 && i <= 3)
            return this.arr[0];
        if (i >= 4 && i <= 7)
            return this.arr[1];
        if (i >= 8 && i <= 10)
            return this.arr[2];
    }

}
