<ng-template #popupOutlet>
    <ca-popup-container
        *ngIf="showConfirmationPopup"
        [title]="textsPopup.title"
        (closeAction)="onClosePopup()"
    >
        <confirm-unsaved-popup
            [text]="TEXTS.POPUP_THREE_QUESTIONS.body"
            [continueText]="TEXTS.COMMON.doNotSave"
            [cancelText]="TEXTS.POPUP_THREE_QUESTIONS.cancel"
            (continueAction)="onCancelAndNavigation()"
            (cancelAction)="onClosePopup()"
        ></confirm-unsaved-popup>
    </ca-popup-container>
</ng-template>

<div class="settings_block scroll">

<form (ngSubmit)="onSubmit()" [formGroup]="settingsForm" novalidate>
    <div class="settings_input_forms scroll">
        <div class="settings_header">
            <div class="settings_header-wrapper">
                <header-page
                    [cbBack]="cancel"
                    [textObject]="{
                        btnBack: textsNotification.back,
                        titlePage: subscription.id === 0 ? textsNotification.newSubscription : textsNotification.editSubscription
                    }"
                ></header-page>
            </div>
        </div>

        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.titleSubscription}}
                </div>
                <input class="input_name" formControlName="title" type="text" minlength="1" maxlength="255" autocomplete="off" required
                    [ngClass]="{
                        'alert-error': title.touched && title.invalid
                    }"
                />
                <div [ngClass]="{
                        'alert_error': true,
                        'alert_error-active': title.touched && title.invalid
                    }"
                >
                    <ng-container *ngIf="title.touched && title.invalid">
                        <div *ngIf="title.errors.required || title.errors.minlength">
                            {{textsNotification.errMsgTitleRequired}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="settings_subtitle">
            <div class="settings_subtitle__name">{{textsNotification.notificationsFromEquipment}}</div>
        </div>

        <div class="setting_input setting_wrapper setting_input-service" formGroupName="serviceSettings">
            <div class="setting_input__str setting_input__str-service">
                <div class="setting_input__title setting_input__title-service">{{textsNotification.lackOfData}}</div>
                <input class="input_name setting_input__input_text-service"
                    formControlName="nodata" type="number" min="1" required
                    [ngClass]="{
                        'alert-error': nodata.invalid
                    }"
                />
                <div class="setting_input__title">
                    {{(nodata.value || 0) | i18nPlural : textsNotification.timeIntervalHours}}
                </div>
            </div>
            <div [ngClass]="{
                'alert_error': true,
                'alert_error-relative': true,
                'alert_error-active': nodata.invalid
            }"
            >
                <ng-container *ngIf="nodata.invalid">
                    <div *ngIf="nodata.errors.required || nodata.errors.min">
                        {{textsNotification.errMsgValueMustBeGreaterThanZero}}
                    </div>
                    <div *ngIf="!(nodata.errors.required || nodata.errors.min) && nodata.errors.pattern">
                        {{textsNotification.errMsgValueMustBeIntegerNumber}}
                    </div>
                </ng-container>
            </div>

            <div class="setting_input__str setting_input__str-service">
                <div class="setting_input__title setting_input__title-service">{{textsNotification.lackOfPower}}</div>
                <input class="input_name setting_input__input_text-service"
                    formControlName="nopower" type="number" min="1" required
                    [ngClass]="{
                        'alert-error': nopower.invalid
                    }"
                />
                <div class="setting_input__title">
                    {{(nopower.value || 0) | i18nPlural : textsNotification.timeIntervalHours}}
                </div>
            </div>

            <div [ngClass]="{
                'alert_error': true,
                'alert_error-relative': true,
                'alert_error-active': nopower.invalid
            }"
            >
                <ng-container *ngIf="nopower.invalid">
                    <div *ngIf="nopower.errors.required || nopower.errors.min">
                        {{textsNotification.errMsgValueMustBeGreaterThanZero}}
                    </div>
                    <div *ngIf="!(nopower.errors.required || nopower.errors.min) && nopower.errors.pattern">
                        {{textsNotification.errMsgValueMustBeIntegerNumber}}
                    </div>
                </ng-container>
            </div>

            <div class="setting_input__str setting_input__str-service">
                <div class="setting_input__title setting_input__title-service">{{textsNotification.verificationExpires}}</div>
                <input class="input_name setting_input__input_text-service"
                    formControlName="verification" type="number" min="1" required
                    [ngClass]="{
                        'alert-error': verification.invalid
                    }"
                />
                <div class="setting_input__title">
                    {{(verification.value || 0) | i18nPlural : textsNotification.timeIntervalDays}}
                </div>
            </div>

            <div [ngClass]="{
                    'alert_error': true,
                    'alert_error-relative': true,
                    'alert_error-active': verification.invalid
                }"
            >
                <ng-container *ngIf="verification.invalid">
                    <div *ngIf="verification.errors.required || verification.errors.min">
                        {{textsNotification.errMsgValueMustBeGreaterThanZero}}
                    </div>
                    <div *ngIf="!(verification.errors.required || verification.errors.min) && verification.errors.pattern">
                        {{textsNotification.errMsgValueMustBeIntegerNumber}}
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="settings_subtitle">
            <div class="settings_subtitle__name">
                {{textsNotification.recipients}}
            </div>
        </div>
        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.emailListInputLabel}}
                </div>
                <input class="input_name" type="text" minlength="1" autocomplete="email"
                    formControlName="emailsList"
                    [ngClass]="{
                        'alert-error': emailsListProxy.invalid
                    }"
                />
                <div [formGroup]="emailsListProxy" style="display: none;">
                    <input type="email" email
                        *ngFor="let item of emailsListData"
                        [formControlName]="item.name"
                        [value]="item.value"
                    />
                </div>
                <div [ngClass]="{
                        'alert_error': true,
                        'alert_error-active': emailsListProxy.invalid
                    }"
                >
                    <ng-container *ngIf="emailsListProxy.invalid">
                        {{textsNotification.errMsgIncorrectEmails}}
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="settings_subtitle">
            <div class="settings_subtitle__name">
                {{textsNotification.dailyReport}}
            </div>
            <div class="settings_subtitle__icon"
                caTooltip
                [caTooltipTemplate]="emailTooltip"
            ></div>
            <ng-template #emailTooltip>
                <ca-info-tooltip
                    [text]="textsNotification.tooltipDeviceDailyReport"
                ></ca-info-tooltip>
            </ng-template>
        </div>
        <div class="settings__section">
            <div class="settings__input-wrapper settings__input-wrapper--text">
                <div class="settings__input-label settings__input-label--text">
                    {{textsNotification.emailListInputLabel}}
                </div>
                <input class="input_name" type="text" minlength="1" autocomplete="email"
                    formControlName="emailsListReports"
                    [ngClass]="{
                        'alert-error': emailsListReportsProxy.invalid
                    }"
                />
                <div [formGroup]="emailsListReportsProxy" style="display: none;">
                    <input type="email" email
                        *ngFor="let item of emailsListReportsData"
                        [formControlName]="item.name"
                        [value]="item.value"
                    />
                </div>
                <div [ngClass]="{
                        'alert_error': true,
                        'alert_error-active': emailsListReportsProxy.invalid
                    }"
                >
                    <div *ngIf="emailsListReportsProxy.invalid">
                        {{textsNotification.errMsgIncorrectEmails}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="settings_footer">
        <div class="action_button__wrapper">
            <ca-button type="cancel"
                (clickAction)="cancel()"
            >
                {{textsNotification.cancel}}
            </ca-button>
            <ca-button type="primary"
                (clickAction)="save()"
                [disabled]="settingsForm.disabled || settingsForm.invalid || !formDataHasChanged"
            >
                <ca-spinner *ngIf="saving; else showContent"></ca-spinner>
                <ng-template #showContent>
                    {{textsNotification.save}}
                </ng-template>
            </ca-button>
        </div>
    </div>
</form>

</div>
