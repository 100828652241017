import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from 'src/texts/texts';
import { AdminDevice, ArrTagDevice, MonitoringObject } from 'src/namespace';

@Component({
    selector: 'device-card',
    templateUrl: './device-card.component.html',
    styleUrls: ['./device-card.component.less']
})
export class DeviceCardComponent {
    @Input() serialNumber = '';
    @Input() isOnline = false;
    @Input() isBattery = false;
    @Input() isNetworks = false;
    @Input() tagsDevice: AdminDevice[] = [];
    @Input() tagsMo: MonitoringObject[] = [];

    @Output() cbClickForStaticTags = new EventEmitter<{ type: string, id: any }>();
    @Output() openActionsPopup = new EventEmitter<{ target: HTMLElement, positionTop: number }>();

    TEXTS = TEXTS;

    arrTagMo: ArrTagDevice[] = [];

    clickForStaticTags(type: string, id: any) {
        this.cbClickForStaticTags.emit({type: type, id: id})
    }

    openActions({target, positionTop}) {
        this.openActionsPopup.emit({
            target,
            positionTop
        });
    }

    getTextOfPower() {
        return this.isNetworks ? this.TEXTS.NOTIFICATIONS.alertTag.onNetworks : (this.isBattery ? this.TEXTS.NOTIFICATIONS.alertTag.onBattery : this.TEXTS.NOTIFICATIONS.alertTag.thereIsNoData)
    }

    getTypeOfPower() {
        return this.isNetworks ? 'green' : (this.isBattery ? 'yellow' : 'gray');
    }
}
