<div class="selectbox">
    <search-input
        [textPlaceholder]="searchPlaceholderText"
        [(text)]="searchText"
        [debounceTime]="400"
    ></search-input>
    <div class="selectbox__list scroll" [formGroup]="form">
        <div class="selectbox__list-item selectbox__list-item--all"
            *ngIf="selectAllText"
        >
            <label>
                <cs-checkbox [checked]="allItems.value"></cs-checkbox>
                <input type="checkbox" formControlName="allItems" />
                <span class="selectbox__list-item__label">{{selectAllText}}</span>
            </label>
        </div>
        <div class="selectbox__list-item" [formGroup]="items"
            *ngFor="let item of listItems | multipleSearchfilter : ['label'] : searchText"
        >
            <label>
                <cs-checkbox [checked]="items.value[item.id]"></cs-checkbox>
                <input type="checkbox" [formControlName]="item.id" />
                <span class="selectbox__list-item__label">{{item.label}}</span>
            </label>
        </div>
    </div>
    <div class="selectbox-actions" *ngIf="resetText">
        <text-button size="small"
            [text]="resetText"
            (clickAction)="resetFilter()"
        ></text-button>
    </div>
</div>
