<div class="packets-monitor-timeline-scale">
    <canvas [attr.width]="ticks * intervalWidth" height="6"
        baseChart
        chartType="line"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [legend]="false"
    ></canvas>

    <div class="packets-monitor-timeline-scale__axis"></div>
    <div class="packets-monitor-timeline-scale__ticks">
        <div class="packets-monitor-timeline-scale__tick"
            [style.width.px]="intervalWidth"
            *ngFor="let tickItem of formattedDatesList"
            [class.packets-monitor-timeline-scale__tick--major]="tickItem.isMajor"
        >
            {{tickItem.date}}
        </div>
    </div>
</div>
