import * as moment from 'moment';

import {
    AdminDevice,
    AQI,
    CH2O,
    CHART_NAME_AIR_VOICE,
    CHART_NAME_MONITOR,
    CHART_NAME_MONITOR_AQI,
    CHART_TYPE_AREA_MONITOR,
    CHART_TYPE_BIG,
    CHART_TYPE_FULL_WIDTH,
    CHART_TYPE_FULL_WIDTH_M,
    CHART_TYPE_TIMELINE,
    CHART_TYPE_TIMELINE_M,
    CHART_TYPE_WIDGET,
    City_model,
    CO,
    CO2,
    DeviceStatus,
    H2S,
    HUM,
    IntervalType,
    MarkerType,
    measureScheme,
    NH3,
    NO,
    NO2,
    O3,
    PM10,
    PM2,
    PRES,
    SO2,
    SUM,
    TEMP,
    WindowGlobalVars
} from './namespace';

import { copyObj } from './utils';
import { TEXTS } from './texts/texts';
import { detectMobile } from 'projects/shared/utils/other-utils';


declare let window: WindowGlobalVars;

export const MIN_PASSWORD_LENGTH = 7;

export const CITYAIR_DEVICE_ID = 3;
export const G1_DEVICE_ID = 21;
export const G2_DEVICE_ID = 22;

export const NO_ACCESS_ID = 0;
export const ADMIN_ID = 1;
export const OPERATOR_ID = 2;
export const OBSERVER_ID = 3;
export const SERVICE_ID = 4;

export const RESIZE_TIMEOUT = 200;

export const MAIN_CHART_HEIGHT = 150;
export const MAIN_CHART_HEIGHT_M = 95;

export const DEFAULT_SELECT_MEASURE = AQI;

export const STND_CITY_ZOOM = 8;
export const CITY_ZOOM_SHOW_HEXAGON = 10;
export const STND_CITY_MAX_ZOOM = 16;
export const STND_GLOBAL_MIN_ZOOM = 1.5;
export const GLOBAL_ZOOM_LEVEL = 8;

export const MO_AREA_RADIUS_IN_METERS = 500;
export const MARKER_AREA_OPACITY = 0.3;

export const EMPTY_CITY: City_model = {
    id: null,
    locationId: null,
    name: '',
    countryName: '',
    lat: undefined,
    lng: undefined,
    tzOffset: moment().utcOffset(),
    msOffset: moment().utcOffset() * 60000,
    bounds: null,
    aqiHistory: null,
    originChartData: null,
    distributionSummary: null,
    zIndex: null,
    isSmallCity: null,
    lastData: {}
};

export const COLOR_BLUE = '#8fcbf9';
export const MARKER_HOVER_COLORS = ['#b2b3b3', '#85a818', '#97b518', '#b2b218', '#efb930', '#ce7e36', '#d85a41', '#d6434b', '#c1384f', '#932a50', '#681b4d'];
export const ALERT_COLORS = ['#B8BFCC', '#8CB917', '#A2C617', '#BEC617', '#FFCC33', '#FFA33B', '#FF7344', '#FF494B', '#D63B50', '#AD2D55', '#821E5A'];
export const CHART_LINE_COLORS = ['#8CB917', '#8CB917', '#A2C617', '#BEC617', '#FFCC33', '#FFA33B', '#FF7344', '#FF494B', '#D63B50', '#AD2D55', '#821E5A'];
export const TIMELINE_COLOR = ['#dddddd', '#dddddd', '#dddddd', '#dddddd', '#FFA33B', '#FFA33B', '#ffa33b', '#D63B50', '#D63B50', '#d63b50', '#d63b50'];
export const CHART_COLORS = ['#8fcbf9', '#ff9d8b', '#bfa3d8', '#85dfa3', '#ffcd8b'];
export const CHART_TIMEOUT_BEFORE_RENDER = 50;
export const WIDTH_ADMIN_PANEL = {
    fat: 506,
    thin: 456
};

export enum MAP_PROVIDERS {
    // GoogleMaps = 'google-maps',
    MapBox = 'mapbox-gl'
};

export function markerOpacity(hours: number, lowDetail: boolean): number {
    if (lowDetail) {
        if (hours >= 48)
            return 0;
        if (hours >= 24)
            return 0.5;

        return 1;
    } else {
        if (hours >= 24)
            return 0;
        if (hours >= 12)
            return 0.3;
        if (hours >= 6)
            return 0.5;
        if (hours >= 2)
            return 0.7;

        return 1;
    }
}

export function markerDescription(hours: number): string {
    if (hours > 1)
        return TEXTS.LAST_DATA_AGO[0] + Math.round(hours) + TEXTS.LAST_DATA_AGO[1]
    return '';
}

export function getOmStatus(arrObjDevice: AdminDevice[]): { text: string, paramPoint: number } {
    let paramPoint = DeviceStatus.OFFLINE;
    let text = TEXTS.LIST_OM.notWorked;
    // TODO: specify requirements

    if (arrObjDevice) {

        if (arrObjDevice.find(d => d.offline === false)) {
            paramPoint = DeviceStatus.ONLINE;
            text = TEXTS.LIST_OM.worked;
        }

        if (arrObjDevice.find(d => d.v220 === false)) {
            paramPoint = DeviceStatus.POWERED_BY_BATTERY;
            text = TEXTS.LIST_OM.batWorked;
        }

        if (arrObjDevice.find(d => d.battery === false)) {
            paramPoint = DeviceStatus.LOW_BATTERY_CHARGE;
            text = TEXTS.LIST_OM.batLow;
        }

        if (arrObjDevice.every(d => d.offline === true)) {
            paramPoint = DeviceStatus.OFFLINE;
            text = TEXTS.LIST_OM.notWorked;
        }

        if (arrObjDevice.find(d => d.battery === null)) {
            paramPoint = DeviceStatus.NO_SIGNAL;
            text = TEXTS.LIST_OM.noData;
        }
    }

    return {
        text,
        paramPoint
    };
}

export function getStationType(dataProviderId: number, accessGranted: boolean): MarkerType {
    if (dataProviderId === 2) {
        return 'OpenAQ';
    }

    if (accessGranted) {
        return 'myMo';
    }

    return 'mo';
};

export function getDataMinimumInterval(type: MarkerType, timeBegin: number, timeEnd: number): IntervalType {
    let interval_1;

    if (type === 'mo')
        interval_1 = 2;
    if (type === 'myMo')
        interval_1 = 1;
    if (type === 'city')
        interval_1 = 3;
    if (type === 'OpenAQ')
        interval_1 = 3;

    const days = (timeEnd - timeBegin) / (1000 * 60 * 60 * 24);
    let interval_2 = 1;
    if (type !== 'myMo' && days > 7) interval_2 = 3;
    if (type !== 'myMo' && days > 100) interval_2 = 4;

    return <IntervalType>Math.max(interval_1, interval_2);
};

export const DATA_MAX_INTERVAL = 4;

export const CHART_STATIC_COLORS = {
    pdk: 'red',
    norma: '#ef7c1b',
    [PM2]: '#A2C617',
    [PM10]: '#A2C617',
    [TEMP]: '#FF494B',
    [HUM]: '#8FCBF9',
    [PRES]: '#FFCC33',
    [AQI]: '#888',
    [CO2]: '#888'
};

const zones = {
    [PM2]: [11,   23,   35,   66,   97,   128,  160,  285,  410],
    [PM10]: [20,   40,   60,   120,  180,  240,  300,  540,  780],
    [CO]:  [1000, 2000, 3000, 3500, 4000, 4500, 5000, 7000, 9000],
    [NO2]: [13,   26,   40,   80,   120,  160,  200,  360,  520],
    [SO2]: [16,   33,   50,   162,  275,  387,  500,  950,  1400],
    [O3]:  [10,   20,   30,   62,   95,   127,  160,  290,  420],
    [NO]:  [20,   40,   60,   145,  230,  315,  400,  740,  1080],
    [CO2]:  [550, 700,  900,  1100, 1300, 1500, 2000, 2500, 3000],
    [H2S]:  [null, null,  null,  null, null, null, 8, null, null],
    [NH3]:  [null, null,  null,  null, null, null, 200, null, null],
    [SUM]:  [null, null,  null,  null, null, null, 8, null, null], // TODO: SibAGRO
};

const zone_mg = (() => {
    const newZone = {}

    Object.keys(zones).forEach(key => {
        newZone[key] = zones[key].map(value => value / 1000);
    });

    return newZone;
})();

class MeasureZones {
    scheme: measureScheme;

    setScheme(scheme: measureScheme) {
        this.scheme = scheme;
    }

    get(name: string) {
        return this.scheme === measureScheme.c_mmhg_mg ? zone_mg[name] : zones[name];
    }

    getPDKcc(name: string) {
        return this.get(name)?.[2];
    }
    getPDKmr(name: string) {
        return this.get(name)?.[6];
    }
}
export const measureZones = new MeasureZones();

export const MEASURE_SORT = [AQI, PM2, PM10, TEMP, HUM, PRES];
export const MEASURE_SORT_ALL = [AQI, PM2, PM10, CO, CO2, NO, NO2, SO2, O3, TEMP, PRES, HUM];
export const MEASURE_SORT_ELEMENTS = [PM2, PM10, CO, NO2, SO2, O3, NO];

export const PACKET_VALUE_TYPE_ID = {
    0: AQI,
    1: TEMP,
    2: PRES,
    3: HUM,
    4: PM2,
    5: PM10,
    6: CO,
    7: CO2,
    8: NO,
    9: NO2,
    10: SO2,
    11: O3,
    12: H2S,
    20: CH2O
};

export const CHART_PADDING_LR = detectMobile() ? [18, 18] : [50, 50]; // less @chartMobileLeftMargin, @mapPageLRpadding

export const formatDayMonth = (m: moment.Moment): string => {
    return m.format('D') + ' ' + m.format('MMM').substr(0, 3);
}

export const formatDayMonthYear = (m: moment.Moment) =>
    `${m.format('D')} ${m.format('MMM').substr(0, 3)} ${m.format('YYYY')}`;

const formatterChartTime = function() {
    const m = moment(this.value).utcOffset(0);
    const startDay = moment(this.value).utcOffset(0).startOf('day');

    const className = detectMobile() ? 'chart-text-bold-m' : 'chart-text-bold';

    return m.valueOf() == startDay.valueOf() ?
        `<span class="${className}" style="font-family: -apple-system, BlinkMacSystemFont, inter, sans-serif;">${formatDayMonth(m)}</span>`
        : `<span class="chart-text">${m.format('HH:mm')}</span>`;
};

export function formatterFlagTime(timestamp: number, tzOffset: number): string {
    const a = moment(timestamp).utcOffset(tzOffset).format('HH:mm');

    const b =  formatDayMonth(moment(timestamp).utcOffset(tzOffset));

    return '<b>' + a + '</b> ' + b;
};

export function formatterFlagTimeHour(timestamp: number, tzOffset: number): string {
    return moment(timestamp).utcOffset(tzOffset).format('HH:mm');
};

export function formatterFlagTimeDate(timestamp: number, tzOffset: number): string {
    return formatDayMonth(moment(timestamp).utcOffset(tzOffset));
};

export function formatterFlagTimeDateWithYear(timestamp: number, tzOffset: number): string {
    const d = moment(timestamp).utcOffset(tzOffset);
    return `${formatDayMonth(d)} ${d.format('YYYY')}`;
}

export function formatterFlagTime2(timestamp: number, tzOffset: number): string {
    const a = moment(timestamp).format('HH:mm');

    const b =  formatDayMonth(moment(timestamp));

    return '<b>' + a + '</b> ' + b;
};


const formatterChartMonitor = function() {
    const v = this.value;
    const styleB = 'style="color: #000; font-weight: bold;"';
    const styleG = 'style="color: #ACACAC;"';

    if (v >= 0 && v <= 10) {
        return `<span ${styleB}>${v}</span>`;
    }

    const m = moment(v).utcOffset(0);
    const startDay = moment(this.value).utcOffset(0).startOf('day');
    return m.valueOf() == startDay.valueOf() ?  `<span ${styleB}>${m.format('DD MMM')}</span>` : `<span ${styleG}>${m.format('HH:mm')}</span>`;
};

const _hoverOff = {
    states: {
        hover: {
            enabled: false
        }
    }
};

export const chartCommonOption = {
    series: [{}],
    title: {
        text: ''
    },
    tooltip: {
        valueDecimals: 1,
        crosshairs: [{
            width: 1,
            color: '#000'
        }],
        useHTML: true,
        headerFormat: `<div style="
                position: relative;
                z-index: 1;
                font-family: Inter, sans-serif;
                background: rgba(255,255,255,0.7);
                margin: -7px;
                padding: 7px;
                border-radius: 3px;
            ">
            {point.key}
        </div>`,
    },
    xAxis: {
        type: 'datetime',
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    rangeSelector: {
        enabled: false
    },
    scrollbar: {
        enabled: false
    },
    plotOptions: {
        series: {
            lineWidth: 1,
            point: {}
        },
        line: {
            states: {
                hover: {
                    enabled: false
                }
            }
        }
    },
};

export const CHART_INDIVIDUAL_OPTIONS = {
    [CHART_TYPE_BIG] : {
        chart: {
            width: 986,
            height: 265,
            events: {}
        },
        navigator: {
            series: {
                dataGrouping: {
                    groupPixelWidth: 16,
                    forced: true
                },
            },
        },
        exporting: {
            enabled: true,
            filename: 'CityAir',
            scale: 2,
            buttons: {
                contextButton: {
                    x: -30,
                    y: -10
                }
            }
        },
        yAxis: {
            labels: {
                enabled: false
            },
            title: {
                enabled: false
            },
            min: -100,
            max: 100
        },
    },

    [CHART_TYPE_FULL_WIDTH] : {
        chart: {
            get width() {return window.innerWidth - CHART_PADDING_LR[0] - CHART_PADDING_LR[1]},
            height: MAIN_CHART_HEIGHT,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 32,
            // zoomType: 'x',
            // resetZoomButton: {
            //   theme: {
            //       display: 'none'
            //   }
            // },
            events: {}
        },
        navigator: {
            series: {
                dataGrouping: {
                    groupPixelWidth: 16,
                    forced: true
                },
            },
            enabled: false
        },
        exporting: {
            enabled: false,
        },
        xAxis: {
            labels: {
                enabled: false
            },
            title: {
                enabled: false
            },
            gridLineWidth: 0,
        },
        yAxis: {
            labels: {
                enabled: false,
                y: 11,
                x: -5
            },
            title: {
                enabled: false
            },
            showLastLabel: true,
            min: -100,
            max: 100
        },
    },

    [CHART_TYPE_TIMELINE] : {
        chart: {
            get width() { return window.innerWidth - CHART_PADDING_LR[0] - CHART_PADDING_LR[1]},
            height: 60,
            marginLeft: 0,
            marginRight: 0,
            events: {},
            type: 'column',
        },
        navigator: {
            enabled: false
        },
        exporting: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                dataLabels: {
                    padding: 0
                },
                groupPadding: 0
            },
            dataGrouping: {
                enabled: false
            },
            series: {
                dataGrouping: {
                    enabled: false
                },
                point: {},
            }
        },
        yAxis: {
            labels: {
                enabled: false
            },
            title: {
                enabled: false
            },
            gridLineWidth: 0
        },
        xAxis: {
            type: 'datetime',
            opposite: true,
            offset: -4,
            labels: {
                formatter: formatterChartTime,
                useHTML: true
            },
            tickColor: 'rgba(0,0,0,0)',
        },
        tooltip: {
            enabled: false
        },
    },

    [CHART_NAME_MONITOR] : {
        chart: {
            get width() {
                let width = window.innerWidth;
                // width -= width < 1600 ? 15*2 : 150*2;
                width -= (width / 100) * 14;
                return width;
            },
            get height() {
                /*расчитываем высоту в процентном соотношении*/
                const height = (((window.innerHeight / 100) * 49) / 100) * 56.2;
                /*return height < 210 ? 210 : height*/
                return height;
            },
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            marginLeft: 0,
            marginRight: 25,
            events: {}
        },
        navigator: {
            enabled: false
        },
        exporting: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: formatterChartMonitor,
                style: {
                    fontSize: '15px'
                }
            }
        },
        yAxis: {
            title: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        plotOptions: {
            series: {
                lineWidth: 1,
                point: {},
                pointPadding: 0.02,
                groupPadding: 0
            },
            areaspline: _hoverOff,
            area: _hoverOff,
            line: _hoverOff,
            column: _hoverOff,
        },
    },

    [CHART_NAME_AIR_VOICE]: {
        tooltip: { enabled: false },
        chart: {
          get width() { return window.innerWidth - 50; },
          backgroundColor: 'transparent',
          height: 300,
          marginLeft: -20,
          marginRight: -20,
          events: {}
        },
        navigator: {
          enabled: false
        },
        exporting: {
          enabled: false,
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            enabled: false
          },
          gridLineWidth: 0,
          visible: false
        },
        yAxis: {
          labels: {
            enabled: false
          },
          title: {
            enabled: false
          },
          gridLineWidth: 0,
        },
        plotOptions: {
          series: {
            lineWidth: 1,
            point: {},
            enableMouseTracking: false
          },
          line: {
            states: {
              hover: {
                enabled: false
              }
            }
          },
          column: {
            borderRadius: 5
          }
        },
    },
};

CHART_INDIVIDUAL_OPTIONS[CHART_NAME_MONITOR_AQI] = {
    ...CHART_INDIVIDUAL_OPTIONS[CHART_NAME_MONITOR],
    // chart не скопирован а сделан ссылкой
    tooltip: {
        enabled: false,
        crosshairs: [{
            width: 0,
        }]
    }
}

CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_FULL_WIDTH_M] = {
    ...CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_FULL_WIDTH],
    chart: {
        ...CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_FULL_WIDTH].chart,
        height: MAIN_CHART_HEIGHT_M ,
    },
    tooltip: {
        crosshairs: false,
        enabled: false,
    }
};
Object.defineProperty(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_FULL_WIDTH_M].chart, 'width', { get() {return window.innerWidth - CHART_PADDING_LR[0] - CHART_PADDING_LR[1]}});

CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_AREA_MONITOR] = copyObj(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_FULL_WIDTH]);
Object.defineProperty(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_AREA_MONITOR].chart, 'width', { get() {return window.innerWidth - CHART_PADDING_LR[0] - CHART_PADDING_LR[1]}});
Object.defineProperty(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_AREA_MONITOR].chart, 'height', { get() {return window.innerWidth < 1600 ? 100 : 200}});

CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE_M] = {
    ...CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE],
    chart: {
        ...CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE].chart,
        height: 40,
        marginTop: 25,
        marginBottom: 5,
    },
    xAxis: {
        ...CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE].xAxis,
        labels: {
            ...CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE].xAxis.labels,
            y: -19
        }
    }
};
Object.defineProperty(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_TIMELINE_M].chart, 'width', { get() {return window.innerWidth - CHART_PADDING_LR[0] - CHART_PADDING_LR[1]}});

CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_WIDGET] = {
    ...CHART_INDIVIDUAL_OPTIONS[CHART_NAME_MONITOR],
    chart: {
        ...CHART_INDIVIDUAL_OPTIONS[CHART_NAME_MONITOR].chart,
    }
};
Object.defineProperty(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_WIDGET].chart, 'width', { get() {return window.innerWidth }});
Object.defineProperty(CHART_INDIVIDUAL_OPTIONS[CHART_TYPE_WIDGET].chart, 'height', { get() {
    let height = window.innerHeight - 130;
    if (height < 150) height = 150;
    if (height > 400) height = 400;
    return height;
}});

export let getShiftMapCityCard = (): [number, number] => [-250, (document.documentElement.clientHeight - 110/*меню+таймлайн*/) / 2 - 150];
export let getShiftMapMobile = (): [number, number] => [0, 421 /*таймлайн+шторка*/ - (document.documentElement.clientHeight) / 2];

export const MIN_PCF = 0.1;
export const MAX_PCF = 3;

export type ModelProps = {
    measure: string;
    contours: boolean;
    overlay: boolean;
};

export enum TabType {
    LEFT = 'left',
    RIGHT = 'right'
}

export const MEASURES_FOR_FORECAST = [PM2, PM10, NO2, SO2 /*O3*/];

