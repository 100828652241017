import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Feature } from 'projects/cityscreen/src/components/timeline-panel/models/core';
import { IIndoorState, IndoorState, measurementAdapter } from './reducers';
import { Post, Measurement } from '../api';

function transformToFeature({ post, measurements }: { post: Post, measurements: Measurement[] }): Feature[] {
    return [
        {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [post.geo.longitude, post.geo.latitude]
            },
            properties: {
                uuid: `${post.id}`,
                name: post.name,
                name_ru: post.name,
                timeseries: {
                    date: measurements.map(d => d.date),
                    AQI: measurements.map(d => d.aqi.indoorAqi?.valueScaled10 || null),
                    T: measurements.map(d => d.temperature),
                    P: measurements.map(d => d.pressure),
                    RH: measurements.map(d => d.humidity),
                    'PM2.5': measurements.map(d => d.pm2),
                    PM10: measurements.map(d => d.pm10),
                    CO2: measurements.map(d => d.co2),
                },
                obj: 'source',
                has_any_timeseries: measurements.length > 0,
            }
        }
    ];
}

export const getIndoorState = createFeatureSelector<IIndoorState>('indoor');

export const selectCore = createSelector(
    getIndoorState,
    (state: IIndoorState): IndoorState => state.core,
);

const _activePost = createSelector(
    selectCore,
    (state: IndoorState) => state.posts.find(p => p.id === state.activePointId)
);

const _activeMeasurements = createSelector(
    selectCore,
    (state: IndoorState) => measurementAdapter.getSelectors().selectAll(state.measurements).filter(m => {
        const d = new Date(m.date).getTime();

        const isInRange = d >= new Date(state.timeBegin).getTime() && d <= new Date(state.timeEnd).getTime();

        return m.postId === state.activePointId && isInRange;
    })
);

export const indoorSelectors = {
    time: createSelector(
        selectCore,
        (state: IndoorState) => state.currentTime
    ),
    activePointId: createSelector(
        selectCore,
        (state: IndoorState) => state.activePointId
    ),
    listPoints: createSelector(
        selectCore,
        (state: IndoorState) => state.posts
    ),
    getMeasurement: (postId: number) => createSelector(
        selectCore,
        (state: IndoorState) => measurementAdapter.getSelectors().selectEntities(state.measurements)[`${postId}_${state.currentTime}`]
    ),
    getActivePostWithMeasurements: createSelector(
        _activePost,
        _activeMeasurements,
        (post, measurements) => ({ post, measurements })
    ),
    getActivePostWithMeasurementsAsFeatures: createSelector(
        _activePost,
        _activeMeasurements,
        (post, measurements) => measurements?.length ? transformToFeature({ post, measurements }) : []
    ),
    analytics: createSelector(
        selectCore,
        (state: IndoorState) => state.analytics
    ),
    isAnalyticsLoading: createSelector(
        selectCore,
        (state: IndoorState) => state.isAnalyticsLoading
    ),
    isPointsLoading: createSelector(
        selectCore,
        (state: IndoorState) => state.isPointsLoading
    ),
    dateBegin: createSelector(
        selectCore,
        (state: IndoorState) => new Date(state.timeBegin)
    ),
    dateEnd: createSelector(
        selectCore,
        (state: IndoorState) => new Date(state.timeEnd)
    ),
    timeSequences: createSelector(
        selectCore,
        (state: IndoorState) => state.timeSequences
    ),
    sort: createSelector(
        selectCore,
        (state: IndoorState) => state.sort
    ),
    isAllowUpdate: createSelector(
        selectCore,
        (state: IndoorState) => state.isAllowUpdate
    ),
    dateRange: createSelector(
        selectCore,
        (state: IndoorState) => ({
            dateBegin: new Date(state.timeBegin),
            dateEnd: new Date(state.timeEnd)
        })
    ),
    selectMeasurement: createSelector(
        selectCore,
        (state: IndoorState) => state.selectMeasurement
    ),
};
