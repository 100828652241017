<div class="notifications_subscriptions">
    <div class="notifications_subscriptions__filter-tabs"
        [class.notifications_subscriptions__filter-tabs--initial-position]="initialScrollPosition || !selectedCount"
    >
        <switch-item
            [currentTab]="selectedTab"
            [tabs]="filterTabs"
            (action)="showSelectedTab($event)"
        ></switch-item>
    </div>

    <cdk-virtual-scroll-viewport class="notifications_subscriptions__list scroll"
        itemSize="340"
        minBufferPx="400"
        maxBufferPx="1600"
        #subs
        isScrollTop (isTop)="initialScrollPosition = $event"
    >
        <ng-container [ngSwitch]="uiState.typeFilter">
            <subscriptions-measurements *ngSwitchCase="filterTypes.MEASUREMENTS" [scrolled]="subs.elementScrolled()"></subscriptions-measurements>
            <subscriptions-service *ngSwitchCase="filterTypes.SERVICE" [scrolled]="subs.elementScrolled()"></subscriptions-service>
        </ng-container>
    </cdk-virtual-scroll-viewport>

    <ca-scroll-top *ngIf="!initialScrollPosition && selectedCount" (click)="scrollTop()"></ca-scroll-top>
</div>
