import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cs-slide-indicator',
    templateUrl: 'slide-indicator.component.html',
    styleUrls: ['slide-indicator.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideIndicatorComponent {
    @Input() slides: any[];

    @Input() currentSlide: any;

    @Output() gotoSlide = new EventEmitter<any>();

    selectSlide(item: any) {
        if (this.currentSlide !== item) {
            this.gotoSlide.emit(item);
        }
    }
}
