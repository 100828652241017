import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataProvider3 } from '../dataProvider/DataProvider';
import { Group } from './models';
import {
    GetUserPermissionModel,
    getUserPermissionTransformer,
    GroupInfo,
    groupInfoTransformer,
} from './dataTransformer';
import {
    AdminDevice,
    CreateOM,
    MoItemsDataToExcelRequestProps,
    MonitoringObject,
    StationDataToExcelRequestProps,
    UserItems
} from '../../namespace';
import * as moment from 'moment';
import { LANGUAGE } from '../../texts/texts';
import { copyObj, getUserSavedData } from '../../utils';
import { OBSERVER_ID } from '../../config';

import {
    CpUserItem,
    CpUserToken,
    FeedItemsExportRequest,
    GroupInfoResponse,
    GroupItem,
    GroupNotificationChangeRequest,
    GroupRequest,
    GroupRoleMemberRequest,
    GroupsListResponse,
    GroupsMosUsersItem,
    GroupsRolesMemberItem,
    SetRegionCoefsItemRequest,
    UserFeedItemRequest,
    UserFeedResponse,
    UserItemRequest,
    UserPermissionResponse
} from '../../../harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';
import {
    AdminApiProvider
} from '../../../harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiProvider';

import {
    MoApiItemRequest
} from '../../../harvester/UiAdminProject8/src/commonData/providers/moApiProvider/moApiModels';
import { MoApiProvider } from '../../../harvester/UiAdminProject8/src/commonData/providers/moApiProvider/moApiProvider';

import {
    DevicesApiProvider
} from '../../../harvester/UiAdminProject8/src/commonData/providers/devicesApiProvider/devicesApiProvider';


import { ApiRequest, ErrorResult, MoItem, PacketsValueTypeItem } from '../../../harvester/UiAdminProject8/src/commonData/models';
import { ApiModels } from '../api-models';
import {
    GroupFeaturesService
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';

// виталино--------------------------------------------------------------------------------------------------------------------------------

export class CopyAndCreateMos {
    renamedMos: MoItem[];

    constructor(private data: GroupInfoResponse, private createFn) {
    }

    create = (cityId: number, addName: string) => {
        const filteredMos = this.data.MonitoringObjects.filter(mo => mo.LocationId === cityId && mo.DeviceLinks && mo.DeviceLinks.length);
        const copiedMos = copyObj(filteredMos);

        copiedMos.forEach(mo => {
            const str = ` [${addName} ${moment().format('DD.MM.YY')}]`;

            mo.MoId = null;
            mo.LocationId = null;

            mo.Name = mo.Name + str;

            if (mo.PublishName)
                mo.PublishName = mo.PublishName + str;

            if (mo.PublishNameRu)
                mo.PublishNameRu = mo.PublishNameRu + str;

            mo.DeviceLinks.forEach(d => d.MapBegin = moment().subtract(3, 'day').toDate());
        })

        this.renamedMos = copiedMos;
        console.log(this.renamedMos);
    }

    send = (GroupId: number) => {
        this.renamedMos.forEach(mo => {
            const request: MoApiItemRequest = {
                GroupId,
                Item: mo,
                Token: window.JS_CP_TOKEN
            }

            this.createFn(request)
        })
    }
}

export class WindowGlobalVars extends Window {
    JS_CP_TOKEN: string;
    JS_CP_SITE_LANG: string;

    _copyAndCreateMos: CopyAndCreateMos;
}

declare const window: WindowGlobalVars;

const authData: ApiRequest = {
    Token: window.JS_CP_TOKEN
};

class ExtApiProvider {
    constructor(private readonly dataProvider: DataProvider3) {
    }

    GetObserverUserItem(request: {Item: CpUserItem}, execOk: (response: CpUserItem) => void, execFail: (errorResult: ErrorResult) => void) {
        this.dataProvider.execHttpEx('AdminApi2', 'GetObserverUserItem', request, false, true, execOk, execFail);
    }
}

@Injectable()
export class AdminPanelApi extends ApiModels {
    private moProvider: MoApiProvider;
    private adminApiProvider: AdminApiProvider;
    private devicesApiProvider: DevicesApiProvider;
    private extApiProvider: ExtApiProvider;

    private _groupInfoResponse: GroupInfoResponse; // для хранения сырых данных
    private _groupListResponce: GroupsListResponse; // для хранения сырых данных
    private _userPermissionResponse: UserPermissionResponse; // для хранения сырых данных

    constructor(
        private http: HttpClient,
        private groupFeaturesService: GroupFeaturesService
    ) {
        super();
        this.moProvider = new MoApiProvider(new DataProvider3(this.http));
        this.adminApiProvider = new AdminApiProvider(new DataProvider3(this.http));
        this.devicesApiProvider = new DevicesApiProvider(new DataProvider3(this.http));
        this.extApiProvider = new ExtApiProvider(new DataProvider3(this.http));
    }

// --------------------------------------------------GROUPS--------------------------------------------------------------------------
    getGroupsList = (): Promise<{groups: Group[], packetValueTypes: PacketsValueTypeItem[]}> => {
        return new Promise<{groups: Group[], packetValueTypes: PacketsValueTypeItem[]}>((resolve, reject) => {
            this.adminApiProvider.getGroupsList(
                authData,
                (data: GroupsListResponse) => {
                    this._groupListResponce = data;
                    const userSavedData: UserItems = getUserSavedData();

                    const groups = [];

                    data.Groups.forEach(g => {
                        if (!g.RoleMembers.find(member => member.UserId === userSavedData.userId))
                            return;

                        groups.push({
                            id: g.GroupId,
                            name: g.Name,
                            moCount: g.MoIds.length,
                            icon: g.GroupIconUrl
                        });
                    });

                    const packetValueTypes = data.PacketValueTypes

                    resolve({groups, packetValueTypes})
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    getGroupInfo = (groupId: number): Promise<GroupInfo> => {
        const groupItem: GroupItem = this._groupListResponce.Groups.find(g => g.GroupId === groupId);

        // возможно пользователь отовсюду удалён
        if (!groupItem) {
            console.log('getGroupInfo - groupItem not found возможно пользователь отовсюду удалён');
            return null;
        }

        return new Promise<GroupInfo>((resolve, reject) => {
            this.adminApiProvider.getGroupInfo(
                new GroupRequest(groupItem),
                (data: GroupInfoResponse) => {
                    this._groupInfoResponse = copyObj(data);

                    window._copyAndCreateMos = new CopyAndCreateMos(this._groupInfoResponse, this.moProvider.addMoItem); // utils

                    const groupInfo = groupInfoTransformer(data);

                    this.groupFeaturesService.setFeatures(groupInfo?.extConfig);

                    resolve(groupInfo);
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    setRegionCoefsItem = (coef: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const regionCoefs = this._groupInfoResponse.RegionCoefs.find(c => c.RegionCoefsId === this._groupInfoResponse.Group.RegionCoefsId);

            if (!regionCoefs) {
                return;
            }

            regionCoefs.Pcf = coef;
            this.adminApiProvider.setRegionCoefsItem(
                new SetRegionCoefsItemRequest(regionCoefs),
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    }
// --------------------------------------------------/GROUPS--------------------------------------------------------------------------
// --------------------------------------------------USERS--------------------------------------------------------------------------

    addUser = (userId: number ): Promise<GroupInfo> => {
        return new Promise<GroupInfo>((resolve, reject) => {
            this.adminApiProvider.addNewMemberToGroup(
                new GroupRoleMemberRequest({
                    UserId: userId,
                    GroupRoleId: OBSERVER_ID,
                    GroupId: this._groupInfoResponse.Group.GroupId
                }),
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    removeUser = (userId: number): Promise<GroupInfo> => {
        const user = this._groupInfoResponse.Group.RoleMembers.find(u => u.UserId === userId);

        return new Promise<GroupInfo>((resolve, reject) => {
            this.adminApiProvider.removeMemberFromGroup(
                new GroupRoleMemberRequest(user),
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    editUser = (userId: number, role: number): Promise<GroupInfo> => {
        const group = this._groupInfoResponse.Group;
        const user: GroupsRolesMemberItem = {
            UserId: userId,
            GroupId: group.GroupId,
            GroupRoleId: role
        };

        return new Promise<GroupInfo>((resolve, reject) => {
            this.adminApiProvider.editMemberRoleInGroup(
                new GroupRoleMemberRequest(user),
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    editUsersMO = (userId: number, mos: number[]): Promise<GroupInfo> => {
        const group = this._groupInfoResponse.Group;
        const user: GroupsMosUsersItem = {
            UserId: userId,
            GroupId: group.GroupId,
            MonitoringObjectsIds: mos
        };
        group.MosUsers = [user]; // отправляем только одного изменённого

        return new Promise<GroupInfo>((resolve, reject) => {
            this.adminApiProvider.editGroupUserMoPermissions(
                new GroupRequest(group),
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    haveTheUser = (Email: string): Promise<UserItems> => {
        return new Promise((resolve, reject) => {
            this.extApiProvider.GetObserverUserItem({
                    ...authData,
                    Item: {
                        ...new CpUserItem(),
                        Email
                    }
                },
                (data: CpUserItem) => {
                    resolve(data ? {
                        userId: data.UserId,
                        login: data.Login,
                        email: data.Email
                    } : null);
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    createObserverUserItem = (selectedUsers: UserItems): Promise<CpUserItem> => {
        return new Promise((resolve, reject) => {
            this.adminApiProvider.userItemCreate(
                new UserItemRequest(
                    <CpUserItem>{
                        UserId: selectedUsers.userId,
                        Email: selectedUsers.email,
                        Login: selectedUsers.login,
                        SiteLang: window.JS_CP_SITE_LANG,

                        IsAdmin: undefined,
                        BioInfo: undefined,
                        GroupRoleId: undefined,
                        Tag: undefined,
                    }
                ),
                (data: CpUserItem) => {
                    resolve(data);
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    getUserPermission = (userId: number): Promise<GetUserPermissionModel> => {
        const user = this._groupInfoResponse.Users.find(u => u.UserId === userId);

        return new Promise<GetUserPermissionModel>((resolve, reject) => {
            this.adminApiProvider.getUserPermission(
                new UserItemRequest(user),
                (data: UserPermissionResponse) => {
                    this._userPermissionResponse = data;
                    resolve(getUserPermissionTransformer(data))
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    sendWelcomeMail = (cpUserItem: CpUserItem): Promise<GetUserPermissionModel> => {
        return new Promise<GetUserPermissionModel>((resolve, reject) => {
            this.adminApiProvider.userItemSendWelcome(
                new UserItemRequest(cpUserItem),
                (data: string) => {
                    resolve()
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };
// --------------------------------------------------/USERS--------------------------------------------------------------------------
// --------------------------------------------------MO--------------------------------------------------------------------------
    private _createRawMoItem = (param: MonitoringObject): MoApiItemRequest => {
        const rawItem = this._groupInfoResponse.MonitoringObjects.find(mo => mo.MoId === param.id);

        return {
            ...authData,
            GroupId: this._groupInfoResponse.Group.GroupId,
            Item: {
                ...rawItem,
                Description: param.description,
                IsOffline: param.isOffline,
                MoId: param.id,
                Name: param.name,
                LocationId: param.locationId,
                DotItem: {
                    Latitude: param.geoLatitude,
                    Longitude: param.geoLongitude
                }
            }
        };
    };

    editMo = (param: MonitoringObject): Promise<{ok: boolean}> => {
        return new Promise((resolve, reject) => {
            this.moProvider.editMoItem(
                this._createRawMoItem(param),
                () => resolve({ok: true}),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    deleteMo = (param: MonitoringObject): Promise<{ok: boolean}> => {
        return new Promise((resolve, reject) => {
            this.moProvider.deleteMoItem(
                this._createRawMoItem(param),
                (data: any) => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    createMoItem = (_props: CreateOM, devices: AdminDevice[]) => {
        return new Promise((resolve, reject) => {
            const props: MoApiItemRequest = {
                ...authData,
                GroupId: this._groupInfoResponse.Group.GroupId,
                Item: {
                    ...new MoItem(),
                    MoId: 0,
                    Name: _props.name,
                    Description: _props.description,
                    DotItem: {Latitude: _props.geoLatitude, Longitude: _props.geoLongitude},
                    LocationId: _props.locationId,
                    DeviceLinks: [{
                        MapBegin: new Date(),
                        MapEnd: null,
                        LinkId: 0,
                        DeviceId: devices[0].id
                    }],
                    RegionCoefsId: this._groupInfoResponse.Group.RegionCoefsId || 1
                }
            };

            this.moProvider.addMoItem(
                props,
                (data: any) => {
                    resolve();
                },
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    }

// -------------------------------------------/MO-----------------------------------------------------------------------------------
// -------------------------------------------DEVICE-----------------------------------------------------------------------------------

    SaveMoDataToExcel = (props: MoItemsDataToExcelRequestProps): Promise<string> => {
        return new Promise<string>( (resolve, reject) => {
            this.moProvider.exportMoPacketsToExcel(
                {
                    ...authData,
                    TimeBegin: new Date(props.timeBegin),
                    TimeEnd: new Date(props.timeEnd),
                    IntervalType: props.interval,
                    MoIds: props.moIds,
                    Lang: LANGUAGE.toLocaleUpperCase(),
                    TimeZone: moment().utcOffset() / 60,
                    ExportType: props.type,
                    UnitsType: props.unitsType,
                    InsertPdk: props.insertPdk
                },
                (resp: string) => resolve(resp),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };

    SaveDeviceDataToExcel = (props: StationDataToExcelRequestProps): Promise<string> => {
        return new Promise<string>( (resolve, reject) => {
            this.devicesApiProvider.exportDataToBase64(
                {
                    ...authData,
                    DeviceExportType: props.type,
                    DateBegin: new Date(props.timeBegin),
                    DateEnd: new Date(props.timeEnd),
                    DeviceIds: props.ids,
                    Lang: LANGUAGE.toLocaleUpperCase(),
                    TimeZone: moment().utcOffset() / 60,
                },
                (resp: string) => resolve(resp),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        });
    };
// -------------------------------------------/DEVICE-----------------------------------------------------------------------------------
// -------------------------------------------NOTIFICATION-----------------------------------------------------------------------------------
    changeNotificationSettings = (request: GroupNotificationChangeRequest): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            this.adminApiProvider.changeGroupNotificationSetting(
                request,
                (data: string) => resolve(data),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    downloadFeedHistory = (request: FeedItemsExportRequest): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            this.adminApiProvider.exportFeedItemsToExcel(
                request,
                (data: string) => resolve(data),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    getUsersEventFeed = (request: UserFeedItemRequest): Promise<UserFeedResponse> => {
        return new Promise<UserFeedResponse>((resolve, reject) => {
            this.adminApiProvider.getUserNotificationFeeds(
                request,
                (result: UserFeedResponse) => resolve(result),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };

    markNotificationFeedViewed = (request: UserFeedItemRequest): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            this.adminApiProvider.markNotificationFeedViewed(
                request,
                (result: string) => resolve(result),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            );
        });
    };
// -------------------------------------------/NOTIFICATION-----------------------------------------------------------------------------------
// -------------------------------------------TOKEN-----------------------------------------------------------
    editUserToken = (id: number, newTitle?: string) => {
        return new Promise<void>((resolve, reject) => {
            if (!this._userPermissionResponse)
                return console.log('_userPermissionResponse undefined');

            const _token = this._userPermissionResponse.Tokens.find(T => T.TokenId === id);

            if (newTitle !== undefined) {
                _token.Title = newTitle;
            }

            this.adminApiProvider.upsertUserToken(
                {   ...authData,
                    Item: _token
                },
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        })
    }

    deleteUserToken = (id: number) => {
        return new Promise<void>((resolve, reject) => {
            if (!this._userPermissionResponse)
                return console.log('_userPermissionResponse undefined');

            const _token = this._userPermissionResponse.Tokens.find(T => T.TokenId === id);

            this.adminApiProvider.deleteUserToken(
                {   ...authData,
                    Item: _token
                },
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        })
    }

    createUserToken = (userId: number, title: string) => {
        const Token = new CpUserToken();

        Token.IsApiKey = true;
        Token.Title = title;
        Token.TokenId = 0;
        Token.UserId = userId;

        return new Promise<void>((resolve, reject) => {
            this.adminApiProvider.upsertUserToken(
                {   ...authData,
                    Item: Token
                },
                () => resolve(),
                (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
            )
        })
    }

    getUserTokenValue = (id: number): Promise<string> => {
        if (!this._userPermissionResponse)
            console.log('_userPermissionResponse undefined');
        else {
            const Token = this._userPermissionResponse.Tokens.find(T => T.TokenId === id);

            return new Promise((resolve, reject) => {
                this.adminApiProvider.getUserTokenValue(
                    {
                        ...authData,
                        Item: Token
                    },
                    (data: string) => resolve(data),
                    (errorResult: ErrorResult) => reject(this.errorHandler(errorResult))
                )
            })
        }
    }

// -------------------------------------------/TOKEN-----------------------------------------------------------
}
