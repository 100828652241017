import { addBreadcrumb, captureException } from '@sentry/minimal';
import { DistributionSummary } from 'projects/cityscreen/src/modules/indoor/services/api';

export enum SeasonsName {
    Year = 'Year',
    Winter = 'Winter',
    Spring = 'Spring',
    Summer = 'Summer',
    Autumn = 'Autumn',
}

export const HUM = 'Humidity';
export const PM2 = 'Pm2';
export const PM10 = 'Pm10';
export const TEMP = 'Temperature';
export const PRES = 'Pressure';
export const WDA = 'WDA';
export const WVA = 'WVA';
export const TIME_STAMP = 'TimeStamp';
export const TIME = 'Time';
export const AQI = 'Aqi';
export const IAQ = 'Iaq';
export const CO = 'CO';
export const CO2 = 'CO2';
export const NO = 'NO';
export const NO2 = 'NO2';
export const NH3 = 'NH3';
export const SO2 = 'SO2';
export const O3 = 'O3';
export const H2S = 'H2S';
export const CH2O = 'CH2O';
export const SUM = 'Sum'; // TODO: convenience alias for SibAGRO

export const HISTOGRAM_AQI = 'HISTOGRAM_AQI';

export const PDK = 'PDK';

export const ROOM = 'ROOM';

export const CHART_TYPE_BIG = 'CHART_TYPE_BIG';
export const CHART_TYPE_AREA_MONITOR = 'CHART_TYPE_AREA_MONITOR';
export const CHART_TYPE_FULL_WIDTH = 'CHART_TYPE_FULL_WIDTH';
export const CHART_TYPE_FULL_WIDTH_M = 'CHART_TYPE_FULL_WIDTH_M';
export const CHART_TYPE_TIMELINE = 'CHART_TYPE_TIMELINE';
export const CHART_TYPE_TIMELINE_M = 'CHART_TYPE_TIMELINE_M';
export const CHART_TYPE_WIDGET = 'CHART_TYPE_WIDGET';
export const CHART_NAME_TIMELINE = 'CHART_NAME_TIMELINE';
export const CHART_NAME_CITYAIR = 'CHART_NAME_CITYAIR';
export const CHART_NAME_MONITOR = 'CHART_NAME_MONITOR';
export const CHART_NAME_MONITOR_AQI = 'CHART_NAME_MONITOR_AQI';
export const CHART_NAME_AIR_VOICE = 'CHART_NAME_AIR_VOICE';

export class WindowGlobalVars extends Window {
    ASSETS_PATH: string;
    JS_CP_STANDALONE_MODE: boolean;
    CLOSED_MAP: boolean;

    JS_CP_LOGIN: string;
    JS_CP_TOKEN: string;
    JS_CP_SITE_LANG: string;
    JS_CP_APP_TITLE: string;
    CURRENT_MAP_PROVIDER: string;
    LOGIN_STYLE: 'sakhalin' | 'demo-cabinet' | 'spb112' | 'nornickel' | 'mnrecology';

    state: any;
    plumeState: any;
    clusterer: any;
    sentryCaptureException: typeof captureException;
    sentryAddBreadcrumb: typeof addBreadcrumb;
    commonLoader: HTMLDivElement;
    visualViewport: any;
}

export class Bounds {
    north: number;
    south: number;
    east: number;
    west: number;
}

export class CityCard {
    city: City_model;
}

export class City_model implements DataForCreateSeries {
    id: string;
    locationId: number;
    name: string;
    countryName: string;
    lat: number;
    lng: number;
    tzOffset: number;
    msOffset: number;
    bounds: Bounds;

    zIndex: number;
    isSmallCity: boolean;

    aqiHistory: AqiHistory_model[];

    originChartData: OriginChartData_model;

    distributionSummary?: DistributionSummary;

    lastData: {
        [AQI]?: [number, string],
        [PRES]?: [number, string],
        [HUM]?: [number, string],
        [TEMP]?: [number, string],
        time?: string,
        textForecast?: string,
        textQuality?: string
    };

    constructor(d: {
        id: string,
        locationId: number,
        name: string,
        countryName: string,
        bounds: {
            east: number,
            north: number,
            south: number,
            west: number,
        },
        tzOffset: number,
        msOffset: number,
        lat: number,
        lng: number,

        zIndex: number,
        isSmallCity: boolean,
    }) {
        this.lastData = {};
        Object.assign(this, d);
    }
}

export class MoBaseInfo {
    id: number;
    name: string;
    tzOffset: number;
}

export type MarkerType = 'city' | 'mo' | 'myMo' | 'OpenAQ' | 'geoPoint';

export class Marker_model extends MoBaseInfo {
    type: MarkerType;
    lat: number;
    lng: number;
    aqis: { aqi: number, timestamp: number }[];
    name: string;

    cityId?: string;
    aqi?: number;
    opacity?: number;
    description?: string;
    icon?: string;
    over?: boolean; // hover
    city?: City_model;
    areaPolygon?: any;

    constructor(options: Partial<Marker_model>) {
        super();
        Object.assign(this, options);
    }
}

export class AqiHistory_model {
    aqi: number;
    time: number; // timestamp для поиска ближней точки по х
}


export class Station_min_model extends MoBaseInfo {
    isOurStation: boolean;
    originChartData: OriginChartData_model;
}

export class DataForCreateSeries {
    id: number | string;
    name: string;
    pubName?: string;
    originChartData: OriginChartData_model;
    lastPacketId?: number;
}

export class LastData {
    [AQI]?: number;
    title?: string;
    [HUM]?: number;
    [TEMP]?: number;
    [PRES]?: number;
    [PM2]?: number;
    [PM10]?: number;
}

export class StationVesper_model extends DataForCreateSeries {
    originChartData: OriginChartData_model;
    lastData: LastData;
    lastPacketID: number;
}

export class StationData {
    lastPacketID: number;
    lastPacketTime?: number;
    packets: MeasuresForTime[];
    measuresInfo: MeasuresInfo_model;
}

export class MeasuresInfo_model {
    [id: number]: MeasuresInfoFormat
}

export class MeasuresInfoFormat {
    type: string;
    name: string;
    isHidden?: boolean;
    order?: number;
    unit: string;
}

export class MeasuresForTime {
    time: number;
    values: {
        [measure: string]: number;
    };
}

export class SeriesElement_model {
    [key: string]: any;
}

export class Series_model {
    series: SeriesElement_model[];
    pdk?: { MR: any, CC: any }[];
    yTitle?: string;
    xAxisOption?: {
        [name: string]: any
    };
}

export class StationForMapPage_model extends Station_min_model {
    pubName: string;
    isOurStation: boolean;
    tzOffset: number;
    measuresVal: {
        [chartName: string]: number
    };
    lastPacketID: number;
    lat: number;
    lng: number;

    type: MarkerType;
    cityId?: string;

    loading?: boolean;

    constructor(marker: Marker_model, originChartData?: OriginChartData_model, type?: MarkerType) {
        super();

        this.id = marker.id;
        this.name = marker.name;
        this.isOurStation = marker.type === 'myMo';
        this.tzOffset = marker.tzOffset;
        this.lat = marker.lat;
        this.lng = marker.lng;

        this.originChartData = originChartData || {};
        this.measuresVal = {};
        this.lastPacketID = null;
        this.type = marker.type;
        this.cityId = marker.cityId;
    }
}

export class ChartControl_model {
    updateChart: { (data: Series_model, deleteSelectLine: boolean): void };
    onPointSelect: { (currentX: number, removeOnly: boolean): void };
    addPoint: {
        (
            data: [number, number],
            _serie: number | string,
            coef?: { a: number, b: number },
            isShift?: boolean
        ): void
    };
    chartFullscreen: { () };
    findPoint: { (coordinateX: number): any };
    highlight: { (clientX: number): number };
    resize: { (type: string, getParams?: () => ParamResizeChart_model): void };
    setSize: { (type: string, params?: ParamResizeChart_model, animation?: { duration: number, easing: string } | false): void };
    findPointXCoordinate: { (timestamp: number): any };
    changeOptions: { ({}: any): void };
    reloadExtremes: { (): void };
}

export class OriginChartData_model {
    [measureName: string]: OriginChartDataMeasure_model; // {AQI, PM... данные для графика}
}

export class OriginChartDataMeasure_model {
    data: [number, number][];
    type: string;
    name: string;
    unit: string;
    min: number;
    max: number;

    constructor() {
        this.data = [];
        this.min = null;
        this.max = null;
    }
}

export class MeasureCoef_model {
    [measureName: string]: { a: number, b: number }
}

export class ParamResizeChart_model {
    shiftWidth: number;
}

export class UsersInMo {
    name: string;
    logins: string[];
}

export class UserItems {
    email: string;
    login: string;
    userId: number;
    roleId?: number;
    linksToMo?: MonitoringObject[];

    constructor() {
        this.userId = 0;
        this.email = '';
        this.login = '';
        this.linksToMo = [];
    }
}


export class AdminDevice {
    id: number;
    serialNumber: string;
    model: string;
    sourceId: number;
    sourceName: string;
    name: string;
    startWork: string;
    serviceDate: string;
    intervalSec: number;
    // isNotSaveData: boolean;
    battery: boolean;
    v220: boolean;
    offline: boolean;
    soft: string;
    hardware: string;
    linksToMo: MonitoringObject[];
    namesMo: string[];
    mapBegin?: number;

    lastTime: string;
    geoLatitude: number;
    geoLongitude: number;
    childDevices: AdminDevice[];
}

export class AdminDeviceStandalone extends AdminDevice {
    originChartData: OriginChartData_model;
    measuresVal: {
        [chartName: string]: number
    };
    lastPacketId: number;
}


export class MonitoringObject {
    id: number;
    name: string;
    description: string;
    isOffline: boolean;
    gmtOffset: number;
    tzOffset: number;
    geoLatitude: number;
    geoLongitude: number;
    pubName: string;
    lastPacketId: number;
    regionCoef: number;
    locationId: number;
    lastConnectedDevice: AdminDevice;

    users: UsersInMo[];
    devicesId: number[];
    devicesObj: AdminDevice[];
    devicesSerialNum: string[];

    originChartData: OriginChartData_model;
    measuresVal: {
        [chartName: string]: number
    };
}

export class CreateOM extends MonitoringObject {
    locationId: number;

    constructor() {
        super();

        this.id = null;
        this.name = '';
        this.description = '';
        this.isOffline = null;
        this.gmtOffset = -(new Date().getTimezoneOffset()) / 60;
        this.tzOffset = null; /* в минкутах*/
        this.geoLatitude = null;
        this.geoLongitude = null;
        this.pubName = null;
        this.lastPacketId = null;
        this.regionCoef = null;
        this.locationId = null;

        this.users = null;
        this.devicesId = null;
        this.devicesObj = null;
        this.devicesSerialNum = null;

        this.originChartData = null;
        this.measuresVal = null;
    }
}

export class StndAskPopupTexts {
    title: string;
    body?: string;
    cancel?: string;
    cancelNew?: string;
    accept?: string;
    extraAction?: string;
    doNotSave?: string;
}

export class ModulePageConfig {
    enableCompare = true;
    enableCalendar = true;
    enablePlumeButton = true;
    enableAqi = true;

    enableMap = true;
    enableTimeline = true;
    enableTopElements = true;
    fullScreen = false;

    lang?: string;
}

export enum namePages {
    defaultPage = '',
    listDevices = 'service',
    listOM = 'posts',
    listUsers = 'users',
    viewInfoOM = 'viewInfoOM',
    viewInfoDevices = 'viewInfoDevices',
    createOM = 'createOM',
    configPanel = 'settings',
    cityCard = 'analytics',
    notifications = 'notifications',
    plumes = 'plumes',
    dashboard = 'dashboard',
    networks = 'networks',
    indoor = 'indoor'
}

export enum measureScheme {
    default = 'default',
    c_mmhg_mg = 'c_mmhg_mg'
}

export type Interval = 1 | 2 | 3 | 4;

export type Contours = {
    [aqi: number]: { lat: number, lng: number }[][]
}

export class ModelLayer {
    width: number;
    height: number;
    bounds: Bounds;
    overlay: any;
    contoursData: any;
    contours: Contours;
    notLoad?: boolean;

    constructor(d: ModelData) {
        this.height = d.height;
        this.width = d.width;
        this.bounds = d.bounds;
        this.contours = d.contours;
    }
}

export class ModelData {
    locationId: number;
    measure: any;
    width: number;
    height: number;
    bounds: Bounds;
    data: number[];
    contours: Contours;
    time?: number;
}

export enum TimeEventFrom {
    clickOnChart,
    timeToEndBtn,
    dragFlag
}

export type UpdatesCommand = ('selectCity'
    | 'loadMos'
    | 'updateCityChart' | 'firstOpen')[];

export type UpdatesProps = {
    gmApiLoad?: Promise<any>,
    setTime?: number,
    timeBegin?: number,
    timeEnd?: number,
    cityId?: string,
    groupId?: number,
    centringMap?: boolean,
    mos?: number[],
    measures?: MeasuresInfoFormat[],
    email?: string,
    inviteCode?: string
}

export class Token {
    tokenId: number;
    title: string;
    isApiKey: boolean;
    createDate: string;
    lastDate: string;
    execCount: number;
    isActive: boolean;
}

export class StartOutsideParams {
    location?: string;
    stations?: number[];
    measures?: MeasuresInfoFormat[];
    time_diapason?: [number, number];
    current_time?: number;
    isOpenCityCard?: boolean;

    action?: 'invite' | '';
    email?: string;
    hash?: string;
}

export enum DeviceStatus {
    NO_SIGNAL,
    OFFLINE,
    LOW_BATTERY_CHARGE,
    POWERED_BY_BATTERY,
    ONLINE
}

export type DownloadType = 1 | 2 | 3;

export enum MoExportType {
    mo = 1,
    tza4 = 2,
}

export class DataPopup {
    type: DownloadType;
    title: string;
}

export class DownloadPopupData {
    type: DownloadType;
    title: string;
    ids: number[];
    mos?: {id: number, name: string}[];
    devices?: {id: number, serialNumber: string}[];
    currentTabInterval?: number;
}

export class SortingColumn {
    props: any;
    title: string;
}

export class HeaderPage {
    titlePage: string;
    btnBack?: string;
    btnOpenPage?: string;
}

export class TabModel {
    isDisabled?: boolean;
    type?: any;
    id?: string;
    title: string;
}

export enum ModulesIds {
    forecast = 1,
    plumes = 2,
    indoor = 3
}

export type InitiatorToCloseTimeline = 'plume';

export class CompareActions {
    add: boolean;
    clearBefore: boolean;
    notAllow: boolean;
    deleteCurrentIndex: number;
}

export type CheckboxItem = {
    id: number | string;
    idNumber?: number;
    label?: string;
    selected?: boolean;
}

export class ArrTagDevice {
    id?: string;
    type: 'device' | 'user' | 'mo';
    text: string;
    description?: string;
    isOnline: boolean;
}

export type ModelDownloadPopup = {
    timeBegin: number;
    timeEnd: number;
    downloadType: DownloadType;
    downloadTitle: string;
};

export type IntervalType = 1 | 2 | 3 | 4;
export type IntervalV2Type = '5m' | '20m' | '1h' | '1d' | 'source';

export const MINUTE20_MS = 20 * 60 * 1000;
export const HOUR_MS = 60 * 60 * 1000;
export const DAY_MS = 24 * HOUR_MS;
export const DATA_INTERVAL_TYPES = {
    1: 5, // minutes
    2: 20,
    3: 60,
    4: 1440 // 1day
};

export class CalendarOutput {
    timeBegin: number;
    timeEnd: number;
    allowUpdate: boolean;
}

export class GetCSTimelineInfoRequest {
    timeBegin: number;
    timeEnd: number;
    groupId: number;
    measureScheme?: measureScheme
}

export const USER_KEY = 'user';
export const ACCESS_TOKEN_KEY = 'token';

export class MoItemsDataToExcelRequestProps {
    timeBegin: number;
    timeEnd: number;
    moIds: number[];
    interval: number;
    type: number;
    unitsType: number;
    insertPdk: boolean;
}

export class StationDataToExcelRequestProps {
    type: number;
    timeBegin: number;
    timeEnd: number;
    ids: number[];
}

export enum CHART_BAR_NAME {
    allHistory = 'AllHistory',
    dayHour = 'DayHour',
    weekDay = 'WeekDay'
}

export const CITYAIR_STND_DEVICE = 'CityAir Device';
