<div class="timeline-runner" >
    <div class="timeline-runner__flagpole" [class.chart_is_open]="chartEnabled"></div>
    <div class="timeline-runner__flag timeline-runner__flag--{{flagDirection}}"
        #flag
    >
        <div class="timeline-date-label">
            <span class="timeline-date-label__time">{{ time }}</span>
            <span class="arrow-go-last" *ngIf="isLastTime" (click)="goEnd()"></span>
        </div>
    </div>
</div>
