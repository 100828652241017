import { Injectable, NgZone } from '@angular/core';
import { LngLatLike, PointLike, Map as Mapbox } from 'mapbox-gl';

import { LANGUAGE } from 'src/texts/texts';

import * as MapboxTypos from './mapbox-typos.json';
import {
    MAPBOX_BOUNDARY_LAYERS,
    MAPBOX_LABEL_LAYERS,
    MAPBOX_LABELS_COLOR,
    MAPBOX_STYLES
} from '../../../../shared/utils/config';

@Injectable({
    providedIn: 'root'
})
export class MapboxFacadeService {
    private map: Mapbox;

    constructor(private zone: NgZone) {}

    setMap(map: Mapbox) {
        this.map = map;
    }

    gotoLocation(lat: number, lng: number, zoom?: number) {
        this.moveTo({ lat, lng }, zoom);
    }

    moveTo(center: LngLatLike, zoom?: number, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            if (isNaN(zoom)) {
                return this.panTo(center, offset);
            }

            const options: any = {
                center,
                zoom,
                duration: 1000,
                easing: (t: number) => t
            };

            if (offset) {
                options.offset = offset;
            }

            this.map.easeTo(options);
        });
    }

    panTo(coords: LngLatLike, offset?: PointLike) {
        this.zone.runOutsideAngular(() => {
            this.map.panTo(coords, offset ? { offset } : undefined)
        });
    }

    applyCustomStyles() {
        const sourceHandler = ({ isSourceLoaded, source }) => {
            if (isSourceLoaded && source.url?.startsWith('mapbox://') && !source.tiles) {
                this.map.off('sourcedata', sourceHandler);
                this._applyCustomStyles();
            }
        };

        this.zone.runOutsideAngular(() => {
            this.map.on('sourcedata', sourceHandler);
        });
    }

    private _applyCustomStyles() {
        const { map } = this;
        // Render under the labels.
        // this.beforeLayerId = map.getStyle().layers.find(l => l.type === 'symbol').id;

        const textField = [
            'coalesce',
            ['get', 'name_' + LANGUAGE],
            ['get', 'name']
        ];

        ['hillshade', ...MAPBOX_BOUNDARY_LAYERS]
            .filter(layerName => map.getLayer(layerName))
            .forEach(layerName => {
                map.setLayoutProperty(layerName, 'visibility', 'none');
            });

        MAPBOX_LABEL_LAYERS
            .filter(layerName => map.getLayer(layerName))
            .forEach(layerName => {
                map.setLayoutProperty(layerName, 'text-field', textField);
                map.setPaintProperty(layerName, 'text-color', MAPBOX_LABELS_COLOR);

                // Dynamically fix mapbox typos
                const typos = MapboxTypos[LANGUAGE]?.[layerName];
                if (typos) {
                    map.setLayoutProperty(
                        layerName,
                        'text-field',
                        this.getTypoFixExpression(typos, `name_${LANGUAGE}`)
                    );
                }
            });

        Object.entries(MAPBOX_STYLES)
            .filter(([layerName]) => map.getLayer(layerName))
            .forEach(([layerName, styles]) => {
                Object.entries(styles).forEach(([style, value]) => {
                    map.setPaintProperty(layerName, style, value);
                });
            });

        map.touchZoomRotate.disableRotation();
    }

    private getTypoFixExpression(typos: [], field: string) {
        return [
            'case',
            ...typos.reduce((
                rules: any,
                names: { [key: string]: string }
            ) => [
                ...rules,
                ['==', ['get', 'name_en'], names.name_en],
                names[field]
            ], []),
            ['get', field]
        ];
    }
}
