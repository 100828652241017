import { Component, Input, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { TEXTS } from '../../../texts/texts';
import { UserItems } from '../../../namespace';
import { UsersStateService } from '../../users-state.service';

import './users-list.component.less';

@Component({
    selector: 'users-list',
    templateUrl: 'users-list.component.html',
})
export class UsersList {
    @Input() stateUsers: UserItems[];

    @ViewChild('feed') feed: CdkVirtualScrollViewport;

    TEXTS = TEXTS;
    searchUsers = '';
    sortingUser;
    sortingDirection = 1;

    sortUsersLogin = (stateUsers: UserItems) => stateUsers.login;
    sortUsersId = (stateUsers: UserItems) => stateUsers.roleId;

    constructor(public stateService: UsersStateService) {}

    sortingOfUesr = (sortItem) => {
        if (this.sortingUser === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingUser = sortItem;
        }
    }
}
