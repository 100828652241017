import { Component, Input, OnChanges } from '@angular/core';
import { DistributionSummary } from 'projects/cityscreen/src/modules/indoor/services/api';
import { TEXTS } from 'src/texts/texts';
import { AQI, CHART_BAR_NAME, SeasonsName, TabModel } from 'src/namespace';
import * as moment from 'moment';
import { ChartDataSets } from 'chart.js';
import { isFalseNumber } from 'src/utils';
import { Metrika } from 'ng-yandex-metrika';

@Component({
    selector: 'cs-analytic',
    templateUrl: './analytic-main.component.html',
    styleUrls: ['./analytic-main.component.less']
})
export class AnalyticComponent implements OnChanges {
    @Input() distributionSummary: DistributionSummary;
    @Input() measure?: string = AQI;

    TEXTS = TEXTS;
    CHART_BAR_NAME = CHART_BAR_NAME;
    chartCount: number[];
    chartHourAvg: ChartDataSets[];
    chartDayOfWeek: ChartDataSets[];
    tooltipDescription: string;
    noDataForSelectPeriod = true;

    tabsSeasons: TabModel[] = [
        {
            title: TEXTS.CITY_CARD.seasons[0],
            type: SeasonsName.Year
        },
        {
            title: TEXTS.CITY_CARD.seasons[1],
            type: SeasonsName.Winter
        },
        {
            title: TEXTS.CITY_CARD.seasons[2],
            type: SeasonsName.Spring
        },
        {
            title: TEXTS.CITY_CARD.seasons[3],
            type: SeasonsName.Summer
        },
        {
            title: TEXTS.CITY_CARD.seasons[4],
            type: SeasonsName.Autumn
        }
    ];

    currentTabsSeasons = this.tabsSeasons[0];

    constructor(private metrika: Metrika) {
    }

    ngOnChanges() {
        this.fillCharts();
    }

    selectSeason(tab: TabModel) {
        this.metrika.fireEvent('environmental_profile_seasons_buttons');
        this.currentTabsSeasons = tab;
        this.fillCharts();
    }

    fillCharts() {
        if (!this.distributionSummary)
            return this.noDataForSelectPeriod = true;

        const dataCartAqi: number[] = this.distributionSummary[this.currentTabsSeasons.type].distributionAqi.map(d => d.percent);
        const dataCartDayHour: number[] = this.distributionSummary[this.currentTabsSeasons.type].distributionDayHour.map(d => d.aqi);
        const dataCartWeekDay: number[] = this.distributionSummary[this.currentTabsSeasons.type].distributionWeekDay.map(d => d.aqi);

        this.noDataForSelectPeriod = isFalseNumber([...dataCartAqi, ...dataCartDayHour, ...dataCartDayHour].find(x => !isFalseNumber(x)));

        const startDate = moment.utc(this.distributionSummary[this.currentTabsSeasons.type].firstPacketDate).format('DD.MM.YYYY');
        this.tooltipDescription = TEXTS.CITY_CARD.since2[0] + startDate + TEXTS.CITY_CARD.since2[1];

        this.chartCount = dataCartAqi;
        this.chartHourAvg = [{data: dataCartDayHour}];
        this.chartDayOfWeek = [{data: dataCartWeekDay}];
    }
}
