import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { DirectivesModule } from '../directives/directives.module';
import { ArrowModule } from '../modules/icons/components/arrow/arrow.module';

import { InputForDropdownComponent } from './input-for-dropdown/input-for-dropdown.component';
import { InputDropdownComponent } from './input-dropdown/input-dropdown.component';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { TooltipsModule } from './tooltips/tooltips.module';
import { LangSelectorMobileComponent } from './lang-selector-mobile/lang-selector-mobile.component';
import { IconsModule } from 'projects/shared/modules/icons/icons.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        ArrowModule,
        ChartsModule,
        IconsModule,
        TooltipsModule
    ],
    declarations: [
        InputForDropdownComponent,
        InputDropdownComponent,
        InputComponent,
        LangSelectorComponent,
        LangSelectorMobileComponent,
    ],
    exports: [
        InputForDropdownComponent,
        InputDropdownComponent,
        InputComponent,
        LangSelectorComponent,
        LangSelectorMobileComponent,
    ]
})
export class SharedComponentsModule {
}
