<div class="user_card">
    <div class="user_card__icon user_card__icon-{{user.roleId}}"></div>

    <div class="user_card__right ellipsis">
        <div class="user_card__name ellipsis">{{user?.login}}</div>
        <div class="user_card__mail ellipsis">{{user?.email}}</div>

        <div class="user_card__bottom_block">
            <div class="user_card__role">{{TEXTS.USER_ACCESS_TYPE[user?.roleId]}}</div>
            <div class="user_card__logout button_link-blue" (click)="logout.emit()">{{TEXTS.LOGIN_WINDOWS.logout}}</div>
        </div>
    </div>
</div>