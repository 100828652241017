<div class="indoor_calendar" (clickOutside)="show = false">
    <div class="indoor_calendar__date" (click)="show = !show">{{getIntervalText()}}</div>

    <calendar-months
            [show]="show"
            [timeBegin]="begin"
            [timeEnd]="end"
            [tzOffset]="tzOffset"
            [applyCb]="updateTimeRange"
            [monthsVisible]="2"
            [columns]="2"
    ></calendar-months>
</div>