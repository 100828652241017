<div class="switch__wrapper">
<div [ngClass]="{
    'switch': true,
    'switch--disabled': isSwitchDisabled
}" (click)="switchOn()">
    <div [ngClass]="{
        'switch__circle': true,
        'switch__circle--active': !isSwitchDisabled && isSwitchOn,
        'switch__circle--active-disabled': isSwitchDisabled && isSwitchOn,
        'switch__circle--disabled': isSwitchDisabled && !isSwitchOn
    }"></div>
    <div [ngClass]="{
        'switch__line': true,
        'switch__line--active': !isSwitchDisabled && isSwitchOn,
        'switch__line--active-disabled':isSwitchDisabled && isSwitchOn,
        'switch__line--disabled': isSwitchDisabled && !isSwitchOn
    }"></div>
</div>
    <div [ngClass]="{'switch__text': true,'switch__text--disabled': isSwitchDisabled || !isSwitchOn}">{{text}}</div>
</div>