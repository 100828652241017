import { Component, OnInit } from '@angular/core';
import { detectMobile, isIos } from 'projects/shared/utils/other-utils';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'cs-market-links',
    templateUrl: './market-links.component.html',
    styleUrls: ['./market-links.component.less']
})
export class MarketLinksComponent implements OnInit {

    isIos = isIos();

    isMobile = detectMobile();

    TEXTS = TEXTS;

    constructor() {
    }

    ngOnInit(): void {
    }

}
