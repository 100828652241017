import { Component, OnInit } from '@angular/core';
import AdminPanelActions
    from 'projects/cityscreen/src/components/admin-panel/actions';
import AdminPanelState
    from 'projects/cityscreen/src/components/admin-panel/state';

@Component({
    selector: 'users-page-wrapper',
    templateUrl: './users-page-wrapper.component.html'
})
export class UsersPageWrapperComponent {

    constructor(
        public apActions: AdminPanelActions,
        public apState: AdminPanelState) {
    }

}
