import { Component, Input } from '@angular/core';

@Component({
    selector: 'dashboard-widget-title',
    templateUrl: 'dashboard-widget-title.component.html',
    styleUrls: ['dashboard-widget-title.component.less']
})
export class DashboardWidgetTitleComponent {
    @Input() title: string;
    @Input() tooltipText: string;
}
