<div class="chart" *ngIf="chartEnabled" [@inOutAnimation]>
    <div class="chart__close-btn" (click)="closeChart()">
        {{ TEXTS.COMMON.closeChart }}
        <cross-button size="small"></cross-button>
    </div>
    <div class="data-container">
        <div class="data-container inner">
            <div class="measurements_data">
                <div class="measurements_data-header">
                    <div class="measurements_data-header__title">{{ TEXTS.STATION_BLOCK.dataOnPollutants }}</div>
                    <div class="measurements_data-header__spacer"></div>
                    <div class="measurements_data-header__measurement-item" *ngFor="let mmt of availableMeasurements">
                        <label class="measurements_data-header__measurement-item-label"
                            [attr.title]="TEXTS.STATION_BLOCK.select"
                            [class.selected-item]="selectedMeasurements.includes(mmt)"
                        >
                            <div (click)="toggleMeasurement(mmt)" class="wrap-checkbox">
                                <cs-checkbox [checked]="selectedMeasurements.includes(mmt)"></cs-checkbox>
                            </div>
                            <span (click)="toggleMeasurementOne(mmt)">{{ mmt | renameAqi : aqiName }}</span>
                        </label>
                    </div>
                    <div class="measurements_data__measurement-items__spacer" *ngIf="showCompare"></div>
                </div>
                <div class="measurements_data__measurement-items"
                    *ngFor="let city of data; count as len; trackBy: featureIdentify"
                >
                    <div class="measurements_data__measurement-items__title">
                        <div class="city-name-wrapper">
                            <div class="city-name ellipsis" [attr.title]="getCurrentCityName(city)">
                                {{ getCurrentCityName(city) }}
                            </div>
                            <div class="city-sub-name">
                                <span *ngIf="city.properties.obj === 'city'; else current_name">
                                    {{ TEXTS.STATION_BLOCK.averageCity }}
                                </span>
                                <ng-template #current_name>
                                    <div class="current-city-name">{{ getCityName(city) }}</div>
                                    <div class="station-text" *ngIf="showStationSubtitle">
                                        {{TEXTS.STATION_BLOCK.experimentalStation}}
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="measurements_data__measurement-items__spacer">
                         <div class="wrapper_chart_line">
                             <svg width="20" height="2" *ngIf="len > 1">
                                <line x1="0" y1="0" x2="20" y2="0" stroke="#b3b4b3" stroke-width="2"
                                    [attr.stroke-dasharray]="this.dashLinesMap[city.properties.uuid]"
                                />
                             </svg>
                         </div>
                    </div>
                    <div class="measurements_data__measurement-items__item"
                        *ngFor="let mmt of availableMeasurements; trackBy: measurementIdentify"
                    >
                        <div *ngIf="mmt === 'AQI'; else value_content">
                            <div class="wrapper_points">
                                <div class="wrapper_circle">
                                    <aqi-circle [numberAqi]="getAqiValue(city, mmt)"></aqi-circle>
                                </div>
                            </div>
                        </div>
                        <ng-template #value_content>
                            <span class="value" [innerHTML]="getValue(city, mmt)"></span>
                            <span class="label" *ngIf="units[mmt]" [innerHTML]="units[mmt] | safeHtml"></span>
                        </ng-template>
                    </div>
                    <div class="measurements_data__measurement-items__spacer" *ngIf="showCompare">
                        <div *ngIf="isCompare; else actionTemplate"
                            (click)="selectFeature(city)"
                            class="remove-feature"
                        >
                            <cross-button size="medium"></cross-button>
                        </div>
                        <ng-template #actionTemplate>
                            <div class="plus-button"
                                (click)="onCompare()"
                                [attr.title]="TEXTS.STATION_BLOCK.compare"
                            >
                                <div class="plus-button-icon"></div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="timeline-loader">
        <div class="wrapper-loader" [class.has_content]="isEmptyData || !data.length">
            <div class="wrapper-no-data" *ngIf="isEmptyData">
                {{TEXTS.STATION_BLOCK.noData}}
            </div>
        </div>
    </div>
    <div class="chart-content"
        [class.hidden]="isEmptyData"
        (mouseleave)="hoverLineVisible = false"
    >
        <canvas #chart
            baseChart
            [legend]="false"
            [chartType]="chartType"
            [datasets]="chartData"
            [options]="chartOptions"
            [plugins]="chartPlugins"
            (chartClick)="chartClick($event)"
        ></canvas>
        <div class="chart-hover-line"
            [style.visibility]="hoverLineVisible ? 'visible' : 'hidden'"
            [style.left.px]="hoverLinePosition"
        ></div>
    </div>
</div>
