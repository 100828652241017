import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

import { measureScheme } from 'src/namespace';
import { environment } from 'projects/cityscreen/src/environments/environment';
import { ProviderApi } from 'projects/cityscreen/src/modules/core/services/api/provider';
import { ApiModels2 } from 'projects/cityscreen/src/modules/core/services/api/error-handler';
import { PlumesCollectionService } from '../../plumes-collection.service';
import { VangaAuthService } from '../vanga-auth/vanga-auth.service';
import { ControlPoint } from '../models/control-point-model';
import { RunConfig } from '../models/run-config.model';
import { FormError } from './form-error';

const API_URL = environment.vanga_api_url;
const AUTH_API_URL = environment.vanga_api_auth_url;

export const FormErrorHandler = (error: HttpErrorResponse, resolve) => {
    if (error.status === 400) {
        if (Object.keys(error.error).length) {
            resolve(new FormError(error.error));
        }
    } else {
        resolve(error);
    }

    return throwError(error);
}

export type RunsQueryOptions = {
    evaluation_time__lte: string;
    evaluation_time__gte: string;
};

export type VangaAuthResponse = {
    access?: string;
    error?: string;
};

@Injectable({
    providedIn: 'root'
})
export class PlumesApi extends ApiModels2 {
    constructor(
        private providerApi: ProviderApi,
        private plumeCollection: PlumesCollectionService,
        private vangaAuthService: VangaAuthService,
    ) {
        super();
    }

    runConfigUrl = `${API_URL}/run_config`;
    runsUrl = `${API_URL}/runs`;
    controlPointUrl = `${API_URL}/control_points`;
    sourcesUrl = `${API_URL}/sources`;
    auth = `${AUTH_API_URL}`;

    async getAllRuns(groupId: number, measure: string, options?: Partial<RunsQueryOptions>) {
        const data = await this.providerApi.get(
            `${this.runsUrl}/`,
            {
                group_id: groupId + '',
                expand: 'domain',
                omit: ['concentration_fields', 'control_points_report'],
                ...(options ? options : {})
            },
            this.errorHandler
        );

        this.plumeCollection.updateAllRuns(data, measure);
    }

    async getRunDetail(id: number, measure: string, scheme?: measureScheme) {
        const data = await this.providerApi.get(
            `${this.runsUrl}/${id}/`,
            {
                expand: ['sources', 'domain'],
                omit: ['concentration_fields'],
                measureScheme: scheme || measureScheme.default
            },
            this.errorHandler
        );

        this.plumeCollection.updateOneRun(id, data, measure);
    }

    async getRunConfig(groupId: number) {
        const data = await this.providerApi.get(
            `${this.runConfigUrl}/`,
            {
                group_id: groupId + '',
                expand: 'domain'
            },
            this.errorHandler
        );

        this.plumeCollection.updateRunConfig(data);
    }

    async editRunConfig(body: RunConfig): Promise<FormError | HttpErrorResponse> {
        return new Promise((resolve) => {
            this.providerApi.patch(
                `${this.runConfigUrl}/${body.id}/`,
                {
                    name: body.name,
                    schedule_period: body.schedule_period,
                    is_active: body.is_active
                },
                (error: HttpErrorResponse) => FormErrorHandler(error, resolve)
            ).then(() => {
                this.plumeCollection.updateRunConfig([body]);
                resolve(null);
            });
        });

    }

    createPoint = async ( body: ControlPoint): Promise<FormError | HttpErrorResponse> => {
        return new Promise((resolve) => {
            this.providerApi.post(
                `${this.controlPointUrl}/`,
                body,
                (error: HttpErrorResponse) => FormErrorHandler(error, resolve)
            ).then((data: ControlPoint) => {
                this.plumeCollection.addPoint(data);
                resolve(null);
            });
        });
    }

    editPoint = async ( body: ControlPoint): Promise<FormError | HttpErrorResponse> => {
        return new Promise((resolve) => {
            this.providerApi.patch(
                `${this.controlPointUrl}/${body.id}/`,
                body,
                (error: HttpErrorResponse) => FormErrorHandler(error, resolve)
            ).then((data: ControlPoint) => {
                this.plumeCollection.patchPoint(data);
                resolve(null);
            });
        });
    }

    deletePoint = async (pointId: number): Promise<void> => {
        await this.providerApi.delete(
            `${this.controlPointUrl}/${pointId}/`,
            this.errorHandler
        );

        this.plumeCollection.deletePoint(pointId);

        return;
    }

    getAllPoints = async (groupId: number) => {
        const data = await this.providerApi.get(
            `${this.controlPointUrl}/`,
            {
                group_id: groupId + ''
            },
            this.errorHandler
        );

        this.plumeCollection.updateAllPoints(data);
    }

    async getAllSources(groupId: number) {
        const data = await this.providerApi.get(
            `${this.sourcesUrl}/`,
            {
                group_id: groupId + ''
            },
            this.errorHandler
        );

        this.plumeCollection.updateAllSources(data);
    }

    async getTilesToken(): Promise<void> {
        const data = await this.providerApi.post(
            `${this.auth}/`,
            {},
            this.errorHandler
        );

        this.vangaAuthService.setAccessToken(data.access);
    }

    checkTilesToken() {
        if (this.vangaAuthService.isTokenExpired()) {
            return this.getTilesToken();
        }
    }
}
