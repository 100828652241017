import { Component } from '@angular/core';
import { Calendar } from './calendar';
import * as moment from 'moment';
import { formatDayMonth } from 'src/config';

@Component({
    selector: 'calendar-m',
    template: `
        <div class="calendar_input__time_interval-m" (click)="toggleShow()">
            {{getIntervalTextForMobile()}}
        </div>
        <calendar-months-mobile
                [show]="showCalendar"
                [timeBegin]="time.getBegin()"
                [timeEnd]="time.getEnd()"
                [tzOffset]="sharedCoreFacade.getTzOffset()"
                [toggleShowCb]="toggleShow"
                [applyCb]="updateTime"
                [updateDays]="updateDays"
        ></calendar-months-mobile>
    `,
})
export class Calendar_m extends Calendar {
    getIntervalTextForMobile() {
        const tzOffset = this.sharedCoreFacade.getTzOffset();
        const begin = moment(this.time.getBegin()).utcOffset(tzOffset);
        const end = moment(this.time.getEnd()).utcOffset(tzOffset);

        const textStart = formatDayMonth(begin);
        const textEnd = formatDayMonth(end);

        return  `${textStart} - ${textEnd}`;
    }
}
