import * as moment from 'moment';
import * as Sentry from '@sentry/browser';

import { DATA_INTERVAL_TYPES, StationForMapPage_model, WindowGlobalVars } from 'src/namespace';
import { is24hDiapason } from 'src/utils';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { GroupExtConfigName, GroupFeaturesService } from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';

import State from './state';
import Actions from './actions';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

declare const window: WindowGlobalVars;

const isAllowUpdate = (allowUpdate: boolean, timeBegin: number, timeEnd: number) => {
    const durationDays = moment.duration(moment().valueOf() - timeBegin).days();
    return (is24hDiapason(timeEnd) && durationDays <= 3) || allowUpdate;
};

const getNewTimeBegin = (allowUpdate: boolean, timeBegin: number, addMinutes: number): number => {
    return allowUpdate ? moment(timeBegin).add(addMinutes, 'minutes').valueOf() : timeBegin;
};

const getNewTimeEnd = (allowUpdate: boolean, timeEnd: number): number => {
    return allowUpdate ?  moment().valueOf() : timeEnd;
};

export class IntervalAction {
    currentHour = moment().get('hour');
    state: State;
    actions: Actions;
    time: Time;
    sharedCoreFacade: SharedCoreFacade
    groupFeaturesService: GroupFeaturesService;

    private async load(s: StationForMapPage_model) {
        return this.actions.cityairMapApi.getMoPackets({
                id: s.id,
                timeBegin: getNewTimeBegin(this.time.getAllowUpdate(), this.time.getBegin(), DATA_INTERVAL_TYPES[1]),
                timeEnd: getNewTimeEnd(this.time.getAllowUpdate(), this.time.getEnd()),
                interval: this.sharedCoreFacade.getChartDataIntervalType(),
                isPrivate: s.isOurStation,
                lastPacketId: s.lastPacketID
            },
            this.groupFeaturesService.getConfig(GroupExtConfigName.MeasureScheme)
        );
    }

    constructor(
        state: State,
        actions: Actions,
        sharedCoreFacade: SharedCoreFacade,
        groupFeaturesService: GroupFeaturesService,
        coreFacade: CoreFacade,
        time: Time
    ) {
        this.state = state;
        this.actions = actions;
        this.time = time;
        this.sharedCoreFacade = sharedCoreFacade;
        this.groupFeaturesService = groupFeaturesService;

        setInterval(() => {
            // обновляем графики станций
            if (
                window.JS_CP_TOKEN
                && sharedCoreFacade.isComparedListNotEmpty()
                && isAllowUpdate(time.getAllowUpdate(), time.getBegin(), time.getEnd())
            ) {
                sharedCoreFacade.getComparedList().forEach(async s => {
                    if (s.type === 'mo' || s.type === 'myMo' || s.type === 'OpenAQ') {
                        const data = await this.load(s);
                        sharedCoreFacade.actionObservers.updateStationDataOnePocket.next({data, station: s});
                        this.time.updateAfterLoadNewChartPoint();
                    }
                });
            }
        }, DATA_INTERVAL_TYPES[1] * 60000);

        Sentry.addBreadcrumb({
            category: 'intervalAction',
            message: `initial [GroupId = ${this.state.adminPanel.getCurrentGroup()?.id}][GroupsList = ${this.state.adminPanel.getGroupsList().map(g => g.name)}]`,
            level: Sentry.Severity.Info,
        });

        setInterval(() => {
            if (sharedCoreFacade.getIsShowPlume()) {
                return;
            }

            const hour = moment().get('hour');

            // сдвигаем промежуток времени до текущего
            if (window.JS_CP_TOKEN && isAllowUpdate(time.getAllowUpdate(), time.getBegin(), time.getEnd()) && this.currentHour !== hour) {
                Sentry.addBreadcrumb({
                    category: 'intervalAction',
                    message: `interval [GroupId = ${this.state.adminPanel.getCurrentGroup()?.id}][GroupsList = ${this.state.adminPanel.getGroupsList().map(g => g.name)}]`,
                    level: Sentry.Severity.Info,
                });

                this.currentHour = hour;
                const newTimeBegin = moment(time.getBegin()).add(1, 'hour').startOf('hour').valueOf();

                const newTimeEnd = getNewTimeEnd(time.getAllowUpdate(), time.getEnd());

                time.intervalUpdate.next({begin: newTimeBegin, end: newTimeEnd, allowUpdate: true});

                const locationWidget = groupFeaturesService.getConfig(GroupExtConfigName.locationWidget);

                if (locationWidget) {
                    actions.updateData(
                        [],
                        { cityId: locationWidget as string }
                    );
                }

                const cityId = sharedCoreFacade.getIsCityMod() ? sharedCoreFacade.getCurrentCityId() : sharedCoreFacade.getCityCard().city?.id;

                coreFacade.getCSTimelineInfo(
                    [ 'updateCityChart'],
                    { cityId }
                );
            }
        }, DATA_INTERVAL_TYPES[1] * 60000);
    }
}
