<div (clickOutside)="close()" class="input_date_range">
    <input-for-dropdown
            [label]="texts.DOWNLOAD_POPUP.period"
            [inputValue]="getIntervalText()"
            [(isOpenDropdown)]="showCalendar"></input-for-dropdown>
    <div *ngIf="descriptionText" class="input_date_range__description" >{{descriptionText}}</div>
    <calendar-months
            [show]="showCalendar"
            [timeBegin]="timeBegin"
            [timeEnd]="timeEnd"
            [applyCb]="updateTime"
            [monthsVisible]="1"
            [columns]="1"
            [dynamicWidth]="true"
    ></calendar-months>
</div>
