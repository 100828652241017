import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDateRangeComponent } from './input-date-range.component'
import { InputForDropdownModule } from 'projects/cityscreen/src/components/input-for-dropdown/input-for-dropdown.module';
import { CalendarMonthsModule } from 'src/calendar/calendar-months/calendar-months.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';

@NgModule({
    exports: [InputDateRangeComponent],
    declarations: [InputDateRangeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputForDropdownModule,
        CalendarMonthsModule,
        DirectivesModule
    ]
})
export class InputDateRangeModule {
}
