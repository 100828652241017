<div class="page-tabs">
    <div (click)="getTabIndex(-1)" *ngIf="isShowBtnArrows" class="page-tabs__btn-arrow page-tabs__btn-arrow--left"></div>
    <drag-scroll #nav [scroll-x-wheel-enabled]="true" [snap-disabled]="true" [drag-scroll-disabled]="true" [drag-disabled]="true" [scrollbar-hidden]="true" >
            <div *ngFor="let tab of tabs"
                 #pageTabsTab
                 drag-scroll-item
                 [ngClass]="{
                'page-tabs__tab': true,
                'page-tabs__tab--arrows': true,
                'page-tabs__tab--inactive': tab !== selectedTab,
                'page-tabs__tab--disable': tab.isDisabled
            }"
                    (click)="selectTab(tab)"
                    [attr.data-cy]="tab.type"
            >
                <div class="page-tabs__tab__title" [innerHTML]="tab.title"></div>
                <div [class.page-tabs__tab__underline]="tab === selectedTab"></div>
            </div>
    </drag-scroll>
    <div (click)="getTabIndex(1)" *ngIf="isShowBtnArrows" class="page-tabs__btn-arrow"></div>
</div>