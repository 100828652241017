import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticTagsComponent } from './static-tags.component';


@NgModule({
    exports: [StaticTagsComponent],
    declarations: [StaticTagsComponent],
    imports: [
        CommonModule
    ]
})
export class StaticTagsModule {
}
