<div [ngClass]="{
        'event_feed_item': true,
        'event_feed_item--visited': disableNavigation || notification.IsViewed
    }"
>
    <div class="event_feed_item__header_wrapper">
        <div class="event_feed_item__header_left">
            <div [ngClass]="{
                    'event_feed_item__header_icon': true,
                    'event_feed_item__header_icon-measurement': isPdkType,
                    'event_feed_item__header_icon-service': isServiceType
                }"
                caTooltip
                caTooltipPlacement="top-left"
                [caTooltipTemplate]="iconTooltip"
            ></div>
            <ng-template #iconTooltip>
                <ca-tooltip [text]="tooltipIconText"></ca-tooltip>
            </ng-template>

            <div class="event_feed_item__header_title"
                *ngIf="stateService.canEdit(); else blankTitle"
                caTooltip
                caTooltipPlacement="top-left"
                [caTooltipTemplate]="eventTooltip"
                (click)="!disableNavigation && openSettings()"
            >
                <div class="ellipsis">
                    {{notification.NotificationTitle}}
                </div>
            </div>
            <ng-template #eventTooltip>
                <ca-tooltip [text]="textsNotification.tooltipEventTitle"></ca-tooltip>
            </ng-template>
            <ng-template #blankTitle>
                <div class="event_feed_item__header_title event_feed_item__header_title-no_select ellipsis">{{notification.NotificationTitle}}</div>
            </ng-template>
        </div>

        <div class="event_feed_item__header_right"
            caTooltip
            caTooltipPlacement="top-right"
            [caTooltipTemplate]="durationTooltip"
        >
            <div class="event_feed_item__header_period">{{textsNotification.durationDescription[durationType]}}:</div>
            <div class="event_feed_item__header_timer">
                {{headerDateTimeText}}
            </div>
        </div>
        <ng-template #durationTooltip>
            <ca-tooltip>
                <div class="event_feed_item__duration-tooltip" *ngIf="hasEnded; else inProgress">
                    <div class="event_feed_item__duration-tooltip__label">
                        {{textsNotification.tooltipDurationLabel[1]}}
                    </div>
                    <div class="event_feed_item__duration-tooltip__details">
                        {{durationTooltipText}}
                    </div>
                </div>
                <ng-template #inProgress>
                    <div class="event_feed_item__duration-tooltip">
                        <div class="event_feed_item__duration-tooltip__label">
                            {{textsNotification.tooltipDurationLabel[0]}}
                        </div>
                        <div class="event_feed_item__duration-tooltip__details">
                            {{textsNotification.duration}}: {{durationTooltipText}}
                        </div>
                    </div>
                </ng-template>
            </ca-tooltip>
        </ng-template>
    </div>

    <event-body
        [notification]="notification"
        [included]="false"
        [todayDate]="todayDate"
        [disableNavigation]="disableNavigation"
        [hasMarker]="hasMarker"
        (gotoMonitoringObject)="gotoMonitoringObject.emit($event); markAsVisited()"
        (gotoDeviceInfo)="gotoDeviceInfo.emit($event)"
        (gotoIndoorMonitoringObject)="gotoIndoorMonitoringObject.emit($event); markAsVisited()"
    ></event-body>

    <event-body *ngIf="firstEventVisible && firstNotification"
        [notification]="firstNotification"
        [included]="true"
        [todayDate]="todayDate"
        [disableNavigation]="disableNavigation"
        [hasMarker]="hasMarker"
        (gotoMonitoringObject)="gotoMonitoringObject.emit($event); markAsVisited()"
        (gotoDeviceInfo)="gotoDeviceInfo.emit($event)"
        (gotoIndoorMonitoringObject)="gotoIndoorMonitoringObject.emit($event); markAsVisited()"
    ></event-body>

    <div class="event_feed_item__more" *ngIf="hasEnded">
        <div [ngClass]="{
                'event_feed_item__more__text': true,
                'event_feed_item__more__text--revert': firstEventVisible
            }"
            (click)="toggleFirstEvent($event)"
        >
            {{firstEventVisible ? textsNotification.collapse : textsNotification.showFirstEvent}}
        </div>
    </div>
</div>
