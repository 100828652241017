import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, pluck, switchMap, take } from 'rxjs/operators';

import AdminPanelActions from './actions';
import AdminPanelState from './state';

import { OffPanelPopupService } from './off-panel-popup.service';

import { namePages } from 'src/namespace';

import { TEXTS } from 'src/texts/texts';

import './style/main.less'
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';
import { ClientConfigService } from 'projects/cityscreen/src/modules/core/services/client-config/client-config.service';
import {
    selectGroupInfo,
    selectGroupList,
    selectIsSidebarOpen
} from 'projects/cityscreen/src/modules/core/store/selectors';
import { toggleSidebar } from 'projects/cityscreen/src/modules/core/store/actions';

@Component({
    selector: 'admin-panel',
    templateUrl: 'admin-panel.component.html'
})
export class AdminPanelComponent implements OnInit {
    TEXTS = TEXTS;
    textsStationList = TEXTS.LIST_OM;
    textsListDevices = TEXTS.LIST_DEVICES;
    textsListUsers = TEXTS.LIST_USERS;

    namePages = namePages;

    intervals: { val: number, name: string }[] = [];

    searchUsers: string;
    isShowDeleteUserPopup = false;
    notShowTooltip = false;
    lastVisitedSidebarPage: namePages;
    selectGroupList = selectGroupList;
    selectIsSidebarOpen = selectIsSidebarOpen;
    toggleSidebar = toggleSidebar;

    assets: Record<string, string> = {};

    constructor(
        readonly popupProvider: OffPanelPopupService,
        public sharedCoreFacade: SharedCoreFacade,
        public coreFacade: CoreFacade,
        public apActions: AdminPanelActions,
        public apState: AdminPanelState,
        readonly clientConfigService: ClientConfigService,
        public store: Store,
    ) {
        this.initAssets();
    }

    private initAssets() {
        const clientConfig = this.clientConfigService.getConfig();

        const logoSize = clientConfig.sidebar?.logoWidth;
        const logo = clientConfig.sidebar?.logo

        this.assets = {
            styleBackgroundSize: logoSize > 0 && `${logoSize}px auto`,
            logo: logo && 'url(' + this.clientConfigService.getAsset(logo) + ')'
        };
    }

    menuItemEnabled: {
        [key in namePages]?: () => boolean;
    } = {
        posts: () => (this.apState.monitoringObjects?.length || this.apState.myDevices?.length) > 0,
        analytics: () => !!this.coreFacade.getCities()?.length,
        plumes: () => this.coreFacade.getAllowPlumes(),
        service: () => this.apState.myRole?.editMo && !!(this.apState.myDevices.length || this.apState.monitoringObjects.length),
        notifications: () => this.apState.myRole?.editMo && !!(this.apState.myDevices.length || this.apState.monitoringObjects.length),
        users: () => this.apState.myRole?.editUser,
        settings: () => !!this.apState.iAm,
        networks: () => true,
        indoor: () => true
    };

    menuItemVisible: {
        [key in namePages]?: Observable<boolean>;
    } = {
        indoor: this.store.select(selectGroupInfo).pipe(pluck('allowIndoor'))
    };

    isSidebarPageAvailable() {
        // cityCard is a default page
        const check = this.menuItemEnabled[this.lastVisitedSidebarPage || namePages.cityCard];
        return check ? check() : true;
    }

    ngOnInit() {
        for (let i = 1; i <= 5; i++) {
            this.intervals.push({
                val: i * 60,
                name: i + ' ' + this.textsListDevices.min
            });
        }

        this.coreFacade.firstLoad$.pipe(
            map(() => {
                const page = this.lastVisitedSidebarPage = this.apActions.getLastVisitedPage();
                const isAvailable = this.menuItemEnabled[page]?.();

                if (!isAvailable) {
                    this.clearLastVisitedPage();
                } else {
                    return this.menuItemVisible[page];
                }
            }),
            filter(v => !!v),
            switchMap(v => v),
            take(1),
            filter(isMenuVisible => !!isMenuVisible),
        ).subscribe(() => {
            this.clearLastVisitedPage();
        });
    }

    clearLastVisitedPage() {
        this.lastVisitedSidebarPage = null;
        this.apActions.clearLastVisitedPage();
    }

    showDeleteUserPopup = () => this.isShowDeleteUserPopup = true;
    hideDeleteUserPopup = () => this.isShowDeleteUserPopup = false;

    updateGroupInfo() {
        this.coreFacade.updateGroupInfo();
    }
}
