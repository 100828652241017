import { Component, Input } from '@angular/core';

export type ButtonSize = 'large' | 'medium' | 'small';

@Component({
    selector: 'cross-button',
    templateUrl: 'cross-button.component.html',
    styleUrls: ['cross-button.component.less']
})
export class CrossButtonComponent {
    @Input() size: ButtonSize = 'medium';
}
