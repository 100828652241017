import { Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';

import { BarChartData, CHART_BAR_NAME } from 'projects/cityscreen/src/components/analytic-charts/bar-chart/namespace';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { HelperService } from 'projects/cityscreen/src/components/timeline-panel/services/helper.service';
import { TEXTS } from 'src/texts/texts';
import { ALERT_COLORS } from 'src/config';
import { AQI } from 'src/namespace';

@Component({
    selector: 'cityscreen-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.less']
})
export class BarChartComponent implements OnInit, OnChanges {
    @Input() data: ChartDataSets[];
    @Input() chartName: string;
    @Input() tooltipDescription: string;
    @Input() measure?: string = TEXTS.NAMES[AQI];

    TEXTS = TEXTS

    noData = TEXTS.CITY_CARD.noData;
    chartOptions: BarChartData = null;
    chartsNameLocale = [
        TEXTS.CITY_CARD.charts[0],
        TEXTS.CITY_CARD.charts[1],
        TEXTS.CITY_CARD.charts[2],
    ];
    public total = 1;
    public barChartPlugins = [pluginDataLabels];

    constructor(private helperService: HelperService) {
    }

    ngOnInit(): void {
        if (this.data && this.chartName) {
            this.chartOptions = this.getOptionsByName(this.data, this.chartName);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data.currentValue) {
            this.data = changes.data.currentValue;
            if (this.data && this.chartName) {
                this.chartOptions = this.getOptionsByName(this.data, this.chartName);
            }
        }
    }

    getOptionsByName(data: ChartDataSets[], chartName: string): BarChartData {
        switch (chartName) {
            case CHART_BAR_NAME.allHistory:
                this.total = this.getTotalValue(data);
                return {
                    title: this.chartsNameLocale[0],
                    tooltip: TEXTS.ANALYTICS_COMPONENT.tooltip(0, this.measure),
                    description: this.tooltipDescription,
                    chart: {
                        colors: [{backgroundColor: ALERT_COLORS.slice(1)}],
                        data: data,
                        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                        options: this.getChartOptions(CHART_BAR_NAME.allHistory),
                        legend: false,
                        plugins: this.barChartPlugins,
                    },
                };
            case CHART_BAR_NAME.weekDay:
                return {
                    title: this.chartsNameLocale[2],
                    tooltip: TEXTS.ANALYTICS_COMPONENT.tooltip(2, this.measure),
                    description: this.tooltipDescription,
                    chart: {
                        colors: this.getChartColor(data),
                        data: data,
                        labels: this.helperService.getWeekShort(),
                        options: this.getChartOptions(chartName),
                        type: 'bar',
                    },
                };
            case CHART_BAR_NAME.dayHour:
                return {
                    title: this.chartsNameLocale[1],
                    tooltip: TEXTS.ANALYTICS_COMPONENT.tooltip(1, this.measure),
                    description: this.tooltipDescription,
                    chart: {
                        colors: this.getChartColor(data),
                        data: data,
                        labels: Array(24).fill(0).map((_, i) => `${i}:00`.padStart(5, '0')),
                        options: this.getChartOptions(chartName),
                        type: 'bar',
                    },
                };
        }
    }

    private getChartColor(data: ChartDataSets[]): Color[] {
        if (!data.length) {
            return [{}];
        }
        const result = [];
        data[0].data.forEach((value) => value ? result.push(ALERT_COLORS[+value.toFixed()]) : result.push(ALERT_COLORS[0]));
        return [{backgroundColor: result}];
    }

    private getChartOptions(chartName: CHART_BAR_NAME): ChartOptions {
        const total = this.total;
        return {
            responsive: true,
            title: {
                display: false,
            },
            layout: {
                padding: {
                    top: 20
                }
            },
            aspectRatio: 2.75, // width / height
            tooltips: {
                enabled: chartName != CHART_BAR_NAME.allHistory,
                borderWidth: 1,
                bodyFontColor: '#000',
                titleFontColor: '#000',
                custom: (tooltip) => {
                    tooltip.displayColors = true;
                    tooltip.borderColor = ALERT_COLORS['1'],
                        tooltip.backgroundColor = 'rgba(255, 255, 255, 0.7)';
                },
                callbacks: {
                    label: (tooltipItem, data) => {
                        let ret;
                        if (chartName == CHART_BAR_NAME.allHistory) {
                            ret = tooltipItem.yLabel + '%';
                        } else {
                            ret =
                                tooltipItem.yLabel.toString().length > 4
                                    ? this.measure + ': ' + tooltipItem.yLabel.toString().substr(0, 4)
                                    : this.measure + ': ' + tooltipItem.yLabel;
                        }
                        return ret;
                    },
                },
            },
            showLines: false,
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            fontColor: '#9B9B9B',
                            fontSize: 13,
                            maxRotation: 0,
                            maxTicksLimit: chartName == CHART_BAR_NAME.dayHour ? 4 : 30,
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: false
                        },
                        ticks: {
                            fontColor: '#262626',
                            fontSize: 12,
                            beginAtZero: true,
                            fontFamily: 'Inter',
                            precision: 0,
                            labelOffset: 5,
                            padding: 2,
                            callback: (value: number | string, index: number, values: number[] | string[]): string | number | null | undefined => {
                                if (value == values[0]) {
                                    return this.measure;
                                } else {
                                    return ' ' + value + '  ';
                                }
                            }
                        },
                        gridLines: {
                            display: true,
                            color: '#FFFFFF',
                            borderDash: [3],
                            drawOnChartArea: true,
                            drawBorder: false,
                            z: 2,
                            zeroLineColor: 'transparent'
                        },
                        display: chartName != CHART_BAR_NAME.allHistory,
                    },
                ],
            },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        return ((value * 100) / total).toFixed(0) + '%';
                    },
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: 'bold',
                    },
                    anchor: 'end',
                    align: 'end',
                    offset: 0
                }
            }
        }
    }

    private getTotalValue(data): number {
        if (data && data[0] && data[0].data.length) {
            return data[0].data.reduce((partial_sum, a) => partial_sum + a, 0);
        } else {
            return 1;
        }
    }

}

