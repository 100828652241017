import {
    AQI,
    CH2O,
    CO,
    CO2,
    H2S,
    HISTOGRAM_AQI,
    HUM,
    measureScheme,
    NO,
    NO2,
    O3,
    PDK,
    PM10,
    PM2,
    PRES,
    ROOM,
    SO2,
    StndAskPopupTexts,
    SUM,
    TEMP,
    WDA,
    WVA,
    NH3, IAQ
} from '../namespace';

export const ugM = 'μg/m<sup>3</sup>';
export const mgM = 'mg/m<sup>3</sup>';
export const shareOfMPC = '';

// used to group the histogram coefficients
export const MEASURES = {
    [AQI]: 'points',
    [HISTOGRAM_AQI]: '% of time',
    [HUM]: '%',
    [PM10]: ugM,
    [PM2]: ugM,
    [SO2]: ugM,
    [CH2O]: ugM,
    [CO]: ugM,
    [CO2]: 'ppm',
    [O3]: ugM,
    [NO]: ugM,
    [H2S]: ugM,
    [NH3]: ugM,
    [NO2]: ugM,
    [TEMP]: '°C',
    [PRES]: 'mm Hg',
    [WDA]: '°',
    [WVA]: 'm/s',
    [SUM]: ugM,
};

export const MEASURES_MG = {
    [AQI]: 'points',
    [HISTOGRAM_AQI]: '% of time',
    [HUM]: '%',
    [PM10]: mgM,
    [PM2]: mgM,
    [SO2]: mgM,
    [CH2O]: mgM,
    [CO]: mgM,
    [CO2]: 'ppm',
    [O3]: mgM,
    [NO]: mgM,
    [H2S]: mgM,
    [NH3]: mgM,
    [NO2]: mgM,
    [TEMP]: '°C',
    [PRES]: 'mm Hg',
    [WDA]: '°',
    [WVA]: 'm/s',
    [SUM]: mgM,
};

export const UNITS = {
    '°C': '°C',
    'mm Hg': 'mm Hg',
    '%': '%',
    'µg/m³': ugM,
    'mg/m³': mgM,
    'ppm': 'ppm',
    'mV': 'mV',
    '°': '°',
    'm/s': 'm/s',
    'mm/min': 'mm/min',
}

class Measures {
    scheme: measureScheme;

    setScheme(scheme: measureScheme) {
        this.scheme = scheme;
    }

    get(name: string) {
        return this.scheme === measureScheme.c_mmhg_mg ? MEASURES_MG[name] : MEASURES[name];
    }
}

export const measures = new Measures();

export const AQI_DECL = ['point<i style="visibility: hidden;">s</i>', 'points', 'points', 'points'];
export const AQI_DECL2 = ['point', 'points', 'points', 'points'];

export const NAMES = {
    [AQI]: 'AQI',
    [IAQ]: 'IAQ',
    [HISTOGRAM_AQI]: '',
    [HUM]: 'RH',
    [PM10]: 'PM10',
    [PM2]: 'PM2.5',
    [SO2]: 'SO<sub>2</sub>',
    [CO]: 'CO',
    [CO2]: 'CO<sub>2</sub>',
    [O3]: 'O<sub>3</sub>',
    [NO]: 'NO',
    [H2S]: 'H<sub>2</sub>S',
    [NH3]: 'NH<sub>3</sub>',
    [NO2]: 'NO<sub>2</sub>',
    [CH2O]: 'CH<sub>2</sub>O',
    [TEMP]: 'T',
    [PRES]: 'P',
    [PDK]: 'MPC',
    [SUM]: 'Sum',
};

const links = 'and use special home appliances for <a target="_blank" href="https://tion.ru/product/breezer/">ventilation</a> and <a target="_blank" href="https://tion.ru/product/clever/">air cleaning</a> inside the room.';
export const AQI_TEXTS = [
    ['No data', 'No data'],
    ['Excellent', 'Air is absolutely safe.'],
    ['Good', `Air is safe for most people.`],
    ['Normal', `Very sensitive to air quality persons (e.g., allergic people) are recommended to limit their outdoor time.`],
    ['Caution is required', `Air can be harmful to allergy sufferers, children, seniors, and people with lung or heart disease. It is recommended to limit their outdoor time.`],
    ['Unsafe for allergy sufferers', `Air is harmful to allergy sufferers, children, seniors, and people with lung or heart disease. It is recommended to avoid being outdoors.`],
    ['Unsafe', `
        Air may be harmful for health. It is recommended to limit the outdoor time ${links}`],
    ['Harmful', `Air is polluted and harmful for health. It is recommended to avoid being outdoors ${links}`],
    ['Very harmful', `
        High level of air pollution. It is recommended to avoid being outdoors
        and use protective equipment while being outdoors${links}
    `],
    ['Dangerous', `
        High level of air pollution. Symptoms of intoxication may arise.
        It is recommended to avoid being outdoors and use protective equipment while being outdoors ${links}
    `],
    ['Critical', `
        Extremely high level of air pollution. It is recommended to refrain from being outdoors ${links}
    `]
];

export const AQI_THREE_TEXTS = ['Safe', 'Satisfactory', 'Dangerous'];
export const AQI_THREE_TEXTS2 = ['Clean air', 'Average amount of impurities', 'Great amount of impurities'];

export const TOOLTIPS = {
    ...NAMES,
    [AQI]: 'Air quality index - Air Quality Index',
    [PM2]: 'Particulate Matter - particles with a diameter of 2.5 µm or less',
    [PM10]: 'Particulate Matter - particles with a diameter of 10 µm or less',
    [CO2]: 'Carbon dioxide',
    [TEMP]: 'Temperature',
    [HUM]: 'Relative Humidity - relative humidity',
    [PRES]: 'Pressure',
    [HUM]: 'Humidity'
};

export const TOOLTIPS_MONITOR = {
    [AQI]: ' - air quality index',
    [PM2]: ' - particles with a diameter of 2.5 µm or less',
    [PM10]: '- particles with a diameter from 2.5 to 10 µm'
};
export const PM_DESCRIPTION = 'PM (Particulate matter) is a mixture of particles of dust, ash, soot, as well as sulfates and nitrates suspended in the air. If you noticed a smog in the center of a major metropolis, it was exactly the mixture of PM2.5 and PM10 particles';

export const LAST_DATA_AGO = ['Last data update ', ' hrs ago'];

export const COMMON = {
    actions: 'Actions',
    today: 'today',
    yesterday: 'yesterday',
    error: 'Error',
    apply: 'Apply',
    save: 'Save',
    doNotSave: 'Don’t save',
    cancel: 'Cancel',
    close: 'Close',
    closeChart: 'Close chart',
    delete: 'Delete',
    gas: 'Gases',
    more: 'More',
    back: 'Back',
    placeholderDate1: 'Start date of the period',
    placeholderDate2: 'End date of the period',
    browserNotSupport: 'Your browser is not supported',
    byTheCity: 'by ',
    pm2: 'PM2.5',
    pm10: 'PM10',
    cities: 'Cities',
    city: 'City',
    location: 'Location',
    allWorld: 'Worldwide',
    allCities: 'All cities',
    airQuality: 'Air Quality.',
    pdkMr: '',
    pdkSs: '',
    logOut: 'Sign out',
    noGroupPermission: 'You don\'t  have access to this monitoring network'
};

export const TIME_NAMES = {
    month: 'Month',
    week: 'Week',
    days3: '72 hours',
    watches24: '24 hours',
    min5: '5 min',
    min20: '20 min',
    hour1: '1 hour',
    day1: '1 day'
};

export const STATION_BLOCK = {
    returnScale: 'Reset scale',
    dataOnPollutants: 'Air Pollution/Environmental Data',
    experimentalStation: 'CityAir experimental station',
    addToComparison: 'Compare',
    compare: 'Comparison mode',
    endCompare: 'Finish  comparison',
    type: {
        mo: 'CityAir Device',
        myMo: 'CityAir Device',
        OpenAQ: 'OpenAQ',
        city: ''
    },
    noData: 'No data available for the selected time interval',
    averageCity: 'Сity average',
    add: 'Add',
    select: 'Select'
};

export const POPUP_TEXT = {
    title: `Welcome to&nbsp;AirVoice`,
    description: `We&nbsp;share real-time data on&nbsp;urban air quality. Our goal is&nbsp;to&nbsp;digitize air quality around the World and to&nbsp;make this information available and accessible to&nbsp;everyone.`,
    btn: `Get started`,
    footer: `The data on&nbsp;this website is&nbsp;preliminary and unofficial. We&nbsp;make every effort to&nbsp;ensure the validity and integrity of&nbsp;the data, but it&nbsp;can be&nbsp;affected by&nbsp;equipment malfunctions, technical difficulties, and other unforeseen circumstances. By&nbsp;clicking &laquo;Get started&raquo;, you agree to&nbsp;the `,
    footerEnd: `terms of use of the data`,
    note: `Don&rsquo;t show again`,
    back: `Back`,
    close: COMMON.close,
    docTitle: `Data Terms of&nbsp;Use`,
    docDescription1: `The service operates fully automatically based on&nbsp;the data obtained from experimental equipment for research purposes. All the information presented on&nbsp;this website is&nbsp;intended solely for private use and may not be&nbsp;used to&nbsp;make decisions involving material risks and/or risks to&nbsp;the lives and health of&nbsp;people.`,
    docDescription2: `The website administration is&nbsp;not responsible for possible damage caused by&nbsp;the use of&nbsp;the information posted on&nbsp;this website. Measurement data may not be&nbsp;used as&nbsp;evidence in&nbsp;court or&nbsp;in&nbsp;resolving disputes between citizens and business entities. Weather forecasts and air pollution data posted on&nbsp;the website are intended only for the purpose of&nbsp;advising private persons.`,
    docDescription3: `For official inquiries, please contact the local
        &nbsp;<a class="window_popup__link"  target="_blank" href="http://www.meteorf.ru/">Roshydromet</a>&nbsp;authorities,<a class="window_popup__link"  target="_blank" href="http://www.meteoinfo.ru/"> the&nbsp;Hydrometeorological Center of Russia</a>
        or a certified &nbsp;<a class="window_popup__link"  target="_blank" href="http://www.academlab.ru/">Academlab</a>&nbsp; laboratory in Novosibirsk.`,
};

export const TEXT_DESCRIPTION = {
    infoBlock: ['Temperature', 'Humidity', 'Pressure'],
    airQualityTitle: 'Air Quality Index',
    airQuality: `The air quality is evaluated<br>
                according to the Air Quality Index:<br>
                from 1 (low risk) to 10 (very high risk)`
}

export const CHART_OPTIONS = {
    lang: {
        loading: 'Loading...',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortMonths: ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        exportButtonTitle: 'Export',
        contextButtonTitle: 'Export',
        printButtonTitle: 'Print',
        rangeSelectorFrom: 'From',
        rangeSelectorTo: 'to',
        rangeSelectorZoom: 'Period',
        downloadPNG: 'Download in PNG',
        downloadJPEG: 'Download in JPEG',
        downloadPDF: 'Download in PDF',
        downloadSVG: 'Download in SVG',
        printChart: 'Print the chart',
        fullscreen: 'Fullscreen',
        resetZoom: 'Reset scale',
        resetZoomTitle: 'Reset the chart scale to 100%'
    }
};

export const MARKET = {
    text: 'The public map is now available in the new app',
    airvoice: 'AirVoice',
    linkApple: 'https://apps.apple.com/app/airvoice/id1484315084',
    linkGoogle: 'https://play.google.com/store/apps/details?id=com.cityair.airvoice',
};

export const WEEKDAY = {
    mo: 'Mo',
    tu: 'Tu',
    we: 'We',
    th: 'Th',
    fr: 'Fr',
    sa: 'Sa',
    su: 'Su'
};

export const DOWNLOAD_POPUP = {
    title: 'Primary data',
    period: 'Select date range',
    all: 'All monitoring stations',
    all_devices: 'All Device',
    several: 'Selected monitoring stations',
    several_devices: 'Selected devices',
    select: 'Select monitoring stations',
    select_devices: 'Select devices',
    select_type: 'Select format',
    selected: 'Selected items: ',
    download: 'Download',
    dateRange: 'Data interval',
    unitsMeasurements: 'Units of measurement',
    error14day: 'Time interval for download may not exceed 14 days at once',

    filenameDataMO: 'AQM_data',
    filenameDataDevice: 'device_data',
    filenameDiagnostic: 'device_diagnostic_data',
    filenameReport: 'AQM_report_TZA4',
    error: 'Sorry, something went wrong. Please try again later or contact our customer service.'
};

export const GMT_VALUE = [
    [-12, '(GMT -12:00) Eniwetok, Kwajalein'],
    [-11, '(GMT -11:00) Midway Island, Samoa'],
    [-10, '(GMT -10:00) Hawaii'],
    [-9, '(GMT -9:00) Alaska'],
    [-8, '(GMT -8:00) Pacific Time (US &amp; Canada)'],
    [-7, '(GMT -7:00) Mountain Time (US &amp; Canada)'],
    [-6, '(GMT -6:00) Central Time (US &amp; Canada), Mexico City'],
    [-5, '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima'],
    [-4, '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'],
    [-3, '(GMT -3:00) Brazil, Buenos Aires, Georgetown'],
    [-2, '(GMT -2:00) Mid-Atlantic'],
    [-1, '(GMT -1:00 hour) Azores, Cape Verde Islands'],
    [0, '(GMT) Western Europe Time, London, Lisbon, Casablanca'],
    [1, '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris'],
    [2, '(GMT +2:00) Kaliningrad, South Africa'],
    [3, '(GMT +3:00) Moscow, Saint Petersburg'],
    [4, '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'],
    [5, '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'],
    [6, '(GMT +6:00) Almaty, Dhaka, Colombo'],
    [7, '(GMT +7:00) Novosibirsk, Krasnoyarsk, Kemerovo'],
    [8, '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'],
    [9, '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'],
    [10, '(GMT +10:00) Eastern Australia, Guam, Vladivostok'],
    [11, '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'],
    [12, '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka']
];

export const PROFILE = {
    network: 'Monitoring Network',
    post: {
        zero: 'monitoring stations',
        one: 'monitoring station',
        two: 'monitoring stations',
        few: 'monitoring stations',
        many: 'monitoring stations',
        other: 'monitoring stations'
    },
    support: 'Support',
    phone: ['+44 (0)20 8089 9027', '+44&nbsp;(0)20&nbsp;8089&nbsp;9027'],
    email: 'info@cityair.io',
    c: 'CityAir. All rights reserved',
};

export const ADMIN_PANEL = {
    listDevices: 'Service',
    listOM: 'Monitoring stations',
    dashboard: 'Dashboard',
    OM: 'Posts',
    eventList: 'Event List.<br>Page under construction',
    notlistDevices: '.<br>Device list is empty',
    notlistOM: '.<br>Monitoring stations list is empty',
    notlistUsers: '.<br>User list is empty',
    noteventList: '',
    notcityCard: '',
    notcoefficientView: '',
    coefficientView: 'Calibration factors',
    listUsers: 'Users',
    configPanel: 'Settings',
    cityCard: 'Analytics',
    plumes: 'Plumes',
    indoor: 'Indoor',
    notindoor: '',
    buttonAddMO: 'Add new monitoring station',
    buttonAddUser: 'Add new user',
    notifications: 'Notification center',
    notnotifications: '',
    postsAndDevices: 'Networks',
    back: 'Back'
};

export const ANALYTICS_PAGE = {
    searchPlaceholder: 'Search city or settlement'
};

export const SERVICE_PAGE = {
    searchPlaceholder: 'Search monitoring stations or devices',
    monitoringObjects: 'Monitoring stations',
    devices: 'Devices'
};

export const LIST_OM = {
    objects: 'Monitoring Network',
    objectsView: 'Monitoring station',
    filterForName: 'Filter by name',
    notPublic: 'Not Publish',
    public: 'Publish',
    offline: 'Offline',
    online: 'Online',
    clearFilter: 'Clear All Filters',
    countStation: 'Count',
    owner: 'Owner',
    operator: 'Operator',
    observer: 'Observer',
    observer_at: 'AQMS',
    selectOm: 'Click to assign Monitoring stations <br> to this user',
    changesSaved: 'Changes saved successfully',
    checkAll: 'Select all',
    uncheckAll: 'Uncheck all',
    noRole: 'No access',
    worked: 'Nominal operating mode',
    notWorked: 'Offline',
    batWorked: 'On battery',
    batLow: 'Low battery',
    noData: 'No data',
    descriptionSection: `This tab shows the list of your monitoring stations (map locations).<br>Each monitoring station includes one or more devices such as CityAir monitoring<br>stations and trace gas measurement extensions.`,
    noDevices: 'No connected devices',
    noPosts: 'No Monitoring stations available',
    noFound: 'No Monitoring stations found',
    noFoundDescription: 'Your query returned no results, try searching with different keywords',
};

export const POPUP_DELETE: StndAskPopupTexts = {
    title: 'Delete monitoring station',
    body: 'Do you really want to delete this monitoring station?',
    cancel: COMMON.cancel,
    cancelNew: COMMON.cancel,
    accept: COMMON.delete
};

export const POPUP_DELETE_USER: StndAskPopupTexts = {
    title: 'Delete user',
    body: 'Do you really want to delete this user?',
    cancel: COMMON.cancel,
    accept: COMMON.delete
};

export const POPUP_THREE_QUESTIONS: StndAskPopupTexts = {
    title: 'You have unsaved changes',
    body: 'Вы действительно хотите покинуть окно редактирования? Внесенные изменения не сохранятся',
    accept: 'Save the settings',
    cancel: 'Continue editing',
    extraAction: COMMON.doNotSave
};

export const EDIT_STATION = {
    accommodationObjects: 'Monitoring stations',
    edit: 'Edit',
    delete: COMMON.delete,
    editing: 'Edit Station Properties',
    name: 'Name',
    public: 'Publish',
    publicName: 'Display Map',
    coordinates: 'Coordinates',
    description: 'Description',
    timezone: 'Time Zone',
    saveData: 'Record Data',
    downloadXLSData: 'Download Data',
    downloadReport: 'Download Report',
    documentation: 'Documentation',
    guideOperationName: 'Manual.pdf',
    guideOperationLink: '/docs/en/CityAir_User_Manual_Monitoring_System.pdf',
    viewSite: 'Read Online',
    stationScheme: 'Station Design.pdf',
    cancel: COMMON.cancel,
    save: COMMON.save,
    technicalInfo: 'Technical Specifications',
    model: 'Model',
    versionH: 'Hardware Version',
    versionS: 'Software Version',
    startTimeStation: 'Station Start Time',
    lastTimeStation: 'Last Packet Time',
    scheduledMaintenance: 'Next Service Date',
    linkSpecification: '/docs/Station Design.pdf',
    textareaPlaceholderAdmin: 'Description. Press “Edit” to add a description.',
    textareaPlaceholder: 'Description.',
    openChart: 'Show plot',
    error: 'Could not complete the update. Please contact support',
    listDevices: 'Device',
    partOf: 'Is part of the following monitoring stations'
};

export const LIST_DEVICES = {
    selectDevice: 'Select device',
    selectDevices: 'Select devices',
    info: 'Information',
    name: 'Name',
    lat: 'Latitude',
    lng: 'Longitude',
    timezone: 'Timezone',
    location: 'Location',
    listDevices: 'Device List',
    startDate: 'Install date',
    service: 'Service Date',
    descriptionSection: `This tab shows the list of monitoring equipment available<br>to you: CityAir monitoring stations, trace gas measurement<br>extensions G1/G2, and other equipment`,
    connected: 'Monitoring stations',
    notConnected: 'Station is not attached to a monitoring station',
    loadPrimaryData: 'Raw data download',
    loadReport: 'Report download',
    loadLog: 'Health log',
    paramDeviceWork: 'Device Parameters',
    interval: 'Sampling interval',
    saveData: 'Recording status',
    savingData: 'Recording measurements',
    off: 'off',
    on: 'on',
    min: ' min',
    serialNumber: 'Serial number',
    type: 'Type',
    online: 'online',
    offline: 'offline',
    condition: 'Condition',
    relatedDevices: 'Related devices',
    since: 'since',
    noDevices: 'No devices available',
    noFound: 'No devices found',
    equipmentNotAttached: `Equipment&nbsp;is&nbsp;not&nbsp;assigned&nbsp;to&nbsp;object`,
    noConnectedDevices: `No&nbsp;connected&nbsp;devices`
};

export const USER_ACCESS_TYPE = ['No access', 'Administrator', 'Operator', 'Observer', 'Service'];

export const LIST_USERS = {
    listUsers: 'Users',
    roleUser: 'User roles',
    deleteUser: 'Remove user',
    addUsers: 'Add user',
    editUsers: 'Edit User',
    login: 'Login',
    mail: 'e-mail',
    emailInGroup: 'This email is&nbsp;used in&nbsp;the current monitoring network',
    incorrectEmail: 'Incorrect email',
    invalidLogin: 'Incorrect login',
    access: 'Access',
    user: 'User',
    add: 'Add',
    create: 'Create',
    operator: `Operators can see the data and manage monitoring stations`,
    observer: `Observers can only see the data from the monitoring stations available to&nbsp;them`,
    noSelect: 'Not selected',
    accessSelect: 'Access level',
    whenSaving: 'Changes saved',
    whenAdd: 'User added',
    whenCreate: 'Account activation instructions will be sent to specified email address'
};

export const CREATE_OM = {
    createOm: 'New monitoring station',
    editOm: 'Edit monitoring station',
    users: 'Users',
    operators: 'Operators',
    observers: 'Observers',
    descriptionSection: 'You can configure a new monitoring station<br>based on your device list in this tab.'
};

export const LANDINGS = {
    info: '',
    PM10: '(particulates less than 10 um)',
    PM2: '(particulates less than 2.5 um)',
    HISTOGRAM_AQI: `air quality last month`,
    last: 'last 3 days'
};


export const SHARING = {
    title: 'Share a link',
    description: 'This link provides access to all current map parameters: geolocation, time, monitoring stations.',
    toWhom: 'To whom:',
    copy: 'Copy',
    send: 'Send'
};

export const MODELS = {
    accommodationObjects: 'Monitoring stations',
    simulation: 'Simulation',
    info: `Attention: mathematical simulation works in experimental mode`,
    info2: `This means that now we are scrupulously comparing the results <br> of the mathematical simulation with the data obtained from CityAir stations in this region. <br>
        A bit later, the accuracy of the calculations will become close to the real air pollution indexes`,
    noData: 'No mathematical simulation for this area at the moment',
    title: 'Mathematical simulation results',
    overlay: 'Heatmap',
    contours: 'Contour',
    off: 'Off',
    on: 'On',
    border: 'Simulation border'
};

export const CITY_CARD = {
    intro: [
        'From the creators of AirVoice',
        'We are happy to share knowledge about air quality in different cities of the world',
        'This map is provided with data from the CityAir global platform',
        'Monitoring networks'
    ],
    backBtnCityScreen: 'Back to the list',
    backBtn: ['Back to the list of the cities', 'Back to the World Map'],
    openCity: 'Look into the city',
    goOver: 'Look into',
    close: 'close',
    tooltip: [
        'Air quality index ranges from 1 (very clean air) <br> to 10 (extremely polluted air)',
        'AQI Distribution',
        'Average AQI value within 24 hrs (entire observations history)',
        'Average AQI value by days of the week (entire observations history)'
    ],
    since2: ['from ', ' to date'],
    nowInCity: 'Now in the city',
    tabs: ['Monitoring stations', 'City profile'],
    sorting: ['Cities', 'Stations'],
    cityProfile: 'Ecological profile',
    seasons: ['Year', 'Winter', 'Spring', 'Summer', 'Autumn'],
    charts: [
        'The history of air quality observations',
        'Average air quality within 24 hrs',
        'Average air quality by days of the week'
    ],
    noData: 'No data available for the selected time interval',
    dataSources: 'Data sources',
    noDataSources: 'No data sources',
    noConnectedDevices: 'No connected devices'
};

export const COEFFICIENT_VIEW = {
    title: 'Calibration factors',
    content: [
        `The dust calibration of the CityAir analyzer is based on the results of collocated measurements used to  determine two factors, PCF (Photometric Calibration Factor) and SCF (Size Calibration Factor). It is recommended to use gravimetric measurements with selective impactors or an  equivalent method as a reference.`,
        `The calibration  coefficients are determined in such a manner that the following relations are true:`,
        `PM2.5<sup>Ref</sup> = PCF ⋅ PM2.5<sup>CityAir</sup>`,
        `PM10<sup>Ref</sup> = SCF<sup>3</sup> ⋅ PM10<sup>CityAir</sup> + (PCF - SCF<sup>3</sup> ) ⋅ PM2.5<sup>CityAir</sup>`,
        `where  PM<sup>Ref</sup> are the results of determining the concentration of suspended  particles by the gravimetric method with selective impactors for PM2.5 and PM10 respectively and PM<sup>CityAir</sup> are the results from the CityAir analyzer for the same period of time.`,
    ],
    current: 'Current PCF:',
    new: ['Set a new factor:', 'PCF', 'Enter value'],
    scf: 'Current SCF<sup>3</sup>:',
    scfComment: 'SCF can only be adjusted when synchronous gravimetric measurements of both PM2.5 and PM10 are available.',
    description: 'The calibration factors apply  only to the data accessible via the monitoring stations. The primary data received from the monitoring devices remains unaltered. You can download the primary data from your user cabinet or via the API.',
    popup: ['You are about to change the calibration factors', 'New PCF:'],
    errorPcf: 'To set PCF outside the common calibration range, please contact CityAir Service'
};

export const QUALITY_CURRENT = [
    [
        'Breathe deeply, the air is&nbsp;clear',
        'Throw open the windows!',
        'Good conditions for outdoor sports!',
    ],

    [
        'Not bad. But be&nbsp;cautious!',
        'You may take a&nbsp;walk. But not a&nbsp;long one',
    ],
    [
        'Danger! Air is&nbsp;dirty!',
        'Be&nbsp;careful, to&nbsp;stay outside may be&nbsp;unsafe',
        'We&nbsp;do&nbsp;not recommend going out',
        'The darkness that came from the Mediterranean Sea covered the city hated by&nbsp;the procurator&hellip;',
        'Can&rsquo;t lean on&nbsp;this air yet. Won&rsquo;t see a&nbsp;pint in&nbsp;your hand though',
    ]
];

export const QUALITY_FORECAST = {
    'now<last': [
        ['Gonna be better'],
        ['Gonna be better'],
        ['Gonna be better'],
    ],
    'now=last': [
        ['Gonna be the same'],
        ['Gonna be the same'],
        ['Gonna be the same'],
    ],
    'now>last': [
        ['Gonna be worse'],
        ['Gonna be worse'],
        ['Gonna be worse'],
    ]
};

export const LOGIN_WINDOWS = {
    welcome: `Welcome to&nbsp;CityScreen`,
    toolsFor: 'Tools for configuring your monitoring network',
    enterNewPass: 'Enter new password below',
    newPass: 'New password',
    confNewPass: 'Confirm new password',
    wrongNewPass: 'Password contains characters that cannot be used',
    passwordsDontMatch: 'These passwords don\'t match',
    warningNewPass: `<strong>Hint</strong>: the password should be&nbsp;at&nbsp;least seven characters long. To&nbsp;make it&nbsp;stronger, use upper and lower case letters, numbers, and symbols.`,
    resetPass: 'Reset password',
    techSup: 'Tech support',
    passLevel: ['', 'too weak', 'medium', 'medium', 'strong', 'strong'],
    wrong: 'Wrong login or password',
    wrongMail: 'Wrong email',
    wrongCode: 'Wrong verification code',
    cityscreenSignIn: 'Sign in to CityScreen',
    login: 'Login',
    logout: 'Logout',
    password: 'Password',
    forgot: 'Forgot password?',
    sign: 'Sign in',
    forgotYourPass: 'Forgot Your Password?',
    pleaseConfirm: `Please, confirm your email address below and we&nbsp;will send you a verification code`,
    emailAddress: 'Email Address',
    sendReset: 'Next',
    backToSign: 'Back to Sign In',
    language: 'Language',
    en: 'English',
    ru: 'Russian',
    passUpd: 'Password Updated',
    yourPassHasBeen: `Your password has been changed successfully. Use your new password to&nbsp;sign&nbsp;in`,
    check: 'Check Your Email',
    pleaseChk: `Please, check the email for instructions to&nbsp;reset your password`,
    info: `+44 (0)20 8089 9027<br>info@cityair.io`,
    licenseStr1: 'License of&nbsp;Federal Service for Hydrometeorology and Environmental Monitoring of&nbsp;Russia',
    licenseStr2: '№1692822 Р/2019/3773/100/Л as of 26.04.2019',
    licenseStr3: `©${new Date().getFullYear()} CityAir. All rights reserved`,
    resetPassTitle: 'Reset Password',
    weHaveSent: `We&nbsp;have sent you a&nbsp;verification code, please check your email address`,
    verCode: 'Verification code',
    next: 'Next',

    mobileIsNotAvailable: 'Mobile version is not available',
    suggestionForMobileClients: 'Please use CityScreen on your laptop or desktop computer',
    suggestionForMobileClientsNoBranding: 'Please switch to your laptop or desktop computer',

    toolsFor_sakhalin: 'Ministry of Ecology of Sakhalin Region',
    clientSignIn: 'Sign in to your account',

    errorLink: 'Invite link has expired',

    unrecognizedError: 'Unrecognized error'
};

export const LOGIN_NORNICKEL = {
    contactSupport: 'Support',
    supportTel: '8 (800) 600-78-08',
    supportAvailable: 'Mon-Fri 9:00 a.m. to 6:00 p.m. (MSK)',
    contactFeedback: 'Leave feedback at',
    feedbackEmail: 'feedback@mail.city.online',
    contactHelp: 'Help',
    helpEmail: 'help@mail.city.online',
    copyright: `© 2008-${new Date().getFullYear()} Norilsk Nickel`
};

export const NOTIFICATIONS = {
    allMonitoringPostsSelected: 'All stations are selected',
    accommodationObjects: 'Monitoring stations',
    back: 'Back',
    settingNotification: 'Manage notifications',
    eventList: 'Event feed',
    eventsNotFound: 'Events not found',
    eventsNotFoundHint: 'Try to use other key words or clear<br /> currently applied filters',
    all: 'All',
    measures: 'Measurements',
    service: 'Service',
    forecasts: 'Forecast',
    plumes: 'Plumes',
    earlier: 'Earlier events',
    collapse: 'collapse',
    searchResultEmpty: 'Matches not found',
    subscribeSuggestion: 'Configure notifications',
    eventsHelpMessageMeasurements: 'Set up alerts for measurements to keep up with the important events',
    eventsHelpMessageService: 'Set up service alerts to keep up with the important events',
    addSubscription: 'Add notifications',
    activeSubscriptions: 'Active notifications',
    addSubscriptionHint: 'Click “Add notifications” in order to set up new notifications',
    measuresDescription1: 'Get alerts about concentrations above specified level at select monitoring stations',
    measuresDescription2: 'Press “Save” if supplied default settings below are satisfactory',
    measuresDescription3: 'If you want to change the suggested notification settings, press “Edit”',
    serviceDescription1: 'Receive notifications about hardware failures and other service information',
    serviceDescription2: 'Press “Save” if you like default settings',
    serviceDescription3: 'If you want to change default notification settings, press “Edit”',
    serviceHelpText: 'If you want to unsubscribe from notifications, remove your email from the notification settings',
    emailListInputLabel: 'Email (you can enter several emails separated by commas)',
    noEventsAvailable: 'No events yet',
    moNotFoundOrDeleted: 'Station not found or deleted',
    subscriptionsPdkTooltip: {
        title: 'ПДКмр — максимальная разовая предельно&nbsp;допустимая концентрация',
        description: 'В соответствии с РД 52.04.667-2005',
        text: [
            'от 0 до 1 ПДК — низкое загрязнение',
            'от 2 до 4 ПДК — повышенное загрязнение',
            'от 5 до 10 ПДК — высокое загрязнение',
            'более 10 ПДК — очень высокое'
        ]
    },

    allPostMeasure: 'All stations. Measurements',
    allPost: 'all stations',
    allStation: 'all devices',
    parameterForNotif: 'Notification values',
    levelExcess: 'Excess level',
    recipients: 'Recipients',
    edit: 'Edit',
    save: COMMON.save,
    cancel: COMMON.cancel,
    deleteSubscription: 'Delete notifications',
    addToEventFeed: 'Show in event feed',
    removeFromEventFeed: 'Remove from event feed',
    newSubscription: 'New notification',
    editSubscription: 'Edit notifications',
    activateSubscription: 'Activate notifications',
    deactivateSubscription: 'Deactivate notifications',
    titleSubscription: 'Notification name',
    substances: 'Values',
    selectPosts: 'Select stations from the list or on the map',
    selectPostsSmall: 'Select stations',
    selectAll: 'Select all',
    add: 'Add',
    controlledSubstances: 'Values to watch',
    excessLevel: 'The level of excess, starting from which you will receive notifications',
    advancedSetup: 'Advanced setup',
    getToBasicSetup: 'Return to the basic setup',
    moreThan: 'more than',
    showMore: 'more',
    selected: {
        zero: 'Selected',
        one: 'Selected',
        two: 'Selected',
        few: 'Selected',
        many: 'Selected',
        other: 'Selected'
    },
    selectedStation: {
        zero: 'Selected',
        one: 'Selected',
        two: 'Selected',
        few: 'Selected',
        many: 'Selected',
        other: 'Selected'
    },
    noneSelected: 'Stations are not selected',
    post: {
        zero: 'stations',
        one: 'station',
        two: 'stations',
        few: 'stations',
        many: 'stations',
        other: 'stations'
    },
    station: {
        zero: 'devices',
        one: 'device',
        two: 'devices',
        few: 'devices',
        many: 'devices',
        other: 'devices'
    },
    setManually: 'Set manually (μg/m3)',
    notifyPeriodically: 'Notify about each measurement interval (20 min)',
    notifyEvent: 'Notify only about the start and end of the event',
    Slack: 'Slack',
    SMS: 'SMS',
    Email: 'Email',
    specifyFew: 'Specify separating by comma',
    dailyReport: 'Daily reports',
    whenSaving: '* Saving this will trigger sending of the text message',
    emailsNotSpecified: 'not specified',
    searchMoPlaceholderText: 'Search monitoring station',
    stationSelectionPrompt: 'Select devices',
    postsFilterPlaceholder: 'Find stations by name',
    serviceNotifications: 'Service notifications',
    notificationsFromEquipment: 'Notification settings',
    lackOfData: 'Absence of data for more than',
    lackOfPower: 'Shut down for more than',
    verificationExpires: 'Verification expires in',
    timeIntervalHours: {
        zero: 'hours',
        one: 'hour',
        two: 'hours',
        few: 'hours',
        many: 'hours',
        other: 'hours'
    },
    timeIntervalDays: {
        zero: 'days',
        one: 'day',
        two: 'days',
        few: 'days',
        many: 'days',
        other: 'days'
    },
    locationSelection: 'Location selection',
    point: 'Point',
    region: 'Region',

    statusActive: 'active',
    statusDeactivated: 'deactivated',
    statusActive2: 'Активное',
    statusDeactivated2: 'Остановлено',
    excessLevelUnits: 'MPCsc',
    qmax: 'q<sub>М</sub>',

    errMsgTitleRequired: 'Title must be filled out',
    errMsgValueMustBeGreaterThanZero: 'Value must be greater than zero',
    errMsgValueMustBeIntegerNumber: 'Integer value must be specified',
    errMsgIncorrectEmails: 'Incorrect emails list format',
    errMsgMissingEmail: 'At least one email address must be specified',

    error: 'Error',

    tooltipEventIcon: {
        measurements: 'Measurement notifications',
        service: 'Service notifications'
    },

    tooltipEventTitle: 'Set notification',

    tooltipSettingsHintValues: 'You can set the absolute maximum permissible concentration (MPC) for the selected parameters',

    tooltipSettingsHintDailyReport: 'All information about excess concentrations<br> in the daily report (detail: 20 min)',
    tooltipDeviceDailyReport: 'Daily report on device status updates',

    tooltipDurationLabel: ['Continue', 'Ended'],

    duration: 'duration',

    popupDelete: {
        title: 'You are to delete the notification. Please, confirm',
        body: 'When you delete the notification, the entire history of excess concentrations will be cleared. Before that you can save it to your device (see notification settings)',
        cancel: COMMON.cancel,
        accept: COMMON.delete
    },

    popupDownloadFeetHistory: {
        title: 'Download history',
        body: 'Select the period of history to download',
        cancel: COMMON.cancel,
        accept: 'Download',
        period: 'Specify the period'
    },

    durationDescription: {
        begin: 'Began',
        continue: 'Continues',
        expect: 'Expecting'
    },

    notificationAlertLabel: {
        pdk: ['', 'excess', 'persisting', 'back to normal'],
        nodata: ['', 'no data', 'no data', 'data is available'],
        nopower: ['', 'no power', 'no power', 'power is on']
    },

    alertTag: {
        endOfExcess: 'Окончание превышения',
        excess: 'Excess',
        thereIsNoData: 'No data',
        dataTransferRestored: 'Передача данных воcстановлена',
        noPowerSupply: 'Нет питания',
        powerRestored: 'Питание восстановлено',
        online: 'Online',
        offline: 'Offline',
        onBattery: 'On battery',
        onNetworks: 'Connected'
    },

    statusTexts: {
        pdk: ['Concentration exceeded', 'All concentrations are back to normal'],
        nodata: ['No data available for more than', 'Data transmission is restored'],
        nopower: ['No power supply for more than ', 'Power supply has been restored']
    },

    showFirstEvent: 'show the beginning',
    eventsFilterPrompt: 'Notification name, device, station or parameter'
};

export const CONFIG = {
    title: 'Settings',
    coeff: 'Calibration factors',
    token: 'API access keys',
    history: 'Activity history',
    notifications: 'Notifications',

    coeffTooltip: `Local calibration factor changes only the data, which forms<br>
 the graphs from the monitoring stations. Primary data,  received <br>
 from the monitoring devices remains unchanged and is available<br>
  for downloading in your personal account and through the API`,

    tokenTooltip: `Unique identifiers (tokens) are designed to facilitate <br>
interaction with the servers of the CityScreen platform <br>
without using a Web interface, i.e. through the API`,

    historyTooltip: `The activity history shows information about the history<br>
 of logins to your personal account. If you suspect that someone<br>
  has gained unwanted access to your account, we recommend<br>
  to change your password and contact the security service`,

    browserTokenItems: ['Last use:', 'Date of creation:', 'Number of requests:'],
    closeToken: 'End session',
    activeToken: 'Current session',
    closedToken: 'Completed session',
    closeAll: 'Close all active sessions',

    active: 'active',

    rename: 'Rename',
    close: 'Delete',
    countTokens: 'Active keys',
    create: 'Create a key',

    titlePopupCreate: 'Create a key',
    titlePopupClose: 'Are you sure you want to permanently delete this API key?',
    titlePopupRename: 'Rename the key',

    applyCreate: 'Create',
    applyClose: 'Delete',
    applyRename: 'Rename',

    closeDescription: 'Key: ',
    createDescription: 'Set API key name',
    copySuccess: 'API key copied',
    copy: 'Copy',
};

export const PLUMES = {
    runResults: 'Run results',
    runConfiguration: 'Run сonfiguration',
    controlPoints: 'Control points',
    resultsAt: 'at',
    addControlPoint: 'Add control point',
    addSource: 'Add source',
    nowOnMap: 'Shown on map',
    noRunSelected: 'Please select a run',
    forecastOn: 'Forecast for',
    createControlPointTitle: 'Create Control Point',
    editControlPointTitle: 'Edit Control Point',
    editRunConfigurationTitle: 'Edit Run Configuration',
    latitude: 'Latitude',
    longitude: 'Longitude',
    title: 'Name',
    value: 'Value',
    runConfigurationName: 'Run configuration name',
    runConfigurationStatus: ['Running', 'Stopped'],
    interval: 'Interval',
    intervalUnits: 'min',
    editRunConfigurationAction: 'Edit run configuration',
    stopRunConfigurationAction: 'Deactivate run configuration',
    startRunConfigurationAction: 'Activate run configuration',
    sourcesListTitle: 'Sources',
    runInterval: 'Launch interval',
    day: 'Days',
    hour: 'Hours',
    min: 'Minutes',
    runIntervalDescription: 'Run will be launched',
    noRunsAvailable: 'Results not available yet',
    selectedPeriodNoRunsAvailable: 'No runs for the selected period',
    popupDelete: {
        title: 'Delete Control Point',
        body: 'Are you sure you want to delete control point “%s”?',
        cancel: COMMON.cancel,
        accept: COMMON.delete
    },
    timeIntervalHours: ['hour', 'hours', 'hours'],
    every: {
        zero: 'every',
        one: 'every',
        two: 'every',
        few: 'every',
        many: 'every',
        other: 'every'
    },
    previousRun: 'Previous run',
    nextRun: 'Next run'
};

export const DASHBOARD = {
    dashboardTitle: 'Monitoring Network Status',
    refreshData: 'Refresh',
    availabilityStatsTitle: 'Availability',
    monthlyStatsTitle: 'Statistics for',
    packetsMonitorTitle: 'Data from Devices',
    packets: {
        zero: 'packets',
        one: 'packet',
        two: 'packets',
        few: 'packets',
        many: 'packets',
        other: 'packets'
    },
    lowBattery: 'No Power',
    lowSignal: 'Low Signal',
    noPackets: 'No Data',
    showInMonitor: 'Show packets',
    dataTransmissionTitle: 'Data Transmission',
    dataTransmissionStatus: ['offline', 'online'],
    powerSupplyTitle: 'Power Supply',
    powerSupplyStatus: ['powered by battery', 'powered by power supply'],
    monthlyStatsChartTitle: 'Data from All Devices',
    dataReceived: 'Data Received',
    deviceMonitorLegend: {
        dataReceived: 'Received data',
        hasMissingData: 'Has missing data',
        poweredByBattery: 'Powered by battery',
        lowGMSSignal: 'Low GSM signal'
    },
    availabilityTooltipText: 'Last packet has been received <br/>at',
    monthlyStatsTooltipText: 'Overall ratio of the received data <br/>from all devices',
    selectedTimeIntervalLabel: 'Time interval',
    selectedDevicesLabel: 'Devices',
    columnLabels: {
        device: 'Device',
        monitoringObject: 'Location',
        status: 'Status',
        packetsRate: 'Packets received per hour'
    },
    searchDevicePlaceholderText: 'Search device',
    selectAllDevicesFilter: 'Select all',
    resetDevicesFilter: 'Reset filter',
    notSelectedStatusFilter: 'Not selected'
};

export const OVERLAY = {
    hotspotsTooltip: {
        title: 'VIIRS thermal hotspots',
        text: [
            'Red spots represent thermal anomalies including active fire detections, active volcanoes, gas flares and other hotspots. The map shows the combined data for the last 48&nbsp;hours.'
        ],
        legend: {
            current: 'current hotspots',
            previous: 'hotspots of the previous day'
        },
        note: 'We acknowledge the use of data and/or imagery from NASA\'s Fire Information for Resource Management System (FIRMS) (<a href="https://earthdata.nasa.gov/firms" target="_blank">https://earthdata.nasa.gov/firms</a>), part of NASA\'s Earth Observing System Data and Information System (EOSDIS).'
    },
    modellingTooltip: {
        title: 'Mathematical simulation results',
        text: [
            'This means that now we are scrupulously comparing the results of the mathematical simulation with the data obtained from CityAir stations in this region.',
            'A bit later, the accuracy of the calculations will become close to the real air pollution indexes.',
        ],
        note: 'Attention: mathematical simulation works in experimental mode',
        noteDisabled: 'No mathematical simulation for this area at the moment'
    }
};

export const POSTS_AND_DEVICES = {
    postAndDevices: 'Monitoring objects and devices',
    addPost: 'Add monitoring object',
    viewInformation: 'Read information',
    loadPrimaryData: 'Raw data download',
    downloadDataFromPost: 'Download',
    downloadDataFromPosts: 'Download',
    downloadTZA4Report: '',
    tza4: '',
    edit: 'Edit',
    replaceDevice: 'Replace device',
    deletePost: 'Remove monitoring object',
    dataDevice: 'Device data',
    noMonitoringPosts: 'No monitoring objects yet',
    noDevices: 'No devices',
    unfortunatelyNothing: 'Unfortunately there is nothing',
    searchPost: 'Find monitoring object, device, location',
    searchDevice: 'Find monitoring object, device',
    post: 'Monitoring object',
    posts: 'Monitoring objects',
    postsMonitoring: 'Monitoring object',
    location: 'Location',
    aqi: 'AQI',
    device: 'Device',
    devices: 'Devices',
    type: 'Type',
    state: 'Condition',
    nameOnMap: 'Name on map',
    coordinate: 'Coordinates',
    timeZone: 'Time Zone',
    dataSources: 'Data sources',
    actionHistory: 'History of activities',
    users: 'Users',
    documentation: 'Documentation',
    showMore: 'Show more',
    showLess: 'Show less',
    administrator: 'Administrator',
    operator: 'Operator',
    power: 'Power',
    dataTransfer: 'Data Transmission',
    info: 'Information',
    online: 'Online',
    offline: 'Offline',
    relatedDevices: 'Connected devices',
    name: 'Name',
    description: 'Description',
    locationOnTheMap: 'Location on map',
    createPost: 'Add new monitoring object',
    editPost: 'Edit monitoring object',
    newMO: 'New monitoring object',
    editMo: 'Edit monitoring object',
    dataFromPost: 'Data from monitoring objects',
    inthevalData: 'Data interval',
    units: 'Units of measurement',
    pightsOfPDK: '',
    loadingDataForThePrevious: 'Loading data from previous period may take some time',
    format: 'Format',
    allAdministratorsAndOperators: 'All administrators and operators have access to&nbsp;data from the monitoring post',
    asWellAsTheFollowing: 'as&nbsp;well as&nbsp;the following observers:',
    accessToData: 'Access to data from the Monitoring Post'
}

export const INDOOR = {
    [ROOM]: 'Room',
    selectRoom: 'Select a room to display information',
    iaq: 'IAQ - indoor air quality index',
    search: 'Search'
};

export const ANALYTICS_COMPONENT = {
    tooltip: (index: number, measure: string) => [
        `${measure} Distribution`,
        `Average ${measure} value within 24 hrs (entire observations history)`,
        `Average ${measure} value by days of the week (entire observations history)`
    ][index],
};
