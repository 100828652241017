import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { ChartsModule } from '../chart/charts.module';
import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { SortList } from './sort-list/sort-list.component';
import { PointOnlineComponent } from './point-online/point-online.component';
import { HeaderPageComponent } from './header-page/header-page.component';
import { StationCityListComponent } from './station-city-list/station-city-list.component';
import { ControlSelectComponent } from './control-select/control-select.component';
import { StringChoiceComponent } from './string-choice/string-choice.component';
import { SwitchComponent } from './switch/switch.component';


import {
    StationLoadingSplash,
    StndAskPopup,
    StndAskPopup2,
    StndAskPopupThreeQuestions,
    StndErrorPopup,
    StndPopup,
} from './blank'

import { Loader, LoaderMap, } from './imgs';

import {
    LinksMo,
    OnlineCircle,
    SelectMeasures,
    SelectMeasures_m,
    SelectMeasuresRadio,
} from './others';

import {
    ChartCompareMeasureIndicator,
    ChartCompareMeasureIndicator_m
} from './measures-indicators-new/chart.compare.measure.indicator';

import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { ArrowModule } from 'projects/shared/modules/icons/components/arrow/arrow.module';

import { MessageAPIResponse } from './message-api-response/message-api-response.component';
import { MessageAPIResponseService } from './message-api-response/message-api-response.service';
import { ButtonsSaveCancel } from './buttons-save-cancel/buttons-save-cancel';
import { SearchResultNotFound } from './search-result-not-found/search-result-not-found';
import { ListHeader } from './list-header/list-header.component';
import { Button } from './button/button.component';
import { SubstancesList } from './substances-list/substances-list.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { NavigationButtonComponent } from './navigation-button/navigation-button.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { CrossButtonModule } from 'projects/shared/modules/buttons/cross-button/cross-button.module';
import { SearchInputModule } from 'projects/shared/modules/form-components/search-input/search-input.module';
import { ItemRadioModule } from 'projects/cityscreen/src/components/item-radio/item-radio.module';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';
import { TagsListModule } from 'projects/cityscreen/src/components/tags-list/tags-list.module';
import { SwitchItemModule } from 'projects/cityscreen/src/components/switch-item/switch-item.module';
import { CheckboxModule } from 'projects/cityscreen/src/components/checkbox/checkbox.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        ChartsModule,
        TooltipsModule,
        LoadersModule,
        ArrowModule,
        CrossButtonModule,
        SearchInputModule,
        ItemRadioModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
    ],
    exports: [
        SelectMeasures_m,
        Loader,
        LoaderMap,
        StndPopup,
        StndErrorPopup,
        StndAskPopup,
        StndAskPopupThreeQuestions,
        StndAskPopup2,
        ChartCompareMeasureIndicator,
        ChartCompareMeasureIndicator_m,
        SelectMeasures,
        SelectMeasuresRadio,
        OnlineCircle,
        SortList,
        PointOnlineComponent,
        LinksMo,
        StationCityListComponent,
        StationLoadingSplash,
        MessageAPIResponse,
        ButtonsSaveCancel,
        SearchResultNotFound,
        HeaderPageComponent,
        ListHeader,
        ControlSelectComponent,
        StringChoiceComponent,
        Button,
        SubstancesList,
        NavigationButtonComponent,
        DropdownButtonComponent,
        SwitchComponent,
        ScrollTopComponent,
    ],
    providers: [
        MessageAPIResponseService,
    ],
    declarations: [
        SelectMeasures_m,
        Loader,
        LoaderMap,
        StndPopup,
        StndErrorPopup,
        StndAskPopup,
        StndAskPopupThreeQuestions,
        StndAskPopup2,
        ChartCompareMeasureIndicator,
        ChartCompareMeasureIndicator_m,
        SelectMeasures,
        SelectMeasuresRadio,
        OnlineCircle,
        SortList,
        PointOnlineComponent,
        LinksMo,
        StationCityListComponent,
        StationLoadingSplash,
        MessageAPIResponse,
        ButtonsSaveCancel,
        SearchResultNotFound,
        HeaderPageComponent,
        ListHeader,
        ControlSelectComponent,
        StringChoiceComponent,
        Button,
        SubstancesList,
        NavigationButtonComponent,
        DropdownButtonComponent,
        SwitchComponent,
        ScrollTopComponent,
    ]
})
export class LittleComponentsModule {}
