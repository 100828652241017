import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { isRU, TEXTS } from 'src/texts/texts';
import { AdminDevice, City_model, CITYAIR_STND_DEVICE, Marker_model, MonitoringObject } from 'src/namespace';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { PAGES } from '../../namespace';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { ANIMATION_OPACITY } from 'projects/shared/utils/config';
import {
    selectGroupCities,
    selectGroupInfo,
    selectMos,
    selectMyDevices
} from 'projects/cityscreen/src/modules/core/store/selectors';
import AdminPanelActions from 'projects/cityscreen/src/components/admin-panel/actions';
import { selectMo } from 'projects/cityscreen/src/modules/core/store/actions';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import State from 'map/state';
import { copyObj } from 'src/utils';

export class CardMo {
    id: number;
    name: string;
    city: string;
    serialNumber: string[];
    aqi: Marker_model;
    isActive: boolean;
    arrDeviceName: AdminDevice[];
    devicesId: number[];
    isConnectedDevices: boolean;
    isNoDataSources: boolean;
    lastConnectedDevice: AdminDevice;
}

export interface Devices {
    serialNumber: string[];
    arrDevicesObj: AdminDevice[];
    devicesId: number[];
    lastConnectedDevice: AdminDevice;
}

@Component({
    selector: 'posts-list',
    templateUrl: './posts-list.component.html',
    styleUrls: ['./posts-list.component.less', '../../posts-and-devices.common-styles.less'],
    animations: ANIMATION_OPACITY
})
export class PostsListComponent implements OnInit, OnDestroy {
    pages = PAGES;
    cardMo: CardMo[] = [];
    TEXTS = TEXTS;
    isShowMenu = false;
    isRU = isRU;
    isShowNotDate = false;
    isShowResultPlaceholder = false;
    menuPositionTop = 0;
    moObj: MonitoringObject[] = [];
    markerObj: Marker_model[] = []
    path = `${PAGES.networks}/${PAGES.postsList}/`;
    idTargetCard = '';
    nameTargetCard = '';
    searchQuery = '';
    isShowDeletePopup = false;
    isConnectedDevices = false;
    citeis: City_model[] = [];
    private popupOpenerElement: HTMLElement;

    selectMyDevices = selectMyDevices;
    selectGroupInfo = selectGroupInfo;
    subscriptions = [];

    @ViewChildren('MOCard', {read: ElementRef}) MOCard: QueryList<ElementRef>;
    @ViewChild('cardMenu', {static: true}) cardMenu: TemplateRef<HTMLDivElement>;

    constructor(
        public postsAndDevicesFacade: PostsAndDevicesFacade,
        public popupProvider: OffPanelPopupService,
        public apActions: AdminPanelActions,
        readonly globalState: State,
        public readonly sharedCoreFacade: SharedCoreFacade,
        public store: Store) {
    }

    ngOnInit() {
        this.getStore();
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    getStore() {
        // if (this.postsAndDevicesFacade.canShowDevices()) {
            const subscription1 = this.store.select(selectMos)
                .subscribe(mos => {
                    this.moObj = copyObj(mos);
                    if (this.moObj.length == 0) {
                        this.isShowNotDate = true;
                    } else {
                        this.isShowNotDate = false;
                    }
                })
        // } else {
        //     this.subscription2 = this.store.select(selectMarker)
        //         .pipe(filter(sm => !!sm.length))
        //         .subscribe(sm => {
        //             this.markerObj = copyObj(sm)
        //         })
        // }
        const subscription2 = this.store.select(selectGroupCities)
            .pipe(filter(citeis => !!citeis.length))
            .subscribe(citeis => {
                this.citeis = copyObj(citeis);
                this.formCardMo()
                this.isShowResultPlaceholder = true
            })

        this.subscriptions.push(subscription1);
        this.subscriptions.push(subscription2);
    }

    formCardMo() {
        this.cardMo = [];
        // if (this.markerObj.length == 0) {
            this.moObj.forEach(mo => {
                this.cardMo.push({
                    id: mo.id,
                    name: mo.name,
                    serialNumber: this.getDeviceAndChildDevice(mo).serialNumber,
                    city: this.getLocationName(mo.locationId),
                    aqi: this.getTargetMarker(mo.id),
                    isActive: false,
                    arrDeviceName: this.getDeviceAndChildDevice(mo).arrDevicesObj,
                    devicesId: this.getDeviceAndChildDevice(mo).devicesId,
                    lastConnectedDevice: this.getDeviceAndChildDevice(mo).lastConnectedDevice,
                    isConnectedDevices: this.getConnectedDevice(mo),
                    isNoDataSources: !(this.getDeviceAndChildDevice(mo).arrDevicesObj.length > 0)
                })
            })
        // TODO непонятно зачем отображать в списке маркеры с карты
        // } else {
        //     this.markerObj.forEach(mo => {
        //         this.cardMo.push({
        //             id: mo.id,
        //             name: mo.name,
        //             serialNumber: null,
        //             city: this.getLocationName(mo.city.locationId),
        //             aqi: this.getTargetMarker(mo.id),
        //             isActive: false,
        //             arrDeviceName: [],
        //             devicesId: [],
        //             lastConnectedDevice: null,
        //             isConnectedDevices: true,
        //             isNoDataSources: true
        //         })
        //     })
        // }
    }

    getConnectedDevice(mo) {
        let isNoDataSources = true;
        mo.devicesObj.forEach(d => {
            this.store.select(selectMyDevices)
                .pipe(filter(smd => !!smd?.length))
                .subscribe(smd => {
                    if (smd.find(s => s.id == d.id)) {
                        isNoDataSources = false;
                    }
                })
        })
        return isNoDataSources;
    }

    getDeviceAndChildDevice(mo: MonitoringObject): Devices {
        const arr: Devices = {
            serialNumber: [],
            arrDevicesObj: [],
            devicesId: [],
            lastConnectedDevice: null
        }
        arr.lastConnectedDevice = mo.lastConnectedDevice;
        mo.devicesObj.forEach(d => {
            arr.serialNumber.push(d.serialNumber)
            //     d.childDevices.forEach(c => arr.serialNumber.push(c.serialNumber))
            arr.arrDevicesObj.push(d)
            //     d.childDevices.forEach(c => arr.arrDevicesObj.push(c))
            arr.devicesId.push(d.id)
            //     d.childDevices.forEach(c => arr.devicesId.push(c.id))
        })
        arr.arrDevicesObj.map(ado => {
            if (ado.model != CITYAIR_STND_DEVICE) {
                ado.offline = false
            }
        })
        return arr
    }
    showDetails(data: { type: string, id: string }) {
        this.apActions.clickFromApToMo(data.id);
        const page = data.type == 'MO' ? PAGES.postsList : PAGES.devicesList;
        this.postsAndDevicesFacade.openPage(PAGES.networks + '/' + page + '/' + PAGES.details + '/' + data.id);
    }

    getLocationName(locationId): string {
        return this.citeis.find(c => c.locationId == locationId)?.name ? this.citeis.find(c => c.locationId == locationId).name : '';
    }

    getTargetMarker(idMarker: number) {
        return this.globalState.map.commonMarkers.find(cm => cm.id == idMarker)
    }

    getRandomInt() {
        return Math.floor(Math.random() * 10);
    }

    openPage(target: any, pageName: string) {
        if (target != null) {
            this.closePopup(target)
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    clickMenu({target, positionTop}, TargetId: string) {
        this.idTargetCard = TargetId;
        const id = Number(TargetId);
        this.store.dispatch(selectMo({id}));
        this.popupProvider.confirm(() => {
        });
        this.popupProvider.setTemplate(this.cardMenu, () => this.isShowMenu = false);
        this.popupOpenerElement = target;
        this.menuPositionTop = (document.documentElement.clientHeight - 242) < positionTop ? document.documentElement.clientHeight - 242 : positionTop;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        this.closePopup(target);
        this.nameTargetCard = this.cardMo.find(ic => ic.id == Number(this.idTargetCard)).name
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.apActions.deleteMo()
        this.isShowDeletePopup = false;
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }
}
