<div class="posts-list-view">
    <div class="posts-list-view__search-line">
        <search-input-embedded
                [textPlaceholder]="TEXTS.POSTS_AND_DEVICES.searchPost"
                [(text)]="searchQuery"
                [debounceTime]="400"
        ></search-input-embedded>
    </div>
    <div [@inOutAnimation] class="posts-list-view__main_block scroll">
        <button-add *ngIf="(store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData && (store.select(selectMyDevices) | async)?.length > 0" class="posts-list-view__button-add"
                    [text]="TEXTS.POSTS_AND_DEVICES.addPost"
                    (action)="openPage($event, path + pages.postCreate)"></button-add>
        <div class="posts-list-view__organizing-buttons">
            <shared-sort-list-item (click)="postsAndDevicesFacade.makePostSorting(postsAndDevicesFacade.sortPostName, 'sortPostName')"
                                   class="posts-list-view__organizing-buttons--item"
                                   [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortPostName"
                                   [text]="TEXTS.POSTS_AND_DEVICES.post"></shared-sort-list-item>
            <switch-v2 *ngIf="(store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData"
                       class="posts-list-view__organizing-buttons--item"
                       (switchOnChange)="postsAndDevicesFacade.showDataSources($event)"
                       [text]="TEXTS.CITY_CARD.dataSources"
                       [isSwitchOn]="postsAndDevicesFacade.isSwitchDataSources"
            ></switch-v2>
            <shared-sort-list-item
                    (click)="postsAndDevicesFacade.makePostSorting(postsAndDevicesFacade.sortPostAqi, 'sortPostAqi')"
                    class="posts-list-view__organizing-buttons--item posts-list-view__organizing-buttons--item-aqi"
                    [sortingDirection]="postsAndDevicesFacade.sortingDirection.sortPostAqi"
                    [text]="TEXTS.POSTS_AND_DEVICES.aqi"></shared-sort-list-item>
        </div>
        <ng-container *ngIf="cardMo | multipleSearchfilter: ['name','city','serialNumber'] : searchQuery as result">
        <div *ngFor="let cm of cardMo | multipleSearchfilter: ['name','city','serialNumber'] : searchQuery | sorting : postsAndDevicesFacade.sortingPost : postsAndDevicesFacade.sortingPostDirectionMain as result">
            <monitoring-object-plate
                    #MOCard
                    class="posts-list-view__monitoring-object-plate"
                    [idMo]="cm.id"
                    [name]="cm.name"
                    [city]="cm.city"
                    [aqi]="cm.aqi ? cm.aqi : 0"
                    [isActive]="cm.isActive"
                    [isComplexMode]="postsAndDevicesFacade.isSwitchDataSources"
                    [arrDeviceName]="cm.lastConnectedDevice ? [cm.lastConnectedDevice] : []"
                    (openActionsPopup)="clickMenu($event, cm.id)"
                    (cbClickForStaticTagsDevice)="showDetails($event)"
                    (cbClickForCard)="apActions.clickFromApToMo(cm.id)"
                    [isShowKebabMenu]="(store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData"
                    [isNotConnectedDevices]="cm.isConnectedDevices"
                    [isNoDataSources]="cm.isNoDataSources"
            ></monitoring-object-plate>
        </div>
            <search-result-placeholder
                    *ngIf="!isShowNotDate && isShowResultPlaceholder && result.length == 0"
                    class="screen-placeholders"
            ></search-result-placeholder>
            <non-data-screen
                    *ngIf="isShowNotDate"
                    class="screen-placeholders"
                    [text]="TEXTS.POSTS_AND_DEVICES.noMonitoringPosts"
            ></non-data-screen>
        </ng-container>
    </div>
    <button-download-data
            *ngIf="!isShowNotDate"
            [text]="TEXTS.POSTS_AND_DEVICES.downloadDataFromPosts"
            (click)="openPage($event,path + pages.postReport + '/all')"
            class="posts-list-view__button-download-data"></button-download-data>
</div>

<ng-template #cardMenu>
    <popup-ask class="posts-list-view__popup-ask"
               *ngIf="isShowDeletePopup"
               [@inOutAnimation]
               (cbAccept)="deleteAccept()"
               (cbClose)="deleteCancel()"
               [title]="TEXTS.POPUP_DELETE.title"
               [description]="TEXTS.POPUP_DELETE.body"
               [textAccept]="TEXTS.POPUP_DELETE.accept"
               [textCancel]="TEXTS.POPUP_DELETE.cancelNew"
    >
        <static-tags class="posts-list-view__static-tags" [typeTag]="'mo'"
                     [text]="nameTargetCard"></static-tags>
    </popup-ask>

    <card-actions-list class="posts-list-view__card-actions-list"
                       *ngIf="isShowMenu"
                       [@inOutAnimation]
                       [position]="menuPositionTop"
                       (clickOutside)="closePopup($event)"
    >
        <card-actions-item
                data-cy="action-rename"
                [text]="TEXTS.POSTS_AND_DEVICES.viewInformation"
                (click)="openPage($event,path +  pages.details + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.downloadDataFromPost"
                (click)="openPage($event,path + pages.postReport + '/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item *ngIf="isRU"
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.downloadTZA4Report"
                (click)="openPage($event,path + pages.postReportTza +'/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-separator></card-actions-separator>
        <card-actions-item
                data-cy="action-close"
                [text]="TEXTS.POSTS_AND_DEVICES.edit"
                (click)="openPage($event, path + pages.postEdit +'/' + idTargetCard)"
        ></card-actions-item>
        <card-actions-item
                data-cy="action-close"
                type="danger"
                [text]="TEXTS.POSTS_AND_DEVICES.deletePost"
                (click)="showPopup($event, 'close')"
        ></card-actions-item>
    </card-actions-list>
</ng-template>
