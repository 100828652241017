import { Component, AfterContentChecked, TemplateRef } from '@angular/core';

import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';

@Component({
    selector: 'ca-popup-outlet',
    template: `
        <ng-container *ngTemplateOutlet="popupTemplateRef"></ng-container>
    `
})
export class PopupOutletComponent implements AfterContentChecked {
    popupTemplateRef: TemplateRef<any>;

    constructor(private popupProvider: OffPanelPopupService) {}

    ngAfterContentChecked() {
        this.popupTemplateRef = this.popupProvider.getTemplate();
    }
}
