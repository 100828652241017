<div class="indoor__wrapper">
    <div class="indoor__analytic" [class.active]="analyticIsOpen">
        <ca-sidebar-toggle-button
            [isActive]="analyticIsOpen"
            (toggle)="analyticIsOpen = !analyticIsOpen"
        ></ca-sidebar-toggle-button>

        <indoor-analytic
                [loading]="store.select(indoorSelectors.isAnalyticsLoading) | async"
                [analyticData]="store.select(indoorSelectors.analytics) | async"
        ></indoor-analytic>
    </div>

    <div class="indoor__center">
        <div class="indoor__center_top">
            <indoor-calendar
                [timeBegin]="store.select(indoorSelectors.dateBegin) | async"
                [timeEnd]="store.select(indoorSelectors.dateEnd) | async"
                (timeRangeChange)="updateTimeRange($event)"
            ></indoor-calendar>

            <btn-accept class="indoor__download_btn" (click)="indoorFacade.clickDownload()">
                <i class="indoor__download_icon"></i>{{TEXTS.EDIT_STATION.downloadXLSData}}
            </btn-accept>
        </div>

        <point-list
            class="indoor__points-list"
            [points]="store.select(indoorSelectors.listPoints) | async"
            (clickOnPoint)="store.dispatch(indoorSelectPoint({id: $event}))"
        ></point-list>

        <div class="indoor__separator"></div>

        <div class="indoor__timeline">
            <timeline-panel #timelinePanel
                [dates]="store.select(indoorSelectors.timeSequences) | async"
                [features]="store.select(indoorSelectors.getActivePostWithMeasurementsAsFeatures) | async"
                [dateTime]="store.select(indoorSelectors.time) | async"
                [initSelectMeasurement]="store.select(indoorSelectors.selectMeasurement) | async"
            ></timeline-panel>
        </div>
    </div>

    <indoor-loading-indicator
        *ngIf="store.select(indoorSelectors.isPointsLoading) | async"
    ></indoor-loading-indicator>
</div>
