import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'search-input-embedded',
    templateUrl: './search-input-embedded.component.html',
    styleUrls: ['./search-input-embedded.component.less']
})
export class SearchInputEmbeddedComponent {
    @Input() textPlaceholder = '';
    @Input() text = '';
    @Input() debounceTime = 0;
    @Output() textChange = new EventEmitter<string>();

    textChangeIn(event) {
        this.textChange.emit(event)
    }
}
