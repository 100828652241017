import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { AnalyticChartTitleModule } from 'projects/cityscreen/src/components/analytic-charts/analytic-chart-title/analytic-chart-title.module';
import { DoughnutChartComponent } from 'projects/cityscreen/src/components/analytic-charts/doughnut-chart/doughnut-chart.component';
import {
    AnalyticComponent
} from 'projects/cityscreen/src/components/analytic-charts/analytic-main/analytic-main.component';
import { BarChartModule } from 'projects/cityscreen/src/components/analytic-charts/bar-chart/bar-chart.module';
import {
    DoughnutChartModule
} from 'projects/cityscreen/src/components/analytic-charts/doughnut-chart/doughnut-chart.module';
import { SwitchItemModule } from 'projects/cityscreen/src/components/switch-item/switch-item.module';

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
        AnalyticChartTitleModule,
        BarChartModule,
        DoughnutChartModule,
        SwitchItemModule
    ],
    declarations: [
        AnalyticComponent,
    ],
    exports: [
        AnalyticComponent,
    ]
})
export class AnalyticModule {
}
