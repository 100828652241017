import { Component, Input } from '@angular/core';

import { STATUS_TYPES } from './device-status.types';

type IconSize = 'small' | 'medium';

@Component({
    selector: 'device-status',
    templateUrl: 'device-status.component.html',
    styleUrls: ['device-status.component.less']
})
export class DeviceStatusComponent {
    @Input() status: STATUS_TYPES;
    @Input() text: string;
    @Input() size: IconSize = 'medium';
}
