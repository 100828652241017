import { errorTransformer, ErrorTransformer_model } from './dataProvider/DataProvider';
import { ErrorNumberType, ErrorResult } from '../../harvester/UiAdminProject8/src/commonData/models';


export class ApiModels {
    private _errorCb: (data: ErrorTransformer_model) => void;
    private _errorTokenCb: () => void;

    setErrorTokenCb = (cb: () => void) => this._errorTokenCb = cb;
    setErrorCb = (cb: (data: ErrorTransformer_model) => void) => this._errorCb = cb;

    errorHandler = (errorResult: ErrorResult): ErrorTransformer_model => {
        const error = errorTransformer(errorResult);

        if (error.errorType === ErrorNumberType.TokenFailed) {
            this._errorTokenCb?.();
        }

        this._errorCb?.(error);

        return error;
    }
}
