<ng-template #popupOutlet>
    <stnd-ask-popup-three-questions
            *ngIf="showConfirmationPopup"
            [texts]="TEXTS.POPUP_THREE_QUESTIONS"
            [close]="onClosePopup"
            [extraAction]="backOnCalculationResults"
    >
    </stnd-ask-popup-three-questions>
</ng-template>

<div class="edit_calc_result">
    <div class="edit_calc_result__title_wrapper">
        <header-page [textObject]="{titlePage: TEXTS.PLUMES.editRunConfigurationTitle}"></header-page>
    </div>

    <div class="edit_calc_result__body">
        <form (ngSubmit)="onSubmit()" [formGroup]="editCalculation" novalidate>
            <div class="edit_calc_result__wrapper_input_name">
                <ca-input
                    type="text"
                    controlName="name"
                    [form]="editCalculation"
                    [valueForm]="name.value"
                    [label]="TEXTS.PLUMES.runConfigurationName"
                    [textError]="errorObj.name"
                ></ca-input>
            </div>
            <div class="edit_calc_result__subtitle">{{TEXTS.PLUMES.runInterval}}</div>
        </form>
    </div>

    <div>
        <div class="edit_calc_result__input_wrapper">
                <div class="edit_calc_result__input_block">
                    <div class="edit_calc_result__label_select">{{TEXTS.PLUMES.hour}}</div>
                    <control-select class="rows-no_border"
                                    data-cy="plume-edit-selector1"
                                    [format]="'simple'"
                                    [model]="hours"
                                    [items]="dropdownSelectHour"
                                    [keyValue]="'id'"
                                    [keyName]="'name'"
                                    (closeEvent)="selectHour($event)"
                    ></control-select>
                </div>
        </div>
    </div>

    <div *ngIf="errorObj.schedule_period && hours == 0" class="edit_calc_result__note edit_calc_result__note-error">
        {{errorObj.schedule_period}}
    </div>

    <div *ngIf="hours != 0" class="edit_calc_result__note">
        {{TEXTS.PLUMES.runIntervalDescription}}
        {{hours | i18nPlural : TEXTS.PLUMES.every}}
        {{hours}} {{declOfNum(hours, TEXTS.PLUMES.timeIntervalHours)}}
    </div>


    <div class="edit_calc_result__action_btn_wrapper">
        <ca-button type="cancel" (clickAction)="backOnCalculationResults()">
            {{TEXTS.EDIT_STATION.cancel}}
        </ca-button>
        <ca-button type="primary" (clickAction)="onSubmit()">
            <ca-spinner *ngIf="isLoading; else showContentSave"></ca-spinner>
            <ng-template #showContentSave>{{TEXTS.EDIT_STATION.save}}</ng-template>
        </ca-button>
    </div>
</div>
