import { IDataProvider, RegionCoefsItem, ErrorResult } from "../../models";
import * as Models from "./adminApiModels";

export class AdminApiProvider implements Models.IAdminApiProvider {
    cpController: string = "AdminApi2";

    constructor(private readonly dataProvider: IDataProvider) {
    }

    getGroupsList = (request: Models.AdminApiRequest, execOk: (response: Models.GroupsListResponse) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetGroupsList", request, execOk, execFail);
    }
    getGroupInfo = (request: Models.GroupRequest, execOk: (response: Models.GroupInfoResponse) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetGroupInfo", request, execOk, execFail);
    }

    createGroup = (request: Models.GroupRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "CreateGroup", request, execOk, execFail);
    }
    editGroup = (request: Models.GroupRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroup", request, execOk, execFail);
    }
    deleteGroup = (request: Models.GroupRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "DeleteGroup", request, execOk, execFail);
    }

    changeGroupNotificationSetting = (request: Models.GroupNotificationChangeRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "ChangeGroupNotificationSetting", request, execOk, execFail);
    }
    editGroupAddDevicePermission = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroupAddDevicePermission", request, execOk, execFail);
    }
    editGroupRemoveDevicePermission = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "editGroupRemoveDevicePermission", request, execOk, execFail);
    }

    editGroupAddMoPermission = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroupAddMoPermission", request, execOk, execFail);
    }
    editGroupRemoveMoPermission = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "editGroupRemoveMoPermission", request, execOk, execFail);
    }

    editGroupAddLocPermission = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroupAddLocPermission", request, execOk, execFail);
    }
    editGroupRemoveLocPermission = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "editGroupRemoveLocPermission", request, execOk, execFail);
    }

    
    editGroupSetModuleAccess = (request: Models.GroupEditPermissionRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroupSetModuleAccess", request, execOk, execFail);
    }

    //editGroupPermissions = (request: Models.GroupRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
    //    this.dataProvider.execHttp(this.cpController, "EditGroupPermissions", request, execOk, execFail);
    //}
    editGroupUserMoPermissions = (request: Models.GroupRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroupUserMoPermissions", request, execOk, execFail);
    }
    editGroupSetExtConfig = (request: Models.GroupRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditGroupSetExtConfig", request, execOk, execFail);
    }


    addNewMemberToGroup = (request: Models.GroupRoleMemberRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "AddNewMemberToGroup", request, execOk, execFail);
    }
    editMemberRoleInGroup = (request: Models.GroupRoleMemberRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "EditMemberRoleInGroup", request, execOk, execFail);
    }
    removeMemberFromGroup = (request: Models.GroupRoleMemberRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "RemoveMemberFromGroup", request, execOk, execFail);
    }


    generateCityairLicense = (request: Models.CityairLicenseRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GenerateCityairLicense", request, execOk, execFail);
    }
    importCityairLicense = (request: Models.CityairLicenseImportRequest, execOk: (response: Models.CityairLicense) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "ImportCityairLicense", request, execOk, execFail);
    }
    getActiveCityairLicense = (request: Models.AdminApiRequest, execOk: (response: Models.CityairLicense) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetActiveCityairLicense", request, execOk, execFail);
    }


    getUsersList = (request: Models.AdminApiRequest, execOk: (response: Models.UsersListResponse) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetUsersList", request, execOk, execFail);
    }
    getUserPermission = (request: Models.UserItemRequest, execOk: (response: Models.UserPermissionResponse) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetUserPermission", request, execOk, execFail);
    }
    upsertUserToken = (request: Models.UserTokenRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "UpsertUserToken", request, execOk, execFail);
    }
    deleteUserToken = (request: Models.UserTokenRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "DeleteUserToken", request, execOk, execFail);
    }
    getUserNotificationFeeds = (request: Models.UserFeedItemRequest, execOk: (response: Models.UserFeedResponse) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetUserNotificationFeeds", request, execOk, execFail);
    }
    getUserTokenValue = (request: Models.UserTokenRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "GetUserTokenValue", request, execOk, execFail);
    }
    markNotificationFeedViewed = (request: Models.UserFeedItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttpEx(this.cpController, "MarkNotificationFeedViewed", request, false, true, execOk, execFail);
    }
    exportFeedItemsToExcel = (request: Models.FeedItemsExportRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttpEx(this.cpController, "ExportFeedItemsToExcel", request, false, true, execOk, execFail);
    }

    userItemCreate = (request: Models.UserItemRequest, execOk: (response: Models.CpUserItem) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "UserItemCreate", request, execOk, execFail);
    }
    userItemEdit = (request: Models.UserItemRequest, execOk: (response: Models.CpUserItem) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "UserItemEdit", request, execOk, execFail);
    }
    userItemDelete = (request: Models.UserItemRequest, execOk: (response: Models.CpUserItem) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "UserItemDelete", request, execOk, execFail);
    }
    userItemSetPassword = (request: Models.UserItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "UserItemSetPassword", request, execOk, execFail);
    }
    userItemSendWelcome = (request: Models.UserItemRequest, execOk: (response: string) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "UserItemSendWelcome", request, execOk, execFail);
    }

    setRegionCoefsItem = (request: Models.SetRegionCoefsItemRequest, execOk: (response: RegionCoefsItem) => void, execFail: (errRes: ErrorResult) => void = null) => {
        this.dataProvider.execHttp(this.cpController, "SetRegionCoefsItem", request, execOk, execFail);
    }
}
