import { ALERT_COLORS } from 'src/config';
import { ChartOptions } from 'chart.js';

export const options: ChartOptions = {
    responsive: true,
    title: {
        display: false,
    },
    aspectRatio: 2.25, // width / height
    tooltips: {
        borderWidth: 1,
        bodyFontColor: '#000',
        titleFontColor: '#000',
        custom: (tooltip) => {
            tooltip.displayColors = true;
            tooltip.borderColor = ALERT_COLORS['1'];
            tooltip.backgroundColor = 'rgba(255, 255, 255, 0.7)';
        },
        callbacks: {
            label: (tooltipItem, data) => {
                return `${data.labels[tooltipItem.index]} ${data.datasets[0].data[tooltipItem.index]}%`;
            }
        },
    },
    legend: {
        display: false,
    },
};
