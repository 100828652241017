import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ChartsModule } from 'ng2-charts';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { CrossButtonModule } from 'projects/shared/modules/buttons/cross-button/cross-button.module';
import { CheckboxModule } from 'projects/cityscreen/src/components/checkbox/checkbox.module';
import { AqiCircleModule } from 'projects/shared/components/aqi-circle/aqi-circle.module';

import { TimelinePanelComponent } from './timeline-panel.component';
import { ButtonPlayComponent } from './button-play/button-play.component';
import { ChartTimelineComponent } from './chart-timeline/chart-timeline.component';
import { TimeRunnerComponent } from './time-runner/time-runner.component';
import { TrackPanelComponent } from './track-panel/track-panel.component';
import { RenameAQIPipe } from './pipes/rename-aqi.pipe';

import * as fromStore from './store';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        CrossButtonModule,
        CheckboxModule,
        AqiCircleModule,
        ChartsModule,
        StoreModule.forFeature('timeline', fromStore.reducers),
    ],
    exports: [
        TimelinePanelComponent,
    ],
    providers: [
        RenameAQIPipe,
    ],
    declarations: [
        TimelinePanelComponent,
        ButtonPlayComponent,
        ChartTimelineComponent,
        TimeRunnerComponent,
        TrackPanelComponent,
        RenameAQIPipe,
    ],
})
export class TimelinePanelModule {}
