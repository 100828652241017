import { createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import * as moment from 'moment';

import {
    Feature,
    DateRange,
    TimeData,
    AppConfig,
} from '../models/core';

import {
    FINISH_DATE_RANGE,
    START_DATE_RANGE,
    INIT_TIME,
    DEFAULT_APP_CONFIG,
} from '../constants';

import * as coreActions from './core.actions';

export interface ITimelineState {
    dateRange: DateRange;
    chartEnabled: boolean;
    timeData: TimeData;
    dates: string[];
    isNeedDatesUpdate: boolean;
    currentIndex: number;
    selectedGeoObjs: Feature[];
    lastSelectedObj: Feature;
    lastFeatures: Feature[];
    currentCity: Feature;
    lastPointTimeseries: Feature[];
    isFeatureMetricsLoading: boolean;
    forecastConfigurations: string[];
    isHotSpotsEnabled: boolean;
    config: AppConfig;
    isLocalStorageUse: boolean;
    measures: string[];
    appLoading: boolean;
}

export const initialState: ITimelineState = {
    dateRange: {
        start: new Date(START_DATE_RANGE).toISOString(),
        finish: new Date(FINISH_DATE_RANGE).toISOString(),
    },
    chartEnabled: false,
    timeData: INIT_TIME,
    dates: [],
    isNeedDatesUpdate: true,
    currentIndex: 0,
    selectedGeoObjs: [],
    lastSelectedObj: null,
    lastFeatures: [],
    currentCity: null,
    lastPointTimeseries: [],
    isFeatureMetricsLoading: false,
    forecastConfigurations: [],
    isHotSpotsEnabled: false,
    config: DEFAULT_APP_CONFIG,
    isLocalStorageUse: false,
    measures: [],
    appLoading: true,
};

const innerCoreReducer: ActionReducer<ITimelineState, any> = createReducer(
    initialState,
    on(coreActions.onSelectGeoObj, (state: ITimelineState, data) => ({
        ...state,
        lastSelectedObj: data.obj,
        chartEnabled: true,
    })),
    on(coreActions.onGetLastPointTimeseries, (state: ITimelineState, data) => ({
        ...state,
        lastPointTimeseries: [...data.payload],
    })),
    on(coreActions.onEnabledChart, (state: ITimelineState, data) => {
        if (!data.obj) {
            return {
                ...state,
                selectedGeoObjs: [],
                lastPointTimeseries: [],
                chartEnabled: false,
            };
        } else {
            return {
                ...state,
                chartEnabled: true,
            };
        }
    }),
    on(coreActions.onChangeTimeDataIndex, (state: ITimelineState, data) => ({
        ...state,
        timeData: {
            ...state.timeData,
            timeIndex: data.index,
            time: moment(state.dates[data.index]).utc(true).valueOf(),
        },
        currentIndex: data.index
    })),
    on(coreActions.onDatesReloaded, (state: ITimelineState, data) => {
        if (state.isNeedDatesUpdate && data.dates.length) {
            const dates = [...data.dates];
            const oldCurrentData = data.currentDate || state.dates[state.currentIndex] || '';
            const currentIndex = data.isReloadIndex ? -1 : dates.indexOf(oldCurrentData);

            // if current data update index
            const index = currentIndex !== -1 ? currentIndex : dates.length - 1;

            return {
                ...state,
                dates: dates,
                currentIndex: index,
                isNeedDatesUpdate: false,
                appLoading: !state.appLoading,
            };
        } else {
            return state;
        }
    }),
    on(coreActions.onMeasuresChanges, (state: ITimelineState, data) => ({
        ...state,
        measures: data.payload,
    })),
    on(coreActions.onDataRangeChanged, (state: ITimelineState, data) => ({
        ...state,
        dateRange: {
            start: data.startDate,
            finish: data.finishDate,
        },
        isNeedDatesUpdate: true,
    })),
);

export function coreReducers(state, action): any {
    return innerCoreReducer(state, action);
}
