import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PacketValueType } from 'harvester/UiAdminProject8/src/commonData/models';
import {
    FeedItemsExportRequest,
    GroupNotificationChangeRequest,
    GroupNotificationChangeType,
    GroupNotificationSetting,
    NotificationFeedItem,
    NotificationSubscribeType,
    UserFeedItemRequest
} from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';

import State from 'map/state';
import Actions from 'map/actions';
import { WindowGlobalVars } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { Store } from '@ngrx/store';
import { modifyNotification } from 'projects/cityscreen/src/modules/core/store/actions';
import { selectNotifications } from 'projects/cityscreen/src/modules/core/store/selectors';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';

export enum Pages {
    EVENT_FEED = 'event-feed',
    SUBSCRIPTIONS = 'subscriptions',
    SETTINGS = 'settings',
}

export enum SubscriptionTypeFilter {
    ALL = 'all',
    MEASUREMENTS = 'measurements',
    SERVICE = 'service',
    FORECAST = 'forecast',
    PLUMES = 'plumes'
}

export const TYPE_MAP = {
    [NotificationSubscribeType.PdkExcess]: SubscriptionTypeFilter.MEASUREMENTS,
    [NotificationSubscribeType.ServiceDevice]: SubscriptionTypeFilter.SERVICE
};

declare const window: WindowGlobalVars;

export class NotificationState {
    typeFilter: SubscriptionTypeFilter = SubscriptionTypeFilter.MEASUREMENTS;

    constructor(config?: Partial<NotificationState>) {
        if (config) {
            Object.assign(this, config);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsStateService {
    currentPage: Pages;
    private previousPage: Pages;
    typeFilter: SubscriptionTypeFilter;

    private subscriptions$ = new BehaviorSubject<NotificationSubscription[]>([]);
    private currentPage$ = new BehaviorSubject<Pages>(Pages.EVENT_FEED);
    currentPageObservable$ = this.currentPage$.asObservable();

    lists: {
        all$?: Observable<NotificationSubscription[]>,
        selected$?: Observable<NotificationSubscription[]>,
        active$?: Observable<NotificationSubscription[]>,
        deactivated$?: Observable<NotificationSubscription[]>
    } = {};

    currentSubscription: NotificationSubscription;

    constructor(
        private readonly globalState: State,
        private globalActions: Actions,
        private store: Store,
        private coreFacade: CoreFacade,
    ) {
        this.currentPage$.subscribe((page) => {
            this.currentPage = page;
        });
        this.setDefaultState();

        this.store.select(selectNotifications).subscribe(notificationSubscriptions => {
            this.setSubscriptions(notificationSubscriptions);
        })
    }

    setDefaultState() {
        this.currentPage$.next(Pages.EVENT_FEED);
        this.typeFilter = SubscriptionTypeFilter.MEASUREMENTS;
    }

    resetData() {
        this.previousPage = null;
        this.globalState.map.notifiableMos$.next(null);
        this.currentSubscription = null;
    }

    setSubscriptions(subscriptions: NotificationSubscription[] = []) {
        const { lists } = this;

        this.subscriptions$.next(subscriptions);

        lists.all$ = this.subscriptions$.asObservable();

        lists.selected$ = lists.all$.pipe(
            map(ss => ss.filter(s => this.hasFilterType(s.type)))
        );

        lists.active$ = lists.selected$.pipe(
            map(ss => ss.filter(s => s.isActive))
        );

        lists.deactivated$ = lists.selected$.pipe(
            map(ss => ss.filter(s => !s.isActive))
        );
    }

    openSettings(subscription: NotificationSubscription) {
        this.resetData();
        this.previousPage = this.currentPage;
        this.currentSubscription = subscription;
        this.currentPage$.next(Pages.SETTINGS);
        this.typeFilter = TYPE_MAP[subscription.type];
    }

    openSubscriptions(typeFilter?: SubscriptionTypeFilter) {
        this.resetData();
        this.currentPage$.next(Pages.SUBSCRIPTIONS);
        if (typeFilter) {
            this.getState(Pages.SUBSCRIPTIONS).typeFilter = typeFilter;
        }
    }

    openEventFeed() {
        this.resetData();
        this.currentPage$.next(Pages.EVENT_FEED);
    }

    openPrevious() {
        const page = this.previousPage;
        this.resetData();
        this.currentPage$.next(page);
    }

    private _updateSubscription(action: GroupNotificationChangeType, item: GroupNotificationSetting): Promise<string> {
        const request = new GroupNotificationChangeRequest(action, item);
        return this.globalActions.adminPanelApi.changeNotificationSettings(request);
    }

    async addSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.NotificationId = 0;
        item.IsActive = true;

        return this._updateSubscription(GroupNotificationChangeType.Add, item)
            .then(() => {
                this.coreFacade.updateGroupInfo();
            });
    }

    async removeSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();

        return this._updateSubscription(GroupNotificationChangeType.Delete, item)
            .then(() => {
                this.coreFacade.updateGroupInfo();
            });
    }

    async activateSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.IsActive = true;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item)
            .then(() => {
                this.store.dispatch(modifyNotification({
                    id: subscription.id,
                    props: {
                        ...subscription,
                        isActive: true
                    }
                }))
            });
    }

    addOrActivateSubscription(subscription: NotificationSubscription) {
        return subscription.isSuggested() || subscription.isNew()
            ? this.addSubscription(subscription)
            : this.activateSubscription(subscription);
    }

    async deactivateSubscription(subscription: NotificationSubscription) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.IsActive = false;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item)
            .then(() => {
                this.store.dispatch(modifyNotification({id: subscription.id, props: {isActive: false}}))
            });
    }

    async updateEventFeedUserIds(subscription: NotificationSubscription, newUserIds: number[]) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.NotifySetting.NotificationFeedUserIds = newUserIds;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item)
            .then(() => {
                this.store.dispatch(modifyNotification({id: subscription.id, props: {forUserIds: newUserIds}}))
            });
    }

    async subscribeForServiceNotifications(subscription: NotificationSubscription, newEmailsList: string[], newUserIds: number[]) {
        const item: GroupNotificationSetting = subscription.toRequestItem();
        item.NotifySetting.NotificationEmails = newEmailsList;
        item.NotifySetting.NotificationFeedUserIds = newUserIds;

        return this._updateSubscription(GroupNotificationChangeType.Edit, item)
            .then(() => {
                this.store.dispatch(modifyNotification({
                    id: subscription.id,
                    props: {emailsList: newEmailsList, forUserIds: newUserIds}
                }))
            });
    }

    async downloadFeedHistory(subscription: NotificationSubscription, dateBegin: string, dateEnd: string) {
        const request = new FeedItemsExportRequest();

        Object.assign(request, {
            NotificationId: subscription.id,
            TimeBegin: new Date(dateBegin),
            TimeEnd: new Date(dateEnd),
            TimeZone: new Date().getTimezoneOffset() / -60,
            Lang: window.JS_CP_SITE_LANG
        });

        return this.globalActions.adminPanelApi.downloadFeedHistory(request);
    }

    async getUsersEventFeed(userId: number) {
        const request = new UserFeedItemRequest(userId, null);
        return this.globalActions.adminPanelApi.getUsersEventFeed(request);
    }

    async markNotificationAsVisited(item: NotificationFeedItem) {
        const { userId } = this.globalState.adminPanel.iAm;
        item.IsViewed = true;
        const request = new UserFeedItemRequest(userId, item);

        return this.globalActions.adminPanelApi.markNotificationFeedViewed(request);
    }

    updateLists(transform: (ss: NotificationSubscription[]) => NotificationSubscription[]) {
        this.subscriptions$
        .pipe(take(1))
        .subscribe(subscriptions => {
            this.subscriptions$.next(transform(subscriptions));
        });
    }

    hasFilterType(type: NotificationSubscribeType) {
        return TYPE_MAP[type] === this.typeFilter
            || this.typeFilter === SubscriptionTypeFilter.ALL;
    }

    getValueName(value: number) {
        let type = PacketValueType[value];
        if (value === PacketValueType.PM2) {
            type = 'Pm2';
        } else if (value === PacketValueType.PM10) {
            type = 'Pm10';
        }
        return TEXTS.NAMES[type];
    }

    uiStates: {
        [key in Pages]?: NotificationState;
    } = {};

    getState(page: Pages) {
        return this.uiStates[page] || (this.uiStates[page] = new NotificationState());
    }

    canEdit = () => this.globalState.adminPanel.myRole?.editGroup;
}
