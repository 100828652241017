import { createAction, props } from '@ngrx/store';
import { Feature } from '../models/core';

export enum CoreActions {
    onEnabledChart = '[timeline] onEnabledChart',
    onMeasuresChanges = '[timeline] onMeasuresChanges',
    onSelectGeoObj = '[timeline] onSelectGeoObj',
    onChangeTimeDataIndex = '[timeline] onChangeTimeDataIndex',
    onDataRangeChanged = '[timeline] onDataRangeChanged',
    onDatesReloaded = '[timeline] onDatesReloaded',
    onGetLastPointTimeseries = '[timeline] onGetLastPointTimeseries',
}

export const onSelectGeoObj = createAction(
    CoreActions.onSelectGeoObj,
    props<{ obj: Feature }>()
);

export const onEnabledChart = createAction(
    CoreActions.onEnabledChart,
    props<{ obj: boolean }>()
);

export const onChangeTimeDataIndex = createAction(
    CoreActions.onChangeTimeDataIndex,
    props<{ index: number }>()
);

export const onDataRangeChanged = createAction(
    CoreActions.onDataRangeChanged,
    props<{ startDate: string; finishDate: string }>()
);

export const onDatesReloaded = createAction(
    CoreActions.onDatesReloaded,
    props<{ dates: string[]; isReloadIndex?: boolean; currentDate?: string }>()
);

export const onMeasuresChanges = createAction(
    CoreActions.onMeasuresChanges,
    props<{ payload: string[] }>()
);

export const onGetLastPointTimeseries = createAction(
    CoreActions.onGetLastPointTimeseries,
    props<{ payload: Feature[] }>()
);
