<div class="location-meteo-card">
    <div class="location-meteo-card__header">
        <div class="location-meteo-card__title">{{TEXTS.CITY_CARD.nowInCity}}</div>
        <div class="location-meteo-card__date">{{dateTime}}</div>
    </div>
    <div class="location-meteo-card__body">
        <div class="location-meteo-card__aqi">
            <div class="location-meteo-card__aqi-icon">
                <aqi-circle [numberAqi]="aqi"></aqi-circle>
            </div>
            <div class="location-meteo-card__aqi-label">AQI</div>
        </div>
        <div class="location-meteo-card__content">
            <div class="location-meteo-card__description" [innerHTML]="textQuality"></div>
            <div class="location-meteo-card__status" [innerHTML]="textForecast"></div>
            <div class="location-meteo-card__info">
                <div *ngFor="let item of measurementItems"
                    class="location-meteo-card__info-item location-meteo-card__info-item--{{item.name}}"
                    [attr.title]="TEXTS.TOOLTIPS[item.name]"
                >
                    <span class="location-meteo-card__info-item__value"
                        [innerHTML]="item.value"
                    ></span>
                    <span class="location-meteo-card__info-item__units"
                        [innerHTML]="TEXTS.UNITS[item.units] || item.units"
                    ></span>
               </div>
            </div>
        </div>
        <div class="location-meteo-card__tooltip">
            <ca-info-icon
                [active]="true"
                caTooltip
                [caTooltipTemplate]="tooltip"
            ></ca-info-icon>
        </div>
        <ng-template #tooltip>
            <ca-info-tooltip [text]="TEXTS.TEXT_DESCRIPTION.airQuality"></ca-info-tooltip>
        </ng-template>
    </div>
</div>
