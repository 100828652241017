import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from 'projects/cityscreen/src/components/analytic-charts/bar-chart/bar-chart.component';
import { AnalyticChartTitleModule } from 'projects/cityscreen/src/components/analytic-charts/analytic-chart-title/analytic-chart-title.module';

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
        AnalyticChartTitleModule
    ],
    declarations: [
        BarChartComponent,
    ],
    exports: [
        BarChartComponent,
    ]
})
export class BarChartModule {
}
