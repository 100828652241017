import { Injectable } from '@angular/core';

import { IntervalType } from 'src/namespace';

@Injectable({
    providedIn: 'root'
})
export class TimelineState {
    readonly timeMachine = {
        get time() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },

        get timeBegin() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },
        get timeEnd() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },

        get lastHoverTime() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },

        get aqi() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },

        get allowUpdate() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        }
    };

    get loadingStationData() {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return null;
    }
    set loadingStationData(a) {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    }

    get intervalType() {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return null;
    }
    set intervalType(a) {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    }

    get playingTime() {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return null;
    }
    set playingTime(a) {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    }

    measures = {
        get selected() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },
        set selected(a) {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        },

        get list() {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            return null;
        },
        set list(a) {
            console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        }
    };

    get showedChart() {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return null;
    }
    set showedChart(a) {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    }

    get percentLoadModels() {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return null;
    }
    set percentLoadModels(a) {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    }

    get modelsLoading() {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        return null;
    }
    set modelsLoading(a) {
        console.error('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
    }


    // private in module
    chartType: 'simple' | 'full' = 'full';
    isZooming = false;

    mobileMinimize = false;

    IntervalButtons: IntervalType[] = [];
}
