import { NgLocalization } from '@angular/common';

import { TEXTS, LANGUAGE } from 'src/texts/texts';

export function showMoreText(ngLocalization: NgLocalization, num: number = 0) {
    const { showMore, post } = TEXTS.NOTIFICATIONS;
    const category = ngLocalization.getPluralCategory(num, LANGUAGE);

    const words = {
        ru: [showMore, num]
    }[LANGUAGE] || [num, showMore];

    words.push(post[category]);

    return words.join(' ');
}

export function selectedPostsText(ngLocalization: NgLocalization, num: number = 0) {
    const { selected, post } = TEXTS.NOTIFICATIONS;
    const category = ngLocalization.getPluralCategory(num, LANGUAGE);

    const words = [
        selected[category],
        num,
        post[category]
    ];

    return words.join(' ');
}

export function postsListLabels(showMore?: boolean, ngLocalization?: NgLocalization) {
    const { allPost, collapse } = TEXTS.NOTIFICATIONS;
    const empty = () => '';
    return {
        all: () => allPost,
        expand: showMore && ngLocalization ? showMoreText.bind(null, ngLocalization) : empty,
        collapse: () => collapse
    };
}
