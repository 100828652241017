import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    OnDestroy
} from '@angular/core';

import { NgLocalization } from '@angular/common';

import {
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';

import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import State from 'map/state';

import type { CheckboxItem } from 'src/namespace';
import { TEXTS, isRU } from 'src/texts/texts';
import { PacketValueType } from 'harvester/UiAdminProject8/src/commonData/models';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';

import { selectedPostsText, postsListLabels } from 'projects/cityscreen/src/modules/notifications/notifications.utils';
import { MAX_INLINE_MOS } from 'projects/cityscreen/src/modules/notifications/notifications.settings';

import {
    EmailsListData,
    deduplicate,
    haveDifferentValues,
    mapEmails,
    stringToList,
    updateFormControls
} from 'projects/cityscreen/src/modules/notifications/components/settings/settings.utils';

import 'projects/cityscreen/src/modules/notifications/components/settings-measurements/settings-measurements.component.less';
import { MessageAPIResponseService } from 'src/little-components/message-api-response/message-api-response.service';
import { TooltipsService } from 'projects/shared/components/tooltips/tooltips.service';
import { Store } from '@ngrx/store';
import { selectMeasures } from 'projects/cityscreen/src/modules/core/store/selectors';

@Component({
    selector: 'settings-measurements',
    templateUrl: 'settings-measurements.component.html'
})
export class SettingsMeasurements implements OnInit, OnDestroy {
    @Input() subscription: NotificationSubscription;

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    workingSubscription: NotificationSubscription;

    allMos: number[];
    allSubstance: number[];
    subscription$: Subscription;
    moCheckboxes: CheckboxItem[] = [];

    textsNotification = TEXTS.NOTIFICATIONS;
    textsPopup = TEXTS.POPUP_THREE_QUESTIONS;

    showPdkTooltip = isRU;

    TEXTS = TEXTS;

    settingsForm: FormGroup;

    emailsListData: EmailsListData;
    emailsListReportsData: EmailsListData;

    saving = false;
    formDataHasChanged = false;
    showMosSelection = false;
    showConfirmationPopup = false;

    maxInlineMos = MAX_INLINE_MOS;

    constructor(
        private element: ElementRef,
        private stateService: NotificationsStateService,
        private globalState: State,
        private ngLocalization: NgLocalization,
        private fb: FormBuilder,
        private popupProvider: OffPanelPopupService,
        private msgService: MessageAPIResponseService,
        readonly tooltipsService: TooltipsService,
        private store: Store
    ) {
        this.subscription$ = store.select(selectMeasures).subscribe(packetsValueTypes => {
            this.allSubstance = packetsValueTypes.map(p => p.ValueType).filter(type => this.getValueName(type));
        });

        this.allMos = globalState.adminPanel.monitoringObjects.map(v => v.id);
    }

    selectedPostsText = selectedPostsText.bind(null, this.ngLocalization);
    getMoNameHandler = this.getMoName.bind(this);

    ngOnInit() {
        this.workingSubscription = NotificationSubscription.clone(this.subscription);

        this.emailsListData = mapEmails(this.workingSubscription.emailsList);
        this.emailsListReportsData = mapEmails(this.workingSubscription.emailsListReports);

        if (this.workingSubscription.isForAllMos) {
            this.workingSubscription.moItems = this.allMos.slice();
        }

        // TODO: server should probably update this list upon station removal
        this.workingSubscription.moItems = this.workingSubscription.moItems.filter(id => this.getMoName(id));

        this.moCheckboxes = this.toCheckboxItems(this.allMos, this.workingSubscription.moItems);

        this.setNotifiableMos();

        this.settingsForm = this.fb.group({
            filterMosText: [
                ''
            ],
            title: [
                this.workingSubscription.title,
                [
                    Validators.required,
                    Validators.minLength(1)
                ]
            ],
            isForAllMos: [
                this.workingSubscription.isForAllMos
            ],
            moItems: this.fb.group(
                this.getUpdatedCheckboxData(this.workingSubscription.moItems)
            ),
            emailsList: [
                this.workingSubscription.emailsList,
                [
                    Validators.minLength(1)
                ]
            ],
            emailsListProxy: this.fb.group(
                this.emailsListData.reduce((acc, { name, value }) => ({
                    ...acc,
                    [name]: [value, [Validators.email]]
                }), {})
            ),
            emailsListReports: [
                this.workingSubscription.emailsListReports,
                [
                    Validators.minLength(1)
                ]
            ],
            emailsListReportsProxy: this.fb.group(
                this.emailsListReportsData.reduce((acc, { name, value }) => ({
                    ...acc,
                    [name]: [value, [Validators.email]]
                }), {})
            ),
            isPeriodic: [
                this.workingSubscription.isPeriodic
            ]
        });

        const title$ = this.title.valueChanges.pipe(debounceTime(200), distinctUntilChanged());
        const emailsList$ = this.emailsList.valueChanges.pipe(debounceTime(400), distinctUntilChanged());
        const emailsListReports$ = this.emailsListReports.valueChanges.pipe(debounceTime(400), distinctUntilChanged());
        const moItems$ = this.moItems.valueChanges.pipe(distinctUntilChanged());

        title$.subscribe(value => {
            this.workingSubscription.title = value;
        });

        emailsList$
        .subscribe(value => {
            this.updateList(value.toString());
            const emails = Object.values(this.emailsListProxy.value as object);
            this.workingSubscription.emailsList = deduplicate(emails);
        });

        emailsListReports$
        .subscribe(value => {
            this.updateListReports(value.toString());
            const emails = Object.values(this.emailsListReportsProxy.value as object);
            this.workingSubscription.emailsListReports = deduplicate(emails);
        });

        this.isPeriodic.valueChanges.subscribe(value => {
            this.workingSubscription.isPeriodic = value;
        });

        this.isForAllMos.valueChanges.subscribe(value => {
            const updated = this.getUpdatedMos(this.moItems.value, value);
            const moItems = Object.keys(updated).filter(k => updated[k]).map(Number);
            this.workingSubscription.moItems = moItems;
            this.setNotifiableMos();
        });

        moItems$.subscribe(value => {
            const sub = this.workingSubscription;
            const moItems = Object.keys(value).filter(k => value[k]).map(Number);

            if (haveDifferentValues(moItems, sub.moItems)) {
                sub.isForAllMos = false;
                this.isForAllMos.patchValue(false, {
                    emitEvent: false
                });
            }

            sub.moItems = moItems;

            this.setNotifiableMos();

            this.moCheckboxes = this.toCheckboxItems(this.allMos, moItems);
        });

        merge(
            title$,
            moItems$,
            emailsList$,
            emailsListReports$,
            this.isForAllMos.valueChanges,
            this.emailsListProxy.valueChanges,
            this.emailsListReportsProxy.valueChanges,
            this.isPeriodic.valueChanges
        ).subscribe(() => {
            this.changeCheck();
        });

        // react on map clicks
        this.globalState.map.notifiableMos$.subscribe(value => {
            const { moItems } = this.workingSubscription;
            if (value && haveDifferentValues(moItems, value)) {
                Object.assign(this.workingSubscription, {
                    isForAllMos: false,
                    moItems: value
                });
                this.isForAllMos.patchValue(false, {
                    emitEvent: false
                });
                this.moItems.patchValue(this.getUpdatedCheckboxData(value));
            }
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            const changed = this.settingsForm.invalid || this.formDataHasChanged;
            if (changed) this.openPopup();
            return changed;
        });
    }

    get emailsListProxy() {
        return this.settingsForm.get('emailsListProxy') as FormGroup;
    }

    get emailsListReportsProxy() {
        return this.settingsForm.get('emailsListReportsProxy') as FormGroup;
    }

    get moItems() {
        return this.settingsForm.get('moItems') as FormGroup;
    }

    ngOnDestroy() {
        this.msgService.clearMsg();
        this.popupProvider.clear();
        this.subscription$.unsubscribe();
    }

    private toCheckboxItems(ids: number[], selected: number[]): CheckboxItem[] {
        return ids.map(id => ({
            id,
            label: this.getMoName(id),
            selected: selected.includes(id)
        }));
    }

    updateMoCheckboxes(items: CheckboxItem[]) {
        this.moItems.patchValue(
            this.getUpdatedCheckboxData(items.filter(c => c.selected).map(c => +c.id))
        );
    }

    selectAllMos(selectAll: boolean) {
        this.isForAllMos.patchValue(selectAll);
    }

    private getUpdatedCheckboxData(moItems: number[], resetValue?: boolean) {
        return this.allMos.reduce((acc, mo) => ({
            ...acc,
            [mo]: resetValue ?? moItems.includes(mo)
        }), {});
    }

    private normalizeMos(subscription: NotificationSubscription) {
        const moItems = subscription.isForAllMos ? [] : subscription.moItems;
        return Object.assign(
            NotificationSubscription.clone(subscription),
            subscription,
            {
                moItems
            }
        );
    }

    private getUpdatedMos(moItems: object, isForAllMos: boolean) {
        this.workingSubscription.isForAllMos = isForAllMos;

        moItems = this.getUpdatedCheckboxData(this.workingSubscription.moItems, isForAllMos);

        this.moItems.patchValue(moItems, {
            emitEvent: false
        });

        return moItems;
    }

    setNotifiableMos() {
        this.globalState.map.notifiableMos$.next(this.workingSubscription.moItems.slice());
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    toggleMosSelection() {
        this.showMosSelection = !this.showMosSelection;
    }

    toggleMosSelectionWithinContainer(e: Event) {
        if (this.element.nativeElement.contains(e.target as HTMLElement)) {
            this.toggleMosSelection();
        }
    }

    updateList(value: string) {
        this.emailsListData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListProxy as FormGroup, this.emailsListData);
    }

    updateListReports(value: string) {
        this.emailsListReportsData = mapEmails(stringToList(value));
        updateFormControls(this.emailsListReportsProxy as FormGroup, this.emailsListReportsData);
    }

    getMoName(id: number) {
        return this.globalState.adminPanel.monitoringObjects.find(v => v.id === id)?.name;
    }

    removePostFromList(index: number) {
        const moItems = this.workingSubscription.moItems.filter((_, i) => i !== index);
        this.moItems.patchValue(this.getUpdatedCheckboxData(moItems));
    }

    get title() {
        return this.settingsForm.get('title');
    }

    get emailsList() {
        return this.settingsForm.get('emailsList');
    }

    get emailsListReports() {
        return this.settingsForm.get('emailsListReports');
    }

    get isPeriodic() {
        return this.settingsForm.get('isPeriodic');
    }

    get isForAllMos() {
        return this.settingsForm.get('isForAllMos');
    }

    get filterMosText() {
        return this.settingsForm.get('filterMosText');
    }

    getValueName(value: number) {
        return this.stateService.getValueName(value);
    }

    toggleValueItem(value: number) {
        this.workingSubscription.isForAllValues = false;

        const { valueItems } = this.workingSubscription;

        const idx = valueItems.indexOf(value);
        if (idx !== -1) {
            valueItems.splice(idx, 1);
        } else {
            valueItems.push(value);
        }

        if (!valueItems.length) {
            this.selectAllValues();
        } else {
            this.changeCheck();
        }
    }

    selectAllValues() {
        this.workingSubscription.isForAllValues = true;
        this.workingSubscription.valueItems.splice(0);
        this.changeCheck();
    }

    changeCheck() {
        this.formDataHasChanged = haveDifferentValues(
            this.subscription,
            this.normalizeMos(this.workingSubscription)
        );
    }

    isValueItemSelected(value: number) {
        return this.workingSubscription.valueItems.includes(value);
    }

    updateSliderValue(value: number) {
        this.workingSubscription.excessLevel = value;
        this.changeCheck();
    }

    postsListLabels() {
        return postsListLabels(true, this.ngLocalization);
    }

    cancel = () => {
        this.stateService.openPrevious();
    }

    save() {
        if (!this.settingsForm.disabled && !this.settingsForm.invalid && this.formDataHasChanged) {
            this.onSubmit();
        }
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onSubmit() {
        this.settingsForm.disable({
            emitEvent: false
        });

        this.saving = true;

        const subscription = this.normalizeMos(this.workingSubscription);

        this.stateService.addOrActivateSubscription(subscription)
        .then(() => {
            if (this.popupProvider.deferred()) {
                this.stateService.openSubscriptions();
            }
        })
        .finally(() => {
            this.saving = false;
            this.settingsForm.enable();
            this.onClosePopup();
        });
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    }

    onCancelAndNavigation() {
        this.onClosePopup();
        this.popupProvider.deferred();
    }

    onSavePopup = () => {
        this.save();
    }

    onCancelPopup = () => {
        this.cancel();
    }
}
