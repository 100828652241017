import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    ApiRequest,
    ErrorNumberType,
    ErrorResult,
    IDataProvider,
    WebResponse
} from 'harvester/UiAdminProject8/src/commonData/models';
import { WindowGlobalVars } from 'src/namespace';
import { environment } from 'projects/cityscreen/src/environments/environment';

declare const window: WindowGlobalVars;

export class DataProvider3 implements IDataProvider {
    constructor(private readonly http: HttpClient) {}

    execHttp = (controllerName: string, funcName: string, requestData: ApiRequest, execOk: (arg: any) => void, execFail: (errorResult: ErrorResult) => void) => {
        this.execHttpEx(controllerName, funcName, requestData, true, true, execOk, execFail);
    };

    execHttpEx = (controllerName: string, funcName: string, requestData: any, showBusyDialog: boolean, showErrorDialog: boolean, execOk: (arg: any) => void, execFail: (errorResult: ErrorResult) => void) => {
        requestData.Token = window.JS_CP_TOKEN;

        const url = environment.api_url + '/' + controllerName + '/' + funcName;

        window.sentryAddBreadcrumb?.({
            data: {
                online: window.navigator?.onLine,
                url,
                user: window.JS_CP_LOGIN
            },
            timestamp: new Date().getTime()
        });

        this.http.post(url, requestData).subscribe(
            (resultAny: any) => {
                const result = resultAny as WebResponse<any>;
                if (!result) {
                  this.execFailInternal(url, null, 'DataProvider3 result is undefined', undefined, execFail, requestData);
                }
                else if (result.Message !== undefined) {
                    this.execFailInternal(url, result.ErrorNumber, result.Message, result.ErrorMessageDetails, execFail, requestData);
                }
                else if (result.IsError) {
                    this.execFailInternal(url, result.ErrorNumber, result.ErrorMessage, result.ErrorMessageDetails, execFail, requestData);
                }
                else if ( typeof result === 'string') {
                    this.execFailInternal(url, undefined, result + '', undefined, execFail, requestData);
                }
                else {
                    this.execOkInternal(result.Result, execOk);
                }
            },
            (error: any) => {
                let errorMessage = 'Unknown Error';
                let errorNumber = ErrorNumberType.NotSpecified;
                if (error instanceof HttpErrorResponse) {
                    const errResp = error as HttpErrorResponse;
                    const result = errResp.error as WebResponse<any>;
                    if (result.IsError) {
                        errorMessage = result.ErrorMessage;
                        errorNumber = result.ErrorNumber;
                    } else {
                        errorMessage = errResp.message;
                    }
                }

                this.execFailInternal(url, errorNumber, errorMessage, null, execFail, requestData);
            }
        );
    };

    private execOkInternal = (result: any, execOk: (arg: any) => void) => {
        if (execOk != null)
            execOk(result);
    };

    private execFailInternal = (url: string, errorNumber: ErrorNumberType, errorMessage: string, errorMessageDetails: string, execFail: (errorResult: ErrorResult) => void, requestData: any) => {
        if (window.navigator?.onLine === false || errorNumber === 0) {
            return;
        }

        if (window.sentryCaptureException && errorNumber === ErrorNumberType.InfoException) {
            const errorResult = new ErrorResult(errorNumber,  errorMessage);
            const error = new Error();
            error.name = errorResult.getErrorText();
            error.message = errorResult.getErrorText();
            if (errorMessageDetails) {
                error.message += ` (details: ${errorMessageDetails})`;
            }
            window.sentryCaptureException(error);
        }

        if (execFail != null)
            execFail(new ErrorResult(errorNumber,  errorMessage));
    }
}

export class ErrorTransformer_model  {
    message: string;
    errorType: ErrorNumberType;
}

export let errorTransformer = (errorResult: ErrorResult): ErrorTransformer_model => {
    return {
        message: errorResult.getErrorText(),
        errorType: errorResult.ErrorNumber
    }
};
