import { Injectable } from '@angular/core';

import { LoginPage, LoginPopup } from '../../login.settings';

@Injectable({
    providedIn: 'root',
})
export class LoginNavigationService {
    private page = LoginPage.Login;

    get activePage() {
        return this.page;
    }

    gotoPage(page: LoginPage) {
        this.page = page;
    }

    gotoLoginPage() {
        this.gotoPage(LoginPage.Login);
    }

    gotoPasswordResetPage() {
        this.gotoPage(LoginPage.PasswordReset);
    }

    gotoResetCodePage() {
        this.gotoPage(LoginPage.ResetCode);
    }

    gotoNewPasswordPage() {
        this.gotoPage(LoginPage.NewPassword);
    }

    private popup: LoginPopup;

    get activePopup() {
        return this.popup;
    }

    openPopup(popup: LoginPopup) {
        this.popup = popup;
    }

    openPasswordUpdatedPopup() {
        this.openPopup(LoginPopup.PasswordUpdated);
    }

    openInviteLinkExpiredPopup() {
        this.openPopup(LoginPopup.InviteLinkExpired);
    }

    closePopup() {
        this.popup = null;
    }
}
