import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardActionsListComponent } from './component/card-actions-list/card-actions-list.component';
import { CardActionsItemComponent } from './component/card-actions-item/card-actions-item.component';
import { CardActionsSeparatorComponent } from './component/card-actions-separator/card-actions-separator.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        CardActionsListComponent,
        CardActionsItemComponent,
        CardActionsSeparatorComponent,
    ],
    declarations: [
        CardActionsListComponent,
        CardActionsItemComponent,
        CardActionsSeparatorComponent,
    ]
})
export class CardActionsModule {
}
