import {
    ValidatorFn,
    Validators,
    FormGroup,
    FormControl
} from '@angular/forms';

export type EmailsListData = {
    name: string;
    value: string;
}[];

export const EMAIL_REG_EXP = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function listValidator(re: RegExp): ValidatorFn {
    return control => {
        const arr = control.value.split ? control.value.split(',') : control.value;

        const invalidFound = arr.some((val: string) => {
            val = val.trim();
            return val.length && !re.test(val);
        });

        return invalidFound ? {
            'listValidator': {
                value: control.value
            }
        } : null;
    };
};

export function deduplicate(list: string[]) {
    return Array.from(new Set(list));
}

export function haveDifferentValues(a: object, b: object) {
    const aKeys = Object.keys(a);

    if (aKeys.length !== Object.keys(b).length) {
        return true;
    } else {
        return aKeys.some(k => {
            return a[k] instanceof Array ? haveDifferentValues(a[k], b[k]) : a[k] !== b[k];
        });
    }
}

export function mapEmails(emailsList: string[]): EmailsListData {
    return emailsList.map((value, i) => ({
        name: `email-${i}`,
        value
    }));
}

export function stringToList(csv: string = '') {
    return csv.split(',').map(v => v.trim()).filter(v => v);
}

export function updateFormControls(list: FormGroup, listData: EmailsListData) {
    listData.forEach(({ name, value }) => {
        if (list.contains(name)) {
            list.patchValue({
                [name]: value
            });
        } else {
            list.addControl(
                name,
                new FormControl(value, Validators.email)
            );
        }
    });

    Object.keys(list.value).forEach(k => {
        if (!listData.find(v => v.name === k)) {
            list.removeControl(k);
        }
    });
}
