import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '../../projects/shared/directives/directives.module';
import { PipesModule } from '../../projects/shared/pipes/pipes.module';

import { LittleComponentsModule } from '../little-components/little-components.module';

import { AnalyticsPage } from './components/analytics-page/analytics-page.component';
import { CityInfo } from '../little-components/city-info/city-info.component';
import { SearchInputModule } from '../../projects/shared/modules/form-components/search-input/search-input.module';
import { AnalyticsPageWrapperComponent } from './components/analytics-page-wrapper/analytics-page-wrapper.component';
import { AnalyticModule } from 'projects/cityscreen/src/components/analytic-charts/analytic-main/analytic-main.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        PipesModule,
        LittleComponentsModule,
        SearchInputModule,
        AnalyticModule,
    ],
    exports: [
        AnalyticsPage,
        AnalyticsPageWrapperComponent
    ],
    declarations: [
        CityInfo,
        AnalyticsPage,
        AnalyticsPageWrapperComponent,
    ]
})
export class AnalyticsModule {}
