import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef
} from '@angular/core';

@Component({
    selector: 'ca-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.less']
})
export class CardComponent {
    @Input() title: string;
    @Input() isActive = true;
    @Input() hasActions = true;
    @Input() actionsActive = false;
    @Input() statusText: string[] = [];
    @Input() actionsTooltipText: string;

    @Output() openActionsPopup = new EventEmitter<{target: HTMLElement, positionTop: number}>();

    @ViewChild('popupOpener') popupOpener: ElementRef<HTMLDivElement>;

    openActions() {
        const { nativeElement: target } = this.popupOpener;
        const positionTop = target.getBoundingClientRect().top - target.offsetTop + window.scrollY;

        this.openActionsPopup.emit({
            target,
            positionTop
        });
    }
}
