<ca-tooltip-placement>
    <div class="packets-tooltip"
        *ngIf="tooltipsService.currentTarget"
        [@inOutAnimation]
    >
        <div class="packets-tooltip__text">{{data.title}}</div>
        <div class="packets-tooltip__details">
            <device-status size="small"
                *ngFor="let label of data.labels"
                [status]="label.status"
                [text]="label.text"
            ></device-status>
        </div>
    </div>
</ca-tooltip-placement>
