import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';
import { CalendarMonthsModule } from 'src/calendar/calendar-months/calendar-months.module';
import { AqiCircleModule } from 'projects/shared/components/aqi-circle/aqi-circle.module';
import { TimelinePanelModule } from 'projects/cityscreen/src/components/timeline-panel/timeline-panel.module';
import { SearchInputModule } from 'projects/shared/modules/form-components/search-input/search-input.module';
import { SortListItemModule } from 'projects/shared/components/sort-list-item/sort-list-item.module';
import { AnalyticModule } from 'projects/cityscreen/src/components/analytic-charts/analytic-main/analytic-main.module';
import { BtnAcceptModule } from 'projects/cityscreen/src/components/btn-accept/btn-accept.module';
import { SearchResultPlaceholderModule } from 'projects/cityscreen/src/components/search-result-placeholder/search-result-placeholder.module';
import { SidebarModule } from 'projects/shared/modules/sidebar/sidebar.module';
import { IconsModule } from 'projects/shared/modules/icons/icons.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';

import { IndoorMainComponent } from './components/indoor-main/indoor-main.component';
import { AnalyticComponent } from './components/analytic/analytic.component';
import { IndoorLoadingIndicatorComponent } from './components/module-loader/indoor-loading-indicator.component';
import { IndoorCalendarComponent } from './components/indoor-calendar/indoor-calendar.component';
import { PointListComponent } from './components/point-list/point-list.component';
import { PointItemComponent } from './components/point-item/point-item.component';
import { AnalyticLoaderComponent } from './components/analytic-loader/analytic-loader.component';
import { indoorReducers } from './services/store/reducers';
import { IndoorEffects } from './services/store/effects';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        StoreModule.forFeature('indoor', indoorReducers),
        EffectsModule.forFeature([IndoorEffects]),
        DirectivesModule,
        PipesModule,
        LittleComponentsModule,
        CalendarMonthsModule,
        AqiCircleModule,
        TimelinePanelModule,
        SearchInputModule,
        SortListItemModule,
        AnalyticModule,
        BtnAcceptModule,
        SearchResultPlaceholderModule,
        SidebarModule,
        IconsModule,
        TooltipsModule,
    ],
    declarations: [
        IndoorMainComponent,
        AnalyticComponent,
        IndoorLoadingIndicatorComponent,
        IndoorCalendarComponent,
        PointListComponent,
        PointItemComponent,
        AnalyticLoaderComponent,
    ],
    exports: [
        IndoorMainComponent,
    ],
})
export class IndoorModule {
}
