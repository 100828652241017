import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gray-background',
    templateUrl: './gray-background.component.html',
    styleUrls: ['./gray-background.component.less']
})
export class GrayBackgroundComponent {

}
