import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from 'src/texts/texts'
import { CheckboxItem } from 'src/namespace';

@Component({
    selector: 'tags-list2',
    templateUrl: 'tags-list.component.html',
    styleUrls: ['tags-list.component.less']
})
export class TagsList2 {
    @Input() tags: CheckboxItem[];
    @Input() limit?: number;
    @Input() isEditable = true;

    @Input() textFunctions?: {
        all?: () => string,
        expand?: (count: number) => string,
        collapse?: () => string
    };

    @Output() removeTag = new EventEmitter<CheckboxItem>();

    TEXTS = TEXTS;

    isExpand = false;

    getLimit() {
        return this.isExpand ? this.tags.length : this.limit;
    }

    remove(tag: CheckboxItem) {
        this.removeTag.emit(tag);
    }
}
