<div class="login-page login-page--{{clientConfig.name || 'unknown'}}"
    [style.backgroundImage]="assets.background"
>
    <div class="login-page__header">
        <div class="login-page__logo">
            <ca-cityair-logo *ngIf="!clientConfig.disableBranding?.all"></ca-cityair-logo>
        </div>
        <div class="login-page__language">
            <ca-language-selector (selectLang)="setLang($event)"></ca-language-selector>
        </div>
    </div>

    <div class="login-page__body">
        <ca-login-pane class="login-page__login-pane">
            <div class="login-page__client-logo"
                *ngIf="assets.logo"
                [style.backgroundImage]="assets.logo"
            ></div>
            <router-outlet></router-outlet>
        </ca-login-pane>
    </div>

    <ng-container *ngIf="!clientConfig.login?.clientFooter; else clientFooter">
        <div class="login-page__footer" *ngIf="!clientConfig.disableBranding?.all">
            <ca-contacts-info></ca-contacts-info>
            <ca-legal-notice *ngIf="currentLanguage === 'ru'"></ca-legal-notice>
        </div>
    </ng-container>

    <ng-template #clientFooter>
        <div class="login-page__footer">
            <div class="client-info">
                <div class="client-info-item">
                    <div class="client-info-title">
                        {{TEXTS.LOGIN_NORNICKEL.contactSupport}}
                    </div>
                    <div class="client-info-details">
                        <a class="login-page-link" [attr.href]="'tel:' + TEXTS.LOGIN_NORNICKEL.supportTel">
                            {{TEXTS.LOGIN_NORNICKEL.supportTel}}
                        </a>
                    </div>
                    <div class="client-info-description">
                        {{TEXTS.LOGIN_NORNICKEL.supportAvailable}}
                    </div>
                </div>
                <div class="client-info-item">
                    <div class="client-info-title">
                        {{TEXTS.LOGIN_NORNICKEL.contactFeedback}}
                    </div>
                    <div class="client-info-details">
                        <a class="login-page-link" [attr.href]="'mailto:' + TEXTS.LOGIN_NORNICKEL.feedbackEmail">
                            {{TEXTS.LOGIN_NORNICKEL.feedbackEmail}}
                        </a>
                    </div>
                </div>
                <div class="client-info-item">
                    <div class="client-info-title">
                        {{TEXTS.LOGIN_NORNICKEL.contactHelp}}
                    </div>
                    <div class="client-info-details">
                        <a class="login-page-link" [attr.href]="'mailto:' + TEXTS.LOGIN_NORNICKEL.helpEmail">
                            {{TEXTS.LOGIN_NORNICKEL.helpEmail}}
                        </a>
                    </div>
                </div>
            </div>

            <div class="client-info-copyright">
                {{TEXTS.LOGIN_NORNICKEL.copyright}}
            </div>
        </div>
    </ng-template>

    <ng-container [ngSwitch]="navigation.activePopup">
        <ca-password-updated-popup *ngSwitchCase="loginPopup.PasswordUpdated"></ca-password-updated-popup>
        <ca-link-expired-popup *ngSwitchCase="loginPopup.InviteLinkExpired"></ca-link-expired-popup>
    </ng-container>
</div>
