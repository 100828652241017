import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { TimeTrackView } from '../models/core';
import { TIMELINE_COLOR } from '../constants';

@Component({
    selector: 'airvoice2-track-panel',
    templateUrl: './track-panel.component.html',
    styleUrls: ['./track-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackPanelComponent {
    @Input() date: TimeTrackView[];
    @Input() AQI: number[];
    @Input() index: number;
    @Input() hasDataByIndex: boolean[] = [];
    @Output() setPosition = new EventEmitter<number>();
    public backColors = TIMELINE_COLOR;

    public setIndex(i: number): void {
        this.setPosition.emit(i);
    }
}
