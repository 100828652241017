import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as commonActions from './actions';
import { IAQ, ROOM } from 'src/namespace';
import {
    Measurement,
    Post,
    PostsMeasurementsResponse,
    PostWithAnalytics
} from '../api';
import { NotificationEvent } from './props.type';

export const measurementAdapter: EntityAdapter<Measurement> = createEntityAdapter<Measurement>({
    selectId: (m: Measurement) => `${m.postId}_${m.date}`
});

export interface IndoorState {
    posts: Post[];
    measurements: EntityState<Measurement>;
    analytics: PostWithAnalytics;
    activePointId: number;
    isPointsLoading: boolean;
    isAnalyticsLoading: boolean;
    isAllowUpdate: boolean;
    timeBegin: string;
    timeEnd: string;
    currentTime: string;
    setTimeAfterDataLoaded: string;
    timeSequences: string[];
    selectMeasurement: string;
    sort: {
        direction: -1 | 1;
        field: string;
    };
}

export const initialState: IndoorState = {
    posts: [],
    measurements: measurementAdapter.getInitialState(),
    analytics: null,
    activePointId: null,
    isPointsLoading: true,
    isAnalyticsLoading: false,
    isAllowUpdate: true,
    timeBegin: null,
    timeEnd: null,
    currentTime: null,
    setTimeAfterDataLoaded: null,
    timeSequences: [],
    selectMeasurement: null,
    sort: {
        direction: 1,
        field: null,
    }
};

const _indoorReducer = createReducer(
    initialState,

    on(commonActions.indoorSetTime, (state: IndoorState, data: { time: string }) => ({
        ...state,
        currentTime: data.time,
    })),

    on(commonActions.indoorUpdatePostsList, (state: IndoorState) => ({
        ...state,
        isPointsLoading: true,
    })),

    on(commonActions.indoorPostsListLoaded, (state: IndoorState, props: PostsMeasurementsResponse) => {
        const timeSequencesSet = new Set(props.data.map(d => d.date));
        const timeSequences = Array.from(timeSequencesSet);

        const timeBegin = timeSequences[0];
        const _timeBegin = new Date(timeBegin).getTime();
        const timeEnd = timeSequences[timeSequences.length - 1];
        const _timeEnd = new Date(timeEnd).getTime();
        const _current = new Date(state.currentTime).getTime();

        const findNearestTime = () => {
            if (!state.currentTime || !timeSequences.includes(state.currentTime)) {
                return timeEnd;
            }

            if (_current < _timeBegin) {
                return timeBegin;
            }

            if (_current > _timeEnd) {
                return timeEnd;
            }

            return state.currentTime;
        };

        return {
            ...state,
            timeBegin: timeBegin,
            timeEnd: timeEnd,
            isPointsLoading: false,
            currentTime: findNearestTime(),
            posts: props.posts,
            measurements: measurementAdapter.setAll(props.data, state.measurements),
            timeSequences
        };
    }),

    on(commonActions.indoorIntervalDataLoaded, (state: IndoorState, props: PostsMeasurementsResponse) => {
        if (!props.data.length) {
            return state;
        }

        const measurements = measurementAdapter.upsertMany(props.data, state.measurements);

        const newSet = new Set(state.timeSequences);
        props.data.forEach(d => newSet.add(d.date));

        const newTimeSequences = Array.from(newSet);

        newTimeSequences.splice(0, newTimeSequences.length - state.timeSequences.length); // убираем старое время

        return {
            ...state,
            timeBegin: newTimeSequences[0],
            timeEnd: newTimeSequences[newTimeSequences.length - 1],
            currentTime: newTimeSequences[newTimeSequences.length - 1],
            measurements,
            timeSequences: newTimeSequences
        };
    }),

    on(commonActions.indoorUpdatedAnalytic, (state: IndoorState, analytics: PostWithAnalytics) => ({
        ...state,
        isAnalyticsLoading: false,
        analytics
    })),

    on(commonActions.indoorHideAnalytics, (state: IndoorState) => ({
        ...state,
        isAnalyticsLoading: false,
        analytics: null
    })),

    on(commonActions.indoorSelectPoint, (state: IndoorState, data: { id: number }) => ({
        ...state,
        activePointId: data.id,
        isAnalyticsLoading: true
    })),

    on(commonActions.indoorDeselectPoints, (state: IndoorState) => ({
        ...state,
        activePointId: null,
        isAnalyticsLoading: false
    })),

    on(commonActions.indoorAllowUpdate, (state: IndoorState, data: { isAllow: boolean }) => ({
        ...state,
        isAllowUpdate: data.isAllow
    })),

    on(commonActions.indoorSortPoints, (state: IndoorState, props: { direction: 1 | -1, field: string }) => {
        const { selectEntities } = measurementAdapter.getSelectors();

        return {
            ...state,
            sort: {
                direction: props.direction,
                field: props.field
            },
            posts: [...state.posts].sort((aPost, bPost) => {
                if (props.field === ROOM) {
                    return aPost.name > bPost.name ? -props.direction : props.direction;
                }

                const aMeasure = selectEntities(state.measurements)[`${aPost.id}_${state.currentTime}`];
                const bMeasure = selectEntities(state.measurements)[`${bPost.id}_${state.currentTime}`];

                if (props.field === IAQ) {
                    const aValue = aMeasure.aqi.indoorAqi?.valueScaled10 || null;
                    const bValue = bMeasure.aqi.indoorAqi?.valueScaled10 || null;

                    return aValue > bValue ? -props.direction : props.direction;
                }

                const _field = props.field.toLowerCase();
                return aMeasure[_field] > bMeasure[_field] ? -props.direction : props.direction;
            })
        };
    }),

    on(commonActions.indoorUpdateTimeIndex, (state: IndoorState, props: { index: number }) => ({
        ...state,
        currentTime: state.timeSequences[props.index]
    })),

    on(commonActions.indoorSetTimeAfterDataLoaded, (state: IndoorState) => ({
        ...state,
        currentTime: state.setTimeAfterDataLoaded || state.currentTime,
        setTimeAfterDataLoaded: null
    })),

    on(commonActions.indoorShowEventOnChart, (state: IndoorState, props: NotificationEvent) => ({
        ...state,
        timeBegin: props.beginDate,
        timeEnd: props.endDate,
        setTimeAfterDataLoaded: props.eventDate,
        isAllowUpdate: false,
        selectMeasurement: props.selectMeasurement,
    })),
);

export interface IIndoorState {
    core: IndoorState;
}

export function indoorReducer(state, action): any {
    return _indoorReducer(state, action);
}

export const indoorReducers: ActionReducerMap<IIndoorState> = {
    core: indoorReducer,
};
