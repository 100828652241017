<div class="reset-code-form">
    <div class="login-title reset-code-form-title">{{TEXTS.LOGIN_WINDOWS.resetPassTitle}}</div>

    <div class="login-description reset-code-form-description" [innerHTML]="TEXTS.LOGIN_WINDOWS.weHaveSent"></div>

    <form class="login__fieldset" (ngSubmit)="onSubmit()" [formGroup]="resetCodeForm" novalidate>
        <input type="submit" [style.display]="'none'" />

        <div class="login-input-group reset-code-form-input-group">
            <input class="login-input"
                type="text"
                autocomplete="one-time-code"
                formControlName="code"
                [class.login-input--danger]="hasErrors"
                [attr.placeholder]="TEXTS.LOGIN_WINDOWS.verCode"
            />

            <div class="login__info reset-code-form__info">
                <div class="login-errors reset-code-form__errors">
                    <ng-container *ngIf="hasErrors">
                        {{(harvester.formError$ | async) || TEXTS.LOGIN_WINDOWS.wrongCode}}
                    </ng-container>
                </div>
            </div>
        </div>

        <button type="submit" class="login-submit-button" data-cy="submit">
            <ca-login-button [disabled]="resetCodeForm.invalid" (clickAction)="onSubmit()">
                <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                <ng-template #showContent>{{TEXTS.LOGIN_WINDOWS.sendReset}}</ng-template>
            </ca-login-button>
        </button>

        <a class="login-nav-link reset-code-form__back-btn" [routerLink]="['/', loginPage.Login]">
            {{TEXTS.LOGIN_WINDOWS.backToSign}}
        </a>
    </form>
</div>
