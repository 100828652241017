<div class="aqi_indicator aqi_indicator-{{avgAqi$ | async}}" (click)="open()"></div>

<div *showDirective="showLegend" class="aqi_legend aqi_legend-m">
    <div class="scroll aqi_legend__wrapper-m">
        <div class="aqi_legend__header aqi_legend__header-m">
            <div class="aqi_legend__navigation_btn aqi_legend__navigation_btn">
                <div class="aqi_legend__arrow_back" (click)="close()"></div>
                <div class="aqi_legend__btn_close" (click)="close()">{{TEXTS.COMMON.close}}</div>
            </div>
            <div class="aqi_legend__title">Air Quality Index</div>
            <div class="aqi_legend__description aqi_legend__description-m" [innerHtml]="TEXTS.TEXT_DESCRIPTION.airQuality"></div>
        </div>
        <div class="aqi_legend__content aqi_legend__content-m">
            <div class="aqi_legend__measure_aqi">
                <div *ngFor="let i of arr;" class="aqi_legend__tr aqi_legend__tr-m">
                    <div class="aqi_legend__color_circle aqi_legend__color_circle-{{i}}"
                        [ngClass]="{'aqi_legend__color_circle-select': i === findSelectAqi(avgAqi$ | async) }"
                    ></div>
                    <div [ngStyle]="{fontWeight: i === findSelectAqi(avgAqi$ | async) ? 'bold' : ''}">
                        {{TEXTS.AQI_THREE_TEXTS[i]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>