// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/search-results.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "search-result-not-found{display:block;width:100%}.search_rnf{padding:30px 30px 20px}.search_rnf__icon{width:41px;height:41px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;margin:0 auto}.search_rnf__title{padding:30px 0 12px;text-align:center;font-weight:600;margin-bottom:4px;font-size:16px;line-height:21px;letter-spacing:-.4px;color:#404655}.search_rnf__text{text-align:center;font-weight:400;font-size:13px;line-height:15px;letter-spacing:-.2px;color:#b8bfcc}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
