import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Device, DashboardStatsModel } from './dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardFacadeService {
    updateCollection$ = new BehaviorSubject<Device[]>([]);
    updateStats$ = new BehaviorSubject<DashboardStatsModel>(null);

    rateInterval: number;
    stats: DashboardStatsModel;

    setRateInterval(rate: number) {
        return this.rateInterval = rate;
    }
}
