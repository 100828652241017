import { Component, OnInit } from '@angular/core';

import Actions from '../../../../../map/actions';
import State from '../../../../../map/state';
import { ConfigPage } from './config.types';
import AdminPanelActions from '../admin-panel/actions';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'config-panel',
    template: `
        <config-title
            [currentPage]="currentPage"
            (selectPage)="selectPage($event)"
        ></config-title>

        <div class="config_panel__content">
            <ng-container [ngSwitch]="currentPage">
                <config-menu *ngSwitchDefault (selectPage)="selectPage($event)"></config-menu>
                <coefficient-view *ngSwitchCase="configPage.COEFFICIENT" (selectPage)="selectPage($event)"></coefficient-view>
                <browser-tokens *ngSwitchCase="configPage.ACTIVITY_HISTORY"></browser-tokens>
                <api-tokens *ngSwitchCase="configPage.API_TOKENS"></api-tokens>
                <setting-lang-selector *ngSwitchCase="'language'"></setting-lang-selector>
            </ng-container>
        </div>
    `,
    styleUrls: ['config.component.less']
})
export class ConfigPanelComponent implements OnInit {
    apState = this.state.adminPanel;

    configPage = ConfigPage;
    currentPage = ConfigPage.HOME;

    constructor(
        private readonly actions: Actions,
        private readonly state: State,
        public apActions: AdminPanelActions,
        private store: Store
    ) {}

    ngOnInit() {
        // TODO перевести на store apState.tokens и можно избавится от actions & state
        this.store.select(selectGroupInfo).pipe(filter(g => !!g), take(1))
            .subscribe(() => this.apActions.updateTokens())
    }

    selectPage = (page: ConfigPage) => this.currentPage = page;
}
