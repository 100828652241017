import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupListItemComponent } from 'projects/cityscreen/src/components/group-list-item/group-list-item.component';
import { GroupListItemMobileComponent } from 'projects/cityscreen/src/components/group-list-item-mobile/group-list-item-m.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        GroupListItemComponent,
        GroupListItemMobileComponent
    ],
    declarations: [
        GroupListItemComponent,
        GroupListItemMobileComponent
    ]
})
export class GroupListItemModule {}
