import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { LittleComponentsModule } from '../little-components/little-components.module';

import { UserRecord } from './components/user-record/user-record.component'
import { UsersList } from './components/users-list/users-list.component'
import { EditUser } from './components/edit-user/edit-user.component'
import { UsersPage } from './components/users-page/users-page.component'
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { ButtonAddModule } from 'projects/cityscreen/src/components/admin-panel/components/button-add/button-add.module';
import { SearchInputModule } from 'projects/shared/modules/form-components/search-input/search-input.module';
import { LoadersModule } from 'projects/cityscreen/src/components/spinner/loaders.module';
import { UsersPageWrapperComponent } from './components/users-page-wrapper/users-page-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        DirectivesModule,
        LittleComponentsModule,
        ScrollingModule,
        SharedComponentsModule,
        SearchInputModule,
        ButtonAddModule,
        LoadersModule,
    ],
    exports: [
        UsersPage,
        UsersPageWrapperComponent,
    ],
    declarations: [
        UserRecord,
        UsersList,
        EditUser,
        UsersPage,
        UsersPageWrapperComponent,
    ]
})
export class UsersModule {}
