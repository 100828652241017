import { Component } from '@angular/core';
import { TabModel } from 'src/namespace';
import { PAGES } from '../../namespace';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { Router } from '@angular/router';
import { TEXTS } from 'src/texts/texts';
import { Store } from '@ngrx/store';
import { selectGroupInfo } from 'projects/cityscreen/src/modules/core/store/selectors';

@Component({
    selector: 'posts-and-devices-header',
    templateUrl: './posts-and-devices-header.component.html',
    styleUrls: ['./posts-and-devices-header.component.less', '../../posts-and-devices.common-styles.less']
})
export class PostsAndDevicesHeaderComponent  {

    TEXTS = TEXTS;
    pages = PAGES;

    tabs: TabModel[] = [
        {
            isDisabled: false,
            type: 1,
            id: PAGES.postsList,
            title: this.TEXTS.ADMIN_PANEL.OM,
        },
        {
            isDisabled: false,
            type: 2,
            id: PAGES.devicesList,
            title: this.TEXTS.SERVICE_PAGE.devices,
        },
    ]
    selectedTab: TabModel = this.tabs[0];
    subscription;

    selectGroupInfo = selectGroupInfo;

    constructor(
        public postsAndDevicesFacade: PostsAndDevicesFacade,
        private router: Router,
        public store: Store
    ) {}

    getActiveTab() {
        return this.router.url.includes(this.pages.devicesList) ? this.tabs[1] : this.tabs[0];
    }

    openPage(tab: TabModel) {
        this.postsAndDevicesFacade.openPage(PAGES.networks + '/' + tab.id);
    }
}
