<div class="plumes_control_points_card">
    <div class="plumes_control_points_card__content" [ngStyle]="{cursor: isFalseNumber(currentPointValues) ? 'default' : 'pointer'}">
        <div class="plumes_control_points_card__name">{{point.name}}</div>
        <div class="plumes_control_points_card__point">
            <div class="plumes_control_points_card__point-coord">
                {{point.lat.toFixed(3)}}
            </div>
            <div class="plumes_control_points_card__point-coord">
                {{point.lon.toFixed(3)}}
            </div>
        </div>
    </div>

    <div class="plumes_control_points_card__value"
        [class.plumes_control_points_card__value-active]="point.id === activePointId"
        [ngStyle]="{cursor: isFalseNumber(currentPointValues) ? 'default' : 'pointer'}"
    >
        <div class="plumes_control_points_card__value_number">
            {{isFalseNumber(currentPointValues) ? '-' : roundValue(currentPointValues)}}
        </div>
        <div class="plumes_control_points_card__value_description" [innerHTML]="measureUnit"></div>
    </div>

    <div class="plumes_control_points_card__more"
        #popupOpener
        caTooltip
        caTooltipPlacement="left"
        [caTooltipTemplate]="actionsTooltip"
        (click)="openActions()"
    ></div>
    <ng-template #actionsTooltip>
        <ca-tooltip *ngIf="!popupActive" [text]="texts.COMMON.actions"></ca-tooltip>
    </ng-template>
</div>
