<div class="tags-list">
    <ng-container *ngIf="isExpanded || !collapseAll">
    <div class="tags-list__item"
        *ngFor="let tag of tags | slice : 0 : maxVisibleTags; index as i"
    >
        {{tagsMapper(tag)}}
        <cross-button class="tags-list__delete"
                      size="small"
                      *ngIf="editable"
                      (click)="remove(i)"
        ></cross-button>
    </div>
    </ng-container>
    <ng-container *ngIf="textNodesFns">
    <div class="tags-list__item tags-list__item--expand"
        *ngIf="tags.length > maxVisibleTags"
        (click)="expandList()"
    >
        {{collapseAll ? textNodesFns.all() : textNodesFns.expand(tags.length - maxVisibleTags)}}
    </div>
    <div class="tags-list__item tags-list__item--expand"
        *ngIf="isExpanded"
        (click)="collapseList()"
    >
        {{textNodesFns.collapse()}}
    </div>
    </ng-container>
</div>
