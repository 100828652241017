import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { skipUntil, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PACKET_VALUE_TYPE_ID } from 'src/config';
import { WindowGlobalVars } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { shortDateFormatWithToday } from 'src/utils/date-formats';

import {
    DeviceExcessInfo,
    MoExcessInfo,
    NotificationEventType,
    NotificationFeedItem,
    NotificationSubscribeType,
    ServiceExcessType
} from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';

import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';

import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { selectMos } from 'projects/cityscreen/src/modules/core/store/selectors';

const enum DurationMap {
    STARTED = 'begin',
    CONTINUING = 'continue'
};

declare const window: WindowGlobalVars;

@Component({
    selector: 'event-body',
    templateUrl: 'event-body.component.html',
    styleUrls: ['event-body.component.less']
})
export class EventBody implements OnChanges {
    @Input() notification: NotificationFeedItem;
    @Input() included = false;
    @Input() todayDate: Date;
    @Input() disableNavigation: boolean;
    @Input() hasMarker: boolean;

    @Output() gotoMonitoringObject = new EventEmitter<{
        moId: number;
        dateTime: string;
    }>();

    @Output() gotoDeviceInfo = new EventEmitter<string>();

    @Output() gotoIndoorMonitoringObject = new EventEmitter<{
        moId: number;
        dateTime: string;
        mmt?: string;
    }>();

    durationType: DurationMap;
    textsNotification = TEXTS.NOTIFICATIONS;
    eventTypes = NotificationEventType;
    serviceExcessTypes = ServiceExcessType;
    beginFormatted = '';
    updateFormatted = '';
    pdkValues = '';
    valueName = '';

    constructor(
        private sharedCoreFacade: SharedCoreFacade,
        private time: Time,
        private store: Store,
    ) {}

    ngOnChanges() {
        this.durationType = this.hasEnded ? DurationMap.CONTINUING : DurationMap.STARTED;

        if (this.isPdkType) {
            this.beginFormatted = shortDateFormatWithToday(this.todayDate, this.notification.BeginTime);
            this.updateFormatted = shortDateFormatWithToday(this.todayDate, this.notification.UpdateTime);
            this.valueName = this.getValueName(this.notification.PdkValueType);

            if (!this.included) {
                this.pdkValues = this.getPdkValues();
            }
        }
    }

    get hasEnded() {
        return this.notification.EventType === this.eventTypes.End;
    }

    get isPdkType() {
        return this.notification.SubscribeType === NotificationSubscribeType.PdkExcess;
    }

    get isServiceType() {
        return this.notification.SubscribeType === NotificationSubscribeType.ServiceDevice;
    }

    getValueName(value: number) {
        const name = PACKET_VALUE_TYPE_ID[value];
        return TEXTS.NAMES[name];
    }

    getPdkValues() {
        const { MaxValue, PdkMrValue } = this.notification.PdkMoContent;
        return [
            this.valueName,
            `${this.textsNotification.qmax}:`,
            (MaxValue / PdkMrValue).toFixed(1),
            this.textsNotification.excessLevelUnits
        ].join(' ');
    }

    showInTimeline(moInfo: MoExcessInfo) {
        const { Begin, Continue, End } = this.eventTypes;
        const { BeginTime, UpdateTime, EndTime } = this.notification;
        let dateTime: string = {
            [Begin]: BeginTime,
            [Continue]: UpdateTime,
            [End]: EndTime,
        }[this.notification.EventType];

        const { MoId } = moInfo;

        // remove fine details for the charts
        dateTime = moment(dateTime).seconds(0).milliseconds(0).toISOString().replace('.000', '');

        if (!this.hasMarker) {
            /**
             * Naive approach: assume that only indoor MOs are not included in markers list.
             * @todo Populate indoor posts data if this module is enabled.
             */
            this.store.select(selectMos).pipe(
                take(1),
            ).subscribe((mos) => {
                if (mos.findIndex(mo => mo.id === moInfo.MoId) !== -1) {
                    this.gotoIndoorMonitoringObject.emit({
                        moId: MoId,
                        dateTime,
                        mmt: this.valueName,
                    });
                } else {
                    window.sentryCaptureException?.(
                        new Error(`Event feed: MO not found. [MoId=${MoId}]`)
                    );
                }
            });

            return;
        }

        const { comparedChart } = this.sharedCoreFacade.actionObservables;

        this.time.timeUpdated.pipe(
            skipUntil(comparedChart),
            take(1)
        ).subscribe(() => {
            this.showAfterChartUpdates(dateTime);
        });

        this.gotoMonitoringObject.emit({
            moId: MoId,
            dateTime
        });
    }

    openDeviceInfo(deviceInfo: DeviceExcessInfo) {
        this.gotoDeviceInfo.emit(deviceInfo.SerialNumber);
    }

    private showAfterChartUpdates(dateTime: string) {
        const type = PACKET_VALUE_TYPE_ID[this.notification.PdkValueType];
        const measure = this.sharedCoreFacade.getMeasuresList().find(m => m.type === type);
        if (measure) {
            this.sharedCoreFacade.setMeasuresSelected([measure])
        }
        const time = moment(dateTime).valueOf();
        // TODO отрефакторить все вызовы updateCurrentTime которые перебивают этот
        setTimeout(() => this.time.timeUpdate.next({time}), 1000);
    }
}
