<div class="device-description scroll">
    <div class="device-description__others-info">
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.type"
                                [description]="device.model"></information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.dataTransfer">
                <alert-tags
                        [text]="device.offline ? TEXTS.POSTS_AND_DEVICES.offline : TEXTS.POSTS_AND_DEVICES.online"
                        [typeTag]="device.offline ? 'gray' : 'royalBlue'"></alert-tags>
            </information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.POSTS_AND_DEVICES.power">
                <alert-tags [text]="getTextOfPower()"
                            [typeTag]="getTypeOfPower()"></alert-tags>
            </information-column>
        </div>
    </div>
    <information-column class="device-description__others-info--mo"
                        [title]="TEXTS.POSTS_AND_DEVICES.postsMonitoring">
        <div *ngIf="device.linksToMo">
            <ng-container *ngFor="let mo of device.linksToMo">
                <static-tags
                        (click)="openPage(pages.networks + '/' + pages.postsList + '/' + pages.details + '/' + mo.id)"
                        class="device-description__static-tags--mo"
                        [isActiveElement]="true"
                        [text]="mo.name"
                        [typeTag]="'mo'"
                ></static-tags>
            </ng-container>
        </div>
        <div class="device-description__others-info--not-attached"
             *ngIf="!device.linksToMo || device.linksToMo.length == 0"
             [innerHTML]="TEXTS.LIST_DEVICES.equipmentNotAttached">
        </div>
    </information-column>
    <section-line [text]="TEXTS.POSTS_AND_DEVICES.info"></section-line>
    <div class="device-description__others-info">
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.EDIT_STATION.model"
                                [description]="device.model"></information-column>
            <information-column [title]="TEXTS.EDIT_STATION.startTimeStation"
                                [description]="device.startWork"></information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.EDIT_STATION.lastTimeStation"
                                [description]="device.lastTime"></information-column>
            <information-column [title]="TEXTS.EDIT_STATION.versionH"
                                [description]="device.hardware"></information-column>
        </div>
        <div class="device-description__others-info--column">
            <information-column [title]="TEXTS.LIST_DEVICES.interval"
                                [description]="device.intervalSec ? device.intervalSec / 60 + TEXTS.LIST_DEVICES.min : '--'"></information-column>

            <information-column [title]="TEXTS.EDIT_STATION.versionS"
                                [description]="device.soft"></information-column>
        </div>
    </div>
<!--    <section-line-->
<!--            [text]="TEXTS.POSTS_AND_DEVICES.relatedDevices"></section-line>-->
<!--    <div *ngIf="device.childDevices.length != 0"-->
<!--         class="device-description__others-info device-description__others-info&#45;&#45;wrap">-->
<!--        <ng-container *ngFor="let device of device.childDevices">-->
<!--            <div class="device-description__static-tags">-->
<!--                <static-tags-->
<!--                        [text]="device.serialNumber"-->
<!--                        [description]="device.model"-->
<!--                        [isOffline]="false"-->
<!--                        [typeTag]="'device'"-->
<!--                ></static-tags>-->
<!--            </div>-->
<!--        </ng-container>-->
<!--    </div>-->
<!--    <div *ngIf="device.childDevices.length == 0"-->
<!--         class="device-description__others-info device-description__others-info&#45;&#45;not-device"-->
<!--         [innerHTML]="TEXTS.LIST_DEVICES.noConnectedDevices">-->
<!--    </div>-->
    <!--    <section-line [text]="TEXTS.POSTS_AND_DEVICES.actionHistory"></section-line>-->
    <!--    <div class="device-description__action-history scroll">-->
    <!--        <div *ngFor="let ah of actionHistory; let i = index;">-->
    <!--            <information-string-->
    <!--                    class="device-description__information-string {{i > 4 && !isShowActionHistory ? 'device-description__information-string&#45;&#45;hide' : ''}}"-->
    <!--                    [title]="ah.date"-->
    <!--                    [description]="ah.text"></information-string>-->
    <!--        </div>-->
    <!--        <btn-accordion-->
    <!--                (click)="isShowActionHistory = !isShowActionHistory"-->
    <!--                [text]="isShowActionHistory ? TEXTS.POSTS_AND_DEVICES.showLess : TEXTS.POSTS_AND_DEVICES.showMore"-->
    <!--                [isActiveArrow]="isShowActionHistory"></btn-accordion>-->
    <!--    </div>-->

    <ng-container *ngIf="device.model === CITYAIR_STND_DEVICE">
        <section-line [text]="TEXTS.POSTS_AND_DEVICES.documentation"></section-line>

        <div class="device-description__documentation">
            <link-on-document class="device-description__link-on-document"
                              [name]="TEXTS.EDIT_STATION.guideOperationName"
                              [link]="TEXTS.EDIT_STATION.guideOperationLink"></link-on-document>
            <link-on-document class="device-description__link-on-document"
                              [name]="TEXTS.EDIT_STATION.stationScheme"
                              [link]="TEXTS.EDIT_STATION.linkSpecification"></link-on-document>
        </div>
    </ng-container>
</div>
<button-download-data
        [text]="TEXTS.LIST_DEVICES.loadPrimaryData"
        (click)="openPage(path + '/' + pages.deviceReport + '/' + activatedRoute.snapshot.params.id)"
        class="device-description__button-download-data"></button-download-data>