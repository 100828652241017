export class FormError {
    [key: string]: string[];

    constructor(obj: any) {
        Object.keys(obj).forEach(key => {
            if (obj[key].length)
                this[key] = obj[key][0];
        })
    }
}
