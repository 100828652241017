import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

import { OffPanelPopupService } from '../admin-panel/off-panel-popup.service';
import { StorageService } from '../../modules/login/services/auth/storage.service';
import { environment } from '../../environments/environment';
import { SLIDES_DATA } from './onboardings/facelifting-12-2021';

type SlideData = {
    title: string;
    description: string;
    imgUrl: string;
};

const ONBOARDING_KEY = 'onboarding_done';

const FEATURE_NAME = 'facelifting-12-2021';

@Component({
    selector: 'cs-onboarding',
    templateUrl: 'onboarding.component.html',
    styleUrls: ['onboarding.component.less'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
    @ViewChild('popup', { static: true }) popup: TemplateRef<HTMLDivElement>;

    markedAsSeen = false;

    currentSlideIndex = 0;

    slides: SlideData[] = SLIDES_DATA.map(v => ({
        ...v,
        imgUrl: this.getAsset(v.imgUrl)
    }));

    constructor(
        private popupProvider: OffPanelPopupService,
        private storageService: StorageService,
    ) {}

    ngOnInit() {
        const onboardingDone = this.storageService.get(ONBOARDING_KEY) === FEATURE_NAME;

        if (!onboardingDone) {
            this.showPopup();
        }
    }

    ngOnDestroy() {
        this.closePopup();
    }

    showPopup() {
        this.popupProvider.setTemplate(this.popup);
    }

    closePopup() {
        if (this.markedAsSeen) {
            this.storageService.set(ONBOARDING_KEY, FEATURE_NAME);
        }

        this.popupProvider.clear();
    }

    getAsset(asset: string) {
        const deployPath = environment.production ? 'map-build/' : '';
        return asset ? `${deployPath}assets/${asset}` : null;
    }

    nextSlide() {
        if (this.currentSlideIndex < this.slides.length - 1) {
            this.currentSlideIndex++;
        }
    }

    selectSlide(item: SlideData) {
        this.currentSlideIndex = this.slides.indexOf(item);
    }

    isLastSlide() {
        return this.currentSlideIndex >= this.slides.length - 1;
    }
}
