import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextButtonComponent } from './text-button.component';

@NgModule({
    exports: [TextButtonComponent],
    declarations: [TextButtonComponent],
    imports: [
        CommonModule,

    ]
})
export class TextButtonModule {
}
