import {
    Component,
    Input, OnChanges, OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    SimpleChanges,
} from '@angular/core';
import { PostsAndDevicesFacade } from 'projects/cityscreen/src/modules/posts-and-devices/posts-and-devices.facade';
import { ActivatedRoute } from '@angular/router';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { PAGES } from 'projects/cityscreen/src/modules/posts-and-devices/namespace';
import { isRU, TEXTS } from 'src/texts/texts';
import { ActionHistory } from 'projects/cityscreen/src/modules/posts-and-devices/posts-and-devices.collection';
import {
    ANIMATION_OPACITY,
    ANIMATION_MAX_HEIGHT
} from 'projects/shared/utils/config';
import { MonitoringObject, AdminDevice, CITYAIR_STND_DEVICE } from 'src/namespace';
import {
    selectGroupCities,
    selectMyDevices
} from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import AdminPanelActions
    from 'projects/cityscreen/src/components/admin-panel/actions';
import { selectMo } from 'projects/cityscreen/src/modules/core/store/actions';

@Component({
    selector: 'post-details',
    templateUrl: './post-details.component.html',
    styleUrls: ['./post-details.component.less'],
    animations: [ANIMATION_OPACITY, ANIMATION_MAX_HEIGHT]
})
export class PostDetailsComponent implements OnChanges, OnDestroy, OnInit {
    @Input() MO: MonitoringObject;

    CITYAIR_STND_DEVICE = CITYAIR_STND_DEVICE;

    location = '';
    isShowDeletePopup = false;
    isShowMenu = false;
    isRU = isRU;
    isShowActionHistory = false;
    menuPositionTop = 0;
    pages = PAGES;
    path = '';
    TEXTS = TEXTS;
    private popupOpenerElement: HTMLElement;
    nameTargetCard = '';
    idTargetCard = '';
    actionHistory: ActionHistory[] = null;
    wrapperGMT;
    isNoDataSources = true;
    isNoConnectedDevices = true;
    devicesList: AdminDevice[] = [];
    isCheckTheresObserver = false;

    @ViewChild('cardMenu', {static: true}) cardMenu: TemplateRef<HTMLDivElement>;

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        public store: Store<any>,
        private activatedRoute: ActivatedRoute,
        public apActions: AdminPanelActions,
        public popupProvider: OffPanelPopupService) {
        this.idTargetCard = this.activatedRoute.snapshot.params.id
        this.path = this.pages.networks + '/' + this.activatedRoute.snapshot.url[0] + '/' + this.activatedRoute.snapshot.url[1] + '/';
    }

    ngOnInit() {
        this.MO.users.forEach(u => {
            if ((u.name == 'Observer' ||  u.name == 'Наблюдатель')  && u.logins.length > 0) {
                this.isCheckTheresObserver = true;
            }
        })
        const id = this.MO.id
        this.store.dispatch(selectMo({id}));
        this.store.select(selectMyDevices)
            .pipe(filter(smd => !!smd?.length))
            .subscribe(smd => {
                if (smd.find(s => s.id == this.MO.lastConnectedDevice?.id)) {
                    this.isNoConnectedDevices = false;
                    this.devicesList.push(this.MO.lastConnectedDevice);
                    // dob.childDevices.forEach(cd => {
                    // this.devicesList.push(cd);
                    // })
                }
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.MO) {
            this.isNoDataSources = !this.MO.devicesObj.length;
        }
        this.wrapperGMT = TEXTS.GMT_VALUE.find(gmt => gmt[0] == this.MO.gmtOffset)?.[1];
        this.store.select(selectGroupCities)
            .pipe(filter(sgc => !!sgc.length))
            .subscribe(sgc => {

                sgc.forEach(s => {
                    if (s.locationId == this.MO.locationId) {
                        this.location = s.name
                    }
                })
            })
    }

    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    clickMenu({target, positionTop}) {
        this.popupProvider.confirm(() => {
        });
        this.popupProvider.setTemplate(this.cardMenu, () => this.isShowMenu = false);
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop;
        this.isShowMenu = true;
    }

    openPage(target: any, pageName: string) {
        if (target != null) {
            this.closePopup(target)
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        this.closePopup(target);
        this.nameTargetCard = this.MO.name;
        this.isShowDeletePopup = true;
    }

    deleteAccept() {
        this.apActions.deleteMo()
        this.isShowDeletePopup = false;
        this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.activatedRoute.snapshot.url[0]);
    }

    deleteCancel() {
        this.isShowDeletePopup = false;
    }
}
