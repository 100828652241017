import {
    Component,
    Input
} from '@angular/core';

import { NgLocalization } from '@angular/common';

import { TEXTS } from 'src/texts/texts';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';
import { NotificationSubscription } from 'src/api/adminPanel/dataTransformer';
import { showMoreText } from 'projects/cityscreen/src/modules/notifications/notifications.utils';

import 'projects/cityscreen/src/modules/notifications/components/subscription-card-service/subscription-card-service.component.less';

@Component({
    templateUrl: './subscription-card-service.component.html',
    selector: 'subscription-card-service'
})
export class SubscriptionCardService {
    @Input() subscription: NotificationSubscription;
    textsNotification = TEXTS.NOTIFICATIONS;
    saving = false;

    constructor(
        public stateService: NotificationsStateService,
        private ngLocalization: NgLocalization
    ) {}

    showMoreText = showMoreText.bind(null, this.ngLocalization);

    getEmails() {
        return this.subscription.emailsList.join(', ');
    }

    editSubscription() {
        this.stateService.openSettings(this.subscription);
    }

    subscribe() {
        this.stateService.addSubscription(this.subscription)
        .finally(() => {
            this.saving = false;
        });
    }
}
