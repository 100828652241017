<div class="point" [class.point-active]="isActive">
    <div class="point__name ellipsis">{{point.name}}</div>

    <div class="point__plug"></div>

    <div class="point__measure">
        <aqi-circle class="point__circle" [numberAqi]="measurement?.aqi?.indoorAqi?.valueScaled10"></aqi-circle>
    </div>

    <div *ngFor="let name of orderOfMeasures" class="point__measure">
        <span class="point__value">{{isFalseNumber(measurement && measurement[name.toLowerCase()]) ? '-' : measurement[name.toLowerCase()].toFixed(0) | number: '1.0-1'}}</span>
        <span class="point__description" [innerHTML]="getUnit(name)"></span>
    </div>
</div>
