import { Component, Input } from '@angular/core';

@Component({
  selector: 'non-data-screen',
    template: `
        <div class="non-data-screen">
            <div class="non-data-screen__text">{{text}}</div>
            <div class="non-data-screen__img"></div>
        </div>`,
  styleUrls: ['./non-data-screen.component.less']
})
export class NonDataScreenComponent {
    @Input() text = '';
}
