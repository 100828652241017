import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { AnalyticChartTitleModule } from 'projects/cityscreen/src/components/analytic-charts/analytic-chart-title/analytic-chart-title.module';
import { DoughnutChartComponent } from 'projects/cityscreen/src/components/analytic-charts/doughnut-chart/doughnut-chart.component';

@NgModule({
    imports: [
        CommonModule,
        ChartsModule,
        AnalyticChartTitleModule
    ],
    declarations: [
        DoughnutChartComponent,
    ],
    exports: [
        DoughnutChartComponent,
    ]
})
export class DoughnutChartModule {
}
