import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Calendar } from './calendar';
import { Calendar_m } from './calendar.mobile';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { InputForDropdownModule } from 'projects/cityscreen/src/components/input-for-dropdown/input-for-dropdown.module';
import { InputDateRangeModule } from 'src/calendar/input-date-range/input-date-range.module';
import { CalendarMonthsModule } from 'src/calendar/calendar-months/calendar-months.module';

@NgModule({
    imports: [
        InputForDropdownModule,
        CommonModule,
        DirectivesModule,
        InputDateRangeModule,
        CalendarMonthsModule,
    ],
    exports: [
        Calendar,
        Calendar_m,
    ],
    declarations: [
        Calendar,
        Calendar_m,
    ]
})
export class CalendarModule {
}
