import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';

import { SidebarToggleButtonComponent } from './components/sidebar-toggle-button/sidebar-toggle-button.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        TooltipsModule,
    ],
    providers: [
    ],
    exports: [
        SidebarToggleButtonComponent,
        MenuComponent,
        MenuButtonComponent,
    ],
    declarations: [
        SidebarToggleButtonComponent,
        MenuComponent,
        MenuButtonComponent,
    ]
})
export class SidebarModule {}
