import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Alignment, Map, ResourceType, Style } from 'mapbox-gl';
import { MarkerComponent } from 'ngx-mapbox-gl/lib/marker/marker.component';
import { Observable, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    pluck,
    switchMapTo,
    takeUntil,
    tap,
    withLatestFrom,
    distinctUntilKeyChanged,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ALERT_COLORS, CITY_ZOOM_SHOW_HEXAGON, MARKER_AREA_OPACITY, RESIZE_TIMEOUT } from 'src/config';

import {
    copyDiffMarkers,
    CopyMarkerObj,
    createTimeSequence,
    createTimeSequencePlumes,
    detectTouchDevice,
    differentObject,
    diffMarkers,
    createBoundaries,
} from 'src/utils';

import { Marker_model, namePages, NO2, PM10, PM2, SO2, WindowGlobalVars } from 'src/namespace';

import { TEXTS } from 'src/texts/texts';
import { PlumesFacadeService } from 'projects/cityscreen/src/modules/plumes/plumes-facade.service';
import { MapboxFacadeService } from 'projects/cityscreen/src/modules/mapbox/mapbox-facade.service';
import { ControlPoint } from 'projects/cityscreen/src/modules/plumes/services/models/control-point-model';
import { Source, RunPlume } from 'projects/cityscreen/src/modules/plumes/services/models/run-model';
import { PlumesCollectionService } from 'projects/cityscreen/src/modules/plumes/plumes-collection.service';
import { ExtraLayer, SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { CoreFacade } from 'projects/cityscreen/src/modules/core/core-facade';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import {
    GroupExtConfigName,
    GroupFeaturesService,
    GroupMapSettings,
    GroupTilePlayerSettings,
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';
import { VangaAuthService } from 'projects/cityscreen/src/modules/plumes/services/vanga-auth/vanga-auth.service';
import { environment } from 'projects/cityscreen/src/environments/environment';
import { PlumesApi } from 'projects/cityscreen/src/modules/plumes/services/api/plumes-api';
import { mapLoaded } from 'projects/cityscreen/src/modules/core/store/actions';
import { selectMapLoaded } from 'projects/cityscreen/src/modules/core/store/selectors';

import { COMPARE_LIMIT } from 'projects/shared/utils/other-utils';
import { TIMELINE_STEP } from 'projects/shared/utils/config';
import MapState from '../../mapState';
import { MapActionsInterface } from '../../mapActionsInterface';
import { MapActionsWrapper, MapStateWrapper } from '../../wrapper';
import { ForecastControlService } from '../../services/forecast-control.service';
import { TilePlayer } from './tile-player';
import { DOMAINS_FORECASTS } from './domain-forecasts.settings';
import { DomainTilesPlayer } from './domain-tiles-player/domain-tiles-player';
import { Substance } from './domain-tiles-player/substance.enum';
import { IAuthorizeHelper } from './domain-tiles-player/domain-config.type';
import { RoutingService } from 'projects/cityscreen/src/modules/core/routing.service';

declare let window: WindowGlobalVars;

const CUSTOM_ALIGNMENT = 'forbid-rotateXZ';

const EMPTY_MAP_STYLE = {
    version: 8,
    name: 'Empty',
    center: [0, 0],
    zoom: 0,
    sources: {},
    layers: []
};

const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/light-v10';

const SUBSTANCES_MAP: Record<string, Substance> = {
    [PM2]: Substance.PM25,
    [PM10]: Substance.PM10,
    [NO2]: Substance.NO2,
    [SO2]: Substance.SO2,
};

const DOMAIN_BUCKET_URL = 'https://tiles.cityair.io/v1/public/forecast';
const RESTRICTED_BUCKET_URL = 'https://tiles.cityair.io/v1/r';
// const RESTRICTED_BUCKET_URL = '/extra/v1/r'; // local proxy

const PLUMES_TIME_STEP = 20 * 60 * 1000; // 20 minutes

@Component({
    selector: 'mapbox-map',
    templateUrl: 'mapbox.component.html',
    styleUrls: ['mapbox.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapboxMapComponent implements OnInit, OnDestroy, DoCheck, AfterViewChecked {
    @Input() globalState: Readonly<MapStateWrapper>;
    @Input() globalActions: Readonly<MapActionsWrapper>;

    @ViewChildren('cityMarkers') cityMarkers: QueryList<MarkerComponent>;

    onDestroy$ = new Subject<void>();

    contoursData: GeoJSON.FeatureCollection<GeoJSON.Polygon>;

    mapState: MapState;
    gmActions: MapActionsInterface;
    isTouchDevice: boolean;

    mapSettings: GroupMapSettings = {};

    groupFeaturesLayer: GeoJSON.FeatureCollection<GeoJSON.Geometry>;

    compareLimit = COMPARE_LIMIT;
    showMarkersArea = false;
    markersData: GeoJSON.FeatureCollection<GeoJSON.LineString>;

    notifiableMarkers: Marker_model[];

    TEXTS = TEXTS;

    timeStep = TIMELINE_STEP;

    _copy_myMarkers: CopyMarkerObj[] = [];
    _copy_moMarkers: CopyMarkerObj[] = [];
    _copy_citiesMarkers: CopyMarkerObj[] = [];
    _copy_comparedStationsIds: number[] = [];
    _copy_cityMod: boolean = null;
    _copy_compareMod: boolean = null;
    _copy_userLocLat: number = null;
    _copy_time: number = null;
    _copy_zoom: number = null;
    _copy_showContours: boolean = null;
    _copy_showOverlay: boolean = null;
    _copy_showTooltip: boolean = null;
    _copy_notifiableMos: number[] = null;
    _copy_showPlumes: boolean = null;
    _enabledExtraLayers: ExtraLayer[] = [];
    _enabledAnimatedExtraLayers: string[] = [];
    _animatedLayersOpacities: {
        [layerId: string]: number[]
    } = null;

    resizeFn: () => void;

    showMap = false;

    tilePlayers: {
        [layerId: string]: TilePlayer
    } = {};

    runSources$: Observable<Source[]>;

    domainTilesPlayer: DomainTilesPlayer;
    domainTilesPlumesPlayer: DomainTilesPlayer;

    constructor(
        private cd: ChangeDetectorRef,
        readonly forecastControlService: ForecastControlService,
        readonly plumesFacadeService: PlumesFacadeService,
        private mapboxFacadeService: MapboxFacadeService,
        readonly sharedCoreFacade: SharedCoreFacade,
        readonly coreFacade: CoreFacade,
        private groupFeaturesService: GroupFeaturesService,
        plumesCollectionService: PlumesCollectionService,
        private vangaAuthService: VangaAuthService,
        private plumesApi: PlumesApi,
        private time: Time,
        private store: Store,
        private routingService: RoutingService
    ) {
        this.runSources$ = time.timeUpdated.pipe(
            takeUntil(this.onDestroy$),
            map(() => plumesCollectionService.getOneRun(plumesFacadeService.getCurrentRunId())?.sources as Source[])
        );

        this.store.select(selectMapLoaded).pipe(
            takeUntil(this.onDestroy$),
            filter(v => v),
            switchMapTo(coreFacade.firstLoad$)
        ).subscribe(() => this.enableMap());

        this.isTouchDevice = detectTouchDevice();

        forecastControlService.isEnabled$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((isEnabled) => {
            if (!isEnabled && this.globalState) {
                this.globalState.map.showingModelMeasure = '';

                this.isForecastEnabled = false;
            }
        });

        routingService.pageChange$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((page) => {
            if (page === namePages.plumes) {
                this.forecastControlService.disable();
            }
        });

        // preload domain tiles images
        sharedCoreFacade.actionObservables.loadBeforePlay.pipe(
            takeUntil(this.onDestroy$),
            withLatestFrom(this.plumesFacadeService.run$),
        ).subscribe(async ([_, run]) => {
            if (this.isForecastLayerVisible()) {
                const timeSequence = createTimeSequence(
                    this.time.getBegin(),
                    this.time.getEnd()
                );

                await this.preloadLayerImages(this.domainTilesPlayer, timeSequence);

            }

            if (this.isPlumesLayerVisible()) {
                const timeSequence = createTimeSequencePlumes(
                    run.getFirstTimestamp(),
                    this.time.getEnd()
                );

                await this.preloadLayerImages(this.domainTilesPlumesPlayer, timeSequence);
            }

            this.sharedCoreFacade.actionObservers.canStartPlay.next();
        });

        forecastControlService.props$.pipe(
            takeUntil(this.onDestroy$),
            filter(props => !!props),
        ).subscribe((props) => {
            this.globalState.map.showingModelMeasure = props.measure;
            this.globalState.map.showOverlay = props.overlay;
            this.globalState.map.showContours = props.contours;

            this.isForecastEnabled = this.mapState.showOverlay && this.sharedCoreFacade.getAllowForecast();
        });

        plumesFacadeService.isEnabled$.pipe(
            takeUntil(this.onDestroy$),
            tap(isEnabled => this.sharedCoreFacade.setIsShowPlume(isEnabled)),
            filter(isEnabled => isEnabled),
            switchMapTo(this.plumesFacadeService.run$),
            filter(run => !!run),
            distinctUntilKeyChanged('id'),
        ).subscribe((run) => {
            this.createPlumesImagePlayer(run);
        });
    }

    async preloadLayerImages(domainTilesPlayer: DomainTilesPlayer, timeSequence: Set<number>) {
        this.sharedCoreFacade.setPercentLoadForecast(0);
        this.sharedCoreFacade.setForecastIsLoadingTrue();

        await domainTilesPlayer.preloadImages(
            timeSequence,
            (percent: number) => this.sharedCoreFacade.setPercentLoadForecast(percent)
        );

        this.sharedCoreFacade.setForecastIsLoadingFalse();
    }

    enableMap() {
        this.sharedCoreFacade.clearMapLayers();
        this.setMapConfig();
        this.createTilePlayers();
        this.checkGroupFeatures();
        this.createForecastImagePlayer();

        this.showMap = true;
    }

    isForecastEnabled: boolean;

    isForecastLayerVisible() {
        return this.isForecastEnabled && this.sharedCoreFacade.getIsCityMod() && this.domainTilesPlayer;
    }

    isPlumesLayerVisible() {
        return this.sharedCoreFacade.getIsShowPlume() && this.domainTilesPlumesPlayer;
    }

    tilesAuthorizerHelper: IAuthorizeHelper = {
        getAuthHeader: () => ({
            Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`
        }),
        refreshToken: () => this.plumesApi.getTilesToken(),
    };

    private createForecastImagePlayer() {
        this.sharedCoreFacade.actionObservables.locationId.pipe(
            takeUntil(this.onDestroy$),
            finalize(() => this.domainTilesPlayer?.destroy()),
            distinctUntilChanged(),
            map((id) => {
                const cityId = this.mapState.citiesMarkers.find(city => city.id === id)?.cityId?.toLowerCase();
                return DOMAINS_FORECASTS[cityId];
            }),
            filter(domain => !!domain),
            tap((domain) => {
                this.domainTilesPlayer?.destroy();

                this.domainTilesPlayer = new DomainTilesPlayer(
                    'forecast',
                    {
                        url: DOMAIN_BUCKET_URL,
                        domain
                    },
                    this.time.timeUpdated.pipe(takeUntil(this.onDestroy$), pluck('time')),
                    this.time.intervalUpdate.pipe(takeUntil(this.onDestroy$)),
                    this.forecastControlService.isEnabled$
                );
            }),
            switchMapTo(this.forecastControlService.props$),
            filter(props => !!props),
            map(props => SUBSTANCES_MAP[props.measure]),
        ).subscribe((substance) => {
            this.sharedCoreFacade.setIsPlayingFalse();
            this.domainTilesPlayer.selectSubstance(substance);
        });
    }

    private createPlumesImagePlayer(run: RunPlume) {
        this.domainTilesPlumesPlayer?.destroy();

        // TODO: SW-1190
        // PM10 as the plumeMeasure parameter won't work
        // since we need to display labels other than PM25 but have to use PM25 tiles
        const substance = Substance.PM25;

        this.domainTilesPlumesPlayer = new DomainTilesPlayer(
            'plumes',
            {
                url: RESTRICTED_BUCKET_URL,
                domain: {
                    slug: [
                        this.coreFacade.getGroupId(),
                        'plumes',
                        run.id
                    ].join('/'),
                    substances: [substance],
                    coordinates: createBoundaries(run.domain.bbox)
                },
                timeStep: PLUMES_TIME_STEP
            },
            this.time.timeUpdated.pipe(
                takeUntil(this.onDestroy$),
                pluck('time'),
                filter(ts => ts >= run.getFirstTimestamp() && ts <= run.getLastTimestamp())
            ),
            this.time.intervalUpdate.pipe(takeUntil(this.onDestroy$)),
            this.plumesFacadeService.isEnabled$,
            this.tilesAuthorizerHelper
        );

        this.sharedCoreFacade.setIsPlayingFalse();
        this.domainTilesPlumesPlayer.selectSubstance(substance);
    }

    private _createTilePlayer(settings: GroupTilePlayerSettings, tzOverride?: number, timeDelayMs: number = 0) {
        const regenerateTilePlayer = ({ begin, end, tzOffset }) => {
            const { layerId } = settings;

            this.tilePlayers[layerId]?.destroy();

            this.tilePlayers[layerId] = new TilePlayer(
                this.time.timeUpdated.pipe(
                    pluck('time'),
                    map((ts) => ts - timeDelayMs)
                ),
                [begin, end].map(d => new Date(d)),
                tzOffset,
                settings
            );
        };

        const getTimelineRange = () => ({
            begin: this.time.getBegin() - timeDelayMs,
            end: this.time.getEnd() - timeDelayMs,
            tzOffset: tzOverride ?? this.sharedCoreFacade.getTzOffset()
        });

        let range = getTimelineRange();

        regenerateTilePlayer(range);

        this.time.timeUpdated
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                const timelineRange = getTimelineRange();

                if (
                    range.begin !== timelineRange.begin ||
                    range.end !== timelineRange.end ||
                    range.tzOffset !== timelineRange.tzOffset
                ) {
                    range = timelineRange;
                    regenerateTilePlayer(range);
                }
            });
    }

    createTilePlayers() {
        const tpSettings = this.groupFeaturesService.getConfig(GroupExtConfigName.tilePlayerSettings) as GroupTilePlayerSettings;

        if (tpSettings) {
            tpSettings.layerId = 'default';
            this._createTilePlayer(tpSettings);
        }
    }

    ngOnInit() {
        this.mapState = this.globalState.map;
        this.gmActions = this.globalActions.mapActions;

        this.mapState.notifiableMos$.subscribe(value => {
            this.mapState.notifiableMos = value;
            this.updateNotifiableMarkersList();
        });
    }

    setMapConfig() {
        this.mapSettings = this.groupFeaturesService.getConfig(GroupExtConfigName.mapSettings) as GroupMapSettings;

        const { tiles, tileSize, minzoom, maxzoom, accessToken } = this.mapSettings;

        if (tiles?.length && tiles[0].split('/{z}')[0]) {
            this.style = EMPTY_MAP_STYLE;

            const groupLayerId = 'group';

            this.addRasterLayer(
                groupLayerId,
                {
                    tiles,
                    tileSize,
                    minzoom,
                    maxzoom,
                },
                accessToken
            );

            this.sharedCoreFacade.toggleMapLayer(groupLayerId, true);
        } else {
            this.style = DEFAULT_MAP_STYLE;
            this.mapboxFacadeService.applyCustomStyles();
        }
    }

    checkGroupFeatures() {
        this.groupFeaturesLayer = this.groupFeaturesService.getConfig(GroupExtConfigName.featuresLayer);
    }

    addRasterLayer(
        id: string,
        options?: {
            tiles?: string[];
            tileSize?: number;
            minzoom?: number;
            maxzoom?: number;
        },
        accessToken?: string
    ) {
        this.sharedCoreFacade.addMapLayer({
            id,
            accessToken,
            source: {
                ...(options || {}),
                type: 'raster',
                url: options.tiles?.[0].split('/').slice(0, 3).join('/') || ''
            }
        });
    }

    ngAfterViewChecked() {
        // TODO: add issue url
        // Workaround for text blurriness in chromium.
        // This fix relies upon mapbox marker implementation:
        // https://github.com/mapbox/mapbox-gl-js/blob/498a96bba904808405ca9b8d8aa5e028689b53d4/src/ui/marker.js#L443
        this.cityMarkers.forEach(marker => {
            const { markerInstance } = marker;

            if (!markerInstance)
                return;

            const alignment = CUSTOM_ALIGNMENT as Alignment;
            if (
                markerInstance.getPitchAlignment() !== alignment ||
                markerInstance.getRotationAlignment() !== alignment
            ) {
                markerInstance
                .setPitchAlignment(alignment)
                .setRotationAlignment(alignment);
            }
        });
    }

    style: Style | string = EMPTY_MAP_STYLE;

    mapboxLoad(map: Map) {
        // TODO: can be moved to the effects
        this.mapboxFacadeService.setMap(map);
        this.gmActions.mapLoaded(map);

        this.store.dispatch(mapLoaded());

        this.mapboxFacadeService.applyCustomStyles();

        // fix for iPad
        if (environment.is_mobile_app) {
            this.resizeFn = () => {
                setTimeout(() => map.resize(), RESIZE_TIMEOUT);
            };
            this.resizeFn();
            window.addEventListener('resize', this.resizeFn);
        }
    }

    ngOnDestroy() {
        if (this.resizeFn) {
            window.removeEventListener('resize', this.resizeFn);
        }

        Object.values(this.tilePlayers).map((tp) => tp?.destroy());

        this.onDestroy$.complete();
    }

    ngDoCheck() {
        if (this.onChangeDetection()) {
            this.updateNotifiableMarkersList();
            this.gmActions.updateAllMarkersIcon();
            this.updateMarkersData();
            this.cd.markForCheck();
        }
    }

    private updateNotifiableMarkersList() {
        const { commonMarkers, notifiableMos } = this.globalState.map;
        const { monitoringObjects } = this.globalState.adminPanel;
        if (!this.notifiableMarkers && notifiableMos) {
            this.notifiableMarkers = commonMarkers.filter(m => monitoringObjects.find(mo => mo.id === m.id));
        }
    }

    private updateMarkersData() {
        this.markersData = {
            type: 'FeatureCollection',
            features: [
                ...this.mapState.myMarkers,
                ...this.mapState.moMarkers
            ].map(marker => ({
                type: 'Feature',
                properties: {
                    color: ALERT_COLORS[marker.aqi],
                    opacity: marker.opacity !== 0 ? MARKER_AREA_OPACITY : 0
                },
                geometry: marker.areaPolygon
            }))
        };
    }

    isInSubscription(moId: number) {
        const { notifiableMos } = this.mapState;
        return notifiableMos && notifiableMos.includes(moId);
    }

    markerIsSelected = (markerId: number) => !!this.sharedCoreFacade.getComparedListObject(markerId);

    openControlPointChart(point: ControlPoint) {
        this.globalActions.openChartControlPoint(point.id, point.name, point.lat, point.lon, this.plumesFacadeService.getCurrentMeasure());
    }

    authorizeTileRequest = (url: string, resourceType: ResourceType) => {
        const [layer] = this.sharedCoreFacade.enabledExtraLayers;

        if (resourceType === 'Image' && url.startsWith(RESTRICTED_BUCKET_URL)) {
            return {
                url,
                headers: {
                    Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`
                }
            };
        } else if (layer?.accessToken && resourceType === 'Tile' && url.startsWith(layer.source.url)) {
            return {
                url,
                headers: {
                    Authorization: `Bearer ${layer.accessToken}`
                }
            };
        }
    };

    currentZoom: number;

    onZoom(zoom: number) {
        this.currentZoom = zoom;
        this.gmActions.zoomChanges(zoom);
    }

    isGreaterThanMinZoom(minzoom: number) {
        return !isNaN(minzoom) && this.currentZoom >= minzoom;
    }

    private onChangeDetection(): boolean {
        if (
            diffMarkers(this._copy_myMarkers, this.mapState.myMarkers) ||
            diffMarkers(this._copy_moMarkers, this.mapState.moMarkers) ||
            diffMarkers(this._copy_citiesMarkers, this.mapState.citiesMarkers) ||
            differentObject(this._copy_comparedStationsIds, this.sharedCoreFacade.getComparedList().map(m => m.id), true) ||
            this._copy_cityMod !== this.sharedCoreFacade.getIsCityMod() ||
            this._copy_compareMod !== this.sharedCoreFacade.getIsCompareMod() ||
            this.showMarkersArea !== this.mapState.currentZoom > CITY_ZOOM_SHOW_HEXAGON ||
            this._copy_zoom !== this.mapState.currentZoom ||
            this._copy_showContours !== this.mapState.showContours ||
            this._copy_showOverlay !== this.mapState.showOverlay ||
            this._copy_userLocLat !== this.mapState.userLocation.lat ||
            this._copy_time !== this.time.getCurrent() ||
            this._copy_showTooltip !== this.mapState.tooltip.show ||
            this._copy_notifiableMos !== this.mapState.notifiableMos ||
            this._copy_showPlumes !== this.sharedCoreFacade.getIsShowPlume() ||
            this._enabledExtraLayers !== this.sharedCoreFacade.enabledExtraLayers ||
            this._enabledAnimatedExtraLayers !== this.sharedCoreFacade.enabledAnimatedExtraLayers ||
            Object.values(this.tilePlayers).some((tp) => tp?.getChangeDetection(this._animatedLayersOpacities[tp.layerId]))
        ) {
            this._copy_myMarkers = copyDiffMarkers(this.mapState.myMarkers);
            this._copy_moMarkers = copyDiffMarkers(this.mapState.moMarkers);
            this._copy_citiesMarkers = copyDiffMarkers(this.mapState.citiesMarkers);
            this._copy_comparedStationsIds = this.sharedCoreFacade.getComparedList().map(m => m.id);
            this._copy_cityMod = this.sharedCoreFacade.getIsCityMod();
            this._copy_compareMod = this.sharedCoreFacade.getIsCompareMod();
            this.showMarkersArea = this.mapState.currentZoom > CITY_ZOOM_SHOW_HEXAGON;
            this._copy_zoom = this.mapState.currentZoom;
            this._copy_showContours = this.mapState.showContours;
            this._copy_showOverlay = this.mapState.showOverlay;
            this._copy_userLocLat = this.mapState.userLocation.lat;
            this._copy_time = this.time.getCurrent();
            this._copy_showTooltip = this.mapState.tooltip.show;
            this._copy_notifiableMos = this.mapState.notifiableMos;
            this._copy_showPlumes = this.sharedCoreFacade.getIsShowPlume();
            this._enabledExtraLayers = this.sharedCoreFacade.enabledExtraLayers;
            this._enabledAnimatedExtraLayers = this.sharedCoreFacade.enabledAnimatedExtraLayers;
            this._animatedLayersOpacities = Object.values(this.tilePlayers).reduce((acc, tp) => ({
                ...acc,
                [tp.layerId]: tp?.layersWindow?.window.map(l => l.opacity) || []
            }), {});
            return true;
        }

        return false;
    }
}
