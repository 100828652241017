import { Component, OnDestroy, OnInit } from '@angular/core';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { ActivatedRoute } from '@angular/router';
import { MonitoringObject, TabModel } from 'src/namespace';
import { CreateDevice, CreateOM, PAGES } from '../../namespace';
import { TEXTS } from 'src/texts/texts';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { ANIMATION_OPACITY } from 'projects/shared/utils/config';
import { selectMo } from 'projects/cityscreen/src/modules/core/store/actions';
import {
    selectCurrentMo,
    selectMyDevices
} from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

export class PATH {
    name: string;
    path: string;
}

@Component({
    selector: 'details-header',
    templateUrl: './details-header.component.html',
    styleUrls: ['./details-header.component.less'],
    animations: ANIMATION_OPACITY
})
export class DetailsHeaderComponent implements OnInit, OnDestroy {
    pages = PAGES;
    name = '';
    selectedMos: MonitoringObject[] = [];
    selectedDevice: CreateDevice[] = [];

    displayMo: MonitoringObject = new CreateOM();
    displayDevice: CreateDevice = new CreateDevice();

    tabs: TabModel[] = [];
    selectedTab: TabModel = null;
    path = '';
    location = '';
    TEXTS = TEXTS;
    navigationLink: PATH[] = [];
    indexSelectElement = 1;
    idTargetCard = '';

    subscribe1;
    subscribe2;

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        public store: Store<any>,
        public popupProvider: OffPanelPopupService
    ) {
    }

    ngOnInit() {
        const snapshot = this.activatedRoute.snapshot
        const idRouter = snapshot.params.id;
        this.idTargetCard = idRouter;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(idRouter);
        if (isCheckExists) {
            this.getDataForDetails(idRouter);
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.activatedRoute.snapshot.url[0].path);
        }
    }

    getDataForDetails(routerId) {
        if (this.activatedRoute.snapshot.url[0].path == PAGES.postsList) {
            const id = Number(routerId);
            this.store.dispatch(selectMo({id}));
            this.subscribe1 = this.store.select(selectCurrentMo)
                .subscribe(scm => {
                    this.name = scm.name;
                    this.selectedMos.push(scm);
                    Object.assign(this.displayMo, scm)
                    if (scm.devicesObj.length > 0) Object.assign(this.displayDevice, scm.devicesObj[0])
                    this.selectedDevice.push(...scm.devicesObj);
                    this.selectedDevice = JSON.parse(JSON.stringify(scm.devicesObj));
                    this.selectedDevice.map(sd => {
                        sd.linksToMo = [];
                        sd.linksToMo.push(scm);
                    })
                    this.toFormTabs()
                    this.createBreadCrumbs();

                })
        } else {
            this.subscribe2 = this.store.select(selectMyDevices)
                .pipe(filter(smd => !!smd?.length))
                .subscribe(smd => {
                    smd.forEach(s => {
                        if (s.serialNumber == this.activatedRoute.snapshot.params.id) {
                            this.name = s.serialNumber;
                            this.selectedMos.push(...s.linksToMo);
                            this.selectedDevice.push(s);
                            Object.assign(this.displayDevice, s)
                            if (s.linksToMo.length > 0) Object.assign(this.displayMo, s.linksToMo[0])
                            this.toFormTabs();
                        }
                    });
                })
            this.createBreadCrumbs();
        }
    }

    toFormTabs() {
        this.selectedMos.forEach(sm => {
            this.tabs.push({
                title: this.selectedMos.length == 1 ? this.TEXTS.POSTS_AND_DEVICES.post : sm.name,
                id: sm.id.toString(),
                type: 1
            })
        })
        this.selectedDevice.forEach(dsn =>
            this.tabs.push({
                title: dsn.serialNumber,
                id: dsn.serialNumber,
                type: 2
            })
        )
        this.selectedTab = this.tabs.find(t => t.id == this.activatedRoute.snapshot.params.id)
        this.tabs.forEach((t, index) => {
            if (t.id == this.activatedRoute.snapshot.params.id) {
                this.indexSelectElement = index
            }
        })
    }

    getSelectedDevice() {
        return this.selectedDevice.find(sm => sm.id.toString() == this.selectedTab.id)
    }

    createBreadCrumbs() {
        this.navigationLink = [{
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices + ' | ' + (this.selectedTab.type == 1 ? TEXTS.POSTS_AND_DEVICES.posts : TEXTS.POSTS_AND_DEVICES.devices),
            path: PAGES.networks + '/' + this.activatedRoute.snapshot.url[0]
        }, {
            name: this.name,
            path: null
        }]
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = tab;
        if (tab.type == 1) {
            this.displayMo = this.selectedMos.find(sm => sm.id.toString() == tab.id)
        } else {
            this.displayDevice = this.selectedDevice.find(sd => sd.serialNumber == tab.id)
        }
        // this.postsAndDevicesFacade.openPage(PAGES.networks + '/' + (tab.type == 1 ? PAGES.postsList : PAGES.devicesList) + '/' + PAGES.details + '/' + this.idTargetCard);
    }

    openPage(target: any, pageName: string) {
        this.postsAndDevicesFacade.openPage(pageName);
    }

    ngOnDestroy() {
        this.subscribe1?.unsubscribe();
        this.subscribe2?.unsubscribe();
    }
}
