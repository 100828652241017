import { AdminDeviceStandalone, MonitoringObject, } from '../../namespace';

// виталино--------------------------------------------------------------------------------------------------------------------------------
import {
    DeviceItem,
    DeviceSourceItem,
    PacketsValueTypeItem
} from '../../../harvester/UiAdminProject8/src/commonData/models';
import { DataApiDevicesResponse } from '../../../harvester/UiAdminProject8/src/commonData/providers/devicesApiProvider/devicesApiModels';

import { isRU } from '../../texts/texts';
import * as moment from 'moment'

function getAdminDeviceParam(d: DeviceItem, source?: DeviceSourceItem[]): AdminDeviceStandalone {
    const model = source ? source.find(s => s.SourceType === d.SourceType) : null;

    let sourceName: string;
    if (source && source.find(s => s.SourceType === d.SourceType)) {
        const _s =  source.find(s => s.SourceType === d.SourceType);
        sourceName = isRU ? _s.NameRu : _s.Name;
    }
    else
        sourceName = '';

    const obj: AdminDeviceStandalone = {
        id: d.DeviceId,
        serialNumber: d.SerialNumber,
        model: model ? model.Name : '',
        name: d.DeviceName,
        startWork: d.DeviceFirstDate ? moment(d.DeviceFirstDate).format('MMM YYYY') : '---',
        serviceDate:  d.DeviceFirstDate ? moment(d.DeviceFirstDate).add(1, 'year').format('MMM YYYY') : '---',
        intervalSec: d.ExtInfo ? d.ExtInfo.DataDeliveryPeriodSec : null,
        // isNotSaveData: d.IsNotSaveData,
        battery: d.ExtInfo.BatOk,
        v220: d.ExtInfo.Ps220,
        offline: d.IsOffline,
        soft: d.ExtInfo  ? d.ExtInfo.SoftwareVersion : null,
        hardware: (d.ExtInfo && d.ExtInfo.SoftwareVersion) ? d.ExtInfo.SoftwareVersion.substr(0, 3) : null,
        lastTime: d.DeviceLastDate ? moment(d.DeviceLastDate).format('HH:mm DD.MM.YY') : '',
        geoLatitude:  null, // d.DeviceLastGeo ?  d.DeviceLastGeo.Latitude : null,
        geoLongitude:  null, // d.DeviceLastGeo ? d.DeviceLastGeo.Longitude : null,
        linksToMo: null,
        namesMo: null,
        sourceId: d.SourceType,
        childDevices: d.ChildDevices.map(cd => getAdminDeviceParam(cd, source)),
        sourceName,

        originChartData: null,
        measuresVal: null,
        lastPacketId: null
    };

    return obj;
}

export type DevicesDataTransformer_model = {
    // measurementInfo: MeasurementInfo_model;
    devices: AdminDeviceStandalone[];
    mos?: MonitoringObject[];
    PacketValueTypes?: PacketsValueTypeItem[];
}

export function devicesDataTransformer(data: DataApiDevicesResponse): DevicesDataTransformer_model {
    // const measurementInfo: MeasurementInfo_model = {};
    //
    // data.PacketsValueTypes.forEach(a => measurementInfo[a.TypeName] = isRU ? a.MeasurementRu : a.Measurement);

    return {
        // measurementInfo,
        devices: <AdminDeviceStandalone[]>data.Devices
            .map(d => getAdminDeviceParam(d, data.DeviceSources))
            .sort((a, b) => a.serialNumber > b.serialNumber ? 1 : -1)
    };
}
