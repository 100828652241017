import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    NotificationsStateService,
    Pages
} from 'projects/cityscreen/src/modules/notifications/notifications-state.service';

import 'projects/cityscreen/src/modules/notifications/components/notifications/notifications.component.less';
import { TabModel } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';

@Component({
    templateUrl: 'notifications.component.html',
    selector: 'notifications'
})
export class Notifications implements OnInit, OnDestroy {
    pages = Pages;

    TEXTS = TEXTS;

    pageTabs: TabModel[] = [
        {
            title: TEXTS.NOTIFICATIONS.eventList,
            type: Pages.EVENT_FEED
        },
        {
            title: TEXTS.NOTIFICATIONS.settingNotification,
            type: Pages.SUBSCRIPTIONS
        }
    ];

    selectedTab = this.pageTabs[0];

    constructor(readonly stateService: NotificationsStateService) {}

    ngOnInit() {
        this.stateService.currentPageObservable$
            .subscribe((page) => {
                this.selectedTab = this.pageTabs.find(t => t.type === page);
            });
    }

    ngOnDestroy() {
        this.stateService.setDefaultState();
        this.stateService.resetData();
    }

    showSelectedTab(tab: TabModel) {
        this.selectedTab = this.pageTabs.find(t => t === tab);
        switch (tab.type) {
            case Pages.EVENT_FEED:
                this.stateService.openEventFeed();
                break;
            case Pages.SUBSCRIPTIONS:
                this.stateService.openSubscriptions();
                break;
        }
    }
}
