import { Component, Input, OnChanges } from '@angular/core';
import { NgLocalization } from '@angular/common';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as moment from 'moment';

import { LANGUAGE, TEXTS } from 'src/texts/texts';
import { shortDateTimeWithoutYear } from 'src/utils/date-formats';
import { Device, DeviceStatus } from '../../services/dashboard.model';
import { createChartOptions } from '../../chartjs.config';
import { STATUS_TYPES } from '../device-status/device-status.types';

const TICK_LINE_COLOR = '#F3F5F6'; // @grey2

const POINT_COLORS = {
    lowBattery: '#FFB800',
    lowSignal: '#404655',
    packetsMissing: '#B8BFCC',
    ok: '#6B66E8'
};

@Component({
    selector: 'dashboard-chart',
    templateUrl: 'dashboard-chart.component.html',
    styleUrls: ['dashboard-chart.component.less']
})
export class DashboardChartComponent implements OnChanges {
    @Input() data: Device;
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() intervalWidth: number;
    @Input() ticks: number;

    chartTooltip = {
        model: null
    };

    tm: NodeJS.Timeout;

    lineChartData: ChartDataSets[] = [];
    lineChartColors: string[] = [];
    lineChartLabels: Label[];
    lineChartOptions: ChartOptions;
    lineChartLegend = false;
    width: number;

    constructor(private ngLocalization: NgLocalization) {}

    ngOnChanges() {
        if (this.data) {
            this.updateChart();

            const data = this.data.chart.map((c, i) => {
                this.lineChartColors[i] = (
                    c.deviceStatus.isBatLow ?
                    POINT_COLORS.lowBattery : c.deviceStatus.isGsmLow ?
                    POINT_COLORS.lowSignal : c.deviceStatus.hasMissingPackets ?
                    POINT_COLORS.packetsMissing : POINT_COLORS.ok
                );

                return {
                    x: new Date(c.time),
                    y: c.packetsRate
                };
            });

            this.lineChartData = [{
                pointBackgroundColor: this.lineChartColors,
                pointHoverBackgroundColor: this.lineChartColors,
                data,
                pointRadius: 4,
                fill: false,
                showLine: false
            }];
        }
    }

    updateChart() {
        this.width = this.ticks * this.intervalWidth;

        const begin = this.beginDate.getTime();
        const step = (this.endDate.getTime() - begin) / (this.ticks - 1);

        this.lineChartLabels = Array(this.ticks).fill('').map((_, i) =>
            i && new Date(begin + i * step).toISOString());

        this.lineChartOptions = Object.assign(
            createChartOptions(this.beginDate, this.endDate, TICK_LINE_COLOR),
            {
                tooltips: {
                    enabled: false,
                    custom: ({ dataPoints }) => {
                        clearTimeout(this.tm);

                        if (dataPoints) {
                            const { value, index } = dataPoints[0];
                            const time = (this.lineChartData[0].data[index] as Chart.ChartPoint).x;

                            this.chartTooltip.model = {
                                title: `${shortDateTimeWithoutYear(moment(time).toDate())}`,
                                labels: this.createLabelsFor(value, this.data.chart[index].deviceStatus)
                            };
                        } else {
                            this.tm = setTimeout(() => {
                                this.chartTooltip.model = null;
                            }, 100);
                        }
                    }
                },
                elements: {
                    point: {
                        borderWidth: 0,
                        hoverBorderWidth: 0
                    }
                },
                hover: {
                    animationDuration: 0
                }
            }
        );

        const linearMaxValue = 2 * this.data.deviceInfo.DevicePacketsRate;
        const maxValue = Math.max(...this.data.chart.map(c => c.packetsRate));
        const yAxis = this.lineChartOptions.scales.yAxes[0];

        if (maxValue > linearMaxValue) {
            yAxis.type = 'logarithmic';
            yAxis.ticks.min = 0;
            yAxis.ticks.max = 10 * maxValue;
        } else {
            yAxis.ticks.min = -10;
            yAxis.ticks.max = linearMaxValue + 10;
        }
    }

    createLabelsFor(value: number, status: DeviceStatus) {
        const category = this.ngLocalization.getPluralCategory(value, LANGUAGE);

        const labels = [
            {
                status: status.hasMissingPackets ? STATUS_TYPES.NO_PACKETS : STATUS_TYPES.OK,
                text: `${value} ${TEXTS.DASHBOARD.packets[category]}`
            }
        ];

        if (status.isBatLow) {
            labels.push({
                status: STATUS_TYPES.LOW_BATTERY,
                text: `${TEXTS.DASHBOARD.deviceMonitorLegend.poweredByBattery}`
            });
        }

        if (status.isGsmLow) {
            labels.push(                                {
                status: STATUS_TYPES.LOW_SIGNAL,
                text: `${TEXTS.DASHBOARD.deviceMonitorLegend.lowGMSSignal}`
            });
        }

        return labels;
    }
}
