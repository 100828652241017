import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { StationForMapPage_model } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { selectMos } from 'projects/cityscreen/src/modules/core/store/selectors';

@Component({
    selector: 'timeline-selected-item',
    templateUrl: 'timeline-selected-item.component.html',
    styleUrls: ['timeline-selected-item.component.less']
})
export class TimelineSelectedItemComponent implements OnInit {
    @Input() item: StationForMapPage_model;
    @Input() lineNumber: number;
    @Input() subtitle?: string;

    @Output() titleOnClick = new EventEmitter<void>();

    name: string;
    description: string;
    coordinates: string;
    additionalClass: string;

    TEXTS = TEXTS;

    titleClick() {
        this.titleOnClick.emit();
    }

    constructor(private store: Store) {}

    ngOnInit() {
        this.name = this.item.pubName || this.item.name;
        this.description = this.subtitle;
        this.additionalClass = this.item.type === 'city' ? 'button_link-underscore' : '';

        if (this.item.isOurStation) {
            this.store.select(selectMos).pipe(
                map(mos => mos.find(m => m.id === this.item.id)),
            ).subscribe(mo => {
                const isCityAirStation = mo?.devicesObj.some(d => d.sourceId === 3);
                const subtitle = isCityAirStation ? TEXTS.STATION_BLOCK.type[this.item.type] : '';
                this.description = this.subtitle || subtitle;
            });
        }
    }
}
