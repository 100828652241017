// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./right_arrow.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../../../../src/little-components/imgs/flag_ru.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../../../../../src/little-components/imgs/flag_en.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".config_menu{padding-left:30px}.config_menu__item{display:flex;align-items:center;height:65px;padding-right:64px;border-bottom:1px solid #e9e9e9;cursor:pointer;font-weight:600;font-size:16px;letter-spacing:-.4px;color:#21242b;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:95%;background-repeat:no-repeat}.config_menu__item:hover{opacity:.8}.config_menu__description{margin-left:16px;font-weight:500;font-size:14px;line-height:16px;letter-spacing:-.35px;color:#95a0b3}.config_menu__flag{flex-grow:2;width:24px;height:18px}.config_menu__flag-ru{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain;background-position:100%}.config_menu__flag-en{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:contain;background-position:100%}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
