import * as moment from 'moment';

function createMonth(i: number, tzOffset: number) {
    function getDay(date) { // получить номер дня недели, от 0(пн) до 6(вс)
        let day = date.day();
        if (day == 0) day = 7;
        return day - 1;
    }

    let arr: Day[][] = [];

    const d = moment().utcOffset(tzOffset).subtract(i, 'months').startOf('month');
    const currentTime = moment().utcOffset(tzOffset);
    const days = d.daysInMonth();

    // заполнить первый ряд от понедельника
    // и до дня, с которого начинается месяц
    arr[0] = [];
    for (let i = 0; i < getDay(d); i++)
        arr[0].push(null);

    let week = 0;
    for (let i = 1; i <= days; i++) {
        if (getDay(d) % 7 == 0) { // понедельник
            week++;
            arr[week] = [];
        }
        arr[week].push({
            text: i,
            timestamp: d.valueOf(),
            // TODO вынести в параметр календаря
            disabled: d.valueOf() > currentTime.valueOf()
        });
        d.add(1, 'days');
    }

    for (let i = arr[week].length; i < 7; i++) {
        arr[week].push(null);
    }

    arr = arr.filter(w => w.length);

    return arr;
}

export class Day {
    text: number;
    timestamp: number;
    disabled: boolean;
}

export class CalendarMonth {
    name: string;
    nameWithoutYear: string;
    year: string;
    daysArr: Day[][];
}

export function createCalendarDays(tzOffset: number): CalendarMonth[] {
    const days: CalendarMonth[] = [];

    for (let i = 0; i < 12; i++)
        days.push({
            name:  moment().utcOffset(tzOffset).subtract(i, 'months').format('MMMM YYYY'),
            nameWithoutYear: moment().utcOffset(tzOffset).subtract(i, 'months').format('MMMM'),
            year: moment().utcOffset(tzOffset).subtract(i, 'months').format('YYYY'),
            daysArr: createMonth(i, tzOffset)
        });

    return days;
}
