<div class="control-point-pin"
    [class.control-point-pin--active]="isSelected"
>
    <div class="control-point-pin__text"
        [ngStyle]="{cursor: isFalseNumber(value) ? 'default' : 'pointer'}"
    >
        <div class="control-point-pin__text__value">
            {{isFalseNumber(value) ? '-' : roundValue(value)}}
        </div>
        <div class="control-point-pin__text__units" [innerHTML]="units"></div>
    </div>
    <div class="control-point-pin__handle"></div>
</div>
