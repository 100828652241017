import { Component, Input } from '@angular/core';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'group-list-item-m',
    templateUrl: './group-list-item-m.component.html',
    styleUrls: ['./group-list-item-m.component.less']
})
export class GroupListItemMobileComponent {
    @Input() name: string;
    @Input() selected: boolean;
    @Input() postsCount: number;
    @Input() iconUrl: string;

    TEXTS = TEXTS;

    constructor(public share: SharedCoreFacade) {
    }
}
