import { Component, EventEmitter, Input, Output } from '@angular/core';

export class PATH {
    name: string;
    path: string;
}

@Component({
  selector: 'header-of-panel',
  templateUrl: './header-of-panel.component.html',
  styleUrls: ['./header-of-panel.component.less']
})
export class HeaderOfPanelComponent {
    @Input() path: PATH[] = [];
    @Input() title = '';
    @Output() outputPath = new EventEmitter<PATH>();

    setOutputPath(path) {
        this.outputPath.emit(path);
    }
}
