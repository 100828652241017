import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import State from 'map/state';
import Actions from 'map/actions';
import { IS_PUBLIC } from 'projects/shared/utils/other-utils';
import { City_model, HUM, PM10, PRES, TEMP } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { PlumesFacadeService } from '../../modules/plumes/plumes-facade.service';
import {
    GroupExtConfigName,
    GroupFeaturesService
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';

import './top-right-elements.component.less';
import { CoreFacade } from '../../modules/core/core-facade';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import { LayerButtonIconType } from 'projects/shared/modules/overlay/components/layer-button/layer-button.component';
import { ForecastControlService } from 'projects/shared/modules/map/services/forecast-control.service';
import { MEASURES_FOR_FORECAST } from 'src/config';

@Component({
    selector: 'cs-top-right-elements',
    templateUrl: 'top-right-elements.component.html'
})
export class CsTopRightElements implements OnInit, OnDestroy {
    @Input() state: State;
    @Input() actions: Actions;

    TEXTS = TEXTS;
    widgetCityId: string;

    onDestroy$ = new Subject<void>();

    LayerButtonIconType = LayerButtonIconType;
    currentMeasure = PM10;
    MEASURES_FOR_FORECAST = MEASURES_FOR_FORECAST;

    constructor(
        @Inject(IS_PUBLIC) readonly isPublic: boolean,
        public plumesFacadeService: PlumesFacadeService,
        public sharedCoreFacade: SharedCoreFacade,
        private groupFeaturesService: GroupFeaturesService,
        public coreFacade: CoreFacade,
        private time: Time,
        readonly forecastControlService: ForecastControlService,
    ) {}

    ngOnInit() {
        this.coreFacade.firstLoad$
            .subscribe(() => {
                this.widgetCityId = this.groupFeaturesService.getConfig(GroupExtConfigName.locationWidget);
                this.currentMeasure = this.plumesFacadeService.getCurrentMeasure();
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getMeasurementItems(data: City_model['lastData']) {
        return [TEMP, PRES, HUM].map((name) => ({
            name,
            value: data[name][0],
            units: TEXTS.MEASURES[name]
        }));
    }

    onPlume() {
        this.plumesFacadeService.enable();
        this.sharedCoreFacade.setIsActiveModelling(false);
        this.forecastControlService.disable();
    }

    offPlume() {
        this.plumesFacadeService.disable();
    }

    updateDateRange({ begin, end, allowUpdate }) {
        this.time.intervalUpdate.next({begin, end, allowUpdate});
    }

    enableModelling() {
        if (this.sharedCoreFacade.getIsShowPlume()) {
            this.plumesFacadeService.disable();
        }

        this.sharedCoreFacade.enableModelling();
    }

    disableModelling() {
        this.sharedCoreFacade.disableModelling();
    }
}
