<div class="page">
    <div class="page__header">
        <div class="header-of-panel-wrapper">
            <header-of-panel [title]="(store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData ? TEXTS.POSTS_AND_DEVICES.postAndDevices : TEXTS.ADMIN_PANEL.OM"></header-of-panel>
        </div>
        <ca-page-tabs *ngIf="(store.select(selectGroupInfo) | async)?.myRole?.viewDeviceData"
                class="page__page-tabs"
                [tabs]="tabs"
                [selectedTab]="getActiveTab()"
                (showTab)="openPage($event)"
        ></ca-page-tabs>
    </div>
    <div class="page__body">
        <router-outlet></router-outlet>
    </div>
</div>
