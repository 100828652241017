import { Component, Input } from '@angular/core';

import './search-result-not-found.less';

@Component ({
    selector: 'search-result-not-found',
    template: `
        <div class="search_rnf">
            <div class="search_rnf__icon"></div>
            <div class="search_rnf__title">
                {{text}}
            </div>
            <div class="search_rnf__text"
                 [innerHtml]="description">
            </div>
        </div>
    `
})
export class SearchResultNotFound {
    @Input() text: string;
    @Input() description: string;
}
