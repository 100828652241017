import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { distinctUntilKeyChanged, map, switchMap, filter } from 'rxjs/operators';

import {
    indoorIntervalDataLoaded,
    indoorPostsListLoaded,
    indoorSelectPoint,
    indoorUpdatedAnalytic,
    indoorUpdateIntervalData,
    indoorUpdatePostsList,
    indoorUpdateTimeIndex,
    indoorDeselectPoints,
    indoorHideAnalytics,
    indoorSetTimeAfterDataLoaded,
} from './actions';

import { onChangeTimeDataIndex, onEnabledChart } from 'projects/cityscreen/src/components/timeline-panel/store/core.actions';

import { IndoorApi } from '../api';

@Injectable()
export class IndoorEffects {
    constructor(
        private actions$: Actions,
        private indoorApi: IndoorApi,
    ) {}

    @Effect()
    indoorUpdatePostsList$ = this.actions$.pipe(
        ofType(indoorUpdatePostsList),
        switchMap(({ timeBegin, timeEnd, groupId }) => this.indoorApi.getPostsMeasurements({ timeBegin, timeEnd, groupId })),
        map(data => indoorPostsListLoaded(data))
    );

    @Effect()
    indoorUpdateMeasurements$ = this.actions$.pipe(
        ofType(indoorUpdateIntervalData),
        switchMap(({ timeBegin, timeEnd, groupId }) => this.indoorApi.getPostsMeasurements({ timeBegin, timeEnd, groupId })),
        map(data => indoorIntervalDataLoaded(data))
    );

    @Effect()
    indoorSelectPoint$ = this.actions$.pipe(
        ofType(indoorSelectPoint),
        switchMap(props => this.indoorApi.getAnalytic(props.id)),
        map(props => indoorUpdatedAnalytic(props))
    );

    @Effect()
    indoorDeselectPoint$ = this.actions$.pipe(
        ofType(indoorDeselectPoints),
        map(() => indoorHideAnalytics())
    );

    @Effect()
    indoorShowChart$ = this.actions$.pipe(
        ofType(indoorSelectPoint),
        map(props => onEnabledChart({ obj: !!props.id }))
    );

    @Effect()
    indoorHideChart$ = this.actions$.pipe(
        ofType(onEnabledChart),
        filter(props => !props.obj),
        map(() => indoorDeselectPoints())
    );

    @Effect()
    indoorTimelineChangeIndex$ = this.actions$.pipe(
        ofType(onChangeTimeDataIndex),
        distinctUntilKeyChanged('index'),
        switchMap(props => of(indoorUpdateTimeIndex(props)))
    );

    @Effect()
    indoorSetTimeAfterDataLoaded$ = this.actions$.pipe(
        ofType(indoorPostsListLoaded),
        map(() => indoorSetTimeAfterDataLoaded())
    );
}
