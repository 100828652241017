import { Component } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { LanguageService } from 'projects/shared/modules/core/services/language.service';
import { ClientConfigService, ClientConfig } from '../../../core/services/client-config/client-config.service';
import { LoginNavigationService } from '../../services/login-navigation/login-navigation.service';
import { LoginPage, LoginPopup } from '../../login.settings';

@Component({
    selector: 'ca-nornickel-login-page',
    templateUrl: 'nornickel-login-page.component.html',
    styleUrls: [
        '../login-page/login-page.component.less',
        'nornickel-login-page.component.less'
    ],
})
export class NornickelLoginPageComponent {
    clientName: string;

    currentLanguage: string;

    TEXTS = TEXTS;

    loginPage = LoginPage;

    loginPopup = LoginPopup;

    resetEmail: string;

    verificationCode: string;

    isInvite = false;

    clientConfig: ClientConfig;

    assets: Record<string, string> = {};

    currentYear = new Date().getFullYear();

    constructor(
        clientConfigService: ClientConfigService,
        languageService: LanguageService,
        readonly navigation: LoginNavigationService,
    ) {
        this.clientConfig = clientConfigService.getConfig();

        this.currentLanguage = languageService.getLanguage();

        const background = this.clientConfig.login?.background;
        const logo = this.clientConfig.login?.logo;

        this.assets = {
            background: background && `url(${clientConfigService.getAsset(background)}`,
            logo: logo && `url(${clientConfigService.getAsset(logo)})`
        };
    }

    setLang(lang: string) {
        localStorage.setItem('lang', lang);
        location.reload();
    }
}
