import { Component, TemplateRef, ViewChild } from '@angular/core';
import { PAGES } from '../../namespace';
import { PostsAndDevicesFacade } from '../../posts-and-devices.facade';
import { AdminDevice, ArrTagDevice } from 'src/namespace';
import { TEXTS } from 'src/texts/texts';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { ANIMATION_OPACITY } from 'projects/shared/utils/config';
import { selectMyDevices } from 'projects/cityscreen/src/modules/core/store/selectors';
import { Store } from '@ngrx/store';
import { selectDevice } from 'projects/cityscreen/src/modules/core/store/actions';
import { filter } from 'rxjs/operators';


export interface Devices {
    arrSerialNumber: string[];
    arrMoName: string[];
}

export class DevicesObj extends AdminDevice {
    arrSerialNumber?: string[];
    arrMoName?: string[];
}

@Component({
    selector: 'devices-list',
    templateUrl: './devices-list.component.html',
    styleUrls: ['./devices-list.component.less', '../../posts-and-devices.common-styles.less'],
    animations: ANIMATION_OPACITY
})
export class DevicesListComponent {

    pages = PAGES;
    devicesList: DevicesObj[] = [];
    path = PAGES.networks + '/' + PAGES.devicesList + '/'
    isShowMenu = false;
    menuPositionTop = 0;
    isShowNotDate = false;
    isShowResultPlaceholder = false;
    TEXTS = TEXTS;
    idTargetCard = '';
    searchQuery = '';
    selectedPost: ArrTagDevice[];
    isShowSearchResultPlaceholder = false
    private popupOpenerElement: HTMLElement;

    constructor(public postsAndDevicesFacade: PostsAndDevicesFacade, public popupProvider: OffPanelPopupService, public store: Store<any>) {
        this.store.select(selectMyDevices)
            .pipe(filter(smd => !!smd?.length))
            .subscribe(smd => {
                smd.forEach(s => {
                    this.devicesList.push({
                        ...s,
                        arrMoName: this.getMoNameAndSerialNumber(s).arrMoName,
                        arrSerialNumber: this.getMoNameAndSerialNumber(s).arrSerialNumber,
                    })
                })
                this.isShowNotDate = this.devicesList.length == 0;
                this.isShowResultPlaceholder = true;
            })
    }

    @ViewChild('cardMenu', {static: true}) cardMenu: TemplateRef<HTMLDivElement>;

    getMoNameAndSerialNumber(devices: AdminDevice): Devices {
        const arr: Devices = {
            arrSerialNumber: [],
            arrMoName: [],
        }
        arr.arrSerialNumber.push(devices.serialNumber)
        // devices.childDevices.forEach(c => arr.arrSerialNumber.push(c.serialNumber))
        devices.linksToMo.forEach(l => {
            arr.arrMoName.push(l.name)
        })
        return arr
    }

    openPage(target: any, pageName: string) {
        const serialNumber = this.idTargetCard;
        this.store.dispatch(selectDevice({serialNumber}));
        if (target != null) {
            this.closePopup(target)
        }
        this.postsAndDevicesFacade.openPage(pageName);
    }

    showDetailsMo(data: {type: string, id: string}) {
        const page = data.type == 'MO' ? PAGES.postsList : PAGES.devicesList;
        this.postsAndDevicesFacade.openPage(PAGES.networks + '/' + page + '/' + this.pages.details + '/' + data.id)
    }

    clickMenu({target, positionTop}, id: string) {
        this.idTargetCard = id;
        this.popupProvider.confirm(() => {
        });
        this.popupProvider.setTemplate(this.cardMenu, () => this.isShowMenu = false);
        this.popupOpenerElement = target;
        this.menuPositionTop = positionTop;
        this.isShowMenu = true;
    }

    closeMenu(e: Event) {
        if (this.isShowMenu && this.popupOpenerElement !== e.target) {
            this.isShowMenu = false;
        }
    }

    closePopup(e: any) {
        if (e.target.id !== 'menuBtn') {
            this.closeMenu(e);
        }
    }

    showPopup(target, type) {
        console.log('showPopup = ', target, '; ', type)
    }
}
