import { Component } from '@angular/core';
import { PATH } from 'projects/cityscreen/src/modules/posts-and-devices/components/post-create/post-create.component';
import { AdminDevice, CheckboxItem, StationDataToExcelRequestProps, TabModel } from 'src/namespace';
import { LANGUAGE, TEXTS } from 'src/texts/texts';
import { FormBuilder } from '@angular/forms';
import { PostsAndDevicesFacade } from 'projects/cityscreen/src/modules/posts-and-devices/posts-and-devices.facade';
import * as moment from 'moment';
import { LOAD_HISTORY_DEFAULT } from 'projects/shared/utils/config';

import { PAGES } from 'projects/cityscreen/src/modules/posts-and-devices/namespace';
import { ActivatedRoute } from '@angular/router';
import { copyObj, saveDataToDisk } from 'src/utils';
import { ErrorTransformer_model } from 'src/api/dataProvider/DataProvider';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import { NgLocalization } from '@angular/common';
import {
    selectMyDevices,
    selectCurrentSerialNumberDevice
} from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
    selector: 'device-report',
    templateUrl: './device-report.component.html',
    styleUrls: ['./device-report.component.less']
})
export class DeviceReportComponent {

    TEXTS = TEXTS;
    pages = PAGES;

    isActiveLoader = false;
    navigationLink: PATH[] = [];
    listDevices: CheckboxItem[] = [];
    myDevices: AdminDevice[] = []
    isShowDropdownForStations = false;
    errorDownloadMsg: string;
    targetMo = '';
    objectForReportStation: StationDataToExcelRequestProps = {
        type: 2,
        timeBegin: null,
        timeEnd: null,
        ids: []
    };

    serialNum;
    isSelectAll = false;
    collapseAll = false;
    tags: string[] = [];
    maxLength = 4;
    editable = false;
    disabledDevices = true;
    idRouter = null;

    listReportType: TabModel[] = [
        {
            type: 1,
            title: 'Excel'
        },
        {
            type: 2,
            title: 'CSV'
        }
    ];


    selectedListReportType: TabModel = this.listReportType[1];

    postsListLabels = (() => {
        const {showMore, collapse} = TEXTS.NOTIFICATIONS;

        return {
            all: () => '',
            expand: (num: number) => {
                return {
                    ru: `${showMore} ${num}`
                }[LANGUAGE] || `${num} ${showMore}`;
            },
            collapse: () => collapse
        };
    })();

    constructor(
        private fb: FormBuilder,
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        public store: Store<any>,
        private ngLocalization: NgLocalization,
        public adminPanelApi: AdminPanelApi,
    ) {
        this.idRouter = this.activatedRoute.snapshot.params.id;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(this.idRouter);
        if (isCheckExists || this.idRouter == 'all') {
            this.getDataForDeviceReport();
            this.isSelectAll = (this.idRouter == 'all');
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.pages.devicesList);
        }
    }

    getDataForDeviceReport() {
        this.objectForReportStation.timeBegin = this.getStndTimeBegin();
        this.objectForReportStation.timeEnd = this.getStndTimeEnd();
        this.store.select(selectCurrentSerialNumberDevice)
            .pipe(take(1))
            .subscribe(smd => {
                this.serialNum = smd
            })

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0]
        })
        if (this.activatedRoute.snapshot.url[1].path == this.pages.details) {
            this.navigationLink.push({
                name: this.serialNum,
                path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0] + '/' + this.activatedRoute.snapshot.url[1] + '/' + this.activatedRoute.snapshot.params.id
            })
        }
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.dataDevice,
            path: null
        })
        const isAllMo = this.activatedRoute.snapshot.params.id == 'all';
        this.store.select(selectMyDevices)
            .pipe(filter(smd => !!smd?.length))
            .subscribe(smd => {
                this.myDevices = copyObj(smd);
                this.formListDevices(isAllMo);
                this.formationTagList();
            })
    }

    formListDevices(isAllMo: boolean = false) {

        this.listDevices = [];
        this.myDevices.forEach(s => {
            if (s.serialNumber == this.serialNum) {
                this.listDevices.push({
                    id: s.id,
                    label: s.serialNumber,
                    selected: true
                })
            } else {
                this.listDevices.push({
                    id: s.id,
                    label: s.serialNumber,
                    selected: isAllMo
                })
            }
        })
    }

    selectAll(selectAll) {
        this.listDevices.map(ls => {
            ls.selected = true
        });
    }

    formationTagList() {
        this.tags = [];
        this.objectForReportStation.ids = [];
        this.listDevices.forEach(ls => {
            if (ls.selected == true) {
                this.tags.push(ls.label)
                this.objectForReportStation.ids.push(Number(ls.id));
            }
        })
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    getDateBeginRangeFromCalendar(time) {
        this.objectForReportStation.timeBegin = time;
    }

    getDateEndRangeFromCalendar(time) {
        this.objectForReportStation.timeEnd = time;
    }

    removeFromList(index: number) {
        const arrForChanges: CheckboxItem[] = []
        this.objectForReportStation.ids = [];
        this.listDevices.forEach(ls => {
            if (this.tags[index] == ls.label) {
                ls.selected = false;
            }
            if (ls.selected == true) {
                this.objectForReportStation.ids.push(Number(ls.id));
            }
            arrForChanges.push(ls);
        })
        this.listDevices = arrForChanges;
        this.tags.splice(index, 1);
    }

    getStndTimeBegin: () => number = () => moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

    getStndTimeEnd: () => number = () => {
        const m = moment();
        m.startOf('minutes')
        m.subtract(m.get('minutes') % 20, 'minutes') // floor of 20 minutes
        return m.valueOf();
    }

    showSelectedTab(tab: TabModel) {
        this.editable = true;
        this.disabledDevices = false
        this.selectedListReportType = tab;
        this.objectForReportStation.type = tab.type;
        if (tab.type == 2) {
            this.editable = false;
            this.disabledDevices = true
            this.formListDevices(false);
            this.formationTagList();
        }
    }

    selectedCheckboxText(num: number = 0) {
        const {selectedStation, station} = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [
            selectedStation[category],
            num,
            station[category]
        ].join(' ');
    }

    downloadReportStation = () => {
        const fileExtension = this.objectForReportStation.type == 1 ? '.xlsx' : '.csv';
        const filename = TEXTS.DOWNLOAD_POPUP.filenameDataDevice + moment().format('_YYYYMMDD_HHmm') + fileExtension;
        this.isActiveLoader = true;
            this.adminPanelApi.SaveDeviceDataToExcel(this.objectForReportStation).then((data: string) => {
                saveDataToDisk(data, filename);
                this.offActiveLoader();
            }).catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.offActiveLoader();
            })
    };

    offActiveLoader() {
        setTimeout(() => {
            this.isActiveLoader = false;
        }, 1000);
    }
}
