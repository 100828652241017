import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import Actions from 'map/actions';
import State from 'map/state';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { LittleComponentsModule } from 'src/little-components/little-components.module';

import { ConfigPanelComponent } from './config.component';
import { ConfigMenuComponent } from './menu/config-menu.component';
import { CoefficientComponent } from './coefficient/coefficient.component';
import { ConfigTitleComponent } from './title/config-title.component';
import { BrowserTokensComponent } from './browser-tokens/browser-tokens.component';
import { ApiTokensComponent } from './api-tokens/api-tokens.component';
import { NotificationsModule } from 'projects/cityscreen/src/modules/notifications/notifications.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';
import { CardModule } from 'projects/cityscreen/src/components/card/card.module';
import { SettingLangSelectorComponent } from './lang-selector/lang-selector.component';
import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { ButtonAddModule } from '../admin-panel/components/button-add/button-add.module';
import { CardActionsModule } from 'projects/cityscreen/src/components/card-actions/card-actions.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        NotificationsModule,
        TooltipsModule,
        CardModule,
        SharedComponentsModule,
        ButtonAddModule,
        CardActionsModule,
    ],
    providers: [
        Actions,
        State,
    ],
    exports: [
        ConfigPanelComponent,
    ],
    declarations: [
        SettingLangSelectorComponent,
        ConfigMenuComponent,
        CoefficientComponent,
        ConfigPanelComponent,
        ConfigTitleComponent,
        BrowserTokensComponent,
        ApiTokensComponent
    ]
})
export class ConfigModule {}
