import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortListItemComponent } from './sort-list-item.component';

@NgModule({
  exports: [SortListItemComponent],
  declarations: [SortListItemComponent],
  imports: [
    CommonModule
  ]
})
export class SortListItemModule { }
