import { SharedCoreFacade } from '../../../../shared/core/SharedCoreFacade';
import { Marker_model } from '../../../../../src/namespace';
import { EventsSourceName, ModuleHandler } from '../../../../shared/utils/other-utils';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';

export class PlumeHandler extends ModuleHandler {
    constructor(private readonly sharedCoreFacade: SharedCoreFacade, private time: Time) {
        super();

        const actions = {
            [EventsSourceName.onMarkerClick$]: (marker: Marker_model) => {
                if (this.time.getBegin() < new Date().getTime()) // не будущее
                    this.sharedCoreFacade.actionObservers.mapMarkerClick.next(marker)
            }
        }

        this.onEvent$ = ({type, payload}) => {
            return actions[type](payload);
        }
    }
}
