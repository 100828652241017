import { NgModule, LOCALE_ID } from '@angular/core';

import { LanguageService } from './services/language.service';

@NgModule({
    providers: [
        {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useFactory: (languageService: LanguageService) => languageService.getLanguage()
        },
    ],
})
export class SharedCoreModule {}
