<div class="page">
    <div class="page__header">
        <header-of-panel
                class="header-of-panel-wrapper"
                [path]="navigationLink"
                (outputPath)="backToPrevPage($event)"
                [title]="this.name">
        </header-of-panel>
    </div>
    <div *ngIf="selectedTab" class="page__body">
        <post-details *ngIf="selectedTab.type == 1"
                      [MO]="displayMo"></post-details>
        <device-details *ngIf="selectedTab.type == 2"
                        [device]="displayDevice"></device-details>
    </div>

</div>
