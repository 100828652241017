import { Injectable } from '@angular/core';

import { environment } from 'projects/cityscreen/src/environments/environment';
import { ProviderApi } from '../../core/services/api/provider';
import { ApiModels2 } from '../../core/services/api/error-handler';
import { DashboardCollectionService } from './dashboard.collection';

const API_URL = environment.vanga_api_url;

@Injectable({
    providedIn: 'root'
})
export class DashboardApi extends ApiModels2 {
    constructor(
        private providerApi: ProviderApi,
        private dashboardCollectionService: DashboardCollectionService
    ) {
        super();
    }

    url = `${API_URL}/dashboard/`;
    statsUrl = `${API_URL}/dashboard-stats/`;

    async getStats() {
        const data = await this.providerApi.get(
            `${this.statsUrl}`,
            {},
            this.errorHandler
        );

        this.dashboardCollectionService.updateStats(data);
    }

    async getData(from: Date, to: Date) {
        const data = await this.providerApi.get(
            `${this.url}`,
            {
                time__gte: from.toISOString(),
                time__lte: to.toISOString()
            },
            this.errorHandler
        );

        this.dashboardCollectionService.updateData(data);
    }
}
