import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnChanges,
    OnDestroy
} from '@angular/core';

import { fromEvent, Subject, merge } from 'rxjs';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';

function clamp(val: number, min: number, max: number) {
    return Math.min(Math.max(val, min), max);
}

@Component({
    selector: 'ca-gradient-slider',
    templateUrl: 'gradient-slider.component.html',
    styleUrls: ['gradient-slider.component.less']
})
export class GradientSlider implements OnChanges, OnDestroy {
    @Input() min = 0;
    @Input() max = 100;
    @Input() start = 0;
    @Input() step = 1;

    @Input() sliderModel = 0;
    @Output() sliderModelChange = new EventEmitter<number>();

    @ViewChild('slider', { static: true }) slider: ElementRef<HTMLDivElement>;
    @ViewChild('stub', { static: true }) stub: ElementRef<HTMLDivElement>;
    @ViewChild('handle', { static: true }) handle: ElementRef<HTMLDivElement>;

    startValue: number;
    sliderWidth: number;
    x0: number;
    minX: number;
    maxX: number;
    padding: number;
    drag = false;
    onDestroy$ = new Subject();

    ngOnChanges() {
        let { min, max, step, sliderModel } = this;

        this.max = max = Math.max(min, max);
        this.step = step = Math.min(step, max - min);
        this.sliderModel = Math.round(clamp(sliderModel, min, max) / step) * step;

        this.moveHandle();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    startHandle(event: MouseEvent) {
        event.preventDefault();

        this.prepareParams(event.clientX);
        this.drag = true;
        const onRelease = fromEvent(window, 'mouseup').pipe(map(() => this.drag = false));

        fromEvent(this.slider.nativeElement, 'mousemove')
        .pipe(
            takeUntil(merge(onRelease, this.onDestroy$)),
            map((e: MouseEvent) => this.calcValue(e.clientX)),
            distinctUntilChanged()
        )
        .subscribe(value => this.changeValue(value));
    }

    startTouchHandle(event: TouchEvent) {
        event.preventDefault();

        this.prepareParams(event.touches.item(0).clientX);
        this.drag = true;
        const onRelease = fromEvent(window, 'touchend').pipe(map(() => this.drag = false));

        fromEvent(this.slider.nativeElement, 'touchmove')
        .pipe(
            takeUntil(merge(onRelease, this.onDestroy$)),
            map((e: TouchEvent) => this.calcValue(e.touches.item(0).clientX)),
            distinctUntilChanged()
        )
        .subscribe(value => this.changeValue(value));
    }

    jumpToValue({ clientX }) {
        this.prepareParams(this.handle.nativeElement.getBoundingClientRect().x);
        this.changeValue(this.calcValue(clientX));
    }

    private prepareParams(x: number) {
        this.sliderWidth = this.slider.nativeElement.clientWidth;
        this.startValue = this.sliderModel;
        this.x0 = x;
        this.minX = this.slider.nativeElement.getBoundingClientRect().x;
        this.maxX = this.minX + this.sliderWidth;
    }

    private changeValue(value: number) {
        this.sliderModel = value;
        this.moveHandle();
        this.sliderModelChange.emit(value);
    }

    private getPercentage() {
        const { sliderModel, min, max } = this;
        return (sliderModel - min) / (max - min);
    }

    private moveHandle() {
        const percentage = `${this.getPercentage() * 100}%`;
        this.stub.nativeElement.style.width = percentage;
        this.handle.nativeElement.style.left = percentage;
    }

    private calcValue(x: number) {
        if (x < this.minX || x > this.maxX) {
            return this.sliderModel;
        }
        const { min, max, step, x0, startValue, sliderWidth } = this;
        const delta = (x - x0) / sliderWidth;
        const val = startValue + Math.round((max - min) * delta / step) * step;
        return clamp(val, min, max);
    }
}
