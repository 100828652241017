import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlumesFacadeService } from 'projects/cityscreen/src/modules/plumes/plumes-facade.service';
import { RunPlume } from 'projects/cityscreen/src/modules/plumes/services/models/run-model';
import { TEXTS } from 'src/texts/texts';
import { annotateWithDates, dateRangeText } from 'src/utils/date-formats';

@Component({
    selector: 'plumes-calculation-results',
    templateUrl: 'plumes-calculation-results.component.html',
    styleUrls: ['plumes-calculation-results.component.less']
})
export class PlumesCalculationResults implements OnInit {
    @Input() plumesVisible: boolean;

    @ViewChild('scrollable') scrollable: ElementRef<HTMLDivElement>;

    isScrolledToTop = true;
    TEXTS = TEXTS;

    annotatedRuns$: Observable<{
        item: RunPlume;
        annotation: string;
    }[]>;

    tzOffset = moment().utcOffset();

    dateRangeFilterText: string;
    calendarIsOpened = false;

    constructor(
        public plumesFacadeService: PlumesFacadeService,
    ) {
        this.annotatedRuns$ = plumesFacadeService.allRuns$.pipe(
            map((items) => {
                return annotateWithDates<RunPlume>(
                    (item) => moment(item.evaluation_time || 0).toDate()
                )(items);
            })
        );
    }

    ngOnInit() {
        const begin = new Date(this.plumesFacadeService.getEvaluationTimeBegin());
        const end = new Date(this.plumesFacadeService.getEvaluationTimeEnd());

        this.dateRangeFilterText = dateRangeText(begin, end);
    }

    hasVisibleLayer({ id }: RunPlume) {
        return this.plumesVisible && id === this.plumesFacadeService.getCurrentRunId();
    }

    scrollTop() {
        this.scrollable.nativeElement.scrollTo(0, 0);
    }

    onScroll() {
        this.isScrolledToTop = !this.scrollable.nativeElement.scrollTop;
    }

    updateDateRange = (begin: number, end: number) => {
        this.calendarIsOpened = false;

        // fix start date after calendar
        begin = moment(begin).startOf('day').valueOf();

        const dateTimeBegin = new Date(begin);
        const dateTimeEnd = new Date(end);

        this.dateRangeFilterText = dateRangeText(dateTimeBegin, dateTimeEnd);

        this.plumesFacadeService.updateRuns({
            evaluation_time__gte: moment(dateTimeBegin).toJSON(),
            evaluation_time__lte: moment(dateTimeEnd).toJSON()
        });
    }
}
