import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-info-icon',
    templateUrl: 'info-icon.component.html',
    styleUrls: ['info-icon.component.less']
})
export class InfoIconComponent {
    @Input() active = false;
}
