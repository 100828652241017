import { Component, Input } from '@angular/core';
import { isRU, LANGUAGE, TEXTS } from '../../../../../../src/texts/texts'

import './lang-selector.component.less';
import Actions from '../../../../../../map/actions';

@Component({
    selector: 'setting-lang-selector',
    templateUrl: `./lang-selector.component.html`
})
export class SettingLangSelectorComponent {
    TEXTS = TEXTS;
    lang: string = LANGUAGE.toLocaleUpperCase();
    constructor(public  actions: Actions) {
    }

}
