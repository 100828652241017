import * as moment from 'moment'

import { ADMIN_ID, formatDayMonth } from 'src/config';
import { AdminDevice, ModulesIds, MonitoringObject, Token, UserItems, WindowGlobalVars } from 'src/namespace';
import { copyObj, getUserSavedData } from 'src/utils';
import { isRU } from 'src/texts/texts';
import { FeaturesConfigType } from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';

// виталино--------------------------------------------------------------------------------------------------------------------------------
import {
    GroupInfoResponse,
    GroupNotificationSetting,
    GroupsRolesItem,
    NotificationSubscribeType,
    UserPermissionResponse
} from 'harvester/UiAdminProject8/src/commonData/providers/adminApiProvider/adminApiModels';
import {
    DeviceItem,
    DeviceSourceItem,
    MoItem,
    PacketValueType
} from 'harvester/UiAdminProject8/src/commonData/models';
import { MoIntervalType } from 'harvester/UiAdminProject8/src/commonData/providers/moApiProvider/moApiModels';
// виталино--------------------------------------------------------------------------------------------------------------------------------

declare const window: WindowGlobalVars;

// export class TagItems {
//     Description: string;
//     IsChecked: boolean;
//     TagId: number;
//     Title: string;
// }

export class RoleList {
    roleNameEn: string;
    roleNameRu: string;
    roleId: number;
}

export class Roles {
    [roleId: number]: GroupsRolesItem;
}

export class CurrentRegionalCoefs {
    currentPcf: number;
    currentScf: number;
}

export class DeviceExportType {
    type: number;
    title: string;
    filename: string;
}

export class NotificationSubscription {
    id: number;
    title: string;
    isActive: boolean;
    type: NotificationSubscribeType;
    groupId: number;

    forUserIds: number[];
    emailsList: string[];
    emailsListReports: string[];
    isForEventFeed: boolean;

    moItems: number[];
    valueItems: PacketValueType[];
    isForAllMos: boolean;
    isForAllValues: boolean;
    excessLevel: number;
    interval: MoIntervalType;
    isPeriodic: boolean;

    serviceNoData: number;
    serviceNoPower: number;
    serviceVerification: number;

    constructor() {
        this.id = 0;
        this.title = '';
        this.isActive = false;
        this.type = NotificationSubscribeType.None;
        this.groupId = 0;

        this.forUserIds = [];
        this.emailsList = [];
        this.emailsListReports = [];
        this.isForEventFeed = false;

        this.moItems = [];
        this.valueItems = [];
        this.isForAllMos = true;
        this.isForAllValues = true;
        this.excessLevel = 3;
        this.interval = MoIntervalType.Interval20M;
        this.isPeriodic = false;

        this.serviceNoData = 1;
        this.serviceNoPower = 1;
        this.serviceVerification = 1;
    }

    isNew() {
        return this.id === 0;
    }

    isSuggested() {
        return this.id === -1;
    }

    setSuggested() {
        this.id = -1;
    }

    toRequestItem(): GroupNotificationSetting {
        return {
            NotificationId: this.id,
            NotificationTitle: this.title,
            GroupId: this.groupId,
            IsActive: this.isActive,
            SubscribeType: this.type,
            NotifySetting: {
                NotifyAllGroupMembers: false,
                NotificationEmails: this.emailsList,
                NotificationFeedUserIds: this.forUserIds,
                DailyReportEmails: this.emailsListReports,
                DailyReportStartHour: 0,
                DailyReportTimeZone: 0
            },
            PdkCheckSetting: {
                IsForAllMoItems: this.isForAllMos,
                IsForAnyValueType: this.isForAllValues,
                SelectedMoItems: this.moItems,
                SelectedValueTypes: this.valueItems,
                IntervalType: this.interval,
                PdkMrMultiplier: this.excessLevel,
                IsContinueNotify: this.isPeriodic
            },
            ServiceCheckSetting: {
                NoDataFromDevicesWithMoDurationInHours: this.serviceNoData,
                NoPs220InDevicesWithMoDurationInHours: this.serviceNoPower,
                NotifyCertificateExpirationInDays: this.serviceVerification
            }
        };
    }

    static clone(src: NotificationSubscription) {
        return Object.assign(new NotificationSubscription(), {
            ...src,
            forUserIds: [...src.forUserIds],
            emailsList: [...src.emailsList],
            emailsListReports: [...src.emailsListReports],
            valueItems: [...src.valueItems],
            moItems: [...src.moItems]
        });
    }

    static fromGroupNotificationItem({
        NotificationId,
        NotificationTitle,
        GroupId,
        IsActive,
        SubscribeType,
        NotifySetting,
        PdkCheckSetting,
        ServiceCheckSetting
    }: GroupNotificationSetting) {
        return Object.assign(new NotificationSubscription(), {
            id: NotificationId,
            title: NotificationTitle,
            isActive: IsActive,
            type: SubscribeType,
            groupId: GroupId,

            forUserIds: NotifySetting.NotificationFeedUserIds,
            emailsList: NotifySetting.NotificationEmails,
            emailsListReports: NotifySetting.DailyReportEmails,
            isForEventFeed: NotifySetting.NotificationFeedUserIds.length > 0,

            moItems: PdkCheckSetting.SelectedMoItems,
            valueItems: PdkCheckSetting.SelectedValueTypes,
            isForAllMos: PdkCheckSetting.IsForAllMoItems,
            isForAllValues: PdkCheckSetting.IsForAnyValueType,
            excessLevel: PdkCheckSetting.PdkMrMultiplier,
            interval: PdkCheckSetting.IntervalType,
            isPeriodic: PdkCheckSetting.IsContinueNotify,

            serviceNoData: ServiceCheckSetting.NoDataFromDevicesWithMoDurationInHours,
            serviceNoPower: ServiceCheckSetting.NoPs220InDevicesWithMoDurationInHours,
            serviceVerification: ServiceCheckSetting.NotifyCertificateExpirationInDays
        });
    }
}

export class GroupInfo {
    monitoringObjects: MonitoringObject[];
    myDevices: AdminDevice[];
    allDevices: AdminDevice[];
    notificationSubscriptions: NotificationSubscription[];
    users: UserItems[];
    usersWithoutAdmin: UserItems[];
    iAm: UserItems;
    roles: Roles;
    myRole: Permissions;
    regionalCoefs: CurrentRegionalCoefs;
    groupLocationsIds: number[];
    allowForecast: boolean;
    allowPlumes: boolean;
    allowIndoor: boolean;
    extConfig: FeaturesConfigType;
    groupId: number;
}

export class Permissions {
    editMo: boolean;
    editUser: boolean;
    editDevice: boolean;
    editGroup: boolean;

    downloadDeviceData: boolean;
    viewDeviceData: boolean;
    viewMoData: boolean;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------


export function createMo(mo: MoItem, Devices: DeviceItem[], DeviceSources: DeviceSourceItem[]): MonitoringObject {
    const geo = mo.DotItem;
    const devicesId: number[] = mo.DeviceLinks?.length ? mo.DeviceLinks.map(d => d.DeviceId) : [];
    const devicesSerialNum = [];
    let lastConnectedDevice = null;

    mo.DeviceLinks = mo.DeviceLinks.sort((left, right) => {
        return moment(left.MapBegin).diff(moment(right.MapBegin));
    })

    const idLastConnectedDevice = mo.DeviceLinks?.length ? mo.DeviceLinks[mo.DeviceLinks.length - 1] : null;

    const devicesObj: AdminDevice[] = [];
    devicesId.map( dv  => {
        return Devices.filter( di => {
            if (idLastConnectedDevice.DeviceId == di.DeviceId) {
                lastConnectedDevice = getDevicesParam(di, DeviceSources);
            }
            if (di.DeviceId == dv) {
                devicesSerialNum.push(di.SerialNumber);
                const obj = getDevicesParam(di, DeviceSources);
                devicesObj.push(obj);
            }
        })
    });

    return <MonitoringObject>{
        id: mo.MoId,
        name: mo.Name,
        description: mo.Description,
        isOffline: mo.IsOffline,
        pubName: isRU ? mo.PublishNameRu : mo.PublishName,
        gmtOffset: mo.GmtOffsetSeconds / 3600,
        tzOffset: mo.GmtOffsetSeconds / 60,
        geoLatitude: geo ? +geo.Latitude : null,
        geoLongitude: geo ? +geo.Longitude : null,
        users: null,
        regionCoef: null,
        locationId: mo.LocationId,

        devicesId,
        devicesObj,
        devicesSerialNum,
        lastConnectedDevice,

        originChartData: null,
        measuresVal: null,
        lastPacketId: null
    };
}

export function groupInfoTransformer(data: GroupInfoResponse): GroupInfo {
    const userSavedData: UserItems = getUserSavedData();
    const roles: Roles = {};
    data.GroupsRoles.forEach(r => roles[r.GroupRoleId] = r);

    const users: UserItems[] = data.Group.RoleMembers
        .map(u => {
            const user = data.Users.find(({ UserId }) => UserId === u.UserId);
            return user ? {
                email: user.Email,
                login: user.Login,
                userId: user.UserId,
                roleId: u.GroupRoleId,
                linksToMo: []
            } : null;
        })
        .filter(u => u);

    const usersInMo: {
        [roleId: string]: {
            name: string,
            logins: string[]
        };
    } = {};

    data.GroupsRoles.forEach(g => usersInMo[g.GroupRoleId] = {
        name: isRU ? g.NameRu : g.Name,
        logins: []
    });

    users.forEach(u => {
        // видны все МО
        if (roles[u.roleId].ViewStationData)
            usersInMo[u.roleId].logins.push(u.login);
    });

    const iAm = users.find(u => u.userId === userSavedData.userId);

    let myRole: Permissions;
    if (!iAm) {
        console.log(`groups: current user is not in group "${data.Group.Name}"`);
        return new GroupInfo();
    } else {
        const r = roles[iAm.roleId];
        myRole = {
            editMo: r.EditStation,
            editDevice: r.EditDeviceProperties,
            editUser: r.EditUser,
            editGroup: r.EditGroup,

            downloadDeviceData: r.DownloadDeviceData,
            viewDeviceData: r.ViewDeviceData,
            viewMoData: r.ViewStationData
        };
    }

    let moIds = [];
    if (myRole.viewMoData) {
        moIds = data.Group.MoIds;
    } else {
        const a = data.Group.MosUsers.find(m => m.UserId === iAm.userId);
        moIds = a ? a.MonitoringObjectsIds : [];
    }

    const regionalCoefs: {
        currentPcf: number,
        currentScf: number,
        [id: number]: number
    } = {currentPcf: null, currentScf: null};

    data.RegionCoefs.forEach(c => {
        regionalCoefs[c.RegionCoefsId] = c.Pcf;

        if (c.RegionCoefsId === data.Group.RegionCoefsId) {
            regionalCoefs.currentPcf = c.Pcf;
            regionalCoefs.currentScf = c.Scf;
        }
    });

    const monitoringObjects = [];
    moIds.map((moId: number) => {
        const mo: MoItem = data.MonitoringObjects.find(_mo => _mo.MoId === moId);

        if (!mo)
            return;

        // юзера которые используют это МО
        const _moUsers = data.Group.MosUsers.filter(m => m.MonitoringObjectsIds.includes(mo.MoId));
        const _thisUsers = [];

        // в нашем формате
        _moUsers.forEach(userRaw => {
            const user = users.find(u => u.userId === userRaw.UserId);
            if (user)
                _thisUsers.push(user);
        });

        const _usersInMo = copyObj(usersInMo);
        _thisUsers.forEach(u => {
            if (!_usersInMo[u.roleId].logins.includes(u.login))
                _usersInMo[u.roleId].logins.push(u.login)
        });

        const obj = createMo(mo, data.Devices, data.DeviceSources);
        obj.users = Object.keys(_usersInMo).map(key => _usersInMo[key]);
        obj.regionCoef = mo.RegionCoefsId ? regionalCoefs[mo.RegionCoefsId] : null;

        // приписываем линки на МО к людям
        _thisUsers.forEach(u => u.linksToMo.push(obj));

        monitoringObjects.push(obj);
    });

    const allDevices: AdminDevice[] = data.Devices.map(d => {
        const linksToMo = monitoringObjects.filter(mo => mo.devicesId.includes(d.DeviceId));
        const obj = getDevicesParam(d, data.DeviceSources);

        obj.linksToMo = linksToMo;
        obj.namesMo = linksToMo.map(ltm => ltm.name);

        return obj;
    });

    const myDevices: AdminDevice[] = data.Group.DeviceIds.map((dId: number) => {
        return allDevices.find(_d => _d.id === dId);
    });

    const notificationSubscriptions = data.GroupNotificationSettings
        .map((gni: GroupNotificationSetting) => NotificationSubscription.fromGroupNotificationItem(gni));

    const extConfig: FeaturesConfigType = {};

    Object.keys(data.Group.ExtConfig || {}).forEach((key) => {
        const str = data.Group.ExtConfig[key];
        let value: string;

        try {
            value = JSON.parse(str);
        } catch (e) {
            value = str;
        }

        extConfig[key] = value;
    });

    return {
        monitoringObjects,
        myDevices,
        allDevices,
        notificationSubscriptions,
        // что б в списке не было себя
        users: users.filter(u => u.login !== window.JS_CP_LOGIN),
        usersWithoutAdmin: users.filter(u => u.login !== window.JS_CP_LOGIN && u.roleId !== ADMIN_ID),
        roles,
        iAm,
        myRole,
        regionalCoefs: {
            currentPcf: regionalCoefs.currentPcf,
            currentScf: regionalCoefs.currentScf
        },
        groupLocationsIds: data.Group.LocationIds,
        allowForecast: data.Group.ModulesIds?.includes(ModulesIds.forecast),
        allowPlumes: data.Group.ModulesIds?.includes(ModulesIds.plumes),
        allowIndoor: data.Group.ModulesIds?.includes(ModulesIds.indoor),
        extConfig,
        groupId: data.Group.GroupId
    };
}

export  function getDevicesParam(d: DeviceItem, source?: DeviceSourceItem[]): AdminDevice {
    const model = source ? source.find(s => s.SourceType === d.SourceType) : null;

    let sourceName: string;
    if (source && source.find(s => s.SourceType === d.SourceType)) {
        const _s =  source.find(s => s.SourceType === d.SourceType);
        sourceName = isRU ? _s.NameRu : _s.Name;
    }
    else
        sourceName = '';

    const obj = {
        id: d.DeviceId,
        serialNumber: d.SerialNumber,
        model: model ? model.Name : '',
        name: d.DeviceName,
        startWork: d.DeviceFirstDate ?   formatDayMonth(moment(d.DeviceFirstDate)) : '---',
        serviceDate:  d.DeviceFirstDate ? moment(d.DeviceFirstDate).add(1, 'year').format('MMM YYYY') : '---',
        intervalSec: d.ExtInfo ? d.ExtInfo.DataDeliveryPeriodSec : null,
        // isNotSaveData: d.IsNotSaveData,
        battery: d.ExtInfo.BatOk,
        v220: d.ExtInfo.Ps220,
        offline: d.IsOffline,
        soft: d.ExtInfo  ? d.ExtInfo.SoftwareVersion : null,
        hardware: (d.ExtInfo && d.ExtInfo.SoftwareVersion) ? d.ExtInfo.SoftwareVersion.substr(0, 3) : null,
        lastTime: d.DeviceLastDate ? moment(d.DeviceLastDate).format('HH:mm DD.MM.YY') : '',
        geoLatitude:  d.DeviceLastGeo ?  d.DeviceLastGeo.Latitude : null,
        geoLongitude:  d.DeviceLastGeo ? d.DeviceLastGeo.Longitude : null,
        linksToMo: null,
        namesMo: null,
        sourceId: d.SourceType,
        childDevices: d.ChildDevices.map(cd => getDevicesParam(cd, source)),
        sourceName
    };

    return obj;
}

export class GetUserPermissionModel {
    apiTokens: Token[];
    browserTokens: Token[];
}

export function getUserPermissionTransformer(data: UserPermissionResponse): GetUserPermissionModel {
    const apiTokens: Token[] = [];
    const browserTokens: Token[] = []

    if (data.Tokens?.length) {
        data.Tokens.forEach(t => {
            const token: Token = {
                tokenId: t.TokenId,
                title: t.Title,
                isApiKey: t.IsApiKey,
                createDate: moment(t.CreateDate).format('YYYY-MM-DD HH:mm'),
                lastDate: moment(t.LastDate).format('YYYY-MM-DD HH:mm'),
                execCount: t.ExecCount,
                isActive: t.TokenId === data.ActiveTokenId
            };

            if (token.isApiKey) {
                apiTokens.push(token)
            } else {
                browserTokens.push(token);
            }
        });
    }

    return {
        apiTokens,
        browserTokens
    }
}

