import type { BBox, Point } from 'geojson';
import * as moment from 'moment';

import { ControlPointsReport, ControlPointTimeline } from './control-point-model';

export interface Domain {
    id: number;
    name: string;
    bbox: BBox;
    centroid: Point;
    zoom: number;
    group_id: number;
    obj: string;
}

export interface Source {
    id: number;
    name: string;
    centroid: Point;
    params: string;
    group_id: number;
    obj: string;
}

export interface ConcentrationField {
    id: number;
    evaluation_time: Date;
    png_base64: string;
    obj: string;
}

export interface CurrentPointValues {
    [pointId: number]: number;
}

export class RunPlume {
    id: number;
    config: number;
    evaluation_time: Date;
    started_at: Date;
    finished_at: Date;
    duration_minutes: number;
    group_id: number;
    domain: Domain;
    sources: (Source | number)[];
    concentration_fields: ConcentrationField[];
    control_points_report: ControlPointsReport[];
    step_minutes: number;
    obj: string;

    _runsControlPointsTimelines?: {
        [pointId: number]: ControlPointTimeline
    } = {};

    constructor(data: Partial<RunPlume>, measure: string) {
        Object.assign(this, data);

        if (this.control_points_report) {
            this.control_points_report.forEach(point => {
                this._runsControlPointsTimelines[point.id] = new ControlPointTimeline(point, measure)
            });
        }
    }

    getCurrentPointsValues(time: number, measure: string) {
        return Object.entries(this._runsControlPointsTimelines).reduce((acc, [pointId, timeline]) => ({
            ...acc,
            [pointId]: timeline[measure][time]
        }), {} as CurrentPointValues);
    }

    getPointValues(pointId: number, measure: string) {
        return this._runsControlPointsTimelines[pointId]?.[measure];
    }

    getFirstTimestamp() {
        return moment(this.evaluation_time).add(this.step_minutes, 'minutes').valueOf();
    }

    getLastTimestamp() {
        return moment(this.evaluation_time).add(this.duration_minutes, 'minutes').valueOf();
    }
}
