import { Component } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { LanguageService } from 'projects/shared/modules/core/services/language.service';
import { ClientConfigService, ClientConfig } from '../../../core/services/client-config/client-config.service';
import { LoginNavigationService } from '../../services/login-navigation/login-navigation.service';
import { LoginPage, LoginPopup } from '../../login.settings';

@Component({
    selector: 'ca-login-page',
    templateUrl: 'login-page.component.html',
    styleUrls: ['login-page.component.less', 'login-page-overrides.component.less'],
})
export class LoginPageComponent {
    clientName: string;

    currentLanguage: string;

    TEXTS = TEXTS;

    loginPage = LoginPage;

    loginPopup = LoginPopup;

    clientConfig: ClientConfig;

    assets = {
        background: null,
        logo: null
    };

    constructor(
        clientConfigService: ClientConfigService,
        languageService: LanguageService,
        readonly navigation: LoginNavigationService,
    ) {
        this.clientConfig = clientConfigService.getConfig();

        this.currentLanguage = languageService.getLanguage();

        this.assets = {
            background: clientConfigService.getAsset(this.clientConfig.login?.background),
            logo: clientConfigService.getAsset(this.clientConfig.login?.logo)
        };
    }

    setLang(lang: string) {
        localStorage.setItem('lang', lang);
        location.reload();
    }
}
