// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../imgs/rectangle.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".station_city_list{margin-left:30px;padding-right:15px;border-bottom:1px solid #e9e9e9}.station_city_list__sort_list_wrapper{position:absolute;display:flex;align-items:center;top:0;height:50px;margin-left:30px;padding-right:69px;width:calc(100% - 30px);border-bottom:1px solid #e6ecf2;z-index:10}.station_city_list__wrapper{position:relative;background:#fff;padding-bottom:20px;height:100%}.station_city_list__body{position:absolute;width:100%;top:50px;height:calc(100% - 50px)}.station_city_list__search{display:block;margin-left:27px;padding-top:16px;padding-right:15px}.station_city_list__dashboard{margin-left:23px;width:28px;height:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.station_city_list__container{display:flex;align-items:center;justify-content:space-between;color:#404655;height:70px;cursor:pointer}.station_city_list__container-big{height:80px}.station_city_list__block-flex{display:flex;align-items:center}.station_city_list__title{font-weight:600;font-size:16px;line-height:21px;letter-spacing:-.4px;cursor:pointer}.station_city_list__sub_description{margin-top:2px;color:#b8bfcc;font-weight:500;font-size:12px;line-height:14px}.station_city_list__description{margin-top:2px;font-weight:500;font-size:12px;line-height:14px;color:#6c7484}.station_city_list__aqi_description{font-weight:400;width:50px;font-size:10px;line-height:12px;color:#b8bfcc}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
