import { Component, Input } from '@angular/core';
import { TEXTS } from 'src/texts/texts';
import { IAQ } from 'src/namespace';
import { PostWithAnalytics } from 'projects/cityscreen/src/modules/indoor/services/api';


@Component({
    selector: 'indoor-analytic',
    templateUrl: './analytic.component.html',
    styleUrls: ['./analytic.component.less']
})
export class AnalyticComponent {
    @Input() loading: boolean;
    @Input() analyticData: PostWithAnalytics;

    IAQ = IAQ;
    TEXTS = TEXTS;

    constructor() {
    }
}
