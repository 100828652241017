import { HttpErrorResponse } from '@angular/common/http';
import { ObservableInput, throwError } from 'rxjs';

import { WindowGlobalVars } from 'src/namespace';

declare const window: WindowGlobalVars;

export class ApiModels2 {
    private _errorCb: (data: HttpErrorResponse) => void;
    private _errorTokenCb: () => void;

    setErrorTokenCb = (cb: () => void) => this._errorTokenCb = cb;
    setErrorCb = (cb: (data: HttpErrorResponse) => void) => this._errorCb = cb;

    errorHandler = (error: HttpErrorResponse): ObservableInput<any> => {
        if (error.status === 401 && this._errorTokenCb) {
            this._errorTokenCb();
            return null;
        }

        if (error.status > 401 && window.sentryCaptureException)
            window.sentryCaptureException(error); // (public sentry: ErrorHandler) почему то не работает

        this._errorCb?.(error);

        return throwError(error);
    }
}
