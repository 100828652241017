import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { PipesModule } from 'projects/shared/pipes/pipes.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';

import { MapContainerComponent } from './components/map-container/map-container.component';
import { MapboxMapComponent } from './components/mapbox/mapbox.component';
import { MarkerIconComponent } from './components/marker-icon/marker-icon.component';
import { CityMarkerComponent } from './components/city-marker/city-marker.component';
import { ControlPointMarkerComponent } from './components/control-point-marker/control-point-marker.component';
import { MapProvidersService } from './services/map-providers.service';
import { BoundaryMarkerComponent } from './components/boundary-marker/boundary-marker.component';
import { DomainBorderComponent } from './components/domain-border/domain-border.component';
import { PlumeSourceMarkerComponent } from './components/plume-source-marker/plume-source-marker.component';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2l0eWFpciIsImEiOiJjazk4MWhja3IwNHZiM25waDJkeWhheHQ0In0.2cWaHj0iCYNPagxl9GBTuA';

@NgModule({
    imports: [
        CommonModule,
        NgxMapboxGLModule.withConfig({
            accessToken: MAPBOX_ACCESS_TOKEN
        }),
        PipesModule,
        TooltipsModule,
    ],
    exports: [
        MapContainerComponent
    ],
    providers: [
        MapProvidersService,
    ],
    declarations: [
        MapContainerComponent,
        MapboxMapComponent,
        MarkerIconComponent,
        CityMarkerComponent,
        ControlPointMarkerComponent,
        BoundaryMarkerComponent,
        DomainBorderComponent,
        PlumeSourceMarkerComponent,
    ],
    bootstrap: [MapContainerComponent]
})
export class MapModule {}
