import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { TEXTS } from 'src/texts/texts';
import { formatDayMonth } from 'src/config';

@Component({
    selector: 'input-date-range',
    templateUrl: './input-date-range.component.html',
    styleUrls: ['./input-date-range.component.less']
})
export class InputDateRangeComponent{
    @Input() timeBegin: number;
    @Input() timeEnd: number;
    @Input() descriptionText = '';
    @Output() timeBeginChange = new EventEmitter<number>();
    @Output() timeEndChange = new EventEmitter<number>();

    showCalendar = false;
    showAttantion = false;
    texts = TEXTS;

    getIntervalText() {
        if (this.timeBegin)
            return formatDayMonth(moment(this.timeBegin)) + ' - '
                + formatDayMonth(moment(this.timeEnd)) + ' ' + moment(this.timeEnd).format('YYYY');
        return ''
    }

    updateTime = (begin, end) => {
        this.timeBegin = begin;
        this.timeEnd = end;
        this.showCalendar = false;

        if (this.timeEnd - this.timeBegin > 3600000 * 24 * 14) {
            this.showAttantion = true;
        } else {
            this.showAttantion = false;
        }
        this.timeBeginChange.emit(begin);
        this.timeEndChange.emit(end);
    };

    close = () => this.showCalendar = false;

}
