<div class="top_right_panel">
    <ng-container *ngIf="sharedCoreFacade.getAllowForecast() || sharedCoreFacade.getModuleConfig().enablePlumeButton">
        <ca-layer-button
            class="layer_button"
            *ngIf="state.adminPanel.namePageOpen === 'plumes'; else forecastButton"
            [type]="LayerButtonIconType.PLUME"
            [active]="sharedCoreFacade.getIsShowPlume()"
            (clickOnIcon)="sharedCoreFacade.getIsShowPlume() ? offPlume() : onPlume()"
        >
            <ca-layer-button-item
                [label]="TEXTS.NAMES[currentMeasure] || currentMeasure"
                [active]="true"
            ></ca-layer-button-item>
        </ca-layer-button>

        <ng-template #forecastButton>
            <ca-layer-button
                class="layer_button"
                *ngIf="sharedCoreFacade.getIsCityMod()"
                [type]="LayerButtonIconType.FORECAST"
                [active]="sharedCoreFacade.getIsActiveModelling()"
                (clickOnIcon)="sharedCoreFacade.getIsActiveModelling() ? disableModelling() : enableModelling()"
            >
                <ca-layer-button-item
                    *ngFor="let opt of MEASURES_FOR_FORECAST"
                    [label]="TEXTS.NAMES[opt]"
                    [active]="state.map.showingModelMeasure === opt"
                    (click)="forecastControlService.updateProps({
                        measure: opt,
                        contours: false,
                        overlay: true
                    })"
                ></ca-layer-button-item>
            </ca-layer-button>
        </ng-template>
    </ng-container>

    <run-summary *ngIf="sharedCoreFacade.getIsShowPlume()"
        [run]="sharedCoreFacade.getPlumeCurrentRun()"
    ></run-summary>

    <ng-container *ngIf="sharedCoreFacade.getModuleConfig().enableAqi">
        <ng-container *ngIf="widgetCityId && sharedCoreFacade.getCityById(widgetCityId) as city; else shortAqi">
            <location-meteo-card
                class="top_right_panel__widget"
                [aqi]="city.lastData.Aqi[0]"
                [dateTime]="city.lastData.time"
                [measurementItems]="getMeasurementItems(city.lastData)"
                [textQuality]="city.lastData.textQuality"
                [textForecast]="city.lastData.textForecast"
            ></location-meteo-card>
        </ng-container>
        <ng-template #shortAqi>
            <aqi-legend *ngIf="sharedCoreFacade.getIsCityMod()"></aqi-legend>
        </ng-template>
    </ng-container>

    <calendar
        *ngIf="sharedCoreFacade.getModuleConfig().enableCalendar"
        [columns]="2"
        [monthsVisible]="2"
        (updateDateRange)="updateDateRange($event)"
    ></calendar>

    <logout (handleLogout)="actions.logOut()"></logout>
</div>
