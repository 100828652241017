import { Component, Input, Output } from '@angular/core';

@Component({
    selector: 'static-tags',
    template: `<div [ngClass]="{
                                'tag_device': true,
                                'tag_device-is-active': isActiveElement,
                                'tag_device-users': typeTag == 'user',
                                'tag_device-mo': typeTag == 'mo'
                                }">
                    <div *ngIf="isOffline && typeTag == 'device'" class="tag_device-not_date"></div>
                    <div *ngIf="description != '' && typeTag == 'device'" class="tag_device__description">{{description}}</div>
                    <div class="tag_device__text">{{text}}</div>
                </div>`,
    styleUrls: ['static-tags.component.less']
})
export class StaticTagsComponent {
    @Input() text: string;
    @Input() description = '';
    @Input() isOffline = false;
    @Input() typeTag: 'device' | 'user' | 'mo' = 'device';
    @Input() isActiveElement = false;
}
