<div class="login_page_m login-page--{{clientConfig.name || 'unknown'}}"
    [style.backgroundImage]="assets.background && 'url(' + assets.background + ')'"
>
    <ca-language-selector-m class="login_page_m__language" (selectLang)="setLang($event)"></ca-language-selector-m>
    <div class="login_page_m__logo">City Screen</div>

    <div class="login_page_m__body">
        <router-outlet></router-outlet>
    </div>

    <div class="login_page_m__footer">
        <div class="login_page_m__text_support">{{TEXTS.PROFILE.support}}:</div>
        <div class="login_page_m__text_info">
            <a href="tel:{{TEXTS.PROFILE.phone[0]}}" [innerHTML]="TEXTS.PROFILE.phone[1]"></a>
            <a style="padding-left: 20px" href="mailto:{{TEXTS.PROFILE.email}}">{{TEXTS.PROFILE.email}}</a>
        </div>
        <div class="login_page_m__text_c">{{textC + TEXTS.PROFILE.c}}</div>
    </div>

    <ng-container [ngSwitch]="navigation.activePopup">
        <ca-password-updated-popup *ngSwitchCase="loginPopup.PasswordUpdated"></ca-password-updated-popup>
        <ca-link-expired-popup *ngSwitchCase="loginPopup.InviteLinkExpired"></ca-link-expired-popup>
    </ng-container>
</div>
