import { Routes } from '@angular/router';

import { PAGES } from './namespace';

import { PostsListComponent } from './components/posts-list/posts-list.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { PostCreateComponent } from './components/post-create/post-create.component';
import { PostReportComponent } from './components/post-report/post-report.component';
import { PostReportTzaComponent } from './components/post-report-tza/post-report-tza.component';
import { DeviceReportComponent } from './components/device-report/device-report.component'
import { PostsAndDevicesHeaderComponent } from './components/posts-and-devices-header/posts-and-devices-header.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { DetailsHeaderComponent } from './components/details-header/details-header.component';


const postRoutes: Routes = [
    {
        path: '',
        redirectTo: PAGES.postsList,
        pathMatch: 'full'
    },
    {
        path: PAGES.postsList,
        component: PostsListComponent,
    },
    {
        path: PAGES.devicesList,
        component: DevicesListComponent
    },
];

export const networksRoutes: Routes = [
    {
        path: '',
        component: PostsAndDevicesHeaderComponent,
        children: postRoutes
    },
    {
        path: PAGES.postsList + '/' + PAGES.postCreate,
        component: PostCreateComponent,
    },
    {
        path:  PAGES.devicesList + '/' + PAGES.details + '/:id',
        component: DetailsHeaderComponent,
    },
    {
        path:  PAGES.postsList + '/' + PAGES.details + '/:id',
        component: DetailsHeaderComponent,
    },
    {
        path: PAGES.postsList + '/' + PAGES.postReportTza + '/:id',
        component: PostReportTzaComponent,
    },
    {
        path:  PAGES.devicesList + '/' + PAGES.details + '/' + PAGES.postReportTza + '/:id',
        component: PostReportTzaComponent,
    },
    {
        path:  PAGES.postsList + '/' + PAGES.details + '/' + PAGES.postReportTza + '/:id',
        component: PostReportTzaComponent,
    },
    {
        path: PAGES.postsList + '/' + PAGES.postEdit + '/:id',
        component: PostEditComponent,
    },
    {
        path:  PAGES.devicesList + '/' + PAGES.details + '/' + PAGES.postEdit + '/:id',
        component: PostEditComponent,
    },
    {
        path:  PAGES.postsList + '/' + PAGES.details + '/' + PAGES.postEdit + '/:id',
        component: PostEditComponent,
    },
    {
        path: PAGES.postsList + '/' + PAGES.postReport + '/:id',
        component: PostReportComponent,
    },
    {
        path:  PAGES.devicesList + '/' + PAGES.details + '/' + PAGES.postReport + '/:id' ,
        component: PostReportComponent,
    },
    {
        path:  PAGES.postsList + '/' + PAGES.details + '/' + PAGES.postReport + '/:id',
        component: PostReportComponent,
    },
    {
        path: PAGES.devicesList + '/' + PAGES.deviceReport + '/:id',
        component: DeviceReportComponent,
    },
    {
        path:  PAGES.devicesList + '/' + PAGES.details + '/' + PAGES.deviceReport + '/:id',
        component: DeviceReportComponent,
    },
    {
        path:  PAGES.postsList + '/' + PAGES.details + '/' + PAGES.deviceReport + '/:id',
        component: DeviceReportComponent,
    },
];
