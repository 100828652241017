import { Component } from '@angular/core';
import { LANGUAGE, TEXTS } from 'src/texts/texts';
import { PATH } from 'projects/cityscreen/src/modules/posts-and-devices/components/post-create/post-create.component';
import { CheckboxItem, MoItemsDataToExcelRequestProps } from 'src/namespace';
import { FormBuilder } from '@angular/forms';
import { PostsAndDevicesFacade } from 'projects/cityscreen/src/modules/posts-and-devices/posts-and-devices.facade';
import * as moment from 'moment';
import { LOAD_HISTORY_DEFAULT } from 'projects/shared/utils/config';
import { ActivatedRoute } from '@angular/router';
import { PAGES } from 'projects/cityscreen/src/modules/posts-and-devices/namespace';
import { AdminPanelApi } from 'src/api/adminPanel/api';
import { NgLocalization } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectCurrentMo, selectMos } from 'projects/cityscreen/src/modules/core/store/selectors';
import { filter, take } from 'rxjs/operators';
import { saveDataToDisk } from 'src/utils';
import { ErrorTransformer_model } from 'src/api/dataProvider/DataProvider';

@Component({
    selector: 'post-report-tza',
    templateUrl: './post-report-tza.component.html',
    styleUrls: ['./post-report-tza.component.less']
})
export class PostReportTzaComponent {

    TEXTS = TEXTS;
    pages = PAGES;
    navigationLink: PATH[] = [];
    isActiveLoader = false;
    errorDownloadMsg = '';
    targetMo = '';
    targetMoId: number = null;
    objectForReportMo: MoItemsDataToExcelRequestProps = {
        timeBegin: null,
        timeEnd: null,
        moIds: [],
        interval: 2,
        type: 2,
        unitsType: 1,
        insertPdk: false
    };

    isShowDropdownForStations = false;

    listStations: CheckboxItem[] = []

    postsListLabels = {
        expand: (num: number) => {
            return {
                ru: `${TEXTS.NOTIFICATIONS.showMore} ${num}`
            }[LANGUAGE] || `${num} ${TEXTS.NOTIFICATIONS.showMore}`;
        },
    };

    constructor(
        private postsAndDevicesFacade: PostsAndDevicesFacade,
        private activatedRoute: ActivatedRoute,
        public adminPanelApi: AdminPanelApi,
        private ngLocalization: NgLocalization,
        public store: Store<any>,
    ) {
        const idRouter = this.activatedRoute.snapshot.params.id;
        const isCheckExists = this.postsAndDevicesFacade.checkExistsPage(idRouter);
        if (isCheckExists) {
            this.getDataForReportTZA();
        } else {
            this.postsAndDevicesFacade.openPage(this.pages.networks + '/' + this.pages.postsList);
        }
    }

    getDataForReportTZA() {
        this.objectForReportMo.timeBegin = this.getStndTimeBegin();
        this.objectForReportMo.timeEnd = this.getStndTimeEnd();
        const isAllMo = this.activatedRoute.snapshot.params.id == 'all';
        this.store.select(selectCurrentMo)
            .pipe(take(1))
            .subscribe(scm => {
                this.targetMoId = scm.id
            })
        this.store.select(selectMos)
            .pipe(filter(mos => !!mos.length))
            .subscribe(mos => {
                mos.forEach(s => {
                    if (s.id == this.activatedRoute.snapshot.params.id || this.targetMoId == s.id) {
                        this.targetMo = s.name
                        this.listStations.push({
                            id: s.id,
                            label: s.name,
                            selected: true
                        })
                    } else {
                        this.listStations.push({
                            id: s.id,
                            label: s.name,
                            selected: isAllMo
                        })
                    }
                })
            })

        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.postAndDevices,
            path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0]
        })
        if (this.activatedRoute.snapshot.url[1].path == this.pages.details) {
            this.navigationLink.push({
                name: this.targetMo,
                path: this.pages.networks + '/' + this.activatedRoute.snapshot.url[0] + '/' + this.activatedRoute.snapshot.url[1] + '/' + this.activatedRoute.snapshot.params.id
            })
        }
        this.navigationLink.push({
            name: TEXTS.POSTS_AND_DEVICES.tza4,
            path: null
        })
    }

    getStndTimeBegin: () => number = () => moment().startOf('hour').subtract(LOAD_HISTORY_DEFAULT, 'days').valueOf();

    getStndTimeEnd: () => number = () => {
        const m = moment();
        m.startOf('minutes')
        m.subtract(m.get('minutes') % 20, 'minutes') // floor of 20 minutes
        return m.valueOf();
    }

    getDateBeginRangeFromCalendar(time) {
        this.objectForReportMo.timeBegin = time;
    }

    getDateEndRangeFromCalendar(time) {
        this.objectForReportMo.timeEnd = time;
    }

    getTags() {
        return this.listStations.filter(s => s.selected);
    }

    backToPrevPage(navigationLink: PATH) {
        if (navigationLink.path) this.postsAndDevicesFacade.openPage(navigationLink.path);
    }

    selectedCheckboxText(num: number = 0) {
        const {selectedStation, station} = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);

        return [
            selectedStation[category],
            num,
            station[category]
        ].join(' ');
    }

    removeFromList(item: CheckboxItem) {
        item.selected = false;
    }

    selectItem(data: {item: CheckboxItem, isSelect: boolean}) {
        data.item.selected = data.isSelect;
    }

    downloadReportMo = () => {
        this.isActiveLoader = true;
        this.objectForReportMo.moIds = this.listStations.filter(s => s.selected).map(s => Number(s.id));

        const filename = this.TEXTS.EDIT_STATION.downloadReport + moment().format('_YYYYMMDD_HHmm') + '.xlsx';
        this.adminPanelApi.SaveMoDataToExcel(this.objectForReportMo)
            .then((data: string) => {
                saveDataToDisk(data, filename);
                this.offActiveLoader();
            })
            .catch((errorDownloadMsg: ErrorTransformer_model) => {
                this.errorDownloadMsg = errorDownloadMsg.message;
                this.offActiveLoader();
            })
    };

    offActiveLoader() {
        setTimeout(() => {
            this.isActiveLoader = false;
        }, 1000);
    }

}
