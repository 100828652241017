import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { TooltipsModule } from 'projects/shared/components/tooltips/tooltips.module';

import { CardComponent } from 'projects/cityscreen/src/components/card/components/card/card.component';
import { CardActionsModule } from 'projects/cityscreen/src/components/card-actions/card-actions.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        TooltipsModule,
        CardActionsModule
    ],
    exports: [
        CardComponent,
    ],
    declarations: [
        CardComponent,
    ]
})
export class CardModule {}
