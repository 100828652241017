<div class="plumes-sources-list scroll">
    <div class="plumes-sources-list__content">
        <ca-card
            class="plumes-sources-list-run-config__item"
            *ngFor="let card of plumesFacadeService.runConfig$ | async"
            [title]="card.name"
            [isActive]="card.is_active"
            [statusText]="TEXTS.PLUMES.runConfigurationStatus"
            [actionsActive]="popupActive"
            [actionsTooltipText]="TEXTS.COMMON.actions"
            (openActionsPopup)="openActionsPopup($event, card)"
        >
            <plumes-calculation
                [minutes]="card.schedule_period"
                [valueTypes]="valueTypes"
            ></plumes-calculation>
        </ca-card>

        <div class="plumes-sources-list__title">
            {{TEXTS.PLUMES.sourcesListTitle}}
        </div>

        <div class="plumes-sources-list__item"
            *ngFor="let source of plumesFacadeService.allSources$ | async"
        >
            <plumes-source
                [source]="source"
                [valueTypes]="valueTypes"
            ></plumes-source>
        </div>
    </div>
</div>

<ng-template #actionsOutlet>
    <card-actions-list
        [position]="popupPositionTop"
        (clickOutside)="closeActionsPopup($event)"
    >
        <card-actions-item
            [text]="TEXTS.PLUMES.editRunConfigurationAction"
            (click)="editRunConfiguration($event, selectedCard)"
        ></card-actions-item>

        <ng-container *ngIf="selectedCard.is_active; else notActive">
            <card-actions-item
                [text]="TEXTS.PLUMES.stopRunConfigurationAction"
                (click)="stopRuns($event, selectedCard)"
            ></card-actions-item>
        </ng-container>

        <ng-template #notActive>
            <card-actions-item
                [text]="TEXTS.PLUMES.startRunConfigurationAction"
                (click)="stopRuns($event, selectedCard)"
            ></card-actions-item>
        </ng-template>
    </card-actions-list>
</ng-template>
