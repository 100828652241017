<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    [attr.width]="getWidth() + 'px'"
    [attr.height]="getHeight() + 'px'"
    [ngStyle]="{
        opacity: marker.opacity,
        visibility: marker.opacity !== 0 ? 'visible' : 'hidden'
    }"
    [attr.viewBox]="isTypeOpenAQ ? '0 0 55 70.5' : '0 0 38.9 ' + markerHeight" x="0px" y="0px"
>
    <defs>
        <g id="dash">
            <path d="M21.4,18.1h-3.4" stroke-width="1.2487" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </defs>
    <ng-container *ngIf="isTypeOpenAQ; else defaultTemplate">
        <path
            [attr.fill]="getMainColor()"
            d="M49,0H6C2.7,0,0,2.7,0,6v43c0,3.3,2.7,6,6,6h12l9.6,15.5L37.1,55h12c3.3,0,6-2.7,6-6V6C55,2.7,52.3,0,49,0z"
        />
        <path
            [attr.fill]="getCircleColor()"
            style="stroke: #fff; stroke-width: 2; stroke-miterlimit: 10;"
            d="M13.8,12.6h27.5c0.7,0,1.2,0.5,1.2,1.2v27.5c0,0.7-0.5,1.2-1.2,1.2H13.8c-0.7,0-1.2-0.5-1.2-1.2V13.8 C12.6,13.1,13.1,12.6,13.8,12.6z"
        />
        <text
            [attr.fill]="getTextColor()"
            [attr.transform]="getTextTransform()"
            [style.fontSize]="getTextSize() + 'px'"
            style="font-family: Arial; font-weight: normal;"
        >
            {{getTextValue()}}
        </text>
    </ng-container>
    <ng-template #defaultTemplate>
        <path
            [attr.fill]="getMainColor()"
            d="M33.375,5.70002C25.97498-1.7,13.97498-1.60003,6.57501,5.8C5.67499,6.70002,4.875,7.70002,4.17499,8.8  c-2.5,3.5-3.59998,7.90002-3,12.20001c0.5,2.79999,1.5,5.5,2.90002,7.89996c3.59998,6.70001,7.79999,13.10004,12.59998,19.10004  c1.10004,1.39996,2.20001,2.79999,3.40002,4.20001c0.20001-0.20001,0.39996-0.40002,0.5-0.60004  c2.70001-3.59998,5.5-7.09998,8.09998-10.70001c3.40002-4.5,6.20001-9.39996,8.60004-14.59998  c1.09998-2.20001,1.69995-4.5,1.79999-6.90002C39.17499,14.20002,37.07501,9.3,33.375,5.70002z M19.70001,28.52497  c-5.75757,0-10.42499-4.66742-10.42499-10.42499S13.94244,7.675,19.70001,7.675S30.125,12.34242,30.125,18.09999  S25.45758,28.52497,19.70001,28.52497z"
        />
        <circle fill="#fff" cx="19.7" cy="18.1" r="12"/>
        <circle
            [attr.fill]="getCircleColor()"
            cx="19.7" cy="18.1" r="10"
        />
        <path
            *ngIf="canNotify; else default"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            d="M25.70877,14.3233 L18.4719,21.3409 L15.1825,18.1510 L18.4719,21.3409"
        />
        <ng-template #default>
            <ng-container *ngIf="!marker.aqi; else textValue">
                <ng-container *ngIf="selected; else notSelected">
                    <use href="#dash" stroke="#FFF"/>
                </ng-container>
                <ng-template #notSelected>
                    <ng-container *ngIf="!canAdd; else textValue">
                        <use href="#dash" stroke="#B8BFCC"/>
                    </ng-container>
                </ng-template>
            </ng-container>
            <ng-template #textValue>
                <text
                    [attr.fill]="getTextColor()"
                    [attr.transform]="getTextTransform()"
                    [style.fontSize]="getTextSize() + 'px'"
                    style="font-family: Inter; font-weight: normal;"
                >
                    {{getTextValue()}}
                </text>
            </ng-template>
        </ng-template>
    </ng-template>
</svg>
