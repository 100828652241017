import { Component, Input } from '@angular/core';

@Component({
    selector: 'card-actions-list',
    templateUrl: 'card-actions-list.component.html',
    styleUrls: ['card-actions-list.component.less']
})
export class CardActionsListComponent {
    @Input() position: number;
}
