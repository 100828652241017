import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgLocalization } from '@angular/common';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import type { CheckboxItem } from 'src/namespace';
import { AdminDevice } from 'src/namespace';
import { LANGUAGE, TEXTS } from 'src/texts/texts';
import { dateRangeText, shortDateTime } from 'src/utils/date-formats';
import { NotificationsStateService } from 'projects/cityscreen/src/modules/notifications/notifications-state.service';
import { FilterByPipe } from 'projects/shared/pipes/pipes';

import { DashboardApi } from '../../services/dashboard.api';
import { DashboardFacadeService } from '../../services/dashboard.facade';
import { Device, DeviceInfo, DevicesStatsModel, DeviceStatus } from '../../services/dashboard.model';
import { createBackgroundGradientsPlugin, GradientConfig } from '../../chartjs-plugins';
import { DeviceStatusLabel, STATUS_TYPES } from '../device-status/device-status.types';
import { DeviceStatusCheckboxItem, STATUS_TEXTS } from '../device-status-filter/device-status-filter.component';

type StatsChart = {
    chart: {
        data: number[];
        size: number;
        labels: string[];
        labelsBg: string[];
        gradientsConfig: GradientConfig[];
        plugins: Chart.PluginServiceRegistrationOptions[];
    };
    devices: string[];
};

type DeviceExtended = {
    device: Device;
    statusList: DeviceStatusLabel[];
};

const TIME_INTERVAL_DAYS = 2;
const DOUGHNUT_CHART_SIZE = 105;
const DOUGHNUT_BIG_CHART_SIZE = 142;
const DATA_AVAILABILITY_THRESHOLD = 95;

function createStatusList(status: DeviceStatus) {
    const tags: DeviceStatusLabel[] = [];

    if (status.isBatLow) {
        const type = STATUS_TYPES.LOW_BATTERY;
        tags.push({ type, text: STATUS_TEXTS[type] });
    }

    if (status.isGsmLow) {
        const type = STATUS_TYPES.LOW_SIGNAL;
        tags.push({ type, text: STATUS_TEXTS[type] });
    }

    if (status.hasMissingPackets) {
        const type = STATUS_TYPES.NO_PACKETS;
        tags.push({ type, text: STATUS_TEXTS[type] });
    }

    return tags;
}

@Component({
    selector: 'dashboard-page',
    templateUrl: 'dashboard-page.component.html',
    styleUrls: ['dashboard-page.component.less']
})
export class DashboardPageComponent implements OnInit {
    @Input() devices: AdminDevice[];

    @Output() adminPanelCallback = new EventEmitter<void>();

    TEXTS = TEXTS;

    dataForChart: DeviceExtended[];

    dataForChartFiltered: DeviceExtended[];

    beginDate: Date;
    endDate: Date;
    intervalWidth = 44;
    ticks = 13;
    scaleDates: Date[];
    dateRangeFilterText: string;
    currentMonth = moment().format('MMMM');

    dataTransmissionStats = {
        title: TEXTS.DASHBOARD.dataTransmissionTitle,
        chart: {
            size: DOUGHNUT_CHART_SIZE,
            data: [],
            labels: [],
            labelsBg: [],
            gradientsConfig: [
                {
                    colorStops: [{
                        percent: 0,
                        color: '#FF5F5F'
                    }, {
                        percent: 1,
                        color: '#C83FBE'
                    }],
                    angleDeg: 0
                },
                {
                    colorStops: [{
                        percent: 0.0857,
                        color: '#4776E6'
                    }, {
                        percent: 0.8168,
                        color: '#8E54E9'
                    }],
                    angleDeg: 59.96
                }
            ],
            plugins: [{
                beforeInit: (chart: Chart) => {
                    const { gradientsConfig } = this.dataTransmissionStats.chart;
                    createBackgroundGradientsPlugin(DOUGHNUT_CHART_SIZE, gradientsConfig)(chart);
                }
            }]
        },
        devices: []
    };

    powerSupplyStats = {
        title: TEXTS.DASHBOARD.powerSupplyTitle,
        chart: {
            size: DOUGHNUT_CHART_SIZE,
            data: [],
            labels: [],
            labelsBg: [],
            gradientsConfig: [
                {
                    colorStops: [{
                        percent: 0.1471,
                        color: '#FFB800'
                    }, {
                        percent: 0.9012,
                        color: '#FFD729'
                    }],
                    angleDeg: 247.37
                },
                {
                    colorStops: [{
                        percent: 0.1549,
                        color: '#BEC617'
                    }, {
                        percent: 0.848,
                        color: '#97BA1E'
                    }],
                    angleDeg: 102.01
                }
            ],
            plugins: [{
                beforeInit: (chart: Chart) => {
                    const { gradientsConfig } = this.powerSupplyStats.chart;
                    createBackgroundGradientsPlugin(DOUGHNUT_CHART_SIZE, gradientsConfig)(chart);
                }
            }]
        },
        devices: []
    };

    monthlyStats = {
        title: TEXTS.DASHBOARD.monthlyStatsChartTitle,
        chart: null
    };

    legacyDevicesOnline$ = new BehaviorSubject<DevicesStatsModel>(null);
    legacyDevicesPower$ = new BehaviorSubject<DevicesStatsModel>(null);

    lastPacketReceivedDate = '';

    deviceStatusTypes = STATUS_TYPES;

    deviceFilter = {
        isActive: false,
        data: [] as CheckboxItem[]
    };

    statusFilter = {
        isActive: false,
        data: [] as DeviceStatusCheckboxItem[]
    };

    filterByDevicesPredicate = (d: DeviceExtended) => {
        return this.deviceFilter.data
            .filter(d => d.selected)
            .map(d => d.label)
            .includes(d.device.deviceInfo.SerialNumber);
    };

    filterByStatusPredicate = (d: DeviceExtended) => {
        const selected = new Set(
            this.statusFilter.data.filter(s => s.selected).map(d => d.id)
        );

        const statusListSet = new Set(d.statusList.map(s => s.type));

        const intersection = new Set<STATUS_TYPES>();

        for (const elem of statusListSet) {
            if (selected.has(elem)) {
                intersection.add(elem);
            }
        }

        return intersection.size > 0;
    };

    constructor(
        private dashboardApi: DashboardApi,
        private dashboardFacadeService: DashboardFacadeService,
        private stateService: NotificationsStateService,
        private ngLocalization: NgLocalization,
        private filterByPipe: FilterByPipe<DeviceExtended>
    ) {
        this.statusFilter.data = [
            STATUS_TYPES.LOW_BATTERY,
            STATUS_TYPES.NO_PACKETS,
            STATUS_TYPES.LOW_SIGNAL
        ].map(type => ({
            id: type,
            label: STATUS_TEXTS[type],
            selected: false
        }));

        this.dashboardFacadeService.updateCollection$
            .asObservable()
            .subscribe((data) => {
                if (data) {
                    this.dataForChart = data.map(device => ({
                        device,
                        statusList: createStatusList(device.deviceStatus)
                    }));

                    const deviceFilter = data.map((d, i) => ({
                        id: d.deviceInfo.DeviceId,
                        label: d.deviceInfo.SerialNumber,
                        selected: this.deviceFilter.data[i]?.selected ?? true
                    }));

                    this.filterCharts(this.dataForChart, deviceFilter, this.statusFilter.data);
                }
            });

        this.dashboardFacadeService.updateStats$
            .asObservable()
            .pipe(filter(data => !!data))
            .subscribe((data) => {
                // this.updateStatsChart(this.dataTransmissionStats, data.dataTransmission);
                // this.createLegendFor(this.dataTransmissionStats.chart, TEXTS.NOTIFICATIONS.station, TEXTS.DASHBOARD.dataTransmissionStatus);

                // this.updateStatsChart(this.powerSupplyStats, data.powerSupply);
                // this.createLegendFor(this.powerSupplyStats.chart, TEXTS.NOTIFICATIONS.station, TEXTS.DASHBOARD.powerSupplyStatus);

                this.monthlyStats.chart = this.createMonthlyStats(data.availability);
                this.lastPacketReceivedDate = shortDateTime(data.lastPacketReceivedDate);
            });

        this.legacyDevicesOnline$
            .asObservable()
            .pipe(filter(data => !!data))
            .subscribe((data) => {
                this.updateStatsChart(this.dataTransmissionStats, data);
                this.createLegendFor(this.dataTransmissionStats.chart, TEXTS.NOTIFICATIONS.station, TEXTS.DASHBOARD.dataTransmissionStatus);
            });

        this.legacyDevicesPower$
            .asObservable()
            .pipe(filter(data => !!data))
            .subscribe((data) => {
                this.updateStatsChart(this.powerSupplyStats, data);
                this.createLegendFor(this.powerSupplyStats.chart, TEXTS.NOTIFICATIONS.station, TEXTS.DASHBOARD.powerSupplyStatus);
            });
    }

    ngOnInit() {
        this.stateService.openEventFeed();

        const end = moment().add(1, 'day');
        this.endDate = end.startOf('day').toDate();
        this.beginDate = end.subtract(TIME_INTERVAL_DAYS, 'days').startOf('day').toDate();
        this.dashboardApi.getData(this.beginDate, this.endDate);

        this.scaleDates = this.createChartLabels();
        this.dateRangeFilterText = dateRangeText(this.beginDate, this.endDate);

        this.refreshData();
    }

    updateStatsChart(stats: StatsChart, data: DevicesStatsModel) {
        stats.chart.data = [data.offline, data.online];
        stats.devices = data.offlineDevicesList;

        stats.chart.labelsBg = stats.chart.gradientsConfig.map(g =>
            `linear-gradient(${g.angleDeg}deg, ${g.colorStops.map(c => `${c.color} ${c.percent}%`).join(', ')})`
        );
    }

    refreshData() {
        this.adminPanelCallback.emit();

        [
            {
                stream: this.legacyDevicesOnline$,
                field: 'offline'
            },
            {
                stream: this.legacyDevicesPower$,
                field: 'battery'
            }
        ].forEach((config) => {
            const devicesOffline = this.devices.filter(d => d[config.field]);
            const devicesOfflineCount = devicesOffline.length;

            config.stream.next({
                online: this.devices.length - devicesOfflineCount,
                offline: devicesOfflineCount,
                offlineDevicesList: devicesOffline.map(d => d.serialNumber)
            });
        });

        this.dashboardApi.getStats();
    }

    private createChartLabels() {
        const begin = this.beginDate.getTime();
        const step = (this.endDate.getTime() - begin) / (this.ticks - 1);
        return Array(this.ticks).fill(0).map((_, i) => new Date(begin + i * step));
    }

    private createLegendFor(
        chart: StatsChart['chart'],
        subjectCategorized: object,
        labelsContent: string[]
    ) {
        chart.labels = labelsContent.map((text, i) => {
            const { data } = chart;
            const category = this.ngLocalization.getPluralCategory(data[i], LANGUAGE);

            return `${subjectCategorized[category]} ${text}`;
        });
    }

    changeTimeInterval(direction: 1 | -1) {
        if (direction === 1) {
            this.beginDate = this.endDate;
            this.endDate = moment(this.endDate).add(TIME_INTERVAL_DAYS, 'days').toDate();
        }

        if (direction === -1) {
            this.endDate = this.beginDate;
            this.beginDate = moment(this.beginDate).subtract(TIME_INTERVAL_DAYS, 'days').toDate();
        }

        this.dateRangeFilterText = dateRangeText(this.beginDate, this.endDate);
        this.dashboardApi.getData(this.beginDate, this.endDate);

        this.scaleDates = this.createChartLabels();
    }

    prevTimeInterval() {
        this.changeTimeInterval(-1);
    }

    nextTimeInterval() {
        if (!this.todayIsIncluded()) {
            this.changeTimeInterval(1);
        }
    }

    todayIsIncluded() {
        return Date.now() <= this.endDate.getTime();
    }

    postsListLabels = (() => {
        const { showMore, collapse } = TEXTS.NOTIFICATIONS;

        return {
            all: () => '',
            expand: (num: number) => {
                return {
                    ru: `${showMore} ${num}`
                }[LANGUAGE] || `${num} ${showMore}`;
            },
            collapse: () => collapse
        };
    })();

    createMonthlyStats(value: number): StatsChart['chart'] {
        const valuePercent = Math.round(100 * value);

        return {
            data: [100 - valuePercent, valuePercent],
            size: DOUGHNUT_BIG_CHART_SIZE,
            labels: [],
            labelsBg: [],
            gradientsConfig: [],
            plugins: [{
                beforeInit: (chart: Chart) => {
                    const mainValue = chart.config.data.datasets[0].data[1];

                    if (!mainValue) {
                        return;
                    }

                    const mainArc = {
                        colorStops: [{
                            percent: 0,
                            color: '#FF5F5F'
                        }, {
                            percent: 1,
                            color: '#C83FBE'
                        }],
                        angleDeg: 247.37
                    };

                    if (mainValue >= DATA_AVAILABILITY_THRESHOLD) {
                        mainArc.colorStops = [{
                            percent: 0,
                            color: '#4776E6'
                        }, {
                            percent: 1,
                            color: '#8E54E9'
                        }];
                    }

                    createBackgroundGradientsPlugin(DOUGHNUT_BIG_CHART_SIZE, [
                        {
                            colorStops: [{
                                percent: 0,
                                color: '#E6ECF2' // @grey3
                            }],
                            angleDeg: 0
                        },
                        mainArc
                    ])(chart);
                }
            }]
        };
    }

    sortingPredicate: (item: DeviceInfo) => string;
    sortingDirection = 1;

    sortDevicesProps = [
        {
            props: ({ deviceInfo }) => deviceInfo.SerialNumber,
            title: TEXTS.DASHBOARD.columnLabels.device
        },
        {
            props: ({ deviceInfo }) => deviceInfo.LocationName,
            title: TEXTS.DASHBOARD.columnLabels.monitoringObject
        }
    ];

    onChartsSorting = (sortItem: (item: DeviceInfo) => string) => {
        if (this.sortingPredicate === sortItem) {
            this.sortingDirection *= -1;
        } else {
            this.sortingPredicate = sortItem;
        }
    };

    filterCharts(data: DeviceExtended[], deviceFilterData: CheckboxItem[], statusFilterData: DeviceStatusCheckboxItem[]) {
        this.deviceFilter.data = deviceFilterData;
        this.statusFilter.data = statusFilterData;

        data = this.filterByPipe.transform(data, this.filterByDevicesPredicate);

        if (statusFilterData.some(s => s.selected)) {
            data = this.filterByPipe.transform(data, this.filterByStatusPredicate);
        }

        const { dataForChartFiltered: filtered } = this;

        if (
            !filtered ||
            filtered.length !== data.length ||
            filtered.some((item, i) => item !== data[i])
        ) {
            this.dataForChartFiltered = data;
        }
    }

    filterHasSelectedItems(data: CheckboxItem[] | DeviceStatusCheckboxItem[]) {
        return data.filter(v => v.selected).length;
    }

    noop() {}
}
