import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cs-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {

    show = false;
    saved: string;

    constructor() {
        this.saved = localStorage.getItem('feedback');
    }

    ngOnInit(): void {
    }

}
