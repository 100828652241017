<div class="group_change_button" (click)="show = !show" (clickOutside)="show = false">
    <div *ngIf="group?.icon"
         class="group_change_button__icon group_change_button__icon-{{show ? 'active' : ''}}"
         [ngStyle]="{'background-image': 'url('+group.icon+')'}"
    ></div>

    <div *ngIf="!group?.icon"
         class="group_change_button__custom_icon group_change_button__custom_icon-{{show ? 'active' : ''}}">
        {{group?.name.substr(0,1)}}
    </div>

    <div *showDirective="show" class="group_change_panel">
        <div class="group_change_panel__scroll scroll">
            <group-list-item *ngFor="let item of (store.select(selectGroupList) | async)"
                                        class="group_change_panel__group"
                                        [selected]="item.id === currentGroupId"
                                        [iconUrl]="item.icon"
                                        [name]="item.name"
                                        [postsCount]="item.moCount"
                                        (click)="coreFacade.changeGroup(item.id)"
            >
            </group-list-item>
        </div>
    </div>
</div>