<div class="confirm-unsaved-popup">
    <div class="confirm-unsaved-popup__text">
        {{text}}
    </div>
    <div class="confirm-unsaved-popup__btn-wrapper">
        <div class="confirm-unsaved-popup__btn">
            <ca-button type="redOutline" (clickAction)="continue()">
                {{continueText}}
            </ca-button>
        </div>
        <div class="confirm-unsaved-popup__btn">
            <ca-button type="primary" (clickAction)="close()">
                {{cancelText}}
            </ca-button>
        </div>
    </div>
</div>
