<div class="plumes_calc scroll">

    <div class="plumes_calc__date-filter"
        (clickOutside)="calendarIsOpened = false"
    >
        <ca-dropdown-button
            [text]="dateRangeFilterText"
            [type]="calendarIsOpened ? 'opened' : 'closed'"
            (click)="calendarIsOpened = !calendarIsOpened"
        ></ca-dropdown-button>
        <div class="plumes_calc__calendar">
            <calendar-months
                [show]="calendarIsOpened"
                [timeBegin]="plumesFacadeService.getEvaluationTimeBegin()"
                [timeEnd]="plumesFacadeService.getEvaluationTimeEnd()"
                [tzOffset]="tzOffset"
                [applyCb]="updateDateRange"
                [monthsVisible]="1"
                [columns]="1"
            ></calendar-months>
        </div>
    </div>

    <ng-container *ngIf="(plumesFacadeService.allRuns$ | async).length === 0 && !plumesFacadeService.isUpdating; else runsContent">
        <div class="plumes_calc__plug_text">{{TEXTS.PLUMES.selectedPeriodNoRunsAvailable}}</div>
        <div class="plumes_calc__svg"></div>
    </ng-container>

    <ng-template #runsContent>
        <div class="plumes_calc__content scroll" (scroll)="onScroll()" #scrollable>
            <ng-container *ngFor="let run of annotatedRuns$ | async">
                <div class="plumes_calc__annotation" *ngIf="run.annotation">
                    {{run.annotation}}
                </div>
                <div class="plumes_calc__card_wrapper"
                    (click)="plumesFacadeService.onRunSelected(run.item.id)"
                >
                    <plumes-calculation-results-card [isActive]="hasVisibleLayer(run.item)">
                        <plumes-run-summary class="plumes_calc__wrapper_run_summary" [run]="run.item"></plumes-run-summary>
                    </plumes-calculation-results-card>
                </div>
            </ng-container>
        </div>
        <ca-scroll-top *ngIf="!isScrolledToTop" (click)="scrollTop()"></ca-scroll-top>
    </ng-template>
</div>
