<div class="point_list">
    <div class="point_list__title_wrapper">
        <span class="point_list__title">Indoor</span>

        <search-input
                [textPlaceholder]="TEXTS.INDOOR.search"
                [(text)]="searchQuery"
        ></search-input>
    </div>

    <div class="point_list__sort_wrapper">
        <shared-sort-list-item
                class="point_list__sort_name"
                (click)="setSort(ROOM)"
                [sortingDirection]="(store.select(indoorSelectors.sort) | async).field === ROOM ? (store.select(indoorSelectors.sort) | async).direction : 0"
                [text]="TEXTS.INDOOR[ROOM]"
        ></shared-sort-list-item>

        <div class="point_list__plug"></div>

        <div class="iaq_info">
            <shared-sort-list-item
                class="point_list__sort_item"
                (click)="setSort(IAQ)"
                [sortingDirection]="(store.select(indoorSelectors.sort) | async).field === IAQ ? (store.select(indoorSelectors.sort) | async).direction : 0"
                [text]="TEXTS.NAMES[IAQ]"
            ></shared-sort-list-item>

            <ca-info-icon
                style="margin-top: -3px;"
                [active]="true"
                caTooltip
                caTooltipPlacement="right"
                [caTooltipTemplate]="info"
            ></ca-info-icon>
            <ng-template #info>
            <ca-info-tooltip
                    [text]="TEXTS.INDOOR.iaq"
            ></ca-info-tooltip>
        </ng-template>
        </div>

        <shared-sort-list-item
                *ngFor="let name of orderOfMeasures"
                class="point_list__sort_item"
                (click)="setSort(name)"
                [sortingDirection]="(store.select(indoorSelectors.sort) | async).field === name ? (store.select(indoorSelectors.sort) | async).direction : 0"
                [text]="TEXTS.NAMES[name]"
        ></shared-sort-list-item>
    </div>

    <div *ngIf="(points | multipleSearchFns : searchFilters : searchQuery).length === 0" class="point_list__not_found">
        <search-result-placeholder></search-result-placeholder>
    </div>

    <div class="point_list__items_wrapper scroll"
         [class.point_list__items_wrapper-shadow]="!isScrollTop"
         isScrollTop (isTop)="isScrollTop = $event"
         #list
    >
        <point-item *ngFor="let point of points | multipleSearchFns : searchFilters : searchQuery"
                    [point]="point"
                    [measurement]="store.select(indoorSelectors.getMeasurement(point.id)) | async"
                    [isActive]="(store.select(indoorSelectors.activePointId) | async) === point.id"
                    (click)="clickOnPoint.emit(point.id)">
        </point-item>
    </div>

    <ca-scroll-top *ngIf="!isScrollTop" class="point_list__to_top" (click)="scrollTop()"></ca-scroll-top>
</div>
