export const environment = {
    production: true,
    sentry: true,
    yandex_metrika: {
        id: 66985123,
        webvisor: true
    },
    is_mobile_app: false,
    vanga_api_auth_url: 'https://vanga.cityair.io/api/auth/v1',
    vanga_api_url: 'https://vanga.cityair.io/plumes/v1',
    api_url: 'https://api.cityscreen.io'
};
