import * as moment from 'moment';

export const AQI = 'AQI';
export const CO = 'CO';
export const CO2 = 'CO2';
export const NO = 'NO';
export const NO2 = 'NO2';
export const SO2 = 'SO2';
export const O3 = 'O3';
export const H2S = 'H2S';
export const NH3 = 'NH3';
export const CH2O = 'CH2O';
export const HUM = 'Humidity';
export const PM2  = 'Pm2';
export const PM10 = 'Pm10';
export const TEMP = 'Temperature';
export const PRES = 'Pressure';
export const HISTOGRAM_AQI = 'HISTOGRAM_AQI';
export const WDA = 'WDA';
export const WVA = 'WVA';
export const PDK = 'PDK';

export const MEASUREMENTS_ORDER = ['AQI', 'T', 'P', 'RH', 'PM2.5', 'PM10', 'CO', 'CO2', 'NO2', 'O3', 'H2S', 'NH3', 'SO2'];

export const SELECT_MEASUREMENTS_INIT = ['AQI'];

export const BAR_CHARTS = ['AQI'];

export const AUTO_SCALED_MEASUREMENTS = ['T', 'P', 'RH'];

export const CHART_STATIC_COLORS = {
    pdk: 'red',
    norma: '#ef7c1b',
    [PM2]: '#A2C617',
    [PM10]: '#A2C617',
    [TEMP]: '#FF494B',
    [HUM]: '#8FCBF9',
    [PRES]: '#FFCC33',
    [AQI]: '#888',
    [CO2]: '#888',
    [O3]: '#D63B50',
    [H2S]: '#D63B50',
    [NH3]: '#D63B50',
    [SO2]: '#D63B50',
};

export const METEO_VALUES_COLORS = {
    T: CHART_STATIC_COLORS[TEMP],
    RH: CHART_STATIC_COLORS[HUM],
    P: CHART_STATIC_COLORS[PRES],
    'PM2.5': CHART_STATIC_COLORS[PM2],
    PM10: CHART_STATIC_COLORS[PM10],
    CO: CHART_STATIC_COLORS[CO],
    NO2: CHART_STATIC_COLORS[NO2],
    O3: CHART_STATIC_COLORS[O3],
    H2S: CHART_STATIC_COLORS[H2S],
    NH3: CHART_STATIC_COLORS[NH3],
    SO2: CHART_STATIC_COLORS[SO2],
};

export const CHART_LINE_COLORS = [
    '#8CB917',
    '#8CB917',
    '#A2C617',
    '#BEC617',
    '#FFCC33',
    '#FFA33B',
    '#FF7344',
    '#FF494B',
    '#D63B50',
    '#AD2D55',
    '#821E5A',
    '#821E5A',
    '#821E5A',
];

export const LINE_DASH_STYLES = [
    [],
    [1, 1],
    [8, 3],
    [8, 3, 2, 3, 2, 3],
    [8, 3, 2, 3]
];

export const DAY_OR_HOUR_BOUND = 24 * 60; // minutes in day

export const DEFAULT_METEO_VALUES_COLOR = '#A2C617';

export const TIMELINE_COLOR = ['#dddddd', '#dddddd', '#dddddd', '#dddddd', '#FFA33B', '#FFA33B', '#ffa33b', '#D63B50', '#D63B50', '#d63b50', '#d63b50'];

export type FlagDirection = 'left' | 'right';

export const STEP_DURATION = 400;

export const CITY_OBJ_TYPE = 'city';

export const COUNTRY_OBJ_TYPE = 'country';

const DURATION_DATE_IN_DAYS = 3;

const currentDate = moment().utc();

export const START_DATE_RANGE = moment().startOf('day').subtract(DURATION_DATE_IN_DAYS, 'days').valueOf();

export const FINISH_DATE_RANGE = currentDate.valueOf();

export const DEFAULT_APP_CONFIG = {
    sidebar: true,
    compare: true,
    calendar: true,
    fires: true,
    forecast: true,
    sharing: true,
    lang: true
};

export const INIT_TIME = {
    time: moment().startOf('day').valueOf(),
    timeIndex: 0,
    date: [],
};
