<div class="popup__wrapper_popup">
    <div class="popup__wrapper_input">
        <input-date-range
            [timeBegin]="downloadPopupObj.timeBegin"
            [timeEnd]="downloadPopupObj.timeEnd"
            (timeBeginChange)="downloadPopupObj.timeBegin = $event; emitObject();"
            (timeEndChange)="downloadPopupObj.timeEnd = $event; emitObject();"
        ></input-date-range>
    </div>
    <div class="popup__wrapper_input popup__wrapper_input-min_padding">
        <input-dropdown
            [inputText]="selectedCheckboxText(currentMos.length)"
            [label]="texts.NOTIFICATIONS.selectPostsSmall"
            [(showDropdown)]="moDropdownOpened"
            (clickOutside)="moDropdownOpened = false"
        >
            <cs-selectbox
                [listItems]="objDropdownList"
                (listItemsChange)="getObjectForReport($event)"
                [searchPlaceholderText]="texts.NOTIFICATIONS.searchMoPlaceholderText"
                [selectAllText]="texts.LIST_OM.checkAll"
            ></cs-selectbox>
        </input-dropdown>
    </div>
    <div class="popup__wrapper_tags_list scroll"
        [class.popup__wrapper_tags_list-active]="currentMos.length !== 0"
    >
        <tags-list
            *ngIf="currentMos.length !== 0"
            [tags]="currentMos"
            [truncateTo]="maxInlineMos"
            [textNodesFns]="postsListLabels()"
            [editable]="true"
            (removeTag)="removePostFromList($event)"
        ></tags-list>
    </div>
    <ng-container *ngIf="downloadPopupObj.downloadType == 1">
        <div class="popup__ca_switch_name">
            {{texts.DOWNLOAD_POPUP.dateRange}}
        </div>
        <div class="popup__wrapper_ca_switch">
            <switch-item
                [currentTab]="currentFilterTabs"
                [tabs]="filterTabsInterval"
                (action)="setTabsInterval($event)"
            ></switch-item>
        </div>
        <div class="popup__ca_switch_name popup__ca_switch_name-units_measurements">
            {{texts.DOWNLOAD_POPUP.unitsMeasurements}}
        </div>
        <div class="popup__unit_measurements">
            <div class="popup__wrapper_ca_switch">
                <switch-item
                    [currentTab]="currentUnitsMeasurements"
                    [tabs]="unitsMeasurementsList"
                    (action)="getUnitMeasurement($event)"
                ></switch-item>
            </div>
            <div class="popup__wrapper_item_checkbox" *ngIf="language == 'ru'"
                [class.popup__wrapper_item_checkbox-disabled]="isDisabledBtnPdk"
                (click)="getPDK()"
            >
                <cs-checkbox class="popup__checkbox" [checked]="isShareOfMPC"></cs-checkbox>
                <div class="popup__checkbox_name">{{texts.shareOfMPC}}</div>
            </div>
        </div>
    </ng-container>
    <div class="popup__error scroll"
        [class.popup__error-active]="errorDownloadMsg"
    >
        {{texts.DOWNLOAD_POPUP.error}}
    </div>
</div>