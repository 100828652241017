import { AfterContentInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
    AQI,
    CHART_NAME_CITYAIR,
    CHART_NAME_TIMELINE,
    CHART_TYPE_FULL_WIDTH,
    CHART_TYPE_TIMELINE,
    MeasuresInfoFormat,
    TabModel,
    TimeEventFrom
} from 'src/namespace';

import '../../style.less';
import { TimelineState } from '../../TimelineState';
import { getChartShiftWidth, TimelineActions } from '../../TimelineActions';
import { dragFlag } from '../../dragFlag';
import { SharedCoreFacade } from 'projects/shared/core/SharedCoreFacade';
import { IS_PUBLIC } from 'projects/shared/utils/other-utils';
import {
    GroupExtConfigName,
    GroupFeaturesService
} from 'projects/cityscreen/src/modules/core/services/group-features/group-features.service';
import { Time } from 'projects/cityscreen/src/modules/core/services/time/time';
import {
    CHART_COLORS,
    COLOR_BLUE,
    formatterFlagTime,
    formatterFlagTimeHour,
    formatterFlagTimeDateWithYear,
} from 'src/config';
import { isRU, TEXTS } from 'src/texts/texts';
import { selectIsSidebarOpen } from 'projects/cityscreen/src/modules/core/store/selectors';
import { Store } from '@ngrx/store';

@Component({
    selector: 'time-line',
    template: `
        <div [ngClass]="{
                'timeline__wrapper timeline__wrapper-displayFlex': true,
                'timeline__wrapper--public': isPublic
            }"
        >
            <div>
                <div [ngClass]="{
                        'timeline__top': true,
                        'timeline-panel': store.select(selectIsSidebarOpen) | async
                    }"
                >
                    <cityair-global-qr #qr
                       *ngIf="showQRCode"
                       class="qr-code-link"
                       [class.bottom-controls--adjusted]="adjustBottomControls"
                    ></cityair-global-qr>
                    <div *ngIf="sharedCoreFacade.getModuleConfig().enableCompare"
                         class="versus_stantions ellipsis"
                         [class.bottom-controls--adjusted]="adjustBottomControls"
                    >
                        <label class="ios7-switch">
                            <div class="ios7-switch__wrapper">
                                {{TEXTS.STATION_BLOCK.compare}}
                                <input type="checkbox" (click)="timelineActions.toggleCompareMod()"
                                       [checked]="sharedCoreFacade.getIsCompareMod()"/>
                                <span></span>
                            </div>
                        </label>
                    </div>
                    <!-- stationMainBlock -->
                    <div *ngIf="sharedCoreFacade.isComparedListNotEmpty()" class="timeline__stations_wrapper">
                        <div class="timeline__left">
                            <div class="timeline__header">
                                <div class="timeline__segment timeline__change_type">
                                    <div *ngIf="timelineState.chartType !== 'simple'"
                                         class="timeline__arrow timeline__arrow-down"
                                         (click)="timelineActions.changeChartType('simple')"></div>
                                    <div *ngIf="timelineState.chartType === 'simple'"
                                         class="timeline__arrow timeline__arrow-top"
                                         (click)="timelineActions.changeChartType('full')"></div>
                                </div>
                                <div class="timeline__header__title ellipsis">
                                    {{config?.timelineTitle || TEXTS.STATION_BLOCK.dataOnPollutants}}
                                </div>
                            </div>
                            <div *ngFor="let item of sharedCoreFacade.getComparedList(); let i = index;"
                                 class="timeline__tr" style="justify-content: space-between;">
                                <timeline-selected-item
                                    [item]="item"
                                    [lineNumber]="sharedCoreFacade.getComparedList().length > 1 ? i : null"
                                    (titleOnClick)="timelineActions.clickForTitleTimeline(item)"
                                ></timeline-selected-item>
                            </div>
                            <div [ngClass]="{
                                'timeline__footer': true,
                                'timeline__footer-show': showPdk(),
                                'timeline__footer-left_block': true
                            }">
                                {{showPdk() === 'mr' ? TEXTS.COMMON.pdkMr : TEXTS.COMMON.pdkSs}}:
                            </div>
                        </div>
                        <div class="timeline__center">
                            <div class="timeline__header">
                                <select-measures
                                    *ngIf="!groupFeaturesService.getConfig(GroupExtConfigName.chartSingleSelect); else radioSelect"
                                    class="timeline__tr timeline__tr-header"
                                    className="timeline__measure_block"
                                    [measuresSelected]="sharedCoreFacade.getMeasuresSelected()"
                                    [measuresList]="sharedCoreFacade.getMeasuresList()"
                                    [select]="select"
                                ></select-measures>
                                <ng-template #radioSelect>
                                    <select-measures-radio
                                        class="timeline__tr timeline__tr-header"
                                        [measuresSelected]="sharedCoreFacade.getMeasuresSelected()"
                                        [measuresList]="sharedCoreFacade.getMeasuresList()"
                                        [select]="select"
                                    ></select-measures-radio>
                                </ng-template>
                            </div>
                            <div class="timeline__tr"
                                 *ngFor="let station of sharedCoreFacade.getComparedList(); index as i"
                            >
                                <chart-compare-measure-indicator-new class="timeline__tr"
                                                                     className="timeline__measure_block"
                                                                     [station]="station"
                                                                     [measuresList]="sharedCoreFacade.getMeasuresList()"
                                                                     [color]="CHART_COLORS[i]"
                                                                     [pdkTypeIndicator]="showPdk()"
                                ></chart-compare-measure-indicator-new>
                            </div>
                            <div [ngClass]="{
                                'timeline__footer': true,
                                'timeline__footer-show': showPdk()
                            }">
                                <ng-container *ngFor="let m of sharedCoreFacade.getMeasuresList();">
                                    <timeline-footer-pdk
                                        class="timeline__footer-pdk"
                                        [class.timeline__footer-pdk--multiline]="isMultiline(m.name)"
                                        [measureType]="m.type"
                                        [unit]="m.unit"
                                        [pdk]="showPdk()"
                                    ></timeline-footer-pdk>
                                </ng-container>
                            </div>
                        </div>
                        <div class="timeline__right">
                            <div class="timeline__close ellipsis" (click)="timelineActions.onCloseTimeline()">
                                [ {{COMMON.close}} ]
                            </div>
                            <div *ngFor="let station of sharedCoreFacade.getComparedList(); let i = index;"
                                 class="timeline__tr timeline__tr-right">
                                <div
                                    *ngIf="sharedCoreFacade.getModuleConfig().enableCompare && !sharedCoreFacade.getIsCompareMod()"
                                    class="timeline__add_del button_link button_link-blue"
                                    (click)="timelineActions.toggleCompareMod()">{{ TEXTS.STATION_BLOCK.addToComparison }}</div>
                                <div *ngIf="sharedCoreFacade.getIsCompareMod()"
                                     class="timeline__add_del timeline__add_del-del"
                                     (click)="timelineActions.deleteCompareStation(i)"></div>
                            </div>
                            <div [ngClass]="{
                                'timeline__footer': true,
                                'timeline__footer-show': showPdk(),
                                'timeline__footer-right': true
                            }"></div>
                        </div>
                    </div>
                    <!-- /stationMainBlock -->
                    <div [ngClass]="{
                            'timeline__chart': true,
                            'timeline__chart-hide': timelineState.chartType === 'simple' || sharedCoreFacade.isComparedListEmpty()
                        }"
                    >
                        <loader [show]="sharedCoreFacade.getMainChartIsLoading()"
                                [className]="'loader-spinner-chart'"></loader>

                        <div [ngClass]="{
                                'anim_showing': true,
                                'anim_showing-show': !sharedCoreFacade.getMainChartIsLoading()
                            }"
                             style=" position: relative;"
                        >
                            <stnd-chart [type]="config?.chartConfig || CHART_TYPE_FULL_WIDTH"
                                        [onClick]="clickOnChart"
                                        [onCreated]="onCreated"
                                        [onHover]="hover"
                                        [onZoomed]="timelineActions.onChartZoom"
                            ></stnd-chart>

                            <switch-item
                                *ngIf="isShowDataInterval()"
                                class="timeline__data_interval"
                                [tabs]="tabsInterval"
                                [currentTab]="getCurrentTabsInterval()"
                                (action)="timelineActions.setDataInterval($event.type)"
                            ></switch-item>

                            <download-btn *ngIf="isShowDataInterval()"></download-btn>

                            <div *ngIf="timelineState.isZooming" class="chart-reset-zoom"
                                 (click)="timelineActions.resetChartZoom()">{{TEXTS.STATION_BLOCK.returnScale}}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- flag -->
                <div [ngClass]="{
                        'timeline__top': true,
                        'timeline-panel': store.select(selectIsSidebarOpen) | async
                    }"
                >
                    <div id="flag_drag"
                         [ngClass]="{
                            'timeline_flag': true,
                            'timeline_flag-have_data': !!time.getCurrent(),
                            'timeline_flag-tall': timelineState.chartType === 'full' && sharedCoreFacade.isComparedListNotEmpty(),
                            'timeline_flag-zoom': timelineState.isZooming
                        }"
                    >
                        <div class="timeline_flag__wrapper">
                            <div id="flag_width" class="timeline_flag__text">
                                <b>{{formatterFlagTimeHour(time.getCurrent(), sharedCoreFacade.getTzOffset())}}</b>
                                {{formatterFlagTimeDateWithYear(time.getCurrent(), sharedCoreFacade.getTzOffset())}}
                            </div>
                            <div class="timeline_flag__to_right"
                                (click)="timelineActions.goToEndTime()"
                            ></div>
                        </div>
                        <div [ngClass]="{
                                timeline_flag__line: true,
                                'timeline_flag__line-tall': !timelineState.mobileMinimize && sharedCoreFacade.isComparedListNotEmpty(),
                                'timeline_flag__line-zoom': timelineState.isZooming
                            }"
                        ></div>
                    </div>
                    <!-- /flag -->

                    <play-button
                        *ngIf="!config?.disablePlay"
                        class="timeline__play_button"
                        [state]="sharedCoreFacade.getIsPlaying() ? 'pause' : 'play'"
                        [loading]="sharedCoreFacade.getForecastIsLoading()"
                        [loadingProgress]="sharedCoreFacade.getPercentLoadForecast()/100"
                        (click)="timelineActions.togglePlay()"
                    >
                    </play-button>

                    <stnd-chart #timelineChart
                                [type]="CHART_TYPE_TIMELINE"
                                [onClick]="timelineClick"
                                [onCreated]="onCreatedTimeline"
                                [onHover]="hover">
                    </stnd-chart>
                </div>
            </div>
        </div>
    `
})
export class Timeline implements AfterContentInit, OnInit, OnDestroy {
    @Input() config?: {
        disablePlay?: boolean;
        chartConfig?: string;
        stationSubtitle?: string;
        timelineTitle?: string;
        showPdk?: boolean;
    };

    @ViewChild('timelineChart', { static: true }) timelineChart: any;

    @ViewChild('qr', { read: ElementRef })
    set qr(v: ElementRef<HTMLElement>) {
        this._qr = v;
        setTimeout(() => {
            this.adjustBottomControls = this.shouldAdjustLayout();
        }, 0);
    }

    get qr() {
        return this._qr;
    }

    private _qr: ElementRef<HTMLElement>;

    timeline = CHART_NAME_TIMELINE;
    cityair = CHART_NAME_CITYAIR;

    formatterFlagTimeHour = formatterFlagTimeHour;
    formatterFlagTimeDateWithYear = formatterFlagTimeDateWithYear;

    selectIsSidebarOpen = selectIsSidebarOpen;
    AQI = AQI;
    CHART_TYPE_FULL_WIDTH = CHART_TYPE_FULL_WIDTH;
    COLOR_BLUE = COLOR_BLUE;
    CHART_COLORS = CHART_COLORS;
    CHART_TYPE_TIMELINE = CHART_TYPE_TIMELINE;
    COMMON = TEXTS.COMMON;
    TEXTS = TEXTS;
    GroupExtConfigName = GroupExtConfigName;

    formatterFlagTime = formatterFlagTime;

    tabsInterval: TabModel[] = [
        {
            title: TEXTS.TIME_NAMES.min5,
            type: 1
        },
        {
            title: TEXTS.TIME_NAMES.min20,
            type: 2
        },
        {
            title: TEXTS.TIME_NAMES.hour1,
            type: 3
        },
        {
            title: TEXTS.TIME_NAMES.day1,
            type: 4
        }
    ];

    showQRCode = false;

    subscriptions: Subscription[] = [];

    isSidebarOpen = false;

    constructor(
        @Inject(IS_PUBLIC) readonly isPublic: boolean,
        public readonly sharedCoreFacade: SharedCoreFacade,
        public readonly groupFeaturesService: GroupFeaturesService,
        public timelineState: TimelineState,
        public timelineActions: TimelineActions,
        public time: Time,
        public store: Store
    ) {}

    getCurrentTabsInterval = () => this.tabsInterval.find(t => t.type === this.sharedCoreFacade.getChartDataIntervalType());

    showPdk = () => {
        if (!this.config?.showPdk || !isRU)
            return null;

        if (this.sharedCoreFacade.getChartDataIntervalType() === 4)
            return 'ss';

        return 'mr';
    }

    isShowDataInterval = () => !this.isPublic && this.timelineState.chartType !== 'simple' && this.sharedCoreFacade.isComparedListNotEmpty()
        && !this.sharedCoreFacade.getComparedList().find(s => s.type !== 'myMo');

    select = (measure: MeasuresInfoFormat, add = false) => {
        this.timelineActions.selectMeasure(measure, this.sharedCoreFacade.getShowingChartName(), add);
    };

    hover = (chartX: number) => this.timelineActions.chartOnHover(chartX);

    clickOnChart = (timestamp: number) => {
        if (timestamp) {
            this.time.timeUpdate.next({
                time: timestamp - this.sharedCoreFacade.getMsOffset(),
                eventFrom: TimeEventFrom.clickOnChart
            });
        }
    };

    timelineClick = (currentX: number, e) => {
        if (!currentX) {
            return;
        }

        e.stopPropagation();
        this.time.timeUpdate.next({
            time: currentX - this.sharedCoreFacade.getMsOffset(),
            eventFrom: TimeEventFrom.clickOnChart
        })
    };

    onCreated = chartControl => this.timelineActions.chartObjCreated(chartControl, this.cityair, this.config?.chartConfig || CHART_TYPE_FULL_WIDTH);
    onCreatedTimeline = chartControl => this.timelineActions.chartObjCreated(chartControl, this.timeline, CHART_TYPE_TIMELINE);

    ngAfterContentInit() {
        this.timelineChart.element.nativeElement.onclick = () => {
            this.time.timeUpdate.next({
                time: this.sharedCoreFacade.getLastHoverTime() - this.sharedCoreFacade.getMsOffset(),
                eventFrom: TimeEventFrom.clickOnChart
            });
        }

        dragFlag(this.timelineActions.updateCurrentTimeForFlagCoordinates, () => getChartShiftWidth(this.isSidebarOpen));
    }

    ngOnInit() {
        const showQRCodeSub = this.groupFeaturesService.ready$.subscribe(() => {
            this.showQRCode = this.groupFeaturesService.getConfig(GroupExtConfigName.showQRCode);
        });

        this.subscriptions.push(showQRCodeSub);

        const adjustSub = merge(
            fromEvent(window, 'resize'),
            this.timelineActions.checkControlsLayout$
        ).pipe(
            debounceTime(100),
        ).subscribe(() => {
            this.adjustBottomControls = this.shouldAdjustLayout();
        });

        this.subscriptions.push(adjustSub);

        const sideBarOpen = this.sharedCoreFacade.store.select(selectIsSidebarOpen).subscribe(val => this.isSidebarOpen = val);

        this.subscriptions.push(sideBarOpen);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    isMultiline(name: string) {
        return (TEXTS.NAMES[name] || name).split(' ').length > 1;
    }

    adjustBottomControls = false;

    shouldAdjustLayout() {
        if (this.qr) {
            const qrDims = this.qr.nativeElement.getBoundingClientRect();
            const timelineHeight = 65; // @bottomTimelineHeight
            const margin = 14;
            const mapControls = '.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group';

            const mapControlsDims = document.querySelector(mapControls)?.getBoundingClientRect();
            const availableSpace = (window.innerHeight - timelineHeight + mapControlsDims?.height) / 2 + margin;
            return qrDims.top < availableSpace;
        }
    }
}
