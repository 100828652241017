<div *ngIf="!editMode" class="plumes-page page-content__wrapper">
    <div class="admin_panel__fixed_block">
        <div class="admin_panel__title">
            <header-page [textObject]="{titlePage: texts.ADMIN_PANEL.plumes}"></header-page>
        </div>
        <ca-page-tabs
            [tabs]="tabs"
            [selectedTab]="selectedTab"
            (showTab)="showSelectedTab($event)"
        ></ca-page-tabs>
    </div>
    <div class="plumes-page__container" [ngSwitch]="currentPage">
        <plumes-calculation-results
            *ngSwitchCase="plumesPages.CALCULATION_RESULTS"
            [plumesVisible]="sharedCoreFacade.getIsShowPlume()"
        ></plumes-calculation-results>
        <plumes-sources-list
            *ngSwitchCase="plumesPages.SOURCES"
            (cbOpenCalculationResultsEdit)="openCalculationResultsEdit()"
        ></plumes-sources-list>
        <plumes-control-points
            *ngSwitchCase="plumesPages.CONTROL_POINTS"
            [(controlPointForEdit)]="controlPointForEdit"
            (cbOpenAddCheckpoint)="openAddCheckpoint()"
            (cbOpenEditCheckpoint)="openEditCheckpoint()"
            [openChartControlPoint]="actions.openChartControlPoint"
            [centringOnMarker]="actions.mapActions.centringOnMarker"
        ></plumes-control-points>
    </div>
</div>

<div *ngIf="editMode">
    <div [ngSwitch]="currentEditPage">
        <div *ngSwitchCase="plumesEditPages.ADD_CHECKPIONT">
            <edit-control-point (cbCloseEditMode)="closeEditMode()" [controlPointForEdit]="controlPointForEdit" [title]="titleEditPage"></edit-control-point>
        </div>
        <div *ngSwitchCase="plumesEditPages.EDIT_CALCULATION">
            <plumes-calculation-results-edit  (cbCloseEditMode)="closeEditMode()"></plumes-calculation-results-edit>
        </div>
    </div>
</div>
