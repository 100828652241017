import { NgModule } from '@angular/core';

import { LocationMeteoCardModule } from './components/location-meteo-card/location-meteo-card.module';

@NgModule({
    exports: [
        LocationMeteoCardModule,
    ],
})
export class WidgetsModule {}
