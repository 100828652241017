import { Component, Input } from '@angular/core';
import { HeaderPage } from '../../namespace';

import './header-page.component.less';


@Component({
    selector: 'header-page',
    templateUrl: `./header-page.component.html`
})
export class HeaderPageComponent {
    @Input() textObject: HeaderPage;
    @Input() cbBack?: () => void;
    @Input() cbOpenPage?: () => void;

}
