import { Component, Input, OnInit } from '@angular/core';

import { TEXTS } from 'src/texts/texts';
import { declOfNum } from '../../../../../../../src/utils';

@Component({
    selector: 'plumes-calculation',
    templateUrl: 'plumes-calculation.component.html',
    styleUrls: ['plumes-calculation.component.less']
})
export class PlumesCalculation implements OnInit{
    @Input() minutes: number;
    @Input() valueTypes: string[];

    TEXTS = TEXTS;

    valText: string;

    ngOnInit() {
        const hours = this.minutes / 60;
        this.valText = `${hours} ${declOfNum(hours, TEXTS.PLUMES.timeIntervalHours)}`;
    }
}
