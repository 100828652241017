import { Component, Input, OnInit, OnDestroy , ElementRef } from '@angular/core';
import * as HighstockNamespase from 'highcharts/highstock';

declare function require(path: string): any;

const Highstock = require('highcharts/highstock.js');
const exportingHighchart = require('highcharts/modules/exporting.js');
const annotationsHighchart = require('highcharts/modules/annotations.js');

import './chart.less';

import {
    updateChart,
    onPointSelect,
    chartFullscreen,
    addPoint,
    highlight,
    findPointXCoordinate,
    findPoint,
    changeOptions,
    reloadExtremes,
    setSize,
} from './chartUpdates';
import { chartCommonOption, CHART_INDIVIDUAL_OPTIONS, RESIZE_TIMEOUT } from '../config';
import { ChartControl_model, ParamResizeChart_model } from '../namespace';
import { TEXTS } from '../texts/texts';

exportingHighchart(Highstock);
annotationsHighchart(Highstock);
Highstock.setOptions(TEXTS.CHART_OPTIONS);

@Component({
    selector: 'stnd-chart',
    template: ''
})
export class StndChart implements OnInit, OnDestroy {
    @Input() type: string;
    @Input() onClick: (val: number, e: any) => void;
    @Input() onHover: (any);
    @Input() onCreated: (any);
    @Input() onZoomed: (any);

    option: any; // HighstockNamespase.Options;

    chartControl: ChartControl_model;

    resizeHandler: () => void;

    constructor(private element: ElementRef) {}

    chart: HighstockNamespase.ChartObject;
    saveInstance(chartInstance) {
        this.chart = chartInstance;

        const chartControl: ChartControl_model = {
            updateChart: updateChart.bind(chartInstance),
            onPointSelect: onPointSelect.bind(chartInstance),
            chartFullscreen: chartFullscreen.bind(chartInstance),
            addPoint: addPoint.bind(chartInstance),
            findPoint: findPoint.bind(chartInstance),
            highlight: highlight.bind(chartInstance),
            resize: this.addResizeHandler(chartInstance),
            setSize: setSize.bind(chartInstance),
            findPointXCoordinate: findPointXCoordinate.bind(chartInstance),
            changeOptions: changeOptions.bind(chartInstance),
            reloadExtremes: reloadExtremes.bind(chartInstance)
        };

        this.chartControl = chartControl;
        this.onCreated(chartControl);
    }

    addResizeHandler(chartInstance) {
        return (chartType, getParams: () => ParamResizeChart_model = () => null) => {
            this.resizeHandler = () => {
                setTimeout(() => setSize.call(chartInstance, chartType, getParams()), RESIZE_TIMEOUT);
            };

            window.addEventListener('resize', this.resizeHandler);
        };
    }

    click(val: number, e) {
        if (this.onClick) {
            this.onClick(val, e);
        }
    }

    onSeriesMouseOver = (e) => {
        if (this.onHover) {
            const point = this.chartControl.findPoint(e.chartX);
            if (point) {
                this.onHover(point.x);
            }
        }
    };

    ngOnInit() {
        const optionIndividual = CHART_INDIVIDUAL_OPTIONS[this.type];

        if (!optionIndividual) {
            console.error('в config.ts нет описаний этого типа графика');
        }

        this.option = {
            ...<HighstockNamespase.Options>chartCommonOption,
            ...<HighstockNamespase.Options>optionIndividual,
        };

        this.option.chart.events = {
            click: e => this.click(e.xAxis[0].value, e)// клик не по точке графика
        };
        this.option.plotOptions.series.point.events = {
            click: (e: any) => this.click(e.point.x, e)
        };

        if (this.onZoomed)
            this.option.xAxis.events = {
                setExtremes: this.onZoomed
            };

        // this.option.chart.style = {overflow: 'visible'};
        const stock = Highstock.stockChart(this.element.nativeElement, this.option);

        /**
         * Set renderer url to empty string after initialization in order to make urls
         * for clip paths and gradients relative to avoid conflict with application router
         */
        stock.renderer.url = '';
        this.saveInstance(stock);

        this.element.nativeElement.addEventListener('mousemove', this.onSeriesMouseOver);
    }

    ngOnDestroy() {
        this.chart.destroy();
        window.removeEventListener('resize', this.resizeHandler);
    }
}
