import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarMonthsComponent } from './calendar-months/calendar-months.component';
import { DirectivesModule } from 'projects/shared/directives/directives.module';
import { CalendarMonthsMobileComponent } from './calendar-months-mobile/calendar-months-mobile.component';

@NgModule({
    exports: [
        CalendarMonthsComponent,
        CalendarMonthsMobileComponent
    ],
    declarations: [
        CalendarMonthsComponent,
        CalendarMonthsMobileComponent
    ],
    imports: [
        CommonModule,
        DirectivesModule
    ]
})
export class CalendarMonthsModule {
}
