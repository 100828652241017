import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { TEXTS } from 'src/texts/texts';
import { PlumesFacadeService } from 'projects/cityscreen/src/modules/plumes/plumes-facade.service';
import { OffPanelPopupService } from 'projects/cityscreen/src/components/admin-panel/off-panel-popup.service';
import { RunConfig } from 'projects/cityscreen/src/modules/plumes/services/models/run-config.model';
import { declOfNum } from 'src/utils';

export interface ErrorModel {
    name?: string;
    schedule_period?: string;
}

export interface DropdownSelect {
    id: number;
    name: number;
}

@Component({
    selector: 'plumes-calculation-results-edit',
    templateUrl: 'plumes-calculation-results-edit.component.html',
    styleUrls: ['plumes-calculation-results-edit.component.less']
})
export class PlumesCalculationResultsEditComponent implements OnInit, OnDestroy {
    @Output() cbCloseEditMode = new EventEmitter<void>();

    @ViewChild('popupOutlet', { static: true }) popupOutlet: TemplateRef<HTMLDivElement>;

    runConfig: RunConfig;

    hours: number;

    TEXTS = TEXTS;

    dropdownSelectHourId: number[] = [];
    dropdownSelectHour: DropdownSelect[] = [];

    showConfirmationPopup = false;
    editCalculation: FormGroup;
    errorObj: ErrorModel = {};
    isLoading = false;

    constructor(
        private fb: FormBuilder,
        private plumeFacade: PlumesFacadeService,
        private popupProvider: OffPanelPopupService
    ) {}

    ngOnInit() {
        this.plumeFacade.runConfig$
            .pipe(take(1))
            .subscribe(runConfig => {
                this.runConfig = runConfig[0];

                this.hours = this.runConfig.schedule_period / 60;
            });

        this.editCalculation = this.fb.group({
            name: [
                this.runConfig.name,
                [
                    Validators.required
                ]
            ]
        });

        this.popupProvider.setTemplate(this.popupOutlet, () => {
            this.openPopup();
            return true;
        });

        for (let i = 1; i < 25; i++) {
            this.dropdownSelectHour.push({id: i, name: i});
            this.dropdownSelectHourId.push(i);
        }
    }

    get name() {
        return this.editCalculation.get('name');
    }

    onSubmit = async () => {
        this.isLoading = true;

        const minutes = this.hours * 60;
        const result = await this.plumeFacade.editRunConfig({
            name: this.editCalculation.value.name,
            schedule_period: minutes
        });

        if (result) {
            this.backOnCalculationResults();
        } else {
            this.errorObj = this.plumeFacade.uiState.formError;
        }
        this.isLoading = false;
    }

    openPopup() {
        this.showConfirmationPopup = true;
    }

    onClosePopup = () => {
        this.showConfirmationPopup = false;
    }

    onSavePopup = () => {
        this.showConfirmationPopup = false;
    }

    backOnCalculationResults = () => {
        this.cbCloseEditMode.emit();
    }


    selectHour = (id) => {
        this.dropdownSelectHour.map(d => {
            if (d.id === id) {
                this.hours = d.name;
            }
        });
    }


    ngOnDestroy() {
        this.popupProvider.setTemplate(null);
    }

    declOfNum = declOfNum;
}
