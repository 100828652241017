import { createSelector } from '@ngrx/store';
import { ICoreState } from './reducers';


export interface AppState {
    core: ICoreState;
}

export const coreSelector = (state: AppState) => state.core;

// -------------------------------------------------------------

export const selectGroupList = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.groups,
);

export const selectGroupInfo = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.groupInfo,
);

export const selectMeasures = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.packetsValueTypes,
);

export const selectNotifications = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.groupInfo?.notificationSubscriptions,
);

export const selectMos = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.groupInfo ? state.collections.groupInfo.monitoringObjects : [],
);

export const selectCurrentMo = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.groupInfo?.monitoringObjects.find(mo => mo.id === state.selectedMoId),
);

export const selectMyDevices = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.groupInfo?.myDevices,
);

export const selectDevices = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.devices,
);

export const selectCurrentSerialNumberDevice = createSelector(
    coreSelector,
    (state: ICoreState) => state.selectedDeviceSerialNumber,
);

export const selectCities = createSelector(
    coreSelector,
    (state: ICoreState) => state.collections.cities
);

export const selectGroupCities = createSelector(
    coreSelector,
    (state: ICoreState) => {
        return state.collections.cities.filter(c => state.collections.groupInfo.groupLocationsIds.includes(c.locationId));
    }
);

export const selectLoadingTimeline = createSelector(
    coreSelector,
    (state: ICoreState) => state.isTimelineLoading
);

export const selectMarker = createSelector(
    coreSelector,
    (state: ICoreState) => state.map ? state.map.markers : []
);

export const selectMapLoaded = createSelector(
    coreSelector,
    (state: ICoreState) => !!state.map?.loaded
);

export const selectIsSidebarOpen = createSelector(
    coreSelector,
    (state: ICoreState) => state.isSidebarOpen
);

