import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

const ACCESS_TOKEN_KEY = 'vanga_auth_access_token';

@Injectable({
    providedIn: 'root'
})
export class VangaAuthService {
    private accessToken = '';

    constructor() {
        this.setAccessToken(this.getAccessToken());
    }

    getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY));
    }

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }

    removeAccessToken() {
        this.accessToken = '';
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }

    private getJWT(token: string) {
        return token ? jwt_decode<{ exp: number }>(token) : { exp: 0 };
    }

    isTokenExpired() {
        return new Date(this.getJWT(this.accessToken).exp * 1000).getTime() < Date.now();
    }
}
