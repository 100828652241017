import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXTS } from 'src/texts/texts';

@Component({
    selector: 'input-for-dropdown',
    templateUrl: 'input-for-dropdown.component.html',
    styleUrls: ['input-for-dropdown.component.less']
})
export class InputForDropdownComponent {
    @Input() label: string;
    @Input() inputValue: string;
    @Input() isOpenDropdown: boolean;
    @Input() isError = false;
    @Input() isDisable = false;
    @Output() isOpenDropdownChange = new EventEmitter<boolean>();

    texts = TEXTS;

    toggleShow() {
        this.isOpenDropdownChange.emit(!this.isOpenDropdown)
    }
}
